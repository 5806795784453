export interface IMessage {
    messageKey: string;
}

export class MessageHub {
    private static messageHandlers: { [id: string] : { [id: string] : (e: IMessage) => void; } } = {};

    public static Subscribe(messageKey: string, listenerName: string, listener: (message: IMessage) => void) {
        if (!MessageHub.messageHandlers[messageKey]) {
            MessageHub.messageHandlers[messageKey] = {};
        }
        MessageHub.messageHandlers[messageKey][listenerName] = listener;
    }

    public static Send(message: IMessage) {
        const messageHandler = MessageHub.messageHandlers[message.messageKey];
        if (messageHandler) {
            Object.values(messageHandler).forEach(listener => listener(message));
        }
    }
}