import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import Draggable from 'react-draggable';
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Tooltip, Button, CardActions } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { RollUpCard } from '../../store/event/types';
import DontSaleCardTable from './DontSaleCardTable';
import { errorCallback, GeoMarkParams, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { BillboardFilters, GeoObjectStatus } from '../../RemoteCommands/type';
import { BillboardsShortInfo } from '../../RemoteCommands/type';
import BUILD_PARAMS from '../../utils/build';
import './DontSaleCard.scss';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

const billboardFilters: BillboardFilters = {
    propertyValueIds: [],
    status: GeoObjectStatus.outOfService
}
const DontSaleCard:React.FC<Props> = (props) => {
    const {
        draggablePositions,
        draggablePositionAction,
        draggable,
        rollUpCards,
        rollUpCardAction,
        dontSaleAction,
        dontSale,
        billboardsShortInfoAction,
        dontSaleFilterAction,
        billboardFiltersAction,
        forbiddenBillboardsAction,
        forbiddenBillboards,
        dontSaleFilter
    } = props;

    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [loading, setLoading] = useState(false);
    const eventKey = useRef<string>("");
    const eventKeyDisabled = useRef<string>("");
    const eventKeyOpenDisabled = useRef<string>("");
    const eventKeyActive = useRef<string>("");
    const eventKeySaveGeoMark = useRef<string>("");

    useEffect(() => {
        setLoading(true);
        SystemEvent.SubscribeEventGetBillboardsShortInfo(
            "DontSaleCard", 
            (answer) => {
                setLoading(false);
                if(answer.eventKey === eventKey.current) {
                    forbiddenBillboardsAction(answer.billboards);
                }
                if(answer.eventKey === eventKeyDisabled.current) {
                    billboardsShortInfoAction(answer.billboards);
                    forbiddenBillboardsAction(answer.billboards);
                }
                if(answer.eventKey === eventKeyActive.current) {
                    billboardsShortInfoAction(answer.billboards);
                }
                if(answer.eventKey === eventKeyOpenDisabled.current) {
                    billboardsShortInfoAction(answer.billboards);
                    billboardFiltersAction(billboardFilters);
                    dontSaleFilterAction(true);
                }
            }, (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
        eventKey.current = SystemEvent.EventGetBillboardsShortInfo(billboardFilters);
    }, []);

    const handleSelected = (newSelected: readonly number[]) => {
        setSelected(newSelected);
    }
    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'DontSaleCard',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Конструкции запрещеные к продаже",
            key: "DontSaleCard"
        });
    }

    const displayMap = () => {
        eventKeyOpenDisabled.current = SystemEvent.EventGetBillboardsShortInfo(billboardFilters);
    }

    const saveGeomark = () => {
        SystemEvent.SubscribeEventSaveGeoMark(
            "DontSaleCard", 
            (answer) => {
                if(eventKeySaveGeoMark.current === answer.eventKey) {
                    const newBillboardFilters: BillboardFilters = {
                        propertyValueIds: [],
                        status: GeoObjectStatus.used
                    }
                    if(!dontSaleFilter) {
                        eventKeyActive.current = SystemEvent.EventGetBillboardsShortInfo(newBillboardFilters);
                        eventKey.current = SystemEvent.EventGetBillboardsShortInfo(billboardFilters);
                    } else {
                        eventKeyDisabled.current = SystemEvent.EventGetBillboardsShortInfo(billboardFilters);
                    }
                }
                setSelected([]);
            }, 
            (error) => errorCallback(error)
        );
        const result: GeoMarkParams[] = [];
        for(let select of selected) {
            const billboards = forbiddenBillboards.filter(item => item.geoMarkId === select);
            for(let billboard of billboards) {
                const geoMark: GeoMarkParams = {
                    geoMarkId: billboard.geoMarkId,
                    geoMarkName: billboard.name,
                    geoMarkTypeId: BUILD_PARAMS.GEO_MARK_TYPE_ID,
                    objectId: billboard.gid,
                    geoMarkStatus: GeoObjectStatus.used
                }
                result.push(geoMark);
            }
        }
        for(let geoMark of result) {
            const eventSand = SystemEvent.EventSaveGeoMark(geoMark);
            eventKeySaveGeoMark.current = eventSand;
        }
    }

    const draggablePosition = draggablePositions.find(item => item.key === "DontSaleCard");
    const rollUpCardHas = rollUpCards.some(item => item.key === "DontSaleCard");

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card 
                className={`
                    selectedCard dontSaleCard 
                    ${dontSale ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="employees__close">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                className="mt-1 ml-1"
                                onClick={() => dontSaleAction(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="center" justify="space-between">
                            <Grid item xs={9}>
                                <span className="billBoardDetailedUsageInfo__title">Конструкции запрещеные к продаже</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    onClick={displayMap}>
                                    Отобразить на карте
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="cancelDraggable pt-0">
                    {loading ?
                        <div className="loading">
                            <CircularProgress />
                        </div>
                        :
                        <DontSaleCardTable 
                            billboardsShortInfo={forbiddenBillboards} 
                            selected={selected} 
                            handleSelected={handleSelected} />
                    }
                </CardContent>
                <CardActions className="cardActions">
                    <Button 
                        variant="contained"
                        color="secondary"
                        disabled={selected.length === 0 && true}
                        onClick={() => handleSelected([])}>Отмента</Button>
                    <Button 
                        variant="contained"
                        color="primary"
                        disabled={selected.length === 0 && true}
                        onClick={saveGeomark}>Сохранить</Button>
                </CardActions>
            </Card>
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
        dontSale: state.event.dontSale,
        forbiddenBillboards: state.billboards.forbiddenBillboards,
        dontSaleFilter: state.app.dontSaleFilter
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    dontSaleAction: (dontSale: boolean) => 
        dispatch(actions.event.dontSaleAction(dontSale)),
    dontSaleFilterAction: (dontSale: boolean) => 
        dispatch(actions.app.dontSaleFilterAction(dontSale)),
    billboardsShortInfoAction: (billboardsShortInfo: BillboardsShortInfo[]) => 
        dispatch(actions.billboards.billboardsShortInfoAction(billboardsShortInfo)),
    forbiddenBillboardsAction: (billboardsShortInfo: BillboardsShortInfo[]) => 
        dispatch(actions.billboards.forbiddenBillboardsAction(billboardsShortInfo)),
    billboardFiltersAction: (billboardFilters: BillboardFilters | undefined) => 
        dispatch(actions.app.billboardFiltersAction(billboardFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DontSaleCard);