import React, { Dispatch, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, selectors, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { Badge, Select, MenuItem } from '@material-ui/core';
import { GeoMarkStatus } from 'sedi-webserverproxy';
import { ProjectStatuses } from '../../store/todo/types';
import MessageShackbar from '../bricks/MessageShackbar';
import { getProjectLocalizedAvailableStatuses } from '../../utils/projectStatusHelper';
import { UserInfo } from '../../store/user/types';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { ChangeProjectParams } from '../../RemoteCommands/type';
import BookingRequestModal from '../bricks/BookingRequestModal';
import './Projects.scss';
import ControlDateModal from '../bricks/ControlDateModal';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    projectId: number,
    surfacesCount?: number,
    projectStatus: ProjectStatuses,
}

const ProjectStatusSelect:React.FC<Props> = (props) => {
    const { 
        projectId, 
        projects,
        surfacesCount,
        currentBrand,
        projectStatus,
        currentGeoMarkStatusAction,
        changeProjectStatusAction,
        changeProjectStatus,
        userInfo,
        loadingAction,
        loadingStatus,
        successMessage
    } = props;

    const [disabled, setDisabled] = useState(false);
    const [statusError, setStatusError] = useState({
        message: "",
        error: false
    });

    const [openBooking, setOpenBooking] = useState(false);
    const [openControlDate, setOpenControlDate] = useState(false);
    const [newProjectStatus, setNewProjectStatus] = useState(GeoMarkStatus.Free);

    const openBookingHandle = () => {
        setOpenBooking(true);
    }

    const closeBookingHandle = () => {
        setOpenBooking(false);
    }

    const openControlDateHandle = () => {
        setOpenControlDate(true);
    }

    const closeControlDateHandle = () => {
        setOpenControlDate(false);
        setDisabled(false);
    }

    const statusErrorClose = () => {
        setStatusError({
            message: "",
            error: false,
        })
    }

    const disabledStatuses = (projectStatus: ProjectStatuses, userInfo: UserInfo | undefined) => {
        if(
            userInfo 
            && (userInfo.userRole === "SalesDirector" || userInfo.userRole === "AdminPMS" 
            || (userInfo.userRole === "SalesManager" && projects.some(item => item.creatorAccountId === userInfo.accountId))) 
            && projectStatus.value === "Sold"
        ) {
            setDisabled(false);
        } else {
            setDisabled(
                projectStatus.value === "Completed" 
                || projectStatus.value === "Sold"
            );
        }
    }

    useEffect(() => {
        disabledStatuses(projectStatus, userInfo);
    }, [projectStatus]);

    useEffect(() => {
        if(successMessage) {
            setOpenBooking(false);
            setDisabled(false);
        }
    }, [successMessage]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const currentStatus = event.target.value as GeoMarkStatus;
        setNewProjectStatus(currentStatus);
        
        loadingAction(true);
        setDisabled(!loadingStatus);

        if(currentStatus === 'BookingRequest') {
            loadingAction(false);
            return openBookingHandle();
        }

        if(currentStatus === GeoMarkStatus.Reserved || currentStatus === GeoMarkStatus.Booked) {
            loadingAction(false);
            return openControlDateHandle();
        }

        const project = projects.find(item => item.projectId === projectId);

        const payload: ChangeProjectParams = {
            projectId: projectId,
            projectName: project && project.projectName,
            projectStatus: currentStatus,
            brandId: currentBrand ? currentBrand.brandId : undefined
        }

        SystemEvent.EventChangeProject(payload);
    };

    useEffect(() => {
        if(changeProjectStatus) {
            const currentProject = projects.find(item => item.projectId === projectId);
            if(currentProject) {
                const projectStatus: ProjectStatuses = {
                    value: currentProject.projectStatus,
                    localization: currentProject.localizedStatus
                }
                currentGeoMarkStatusAction(projectStatus);
            }
            changeProjectStatusAction(false);
        }
    }, [changeProjectStatus]);
    
    return (
        <>
            <Badge 
                badgeContent={surfacesCount ? surfacesCount : 0} 
                color="primary"
                max={9999}>
                <Select
                    value={projectStatus.value}
                    onChange={handleChange}
                    labelWidth={52}
                    disabled={disabled}
                    className="cancelDraggable">
                    {getProjectLocalizedAvailableStatuses(projectStatus.value, surfacesCount).map(item => {
                        return (
                            <MenuItem 
                                key={item.value} 
                                value={item.value}>
                                {item.localization}
                            </MenuItem>
                        )
                    })}
                </Select>
            </Badge>
            <MessageShackbar 
                message={statusError.message}
                variant="error"
                messageOpen={statusError.error} 
                vertical="top"
                horizontal="center" 
                messageShackbarClose={statusErrorClose} />
            <BookingRequestModal 
                open={openBooking} 
                handleClose={closeBookingHandle} 
                projectId={projectId} />
            {openControlDate && 
                <ControlDateModal 
                    open={openControlDate} 
                    handleClose={closeControlDateHandle} 
                    projectId={projectId}
                    projectStatus={newProjectStatus} />
            }
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    projectID: selectors.todo.getProjectID(state.todo),
    statuses: selectors.billboards.getAllStatuses(state.billboards),
    projects: state.todo.projects,
    currentBrand: state.billboards.currentBrandInfo,
    clientItem: state.customers.clientItem,
    changeProjectStatus: state.event.changeProjectStatus,
    userInfo: state.user.userInfo,
    loadingStatus: state.event.loading,
    successMessage: state.event.successMessage
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    changeStatuses: (projectIds: number[]) => 
        dispatch(actions.billboards.changeBillboardStatusesActionCreator(projectIds)),
    currentGeoMarkStatusAction: (currentGeoMarkStatus: ProjectStatuses) => 
        dispatch(actions.todo.currentGeoMarkStatusAction(currentGeoMarkStatus)),
    changeProjectStatusAction: (changeProjectStatus: boolean) => 
        dispatch(actions.event.changeProjectStatusAction(changeProjectStatus)),
    loadingAction: (loading: boolean) => dispatch(actions.event.loadingAction(loading))
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(ProjectStatusSelect);