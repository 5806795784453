import { BillboardTariffs, GeoMarkStatus, GetGeoMarkTariffs, ProjectGeoMarkStatus } from "sedi-webserverproxy"
import { Discount } from "sedi-webserverproxy/dist/controllers/webclient/types/CommercialOffer"

export type SalesPaymentStatisticFilter = {
    Year: number,
    Month: number,
    YearTo?: number,
    MonthTo?: number,
    Filter?: string
}

export type SalesPaymentStatisticResult = {
    header: string,
    statistic: Statistic[],
    totalItem: TotalItem,
}

export type Statistic = {
    month: number;
    year: number;
    sales: ISales[];
    totalItem: TotalItem;
}

export type TotalItem = {
    accountId: number,
    debtSum: number,
    name: string,
    paidTotalSum: number,
    soldTotalCount: number,
    soldTotalSum: number,
    designSum: number,
    installationSum: number,
    printSum: number,
    additionalInstallationSum: number,
    usagePercent: number
}
export interface ISales {
    accountId: number,
    name: string,
    soldTotalCount: number,
    soldTotalSum: number,
    paidTotalSum: number,
    debtSum: number,
    designSum: number,
    installationSum: number,
    printSum: number,
    additionalInstallationSum: number,
    usagePercent: number,
    projectIds: number[]
}

export type GetProjectAdditionalCostParameters = {
    additionalCostNotSaved: boolean;
    additionalCostParameters: IAdditionalCostParameters[];
}
export interface IAdditionalCostParameters {
    id: number
    billId: number
    condition: string
    costLocalization: string
    costName: string
    date: Date
    itemCost: number
    itemsCount: number
    measure: string
    measureLocalization: string
    parameter: string
    totalCost: number
    month: number
    year: number
}

export enum AutocompleteType {
    Project = "Project"
}

export interface IGetAutocompleteList {
    id: number;
    name: string;
    nameWithId: string;
}

export type GetAutocompleteList = {
    autocompleteResult: IGetAutocompleteList[]
}

export type StatisticFilter = {
    Year: number,
    Month: number,
    YearTo?: number,
    MonthTo?: number,
    SellerAccountId?: number,
    BuyerAccountId?: number
}

export type CreateProjectParams = {
    projectOwnerAccountId: number;
    projectName: string;
    brandId?: number;
    dateStart?: string;
    dateEnd?: string;
}

export type StatisticRegionFilter = {
    Year: number,
    Month: number,
    YearTo?: number,
    MonthTo?: number,
    RegionName?: string
}
export interface IPaymentAccountStatistic {
    accountName: string
    averageItemCost: number
    itemsCount: number
    region: string
    totalCost: number
}
export interface IPaymentAccountTotalItem {
    accountName: string | null
    averageItemCost: number
    itemsCount: number
    region: string | null
    totalCost: number
}

export type SalesPaymentAccountStatisticResult = {
    header: string;
    statistic: IPaymentAccountStatistic[];
    totalItem: IPaymentAccountTotalItem;
}

export interface IPaymentRegionStatistic {
    averageItemCost: number;
    buyerAccountId: number;
    buyerName: string;
    itemsCount: number;
    sellerAccountId: number;
    sellerName: string;
    totalCost: number;
}

export interface IPaymentRegionTotalItem {
    averageItemCost: number;
    itemsCount: number;
    totalCost: number;
}

export type SalesPaymentRegionStatisticResult = {
    statistic: IPaymentRegionStatistic[];
    totalItem: IPaymentRegionTotalItem;
}

export type SalesPaymentRegionStatistic = {
    salesPaymentRegionStatisticResult: SalesPaymentRegionStatisticResult
}

export type SalesPaymentAccountStatistic = {
    salesPaymentAccountStatisticResult: SalesPaymentAccountStatisticResult
}

export type ProjectInfo = {
    additionalCost: number | null;
    brandId: number;
    customerAccountId: number;
    dateFrom: string;
    dateTo: string;
    financesString: string;
    geoMarks: IGeoMark[];
    localizedStatus: string;
    projectId: number;
    projectName: string;
    projectStatus: string;
    geoMarkTimeStatusesCount: number;
    projectTotalCost: number;
}

export type GetBillboardInfo = {
    name: string;
    address: BillboardAddress;
    side1Properties: ISideProperties[];
    side2Properties: ISideProperties[];
    statuses: IBillboardStatuses[];
    uploadedPhotos: string[];
    status: GeoObjectStatus;
}

export interface ISideProperties {
    propertyId: number;
    propertyName: string;
    propertyValueId: number;
    value: string;
    propertyLocalization: string;
}

export type BillboardAddress = {
    addressString: string | null;
    apartmentNumber: string;
    areaDistrictName: string;
    areaName: string;
    cityId: number;
    cityName: string;
    countryName: string;
    entranceNumber: number;
    geoPoint: EmployeeInfoGeoPoint;
    houseNumber: string;
    id: number;
    localityName: string;
    nearestPoint: string;
    objectName: string;
    postalCode: string;
    precision: number;
    streetName: string;
}
export interface IBillboardStatuses {
    geoMarkTimeStatusId: number;
    dateTimeEnd: string;
    dateTimeStart: string;
    geoMarkPart: string;
    projectId: number;
    projectName: string;
    status: ProjectGeoMarkStatus | GeoMarkStatus;
    isOverSale: boolean;
}

export interface IGeoMark {
    geoMarkId: number;
    geoMarkName: string;
    geoMarkTimeStatuses: IGeoMarkTimeStatuses[];
    ttLink: string;
}

export interface IGeoMarkTimeStatuses {
    dateTimeEnd: string;
    dateTimeStart: string;
    geoMarkTimeStatusId: number;
    gid: string;
    price: number;
    priceWithDiscount: string;
    properties: string;
    geoMarkPart: string;
    notExistingPart: boolean;
    isOverSale: boolean;
}

export type BillboardUsageFilter = {
    region?: string | null;
    city?: string | null;
    constructionType?: string | null;
    year: number;
    month: number; 
    yearTo?: number;
    monthTo?: number; 
}

export type IBillboardUsageFilterResult = {
    region?: string;
    constructionType?: string;
    year: number;
    month: number; 
    yearTo?: number;
    monthTo?: number; 
}

export interface IBillboardsUsageInfoItems {
    geoMarkId: number;
    geoMarkName: string;
    gid: string;
    items: IUsageItems[];
}

export interface IUsageItems {
    month: number;
    year: number;
    usagePercent: number;
}
export interface IEventGetBillBoardsUsageInfo {
    billboardUsageFilter: IBillboardUsageFilterResult;
    billboardsUsageInfoResult: IBillboardsUsageInfoItems[];
    billboardsAverageMonthUsage: BillboardsAverageMonthUsage[];
    totalData: BillboardsAverageMonthUsage[];
}

export type BillboardsAverageMonthUsage = {
    month: number;
    usagePercent: number;
    value: number;
    year: number;
}

export interface IBillboardsUsageInfoResult {
    customerAccountId: number;
    customerName: string;
    gid: string;
    localizedStatus: string;
    periods: Periods[];
    projectId: number;
    projectName: string;
    side: string;
    status: string;
}

export type Periods = {
    dateEnd: string;
    dateStart: string;
}
export interface IBillboardClients {
    customerAccountId: number;
    customerName: string;
    projects: ClientProjects[];
}

export type ClientProjects = {
    id: number;
    name: string;
}

export type GetBillBoardsUsageInfo = {
    billboardDetailedUsageResult: IBillboardsUsageInfoResult[];
}

export type Period = {
    Year: number;
    Month: number; 
    YearTo?: number;
    MonthTo?: number;
}
export type EmployeeFilter = {
    textFilter?: string;
    roles?: string[] | null;
    workMode?: WorkMode;
}

export enum EmployeeRole {
    SalesManager = "SalesManager",
    SalesDirector = "SalesDirector",
    DataOperator = "DataOperator"
}

export enum WorkMode {
    All = "",
    Online = "Online",
    Offline = "Offline",
    Web = "Web",
    Mixed = "Mixed",
    Botline = "Botline",
    Pushline = "Pushline",
}

export type GetEmployeesShortInfo = {
    shortEmployeesInfo: IShortEmployeesInfo[]
}

export interface IShortEmployeesInfo {
    employeeId: number;
    firstName: string;
    roles: EmployeesRoles[];
    secondName: string;
    sipAccount: string | null;
    workMode: number;
}

export type EmployeesRoles = {
    groupId: number;
    role: number;
    roleAllowed: boolean;
}

export type GetEmployeeDetailedInfo = {
    detailedEmployeeInfo: IDetailedEmployeeInfo;
}

export interface IDetailedEmployeeInfo {
    employeeId: number;
    firstName: string;
    secondName: string;
    gender: boolean;
    email: string;
    notes: any;
    phones: Phones[];
    roles: EmployeeInfoRole[];
    address: EmployeeInfoAddress | null;
    photoImageId?: number;
    workMode?: number;
    birthDate?: any;
    documentIds?: number[];
    driverInfo?: any;
}

export type EmployeeData = {
    employeeId: number;
    firstName: string;
    secondName: string;
    phones: Phones[];
    email: string;
    gender: boolean;
    address: EmployeeInfoAddress | null;
    notes: any;
    birthDate: any;
    roles: EmployeeInfoRole[];
}

export type Phones = {
    number: string;
    phoneType: string;
}
export type EmployeeInfoAddress = {
    addressString?: string | null;
    apartmentNumber?: string;
    areaDistrictName?: string;
    areaName?: string;
    cityId?: number;
    cityName?: string;
    countryName?: string;
    entranceNumber?: number;
    geoPoint?: EmployeeInfoGeoPoint;
    houseNumber?: string;
    id?: number;
    localityName?: string;
    nearestPoint?: string;
    objectName?: string;
    postalCode?: string;
    precision?: number;
    streetName?: string;
}

export type EmployeeInfoGeoPoint = {
    latitude: number;
    longitude: number;
}
export type EmployeeInfoRole = {
    groupId: number;
    role: string;
    roleAllowed: boolean;
}

export type GeoMarkTariffsParams = {
    geoMarkIds?: number[], 
    ownerAccountId?: number, 
    brandId?: number
}

export type GetGeoMarkTariffsType = {
    geoMarkTariffs: GetGeoMarkTariffs[];
}

export type ChangeProjectParams = {
    projectId: number,
    projectName?: string,
    projectStatus?: GeoMarkStatus,
    projectDescription?: string,
    brandId?: number,
    creatorAccountId?: number,
    controlDateTime?: string
}

export type ChangedCommercialOfferParmas = {
    offerId?: number, 
    projectId?: number, 
    financialDiscounts?: Discount[], 
    boardCostInfo?: BillboardTariffs | null
}

export type GetProjectParmas = {
    projectType?: string;
    dateStart?: string;
    dateEnd?: string;
    projectStatus?: string;
    brandId?: number;
    creatorAccountId?: number;
    ownerAccountId?: number;
    language?: string | null;
    projectIds?: number[];
}

export type GetTariffParameterConditions = {
    tariffParameterConditions: TariffParameterConditions[];
}

export type TariffParameterConditions = {
    conditionLocalization: string;
    conditionName: string;
    values: string[];
}

export interface AdditionalGeoMarkTariffs {
    cost: number | string;
    parameters: TariffsParameters[];
    pricingLocalization: string;
    pricingType: string;
    tariffParameterId: number;
}

export type TariffsParameters = {
    parameterName: string;
    parameterValue: string;
}

export class AdditionalGeoMarkTariff implements AdditionalGeoMarkTariffs {
    cost: number | string;
    parameters: TariffsParameters[];
    pricingLocalization: string;
    pricingType: string;
    tariffParameterId: number;

    constructor(source?: AdditionalGeoMarkTariffs) {
        this.cost = source ? source.cost : "";
        this.parameters = source ? source.parameters : [];
        this.pricingLocalization = source ? source.pricingLocalization : "";
        this.pricingType = source ? source.pricingType : "";
        this.tariffParameterId = source ? source.tariffParameterId : -1;
    }
}

export type EventGetBillboardsShortInfo = {
    billboards: BillboardsShortInfo[];
    eventKey: string;
}

export interface BillboardsShortInfo {
    geoMarkId: number;
    geoPoint: EmployeeInfoGeoPoint;
    gid: string;
    name: string;
    region: string;
    city: string;
    side1Fit: boolean;
    side2Fit: boolean;
    boardSides: string[];
}

export type BillboardFilters = {
    region?: string;
    side?: string;
    propertyValueIds?: number[];
    city?: string;
    status?: GeoObjectStatus;
    geoMarkIds?: number[];
}

export enum GeoObjectStatus {
    used = "Used",
    outOfService = "OutOfService"
}

export type GeoMarksFilter = {
    regionName?: string;
    cityName?: string;
    text?: string;
}

export type EventGeoMarks = {
    id: number;
    name: string;
    gid: string;
    region: string;
    city: string;
}

export type FullMonthStatistic = {
    geoMarkId: number;
    statuses: StatisticStatuses[];
}

export type StatisticStatuses = {
    sideCount: number
    status: string
}

export type SalesPaymentGidDetailedStatistic = {
    boardName: string
    gid: string
    projectId: number
    projectName: string
    region: string
    sum: number
}

export type ImageType = {
    imageType: string;
    localization: string;
}

export enum SandingWay {
    Online = 'Online',
    BySms = 'BySms',
    ByEmail = 'ByEmail',
    ByPhone = 'ByPhone',
    NotSend = 'NotSend',
    Push = 'Push'
}

export type RequestProjectBooking = {
    projectId: number;
    bookingEndTime: string;
    controlDateTime: string;
    messageSendingWay: SandingWay[];
}

export enum QiwiBillStatus {
    /** Новый */ 
    New = 'New',
    /** Выставлен */ 
    Invoiced = 'Invoiced',
    /** Проводится */
    InProgress = 'InProgress',
    /** Деньги заблокированы */
    FundsLocked = 'FundsLocked',
    /** Оплачен */
    Paid = 'Paid',
    /** Отменен. Ошибка на терминале */
    CancelledTerminalError = 'CancelledTerminalError',
    /** Отменен. Ошибка авторизации */
    CancelledAuthorizationError = 'CancelledAuthorizationError',
    /** Отменен */
    Cancelled = 'Cancelled',
    /** Отменен. Истекло время */
    CancelledTimeout = 'CancelledTimeout',
    /** Возврат средств */
    Refund = 'Refund',
    /** Частичный возврат средств */
    PartialRefund = 'PartialRefund',
    /** Отправлен плательщику */
    SentToPayer = 'QiwiBillStatusSentToPayer'
}

export type PaymentBillInfo = {
    billId: number;
    payerAccountId: number;
    payerName: string;
    recipientAccountId: number;
    recipientName: string;
    paymentAmount: number;
    registrationDate: Date;
    comment: string;
    status: QiwiBillStatus;
    projectId: number;
}

export type PaymentBillInfoType = {
    paymentBills: PaymentBillInfo[];
}

export type PaymentBillsFilter = {
    projectId?: number;
    billStatuses?: QiwiBillStatus[];
    customerAccountId?: number;
    responsibleAccountId?: number;
    dateStart?: string;
    dateEnd?: string;
}

export type PhotoImageData = {
    imageId: number;
    takingTime: Date;
}

export type BillboardPhotoControlInfo = {
    geoMarkId: number;
    geoMarkName: string;
    gid: string;
    region: string;
    images: PhotoImageData[];
    photoControllerId: number;
    photoControllerName: string;
}

export type BillboardsPhotoControlType = {
    billBoardsPhotoControl: BillboardPhotoControlInfo[];
}