import React, { Component } from 'react';
import InputNumber from './InputNumber';


type Props = {
    onChange: (range: NumbersRange) => void,
    range?: NumbersRange,
    allowUndefinedValues?: boolean,
    labelTextFrom?: string,
    labelTextTo?: string,
};


export type NumbersRange = {
    Min: number | undefined,
    Max: number | undefined
};


type State = {
    range: NumbersRange
};


export default class InputDatesRange extends Component<Props, State> {

    public static defaultProps = {
        allowUndefinedValues: true,
        labelTextFrom: 'От',
        labelTextTo: 'до',
    }


    constructor(props: Props) {
        super(props);

        const { range } = props;

        this.state = {
            range: range || { Min: undefined, Max: undefined }
        };
    }


    handleChangeDate(isEditMin: boolean, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        const target = event.target;
        const newValue = parseFloat(target.value) || undefined;

        const { range } = this.state;

        let newRange = range;
        if (isEditMin) {
            newRange.Min = newValue;
        } else {
            newRange.Max = newValue;
        }

        // корректоривка не всезда приводит к ожидаемому результату
        // например, "мин = 5, макс = 33" стирает "мин = 5" после ввода "макс = 3"
        // if (newRange.Min !== undefined
        //     && newRange.Max !== undefined
        //     && newRange.Min > newRange.Max) {
        //     if (isEditMin) {
        //         newRange.Max = undefined;
        //     }
        //     else {
        //         newRange.Min = undefined;
        //     }
        // }

        this.setState({ range: newRange });
        this.props.onChange(newRange);
    }


    render() {
        const { range } = this.state;
        const { labelTextFrom, labelTextTo } = this.props;

        return (
            <div className="d-flex align-items-center">

                <InputNumber
                    label={labelTextFrom}
                    className="mr-1"
                    valueAsNumber={range.Min}
                    onChange={(e) => this.handleChangeDate(true, e)}
                />

                <InputNumber
                    label={labelTextTo}
                    className="mr-1"
                    valueAsNumber={range.Max}
                    onChange={(e) => this.handleChangeDate(false, e)}
                />
            </div>
        );
    }
}
