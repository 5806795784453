import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../../store';
import { connect } from 'react-redux';
import { ExceededTimeProject } from '../../../utils/onlyUnique';
import CloseIcon from '@material-ui/icons/Close';
import { removeItemAtIndex } from '../../../utils/replaceItem';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    project: ExceededTimeProject
};

const ExceededProject = (props: Props) => {
    const { 
        project,
        setActiveProject,
        openSelectedBillboardsAction,
        exceededTimeProjects,
        exceededTimeProjectsAction
    } = props;

    const handleDelete = () => {
        const idx = exceededTimeProjects.findIndex((item) => item === project);
        const newList = removeItemAtIndex(exceededTimeProjects, idx);
        exceededTimeProjectsAction(newList);
    }

    const handleOpenProject = () => {
        setActiveProject(parseInt(project.projectId));
        openSelectedBillboardsAction(true);
    }

    return (
        <div className="notificationGroup__item">
            <div className="notificationGroup__iconGroup">
                <CloseIcon onClick={handleDelete} />
            </div>
            <div className="notificationGroup__desc">{project.eventText}</div>
            <div className="notificationGroup__btnRow">
                <button 
                    className="notificationGroup__btn"
                    onClick={handleOpenProject}>
                    Открыть проект
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    exceededTimeProjects: state.todo.exceededTimeProjects
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => 
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    exceededTimeProjectsAction: (exceededTimeProjects: ExceededTimeProject[]) => 
        dispatch(actions.todo.exceededTimeProjectsAction(exceededTimeProjects))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExceededProject);