import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import { MonthYear } from '../../utils/getMonthYear';
import { RollUpCard, StatisticsType } from '../../store/event/types';

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
    contextMenuClose: () => void,
    anchorEl: HTMLElement | null,
    currentMonthYear: MonthYear
}

const ContextMenu:React.FC<Props> = (props) => {
    const { 
        salesStatisticsToggleAction,
        contextMenuClose,
        anchorEl,
        currentMonthYear,
        currentMonthYearAction,
        statisticsTypeAction,
        userInfo,
        rollUpCardsAction,
        rollUpCards
    } = props;

    const statisticClick = (statisticsType: StatisticsType) => {
        statisticsTypeAction(statisticsType);
        currentMonthYearAction(currentMonthYear);
        salesStatisticsToggleAction(true);
        contextMenuClose();
    }
    const rolleUpClick = () => {
        const idx = rollUpCards.findIndex((item) => item.key === "SalesStatistics");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    const userRoleType = userInfo && (userInfo.userRole === "SalesDirector" || userInfo.userRole === "AdminPMS");

    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={contextMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className="ml-2"
            onClick={rolleUpClick}
        >
            <MenuItem
                onClick={() => statisticClick(StatisticsType.sale)}>
                Статистика по продажам</MenuItem>
            <MenuItem 
                onClick={() => statisticClick(StatisticsType.client)}>
                Статистика по клиентам</MenuItem>
            {userRoleType &&  
                <MenuItem
                    onClick={() => statisticClick(StatisticsType.employee)}>
                    Статистика по сотрудникам
                </MenuItem>
            }
            <MenuItem
                onClick={() => statisticClick(StatisticsType.region)}>
                Статистика по регионам
            </MenuItem>
            {/* <MenuItem
                onClick={() => statisticClick(StatisticsType.city)}>
                Статистика по городам (районам)
            </MenuItem> */}
            <MenuItem
                onClick={() => statisticClick(StatisticsType.constructionType)}>
                Статистика по типам конструкций
            </MenuItem>
            <MenuItem
                onClick={() => statisticClick(StatisticsType.loadingConstructions)}>
                Загрузка конструкций
            </MenuItem>
        </Menu>
    );
};

const mapStateToProps = (state: RootState) => ({
    userInfo: state.user.userInfo,
    rollUpCards: state.event.rollUpCards
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    salesStatisticsToggleAction: (salesStatisticsToggle: boolean) => 
        dispatch(actions.event.salesStatisticsToggleAction(salesStatisticsToggle)), 
    currentMonthYearAction: (currentMonthYear: MonthYear) => 
        dispatch(actions.event.currentMonthYearAction(currentMonthYear)), 
    statisticsTypeAction: (statisticsType: StatisticsType) => 
        dispatch(actions.event.statisticsTypeAction(statisticsType)),  
    rollUpCardsAction: (rollUpCards: RollUpCard[]) => 
        dispatch(actions.event.rollUpCardsAction(rollUpCards)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);