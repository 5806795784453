import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { RootState, selectors, actions } from '../../store';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import { BillboardAddress, BillboardsShortInfo, ISideProperties, IBillboardStatuses, GeoObjectStatus, ImageType } from '../../RemoteCommands/type';
import { DatesRange } from '../bricks/InputDatesRange';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import BillboardSides from "../billboards/BillboardSides/BillboardSides";
import { getHighlightStyleFunc } from "../../utils/getHighlightStyleFunc";
import BillboardInfoProperties from "./BillboardInfoProperties";
import BillboardAdd from "../billboards/BillboardAdd/BillboardAdd";
import AddGeoMarkConfirm from "../Projects/ProjectSelector/AddGeoMarkConfirm";
import { AssignedObject, GeoMarkTimeStatus, ProjectGeoMarkStatus } from "sedi-webserverproxy";
import { SelectedFocus } from "../../store/event/types";
import { IBillboardProperties, PropertiesHelper } from "../../utils/PropertiesHelper";
import { EventBuilder } from "../../utils/eventHelpers";
import { ServerProxy } from "../../RemoteCommands/ServerProxy";
import { SlickCarousel } from "./SlickCarousel";
import { sortAlphabeticallyString } from "../../utils/sortAlphabetically";
import './BillboardInfo.scss';

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
    billboardShort: BillboardsShortInfo,
    dates: DatesRange
};

const BillboardInfo = (props: Props) => {
    const {
        billboardShort,
        dates,
        selectedSide,
        projectID,
        setActiveProject,
        openSelectedBillboardsAction,
        currentGeoMarkStatus,
        billboardShortAction,
        brandGeoObjects,
        newBrandGeoObjects,
        clientItem,
        selectedFocusAction,
        billboardPropertiesAction,
        billboardProperties,
        selectedSideAction,
        billboadStatuses,
        deleteGeoMartTimeStatuses,
        setStatus,
        deletePhotoAction
    } = props;

    const [name, setName] = useState("");
    const [address, setAddress] = useState<BillboardAddress | null>(null);
    const [side1Properties, setSide1Properties] = useState<ISideProperties[]>([]);
    const [side2Properties, setSide2Properties] = useState<ISideProperties[]>([]);
    const [statuses, setStatuses] = useState<IBillboardStatuses[]>([]);
    const [uploadedPhotos, setUploadedPhotos] = useState<string[]>([]);
    const [openAddBillboard, setOpenAddBillboard] = useState(false);
    const [addGeoMarkOpen, setAddGeoMarkOpen] = useState(false);
    const [geoMarkTimeStatus, setGeoMarkTimeStatus] = useState<GeoMarkTimeStatus | null>(null);
    const [isSide, setIsSide] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [geoObjectStatus, setGeoObjectStatus] = useState<GeoObjectStatus>(GeoObjectStatus.used);
    const [imageTypes, setImageTypes] = useState<ImageType[]>([]);

    const selectedSideStatus = statuses.find(s => s.geoMarkPart === selectedSide && s.projectId === projectID);
    const disabledStatus = currentGeoMarkStatus.value === "Paid" || currentGeoMarkStatus.value === "Sold";

    const isSideHandle = (side: boolean) => {
        setIsSide(side);
    }

    const onClose = () => {
        billboardShortAction(undefined);
        selectedSideAction("A1");
    }

    const geoMarkClickClose = () => {
        setAddGeoMarkOpen(false);
    }

    const addBillboardOpenClick = () => {
        setOpenAddBillboard(true);
        setIsEdit(false)
    }

    const addBillboardCloseClick = () => {
        setOpenAddBillboard(false);
    }

    const editBillboardClick = () => {
        setOpenAddBillboard(true);
        setIsEdit(true);
    }

    useEffect(() => {
        SystemEvent.SubscribeEventGetBillboardInfo(
            "BillboardInfo", 
            (answer) => {
                setName(answer.name);
                setAddress(answer.address);
                setSide1Properties(answer.side1Properties);
                setSide2Properties(answer.side2Properties);
                setGeoObjectStatus(answer.status);
                billboardPropertiesAction(
                    PropertiesHelper.getBillboardProperties(
                        answer.side1Properties, 
                        answer.side2Properties
                    )
                );
                setStatuses(answer.statuses);
                setUploadedPhotos(answer.uploadedPhotos);
            }, 
            (error) => errorCallback(error)
        );
        SystemEvent.SubscribeEventGetGeoMarkImageTypes(
            "BillboardInfo", 
            (answer) => {
                setImageTypes(answer.geoMarkImageTypes);
            }, 
            (error) => errorCallback(error)
        );
        SystemEvent.SubscribeEventSetGeoMarkTimeStatus(
            "BillboardInfo", 
            () => {
                getBillboardInfo();
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventGetProjectInfo(
            "BillboardInfo", 
            () => {
                getBillboardInfo();
            }, 
            (error) => {
                errorCallback(error);
            }
        );

        SystemEvent.EventGetGeoMarkImageTypes();
    }, [])

    const getBillboardInfo = useCallback(() => {
        const dateStart = moment.utc(dates.DateFrom, "YYYY-MM-DD").format('DD.MM.YYYY');
        const dateEnd = moment.utc(dates.DateTo, "YYYY-MM-DD").format('DD.MM.YYYY');
        SystemEvent.EventGetBillboardInfo(billboardShort.geoMarkId, dateStart, dateEnd);
    }, [billboardShort]);

    useEffect(() => {
        getBillboardInfo();
    }, [billboardShort]);

    const currentStatusProjectView = () => {
        if(selectedSideStatus) {
            return (
                <div className="projectName">
                    {selectedSideStatus.projectName}
                </div>
            )
        } 
        return;
    }

    const currentProjectSelected = async (projectId: number) => {
        setActiveProject(projectId)
        openSelectedBillboardsAction(true);
    }

    const statusProjectsView = () => {
        let otherStatuses = statuses.filter(item => item.geoMarkPart === selectedSide && item.projectId !== projectID);
        otherStatuses = otherStatuses.concat(statuses.filter(item => item.projectId !== projectID && item.isOverSale === true && item.geoMarkPart.includes(selectedSide.substring(0, 1))));
        let distinctStatuses: IBillboardStatuses[] = [];
        otherStatuses.forEach(element => {
            if(distinctStatuses.findIndex(item =>item.projectName == element.projectName) < 0)
                distinctStatuses.push(element);
        });
        return distinctStatuses.map(item => {
            return (
                <div 
                    key={"currentStatuses-" + item.geoMarkTimeStatusId}
                    className="project-row">
                    <div 
                        style={getHighlightStyleFunc(item.status)}
                        className="project-row__icon">
                    </div>
                    <div 
                        className="linkProjectName" 
                        key={item.geoMarkTimeStatusId}
                        onClick={() => currentProjectSelected(item.projectId)}>
                        {item.projectName}
                    </div>
                </div>
            )
        })
    }

    const handleClickReservedSide = async (isOverSale: boolean = false) => {
        let address: AssignedObject | undefined = undefined;

        const allBrandGeoObjects = brandGeoObjects.concat(newBrandGeoObjects);

        for(let brandGeoObject of allBrandGeoObjects) {
            if(brandGeoObject.Billboards) {
                for(let geoObjectBillboard of brandGeoObject.Billboards) {
                    if(geoObjectBillboard.GeoMarkId === billboardShort.geoMarkId) {
                        const { Address } = brandGeoObject;
                        if(Address) {
                            const assignedObject: AssignedObject = {
                                cityName: Address.CityName ? Address.CityName : "",
                                streetName: Address.StreetName ? Address.StreetName : "",
                                objectName: Address.ObjectName ? Address.ObjectName : "",
                                houseNumber: Address.HouseNumber ? Address.HouseNumber : "",
                                geoPoint: {
                                    latitude: Address.GeoPoint ? Address.GeoPoint.Latitude : 0,
                                    longitude: Address.GeoPoint ? Address.GeoPoint.Longitude : 0,
                                }
                            }
                            address = assignedObject
                        }
                    }
                }
            }
        }

        const currentStatus = currentGeoMarkStatus.value === "New" ? "Free" : currentGeoMarkStatus.value;

        const newStatus: GeoMarkTimeStatus = {
            dateTimeEnd: moment(dates.DateTo).format("YYYY-MM-DDTHH:mm:ss"),
            dateTimeStart: moment(dates.DateFrom).format("YYYY-MM-DDTHH:mm:ss"),
            geoMarkId: billboardShort!.geoMarkId,
            geoMarkPart: selectedSide,
            geoMarkStatus: currentStatus as ProjectGeoMarkStatus,
            geoMarkTimeStatusId: -1,
            projectId: projectID,
            isOverSale: isOverSale,
            assignedObject: address
        }

        if(projectID <= 0) {
            openSelectedBillboardsAction(true);
            if(!clientItem) {
                selectedFocusAction(SelectedFocus.company);
            } else {
                selectedFocusAction(SelectedFocus.project);
            }
        } else {
            setGeoMarkTimeStatus(newStatus);
            setAddGeoMarkOpen(true);
            selectedFocusAction(SelectedFocus.none);
        }
    }

    const handleClickFreeSide = () => {
        const selectedSideStatus = statuses.find(s => s.geoMarkPart === selectedSide && s.projectId === projectID);

        if (selectedSideStatus === undefined) {
            alert('status not found');
            return;
        }

        const newStatus: GeoMarkTimeStatus = {
            dateTimeEnd: selectedSideStatus.dateTimeEnd,
            dateTimeStart: selectedSideStatus.dateTimeStart,
            geoMarkId: billboardShort.geoMarkId,
            geoMarkPart: selectedSideStatus.geoMarkPart,
            // сменить статус на Deleted
            geoMarkStatus: ProjectGeoMarkStatus.Deleted,
            geoMarkTimeStatusId: selectedSideStatus.geoMarkTimeStatusId,
            projectId: selectedSideStatus.projectId,
            isOverSale: false
        }

        setStatus(newStatus);
        const geoMarkTimeStatus = billboadStatuses.filter(status => status.geoMarkTimeStatusId === selectedSideStatus.geoMarkTimeStatusId)
        const geoMarkTimeStatusIds = geoMarkTimeStatus.map(b => b.geoMarkTimeStatusId)
        deleteGeoMartTimeStatuses(geoMarkTimeStatusIds);
    }

    const billboardSidesClick = (selectedSide: string) => {
        const builder = new EventBuilder(`EventUserGetYearStatistics`);
        builder.AddParameter('GeoMarkGid', billboardShort.gid + selectedSide);
        ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        selectedSideAction(selectedSide);
    }

    const sortImages = useCallback(() => {
        const result: string[] = [];
        const sideNumber = parseInt(selectedSide.replace(/[^\d]/g, ''));
        const name = (selectedSide.includes('B') || sideNumber % 2 === 0) ? "2" : "1";
        for(let image of uploadedPhotos) {
            if(image.includes(name)) {
                result.push(image);
            }
        }
        if(!result.some(item => item == "TT2")){
            if(uploadedPhotos.some(image => image === "TT")) {
                result.push('TT');
            }
        }
        const sortResult =  sortAlphabeticallyString(result)
        return sortResult;
    }, [selectedSide, uploadedPhotos]);
    
    const getCanAddOverSale = () => {
        let can = false;

        const sideProps = isSide ? side1Properties : side2Properties;
        const overSaleCountProp = sideProps.find(item => item.propertyName === "OverSalesCount");
        const countMaxOverSale = parseInt(overSaleCountProp && overSaleCountProp.value || "0");
        if(countMaxOverSale === 0)
            return false;

        if(!billboardProperties)
            return false;

        //const sideCount = isSide ? billboardProperties.side1Count : billboardProperties.side2Count;
        const sides = isSide ? billboardProperties.sides1 : billboardProperties.sides2;
        //проверяем, все ли базовые поверхности проданы хотя бы 1 раз
        if(sides.filter(item => statuses.filter(s => item.includes(s.geoMarkPart) && s.isOverSale === false).length === 0).length === 0)
            can = true;
        
        if(statuses.filter(item => item.isOverSale === true).length == countMaxOverSale) {
            can = false
        }
        return can;
    }

    return (
        <>
        <Card className="modal-billboard-detail">
            <CardHeader
                action={
                    <>
                        <Tooltip 
                            title="Добавить новую конструкцию" 
                            placement="top">
                            <IconButton 
                                onClick={addBillboardOpenClick}
                                className="mt-1 ml-1">
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip 
                            title="Редактировать конструкцию" 
                            placement="top">
                            <IconButton 
                                onClick={editBillboardClick}
                                className="mt-1 ml-1">
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <IconButton 
                            onClick={onClose}
                            className="mt-1 ml-1">
                            <Close />
                        </IconButton>
                    </>
                }
                title={
                    <div className="billboardTitle">
                        <div>{name}</div>
                        <div className="billboardDates">
                            c {moment.utc(dates.DateFrom).format("DD.MM.YYYY")} до {moment.utc(dates.DateTo).format("DD.MM.YYYY")}
                        </div>
                    </div>
                }
            />
            <CardContent>
                <Grid container>
                    <Grid container direction="row">
                        <Grid item xs={8}>
                            {billboardProperties && 
                                <BillboardSides
                                    selectedSide={selectedSide}
                                    statuses={statuses}
                                    dates={dates}
                                    onClick={(selectedSide: string) => billboardSidesClick(selectedSide)}
                                    billboardProperties={billboardProperties}  
                                    isSideHandle={(side: boolean) => isSideHandle(side)} />
                            }
                            {currentStatusProjectView()}
                            {!selectedSideStatus ? 
                                <Button 
                                    color="primary" 
                                    variant="contained" 
                                    onClick={() => handleClickReservedSide(false)} 
                                    disabled={disabledStatus}>
                                    Добавить в проект
                                </Button>
                                :
                                <Button 
                                    color="secondary" 
                                    variant="contained" 
                                    onClick={handleClickFreeSide} 
                                    disabled={disabledStatus}>
                                    Удалить из проекта
                                </Button>
                            }
                            <div className="projects-list">
                                {statusProjectsView()}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <SlickCarousel
                                uploadedPhotos={sortImages()}
                                geoMarkId={billboardShort.geoMarkId}
                                selectedSide={selectedSide} 
                                deletePhotoAction={deletePhotoAction} 
                                dates={dates} 
                                imageTypes={imageTypes} />
                        </Grid>
                    </Grid>
                </Grid>
                <BillboardInfoProperties 
                    billboardShort={billboardShort} 
                    sideProperties={isSide ? side1Properties : side2Properties} 
                    name={name}
                    billboardOverSaleStatuses={statuses.filter(item => item.isOverSale === true)}
                    overSaleHandleClick={handleClickReservedSide}
                    canAddOverSale={getCanAddOverSale()} />
            </CardContent>
        </Card>
        {openAddBillboard &&
            <BillboardAdd 
                openAddBillboard={openAddBillboard}
                isEdit={isEdit} 
                addBillboardCloseClick={addBillboardCloseClick}
                billboard={billboardShort} 
                sideProperties={isSide ? side1Properties : side2Properties} 
                name={name} 
                isSide={isSide} 
                dates={dates} 
                geoObjectStatus={geoObjectStatus} />
        }
        {geoMarkTimeStatus &&
            <AddGeoMarkConfirm 
                duplicateOpen={addGeoMarkOpen} 
                duplicateClickClose={geoMarkClickClose} 
                geoMarkTimeStatus={geoMarkTimeStatus} 
                GID={billboardShort.gid + selectedSide} 
            />
        }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    let projectID = selectors.todo.getProjectID(state.todo);

    return {
        projectID,
        selectedSide: state.billboards.selectedSide,
        currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
        clientItem: state.customers.clientItem,
        brandGeoObjects: state.event.brandGeoObjects,
        newBrandGeoObjects: state.event.newBrandGeoObjects,
        billboardProperties: state.billboards.billboardProperties,
        billboadStatuses: state.billboards.billboadStatuses
    }
};


const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    selectedSideAction: (selectedSide: string) => 
        dispatch(actions.billboards.selectedSideAction(selectedSide)),
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => 
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    billboardShortAction: (billboardShort: BillboardsShortInfo | undefined) => 
        dispatch(actions.billboards.billboardShortAction(billboardShort)),
    selectedFocusAction: (selectedFocus: SelectedFocus) => 
        dispatch(actions.event.selectedFocusAction(selectedFocus)),
    billboardPropertiesAction: (billboardProperties: IBillboardProperties | undefined) => 
        dispatch(actions.billboards.billboardPropertiesAction(billboardProperties)),
    deleteGeoMartTimeStatuses: (geoMarkTimeStatusIds: number[]) => 
        dispatch(actions.billboards.deleteGeoMartTimeStatuses(geoMarkTimeStatusIds)),
    setStatus: (status:GeoMarkTimeStatus) => 
        dispatch(actions.billboards.saveBillboardStatusActionCreator(status)),
    deletePhotoAction: (geoMarkId: number, sideName: string) => 
        dispatch(actions.billboards.deletePhotoAction(geoMarkId, sideName))
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(BillboardInfo)