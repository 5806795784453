import { Action} from 'redux';
import store, { actions } from '..';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { BillboardFilters, GeoObjectStatus } from '../../RemoteCommands/type';
import { DefaultPosition, DraggablePosition, PropertyFilter } from './types';

export enum AppActionTypes {
    CHANGE_PHONE_CONTROL_STATE = 'CHANGE_PHONE_CONTROL_STATE',
    CHANGE_MENU_STATE = 'CHANGE_MENU_STATE',
    CHANGE_MAP_STTINGS = 'CHANGE_MAP_STTINGS',
    CHANGE_STATUS_CHECKBOX = 'CHANGE_STATUS_CHECKBOX',
    FILTER_PROPERTY_BILLBOARDS = 'FILTER_PROPERTY_BILLBOARDS',
    FILTER_PROPERTY_BILLBOARDS_FORMAT = 'FILTER_PROPERTY_BILLBOARDS_FORMAT',
    FILTER_PROPERTY_BILLBOARDS_SIDE = 'FILTER_PROPERTY_BILLBOARDS_SIDE',
    FILTER_PROPERTY_BILLBOARDS_LIGHT = 'FILTER_PROPERTY_BILLBOARDS_LIGHT',
    FILTER_PROPERTY_BILLBOARDS_TYPE = 'FILTER_PROPERTY_BILLBOARDS_TYPE',
    FILTER_PROPERTY_BILLBOARDS_AB = 'FILTER_PROPERTY_BILLBOARDS_AB',
    ALL_RESET_FILTERS = 'ALL_RESET_FILTERS',
    DRAGGABLE = 'DRAGGABLE',
    DEFAULT_POSITION = 'DEFAULT_POSITION',
    DEFAULT_POSITION_DISCOUNT = "DEFAULT_POSITION_DISCOUNT",
    POSITION_NEXT_BILLBOARD = "POSITION_NEXT_BILLBOARD",
    POSITION_BRANDS_CARD = "POSITION_BRANDS_CARD",
    POSITION_SEARCH_OBJECTS = "POSITION_SEARCH_OBJECTS",
    POSITION_SALES_STATISTICS = "POSITION_SALES_STATISTICS",
    POSITION_ADDITIONAL_COST = "POSITION_ADDITIONAL_COST",
    ROLL_UP_BRAND = "ROLL_UP_BRAND",
    POSITION_BILLBOARD_ADD = "POSITION_BILLBOARD_ADD",
    DRAGGABLE_POSITION = "DRAGGABLE_POSITION",
    BILLBOARD_FILTERS = "BILLBOARD_FILTERS",
    PROPERTY_FILTER = "PROPERTY_FILTER",
    DONT_SALE_FILTER = "DONT_SALE_FILTER"
}


export interface IChangePhoneControlStateAction extends Action<AppActionTypes.CHANGE_PHONE_CONTROL_STATE> {}
export interface IChangeMenuStateAction extends Action<AppActionTypes.CHANGE_MENU_STATE> {}
export interface IChangeMapSettingAction extends Action<AppActionTypes.CHANGE_MAP_STTINGS> {clusterZoomLevel: number, clusterMinPoints: number}
export interface IChangeStatusCheckboxAction extends Action<AppActionTypes.CHANGE_STATUS_CHECKBOX> {reservedChecked: boolean, bookedChecked: boolean, soldChecked: boolean, paidChecked: boolean, freeChecked: boolean}
export interface IFilterPropertyBillboardsAction extends Action<AppActionTypes.FILTER_PROPERTY_BILLBOARDS> {constructionType: string}
export interface IFilterPropertyBillboardsFormatAction extends Action<AppActionTypes.FILTER_PROPERTY_BILLBOARDS_FORMAT> {format: string}
export interface IFilterPropertyBillboardsSideAction extends Action<AppActionTypes.FILTER_PROPERTY_BILLBOARDS_SIDE> {selectedSide: string}
export interface IFilterPropertyBillboardsLightAction extends Action<AppActionTypes.FILTER_PROPERTY_BILLBOARDS_LIGHT> {light: string}
export interface IFilterPropertyBillboardsTypeAction extends Action<AppActionTypes.FILTER_PROPERTY_BILLBOARDS_TYPE> {billboardType: string}
export interface IFilterPropertyBillboardsABAction extends Action<AppActionTypes.FILTER_PROPERTY_BILLBOARDS_AB> {billboardAB: string}
export interface IAllResetFiltersAction extends Action<AppActionTypes.ALL_RESET_FILTERS> {}
export interface IDraggable extends Action<AppActionTypes.DRAGGABLE> {draggable: boolean}
export interface IDefaultPosition extends Action<AppActionTypes.DEFAULT_POSITION> {defaultPosition: DefaultPosition}
export interface IDefaultPositionDiscount extends Action<AppActionTypes.DEFAULT_POSITION_DISCOUNT> {defaultPositionDiscount: DefaultPosition}
export interface IPositionNextBillboard extends Action<AppActionTypes.POSITION_NEXT_BILLBOARD> {positionNextBillboard: DefaultPosition}
export interface IPositionBrandsCard extends Action<AppActionTypes.POSITION_BRANDS_CARD> {positionBrandsCard: DefaultPosition}
export interface IPositionSearchObjects extends Action<AppActionTypes.POSITION_SEARCH_OBJECTS> {positionSearchObjects: DefaultPosition}
export interface IPositionSalesStatistics extends Action<AppActionTypes.POSITION_SALES_STATISTICS> {positionSalesStatistics: DefaultPosition}
export interface IPositionAdditionalCost extends Action<AppActionTypes.POSITION_ADDITIONAL_COST> {positionAdditionalCost: DefaultPosition}
export interface IRollUpBrand extends Action<AppActionTypes.ROLL_UP_BRAND> {rollUpBrand: boolean}
export interface IPositionBillboardAdd extends Action<AppActionTypes.POSITION_BILLBOARD_ADD> {positionBillboardAdd: DefaultPosition}
export interface IDraggablePosition extends Action<AppActionTypes.DRAGGABLE_POSITION> {draggablePosition: DraggablePosition}
export interface IBillboardFilters extends Action<AppActionTypes.BILLBOARD_FILTERS> {billboardFilters: BillboardFilters | undefined}
export interface IPropertyFilter extends Action<AppActionTypes.PROPERTY_FILTER> {propertyFilter: PropertyFilter}
export interface IDontSaleFilter extends Action<AppActionTypes.DONT_SALE_FILTER> {dontSaleFilter: boolean}

export type AppActions =
    | IChangePhoneControlStateAction
    | IChangeMenuStateAction
    | IChangeMapSettingAction
    | IChangeStatusCheckboxAction
    | IFilterPropertyBillboardsAction
    | IFilterPropertyBillboardsFormatAction
    | IFilterPropertyBillboardsSideAction
    | IFilterPropertyBillboardsLightAction
    | IFilterPropertyBillboardsTypeAction
    | IFilterPropertyBillboardsABAction
    | IAllResetFiltersAction
    | IDraggable
    | IDefaultPosition
    | IDefaultPositionDiscount
    | IPositionNextBillboard
    | IPositionBrandsCard
    | IPositionSearchObjects
    | IPositionSalesStatistics
    | IPositionAdditionalCost
    | IRollUpBrand
    | IPositionBillboardAdd
    | IDraggablePosition
    | IBillboardFilters
    | IPropertyFilter
    | IDontSaleFilter;

export function dontSaleFilterAction(dontSaleFilter: boolean) {
    const action: IDontSaleFilter = {
        type: AppActionTypes.DONT_SALE_FILTER,
        dontSaleFilter
    }
    return action;
}
export function propertyFilterAction(propertyFilter: PropertyFilter) {
    const action: IPropertyFilter = {
        type: AppActionTypes.PROPERTY_FILTER,
        propertyFilter
    }
    return action;
}

export function billboardFiltersAction(billboardFilters: BillboardFilters | undefined) {
    const action: IBillboardFilters = {
        type: AppActionTypes.BILLBOARD_FILTERS,
        billboardFilters
    }
    return action;
}

export function draggablePositionAction(draggablePosition: DraggablePosition) {
    const action: IDraggablePosition = {
        type: AppActionTypes.DRAGGABLE_POSITION,
        draggablePosition
    }
    return action;
}

export function positionBillboardAddAction(positionBillboardAdd: DefaultPosition) {
    const action: IPositionBillboardAdd = {
        type: AppActionTypes.POSITION_BILLBOARD_ADD,
        positionBillboardAdd
    }
    return action;
}

export function rollUpBrandAction(rollUpBrand: boolean) {
    const action: IRollUpBrand = {
        type: AppActionTypes.ROLL_UP_BRAND,
        rollUpBrand
    }
    return action;
}

export function positionAdditionalCostAction(positionAdditionalCost: DefaultPosition) {
    const action: IPositionAdditionalCost = {
        type: AppActionTypes.POSITION_ADDITIONAL_COST,
        positionAdditionalCost
    }
    return action;
}

export function positionSalesStatisticsAction(positionSalesStatistics: DefaultPosition) {
    const action: IPositionSalesStatistics = {
        type: AppActionTypes.POSITION_SALES_STATISTICS,
        positionSalesStatistics
    }
    return action;
}

export function positionSearchObjectsAction(positionSearchObjects: DefaultPosition) {
    const action: IPositionSearchObjects = {
        type: AppActionTypes.POSITION_SEARCH_OBJECTS,
        positionSearchObjects
    }
    return action;
}

export function positionBrandsCardAction(positionBrandsCard: DefaultPosition) {
    const action: IPositionBrandsCard = {
        type: AppActionTypes.POSITION_BRANDS_CARD,
        positionBrandsCard
    }
    return action;
}

export function positionNextBillboardAction(positionNextBillboard: DefaultPosition) {
    const action: IPositionNextBillboard = {
        type: AppActionTypes.POSITION_NEXT_BILLBOARD,
        positionNextBillboard
    }
    return action;
}

export function defaultPositionDiscountAction(defaultPositionDiscount: DefaultPosition) {
    const action: IDefaultPositionDiscount = {
        type: AppActionTypes.DEFAULT_POSITION_DISCOUNT,
        defaultPositionDiscount
    }
    return action;
}

export function defaultPositionAction(defaultPosition: DefaultPosition) {
    const action: IDefaultPosition = {
        type: AppActionTypes.DEFAULT_POSITION,
        defaultPosition
    }
    return action;
}

export function draggableAction(draggable: boolean) {
    const action: IDraggable = {
        type: AppActionTypes.DRAGGABLE,
        draggable
    }
    return action;
}

export function changePhoneControlStateAction() {
    const action: IChangePhoneControlStateAction = {
        type: AppActionTypes.CHANGE_PHONE_CONTROL_STATE
    }
    return action;
}

export function changeMenuStateAction() {
    const action: IChangeMenuStateAction = {
        type: AppActionTypes.CHANGE_MENU_STATE
    }
    return action;
}

export function changeMapSettingsAction(clusterZoomLevel: number, clusterMinPoints: number) {
    const action: IChangeMapSettingAction = {
        type: AppActionTypes.CHANGE_MAP_STTINGS,
        clusterZoomLevel,
        clusterMinPoints
    }
    return action;
}

export function statusCheckbox(reservedChecked: boolean, bookedChecked: boolean, soldChecked: boolean, paidChecked: boolean, freeChecked: boolean) {
    const action: IChangeStatusCheckboxAction = {
        type: AppActionTypes.CHANGE_STATUS_CHECKBOX,
        reservedChecked,
        bookedChecked,
        soldChecked,
        paidChecked,
        freeChecked
    }
    return action;
}

export function filterPropertyBillbords(constructionType: string) {
    const action: IFilterPropertyBillboardsAction = {
        type: AppActionTypes.FILTER_PROPERTY_BILLBOARDS,
        constructionType
    }
    return action;
}

export function filterPropertyBillbordsFormat(format: string) {
    const action: IFilterPropertyBillboardsFormatAction = {
        type: AppActionTypes.FILTER_PROPERTY_BILLBOARDS_FORMAT,
        format
    }
    return action;
}

export function filterPropertyBillbordsSide(selectedSide: string) {
    const action: IFilterPropertyBillboardsSideAction = {
        type: AppActionTypes.FILTER_PROPERTY_BILLBOARDS_SIDE,
        selectedSide
    }
    return action;
}

export function filterPropertyBillbordsLight(light: string) {
    const action: IFilterPropertyBillboardsLightAction = {
        type: AppActionTypes.FILTER_PROPERTY_BILLBOARDS_LIGHT,
        light
    }
    return action;
}

export function filterPropertyBillbordsType(billboardType: string) {
    const action: IFilterPropertyBillboardsTypeAction = {
        type: AppActionTypes.FILTER_PROPERTY_BILLBOARDS_TYPE,
        billboardType
    }
    return action;
}

export function filterPropertyBillbordsAB(billboardAB: string) {
    const action: IFilterPropertyBillboardsABAction = {
        type: AppActionTypes.FILTER_PROPERTY_BILLBOARDS_AB,
        billboardAB
    }
    return action
}

export function allResetFilters() {
    const region = store.getState().billboards.selectedAreaName;
    const city = store.getState().billboards.selectedCityName;
    const dontSaleFilter = store.getState().app.dontSaleFilter;
    const action: IAllResetFiltersAction = {
        type: AppActionTypes.ALL_RESET_FILTERS,
    }
    let eventKey = "";
    SystemEvent.SubscribeEventGetBillboardsShortInfo(
        "AdvancedFilter", 
        (answer) => {
            if(answer.eventKey === eventKey) {
                store.dispatch(actions.billboards.billboardsShortInfoAction(answer.billboards));
            }
        }, (error) => {
            errorCallback(error);
        }
    );
    const billboardFilters = {
        propertyValueIds: [],
        region: region ? (region === "Все" ? undefined : region) : undefined,
        city: city ? city : undefined,
        status: dontSaleFilter ? GeoObjectStatus.outOfService : GeoObjectStatus.used
    }
    store.dispatch(actions.app.billboardFiltersAction(billboardFilters));
    eventKey = SystemEvent.EventGetBillboardsShortInfo(billboardFilters);
    return action
}