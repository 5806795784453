import React, { Dispatch, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import { TableCell, TextField } from '@material-ui/core';
import { BrandGeoObjects } from '../../store/event/types';
import { GeoObjectType } from '../../store/event/types';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    geoObjectType: GeoObjectType;
    brandGeoObject: BrandGeoObjects;
}

const EditGeomarkName:React.FC<Props> = (props) => {
    const { 
        geoObjectType, 
        newBrandGeoObjects, 
        newBrandGeoObjectsAction,
        brandGeoObject
    } = props;
    const [editGeoMarkName, setEditGeoMarkName] = useState(false);
    const [geoMarkNameValue, setGeoMarkNameValue] = useState('');

    const geoMarkNameOpen = (geoMarkName: string) => {
        setEditGeoMarkName(true);
        setGeoMarkNameValue(geoMarkName);
    }

    const changeValue = (value: string) => {
        setGeoMarkNameValue(value);
        const idx = newBrandGeoObjects.findIndex((item) => item.GeoMarkId === brandGeoObject.GeoMarkId);
        const oldItem = newBrandGeoObjects[idx];
        const newItem = {...oldItem, GeoMarkName: value};
        const newBrandGeoObject = [
            ...newBrandGeoObjects.slice(0, idx),
            newItem,
            ...newBrandGeoObjects.slice(idx + 1)
        ];
        newBrandGeoObjectsAction(newBrandGeoObject);
    }
    
    return (
        <TableCell 
            component="th" 
            scope="row"
            className="geoMarkName"
            onClick={() => geoMarkNameOpen(brandGeoObject.GeoMarkName)}>
            {geoObjectType === GeoObjectType.newGeoObject ?
                <>
                    {!editGeoMarkName ? 
                        <span>
                            {brandGeoObject.GeoMarkName}
                            <CreateIcon />
                        </span>
                        : 
                        <TextField 
                            variant="outlined" 
                            value={geoMarkNameValue} 
                            size="small"
                            fullWidth
                            onChange={(event) => 
                                changeValue(event.target.value)}
                            autoFocus={editGeoMarkName}
                            onBlur={() => setEditGeoMarkName(false)}
                        />
                    }
                </>
            :
                <span>{brandGeoObject.GeoMarkName}</span>
            }
        </TableCell>
    );
}

const mapStateToProps = (state: RootState) => ({
    newBrandGeoObjects: state.event.newBrandGeoObjects
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    newBrandGeoObjectsAction: (brandGeoObjects: BrandGeoObjects[]) => 
        dispatch(actions.event.newBrandGeoObjectsAction(brandGeoObjects)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGeomarkName);