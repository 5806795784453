import React, { FunctionComponent } from 'react';
import TextFieldMaterial, { TextFieldProps } from '@material-ui/core/TextField';


const TextField: FunctionComponent<TextFieldProps> = (inputProps) => {
    return (
        <TextFieldMaterial {...inputProps}
            variant="outlined"
            margin="dense"
            InputLabelProps={{ shrink: true }}
        />);
};


export default TextField;