import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import moment from 'moment';
import { RequestProjectBooking, SandingWay } from '../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
    open: boolean;
    handleClose: () => void;
    projectId: number;
}
const sandingWay: string[] = ['Online', 'BySms', 'ByEmail','ByPhone','NotSend', 'Push'];

function BookingRequestModal(props: Props) {
    const { open, handleClose, projectId } = props;

    const [projectBooking, setProjectBooking] = useState<RequestProjectBooking>({
        projectId,
        bookingEndTime: '',
        controlDateTime: '',
        messageSendingWay: [SandingWay.Online],
    });

    useEffect(() => {
        SystemEvent.SubscribeEventRequestProjectBooking(
            "BookingRequestModal", 
            (answer) => {
                console.log("BookingRequestModal", answer)
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, []);

    const bookingChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectBooking({ ...projectBooking, bookingEndTime: event.target.value });
    }

    const controlChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectBooking({ ...projectBooking, controlDateTime: event.target.value });
    }

    const messageChange = (changeValue: SandingWay[]) => {
        setProjectBooking({ ...projectBooking, messageSendingWay: changeValue });
    }

    const projectBookingHandle = () => {
        const payload: RequestProjectBooking = {
            projectId,
            bookingEndTime: moment(projectBooking.bookingEndTime).format('YYYY-MM-DDTHH:mm:ss'),
            controlDateTime: moment(projectBooking.controlDateTime).format('YYYY-MM-DDTHH:mm:ss'),
            messageSendingWay: projectBooking.messageSendingWay
        };
        const builder = new EventBuilder(`EventRequestProjectBooking`);
            builder.AddParameter('ProjectId', payload.projectId);
            builder.AddParameter('BookingEndTime', payload.bookingEndTime);
            builder.AddParameter('ControlDateTime', payload.controlDateTime);
            builder.AddParameter('MessageSendingWay', payload.messageSendingWay.join(','));
        ServerProxy.SendSystemEvent(builder.GetSystemEvent());
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}>
            <DialogTitle>Отправить на согласование к директору</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Время окончания бронирования"
                            type="date"
                            value={projectBooking.bookingEndTime}
                            onChange={bookingChangeDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Контрольная дата"
                            type="date"
                            value={projectBooking.controlDateTime}
                            onChange={controlChangeDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            options={sandingWay}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            value={projectBooking.messageSendingWay}
                            onChange={(event: React.ChangeEvent<{}>, changeValue: SandingWay[]) => messageChange(changeValue)}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField 
                                    {...params} 
                                    label="Способ отправки сообщений" 
                                    fullWidth />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    Отмена
                </Button>
                <Button color="primary" autoFocus onClick={projectBookingHandle}>
                    Отправить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default BookingRequestModal;