import { CustomerState } from './types';
import { getInitialFilterState, getInitialSearchState } from './reducer';


export function getFoundCustomers(state: CustomerState, componentID: string) {
    const foundCustomersIDs = getSearchData(state, componentID).foundCustomersIDs;
    return state.customers.filter(customer => foundCustomersIDs.indexOf(customer.id) >= 0);
}


export function getCustomerByID(state: CustomerState, customerID: number) {
    return state.customers.find(c => c.id === customerID);
}


export function getCustomerByAccountID(state: CustomerState, accountID: number) {
    return state.customers.find(c => c.accountId === accountID);
}


export function getFoundCompanies(state: CustomerState, componentID: string) {
    const foundCompaniesIDs = getSearchData(state, componentID).foundCompaniesIDs;
    return state.companies.filter(company => foundCompaniesIDs.indexOf(company.id) >= 0);
}


// export function getAllCompanies(state: CustomerState) {
//     return state.companies;
// }


export function getCompanyByID(state: CustomerState, companyID: number) {
    return state.companies.find(c => c.id === companyID);
}


export function getCompanyByAccountID(state: CustomerState, accountID: number) {
    return state.companies.find(c => c.accountId === accountID);
}


function getSearchData(state: CustomerState, componentID: string) {
    const searchForComponent = state.search.find(s => s.componentID === componentID);
    
    return searchForComponent || getInitialSearchState();
}


export function getFilter(state: CustomerState, componentID: string) {
    const searchForComponent = getSearchData(state, componentID);
    return searchForComponent.filters;
}


export function getIsLoading(state: CustomerState, componentID: string) {
    const searchForComponent = getSearchData(state, componentID);
    return searchForComponent.isLoading;
}


export function getFoundByInn(state: CustomerState, componentID: string) {
    const searchForComponent = getSearchData(state, componentID);
    return searchForComponent.foundByInn;
}