import React, { Dispatch } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { BrandGeoObjects } from '../../store/event/types';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    deleteGeoObjectOpen: boolean;
    deleteGeoObjectClose: () => void;
    brandGeoObject: BrandGeoObjects;
}

const DeleteGeoObjectModal:React.FC<Props> = (props) => {
    const { 
        deleteGeoObjectOpen,  
        deleteGeoObjectClose,
        newBrandGeoObjects,
        brandGeoObject,
        newBrandGeoObjectsAction
    } = props;

    const deleteGeoObject = () => {
        const idx = newBrandGeoObjects.findIndex((item) => item.GeoMarkId === brandGeoObject.GeoMarkId);
        if(brandGeoObject.GeoMarkId > 0) {
            const builder = new EventBuilder(`EventDeleteBrandGeoObject`);
                builder.AddParameter('GeoMarkId', brandGeoObject.GeoMarkId);
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        } else {
            newBrandGeoObjectsAction([
                ...newBrandGeoObjects.slice(0, idx),
                ...newBrandGeoObjects.slice(idx + 1)
            ]);
        }
        deleteGeoObjectClose();
    }


    return (
        <Dialog
            open={deleteGeoObjectOpen}
            onClose={deleteGeoObjectClose}>
            <DialogTitle>Удалить {brandGeoObject.GeoMarkName}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы действительно хотите удалить {brandGeoObject.GeoMarkName}?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={deleteGeoObjectClose} color="primary">
                    Отмена
                </Button>
                <Button color="secondary" autoFocus onClick={deleteGeoObject}>
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state: RootState) => ({
    newBrandGeoObjects: state.event.newBrandGeoObjects,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    newBrandGeoObjectsAction: (newBrandGeoObjects: BrandGeoObjects[]) => 
        dispatch(actions.event.newBrandGeoObjectsAction(newBrandGeoObjects)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteGeoObjectModal);