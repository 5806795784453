import { Billboard, GeoMarkTariffs } from "../store/billboards/types";
import { ClientItem } from "../store/customers/types";
import { ClientInfo } from "../WebServerProxy";

const sortAlphabetically = (arr: Billboard[]) => {
    const billboardsSortAlphabetically = arr.sort((a: Billboard, b: Billboard) => (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) ? 1 : -1);
    return billboardsSortAlphabetically;
}

const sortAlphabeticallyClientInfo = (arr: ClientInfo[]) => {
    const billboardsSortAlphabetically = arr.sort((a: ClientInfo, b: ClientInfo) => (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) ? 1 : -1);
    return billboardsSortAlphabetically;
}

const sortAlphabeticallyClientItem = (arr: ClientItem[]) => {
    const billboardsSortAlphabetically = arr.sort((a: ClientItem, b: ClientItem) => (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) ? 1 : -1);
    return billboardsSortAlphabetically;
}

const sortAlphabeticallyString = (arr: string[]) => {
    const billboardsSortAlphabetically = arr.sort((a: string, b: string) => (a.trim().toLowerCase() > b.trim().toLowerCase()) ? 1 : -1);
    return billboardsSortAlphabetically;
}

const sortAlphabeticallyGeoMarkTariffs = (arr: GeoMarkTariffs[]) => {
    const geoMarkTariffsSortAlphabetically = arr.sort((a: GeoMarkTariffs, b: GeoMarkTariffs) => (a.side.trim().toLowerCase() > b.side.trim().toLowerCase()) ? 1 : -1);
    return geoMarkTariffsSortAlphabetically;
}

export { sortAlphabetically, sortAlphabeticallyString, sortAlphabeticallyClientInfo, sortAlphabeticallyClientItem, sortAlphabeticallyGeoMarkTariffs }