import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import WidgetToggleButton from './WidgetToggleButton';
import './WidgetPanel.scss'

type Props = {
    positionPanel: 'left' | 'right';
    settingsKey: string;
    hasActiveCall: boolean;
}

type State = {
    panelToggle: boolean;
}

class WidgetPanel extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.toggleClick = this.toggleClick.bind(this);

        this.state = {
            panelToggle: localStorage.getItem('settings-' + this.props.settingsKey) === 'true'
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { hasActiveCall, settingsKey } = this.props;
        if(hasActiveCall !== prevProps.hasActiveCall && settingsKey === 'phoneToggle') {
            this.setState({
                panelToggle: false
            })
        }
    }

    toggleClick() {
        localStorage.setItem('settings-' + this.props.settingsKey, (!this.state.panelToggle).toString())
        this.setState({
            panelToggle: !this.state.panelToggle
        })
    }

    position(positionPanel: string) {
        const { panelToggle } = this.state;
        const translateWidthLeft = !panelToggle ? 'translateX(0)' : 'translateX(-100%)';
        const translateWidthRight = !panelToggle ? 'translateX(0)' : 'translateX(100%)';

        if(positionPanel === 'left') {
            return translateWidthLeft
        } else if (positionPanel === 'right') {
            return translateWidthRight
        }
    }

    render() {
        const { positionPanel, settingsKey } = this.props;
        const { panelToggle } = this.state;
        return (
            <div className="widget-panel" style={{transform: this.position(positionPanel)}}>

                <WidgetToggleButton 
                    toggleClick={this.toggleClick} 
                    panelToggle={panelToggle}
                    positionPanel={positionPanel}
                    settingsKey={settingsKey} />
                <div>
                    {this.props.children}
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    hasActiveCall: state.sip.hasActiveCall
});

export default connect(mapStateToProps)(WidgetPanel)