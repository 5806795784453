import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions } from '../../store';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, TableFooter } from '@material-ui/core';
import { getMonthYearDate, monthDiff } from '../../utils/getMonthYear';
import { BillboardsAverageMonthUsage, IBillboardsUsageInfoItems } from '../../RemoteCommands/type';
import { DatesRange } from '../bricks/InputDatesRange';

type Props = ReturnType<typeof mapDispatchToProps>  & {
    billboardsUsageInfoResult: IBillboardsUsageInfoItems[],
    dates: DatesRange;
    isFixedSum: boolean;
    totalData: BillboardsAverageMonthUsage[];
};

const BillBoardsUsageInfo:React.FC<Props> = (props) => {
    const {
        billboardsUsageInfoResult,
        usageInfoAction,
        dates,
        isFixedSum,
        totalData
    } = props;

    let groups: Array<IBillboardsUsageInfoItems[]>  = Object.values(billboardsUsageInfoResult.reduce((r: any, a:IBillboardsUsageInfoItems) => {
        r[a.geoMarkId] = [...r[a.geoMarkId] || [], a];
        return r;
    }, {}));

    const result = billboardsUsageInfoResult.flatMap(a => a.items); 
    let maxCost: number = 0;
    for(let item of result) {
        if(item.usagePercent > maxCost) {
            maxCost = item.usagePercent
        }
    }

    const calcPercent = function(value: number, total: number) {
        return 100 * value / total;
    };

    const usagebBckground = (percent: number) => {
        let color =  "#ffffff";
        let newPercent: number = percent;
        if(isFixedSum) {
            newPercent = calcPercent(percent, maxCost)
        }
        if(newPercent >= 50) {
            return color = "#1de9b6";
        }
        if(newPercent >= 15) {
            return color = "#ffce67";
        }
        if(newPercent > 0) {
            return color = "#ffff00";
        }
        if(newPercent === 0) {
            return color = "#ffffff";
        }
        return color;
    }

    const monthCount = dates.DateFrom && dates.DateTo ? monthDiff(dates.DateFrom, dates.DateTo) + 1 : 1;

    return (
        <Paper>
        <TableContainer>
            <Table className="constructionStatisticsTable">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} rowSpan={2}>Адрес</TableCell>
                        <TableCell align="center" colSpan={monthCount}>% Загрузки</TableCell>
                    </TableRow>
                    <TableRow>
                        {getMonthYearDate(dates).map(item => {
                            const monthNumber = item.monthNumber > 9 ? item.monthNumber : '0' + item.monthNumber;
                            return (
                                <TableCell key={`month-${item.monthNumber}-${item.year}`}>
                                    {monthNumber}.{item.year}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map((group, i) => {
                        const geoMarkName = group[0].geoMarkName;
                        return (
                            group.map((billboard, billboardIndex) => {
                                return (
                                    <TableRow key={`billboardStatisticsTable-${geoMarkName + billboardIndex}`}>
                                        {billboardIndex === 0 && 
                                            <TableCell rowSpan={group.length} key={`billboardGeoMarkName-${geoMarkName + billboardIndex}`}>
                                                <span className="link"
                                                onClick={() => usageInfoAction(billboard)}>{geoMarkName}</span>
                                            </TableCell>
                                        }
                                        <TableCell 
                                            key={`projectsName-${billboard.gid}${billboardIndex}`}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {billboard.gid}
                                        </TableCell>
                                        {getMonthYearDate(dates).map((item, index) => {
                                            const findUsagePercent = billboard.items.find(usage => usage.month === item.monthNumber && usage.year.toString() === item.year);
                                            const usagePercent = findUsagePercent ? findUsagePercent.usagePercent : 0;
                                            const color = usagebBckground(usagePercent);
                                            return (
                                                <TableCell 
                                                    key={`billboard-usege-percent-${item.monthNumber + item.year + billboard.geoMarkId + index + i + billboard.gid}`} 
                                                    align="center"
                                                    style={{ backgroundColor: color }}>
                                                    {usagePercent}{isFixedSum ? 'р' : '%'}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })
                        )
                    })}
                </TableBody>
                {isFixedSum && totalData && 
                    <TableFooter>
                        <TableRow className="constructionStatisticsTable__total">
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            {getMonthYearDate(dates).map((item) => {
                                const findUsagePercent = totalData.find(usage => usage.month === item.monthNumber && usage.year.toString() === item.year);
                                const usagePercent = findUsagePercent ? findUsagePercent.usagePercent : 0;
                                return (
                                    <TableCell 
                                        key={item.month + item.year}
                                        style={{ cursor: "pointer" }}
                                        align="center">
                                        {usagePercent}р
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableFooter>
                }
            </Table>        
        </TableContainer>
        </Paper>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    usageInfoAction: (usageInfo: IBillboardsUsageInfoItems | null) => 
        dispatch(actions.event.usageInfoAction(usageInfo)),
});

export default connect(null, mapDispatchToProps)(BillBoardsUsageInfo);