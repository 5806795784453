import React, { FunctionComponent, Dispatch, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { sortAlphabeticallyString } from '../../utils/sortAlphabetically'
import { IGetAddress } from '../../api/AddressCacheAPI';
import ClearIcon from '@material-ui/icons/Clear';
import { IGetAddressItem } from 'sedi-webserverproxy';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { BillboardsShortInfo, EventGeoMarks } from '../../RemoteCommands/type';
import { IMultipleMarker } from '../map/types';
import AdvancedAutocomplete from './AdvancedAutocomplete';
import './bricks.scss';

type  Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    advancedSearch?: boolean | undefined;
    label: string;
    selectedMultipleMarker: (multipleMarker: IMultipleMarker) => void;
}

const AdvancedSearch: FunctionComponent<Props> = (props) => {
    const { 
        label, 
        billboardShortAction, 
        advancedSearch, 
        changeAreaName, 
        getAdditionalBillboardAction, 
        changeCityName,
        selectedAddressAction,
        regions,
        cities,
        eventGeoMarksAction,
        billboardsShortInfo,
        selectedMultipleMarker
    } = props;
    
    const [properties, setProperties] = useState({
        region: true,
        city: false,
        street: false,
        autocomplete: false
    });

    const [value, setValue] = useState('');

    useEffect(() => {
        SystemEvent.SubscribeEventGetGeoMarks(
            "AdvancedSearch", 
            (answer) => {
                eventGeoMarksAction(answer.geoMarks);
            }, (error) => {
                errorCallback(error);
            }
        );
    }, []);

    const options = () => {
        if(properties.region) {
            return sortAlphabeticallyString(regions.concat("Все"));
        } else if(properties.city) {
            return cities;
        } else {
            return billboardsShortInfo
        }
    }

    const filterBillboard = (geoMarks: BillboardsShortInfo[], value: string) => {
        const optionsArray: BillboardsShortInfo[] = geoMarks.filter(option => 
            (
                option.name.toLowerCase().indexOf(value.toLowerCase()) === 0 
                || option.gid.toLowerCase().indexOf(value.toLowerCase()) === 0
                || option.region.toLowerCase().indexOf(value.toLowerCase()) === 0
                || option.city.toLowerCase().indexOf(value.toLowerCase()) === 0
            ) || 
            (
                option.name.toLowerCase().indexOf(' ' + value.toLowerCase()) > 0
                || option.gid.toLowerCase().indexOf(' ' + value.toLowerCase()) > 0
                || option.region.toLowerCase().indexOf(' ' + value.toLowerCase()) > 0
                || option.city.toLowerCase().indexOf(' ' + value.toLowerCase()) > 0
            )
        );

        return optionsArray;
    }

    const filterCity = (cities: string[], value: string) => {
        const optionsArray: string[] = cities.filter(city => 
            city.toLowerCase().indexOf(value.toLowerCase()) === 0
            || city.toLowerCase().indexOf(' ' + value.toLowerCase()) > 0
        )
        return optionsArray;
    }

    const enterChange = (newValue: string) => {
        setValue(newValue);
        if(newValue.length === 0) {
            setProperties({
                region: true,
                city: false,
                street: false,
                autocomplete: false
            });
            changeAreaName('');
            changeCityName('');
        } else {
            const filterCities = filterCity(cities, newValue);
            if(filterCities.length > 0 && newValue.length <= 2) {
                setProperties({
                    region: false,
                    city: true,
                    street: false,
                    autocomplete: false,
                });
            } else {
                const filterBillboards = filterBillboard(billboardsShortInfo, newValue);
                if(filterBillboards.length === 0) {
                    setProperties({
                        region: false,
                        city: true,
                        street: false,
                        autocomplete: true
                    });
                } else {
                    setProperties({
                        region: false,
                        city: false,
                        street: true,
                        autocomplete: false
                    });
                }
            }
        }

        // if(newValue.length > 1) {
        //     const empty = eventGeoMarks.some((geoMark) => {
        //         const text = geoMark.name + " " + geoMark.region + " " + geoMark.city; 
        //         return  text.toLowerCase().search(newValue.toLowerCase()) != -1;
        //     });
        //     if(eventGeoMarks.length >= 200 || !empty || newValue.length === 0) {
        //         const geoMarksFilter = {
        //             text: newValue === "Все" ? "" : newValue
        //         }
        //         SystemEvent.EventGetGeoMarks(geoMarksFilter);
        //     } 
        // }
    }

    function isBillboardsShortInfo(obj: any): obj is BillboardsShortInfo {
        if(obj) {
            return obj.gid !== undefined 
        }
        return obj
    }

    const currentValueOnChange = (changeValue: BillboardsShortInfo | IGetAddress | string) => {
        if(isBillboardsShortInfo(changeValue)) {
            const billboardsShort = billboardsShortInfo.find(item => item.geoMarkId === changeValue.geoMarkId)
            billboardShortAction(billboardsShort)
            getAdditionalBillboardAction(changeValue.geoMarkId)
        } else {
            if(properties.region) {
                const value = changeValue as string;
                changeAreaName(value);
            } else if (properties.city) {
                changeCityName(changeValue as string);
            }
        }
    }

    const getOptionName = (option: EventGeoMarks) => {
        if(advancedSearch && advancedSearch === true) {
            return option as any
        } else {
            return option.name
        }
    }

    const renderItemName = (option: BillboardsShortInfo) => {
        return  (
            <span className="advanced-search-option">
                <span className="advanced-search-option__name">{option.name}</span>
                {advancedSearch ?   
                    <React.Fragment>
                        <span className="advanced-search-option__GID">{option.gid} </span>
                        <span className="advanced-search-option__AreaName">{option.region}</span>
                    </React.Fragment>
                : ''}
            </span>
        )
    }

    const renderItem = (option: BillboardsShortInfo) => {
        if(isBillboardsShortInfo(option)) {
            return renderItemName(option)
        } else {
            return renderItemAreaName(option as string);
        }
    }

    const fliterOptionsAdress = (options: any[], state: FilterOptionsState) => {
        if(properties.region) {
            return options.filter(option => option);
        } else if(properties.city) {
            const optionsArray: string[] = options.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) === 0 
                || option.toLowerCase().indexOf(' ' + value.toLowerCase()) > 0);
            return optionsArray;
        } else {
            return filterBillboard(options, value);
        }
    }

    const clearSelectedAddress = () => {
        enterChange("");
        selectedAddressAction(null);
        eventGeoMarksAction([]);
    }

    if(properties.autocomplete) {
        return (
            <AdvancedAutocomplete 
                selectedMultipleMarker={selectedMultipleMarker} 
                value={value}
                handleInputChange={enterChange} />
        )
    } else {
        return (
            <Autocomplete
                options={options()}
                getOptionLabel={option => getOptionName(option)}
                renderOption={option => renderItem(option)}
                value={value}
                inputValue={value}
                defaultValue={value}
                onChange={(event: React.ChangeEvent<{}>, changeValue: BillboardsShortInfo) => currentValueOnChange(changeValue)}
                filterOptions={(options: BillboardsShortInfo[], state: FilterOptionsState) => fliterOptionsAdress(options, state)}
                onInputChange={(event: React.ChangeEvent<{}>, value: string) => enterChange(value)}
                closeIcon={(
                    <ClearIcon 
                        fontSize="small" 
                        onClick={clearSelectedAddress} />
                )}
                renderInput={params => (
                    <TextField 
                        {...params} 
                        placeholder={label}
                        variant="outlined" 
                        fullWidth 
                        size="small" 
                        autoFocus={properties.street || properties.city} />
                )}
            />
        )
    }
};


const renderItemAreaName = (option: string) => {
    return  (
        <span className="advanced-search-option">
            <span className="advanced-search-option__name">{option}</span>
        </span>
    )
}

const mapStateToProps = (state: RootState) => ({
    regions: state.event.regions,
    cities: state.event.cities,
    eventGeoMarks: state.billboards.eventGeoMarks,
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    billboardFilters: state.app.billboardFilters,
    selectedCityName: state.billboards.selectedCityName
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    billboardShortAction: (newBillboard: BillboardsShortInfo | undefined) => dispatch(actions.billboards.billboardShortAction(newBillboard)),
    changeAreaName: (newSelectedAreaName: string) => dispatch(actions.billboards.changeAreaName(newSelectedAreaName)),
    changeCityName: (selectedCityName: string) => dispatch(actions.billboards.changeCityName(selectedCityName)),
    getAdditionalBillboardAction: (geoMarkId: number) => dispatch(actions.billboards.getAdditionalBillboardAction(geoMarkId)),
    selectedAddressAction: (selectedAddress: IGetAddressItem | null) => 
        dispatch(actions.event.selectedAddressAction(selectedAddress)),
    eventGeoMarksAction: (eventGeoMarks: EventGeoMarks[]) => 
        dispatch(actions.billboards.eventGeoMarksAction(eventGeoMarks))
});


export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch);