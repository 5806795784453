import { StateType } from 'typesafe-actions';
import rootReducer from './root-reducer';


import * as billboardsSelectors from './billboards/selectors';
import * as userSelectors from './user/selectors';
import * as appSelectors from './app/selectors';
import * as sipSelectors from './sip/selectors';
import * as customerSelectors from './customers/selectors';
import * as todoSelectors from './todo/selectors';

import * as billboardsActions from './billboards/actions';
import * as userActions from './user/actions';
import * as appActions from './app/actions';
import * as sipActions from './sip/actions';
import * as customerActions from './customers/actions';
import * as todoActions from './todo/actions';
import * as eventActions from './event/actions';
import * as billsActions from './bills/actions'

export { default } from './store';
export { default as rootReducer } from './root-reducer';

export const selectors = {
    billboards: billboardsSelectors,
    user: userSelectors,
    app: appSelectors,
    sip: sipSelectors,
    customers: customerSelectors,
    todo: todoSelectors,
};

export const actions = {
    billboards: billboardsActions,
    user: userActions,
    app: appActions,
    sip: sipActions,
    customers: customerActions,
    todo: todoActions,
    event: eventActions,
    bills: billsActions
}

export type RootState = StateType<typeof rootReducer>;
