import React from 'react';


type Props = {
    text: string,
    onClick: () => void
}


const Button: React.FunctionComponent<Props> = ({ text, onClick }) => {

    return (
        <button onClick={() => onClick()} className='btn btn-info btn-sm'>
            {text}
        </button>
    )
}


export default Button;