import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { List, Divider, ListItem, ListSubheader, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import { withLocalize, LocalizeContextProps } from "react-localize-redux";
import { RootState, selectors } from "../../../../store";
import { ClientInfo, GetCustomersFilter } from "sedi-webserverproxy";
import { mapStateToPropsLocalize } from "../../../../utils/store";
import { getWebServerProxy } from "../../../../WebServerProxy";
import Message from "../../../bricks/Message";
import EditIcon from '@material-ui/icons/Edit';
import { ConnectedClientModal } from "../../../../pages/ClientModal";


type OwnProps = {
    customerID: number,
    isLegalEntity: boolean,
    onChange: (newRelations: ClientInfo[]) => void,
    onDeleted: (deletedRelation: ClientInfo) => void,
    openCompanyCustomer: ClientInfo | undefined,
    companyCustomerModalClose: () => void
};


export type Props = LocalizeContextProps
    //& ReturnType<typeof mapDispatchToProps>
    & ReturnType<typeof mapStateToProps>
    & OwnProps;


const CustomerRelations: React.FC<Props> = ({ customerID, isLegalEntity, onChange, company, openCompanyCustomer, companyCustomerModalClose, onDeleted, ...otherProps }) => {

    //const [showSearch, setShowSearch] = useState(false);
    const [loadError, setLoadError] = useState('');    

    const initialCustomers: ClientInfo[] = [];
    if (isLegalEntity === false && company !== undefined) {
        initialCustomers.push(company);
    }
    const [customers, setCustomers] = useState(initialCustomers);
    const [clientForEdit, setClientForEdit] = useState(undefined as ClientInfo | undefined)


    useEffect(() => {
        if (customerID > 0 && isLegalEntity) {
            loadCustomersByCompany(customerID);
        }
    }, [renderCompanyClient(), customerID, isLegalEntity, clientForEdit, openCompanyCustomer]);


    const loadCustomersByCompany = async (companyID: number) => {
        const response = await getWebServerProxy().WebClient.GetCustomers({ companyId: companyID });
        if (response.Success) {
            setCustomers(response.Result || []);
            return;
        }

        setLoadError(response.Message || '');
    }


    const addRelation = (newRelation: ClientInfo) => {
        let newCustomerRelations;
        if (isLegalEntity) {
            newCustomerRelations = customers
                .filter(c => c.id !== newRelation.id)
                .concat([newRelation])
        }
        else {
            newCustomerRelations = [newRelation]
        };

        setCustomers(newCustomerRelations);
        onChange(newCustomerRelations);
    }

    const delRelation = (relationForDelete: ClientInfo) => {
        const newCustomerRelations = customers
            .filter(c => c.id !== relationForDelete.id);
        setCustomers(newCustomerRelations);
        onChange(newCustomerRelations);
        onDeleted(relationForDelete);
    }

    const headerNode = (
        <ListSubheader component="div" disableGutters className="pb-1" style={{background: '#fff'}}>
            {isLegalEntity ? 'Сотрудники' : 'Компания'}
        </ListSubheader>
    );

    useEffect(() => {
        if(customerID < 0) {
            setCustomers(initialCustomers);
        }
    }, [customerID])

    const customersNodes = customers.map(c => (
        <ListItem key={c.accountId}>
            <ListItemIcon>
                {isLegalEntity ? <AccountCircleIcon /> : <PeopleIcon />}
            </ListItemIcon>
            <ListItemText primary={c.name} />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => setClientForEdit(c)}>
                    <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => delRelation(c)}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    ))

    const canAddRelation = isLegalEntity || customers.length === 0;
    const fixedFilter: GetCustomersFilter = {
        isLegal: !isLegalEntity,
        orderFrom: 0,
        orderTo: 9999999,
        registrationDateFrom: new Date(1970, 1, 1),
        registrationDateTo: new Date(2999, 12, 31),
        isVip: null
    };    

    function headerNodes() {
        if(customersNodes.length !== 0) {
            return headerNode
        }
    }

    // var clientInfo = new ClientInfo();
    // clientInfo.IsLegalEntity = !isLegalEntity;

    function renderCompanyClient() {
        const listCompanyIfClient = document.querySelector('.list-companys-if-clients');
        if (listCompanyIfClient !== null) {
            const listCompanyIfClientHeight = listCompanyIfClient.clientHeight;
            return listCompanyIfClientHeight >= 120 && listCompanyIfClient.setAttribute("style", "overflow-y: scroll; height: 120px");
        };
    }

    return (
        <>
            {/* текст ошибка  */}
            {loadError &&
                <Message variant="error" message={loadError} />
            }

            {/* список компаний/клиентов */}
            <List component="nav" subheader={headerNodes()} disablePadding aria-label="main mailbox folders" className="list-companys-if-clients">
                {customersNodes}
                {customersNodes.length !== 0 && <Divider />}
            </List>

            {canAddRelation &&
                <>
                    {/* кнопки добавления */}
                    {/* <List component="nav" disablePadding aria-label="secondary mailbox folders">
                        <ListItem button onClick={() => setShowSearch(true)} data-test-locator="add-relation">
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary={isLegalEntity ? 'Добавить клиента' : 'Добавить компанию'} />
                        </ListItem>
                    </List> */}
                </>
            }

            {/* модаль поиска */}
            {/* <Dialog
                open={showSearchProps}
                onClose={() => showSearchProps}
                aria-labelledby="draggable-dialog-title"
                maxWidth="lg"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <SearchIcon />
                    {isLegalEntity ? 'Поиск клиентов' : 'Поиск компаний'}
                    <div className="small"><div className="small">Выберите двойным кликом</div></div>
                    <IconButton aria-label="delete" style={{ position: 'absolute', top: '5px', right: '5px' }}
                        onClick={() => closeSearchModal()}>
                        <CloseIcon />
                    </IconButton>                                        
                </DialogTitle>
                <DialogContent>
                    <CustomersList data-test-locator="customers-list"
                        onDoubleClickClient={(clientInfo) => { addRelation(clientInfo); closeSearchModal(); }}
                        componentID={`search-relations-${isLegalEntity}`} 
                        allowAddNewCustomer={false} 
                        fixedFilter={fixedFilter} />
                </DialogContent>
            </Dialog> */}

            {openCompanyCustomer && 
                <ConnectedClientModal
                    show={true}
                    clientInfo={openCompanyCustomer}
                    onClose={() => companyCustomerModalClose()}
                    addButtonHidden={true}
                    addCompanyClient= {(clientInfo) => addRelation(clientInfo)}
                    customerCompanyId={customerID}
                />
            }

            {clientForEdit !== undefined && 
                <ConnectedClientModal
                    show={true}
                    clientInfo={clientForEdit}
                    onClose={() => setClientForEdit(undefined)}
                    addButtonHidden={true}
                />
            }
            
        </>
    )
}


const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    const { customerID, isLegalEntity } = ownProps;
    let company: ClientInfo | undefined;

    if (customerID > 0) {
        if (isLegalEntity === false) {
            const customer = selectors.customers.getCustomerByID(state.customers, customerID);
            if (customer && customer.customerCompanyId !== null && customer.customerCompanyId > 0) {
                company = selectors.customers.getCompanyByID(state.customers, customer.customerCompanyId);
            }
        }
        //else {
        // ! для компании нужно произвести поиск сотруднитов из компонента, т.к. это асинхронная операция
        //}
    }

    return {
        ...mapStateToPropsLocalize(state),
        company,
    }
};


// const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
//     updateFilterState: (filter: GetCustomersFilter) => dispatch(actions.customers.updateCustomerFiltersAction(filter)),
//     searchClients: (filter: GetCustomersFilter) => dispatch(actions.customers.searchCustomersActionCreator(filter)),
// });


export default withLocalize(connect(
    mapStateToProps//, mapDispatchToProps
)(CustomerRelations));