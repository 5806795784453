import * as React from 'react';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RootState } from '../../store';
import { sortAlphabeticallyString } from '../../utils/sortAlphabetically';

type Props = ReturnType<typeof mapStateToProps> & {
    cityName: string | null | undefined;
    setCityName: React.Dispatch<React.SetStateAction<string | undefined | null>>;
    regionName: string | null | undefined;
};

function CityAutocomplete(props: Props) {
    const { citiesProps, cityName, setCityName, regionName } = props;

    // const filteredCity = billboards.filter(billboard => billboard.addressInfo ? 
    //     (regionName ? billboard.addressInfo.areaName ===  regionName : billboard.addressInfo.cityName) : '');
    // const propertyCityName = filteredCity.map(billboard => billboard.addressInfo ? billboard.addressInfo.cityName : '');
    // const onlyUniqueCity = [...new Set(propertyCityName)];
    const citiesSort = sortAlphabeticallyString(citiesProps);
    const cities = ["Все", ...citiesSort];

    React.useEffect(() => {
        if(regionName === null) {
            setCityName(null);
        }
    }, [regionName]);

    const handleChange = (value: string | null) => {
        setCityName(value);
    }

    return (
        <Autocomplete
            key={`cityName`}
            id="city"
            options={cities}
            getOptionLabel={(option) => option}
            value={cityName ? cityName : ""}
            onChange={(event: React.ChangeEvent<{}>, changeValue: string) => 
                handleChange(changeValue)}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label="Города" 
                    variant="outlined"
                    size="small" 
                    fullWidth
                />
            }
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    citiesProps: state.event.cities,
});

export default connect(mapStateToProps)(CityAutocomplete);