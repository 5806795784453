import { RemoteCommandJson, RemoteCommandName } from './RemoteCommandJson';
import { RemoteCommandSynchronizerCollection } from './RemoteCommandSynchronizerCollection';
import ServerService from './ServerService';
import store from "../store";
import * as billboardActions from '../store/billboards/actions';
import * as todoActions from '../store/todo/actions';
import * as customersActions from '../store/customers/actions';
import * as eventActions from '../store/event/actions';
import * as billsAction from '../store/bills/actions';
import { ActionFromBackend } from '../store/todo/types';
import { EventParser, EventBuilder } from '../utils/eventHelpers';
import { MessageToUser } from '../store/event/types';
import { onlyUnique } from '../utils/onlyUnique';
import { ServerProxy } from './ServerProxy';
import { EmployeeBrandsInfo, ProjectType } from '../WebServerProxy';
import { SystemEvent } from './SystemEvent';


class RemoteCommandHandler {

  public static Handle(commandJson: string) {
    console.log(commandJson);

    let command: RemoteCommandJson;
    try {
      command = RemoteCommandJson.parse(commandJson);
    }
    catch (err) {
      // TODO отправить системное событие о проблеме
      return;
    }

    if (command.IsResult) {
      RemoteCommandSynchronizerCollection.Stop(command.CommandId!, command);
      return;
    }
    RemoteCommandHandler.HandleCommand(command);
  }


  private static HandleCommand(command: RemoteCommandJson) {
    switch (command.Command) {
        case RemoteCommandName.Ping:
        RemoteCommandHandler.HandlePing(command);
        break;

        case RemoteCommandName.SendAction:
        RemoteCommandHandler.HandleSendAction(command);
        break;

        case RemoteCommandName.SendHandleEventResult:
            RemoteCommandHandler.handleEventResultAction(command);
            break;

        default:
        console.log("command handler not found : " + command.Command!);
    }
  }


    private static HandlePing(command: RemoteCommandJson) {
        ServerService.Ping();
    }


    private static HandleSendAction(command: RemoteCommandJson) {
        const todoAction = command.GetParameter<ActionFromBackend>('actionInfo');
        if (!todoAction) {
        // TODO отправить системное событие о проблеме
        return;
        }

        store.dispatch(todoActions.addTodoAction(todoAction));
    }

    private static handleEventResultAction(command: RemoteCommandJson) {
        const fullEvent = command.GetParameter<string>('eventName');
        if(fullEvent) {
            this.handleEventName(fullEvent);
            console.log('answer:', fullEvent);
        }
    }

    private static handleError = (event: string) => {
        let eventError: string;
        eventError = event.split(';')[2];
        if(event.split(';')[0] === "EventChangeProject") {
            store.dispatch(eventActions.changeProjectStatusAction(true));
        }
        if((eventError && eventError.length === 0) || eventError === undefined) {
            eventError = event.split(';')[1];
        }
        store.dispatch(eventActions.errorMessageAction(eventError));
    }

    private static handleEventName = (fullEvent: string) => {
        let event = fullEvent;
        if(fullEvent.includes('~')) {
            const action = fullEvent.split('~')[0];
            event = fullEvent.split('~')[1];
            if(action === 'error') {
                RemoteCommandHandler.handleError(event);
                return;
            }
        }
        const parser = new EventParser(event);
        const eventName = parser.GetEventName();

        store.dispatch(eventActions.eventNameAction(eventName));

        if(eventName === 'EventChangedCommercialOffer') {
            const projectID = store.getState().todo.projectID;
            const message = "Информация успешно сохранена";
            store.dispatch(eventActions.successMessageAction(message));
            SystemEvent.EventGetProjectInfo(projectID);
        }

        if(eventName === 'EventSendCommercialOfferToController') {
            const message = "Информация успешно передана для согласования директору";
            store.dispatch(eventActions.successMessageAction(message));
        }

        if(eventName === 'EventRequestProjectBooking') {
            const message = "Информация успешно передана для согласования директору";
            store.dispatch(eventActions.successMessageAction(message));
        }

        if(eventName === 'EventChangeProject') {
            store.dispatch(eventActions.loadingAction(false));
            const builder = new EventBuilder(`EventGetProjects`);
                builder.AddParameter('ProjectType', ProjectType.CommonProject);
            const clientItem = store.getState().customers.clientItem;
            const currentBrand = store.getState().billboards.currentBrandInfo;
            if(clientItem) {
                builder.AddParameter('OwnerAccountId', clientItem.accountId);
            }
            if(currentBrand) {
                builder.AddParameter('BrandId', currentBrand.brandId);
            }
            builder.AddParameterDate('Language', 'ru');
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }

        if(eventName === 'EventChangeProjectPeriod') {
            const projectPeriod = JSON.parse(parser.GetParameterValue('GeomarkTimeStatuses', true));
            const isOk = parser.GetParameterValue('IsOk', true);
            if(isOk === 'False') {
                store.dispatch(todoActions.projectPeriodAction(projectPeriod));
            } else {
                store.dispatch(todoActions.projectPeriodAction([]));
                const projectId = store.getState().todo.projectID;
                const currentStatuses = store.getState().billboards.billboadStatuses.filter(status => status.projectId === projectId);
                const bilbordIds: number[] = onlyUnique(currentStatuses.map(billboad => billboad.geoMarkId));
                const dateStart = store.getState().todo.dates.DateFrom;
                const dateEnd = store.getState().todo.dates.DateTo;
                const projects = store.getState().todo.projects;
                //@ts-ignore
                store.dispatch(billboardActions.changeBillboardStatusesActionCreator(projects.map(p => p.projectId)));
                if(dateStart && dateEnd) {
                    //@ts-ignore
                    store.dispatch(billboardActions.fetchBillboardStatusesActionCreator(bilbordIds, dateStart, dateEnd, true));
                }
            }
        }

        if(eventName === 'EventSetGeoMarkTariffs' || eventName === 'EventDeleteGeoMarkTariffs') {
            const { currentAgency } = store.getState().event;
            const { currentBrandInfo } = store.getState().billboards;
            const brandId = currentBrandInfo ? currentBrandInfo.brandId : 0;
            const builder = new EventBuilder(`EventGetGeoMarkTariffs`);
            builder.AddParameter('OwnerAccountId', currentAgency ? currentAgency.AccountId : 0);
            builder.AddParameter('BrandId', brandId);
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }

        if(eventName === 'EventUserGetFullYearStatistics') {
            const statusStatisticsFull = JSON.parse(parser.GetParameterValue('GeomarkTimeStatusFullYearStatistics', true));
            store.dispatch(eventActions.statusStatisticsFullAction(statusStatisticsFull));
        }
        if(eventName === 'EventUserGetYearStatistics') {
            const statusStatistics = JSON.parse(parser.GetParameterValue('GeomarkTimeStatusYearStatistics', true));
            store.dispatch(eventActions.statusStatisticsAction(statusStatistics));
        }
        if(eventName === 'EventChangedCommercialOffer') {
            const offerId = parser.GetParameterValue('OfferId', true);
            const offerTotalSumText = parser.GetParameterValue('OfferTotalSumText', true);
            const costWithDiscounts = JSON.parse(parser.GetParameterValue('CostWithDiscounts', true));
            store.dispatch(todoActions.commercialOfferIdAction(parseInt(offerId)));
            store.dispatch(todoActions.totalDiscountTextAction(offerTotalSumText));
            store.dispatch(todoActions.projectDiscountAction(costWithDiscounts));
            return;
        }
        if(eventName === 'EventGetCommercialOfferDiscounts') {
            const offerTotalSumText = parser.GetParameterValue('OfferTotalSumText', true);
            const costWithDiscounts = JSON.parse(parser.GetParameterValue('CostWithDiscounts', true));
            const additionalCost = parser.GetParameterValue('AdditionalCost', true);
            //console.log('EventGetCommercialOfferDiscountsAdditionalCost:', additionalCost);
            store.dispatch(todoActions.totalDiscountTextAction(offerTotalSumText));
            store.dispatch(todoActions.projectDiscountAction(costWithDiscounts));
            store.dispatch(eventActions.additionalCostAction(additionalCost));
            return;
        }
        if(eventName === 'EventUserGetCompanyInfo') {
            const officialAddress = JSON.parse(parser.GetParameterValue('OfficialAddress', true));
            const officeAddress = JSON.parse(parser.GetParameterValue('OfficeAddress', true));
            const postalAddress = JSON.parse(parser.GetParameterValue('PostalAddress', true));
            const agencyId = JSON.parse(parser.GetParameterValue('AgencyId', true));
    
            const companyInfo = {
                agencyId: agencyId,
                officialAddress: {
                    addressString: officialAddress.addressString
                },
                officeAddress: {
                    addressString: officeAddress.addressString
                },
                postalAddress: {
                    addressString: postalAddress.addressString
                }
            }
            store.dispatch(customersActions.companyInfoAction(companyInfo));
            return;
        }
        if(eventName === 'EventSendMessageToUser') {
            const messageText = parser.GetParameterValue('MessageText', false);
            const messageId = messageText.length + Math.floor(Math.random() * 1000);
            const messageToUser = new MessageToUser(messageId, messageText);
            store.dispatch(eventActions.messageToUserAction(messageToUser));
            return;
        }
        if(eventName === 'EventGetAgencies') {
            const agencyInfo = JSON.parse(parser.GetParameterValue('AgencyInfo', true));
            store.dispatch(eventActions.agencyInfoAction(agencyInfo));
        }
        if(eventName === 'EventChangeAgencyInfo') {
            store.dispatch(eventActions.addNewAgencyAction(true));
            const builder = new EventBuilder(`EventGetAgencies`);
                builder.AddParameter('AgencyIds', "");
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }
        if(eventName === 'EventGetGeoMarkTariffs') {
            const geoMarkTariffs = JSON.parse(parser.GetParameterValue('GeoMarkTariffs', true));
            // Нужно разобраться почему сделал таким образом
            if(geoMarkTariffs.length > 0 && geoMarkTariffs[0].ownerAccountId > 0) {
                store.dispatch(billboardActions.getGeoMarkTariffsOwnerAction(geoMarkTariffs));
            } else {
                store.dispatch(billboardActions.getGeoMarkTariffsAction(geoMarkTariffs));
            }
            // store.dispatch(billboardActions.getGeoMarkTariffsOwnerAction(geoMarkTariffs));
            store.dispatch(eventActions.loadingAction(false));

        }

        if(eventName === 'EventSetAdditionalTariffCosts') {
            const { projectBillboardIds } = store.getState().billboards;
            const { clientItem } = store.getState().customers;
            const { projectID } = store.getState().todo;

            if(clientItem) {
                const builderOwner = new EventBuilder(`EventGetGeoMarkTariffs`);
                    builderOwner.AddParameter('GeoMarkIds', JSON.stringify(projectBillboardIds));
                    builderOwner.AddParameter('OwnerAccountId', clientItem.accountId);
                ServerProxy.SendSystemEvent(builderOwner.GetSystemEvent());
            }
            const builder = new EventBuilder(`EventGetCommercialOfferDiscounts`);
                builder.AddParameter('ProjectId', projectID);
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }

        if(eventName === 'EventGetProjects') {
            const projectsInfo = JSON.parse(parser.GetParameterValue('ProjectsInfo', true));
            store.dispatch(todoActions.setAllProjects(projectsInfo));
            setTimeout(() => {
                store.dispatch(eventActions.loadingAction(false));
            }, 500)
        }
        if(eventName === 'EventGetProjectFilters') {
            const projectFilters = JSON.parse(parser.GetParameterValue('ProjectFilters', true));
            store.dispatch(todoActions.projectStatusesAction(projectFilters.ProjectStatuses));
            store.dispatch(todoActions.projectTypesAction(projectFilters.ProjectTypes));
            store.dispatch(todoActions.coworkersAction(projectFilters.Coworkers));
            store.dispatch(billsAction.billStatusesAction(projectFilters.BillStatuses));
        }
        if(eventName === 'EventGetBrands') {
            const { setBrandBoolean } = store.getState().event;
            const brands: EmployeeBrandsInfo[] = JSON.parse(parser.GetParameterValue('BrandsInfo', true));
            store.dispatch(billboardActions.brandsInfoAction(brands));
            if(setBrandBoolean) {
                let maxBrnadId = Math.max(...brands.map(item => item.brandId));
                const currentBrand = brands.find(item => item.brandId === maxBrnadId);
                if(currentBrand) {
                    store.dispatch(billboardActions.currentBrandInfoAction(currentBrand));
                    store.dispatch(eventActions.setBrandBooleanAction(false));
                }
            }
        }
        if(eventName === 'EventGetBrandGeoObjects') {
            const brandGeoObjects = JSON.parse(parser.GetParameterValue('BrandGeoObjects', true));
            store.dispatch(eventActions.brandGeoObjectsAction(brandGeoObjects));
        }
        if(eventName === 'EventSetBrandGeoObjects') {
            const { currentBrandInfo } = store.getState().billboards;
            const message = "Информация успешно сохранена";
            store.dispatch(eventActions.successMessageAction(message));
            if(currentBrandInfo) {
                const builder = new EventBuilder(`EventGetBrandGeoObjects`);
                    builder.AddParameter('BrandId', currentBrandInfo.brandId);
                    ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            }
            const builderBrands = new EventBuilder(`EventGetBrands`);
                ServerProxy.SendSystemEvent(builderBrands.GetSystemEvent());
        }
        if(eventName === 'EventDeleteBrandGeoObject') {
            const { currentBrandInfo } = store.getState().billboards;
            if(currentBrandInfo) {
                const builder = new EventBuilder(`EventGetBrandGeoObjects`);
                    builder.AddParameter('BrandId', currentBrandInfo.brandId);
                    ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            }
            const builderBrands = new EventBuilder(`EventGetBrands`);
                ServerProxy.SendSystemEvent(builderBrands.GetSystemEvent());
        }
        if(eventName === 'EventDeleteBrand') {
            const builderBrands = new EventBuilder(`EventGetBrands`);
                ServerProxy.SendSystemEvent(builderBrands.GetSystemEvent());
        }
        if(eventName === 'EventSetBrand') {
            const builder = new EventBuilder(`EventGetBrands`);
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            store.dispatch(eventActions.setBrandBooleanAction(true));
        }
        if(eventName === 'EventCreateProject') {
            const projectId = parser.GetParameterValue('ProjectId', true);
            const { projectFilter } = store.getState().todo;
            const { currentBrandInfo } = store.getState().billboards;
            const { clientItem } = store.getState().customers;
            const builder = new EventBuilder(`EventGetProjects`);
                builder.AddParameter('ProjectType', projectFilter.projectType);
                builder.AddParameterDate('ProjectStatus', projectFilter.projectStatus);
                builder.AddParameterDate('BrandId', currentBrandInfo ? currentBrandInfo.brandId : null);
                builder.AddParameterDate('OwnerAccountId', clientItem ? clientItem.accountId : null);
                builder.AddParameterDate('CreatorAccountId', projectFilter.coworker);
                builder.AddParameterDate('Language', 'ru');
            // if(dates.DateFrom) {
            //     const dateStart = moment(dates.DateFrom).format('yyyy-MM-DD HH:mm:ss');
            //     builder.AddParameterDate('DateStart', dateStart);
            // }
            // if(dates.DateTo) {
            //     const dateEnd = moment(dates.DateTo).format('yyyy-MM-DD HH:mm:ss');
            //     builder.AddParameterDate('DateEnd', dateEnd);
            // }
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            store.dispatch(todoActions.setActiveProjectActionCreator(parseInt(projectId)));
        }
    }
}


export default RemoteCommandHandler;


// MOCKs

const action1 = {
  "CommandId": "9a9a6548-b55c-414f-b629-d7059895c412",
  "IsResult": false,
  "Command": "SendAction",
  "CountParameters": 1,
  "Parameters": [{
    "Name": "actionInfo",
    "ValueJson": {
      "ActionName": "SetCustomerInformation",
      "Description": "Сохранение информации о клиенте.",
      "SequenceNumber": 1,
      "Items": [{
        "Text": "Вы можете дополнить или отредактировать информацию по юр. или физ. лицу звонящего",
        "Buttons": [{
          "Text": "Юр. лицо",
          "FrontendAction": "OpenCallerCompanyProfile"
        }, {
          "Text": "Физ. лицо",
          "FrontendAction": "OpenCallerCustomerProfile"
        }
        ]
      }
      ]
    }
  }
  ]
}

const action2 = {
  "CommandId": "b7144167-26fd-428b-9b95-d27b203b79c7",
  "IsResult": false,
  "Command": "SendAction",
  "CountParameters": 1,
  "Parameters": [{
    "Name": "actionInfo",
    "ValueJson": {
      "ActionName": "SetBoardInformationProject",
      "Description": "Сохранение информации о щитах выбранных клиентом.",
      "SequenceNumber": 2,
      "Items": [{
        "Text": "Вы можете приступить к резервированию рекламных щитов ... нажмите кнопку \"Завершено \"",
        "Buttons": [{
          "Text": "Завершено",
          "FrontendAction": "CompleteCurrentAction"
        }
        ]
      }
      ]
    }
  }
  ]
}

const action3 = '{"CommandId":"29451c04-a02e-48c8-849f-03d73f2ceb9a","IsResult":false,"Command":"SendAction","CountParameters":1,"Parameters":[{"Name":"actionInfo","ValueJson":"{\"ActionName\":\"FirstAction\",\"Description\":\"\",\"Header\":\"Быстрый доступ\",\"SequenceNumber\":0,\"Items\":[{\"Text\":\"\",\"Buttons\":[{\"Text\":\"Юр. лицо\",\"FrontendAction\":\"OpenCallerCompanyProfile\"},{\"Text\":\"Физ. лицо\",\"FrontendAction\":\"OpenCallerCustomerProfile\"},{\"Text\":\"Проекты\",\"FrontendAction\":\"OpenProjects\"}]}],\"ClearActions\":false}"}]}'


if (process.env.NODE_ENV === "development") {
    // RemoteCommandHandler.Handle(JSON.stringify(action1));
    // RemoteCommandHandler.Handle(JSON.stringify(action2));
    //   RemoteCommandHandler.Handle(JSON.stringify(action3))
}