import { TableCell, TableRow, TextField, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { GetGeoMarkTariff } from '../../store/billboards/types';
import { BillboardTariffs, GetGeoMarkTariffs } from 'sedi-webserverproxy';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { BillboardsShortInfo } from '../../RemoteCommands/type';

type Props = {
    billboardShort: BillboardsShortInfo | undefined;
    billboardTariff: BillboardTariffs;
    geoMarkTariffs: GetGeoMarkTariffs[];
};

export function GeoMarkTariffCell(props: Props) {
    const {
        billboardShort,
        billboardTariff,
        geoMarkTariffs
    } = props;

    const [editChecked, setEditChecked] = useState(false);
    const [tariffSate, setTariffState] = useState<BillboardTariffs>(billboardTariff);
    const [billboardTariffs, setBillboardTariffs] = useState<BillboardTariffs[]>(geoMarkTariffs[0].billboardTariffs);

    useEffect(() => {
        setBillboardTariffs(geoMarkTariffs[0].billboardTariffs);
    }, [geoMarkTariffs]);

    const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setTariffState(prev => ({...prev, ...{
            [event.target.name]: parseInt(event.target.value)
        }}));
    };

    function replaceItemAtIndex(arr: BillboardTariffs[] , index: number, newItem: BillboardTariffs) {
        return [...arr.slice(0, index), newItem, ...arr.slice(index + 1)];
    }
      

    useEffect(() => {
        const index = billboardTariffs.findIndex((listItem) => listItem.side === tariffSate.side);
        const newList = replaceItemAtIndex(billboardTariffs, index, {
            ...tariffSate,
            totalCost: tariffSate.totalCost,
        });
        setBillboardTariffs(newList);
    }, [tariffSate]);

    const saveClick = () => {
        const payload = {
            geoMarkTariffId: geoMarkTariffs[0].geoMarkTariffId,
            geoMarkId: geoMarkTariffs[0].geoMarkId,
            billboardTariffs: billboardTariffs,
            changeTime: new Date(),
            ownerAccountId: -1,
            brandId: 0,
        }
        const newGetGeoMarkTariff = new GetGeoMarkTariff(payload);

        SystemEvent.EventSetGeoMarkTariffs(newGetGeoMarkTariff);
        setEditChecked(false);
    } 

    if(editChecked) {
        return (
            <TableRow>
                <TableCell component="th" scope="row" style={{ width: 310 }}>{billboardShort && billboardShort.name}</TableCell>
                <TableCell align="center">{billboardTariff.side}</TableCell>
                <TableCell align="center">
                    <TextField 
                        value={tariffSate.totalCost} 
                        size="small" 
                        fullWidth 
                        name="totalCost"
                        onChange={changeInputHandler} />
                </TableCell>
                <TableCell align="center">
                    <Tooltip title="Сохранить" placement="top">
                        <CheckIcon 
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={saveClick} />
                    </Tooltip>
                    <Tooltip title="Отменить" placement="top">
                        <CloseIcon 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => setEditChecked(false)} />
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    } else {
        return (
            <TableRow>
                <TableCell component="th" scope="row" style={{ width: 310 }}>{billboardShort && billboardShort.name}</TableCell>
                <TableCell align="center">{billboardTariff.side}</TableCell>
                <TableCell align="center">{tariffSate.totalCost}</TableCell>
                <TableCell align="center">
                    <Tooltip title="Редактировать" placement="top">
                        <EditIcon 
                            fontSize="small" 
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={() => setEditChecked(true)} />
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }
};