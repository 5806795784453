import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { IDetailedEmployeeInfo, Phones } from '../../RemoteCommands/type';
import './EmployeeCallModal.scss';
import { Sip } from '../../sip/sip';
import { getTranslate, Translate } from 'react-localize-redux';
import { RootState, selectors } from '../../store';
import { connect } from 'react-redux';

type Props = ReturnType<typeof mapStateToProps> & {
    open: boolean;
    handleClose: () => void;
    employeeDetailInfo: IDetailedEmployeeInfo | null;
    loading: boolean;
    sipAccount: string | null;
};

function EmployeeCallModal(props: Props) {
    const {
        open,
        handleClose,
        employeeDetailInfo,
        loading,
        translate,
        sipNumber,
        phoneNumber,
        sipAccount
    } = props;

    const inputEl = useRef<HTMLInputElement>(null);
    const [phone, setPhone] = useState("");

    useEffect(() => {
        if(sipAccount) {
            setPhone(sipAccount);
        } else {
            if(!employeeDetailInfo) {
                return;
            }
            if(employeeDetailInfo.phones.length > 0) {
                setPhone(employeeDetailInfo.phones[0].number);
            }
        }
    }, [open]);

    const handleCallClose = () => {
        handleClose();
        Sip.HangUp();
    }

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    }

    const sipCallClick = () => {
        Sip.Call(sipNumber!, phone);
        if(inputEl && inputEl.current) {
            inputEl.current.focus();
        } 
        setPhone("");
    }

    const callBlock = () => {
        if (Sip.HasActiveCall) {
            return null;
        }

        return (
            <div className="input-group">
                <input
                    autoFocus={true} 
                    ref={inputEl}
                    type="text" 
                    className="form-control" 
                    placeholder={translate("SipPhone") as string} 
                    value={phone} 
                    onChange={handlePhoneChange} 
                />
                <div className="input-group-append">
                    <button 
                        className="btn btn-outline-success" 
                        type="button" 
                        onClick={sipCallClick}
                    >
                        <Translate id='SipCall'/>
                    </button>
                </div>
            </div>
            );
    }

    const activeCallBlock = () => {
        if (!Sip.HasActiveCall) {
            return null;
        }
        var answerButton = Sip.IsIncomingCall && !Sip.IsEstablished
            ? (<a href='#' className='btn btn-outline-success' style={{ padding: 3 }} onClick={() => Sip.Answer()
            }><img width="30" height="30" src='assets/img/call_accept.png' alt={translate('SipAnswer').toString()}/></a>)
            : null;

        var text = translate(Sip.IsIncomingCall ? "SipCallFrom" : "SipCallTo");
        if (Sip.IsEstablished)
            text = translate("SipInCallWith");

        return (
            <div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text"> {text} </span>
                        <span className="input-group-text">{phoneNumber}</span>
                    </div>
                    <div className="input-group-append" style={{height:38}} >
                        {answerButton}
                        <a 
                            href='#' 
                            className='btn btn-outline-danger' 
                            style={{ padding: 3 }} 
                            onClick={() => Sip.HangUp()}>
                            <img width="30" height="30" src='assets/img/call_refuse.png' alt={translate('SipHangUp').toString()} />
                        </a>
                    </div>
                </div>
            </div>);
    }

    const employeeCallView = () => {
        if(loading) {
            return (
                <div className="loading">
                    <CircularProgress />
                </div>
            )
        } else {
            if(!employeeDetailInfo) {
                return (
                    <DialogContentText>
                        Данные не подгрузились
                    </DialogContentText>
                )
            }
            return (
                <DialogContent>
                   {callBlock()}
                   {activeCallBlock()}
                </DialogContent>
            )
        }
    }

    const callOptionNumber = (phones: Phones[]) => {
        if(sipAccount) {
            return (
                <span
                    className="link"
                    onClick={() => setPhone(sipAccount)}>
                    {sipAccount}
                </span>
            )
        } else {
            return (
                phones.map((item, i) => (
                    <span
                        className="link"
                        onClick={() => setPhone(item.number)}
                        key={item.number + i}>
                        {item.number}
                    </span>
               ))
            )
        }
    }

    const employeeCallTitleView = () => {
        if(!employeeDetailInfo) {
            return;
        } else {
            return (
                <div className="employeeCallModal__title">
                    Номер: 
                    {callOptionNumber(employeeDetailInfo.phones)}
                </div>
            )
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleCallClose}
            className="employeeCallModal"
        >
            <DialogTitle>
                <IconButton 
                    style={{ position: 'absolute', top: '5px', right: '5px' }}
                    onClick={handleCallClose}>
                    <CloseIcon />
                </IconButton>
                {employeeCallTitleView()}
            </DialogTitle>
            {employeeCallView()}
            <DialogActions>
                <Button onClick={handleCallClose} color="primary">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        translate: getTranslate(state.localize),
        sipNumber: selectors.user.sipNumber(state.user),
        phoneNumber: selectors.sip.GetCallerPhoneNumber(state.sip),
    }
};

export default connect(mapStateToProps)(EmployeeCallModal);