import React, { Dispatch, useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';       
import { IconButton, CardHeader, Grid, Tooltip, Button, Menu, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import Draggable from 'react-draggable';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import { RollUpCard, SortOrder } from '../../store/event/types';
import RemoveIcon from '@material-ui/icons/Remove';
import AdditionalTariffsTable from './AdditionalTariffsTable';
import { AdditionalGeoMarkTariffs, TariffParameterConditions } from '../../RemoteCommands/type';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { uniqueAdditionalTariffs } from '../../utils/onlyUnique';
import _ from 'lodash';
import './AdditionalTariffs.scss';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function AdditionalTariffs(props: Props) {
    const {
        openAdditionalTariffAction,
        draggablePositions,
        draggablePositionAction,
        draggable,
        rollUpCards,
        rollUpCardAction,
        openAdditionalTariff,
        additionalTariffsAction,
        additionalGeoMarkTariffsAction,
        loadingAction,
        additionalGeoMarkTariffAction,
        additionalGeoMarkTariffs
    } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAddClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        loadingAction(true);
        SystemEvent.SubscribeEventGetTariffParameterConditions(
            "AdditionalTariffs", 
            (answer) => {
                additionalTariffsAction(answer.tariffParameterConditions);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventGetAdditionalGeoMarkTariffs(
            "AdditionalTariffs", 
            (answer) => {
                loadingAction(false);
                const orderStore: SortOrder = JSON.parse(localStorage.getItem('orderStore') || '{ "order":"asc","orderBy":"cost" }');
                let chars = answer.additionalGeoMarkTariffs;
                chars = _.orderBy(chars, [orderStore.orderBy],[orderStore.order]);
                additionalGeoMarkTariffsAction(chars);
            }, 
            (error) => {
                loadingAction(false);
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventChangeAdditionalGeoMarkTariff(
            "AdditionalTariffs", 
            (answer) => {
                SystemEvent.EventGetAdditionalGeoMarkTariffs();
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventDeleteAdditionalGeoMarkTariff(
            "AdditionalTariffs", 
            (answer) => {
                SystemEvent.EventGetAdditionalGeoMarkTariffs();
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.EventGetAdditionalGeoMarkTariffs();
        SystemEvent.EventGetTariffParameterConditions("AdvertisementServiceAdditionalCost");
    }, []);

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'AdditionalTariffs',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Доп стоимость",
            key: "AdditionalTariffs"
        });
    }

    const addGeoMarkTariffClick = (pricingType: string) => {
        const payload: AdditionalGeoMarkTariffs = {
            cost: "",
            parameters: [],
            pricingLocalization:  "",
            pricingType,
            tariffParameterId: -1
        }
        additionalGeoMarkTariffAction(payload);
        handleAddClose();
    }

    const handleClose = () => {
        openAdditionalTariffAction(false);
        additionalGeoMarkTariffAction(null);
    }

    const draggablePosition = draggablePositions.find(item => item.key === "AdditionalTariffs");
    const rollUpCardHas = rollUpCards.some(item => item.key === "AdditionalTariffs");
    const onlyUniqueTariffs = uniqueAdditionalTariffs(additionalGeoMarkTariffs);

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card
                className={`
                    selectedCard additionalTariffs ${openAdditionalTariff ? 'openSelected' : 'closeSelected'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                aria-label="settings" 
                                className="mt-1 ml-1"
                                onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <div className="box-title">Дополнительная стоимость</div>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    endIcon={<ExpandMoreIcon />}
                                    onClick={handleAddClick}
                                >
                                    Добавить стоимость
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleAddClose}>
                                    {onlyUniqueTariffs.map(item => (
                                        <MenuItem 
                                            key={`menuItem-${item.pricingType}`}
                                            onClick={() => addGeoMarkTariffClick(item.pricingType)}>
                                            {item.pricingLocalization}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="pt-0">
                    <AdditionalTariffsTable />
                </CardContent>
            </Card>
        </Draggable>
    );
};

const mapStateToProps = (state: RootState) => ({
    openAdditionalTariff: state.event.openAdditionalTariff,
    draggable: state.app.draggable,
    draggablePositions: state.app.draggablePositions,
    rollUpCards: state.event.rollUpCards,
    additionalGeoMarkTariffs: state.event.additionalGeoMarkTariffs
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    openAdditionalTariffAction: (openAdditionalTariff: boolean) => 
        dispatch(actions.event.openAdditionalTariffAction(openAdditionalTariff)),
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    additionalTariffsAction: (additionalTariffs: TariffParameterConditions[]) => 
        dispatch(actions.event.additionalTariffsAction(additionalTariffs)),
    additionalGeoMarkTariffsAction: (additionalGeoMarkTariffs: AdditionalGeoMarkTariffs[]) => 
        dispatch(actions.event.additionalGeoMarkTariffsAction(additionalGeoMarkTariffs)),
    loadingAction: (loading: boolean) => dispatch(actions.event.loadingAction(loading)),
    additionalGeoMarkTariffAction: (additionalGeoMarkTariff: AdditionalGeoMarkTariffs | null) => 
        dispatch(actions.event.additionalGeoMarkTariffAction(additionalGeoMarkTariff))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalTariffs);