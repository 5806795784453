import { GeoMarkStatus, ProjectGeoMarkStatus } from "sedi-webserverproxy";
import { getHighlightColor } from "./statusesColor";

export function  getHighlightStyleFunc (status: ProjectGeoMarkStatus | GeoMarkStatus) {
    const color =  getHighlightColor(status);
    if(status === GeoMarkStatus.Free) {
        return {
            'border': '1px solid #ccc',
            'backgroundColor': color
        }
    } else {
        return {
            'backgroundColor': color,
        };
    }
 }