import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../../store';
import { connect } from 'react-redux';
import ProjectAdditionalCost from './AdditionalCost';
import { RollUpCard } from '../../../store/event/types';
import { DefaultPosition } from '../../../store/app/types';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

export const AdditionalCostContainer:React.FC<Props> = (props) => {
    const {
        rollUpCards,
        rollUpCardAction,
        draggable,
        positionAdditionalCost,
        positionAdditionalCostAction,
        openAdditionalCostAction,
        openAdditionalCost,
        projects,
        projectID,
        disabledScoreSave,
        disabledEditAction
    } = props;

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        positionAdditionalCostAction(position);
    };
    
    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Дополнительная стоимость",
            key: "AdditionalCost"
        });
    }

    const cardCloseClick = () => {
        openAdditionalCostAction(false);
        disabledEditAction(false);
    }

    const rollUpCardHas = rollUpCards.some(item => item.key === "AdditionalCost");
    const findProject = projects.find(item => item.projectId === projectID);
    const projectName = findProject ? findProject.projectName : "";

    return (
        <ProjectAdditionalCost 
            defaultPosition={positionAdditionalCost} 
            draggable={draggable} 
            openAdditionalCost={openAdditionalCost}
            rollUpCardHas={rollUpCardHas}
            onStop={onStop}
            rolleUpClick={rolleUpClick} 
            cardCloseClick={cardCloseClick} 
            projectName={projectName} 
            projectID={projectID} 
            disabledScoreSave={disabledScoreSave} />
    );
}

const mapStateToProps = (state: RootState) => ({
    rollUpCards: state.event.rollUpCards,
    draggable: state.app.draggable,
    positionAdditionalCost: state.app.positionAdditionalCost,
    openAdditionalCost: state.todo.openAdditionalCost,
    projects: state.todo.projects,
    projectID: state.todo.projectID,
    disabledScoreSave: state.event.disabledScoreSave
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    positionAdditionalCostAction: (positionAdditionalCost: DefaultPosition) => 
        dispatch(actions.app.positionAdditionalCostAction(positionAdditionalCost)),
    openAdditionalCostAction: (openAdditionalCost: boolean) => 
        dispatch(actions.todo.openAdditionalCostAction(openAdditionalCost)),
    disabledEditAction: (disabledEdit: boolean) => 
        dispatch(actions.event.disabledEditAction(disabledEdit))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCostContainer);