import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import BUILD_PARAMS from '../../utils/build';
import { BillboardFilters, BillboardsShortInfo } from '../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import BillboardCard from '../BillboardCard/BillboardCard';
import { CircularProgress } from '@material-ui/core';
import MarkerBillboard from '../map/components/MarkerBillboard';
import { getMonthDates } from '../bricks/InputDatesRange';
import RollUpBtn from '../bricks/RollUpBtn';
import { RollUpCard } from '../../store/event/types';
import './ClientMap.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>

const ClientMap = (props: Props) => {
    const {
        billboardShort, 
        billboardShortAction, 
        rollUpCards,
        rollUpCardsAction
    } = props;
    const geoMarkId = new URLSearchParams(window.location.search).get("geoMarkId") || "";
    const imageType = new URLSearchParams(window.location.search).get("imageType") || "";
    const side = new URLSearchParams(window.location.search).get("side") || "";

    const [billboard, setBillboard] = useState<BillboardsShortInfo | undefined>(undefined);
    
    const eventKey = useRef<string>("");
    const [loading, setLoading] = useState(false);

    const defaultProps = {
        center: {
            lat: billboard ? billboard.geoPoint.latitude : 55.752256,
            lng: billboard ? billboard.geoPoint.longitude : 37.619345,
        },
        zoom: 16
    };

    useEffect(() => {
        SystemEvent.SubscribeEventGetBillboardsShortInfo(
            "ClientMap", 
            (answer) => {
                if(answer.eventKey === eventKey.current) {
                    setBillboard(answer.billboards[0]);
                    billboardShortAction(answer.billboards[0]);
                }
                setLoading(false);
            }, (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
        setLoading(true);
        const newbillboardFilters: BillboardFilters = {
            geoMarkIds: [+geoMarkId]
        }
        eventKey.current = SystemEvent.EventGetBillboardsShortInfo(newbillboardFilters);
    }, []);

    const openBillbaord = (billboard: BillboardsShortInfo) => () => {
        const idx = rollUpCards.findIndex((item) => item.key === "BillboardCard");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
        billboardShortAction(billboard);
    }

    if(loading) {
        return (
            <div className="loading full-height">
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="clientMap">
            {/* {billboard && 
                <GoogleMapReact
                    bootstrapURLKeys={{ key: BUILD_PARAMS.GOOGLE_MAPS_API_KEY || ''}}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals>
                        <MarkerBillboard 
                            lat={billboard.geoPoint.latitude} 
                            lng={billboard.geoPoint.longitude}
                            statuses={[]}
                            dates={getMonthDates()}
                            text={billboard.gid} 
                            key={'billboard-' + billboard.geoMarkId}
                            onClick={openBillbaord(billboard)}>
                        </MarkerBillboard>
                </GoogleMapReact>
            } */}
            {billboardShort && 
                <BillboardCard 
                    billboardShort={billboardShort} 
                    dates={getMonthDates()} 
                    geoMarkId={geoMarkId} 
                    imageType={imageType} 
                    initialSide={side}
                    notMoved={true} />
            }
            <div className="rollUpBtnRow">
                {rollUpCards.map(item => (
                    <span key={item.key}>
                        <RollUpBtn rollUpCard={item} />
                    </span>
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        billboardShort: state.billboards.billboardShort,
        rollUpCards: state.event.rollUpCards
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    billboardShortAction: (billboardShort: BillboardsShortInfo | undefined) => 
        dispatch(actions.billboards.billboardShortAction(billboardShort)),
    rollUpCardsAction: (rollUpCards: RollUpCard[]) => 
        dispatch(actions.event.rollUpCardsAction(rollUpCards)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientMap);