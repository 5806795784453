import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { IAdditionalCostParameters } from '../../../RemoteCommands/type';
import AdditionalCostTableRowContainer from '../AdditionalCostTableRow';
import ScoreConfirm from '../ScoreConfirm';
import { errorCallback, IEventGeneratePaymentBill, IEventSavePaymentBill, SystemEvent } from '../../../RemoteCommands/SystemEvent';
import { ProjectStatuses } from '../../../store/todo/types';
import { ProjectGeoMarkStatus } from 'sedi-webserverproxy';
import DeleteCostModal from './DeleteCostModal';

type Props = {
    additionalCostParameters: IAdditionalCostParameters[];
    additionalCostParametersAction: (additionalCostParameters: IAdditionalCostParameters[]) => void;
    projectID: number;
    currentGeoMarkStatus: ProjectStatuses;
    additionalCostNotSaved: boolean;
    disabledScoreSave: boolean;
    disabledScoreSaveAction: (disabledScoreSave: boolean) => void;
    disabledEditAction: (disabledEdit: boolean) => void;
}

const AdditionalCostTable:React.FC<Props> = (props) => {
    const { 
        additionalCostParameters,
        projectID,
        currentGeoMarkStatus,
        additionalCostNotSaved,
        disabledScoreSave,
        disabledScoreSaveAction,
        additionalCostParametersAction,
        disabledEditAction
    } = props;

    const [showAdditionalCostBtn, setShowAdditionalCostBtn] = useState(true);
    const [groupItems, setGrtoupItems] = useState<Array<IAdditionalCostParameters[]>>([])
    const [additionalCostLocalParametrs, setAdditionalCostLocalParametrs] = useState<IAdditionalCostParameters[]>([]);
    const [scoreOpen, setScoreOpen] = useState(false);
    const [generatePaymentBill, setGeneratePaymentBill] = useState<IEventGeneratePaymentBill | null>(null);
    const [savePaymentBill, setSavePaymentBill] = useState<IEventSavePaymentBill | null>(null);
    const [additionalNoCostItems, setAdditionalNoCostItems] = useState<IAdditionalCostParameters[] | undefined>(undefined);
    const [duplicateAddCost, setDuplicateAddCost] = useState<IAdditionalCostParameters | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const additionalCost = useRef<IAdditionalCostParameters | null>(null);

    const duplicateClick = (item: IAdditionalCostParameters) => {
        const id = additionalCostParameters.length + 1;
        const currentItem = {...item, id: -id};
        additionalCostParametersAction([
            currentItem,
            ...additionalCostParameters
        ]);
        setDuplicateAddCost(currentItem);
        disabledEditAction(true);
    }

    const deleteHandle = (item: IAdditionalCostParameters) => {
        additionalCost.current = item;
        setDeleteOpen(true);
    }

    const deleteCloseHandle = () => {
        setDeleteOpen(false);
    }

    const resetDuplicate = () => {
        setDuplicateAddCost(undefined);
    }

    const handleScoreClose = () => {
        setScoreOpen(false);
    }

    const scoreSaveHandler = (disabled: boolean) => {
        disabledScoreSaveAction(disabled);
    }

    useEffect(() => {
        SystemEvent.SubscribeEventSavePaymentBill(
            "AdditionalCostTable", 
            (answer) => {
                setSavePaymentBill(answer);
                SystemEvent.EventGetProjectAdditionalCostParameters(projectID);
                setLoading(false);
                setShowAdditionalCostBtn(true);
            }, 
            (error) => {
                errorCallback(error);
                setLoading(false);
            }
        );
    }, []);

    useEffect(() => {
        let group = additionalCostParameters.reduce((r: any, a:IAdditionalCostParameters) => {
            r[a.billId] = [...r[a.billId] || [], a];
            return r;
        }, {});
    
        const additionalCostItems = group['-1'];
        delete group['-1'];
        setAdditionalNoCostItems(additionalCostItems);
        const addAdditionalCostItems = Object.values(group).length > 0 ? Object.values(group)[0] as IAdditionalCostParameters[] : [];
        setGrtoupItems(Object.values(group));
        if(additionalCostItems && additionalCostItems.length > 0) {
            setAdditionalCostLocalParametrs(additionalCostItems);
            setShowAdditionalCostBtn(false);
        } else {
            setAdditionalCostLocalParametrs(
                addAdditionalCostItems.map((item: IAdditionalCostParameters) => ({
                    id: -1,
                    billId: -1,
                    condition: item.condition,
                    costLocalization: item.costLocalization,
                    costName: item.costName,
                    date: item.date,
                    itemCost: item.itemCost,
                    itemsCount: item.itemsCount,
                    measure: item.measure,
                    measureLocalization: item.measureLocalization,
                    parameter: item.parameter,
                    totalCost: item.totalCost,
                    month: item.month,
                    year: item.year
                }))
            );
        }
    }, [additionalCostParameters]);

    const addAdditionalCostClick = () => {
        setShowAdditionalCostBtn(false);
    }

    const saveScoreClick = () => {
        setLoading(true);
        if(additionalNoCostItems === undefined) {
            SystemEvent.EventSaveProjectAdditionalCosts(projectID, additionalCostLocalParametrs);
        }

        SystemEvent.EventSavePaymentBill(projectID);
    }

    const addAdditionalCostItem = () => {
        return (
            <TableContainer 
                component={Paper}
                className="tableContainer">
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Товары (работы, услуги)</TableCell>
                            <TableCell align="center" style={{width: 90}}>Кол-во</TableCell>
                            <TableCell align="center">Ед.</TableCell>
                            <TableCell align="center">Цена</TableCell>
                            <TableCell align="center">Сумма</TableCell>
                            <TableCell align="center" style={{width: 116}}>Дата</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {additionalCostLocalParametrs.map((item) => (
                            <TableRow 
                                key={item.id + item.billId}
                                style={costTableRowColor(item)}>
                                <AdditionalCostTableRowContainer 
                                    additionalCostItem={item} 
                                    additionalCostParameters={additionalCostLocalParametrs} 
                                    scoreSaveHandler={scoreSaveHandler} 
                                    duplicateClick={duplicateClick} 
                                    duplicateAddCost={duplicateAddCost} 
                                    resetDuplicate={resetDuplicate} 
                                    deleteHandle={deleteHandle} />
                            </TableRow>
                        ))}
                        {totalTableRow(additionalCostLocalParametrs)}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    };

    const totalTableRow = useCallback((groupItem: IAdditionalCostParameters[]) => {
        let totalCost = 0;
        for(let item of groupItem) {
            totalCost += item.totalCost
        }
        return (
            <TableRow>
                <TableCell><strong>Итого:</strong></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"><strong>{totalCost}</strong></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>
        );
    }, [additionalCostParameters]);

    const buttonGroup = () => {
        const showStatuses = 
            currentGeoMarkStatus.value === ProjectGeoMarkStatus.InEditMode
            || currentGeoMarkStatus.value === ProjectGeoMarkStatus.Reserved
            || currentGeoMarkStatus.value === ProjectGeoMarkStatus.Booked
            || currentGeoMarkStatus.value === ProjectGeoMarkStatus.New;
        if(showStatuses && !additionalCostNotSaved) {
            if(!showAdditionalCostBtn) {
                return (
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={saveScoreClick}
                        className="addAdditionalCost"
                        startIcon={
                            loading &&
                                <CircularProgress 
                                    color="secondary" 
                                    size={16} />
                        }
                        disabled={loading || disabledScoreSave}>
                        Сохранить счет
                    </Button>
                )
            }
            // if(showAdditionalCostBtn) {
            //     return (
            //         <Button 
            //             variant="contained" 
            //             color="primary"
            //             onClick={addAdditionalCostClick}
            //             className="addAdditionalCost">
            //             Добавить доп стоимость
            //         </Button>
            //     )
            // } else {
            //     return (
            //         <Button 
            //             variant="contained" 
            //             color="primary"
            //             onClick={saveScoreClick}
            //             className="addAdditionalCost"
            //             startIcon={
            //                 loading &&
            //                     <CircularProgress 
            //                         color="secondary" 
            //                         size={16} />
            //             }
            //             disabled={loading || disabledScoreSave}>
            //             Сохранить счет
            //         </Button>
            //     )
            // }
        }
    }

    return (
        <>
            {groupItems.map((groupItem, i) => (
                <span key={i}>
                    {groupItem[0].billId > -1 &&
                        <h6 className="pt-1 pb-1">Номер счета {groupItem[0].billId}</h6>
                    }
                    <TableContainer 
                        component={Paper}
                        className="tableContainer">
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Товары (работы, услуги)</TableCell>
                                    <TableCell align="center" style={{width: 90}}>Кол-во</TableCell>
                                    <TableCell align="center">Ед.</TableCell>
                                    <TableCell align="center">Цена</TableCell>
                                    <TableCell align="center">Сумма</TableCell>
                                    <TableCell align="center" style={{width: 116}}>Дата</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupItem.map((item) => (
                                    <TableRow 
                                        key={item.id + item.billId}
                                        style={costTableRowColor(item)}>
                                        <AdditionalCostTableRowContainer 
                                            additionalCostItem={item} 
                                            additionalCostParameters={additionalCostParameters} 
                                            duplicateClick={duplicateClick} 
                                            duplicateAddCost={duplicateAddCost} 
                                            resetDuplicate={resetDuplicate} 
                                            deleteHandle={deleteHandle} />
                                    </TableRow>
                                ))}
                                {totalTableRow(groupItem)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </span>
            ))}
            {buttonGroup()}
            {!showAdditionalCostBtn && addAdditionalCostItem()}
            {scoreOpen && generatePaymentBill &&
                <ScoreConfirm 
                    scoreOpen={scoreOpen}
                    handleScoreClose={handleScoreClose} 
                    generatePaymentBill={generatePaymentBill} />
            }
            {additionalCost.current &&
                <DeleteCostModal 
                    deleteOpen={deleteOpen}
                    deleteCloseHandle={deleteCloseHandle} 
                    additionalCost={additionalCost.current} />
            }
        </>
    );
}

export default AdditionalCostTable;

function costTableRowColor(item: IAdditionalCostParameters) {
    if(item.billId >= 0 && item.id >= 0) {
        return { backgroundColor: "rgb(40 167 69 / 10%)" }
    }
    if(item.id < 0) {
        return { backgroundColor: "rgba(0, 0, 0, 0.04)" } 
    }
    return {}
}