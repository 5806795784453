import { StatusStatisticsFull, StatusStatistics } from "../store/event/types";
import moment from 'moment';
import 'moment/locale/ru';
import { GeoMarkStatus, ProjectGeoMarkStatus } from "../WebServerProxy";
import { getHighlightColor } from "./statusesColor";
import { DatesRange } from "../components/bricks/InputDatesRange";
import { BillboardsShortInfo } from "../RemoteCommands/type";

export class MonthYear {
    public month: string;
    public monthNumber: number;
    public year: string;
    public color: string;
    public status: GeoMarkStatus;
    constructor(month: string, monthNumber: number, year: string, color: string, status: GeoMarkStatus) {
        this.month = month;
        this.monthNumber = monthNumber;
        this.year = year;
        this.color = color;
        this.status = status;
    }
}

export const getMonthYear = (statusStatisticsFull: StatusStatisticsFull[], statusStatistics: StatusStatistics[], selectedBillboard: BillboardsShortInfo | undefined) => {
    const result: MonthYear[] = [];
    const fromDate = new Date();
    let color = '#fff';
    if(selectedBillboard === undefined) {
        if(statusStatisticsFull.length === 0) {
            for(let i = 0; i < 12; i++) {
                const dateStart = moment(fromDate, "YYYY-MM-DD").add(i, 'month');
                result.push(new MonthYear(dateStart.format("MMM"), parseInt(dateStart.format('M')), dateStart.format("YYYY"), color, GeoMarkStatus._FreeOnlyForFrontend));
            }
        } else {
            for(let statistic of statusStatisticsFull) {
                let color = '#fff';
                if(statistic.Status === "Paid") {
                    color = getHighlightColor(ProjectGeoMarkStatus.Paid);
                } else if(statistic.Status === "Sold") {
                    color = getHighlightColor(ProjectGeoMarkStatus.Sold);
                }
                else if(statistic.Status === "Booked") {
                    color = getHighlightColor(ProjectGeoMarkStatus.Booked);
                }
                else if(statistic.Status === "Reserved") {
                    color = getHighlightColor(ProjectGeoMarkStatus.Reserved);
                }
                else {
                    color = getHighlightColor(ProjectGeoMarkStatus._FreeOnlyForFrontend);
                }
                const findMonth = result.find(res => res.month === statistic.Month.toString() && res.year === statistic.Year.toString());
                if(findMonth) {
                    if(statistic.Status === "Paid" && statistic.Count > 0) {
                        findMonth.color =  color;
                        findMonth.status = GeoMarkStatus.Paid;
                    } else if(statistic.Status === "Sold" && findMonth.status !== "Paid") {
                        findMonth.color =  color;
                        findMonth.status = GeoMarkStatus.Sold;
                    }
                    else if(statistic.Status === "Booked" && findMonth.status !== "Sold" && findMonth.status !== "Paid") {
                        findMonth.color =  color;
                        findMonth.status = GeoMarkStatus.Booked;
                    }
                    else if(statistic.Status === "Reserved" && findMonth.status !== "Booked" && findMonth.status !== "Sold" && findMonth.status !== "Paid") {
                        findMonth.color =  color;
                        findMonth.status = GeoMarkStatus.Reserved;
                    } 
                    // else if(statistic.Status !== "Reserved" && findMonth.status !== "Booked" && findMonth.status !== "Sold" && findMonth.status !== "Paid" && statistic.Count > 0) {
                    //     findMonth.color =  color;
                    //     findMonth.status = GeoMarkStatus._FreeOnlyForFrontend;
                    // }
                } else {
                    result.push(new MonthYear(statistic.Month.toString(), statistic.Month, statistic.Year.toString(), color, statistic.Status));
                }
            }
        }
    } else {
        if(statusStatistics.length === 0) {
            for(let i = 0; i < 12; i++) {
                const dateStart = moment(fromDate, "YYYY-MM-DD").add(i, 'month');
                result.push(new MonthYear(dateStart.format("MMM"), parseInt(dateStart.format('M')), dateStart.format("YYYY"), color, GeoMarkStatus._FreeOnlyForFrontend));
            }
        } else {
            for(let statistic of statusStatistics) {
                let color = '#fff';
                if(statistic.Status === "Paid") {
                    color = getHighlightColor(ProjectGeoMarkStatus.Paid);
                } else if(statistic.Status === "Sold") {
                    color = getHighlightColor(ProjectGeoMarkStatus.Sold);
                }
                else if(statistic.Status === "Booked") {
                    color = getHighlightColor(ProjectGeoMarkStatus.Booked);
                }
                else if(statistic.Status === "Reserved") {
                    color = getHighlightColor(ProjectGeoMarkStatus.Reserved);
                }
                else {
                    color = getHighlightColor(ProjectGeoMarkStatus._FreeOnlyForFrontend);
                }
                const findMonth = result.find(res => res.month === statistic.Month.toString() && res.year === statistic.Year.toString());
                if(findMonth) {
                    if(statistic.Status === "Paid") {
                        findMonth.color =  color;
                        findMonth.status = GeoMarkStatus.Paid;
                    } else if(statistic.Status === "Sold" && findMonth.status !== "Paid") {
                        findMonth.color =  color;
                        findMonth.status = GeoMarkStatus.Sold;
                    }
                    else if(statistic.Status === "Booked" && findMonth.status !== "Sold" && findMonth.status !== "Paid") {
                        findMonth.color =  color;
                        findMonth.status = GeoMarkStatus.Booked;
                    }
                    else if(statistic.Status === "Reserved" && findMonth.status !== "Booked" && findMonth.status !== "Sold" && findMonth.status !== "Paid") {
                        findMonth.color =  color;
                        findMonth.status = GeoMarkStatus.Reserved;
                    } else if(statistic.Status !== "Reserved" && findMonth.status !== "Booked" && findMonth.status !== "Sold" && findMonth.status !== "Paid") {
                        findMonth.color =  color;
                        findMonth.status = GeoMarkStatus._FreeOnlyForFrontend;
                    }
                } else {
                    result.push(new MonthYear(statistic.Month.toString(), statistic.Month, statistic.Year.toString(), color, statistic.Status));
                }
            }
        }
    }
    return result;
}

export const getMonthYearInterval = () => {
    const result = [];
    const fromDate = new Date();
    let color = '#fff';
    for(let i = 0; i < 12; i++) {
        const dateStart = moment(fromDate, "YYYY-MM-DD").add(i, 'month');
        result.push(new MonthYear(dateStart.format("MMM"), parseInt(dateStart.format('M')), dateStart.format("YYYY"), color, GeoMarkStatus._FreeOnlyForFrontend));
    }
    return result;
};

export const getMonthYearDate = (dates: DatesRange) => {
    const result = [];
    if(!dates.DateFrom || !dates.DateTo) {
        return []
    }
    const fromDate = dates.DateFrom;
    let color = '#fff';
    const monthCount = monthDiff(dates.DateFrom, dates.DateTo) + 1;
    for(let i = 0; i < monthCount; i++) {
        const dateStart = moment(fromDate, "YYYY-MM-DD").add(i, 'month');
        result.push(new MonthYear(dateStart.format("MMM"), parseInt(dateStart.format('M')), dateStart.format("YYYY"), color, GeoMarkStatus._FreeOnlyForFrontend));
    }
    return result;
};

export function monthDiff(dateStart: Date, dateEnd: Date) {
    var months;
    months = (dateEnd.getFullYear() - dateStart.getFullYear()) * 12;
    months -= dateStart.getMonth();
    months += dateEnd.getMonth();
    return months <= 0 ? 0 : months;
}