import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../../../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { ActionFromBackendButton } from '../../../../../../store/todo/types';
import { Button } from '@material-ui/core';
import { ServerProxy } from '../../../../../../RemoteCommands/ServerProxy';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    action: ActionFromBackendButton
}

const SendSystemEventButton: React.FC<Props> = ({ action, buttonNegativeAction, note, noteAction, eventNameAction }) => {
    //AdditionalText
    const sendSystemEventSave = async () => {
        const systemEventName = action.SystemEvent;
        if(systemEventName === 'EventPermissionToSendCommercialOffer') {
            const offerId = action.SystemEventParameters && action.SystemEventParameters.find(item => item.ParameterName === 'OfferId');
            const projectId = action.SystemEventParameters && action.SystemEventParameters.find(item => item.ParameterName === 'ProjectId');
            const resolution = action.SystemEventParameters && action.SystemEventParameters.find(item => item.ParameterName === 'Resolution');
            try {
                if(systemEventName && offerId && projectId && resolution) {
                    if(resolution.ParameterValue === 'PermissionResolutionNegative') {
                        const systemEvent = `${systemEventName};${offerId.ParameterValue};${projectId.ParameterValue};${resolution.ParameterValue};${note}`;
                        eventNameAction(systemEventName);
                        if(note) {
                            await ServerProxy.SendSystemEvent(systemEvent);
                            buttonNegativeAction(false);
                            noteAction('');
                        } else {
                            buttonNegativeAction(true);
                        }
                    } else {
                        const systemEvent = `${systemEventName};${offerId.ParameterValue};${projectId.ParameterValue};${resolution.ParameterValue};`;
                        await ServerProxy.SendSystemEvent(systemEvent);
                    }
                }
            } catch (e) {
                alert(`Ошибка: ${e.Message}`);
            }
        } else {
            let parametrs = '';
            if(action.SystemEventParameters) {
                for (var parametr of action.SystemEventParameters) {
                    parametrs = parametrs + ';' + parametr.ParameterValue;
                }
            }
            const systemEvent = `${systemEventName}${parametrs}`;
            await ServerProxy.SendSystemEvent(systemEvent);
        }
    }
    return (
        <Button 
            variant="contained" 
            color="primary"
            size="small"
            onClick={sendSystemEventSave}>
            {action.Text}
        </Button>
    );
}

const mapStateToProps = (state: RootState) => ({
    note: state.todo.note
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    buttonNegativeAction: (permissionResolutionNegative: boolean) => dispatch(actions.todo.buttonNegativeAction(permissionResolutionNegative)),
    noteAction: (note: string) => dispatch(actions.todo.noteAction(note)),
    eventNameAction: (eventName: string) => dispatch(actions.event.eventNameAction(eventName)),
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(SendSystemEventButton);