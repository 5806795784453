import { Reducer } from "redux";
import { BillsActions } from "./actions";
import { BillsActionsState, ReduxBillsActionsTypes } from "./types";

const defaultState: BillsActionsState = {
    billStatuses: []
}

const billsActionsReducer: Reducer<BillsActionsState, BillsActions> = (state = defaultState, action) => {
    switch (action.type) {
        case ReduxBillsActionsTypes.BILL_STATUSES:
            return {
                ...state,
                billStatuses: action.billStatuses
            };
        default:
            return state;
    }
}

export default billsActionsReducer;