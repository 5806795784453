import { Action, ActionCreator } from 'redux';
import { BillStatuses, ReduxBillsActionsTypes } from "./types";

export type BillsActions = IBillStatuses;

export function billStatusesAction(billStatuses: BillStatuses[]) {
    const action: IBillStatuses = {
        type: ReduxBillsActionsTypes.BILL_STATUSES,
        billStatuses
    }
    return action;
}

export interface IBillStatuses extends Action<ReduxBillsActionsTypes.BILL_STATUSES> {
    billStatuses: BillStatuses[],
}