import { AppActions, AppActionTypes } from "./actions";
import { AppState, DraggablePosition } from './types';
import { Reducer } from "redux";

const defaultState: AppState = {
    showPhoneControl:false,
    showFullMenu: true,
    clusterZoomLevel: 12,
    clusterMinPoints: 5,
    reservedChecked: true,
    bookedChecked: true,
    soldChecked: true,
    paidChecked: true,
    freeChecked: true,
    constructionType: '',
    format: '',
    selectedSide: '',
    light: '',
    billboardType: '',
    billboardAB: '',
    draggable: true,
    draggablePositions: [],
    defaultPosition: {
        x: 0,
        y: 0,
    },
    defaultPositionDiscount: {
        x: 600,
        y: 0,
    },
    positionNextBillboard: {
        x: 0,
        y: 0,
    },
    positionBrandsCard: {
        x: 0,
        y: 0,
    },
    positionSearchObjects: {
        x: 0,
        y: 0,
    },
    positionSalesStatistics: {
        x: 0,
        y: 0,
    },
    positionAdditionalCost: {
        x: 0,
        y: 0,
    },
    positionBillboardAdd: {
        x: 0,
        y: 0,
    },
    rollUpBrand: false,
    billboardFilters: undefined,
    propertyFilter: {},
    dontSaleFilter: false
};

const defaultStateGetStorage: AppState =  JSON.parse(localStorage.getItem('appReducer') || '{}');
const defaultStateNew = Object.assign({}, defaultState, defaultStateGetStorage);

const appReducerPrivate: Reducer<AppState, AppActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case AppActionTypes.DONT_SALE_FILTER:
            {
                return {
                    ...state,
                    dontSaleFilter: action.dontSaleFilter
                };
            }
        case AppActionTypes.DRAGGABLE_POSITION:
            {
                let draggablePositions: DraggablePosition[] = state.draggablePositions;
                if(state.draggablePositions.some(item => item.key === action.draggablePosition.key)) {
                    const idx = state.draggablePositions.findIndex((item) => item.key === action.draggablePosition.key);
                    const oldItem = state.draggablePositions[idx];
                    const newItem = {...oldItem, position: action.draggablePosition.position};
                    const newDraggablePositions = [
                        ...state.draggablePositions.slice(0, idx),
                        newItem,
                        ...state.draggablePositions.slice(idx + 1)
                    ];
                    draggablePositions = newDraggablePositions;
                } else {
                    draggablePositions.push(action.draggablePosition);
                }
                return {
                    ...state,
                    draggablePositions: draggablePositions
                };
            }
        case AppActionTypes.PROPERTY_FILTER:
            {
                return {
                    ...state,
                    propertyFilter: action.propertyFilter
                };
            }
        case AppActionTypes.BILLBOARD_FILTERS:
            {
                return {
                    ...state,
                    billboardFilters: action.billboardFilters
                };
            }
        case AppActionTypes.POSITION_BILLBOARD_ADD:
            {
                return {
                    ...state,
                    positionBillboardAdd: action.positionBillboardAdd
                };
            }
        case AppActionTypes.POSITION_ADDITIONAL_COST:
            {
                return {
                    ...state,
                    positionAdditionalCost: action.positionAdditionalCost
                };
            }
        case AppActionTypes.POSITION_SALES_STATISTICS:
            {
                return {
                    ...state,
                    positionSalesStatistics: action.positionSalesStatistics
                };
            }
        case AppActionTypes.ROLL_UP_BRAND:
            {
                return {
                    ...state,
                    rollUpBrand: action.rollUpBrand
                };
            }
        case AppActionTypes.POSITION_SEARCH_OBJECTS:
            {
                return {
                    ...state,
                    positionSearchObjects: action.positionSearchObjects
                };
            }
        case AppActionTypes.POSITION_BRANDS_CARD:
            {
                return {
                    ...state,
                    positionBrandsCard: action.positionBrandsCard
                };
            }
        case AppActionTypes.POSITION_NEXT_BILLBOARD:
            {
                return {
                    ...state,
                    positionNextBillboard: action.positionNextBillboard
                };
            }
        case AppActionTypes.DEFAULT_POSITION_DISCOUNT:
            {
                return {
                    ...state,
                    defaultPositionDiscount: action.defaultPositionDiscount
                };
            }
        case AppActionTypes.DEFAULT_POSITION:
            {
                return {
                    ...state,
                    defaultPosition: action.defaultPosition
                };
            }
        case AppActionTypes.DRAGGABLE:
            {
                return {
                    ...state,
                    draggable: action.draggable,
                };
            }
        case AppActionTypes.CHANGE_PHONE_CONTROL_STATE:
            {
                return {
                    ...state,
                    showPhoneControl: !state.showPhoneControl,
                };
            }
        case AppActionTypes.CHANGE_MENU_STATE:
            {
                return {
                    ...state,
                    showFullMenu: !state.showFullMenu,
                };
            }
        case AppActionTypes.CHANGE_MAP_STTINGS:
            {
                return {
                    ...state,
                    clusterZoomLevel: action.clusterZoomLevel,
                    clusterMinPoints: action.clusterMinPoints
                }
            }
        case AppActionTypes.CHANGE_STATUS_CHECKBOX:
            {
                return {
                    ...state,
                    reservedChecked: action.reservedChecked,
                    bookedChecked: action.bookedChecked,
                    soldChecked: action.soldChecked,
                    paidChecked: action.paidChecked,
                    freeChecked: action.freeChecked
                }
            }
        case AppActionTypes.FILTER_PROPERTY_BILLBOARDS:
            {
                return {
                    ...state,
                    constructionType: action.constructionType
                }
            }
        case AppActionTypes.FILTER_PROPERTY_BILLBOARDS_FORMAT:
            {
                return {
                    ...state,
                    format: action.format
                }
            }
        case AppActionTypes.FILTER_PROPERTY_BILLBOARDS_SIDE:
            {
                return {
                    ...state,
                    selectedSide: action.selectedSide
                }
            }
        case AppActionTypes.FILTER_PROPERTY_BILLBOARDS_LIGHT:
            {
                return {
                    ...state,
                    light: action.light
                }
            }
        case AppActionTypes.FILTER_PROPERTY_BILLBOARDS_TYPE:
            {
                return {
                    ...state,
                    billboardType: action.billboardType
                }
            }
        case AppActionTypes.FILTER_PROPERTY_BILLBOARDS_AB:
            {
                return {
                    ...state,
                    billboardAB: action.billboardAB
                }
            }
        case AppActionTypes.ALL_RESET_FILTERS:
            {
                return {
                    ...state,
                    billboardFilters: undefined,
                    propertyFilter: {}
                }
            }
        default:
            return state;
    }
};

export const appReducer: Reducer<AppState, AppActions> = (
    state = defaultStateNew, action) => {
        const newState = appReducerPrivate(state, action);
        localStorage.setItem('appReducer', JSON.stringify(newState));
        return newState;
    }

export default appReducer;