import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import Draggable from 'react-draggable';
import { Card, CardContent, CardHeader, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getMonthYearDate } from '../../utils/getMonthYear';
import { IBillboardsUsageInfoItems, IBillboardsUsageInfoResult, SalesPaymentStatisticFilter } from '../../RemoteCommands/type';
import { DatesRange } from '../bricks/InputDatesRange';
import { SystemEvent, errorCallback } from '../../RemoteCommands/SystemEvent';
import moment from 'moment';
import { GeoMarkStatus } from 'sedi-webserverproxy';
import { getHighlightColor } from '../../utils/statusesColor';
import { ClientItem } from '../../store/customers/types';
import { clientsArray } from '../../utils/clientsArray';
import RemoveIcon from '@material-ui/icons/Remove';
import { RollUpCard } from '../../store/event/types';
import DatePicker from "react-datepicker";
import FilterByCustomers from '../bricks/FilterByCustomers';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

const BillBoardDetailedUsageInfo:React.FC<Props> = (props) => {
    const {
        draggablePositions,
        draggablePositionAction,
        draggable,
        usageInfoAction,
        usageInfo,
        openSelectedBillboardsAction,
        setActiveProject,
        clientItemAction,
        companies, 
        customers, 
        agencyInfo,
        salesStatisticsToggleAction,
        rollUpCards,
        rollUpCardAction,
        reservedChecked, 
        bookedChecked, 
        soldChecked, 
        paidChecked,
        datesProps
    } = props;

    const [billBoardDetailedUsageInfo, setBillBoardDetailedUsageInfo] = useState<IBillboardsUsageInfoResult[]>([]);
    const [currentClients, setCurrentClients] = useState<ClientItem[]>([]);
    const [billboardCustomers, setBillboardCustomers] = useState<ClientItem[]>([]);

    const [dates, setDates] = useState<DatesRange>({
        DateFrom: datesProps.DateFrom,
        DateTo: datesProps.DateTo
    });

    const customerAccountIdChange = (client: ClientItem[]) => {
        setCurrentClients(client);
    }

    const dateFromChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateFrom: date ? date : undefined
        }));
    }

    const dateToChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateTo: date ? date : undefined
        }));
    }

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'BillBoardDetailedUsageInfo',
            position
        });
    };

    useEffect(() => {
        SystemEvent.SubscribeEventGetBillBoardDetailedUsageInfo(
            "EventGetBillBoardDetailedUsageInfo", 
            (answer) => {
                let billboardDetailedUsageResultSort = answer.billboardDetailedUsageResult;
                    billboardDetailedUsageResultSort.sort((a: IBillboardsUsageInfoResult, b: IBillboardsUsageInfoResult) => a.gid.localeCompare(b.gid));
                setBillBoardDetailedUsageInfo(billboardDetailedUsageResultSort);
            }, 
            (error) => errorCallback(error)
        );

        SystemEvent.SubscribeEventGetGeoMarkCustomers(
            "BillBoardDetailedUsageInfo", 
            (answer) => {
                setBillboardCustomers(answer.customers);
            }, 
            (error) => errorCallback(error)
        );
    }, []);

    useMemo(() => {
        if(usageInfo) {
            const salesPaymentStatisticFilter: SalesPaymentStatisticFilter = {
                Year: Number(moment(dates.DateFrom).format('YYYY')),
                Month: Number(moment(dates.DateFrom).format('M')),
                YearTo: Number(moment(dates.DateTo).format('YYYY')),
                MonthTo: Number(moment(dates.DateTo).format('M'))
            };
            const customerAccountIds = currentClients.map(item => item.accountId);
            SystemEvent.EventGetBillBoardDetailedUsageInfo(usageInfo.geoMarkId, salesPaymentStatisticFilter, customerAccountIds);

            SystemEvent.EventGetGeoMarkCustomers(usageInfo.geoMarkId, salesPaymentStatisticFilter);
        }
    }, [usageInfo, dates, currentClients]);

    const creatStatusName = () => {
        const statuses = [];
        //if(paidChecked) {
            statuses.push("Paid");
        //}
        //if(soldChecked) {
            statuses.push("Sold");
        //}
        //if(bookedChecked) {
            statuses.push("Booked");
        //}
        //if(reservedChecked) {
            statuses.push("Reserved");
        //}
        return statuses
    }

    const filterBillboardDetailInfo = () => {
        let resultFilter: IBillboardsUsageInfoResult[] = [];
        const statuses = creatStatusName();
        for(let billBoardDetailed of billBoardDetailedUsageInfo) {
            if(statuses.some(item => item === billBoardDetailed.status)) {
                resultFilter.push(billBoardDetailed);
            }
        }
        return resultFilter
    }

    let groups: Array<IBillboardsUsageInfoResult[]>  = Object.values(filterBillboardDetailInfo().reduce((r: any, a:IBillboardsUsageInfoResult) => {
        r[a.gid] = [...r[a.gid] || [], a];
        return r;
    }, {}));

    const usageStatusFunc = (status: string) => {
        let color =  "#ffffff";
        if(status === GeoMarkStatus.Paid) {
            return color = getHighlightColor(GeoMarkStatus.Paid);
        }
        if(status === GeoMarkStatus.Sold) {
            return color = getHighlightColor(GeoMarkStatus.Sold);
        }
        if(status === GeoMarkStatus.Booked) {
            return color = getHighlightColor(GeoMarkStatus.Booked);
        }
        if(status === GeoMarkStatus.Reserved) {
            return color = getHighlightColor(GeoMarkStatus.Reserved);
        }
        if(status === GeoMarkStatus.Free) {
            return color = getHighlightColor(GeoMarkStatus.Free);
        }
        return color;
    }

    const openProjectClick = (projectId: number, customerAccountId: number) => {
        const client = clientsArray(companies, customers, agencyInfo);
        const currentClientItem = client.find(item => item.accountId === customerAccountId);
        if(currentClientItem) {
            clientItemAction(currentClientItem);
            setActiveProject(projectId);
            openSelectedBillboardsAction(true);
            salesStatisticsToggleAction(false);
        }
    }

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Детальная информация",
            key: "BillBoardDetailedUsageInfo"
        });
    }

    const draggablePosition = draggablePositions.find(item => item.key === "BillBoardDetailedUsageInfo");
    const rollUpCardHas = rollUpCards.some(item => item.key === "BillBoardDetailedUsageInfo");

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card 
                className={`
                    selectedCard salesStatistics billBoardDetailedUsageInfo 
                    ${usageInfo ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="employees__close">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                className="mt-1 ml-1"
                                onClick={() => usageInfoAction(null)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                              <span className="billBoardDetailedUsageInfo__title">Детальная информация {usageInfo && usageInfo.geoMarkName}</span>
                            </Grid>
                            <Grid item>
                                <div className="chartDate">
                                    <DatePicker
                                        selected={dates.DateFrom}
                                        onChange={(date: Date | null) => dateFromChange(date)}
                                        selectsStart
                                        startDate={dates.DateFrom}
                                        endDate={dates.DateTo}
                                        dateFormat="MMMM yyyy"
                                        showMonthYearPicker
                                        isClearable
                                        locale="ru"
                                        placeholderText="Дата от"
                                    />
                                    <DatePicker
                                        selected={dates.DateTo}
                                        onChange={(date: Date | null) => dateToChange(date)}
                                        selectsStart
                                        startDate={dates.DateFrom}
                                        endDate={dates.DateTo}
                                        dateFormat="MMMM yyyy"
                                        isClearable
                                        showMonthYearPicker
                                        locale="ru"
                                        placeholderText="Дата до"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <FilterByCustomers 
                                    currentClients={currentClients}
                                    onChange={customerAccountIdChange}
                                    billboardCustomers={billboardCustomers} />
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="pt-0">
                    <TableContainer component={Paper}>
                        <Table stickyHeader className="billboardStatisticsTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell>GID</TableCell>
                                    <TableCell>Проект</TableCell>
                                    {getMonthYearDate(dates).map(item => {
                                        const monthNumber = item.monthNumber > 9 ? item.monthNumber : '0' + item.monthNumber;
                                        return (
                                            <TableCell key={`month-const-${item.monthNumber}-${item.year}`}>
                                                {monthNumber}.{item.year}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {groups.map((group, i) => {
                                const gid = group[0].gid;
                                return (
                                    group.map((billboard, billboardIndex) => {
                                        return (
                                            <TableRow key={`billboardStatisticsTable-${gid + billboardIndex + i}`}>
                                                {billboardIndex === 0 && 
                                                    <TableCell rowSpan={group.length}>{gid}</TableCell>
                                                }
                                                <TableCell 
                                                    key={`projectsName-${billboard.projectName}${billboardIndex}`}>
                                                    <span
                                                        className="link"
                                                        onClick={() => openProjectClick(billboard.projectId, billboard.customerAccountId)}>
                                                        {billboard.projectName}
                                                    </span>
                                                </TableCell>
                                                {getMonthYearDate(dates).map((item, index) => {
                                                    const period = billboard.periods.find(period => {
                                                        const currentDate = new Date(Number(item.year), item.monthNumber -1, 1);
                                                        const currentDateEnd = moment(currentDate).clone().endOf('month').toDate();
                                                        const dateStart = moment(period.dateStart, "YYYY-MM-DD").toDate();
                                                        const dateEnd = moment(period.dateEnd, "YYYY-MM-DD").toDate();
                                                        return (
                                                            (currentDate <= dateStart && currentDateEnd > dateStart)
                                                            || (currentDate <= dateEnd && currentDateEnd > dateEnd)
                                                            || (dateStart < currentDate && dateEnd > currentDateEnd)
                                                        )
                                                    });
                                                    if(period) {
                                                        const color = usageStatusFunc(billboard.status);
                                                        const dateStart = moment(period.dateStart).format('DD.MM.YYYY');
                                                        const dateEnd = moment(period.dateEnd).format('DD.MM.YYYY');
                                                        return (
                                                            <Tooltip placement="top" title={`${billboard.localizedStatus} c ${dateStart} до ${dateEnd}`}>
                                                                <TableCell 
                                                                    key={`billboard-usege-${item.monthNumber + item.year + billboard.customerAccountId + billboard.gid}`}
                                                                    style={{ backgroundColor: color, cursor: "pointer"}}>
                                                                </TableCell>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    return (
                                                        <Tooltip placement="top" title={"Свободно"}>
                                                            <TableCell 
                                                                key={item.monthNumber + index + item.year}
                                                                style={{ backgroundColor: "#ffffff", cursor: "pointer"}}>
                                                            </TableCell>
                                                        </Tooltip>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })
                                )
                            })}
                            </TableBody>
                        </Table>        
                    </TableContainer>
                </CardContent>
            </Card>
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        curretnSale: state.event.curretnSale,
        statisticsType: state.event.statisticsType,
        usageInfo: state.event.usageInfo,
        companies: state.customers.companies,
        customers: state.customers.customers,
        agencyInfo: state.event.agencyInfo,
        rollUpCards: state.event.rollUpCards,
        reservedChecked: state.app.reservedChecked,
        bookedChecked: state.app.bookedChecked,
        soldChecked: state.app.soldChecked,
        paidChecked: state.app.paidChecked,
        datesProps: state.todo.dates
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    usageInfoAction: (usageInfo: IBillboardsUsageInfoItems | null) => 
        dispatch(actions.event.usageInfoAction(usageInfo)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => 
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    clientItemAction: (clientItem: ClientItem) => 
        dispatch(actions.customers.clientItemAction(clientItem)),
    salesStatisticsToggleAction: (salesStatisticsToggle: boolean) => 
        dispatch(actions.event.salesStatisticsToggleAction(salesStatisticsToggle)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillBoardDetailedUsageInfo);