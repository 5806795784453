import React from 'react';
import { IconButton, TableCell, Tooltip } from '@material-ui/core';
import { IAdditionalCostParameters } from '../../../RemoteCommands/type';
import AdditionalCostFormControlContainer from '../AdditionalCostFormControl';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { ProjectStatuses } from '../../../store/todo/types';
import { ProjectGeoMarkStatus } from 'sedi-webserverproxy';
import DatePicker from "react-datepicker";
import { DatesRange } from '../../bricks/InputDatesRange';
import moment from 'moment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

type Props = {
    aditionalCostState: IAdditionalCostParameters;
    editChecked: boolean;
    editHandleClick: () => void;
    editHandleClose: () => void;
    saveProjectAdditionalCosts: () => void;
    changeInputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    currentGeoMarkStatus: ProjectStatuses;
    disabledEdit: boolean;
    dates: DatesRange;
    dateFromChange: (date: Date | null) => void;
    duplicateClick: (item: IAdditionalCostParameters) => void;
    deleteHandle: (item: IAdditionalCostParameters) => void;
}

const AdditionalCostTableRow:React.FC<Props> = (props) => {
    const {
        aditionalCostState,
        editChecked,
        editHandleClick,
        saveProjectAdditionalCosts,
        changeInputHandler,
        currentGeoMarkStatus,
        disabledEdit,
        dates,
        dateFromChange,
        editHandleClose,
        duplicateClick,
        deleteHandle
    } = props;

    if(editChecked) {
        return (
            <>
                <TableCell component="th" scope="row">
                    {aditionalCostState.costLocalization}
                </TableCell>
                <TableCell align="center">
                    <AdditionalCostFormControlContainer 
                        value={aditionalCostState.itemsCount} 
                        name="itemsCount" 
                        changeInputHandler={changeInputHandler} />
                </TableCell>
                <TableCell align="center">шт</TableCell>
                <TableCell align="center">
                    <AdditionalCostFormControlContainer 
                        value={aditionalCostState.itemCost} 
                        name="itemCost" 
                        changeInputHandler={changeInputHandler} />
                </TableCell>
                <TableCell align="center">
                    <AdditionalCostFormControlContainer 
                        value={aditionalCostState.totalCost} 
                        name="totalCost" 
                        changeInputHandler={changeInputHandler} />
                </TableCell>
                <TableCell align="center">
                    <DatePicker
                        selected={dates.DateFrom}
                        onChange={(date: Date | null) => dateFromChange(date)}
                        selectsStart
                        startDate={dates.DateFrom}
                        dateFormat="MM yyyy"
                        showMonthYearPicker
                        isClearable
                        locale="ru"
                        placeholderText="Дата"
                    />
                </TableCell>
                <TableCell align="center">
                    <div className="button-group ml-0">
                        <Tooltip title="Отмена" placement="top">
                            <IconButton 
                                size="small"
                                onClick={editHandleClose}>
                                <CloseIcon 
                                    fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Сохранить" placement="top">
                            <IconButton 
                                size="small"
                                onClick={saveProjectAdditionalCosts}>
                                <SaveIcon 
                                    fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </TableCell>
            </>
        )
    } else {
        return (
            <>
                <TableCell component="th" scope="row">
                    {aditionalCostState.costLocalization}
                </TableCell>
                <TableCell align="center">
                    {aditionalCostState.itemsCount}
                </TableCell>
                <TableCell align="center">шт</TableCell>
                <TableCell align="center">
                    {aditionalCostState.itemCost}
                </TableCell>
                <TableCell align="center">
                    {aditionalCostState.totalCost}
                </TableCell>
                <TableCell align="center">
                    {dateString(aditionalCostState.month, aditionalCostState.year)}
                </TableCell>
                <TableCell align="center">
                    {(
                        currentGeoMarkStatus.value === ProjectGeoMarkStatus.InEditMode
                        || currentGeoMarkStatus.value === ProjectGeoMarkStatus.Reserved
                        || currentGeoMarkStatus.value === ProjectGeoMarkStatus.Booked
                        || currentGeoMarkStatus.value === ProjectGeoMarkStatus.New
                    ) &&
                        <div className="button-group ml-0">
                            <Tooltip title="Редактировать" placement="top">
                                <IconButton 
                                    disabled={disabledEdit}
                                    size="small"
                                    onClick={editHandleClick}>
                                    <EditIcon 
                                        fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Дублировать" placement="top">
                                <IconButton 
                                    disabled={disabledEdit}
                                    size="small"
                                    onClick={() => duplicateClick(aditionalCostState)}>
                                    <FileCopyIcon 
                                        fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Удалить" placement="top">
                                <IconButton 
                                    disabled={disabledEdit}
                                    size="small"
                                    onClick={() => deleteHandle(aditionalCostState)}>
                                    <DeleteIcon 
                                        fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                </TableCell>
            </>
        );
    }
}

export default AdditionalCostTableRow;

function dateString(month: number, year: number) {
    if(month === 0 && year === 0) {
        return "";
    }
    return moment(new Date(year, month - 1, 1)).format("MMM yyyy")
}