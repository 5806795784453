import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { BillboardsShortInfo } from '../../RemoteCommands/type';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    billboardsShortInfo: BillboardsShortInfo[];
    selected: readonly number[];
    handleSelected: (newSelected: readonly number[]) => void;
};

const DontSaleCardTable = (props: Props) => {
    const { 
        billboardsShortInfo, dontSaleFilter, 
        billboardShortAction, selected, 
        handleSelected 
    } = props;

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];
    
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
    
        handleSelected(newSelected);
    };
    const isSelected = (geoMarkId: number) => selected.indexOf(geoMarkId) !== -1;

    return (
        <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Название</TableCell>
                        <TableCell align="center">GID</TableCell>
                        <TableCell align="center">Регион</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {billboardsShortInfo.map((item, index) => {
                        const isItemSelected = isSelected(item.geoMarkId);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <TableRow 
                                key={'dont-sale-' + item.geoMarkId}
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                selected={isItemSelected}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        onClick={(event) => handleClick(event, item.geoMarkId)}
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {dontSaleFilter ?
                                        <span 
                                            className="link"
                                            onClick={() => billboardShortAction(item)}>
                                            {item.name}
                                        </span>
                                        :
                                        item.name
                                    }
                                </TableCell>
                                <TableCell align="center">{item.gid}</TableCell>
                                <TableCell align="center">{item.region}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>        
        </TableContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    dontSaleFilter: state.app.dontSaleFilter
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    billboardShortAction: (billboardShort: BillboardsShortInfo | undefined) => 
        dispatch(actions.billboards.billboardShortAction(billboardShort))
});

export default connect(mapStateToProps, mapDispatchToProps)(DontSaleCardTable);