import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import moment from 'moment';
import { 
    CircularProgress,
    Paper,  
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow 
} from '@material-ui/core';
import ProjectStatusSelect from '../ProjectStatusSelect';
import EditProjectName from './EditProjectName';
import '../Projects.scss';
import EditProjectCreatorName from '../../bricks/EditProjectCreatorName';
import { Project } from 'sedi-webserverproxy';

type Props = ReturnType<typeof mapStateToProps> & {
    projects: Project[],
    statisticType?: boolean
}

const ProjectCardTable:React.FC<Props> = (props) => {
    const {
        projectStatuses,
        loading,
        projects,
        statisticType
    } = props;

    return (
        <TableContainer 
            component={Paper}
            className="tableContainer">
            {loading ? 
                <div className="loading">
                    <CircularProgress />
                </div>
            :
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: 250}}>Название проекта</TableCell>
                            <TableCell align="center">Компания/владелец</TableCell>
                            <TableCell align="center">Временной интервал</TableCell>
                            <TableCell align="center">Время создания</TableCell>
                            <TableCell align="center">Время последнего изменения</TableCell>
                            {!statisticType && 
                                <TableCell align="center" style={{width: 220}}>Создатель</TableCell>
                            }
                            <TableCell align="center">Статус проекта</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects.map((item) => {
                            const findGeoMarkStatus = projectStatuses.find(status => status.value === item.projectStatus);
                            return (
                                <TableRow key={item.projectId}>
                                    <EditProjectName 
                                        project={item} />
                                    <TableCell align="center">{item.companyName}</TableCell>
                                    <TableCell align="center">
                                        {moment(item.dateFrom).format("DD.MM.YYYY")}
                                        /{moment(item.dateTo).format("DD.MM.YYYY")}
                                    </TableCell>
                                    <TableCell align="center">{moment(item.createTime).format("DD.MM.YYYY")}</TableCell>
                                    <TableCell align="center">{moment(item.changeTime).format("DD.MM.YYYY")}</TableCell>
                                    {!statisticType && 
                                        <TableCell align="center">
                                            <EditProjectCreatorName 
                                                project={item} />
                                        </TableCell>
                                    }
                                    <TableCell align="right">
                                        {findGeoMarkStatus && 
                                            <ProjectStatusSelect 
                                                projectId={item.projectId} 
                                                surfacesCount={item.surfacesCount} 
                                                projectStatus={findGeoMarkStatus} 
                                            />
                                        }
                                        {/* {item.surfacesCount > 0 && findGeoMarkStatus ? 
                                            <ProjectStatusSelect 
                                                projectId={item.projectId} 
                                                surfacesCount={item.surfacesCount} 
                                                projectStatus={findGeoMarkStatus} 
                                            />
                                            : <span>{findGeoMarkStatus && findGeoMarkStatus.Localization}</span>
                                        } */}
                                    </TableCell>
                                </TableRow>
                            ) 
                        }).reverse()}
                    </TableBody>
                </Table>
            }
        </TableContainer>
    );
}

const mapStateToProps = (state: RootState) => ({
    projectStatuses: state.todo.projectStatuses,
    loading: state.event.loading
});


export default connect(
    mapStateToProps
)(ProjectCardTable);