import React, { FunctionComponent } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import TextField from './TextField';


type Props = TextFieldProps & {
    valueAsNumber?: number
};


const InputNumber: FunctionComponent<Props> = ({ valueAsNumber, ...inputProps }) => {
    return (
        <TextField {...inputProps}
            type="number"
            value={valueAsNumber || ''}
        />);
};


export default InputNumber;