import React, { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { AgencyInfo } from '../../store/event/types';
import { ClientItem, ClientType } from '../../store/customers/types';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    label: string;
    autoFocus: boolean;
}

const AgenciesAutocomplete: React.FC<Props> = (props) => {
    const { 
        label, 
        autoFocus, 
        agencyInfo, 
        currentAgencyAction, 
        currentAgency, 
        companyInfo, 
        addNewAgency,
        clientItemAction
    } = props;

    useEffect(() => {
        const builder = new EventBuilder(`EventGetAgencies`);
        builder.AddParameter('AgencyIds', "");
        ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        if(addNewAgency) {
            const lastAgencyInfo = agencyInfo[agencyInfo.length - 1];
            currentAgencyAction(lastAgencyInfo);
        }
    }, [agencyInfo.length]);

    useEffect(() => {
        const defaultAgency = agencyInfo.find(agency => agency.AgencyId === companyInfo.agencyId);
        if(defaultAgency) {
            currentAgencyAction(defaultAgency)
        }
    }, [companyInfo]);

    const changeAgency = (event: React.ChangeEvent<{}>, changeValue: AgencyInfo) => {
        currentAgencyAction(changeValue);
        if(changeValue) {
            const currentClientItem = new ClientItem(changeValue.AccountId, changeValue.AgencyName, ClientType.agency);
            clientItemAction(currentClientItem);
        }
    }

    return (
        <Autocomplete
            options={agencyInfo}
            getOptionLabel={(option: AgencyInfo) => option.AgencyName}
            value={currentAgency}
            onChange={(event: React.ChangeEvent<{}>, changeValue: AgencyInfo) => changeAgency(event, changeValue)}
            freeSolo
            filterOptions={(options: AgencyInfo[], state: FilterOptionsState) => fliterOptions(options, state)}
            renderInput={(params) => 
                <TextField 
                    {...params}
                    label={label} 
                    variant="outlined" 
                    fullWidth 
                    size="small" 
                    autoFocus={autoFocus}
                    InputLabelProps={{ shrink: true }} />
            }
        />
    )
}

const fliterOptions = (options: AgencyInfo[], state: FilterOptionsState) => {
    const optionsArray: AgencyInfo[] = options.filter(option => option.AgencyName.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 || option.AgencyName.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
    return optionsArray;
}

const mapStateToProps = (state: RootState) => ({
    agencyInfo: state.event.agencyInfo,
    currentAgency: state.event.currentAgency,
    companyInfo: state.customers.companyInfo,
    addNewAgency: state.event.addNewAgency,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    currentAgencyAction: (currentAgency: AgencyInfo) => dispatch(actions.event.currentAgencyAction(currentAgency)),
    clientItemAction: (clientItem: ClientItem) => dispatch(actions.customers.clientItemAction(clientItem))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesAutocomplete);