import { WebServerProxy, SystemEventName } from 'sedi-webserverproxy';
import BUILD_PARAMS from '../utils/build';
import { WebServerInitParams } from 'sedi-webserverproxy/dist/controllers/base/WebServerBase';
export * from 'sedi-webserverproxy';


const { WEBSERVER_URL, WEBSERVER_APIKEY, WEBSERVER_PORT, WEBAPI_URL } = BUILD_PARAMS;


let proxy: WebServerProxy;


export const getWebServerProxy = () => {
    return proxy;
}


export const initWebServerProxy = (userKey: string) => {
    const options: WebServerInitParams = {
        serverUrl: WEBSERVER_URL!,
        licenceKey: WEBSERVER_APIKEY!, 
        webApiUrl: WEBAPI_URL!, 
        port: WEBSERVER_PORT,
        userKey: userKey
    };

    proxy = new WebServerProxy(options);
}


export const sendEventActionComplete = (actionName: string) => {
    getWebServerProxy().WebClient.SaveSystemEvent(actionName + 'Complete' as SystemEventName, []);
}