import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { IGeoMark } from '../../RemoteCommands/type';
import moment from 'moment';
import { GeoMarkTimeStatus } from 'sedi-webserverproxy';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import MessageShackbar from '../bricks/MessageShackbar';

type Props = ReturnType<typeof mapStateToProps> & {
    consfirmOpen: boolean,
    consfirmClickClose: () => void,
    dateString: string;
    geoMarks: IGeoMark[];
    setGeoMarks: React.Dispatch<React.SetStateAction<IGeoMark[]>>;
    isDateStart: boolean;
    prevDate: string;
    setGeoMarkTimeStatusId: React.Dispatch<React.SetStateAction<number>>;
}

const CopyDateConfirm:React.FC<Props> = (props) => {
    const {
        consfirmOpen,
        consfirmClickClose,
        dateString,
        geoMarks,
        setGeoMarks,
        isDateStart,
        prevDate,
        currentGeoMarkStatus,
        projectID,
        setGeoMarkTimeStatusId
    } = props;

    const [statusError, setStatusError] = useState({
        message: "",
        error: false
    });

    const statusErrorClose = () => {
        setStatusError({
            message: "",
            error: false,
        })
    }

    const sendCopyDateClick = () => {
        const newDateString = moment(dateString, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss");
        const prevDateFormat = moment(prevDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss");
        let newGeoMarks = geoMarks.slice();
        let selectedGeoMarkTimeStatuses: GeoMarkTimeStatus[] = [];
        for(let geoMark of newGeoMarks) {
            const { geoMarkTimeStatuses } = geoMark;
            for(let geoMarkTimeStatus of geoMarkTimeStatuses) {
                if(isDateStart) {
                    const dateTimeStart = moment(geoMarkTimeStatus.dateTimeStart, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss");
                    if(dateTimeStart.includes(prevDateFormat)) {
                        geoMarkTimeStatus.dateTimeStart = newDateString;
                        selectedGeoMarkTimeStatuses.push({
                            geoMarkTimeStatusId: geoMarkTimeStatus.geoMarkTimeStatusId,
                            geoMarkId: geoMark.geoMarkId,
                            dateTimeStart: geoMarkTimeStatus.dateTimeStart,
                            dateTimeEnd: geoMarkTimeStatus.dateTimeEnd,
                            geoMarkStatus: currentGeoMarkStatus.value,
                            projectId: projectID,
                            geoMarkPart: geoMarkTimeStatus.geoMarkPart
                        })
                    }
                } else {
                    const dateTimeEnd = moment(geoMarkTimeStatus.dateTimeEnd, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss");
                    if(dateTimeEnd.includes(prevDateFormat)) {
                        geoMarkTimeStatus.dateTimeEnd = newDateString;
                        selectedGeoMarkTimeStatuses.push({
                            geoMarkTimeStatusId: geoMarkTimeStatus.geoMarkTimeStatusId,
                            geoMarkId: geoMark.geoMarkId,
                            dateTimeStart: geoMarkTimeStatus.dateTimeStart,
                            dateTimeEnd: geoMarkTimeStatus.dateTimeEnd,
                            geoMarkStatus: currentGeoMarkStatus.value,
                            projectId: projectID,
                            geoMarkPart: geoMarkTimeStatus.geoMarkPart
                        })
                    }
                }
            }
        }
        setGeoMarks(newGeoMarks);
        consfirmClickClose();
        if(selectedGeoMarkTimeStatuses.length > 0) {
            SystemEvent.EventSetGeoMarkTimeStatus(selectedGeoMarkTimeStatuses, false);
        } else {
            setStatusError({
                message: "Нет никаких совпадений",
                error: true
            });
        }
        setGeoMarkTimeStatusId(-1);
    }

    return (
        <>
            <Dialog
                open={consfirmOpen}>
                <DialogTitle>Копировать дату {dateString}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы действительно хотите поменять все даты {dateString}? 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={consfirmClickClose} color="secondary">
                        Нет
                    </Button>
                    <Button color="primary" autoFocus onClick={sendCopyDateClick}>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
            <MessageShackbar 
                message={statusError.message}
                variant="error"
                messageOpen={statusError.error} 
                vertical="top"
                horizontal="center" 
                messageShackbarClose={statusErrorClose} />
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
    projectID: state.todo.projectID
});

export default connect(mapStateToProps)(CopyDateConfirm);