import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { FullMonthStatistic } from '../../RemoteCommands/type';
import './Tooltip.scss';

type Props = ReturnType<typeof mapStateToProps> & {
    billboardIds: number[]
}

export class ClusterBillboardGroup {
    public total = 0;
    public reserved = 0;
    public booked = 0;
    public sold = 0;
    public paid = 0;
    public get free(): number {
        return this.total //- this.booked - this.paid;
    }
    constructor(total: number, reserved: number, booked: number, sold: number, paid: number) {
        this.total = total;
        this.reserved = reserved;
        this.booked = booked;
        this.sold = sold;
        this.paid = paid;
    }
}

const TooltipContent: React.FunctionComponent<Props> = (props) => {
    const { billboardIds, fullMonthStatistic } = props;

    return getClusterBillboards(fullMonthStatistic, billboardIds);
}

const getClusterBillboards = (fullMonthStatistic: FullMonthStatistic[], billboardIds: number[]) => {
    const clusterStatistics = fullMonthStatistic.filter(s => billboardIds && billboardIds.indexOf(s.geoMarkId) >= 0);

    // const billboardGroup: { [id: number] : ClusterBillboardGroup } = {};
    
    let freeBoardCount = 0;
    let freeSideCount = 0;
    let reservedBoardCount = 0;
    let reservedSideCount = 0;
    let bookedBoardCount = 0;
    let bookedSideCount = 0;
    let soldBoardCount = 0;
    let soldSideCount = 0;
    let paidBoardCount = 0;
    let paidSideCount = 0;

    for (let statistic of clusterStatistics) {
        const reservedCount = statistic.statuses.find(item => item.status === 'Reserved')!.sideCount;
        const bookedCount = statistic.statuses.find(item => item.status === 'Booked')!.sideCount;
        const soldCount = statistic.statuses.find(item => item.status === 'Sold')!.sideCount;
        const paidCount = statistic.statuses.find(item => item.status === 'Paid')!.sideCount;
        const freeCount = statistic.statuses.find(item => item.status === 'Free')!.sideCount;
        // const clusterBillboard = billboards.find(billboard => billboard.id === id) as Billboard;
        // const reservedCount = clusterStatuses.filter(status => status.geoMarkId === id && status.geoMarkStatus === 'Reserved').length;
        // const bookedCount = clusterStatuses.filter(status => status.geoMarkId === id && status.geoMarkStatus === 'Booked').length;
        // const soldCount = clusterStatuses.filter(status => status.geoMarkId === id && status.geoMarkStatus === 'Sold').length;
        // const paidCount = clusterStatuses.filter(status => status.geoMarkId === id && status.geoMarkStatus === 'Paid').length;
        //const total = 12 //clusterBillboard.sideACount + clusterBillboard.sideBCount;
        // billboardGroup[id] = new ClusterBillboardGroup(total, reservedCount, bookedCount, soldCount, paidCount);
        
        freeSideCount += freeCount;
        if(freeCount > 0) {
            freeBoardCount++
        }

        reservedSideCount += reservedCount;
        if(reservedCount > 0) {
            reservedBoardCount++ 
        }

        bookedSideCount += bookedCount;
        if(bookedCount > 0) {
            bookedBoardCount++ 
        }

        soldSideCount += soldCount;
        if(soldCount > 0) {
            soldBoardCount++ 
        }

        paidSideCount += paidCount;
        if(paidCount > 0) {
            paidBoardCount++ 
        }
    }

    const free   =  `Свободных щитов ${freeBoardCount}, поверхностей ${freeSideCount}`
    const reserved =  `Зарезервировано щитов ${reservedBoardCount}, поверхностей ${reservedSideCount}`
    const booked =  `Забронировано щитов ${bookedBoardCount}, поверхностей ${bookedSideCount}`
    const sold   =  `Продано щитов ${soldBoardCount}, поверхностей ${soldSideCount}`
    const paid   =  `Оплачено щитов ${paidBoardCount}, поверхностей ${paidSideCount}`

    return (
        <ul className="tooltip-list-group">
            <li>{free}</li>
            <li>{reserved}</li>
            <li>{booked}</li>
            <li>{sold}</li>
            <li>{paid}</li>
        </ul>
    )
}

const mapStateToProps = (state: RootState) => ({
    fullMonthStatistic: state.billboards.fullMonthStatistic
});

export default connect(mapStateToProps)(TooltipContent);