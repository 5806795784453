import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SelectChangeEvent } from './Select';
import { IPropertyValues } from '../../RemoteCommands/SystemEvent';

type Props = {
    labelText: string;
    initialValue: string | number;
    onChange: (event: SelectChangeEvent) => void,
    propertyValues: IPropertyValues[];
    name: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            padding: "10.5px 14px"
        },
        label: {
            transform: "translate(10px, 12px) scale(1)"
        }
    }),
);

const FormControlUniversal:React.FC<Props> = (props) => {
    const {
        labelText,
        onChange,
        propertyValues,
        name,
        initialValue
    } = props;
    const classes = useStyles();
    const [value, setValue] = useState<string | number>(initialValue);

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setValue(event.target.value as any);
        onChange(event);
    };

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    return (
        <FormControl 
            variant="outlined" 
            className="w-100 mt-1 cancelDraggable">
            <InputLabel 
                ref={inputLabel} 
                className={classes.label}>
                {labelText}
            </InputLabel>
            <Select
                value={value === -1 ? "" : value}
                name={name}
                onChange={handleSelectChange}
                input={
                    <OutlinedInput 
                        classes={{ input: classes.input }} 
                        labelWidth={labelWidth}
                    />
                }
            >
                <MenuItem 
                    value={-1}>
                    Не задано
                </MenuItem>
                {propertyValues.sort((a: any, b: any) => a.value - b.value).map(item => (
                    <MenuItem 
                        key={item.valueId} 
                        value={item.valueId}>
                        {item.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default FormControlUniversal;