import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizeProvider } from "react-localize-redux";
import AppStack from './pages/AppStack';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from './store';
import './App.scss';
import ClientMap from './components/ClientMap/ClientMap';

toast.configure();
const App = () => {

    const geoMarkId = new URLSearchParams(window.location.search).get("geoMarkId");

    if(geoMarkId) {
        return (
            <Provider store={store}>
                <LocalizeProvider store={store}>
                    <ClientMap />
                </LocalizeProvider>
            </Provider>
        )
    }
    
    return (
        <Provider store={store}>
            <LocalizeProvider store={store}>
                <Router>
                    <Switch>
                        <Route path="/" component={AppStack} />
                    </Switch>                
                </Router>
            </LocalizeProvider>
        </Provider>
    );
};


export default App;