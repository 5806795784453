import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Tooltip} from '@material-ui/core';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import CloseIcon from '@material-ui/icons/Close';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { DatesRange } from '../bricks/InputDatesRange';
import moment from 'moment';
import { Project } from 'sedi-webserverproxy';
import ProjectCardTable from '../Projects/ProjectSelector/ProjectCardTable';
import { RollUpCard, StatisticsType } from '../../store/event/types';
import RemoveIcon from '@material-ui/icons/Remove';
import { ISales } from '../../RemoteCommands/type';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
        dates: DatesRange,
    }

const ProjectStatistics:React.FC<Props> = (props) => {
    const {
        draggable,
        draggablePositionAction,
        draggablePositions,
        dates,
        projectStatisticsAction,
        projectStatistics,
        rollUpCardAction,
        rollUpCards,
        curretnSale,
        statisticsType,
        curretnSaleAction
    } = props;

    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        SystemEvent.SubscribeEventGetProjects(
            "ProjectStatistics", 
            (answer) => {
                setLoading(false);
                setProjects(answer.projectsInfo);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
    }, []);

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'ProjectStatistics',
            position
        });
    };

    const titleView = () => {
        const dateFrom = moment(dates.DateFrom).format('MMMM yyyy'),
            dateTo = moment(dates.DateTo).format('MMMM yyyy');
        let type: string = "";
        if(statisticsType === StatisticsType.client) {
            type = "клиент";
        } else if(statisticsType === StatisticsType.employee) {
            type = "сотрудник";
        } else {
            type = ""
        }
        return (
            <div className="box-title">
                Продажи {dateFrom} - {dateTo}, {type} {curretnSale && curretnSale.name}
            </div>
        )
    }

    const draggablePosition = draggablePositions.find(item => item.key === "ProjectStatistics");
    const rollUpCardHas = rollUpCards.some(item => item.key === "ProjectStatistics");
    const rolleUpClick = () => {
        rollUpCardAction({
            title: `Проекты статистика`,
            key: "ProjectStatistics"
        });
    }

    const onClose = () => {
        curretnSaleAction(null);
        projectStatisticsAction(false);
    }

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}>
            <Card 
                className={`
                    selectedCard projectStatistics 
                    ${projectStatistics ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                className="mt-1 ml-1"
                                onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                {titleView()}
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="pt-0">
                    {loading ?
                        <div className="loading">
                            <CircularProgress />
                        </div>
                    :
                        <ProjectCardTable
                            projects={projects} 
                            statisticType={true} />
                    }
                </CardContent>
            </Card>    
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        projectStatistics: state.event.projectStatistics,
        rollUpCards: state.event.rollUpCards,
        curretnSale: state.event.curretnSale,
        statisticsType: state.event.statisticsType,
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    projectStatisticsAction: (projectStatistics: boolean) => 
        dispatch(actions.event.projectStatisticsAction(projectStatistics)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),  
    curretnSaleAction: (curretnSale: ISales | null) => 
        dispatch(actions.event.curretnSaleAction(curretnSale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStatistics);