import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../../store';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { DefaultPosition } from '../../../store/app/types';
import { 
    Button,
    Card, 
    CardContent, 
    CardHeader, 
    CircularProgress, 
    createStyles, 
    FormControl, 
    Grid, 
    IconButton,  
    InputLabel, 
    makeStyles, 
    MenuItem, 
    Select,
    Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Theme } from 'react-autosuggest';
import BrandAutocomplete from '../../Tariff/BrandAutocomplete';
import ClientItemAutocomplete from '../../bricks/ClientItemAutocomplete';
import InputDatesRange, { DatesRange } from '../../bricks/InputDatesRange';
import ProjectCardTable from './ProjectCardTable';
import { IProjectFilter } from '../../../store/todo/types';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { Project, ProjectType } from 'sedi-webserverproxy';
import Message from '../../bricks/Message';
import RemoveIcon from '@material-ui/icons/Remove';
import { RollUpCard } from '../../../store/event/types';
import GetAutocompleteList from '../../bricks/GetAutocompleteList';
import { AutocompleteType, CreateProjectParams, GetProjectParmas } from '../../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../../RemoteCommands/SystemEvent';
import { localStorageGetItem } from '../../../utils/storage';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
        handleChangeDates: (dates: DatesRange) => void
        dates: DatesRange;
}

const ProjectCard:React.FC<Props> = (props) => {
    const classes = useStyles();
    const { 
        draggable,
        defaultPosition,
        defaultPositionAction,
        currentBrandInfo,
        clientItem,
        projectStatuses,
        coworkers,
        currentGeoMarkStatus,
        openProjectChange,
        openProjectModal,
        datesAction,
        projectFilterAction,
        userInfo,
        rollUpCardAction,
        rollUpCards,
        loadingAction,
        changeStatuses,
        projects,
        setAllProjects,
        successMessage
    } = props;

    const [state, setState] = useState<IProjectFilter>({
        projectType: ProjectType.CommonProject,
        projectStatus: undefined,
        coworker: undefined
    });
    const [dates, setDates] = useState<DatesRange>({
        DateFrom: undefined,
        DateTo: undefined
    });

    const [waiting, setWaiting] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        SystemEvent.SubscribeEventGetProjects(
            "ProjectCard", 
            (answer) => {
                loadingAction(false);
                setAllProjects(answer.projectsInfo);
            }, 
            (error) => {
                loadingAction(false);
                errorCallback(error)
            }
        );

        SystemEvent.SubscribeEventCreateProject(
            "ProjectCard", 
            (answer) => {
                setWaiting(false);
                setAllProjects([
                    answer.project,
                    ...projects,
                ]);
            }, 
            (error) => {
                setWaiting(false);
                errorCallback(error)
            }
        );
    }, []);

    useEffect(() => {
        projectFilterAction(state)
    }, [state]);

    async function addProject() {
        setWaiting(true);
        setErrorText('');

        if(clientItem) {
            const newProjectName = clientItem.name + ' ' + moment(new Date()).format('DD.MM.YYYY');
            const dateStart = dates.DateFrom ? moment(dates.DateFrom).format('yyyy-MM-DD HH:mm:ss') : undefined;
            const dateEnd = dates.DateTo ? moment(dates.DateTo).format('yyyy-MM-DD HH:mm:ss') : undefined;

            const payload: CreateProjectParams = {
                projectOwnerAccountId: clientItem.accountId,
                projectName: newProjectName,
                brandId: currentBrandInfo ? currentBrandInfo.brandId : undefined,
                dateStart,
                dateEnd
            }
            SystemEvent.EventCreateProject(payload);
        }
    }

    const handleChangeDates = (dates: DatesRange) => {
        let _dates: DatesRange = dates;
        if(dates.DateFrom && dates.DateTo && (dates.DateTo <= dates.DateFrom)) {
            const pluseMonthDateTo = moment(dates.DateFrom, "YYYY-MM-DD").add(1, 'month').toDate();
            _dates = {
                DateFrom: dates.DateFrom,
                DateTo: pluseMonthDateTo
            }
        }
        setDates({DateFrom: _dates.DateFrom, DateTo: _dates.DateTo});
        datesAction({DateFrom: _dates.DateFrom, DateTo: _dates.DateTo});
    }

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        defaultPositionAction(position);
    };

    const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof state;
        setState(prevState => ({
            ...prevState,
            [name]: event.target.value,
        }));
    };

    const baseDisabled = state.projectType === ProjectType.ParentCommercialOfferProject ? true : false;

    const getProjectInfo = useCallback(() => {
        loadingAction(true);
        const payload: GetProjectParmas = {
            projectType: state.projectType,
            projectStatus: state.projectStatus,
            brandId: currentBrandInfo ? currentBrandInfo.brandId : undefined,
            creatorAccountId: state.coworker,
            ownerAccountId: clientItem ? clientItem.accountId : undefined,
            language: localStorageGetItem("lang")
        }
        if(dates.DateFrom) {
            const dateStart = moment(dates.DateFrom).format('yyyy-MM-DD HH:mm:ss');
            Object.assign(payload, {
                dateStart
            });
        }
        if(dates.DateTo) {
            const dateEnd = moment(dates.DateTo).format('yyyy-MM-DD HH:mm:ss');
            Object.assign(payload, {
                dateEnd
            });
        }
        SystemEvent.EventGetProjects(payload);
    }, [
        currentGeoMarkStatus,  
        clientItem, 
        currentBrandInfo, 
        state.coworker, 
        state.projectStatus,
        dates.DateFrom,
        dates.DateTo
    ]);

    useEffect(() => {
        if(successMessage) {
            getProjectInfo();
        }
    }, [successMessage]);

    useEffect(() => {
        getProjectInfo();
        SystemEvent.SubscribeEventChangeProject(
            "ProjectCard", 
            (answer) => {
                loadingAction(false);
                getProjectInfo();
            }, 
            (error) => {
                loadingAction(false);
                getProjectInfo();
                errorCallback(error)
            }
        );
    }, [
        currentGeoMarkStatus, 
        clientItem, 
        currentBrandInfo, 
        state.coworker, 
        state.projectStatus,
        dates.DateFrom,
        dates.DateTo
    ]);

    useEffect(() => {
        changeStatuses(projects.map(item => item.projectId));
    }, [projects]);

    const userRoleTypeProjects = userInfo && (userInfo.userRole === "SalesDirector" || userInfo.userRole === "AdminPMS");

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Проекты",
            key: "ProjectCard"
        });
    }

    const rollUpCardHas = rollUpCards.some(item => item.key === "ProjectCard");

    return (
        <Draggable
            handle=".projectCard"
            defaultPosition={defaultPosition}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
                <Card 
                    className={`
                        projectCard ${openProjectModal ? 'openSelectedCard' : 'closeSelectedCard'}
                        ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                    `} 
                    style={{cursor: draggable ? 'move' : 'auto'}}>
                    <CardHeader
                        action={
                            <div className="button-group">
                                <Tooltip
                                    title="Свернуть"
                                    placement="top">
                                    <IconButton
                                        onClick={rolleUpClick}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                                <IconButton
                                    onClick={() => openProjectChange(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        }
                        title={
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <div className="box-title">Проекты</div>
                                </Grid>
                                {/* {userRoleTypeProjects && 
                                    <Grid item>
                                        <FormControl 
                                            variant="outlined" 
                                            size="small" 
                                            className={`${classes.formControl} cancelDraggable`}>
                                            <InputLabel>Тип Проектов</InputLabel>
                                            <Select
                                                value={state.projectType}
                                                onChange={handleSelectChange}
                                                name="projectType"
                                                labelWidth={106}>
                                                {projectTypes.map(item => (
                                                    <MenuItem 
                                                        key={item.Value} 
                                                        value={item.Value}>
                                                            {item.Localization}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                } */}
                                <Grid item xs={3}>
                                    <ClientItemAutocomplete 
                                        disabled={baseDisabled} />
                                </Grid>
                                <Grid item>
                                    <GetAutocompleteList 
                                        label="Выберите проект" 
                                        type={AutocompleteType.Project} />
                                </Grid>
                                <Grid item>
                                    <FormControl 
                                        variant="outlined" 
                                        size="small" 
                                        className={`${classes.formControl} cancelDraggable`}>
                                        <InputLabel>Статус</InputLabel>
                                        <Select
                                            value={state.projectStatus}
                                            onChange={handleSelectChange}
                                            name="projectStatus"
                                            labelWidth={52}>
                                            <MenuItem value={undefined}>Все</MenuItem>
                                            {projectStatuses.map(item => (
                                                <MenuItem 
                                                    key={item.value} 
                                                    value={item.value}>
                                                        {item.localization}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {userRoleTypeProjects && 
                                    <Grid item>
                                        <FormControl 
                                            variant="outlined" 
                                            size="small" 
                                            className={`${classes.formControl} cancelDraggable`}>
                                            <InputLabel>Создатель проекта</InputLabel>
                                            <Select
                                                value={state.coworker}
                                                onChange={handleSelectChange}
                                                name="coworker"
                                                labelWidth={146}>
                                                <MenuItem value={undefined}>Все</MenuItem>
                                                {coworkers.map(item => (
                                                    <MenuItem 
                                                        key={item.id} 
                                                        value={item.id}>
                                                            {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item>
                                    <InputDatesRange 
                                        showShiftControl={false}
                                        allowUndefinedValues={true}
                                        onChange={(dates) => handleChangeDates(dates)}  
                                        dates={dates}
                                        disabled={false} />
                                </Grid>
                                {/* <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={getInfoClick}>
                                        Получить информацию
                                    </Button>
                                </Grid> */}
                                <Grid item>
                                    <BrandAutocomplete 
                                        disabled={baseDisabled} />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={clientItem ? waiting : true}
                                        onClick={addProject}
                                        startIcon={waiting && 
                                            <CircularProgress 
                                                color="secondary" 
                                                size={24} 
                                                thickness={4} 
                                            />
                                        }>
                                        <Translate id='AddProject' />
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    />
                    <CardContent>
                        {errorText.length > 0 &&
                            <Message message={errorText} variant="error" />
                        }
                        <ProjectCardTable
                            projects={projects} />
                    </CardContent>
                </Card>
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => ({
    defaultPosition: state.app.defaultPosition,
    draggable: state.app.draggable,
    currentBrandInfo: state.billboards.currentBrandInfo,
    clientItem: state.customers.clientItem,
    projectStatuses: state.todo.projectStatuses,
    projectTypes: state.todo.projectTypes,
    coworkers: state.todo.coworkers,
    currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
    openProjectModal: state.todo.openProjectModal,
    userInfo: state.user.userInfo,
    rollUpCards: state.event.rollUpCards,
    openSelectedBillboards: state.todo.openSelectedBillboards,
    projects: state.todo.projects,
    successMessage: state.event.successMessage
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    defaultPositionAction: (defaultPosition: DefaultPosition) => 
        dispatch(actions.app.defaultPositionAction(defaultPosition)),
    openProjectChange: (openProjectModal: boolean) => 
        dispatch(actions.todo.openProject(openProjectModal)),
    setAllProjects: (projects: Project[]) => 
        dispatch(actions.todo.setAllProjects(projects)),
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    datesAction: (dates: DatesRange) => dispatch(actions.todo.datesAction(dates)),
    projectFilterAction: (projectFilter: IProjectFilter) => 
        dispatch(actions.todo.projectFilterAction(projectFilter)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    loadingAction: (loading: boolean) => dispatch(actions.event.loadingAction(loading)),
    changeStatuses: (projectIds: number[]) => 
        dispatch(actions.billboards.changeBillboardStatusesActionCreator(projectIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCard);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
        width: 200
    },
  }),
);