import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import { Card, CardContent, CardHeader, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { RollUpCard } from '../../store/event/types';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { Translate } from 'react-localize-redux';
import { GetGeoMarkTariffs } from 'sedi-webserverproxy';
import { GeoMarkTariffCell } from './GeoMarkTariffCell';
import { GeoMarkTariffsParams } from '../../RemoteCommands/type';
import './TariffCard.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

function GeoMarkTariffCard(props: Props) {
    const {
        draggablePositions,
        draggablePositionAction,
        draggable,
        rollUpCards,
        rollUpCardAction,
        billboardShort,
        openGeoMarkTariff,
        openGeoMarkTariffAction
    } = props;

    
    const [geoMarkTariffsState, setGeoMarkTariffsState] = useState<GetGeoMarkTariffs[]>([]);

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'GeoMarkTariffCard',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Тарифы",
            key: "GeoMarkTariffCard"
        });
    }

    useEffect(() => {
        SystemEvent.SubscribeEventGetGeoMarkTariffs(
            "GeoMarkTariffCard", 
            (answer) => {
                console.log("EventGetGeoMarkTariffs", answer);
                setGeoMarkTariffsState(answer.geoMarkTariffs);
            }, 
            (error) => errorCallback(error)
        );

        if(billboardShort) {
            SystemEvent.SubscribeEventSetGeoMarkTariffs(
                "GeoMarkTariffCard", 
                (answer) => {
                    console.log("Все прошло успешно");
                    const payload: GeoMarkTariffsParams = {
                        geoMarkIds: [billboardShort.geoMarkId]
                    }
                    SystemEvent.EventGetGeoMarkTariffs(payload);
                }, 
                (error) => errorCallback(error)
            );
        }

    }, []);

    const draggablePosition = draggablePositions.find(item => item.key === "GeoMarkTariffCard");
    const rollUpCardHas = rollUpCards.some(item => item.key === "GeoMarkTariffCard");

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card
                className={`
                    selectedCard geoMarkTariffCard ${openGeoMarkTariff ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                onClick={() => openGeoMarkTariffAction(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <div className="box-title">
                                    Тарифы
                                </div>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="pt-0">
                    <TableContainer component={Paper} className="billboardTariffTable">
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{minWidth: 180}}><Translate id='BillboardListAddress'/></TableCell>
                                    <TableCell align="center">Сторона</TableCell>
                                    <TableCell align="center" style={{width: 110}}>Стоимость</TableCell>
                                    <TableCell align="center">Действия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {geoMarkTariffsState.length > 0 && geoMarkTariffsState[0].billboardTariffs.map(item => (
                                    <GeoMarkTariffCell
                                        key={item.boardId + item.side}
                                        billboardShort={billboardShort}
                                        billboardTariff={item} 
                                        geoMarkTariffs={geoMarkTariffsState} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Draggable>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
        billboardShort: state.billboards.billboardShort,
        openGeoMarkTariff: state.billboards.openGeoMarkTariff
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    openGeoMarkTariffAction: (openGeoMarkTariff: boolean) => 
        dispatch(actions.billboards.openGeoMarkTariffAction(openGeoMarkTariff)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoMarkTariffCard);