import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { Badge, Fab } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { RollUpCard } from '../../store/event/types';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    rollUpCard: RollUpCard;
}

const RollUpBtn:React.FC<Props> = (props) => {
    const {
        rollUpCardsAction,
        rollUpCards,
        rollUpCard,
        employeesCount
    } = props;

    const rolleUpDeleteClick = () => {
        const idx = rollUpCards.findIndex((item) => item.key === rollUpCard.key);
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    if(rollUpCard.key === "Employees") {
        return (
            <Badge 
                badgeContent={employeesCount} 
                color="secondary"
                max={9999}>
                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    className="rollUpBtn"
                    onClick={rolleUpDeleteClick}>
                    <KeyboardArrowDownIcon />
                    {rollUpCard.title}
                </Fab>
            </Badge>
        )
    } else {
        return (
            <Fab
                variant="extended"
                size="small"
                color="primary"
                className="rollUpBtn"
                onClick={rolleUpDeleteClick}>
                <KeyboardArrowDownIcon />
                {rollUpCard.title}
            </Fab>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    rollUpCards: state.event.rollUpCards,
    employeesCount: state.event.employeesCount
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    rollUpCardsAction: (rollUpCards: RollUpCard[]) => 
        dispatch(actions.event.rollUpCardsAction(rollUpCards))
});

export default connect(mapStateToProps, mapDispatchToProps)(RollUpBtn);