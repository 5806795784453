import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AgencyInfo } from '../../store/event/types';
import { TextField } from '@material-ui/core';
import { GetGeoMarkTariffs } from 'sedi-webserverproxy';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { GeoMarkTariffsParams } from '../../RemoteCommands/type';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const TariffAgenciesAutocomplete:React.FC<Props> = (props) => {
    const { agencyInfo, currentAgencyAction, currentAgency, getGeoMarkTariffsOwnerAction } = props;

    const currentValueOnChange = (changeValue: AgencyInfo) => {
        getGeoMarkTariffsOwnerAction([]);
        if(changeValue) {
            // const builder = new EventBuilder(`EventGetGeoMarkTariffs`);
            // builder.AddParameter('OwnerAccountId', changeValue.AccountId.toString());
            // ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            const payload: GeoMarkTariffsParams = {
                ownerAccountId: changeValue.AccountId
            }
            SystemEvent.EventGetGeoMarkTariffs(payload);
            currentAgencyAction(changeValue)
        }
    }

    return (
        <Autocomplete
            options={agencyInfo}
            getOptionLabel={(option: AgencyInfo) => option.AgencyName}
            value={currentAgency}
            style={{ minWidth: 300}}
            onChange={(event: React.ChangeEvent<{}>, changeValue: AgencyInfo) => 
                currentValueOnChange(changeValue)
            }
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    fullWidth 
                    label="Выберите агентства" 
                    variant="outlined" 
                    size="small" 
                />
            }
        />
    );
}

const mapStateToProps = (state: RootState) => ({
    agencyInfo: state.event.agencyInfo,
    currentAgency: state.event.currentAgency,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    currentAgencyAction: (currentAgency: AgencyInfo) => 
        dispatch(actions.event.currentAgencyAction(currentAgency)),
    getGeoMarkTariffsOwnerAction: (billboardTariffsOwner: GetGeoMarkTariffs[]) => 
        dispatch(actions.billboards.getGeoMarkTariffsOwnerAction(billboardTariffsOwner))
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffAgenciesAutocomplete);