import React, { useRef, useState, useEffect } from 'react';
import Slider from "react-slick";
import Fancybox from '../../hooks/FancyboxHook';
import { Settings } from '../../utils/settings';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './BillboardCard.scss';

type Props = {
    uploadedPhotos: string[];
    geoMarkId: number;
    photoVersion: number;
};

export type SliderState = {
    main: Slider | null;
    thumb: Slider | null;
}
export type SliderRef = {
    mainRef: Slider | null;
    thumbRef: Slider | null;
}

export const BillboardCarousel = (props: Props) => {
    const { uploadedPhotos, geoMarkId, photoVersion } = props;

    const sliderRef = useRef<SliderRef>({
        mainRef: null,
        thumbRef: null
    });

    const [sliderState, setSliderState] = useState<SliderState>({
        main: null,
        thumb: null
    });

    useEffect(() => {
        setSliderState({
            main: sliderRef.current.mainRef,
            thumb: sliderRef.current.thumbRef,
        });
    }, []);

    return (
        <Fancybox>
            <Slider 
                asNavFor={sliderState.thumb || undefined}
                ref={slider => (sliderRef.current.mainRef = slider)}
                dots={false}
                arrows={false}
                className="mainSlider"
                infinite={false}>
                {uploadedPhotos.map(photo => {
                    const imgSrc = Settings.useNewUrl ?
                    `${Settings.imgUrlNew + geoMarkId}&imagetype=${photo}` :
                    `${Settings.imgUrl + geoMarkId}&sideName=${photo}&v=${photoVersion}`;
                    const imgSrcBig = Settings.useNewUrl ? 
                    `${Settings.imgUrlNew + geoMarkId}&imagetype=${photo}&bigPhoto=true`: 
                    `${Settings.imgUrl + geoMarkId}&sideName=${photo}&bigPhoto=true&v=${photoVersion}`;
                    return (
                        <div 
                            key={`carousel-${photo}`}
                            data-fancybox="gallery"
                            data-src={imgSrcBig}
                            className="mainSlider__item">
                            <img src={imgSrc} alt="" />
                        </div>
                    )
                })}
            </Slider>
            <Slider 
                asNavFor={sliderState.main  || undefined}
                ref={slider => (sliderRef.current.thumbRef = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                className="thumbSlider"
                centerPadding="60px"
                centerMode={true}
                infinite={uploadedPhotos.length > 3 ? true : false}>
                {uploadedPhotos.map(photo => {
                    const imgSrc = `${Settings.imgUrl + geoMarkId}&sideName=${photo}&v=${photoVersion}`;
                    return (
                        <div 
                            className="thumbSlider__item"
                            key={`carousel-thumb-${photo}`}>
                            <img src={imgSrc} alt="" />
                        </div>
                    )
                })}
            </Slider>
        </Fancybox>
    );
};