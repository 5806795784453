import React from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
    previewOpen: boolean;
    previewClickClose: () => void;
    htmlPreview: string;
}

const PreviewModal: React.FC<Props> = (props) => {
    const { previewOpen, previewClickClose, htmlPreview } = props;

    return (
        <Dialog
            open={previewOpen}
            onClose={previewClickClose}
            maxWidth="md"
            className="preview"
        >
            <DialogTitle>
                <IconButton aria-label="delete" style={{ position: 'absolute', top: '5px', right: '5px' }} 
                    onClick={previewClickClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div dangerouslySetInnerHTML={{__html: htmlPreview}} />
            </DialogContent>
        </Dialog>
    );
}

export default PreviewModal;