import React from 'react';
import { SalesPaymentStatisticResult } from '../../RemoteCommands/type';
import { StatisticsType } from '../../store/event/types';
import { MonthYear } from '../../utils/getMonthYear';
import GraphsCharts from '../bricks/GraphsCharts';
import SalesStatisticsTable from './SalesStatisticsTable';
import moment from 'moment';

type Props = {
    salesPaymentStatisticResult: SalesPaymentStatisticResult,
    intervalMonthYears: () => MonthYear[];
    statisticsType: StatisticsType;
    title: any;
}

class StatisticsToPrint extends React.PureComponent<Props> {
    render() {
        const { 
            salesPaymentStatisticResult, 
            intervalMonthYears, 
            statisticsType,
            title
        } = this.props;

        return (
            <div className="p-5" style={{width: '100%', display: "block"}}>
                <div className="print">
                    <div className="print__date">{moment(new Date()).format('DD-MM-yyyy')}</div>
                    <div className="print__title">Продажи {title}</div>
                </div>
                <SalesStatisticsTable 
                    salesPaymentStatisticResult={salesPaymentStatisticResult} />
                <div className="totalStatistics">
                    {`
                        Всего продано/Оплачено: 
                        ${salesPaymentStatisticResult.totalItem.soldTotalCount} 
                        поверхности на Сумму 
                        ${
                            salesPaymentStatisticResult.totalItem.soldTotalSum 
                                + salesPaymentStatisticResult.totalItem.designSum 
                                + salesPaymentStatisticResult.totalItem.installationSum 
                                + salesPaymentStatisticResult.totalItem.printSum 
                                + salesPaymentStatisticResult.totalItem.additionalInstallationSum
                        } руб.
                    `}
                </div>
                <br />
                <br />
                <GraphsCharts 
                    salesPaymentStatisticResult={salesPaymentStatisticResult} 
                    intervalMonthYears={intervalMonthYears} 
                    statisticsType={statisticsType} />
            </div>
        );
    }
}

export default StatisticsToPrint