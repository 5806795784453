import React, {Dispatch} from "react";
import { connect } from "react-redux";
import { RootState, selectors, actions } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { registerLocale } from "react-datepicker";
import { Translate, getTranslate, LocalizedElement, LocalizedElementMap } from "react-localize-redux";
import ru from "date-fns/locale/ru";
import Grid from '@material-ui/core/Grid';
import ButtonMaterial from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectOutlined from "../../bricks/SelectOutlined";
import { renderToString } from "react-dom/server";
import TextField from "../../bricks/TextField";
import InputDatesRange from "../../bricks/InputDatesRange";
import InputNumbersRange from "../../bricks/InputNumbersRange";
import InputPhoneFormated from "../../bricks/inputPhoneFormated";
import { GetCustomersFilter } from "sedi-webserverproxy";
import CompanyAutocomplete from "../../bricks/CompanyAutocomplete";
import { getCompanyByID } from "../../../store/customers/selectors";


registerLocale("ru", ru);


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    filter: GetCustomersFilter,
    fixedFilter?: GetCustomersFilter,
    disabled: boolean,
    onClickSearch: () => void,
    onChange: (filter: GetCustomersFilter) => void;
};


type State = {}


enum LegalTypes {
    Physical = 'Physical',
    Legal = 'Legal'
}


enum VipTypes {
    Vip = 'Vip',
    NotVip = 'NotVip',
    Both = 'Both'
}


class CustomersFilter extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }


    onLegalTypeChanged(type: LegalTypes) {
        let filter = { ...this.props.filter };
        filter.isLegal = type === LegalTypes.Legal;
        if (filter.isLegal !== true)
            filter.inn = '';
        this.triggerOnChange(filter);
    }

    onIsVipChanged(isVip: VipTypes) {
        let filter = { ...this.props.filter };
        filter.isVip = isVip == VipTypes.Both ? null : isVip == VipTypes.Vip;
        this.triggerOnChange(filter);
    }

    onInnChanged(inn: string) {
        let filter = { ...this.props.filter };
        filter.inn = inn;
        if (inn.length > 0)
            filter.isLegal = true;
        this.triggerOnChange(filter);
    }


    onNameChanged(name: string) {
        let filter = { ...this.props.filter };
        filter.name = name;
        this.triggerOnChange(filter);
    }

    onPhoneChanged(phone: string) {
        let filter = { ...this.props.filter };
        filter.phone = phone;
        this.triggerOnChange(filter);
    }

    onEmailChanged(email: string) {
        let filter = { ...this.props.filter };
        filter.email = email;
        this.triggerOnChange(filter);
    }

    onCompanyChanged(id: number) {
        let filter = { ...this.props.filter };
        filter.companyId = id;
        this.triggerOnChange(filter);
    }


    onRegistrationDateChanged(dateFrom: Date | undefined, dateTo: Date | undefined) {
        let filter = { ...this.props.filter };
        filter.registrationDateFrom = dateFrom || null;
        filter.registrationDateTo = dateTo|| null;
        this.triggerOnChange(filter);
    }

    
    onOrdersChanged(ordersFrom: number | undefined, ordersTo: number | undefined) {        
        let filter = { ...this.props.filter };
        filter.orderFrom = ordersFrom || null;
        filter.orderTo = ordersTo || null;
        this.triggerOnChange(filter);
    }


    triggerOnChange(filter: GetCustomersFilter) {        
        const { fixedFilter } = this.props;
        // TODO поиск компаний не работает, если скопировать заказы, время...
        //filter = Object.assign(filter, fixedFilter);
        if (fixedFilter){
            filter.isLegal = fixedFilter.isLegal;
        }
        this.props.onChange(filter);
    }


    render() {
        const { translateFunc, disabled, onClickSearch, fixedFilter, currentCompany } = this.props;
        
        let { filter } = this.props;
        //filter = Object.assign(filter, fixedFilter);
        
        const showClientType = fixedFilter === undefined || fixedFilter.isLegal === undefined;
        const showOrders = fixedFilter === undefined 
            || fixedFilter.orderFrom === undefined 
            || fixedFilter.orderTo === undefined;
        const showRegistrationDate = fixedFilter === undefined 
            || fixedFilter.registrationDateFrom === undefined
            || fixedFilter.registrationDateTo === undefined;
        const showVip = fixedFilter === undefined || fixedFilter.isLegal === undefined;

        const legalTypesValues = {
            [LegalTypes.Legal]: translateFunc('CustomersListLegal'),
            [LegalTypes.Physical]: translateFunc('CustomersListNotLegal'),
        };

        const vipTypesValues = {
            [VipTypes.Both]: translateFunc('CustomersListAll'),
            [VipTypes.Vip]: translateFunc('CustomersListVipClients'),
            [VipTypes.NotVip]: translateFunc('CustomersListNotVipClients'),
        };

        const companiesDataSource = [{ id: 0, name: ''}]
            .concat(this.props.companies.map(c => { return { id: c.id, name: c.name}}))
            .reduce((obj: any, c) => {
                if(c.id === 0) {
                    obj[c.id.toString()] = 'Все';
                    return obj;
                }
                obj[c.id.toString()] = `#${c.id} ${c.name}`;
                return obj;
            }, {});            

        return (
            <div>
                <Grid container spacing={1} alignItems="center" className="customers-filter">

                    {showClientType && 
                        <Grid item xs={6} sm={4} lg={2}>
                            <SelectOutlined 
                                labelText={getTranslateAsString(translateFunc('CustomersListClientType'))}
                                dataSource={legalTypesValues}
                                onChange={e => this.onLegalTypeChanged(e.target.value as LegalTypes)}
                                value={filter.isLegal === true ? LegalTypes.Legal : LegalTypes.Physical} 
                            />
                        </Grid>
                    }

                    {showVip && 
                        <Grid item xs={6} sm={4} lg={2}>
                            <SelectOutlined 
                                labelText="ВИП статус"
                                dataSource={vipTypesValues}
                                onChange={e => this.onIsVipChanged(e.target.value as VipTypes)}
                                value={filter.isVip === null ? VipTypes.Both : (filter.isVip ? VipTypes.Vip : VipTypes.NotVip)} 
                            />
                        </Grid>     
                    }       
                    <Grid item xs={6} sm={4} lg={2}>
                        <TextField className="w-100"
                            label={getTranslateAsString(translateFunc('CustomersListName'))}
                            onChange={(e=> this.onNameChanged(e.target.value))}
                            value={filter.name}
                        />
                    </Grid>      
                    <Grid item xs={6} sm={3} lg={2}>
                        {/* <TextField className="w-100"
                            type="tel"
                            label={getTranslateAsString(translateFunc('CustomersListPhone'))}
                            onChange={(e => this.onPhoneChanged(e.target.value))}
                            value={filter.phone}
                        /> */}
                        <InputPhoneFormated
                            className="w-100"
                            type="tel"
                            label={getTranslateAsString(translateFunc('CustomersListPhone'))}
                            value={filter.phone}
                            onPhoneChange={(e) => this.onPhoneChanged(e.value)}
                            error={false}
                        />
                    </Grid>    
                    <Grid item xs={5} sm={3} lg={2}>
                        <TextField className="w-100"
                            type="email"
                            label={getTranslateAsString(translateFunc('CustomersListEmail'))}
                            onChange={(e=> this.onEmailChanged(e.target.value))}
                            value={filter.email}
                        />
                    </Grid>

                    {showOrders && 
                        <Grid item xs={7} sm={6} md={6} lg={3}>
                            <InputNumbersRange
                                labelTextFrom={getTranslateAsString(translateFunc('CustomersListOrdersCount')) 
                                    + ' ' + getTranslateAsString(translateFunc('CustomersListFrom'))}
                                labelTextTo={getTranslateAsString(translateFunc('CustomersListTo'))}
                                onChange={(e) => { this.onOrdersChanged(e.Min, e.Max) } }
                                range={{Min: filter.orderFrom || undefined, Max: filter.orderTo || undefined}}
                            />
                        </Grid>
                    }

                    {showRegistrationDate &&
                        <InputDatesRange
                            labelTextFrom={getTranslateAsString(translateFunc('CustomersListRegistrationDate')) 
                                + ' ' + getTranslateAsString(translateFunc('CustomersListFrom'))}
                            labelTextTo={getTranslateAsString(translateFunc('CustomersListTo'))}
                            onChange={(e) => { this.onRegistrationDateChanged(e.DateFrom, e.DateTo) } }
                            dates={{DateFrom: filter.registrationDateFrom || undefined, DateTo: filter.registrationDateTo || undefined}}
                        />
                    }

                    { this.props.filter.isLegal &&
                        <Grid item xs={9} sm={5} md={3} lg={2}>
                            <TextField className="w-100"
                                label={getTranslateAsString(translateFunc('CustomersListINN'))}
                                onChange={(e=> this.onInnChanged(e.target.value))}
                                value={filter.inn}
                            />
                        </Grid>
                    }
                    { !this.props.filter.isLegal &&
                        <Grid item xs={9} sm={5} md={3} lg={2}>
                            <CompanyAutocomplete 
                                currentValue={currentCompany}
                                onChange={(value) => this.onCompanyChanged(value !== null ? value.id : -1)} />
                            {/* <SelectOutlined 
                                labelText={getTranslateAsString(translateFunc('CustomersListCompany'))}
                                dataSource={companiesDataSource}
                                onChange={e => this.onCompanyChanged(parseInt(e.target.value as string))}
                                value={filter.CompanyId !== undefined ? filter.CompanyId.toString() : ''} 
                            /> */}
                        </Grid>
                    }
                    <Grid item xs={2}>
                        {disabled
                            ?
                                <ButtonMaterial variant="contained" color="primary" disabled={true} style={{'height': '40px', 'marginTop': '2px'}}>
                                    <CircularProgress color="secondary" className="mr-2" size={20}/>
                                    <Translate id='CustomersListLoading' />
                                </ButtonMaterial>
                            :
                                <ButtonMaterial onClick={onClickSearch} variant="contained" color="primary" style={{'height': '40px', 'marginTop': '2px'}}>
                                    <Translate id='CustomersListSearch' />
                                </ButtonMaterial>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    };
}


const mapStateToProps = (state: RootState) => {

    let currentCompanyAccountId = selectors.sip.GetCallerAccountID(state.sip);

    if (currentCompanyAccountId <= 0){
        currentCompanyAccountId = selectors.todo.getCurrentCompanyAccountId(state.todo)
    }
    
    return {
        translateFunc: getTranslate(state.localize),
        // TODO reselect
        companies: state.customers.companies,
        currentCompany: state.customers.currentCompany,
    }
};


function getTranslateAsString(translateObject: LocalizedElement | LocalizedElementMap) : string{
    if (typeof translateObject === 'string'){
        return translateObject;
    }
    
    const TranslateElement = translateObject;
    // @ts-ignore
    return renderToString(<TranslateElement />);
}

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    currentCompanyAccountIdChange: (currentCompanyAccountId: number) => dispatch(actions.todo.currentCompanyAction(currentCompanyAccountId))
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(CustomersFilter);