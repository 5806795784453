import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { 
    FormControl, 
    MenuItem, 
    Select, 
    TableCell, 
    TableRow, 
    TextField, 
    Tooltip 
} from '@material-ui/core';
import { AdditionalGeoMarkTariffs, TariffsParameters } from '../../RemoteCommands/type';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import DeleteAdditionalGeoMarkTariff from './DeleteAdditionalGeoMarkTariff';
import { uniqueAdditionalTariffs } from '../../utils/onlyUnique';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    geoMarkTariff: AdditionalGeoMarkTariffs;
};

function CurrentGeoMarkTariff(props: Props) {
    const {
        geoMarkTariff,
        additionalTariffs,
        additionalGeoMarkTariff,
        additionalGeoMarkTariffAction,
        additionalGeoMarkTariffs
    } = props;

    const inputEl = useRef<HTMLInputElement>(null);

    const [editChecked, setEditChecked] = useState(Boolean(additionalGeoMarkTariff));
    const [geoMarkTariffState, setGeoMarkTariffState] = useState(geoMarkTariff);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setGeoMarkTariffState(geoMarkTariff);
        if(inputEl && inputEl.current) {
            inputEl.current.focus();
        }
    }, [geoMarkTariff]);

    const onOpen = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGeoMarkTariffState({
            ...geoMarkTariffState,
            cost: event.target.value
        });
    };

    const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof geoMarkTariffState;
        const index = geoMarkTariffState.parameters.findIndex((listItem) => listItem.parameterName === name);
        const parameter = geoMarkTariffState.parameters.find(item => item.parameterName === name);
        if(parameter) {
            const newParams = replaceItemAtIndex(geoMarkTariffState.parameters, index, {
                ...parameter,
                parameterValue: event.target.value as string
            });
            setGeoMarkTariffState({
                ...geoMarkTariffState,
                parameters: newParams
            });
        } else {
            const payload: TariffsParameters = {
                parameterName: name,
                parameterValue: event.target.value as string
            }
            setGeoMarkTariffState({
                ...geoMarkTariffState,
                parameters: [
                    ...geoMarkTariffState.parameters,
                    payload
                ]
            });
        }
    };

    const pricingTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setGeoMarkTariffState({
            ...geoMarkTariffState,
            pricingType: event.target.value as string
        })
    }

    const saveGeoMarkTariff = () => {
        SystemEvent.EventChangeAdditionalGeoMarkTariff(geoMarkTariffState);
        setEditChecked(false);
        additionalGeoMarkTariffAction(null);
    }

    const cancelClick = () => {
        SystemEvent.EventGetAdditionalGeoMarkTariffs();
        setEditChecked(false);
        additionalGeoMarkTariffAction(null);
    }

    const onlyUniqueTariffs = uniqueAdditionalTariffs(additionalGeoMarkTariffs);

    if(editChecked) {
        return (
            <TableRow>
                <TableCell component="th" scope="row">
                    <FormControl 
                        fullWidth 
                        margin="dense"
                        className="table-select">
                        <Select
                            value={geoMarkTariffState.pricingType}
                            name={geoMarkTariffState.pricingType}
                            displayEmpty
                            onChange={pricingTypeChange}>
                            {onlyUniqueTariffs.map(item => (
                                <MenuItem 
                                    key={`table-item-${item.pricingType}`} 
                                    value={item.pricingType}>
                                    {item.pricingLocalization}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell align="center">
                    <TextField 
                        type="number"
                        size="small"
                        value={geoMarkTariffState.cost} 
                        onChange={handleChange} 
                        autoFocus={editChecked} 
                        inputProps={{ref: inputEl}} />
                </TableCell>
                {additionalTariffs.map(tariff => {
                    const parameter = geoMarkTariffState.parameters.find(item => item.parameterName === tariff.conditionName);
                    return (
                        <TableCell 
                            key={`geoMarkTariff-${tariff.conditionName}`} 
                            align="center">
                            <FormControl 
                                fullWidth 
                                margin="dense"
                                className="table-select">
                                <Select
                                    value={parameter ? parameter.parameterValue : ""}
                                    name={tariff.conditionName}
                                    displayEmpty
                                    onChange={handleSelectChange}>
                                    {tariff.values.map(value => (
                                        <MenuItem key={`value-${value}`} value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                    )
                })}
                <TableCell align="center">
                    <Tooltip title="Сохранить" placement="top">
                        <CheckIcon 
                            style={{ cursor: 'pointer', marginRight: 10 }} 
                            onClick={saveGeoMarkTariff} />
                    </Tooltip>
                    <Tooltip title="Отменить" placement="top">
                        <CloseIcon 
                            style={{ cursor: 'pointer' }} 
                            onClick={cancelClick} />
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row">
                    {geoMarkTariffState.pricingLocalization}
                </TableCell>
                <TableCell align="center">
                    {geoMarkTariffState.cost}
                </TableCell>
                {additionalTariffs.map(tariff => {
                    const parameter = geoMarkTariffState.parameters.find(item => item.parameterName === tariff.conditionName);
                    return (
                        <TableCell 
                            key={`geoMarkTariff-${tariff.conditionName}`} 
                            align="center">
                            {parameter ? parameter.parameterValue : ""}
                        </TableCell>
                    )
                })}
                <TableCell align="center">
                    <Tooltip title="Редактировать" placement="top">
                        <EditIcon 
                            fontSize="small" 
                            style={{ cursor: 'pointer', marginRight: 10 }} 
                            onClick={() => setEditChecked(true)} />
                    </Tooltip>
                    <Tooltip title="Удалить" placement="top">
                        <DeleteIcon 
                            fontSize="small" 
                            style={{ cursor: 'pointer' }} 
                            onClick={onOpen} />
                    </Tooltip>
                </TableCell>
            </TableRow>
            <DeleteAdditionalGeoMarkTariff 
                open={open} 
                onClose={onClose} 
                geoMarkTariff={geoMarkTariffState} />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    additionalTariffs: state.event.additionalTariffs,
    additionalGeoMarkTariff: state.event.additionalGeoMarkTariff,
    additionalGeoMarkTariffs: state.event.additionalGeoMarkTariffs
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    additionalGeoMarkTariffAction: (additionalGeoMarkTariff: AdditionalGeoMarkTariffs | null) => 
        dispatch(actions.event.additionalGeoMarkTariffAction(additionalGeoMarkTariff))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentGeoMarkTariff);

function replaceItemAtIndex(arr: TariffsParameters[], index: number, newValue: TariffsParameters) {
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}