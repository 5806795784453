import { Center } from "../components/bricks/AddressCompany";
import { Billboard } from "../store/billboards/types";
import { GeoPoint, IGeoObjectBillboards } from "../store/event/types";

export function calculateDistance(lat1:number, lon1:number, lat2:number, lon2: number) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        dist = dist * 1.609344
        dist = dist * 1000
    return dist
}

export function sortByDistance(billboards: IGeoObjectBillboards[], center: GeoPoint) {
    billboards.sort((a: IGeoObjectBillboards, b: IGeoObjectBillboards) => 
        calculateDistance(
            a.GeoPoint.Latitude, a.GeoPoint.Longitude, center.Latitude, center.Longitude
        ) > 
        calculateDistance(
            b.GeoPoint.Latitude, b.GeoPoint.Longitude, center.Latitude, center.Longitude
        ) ? 1 : -1);
}