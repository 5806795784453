import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TooltipContent from '../../Tooltips/TooltipContent'
import Tooltip from '@material-ui/core/Tooltip';
import '../components/ClusterMarker.scss';


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    count: number,
    onClick?: () => void,
    // lat/lng в явном виде не используются, но нужны для позиционирования на карте
    lat: number,
    lng: number,
    onContextMenu?: () => (event: React.MouseEvent<HTMLButtonElement | HTMLElement>) => void;
    clusterContextMenuClose?: () => void,
    anchorEl?: null | HTMLElement,
    billboardIds: number[]
}


const ClusterMarker:React.FC<Props> = (props) => {
    const { 
        count, 
        onClick, 
        onContextMenu, 
        clusterContextMenuClose, 
        anchorEl, 
        billboardIds 
    } = props;

    // const currentDate = new Date();
    // const billboardIDS = billboards.map(b => b.id);
    // const dateStart = moment(currentDate).toDate();
    // const dateEnd = moment(currentDate).add(1, 'years').toDate();

    // useEffect(() => {
    //     fetchBillboardStatusesAction(billboardIDS, dateStart, dateEnd, true)
    // }, []);

    return (
        <>
            <Tooltip 
                placement="top"
                title={
                    <TooltipContent 
                        billboardIds={billboardIds} />
                }>
                <div
                    className="cluster-marker"
                    onClick={() => onClick && onClick()}
                    onContextMenu={onContextMenu && onContextMenu()}
                >
                    <span className="cluster-marker__title">{count}</span>
                </div>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={clusterContextMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className="ml-2"
            >
                <MenuItem><strong>новое&nbsp;</strong>коммерческое предложение</MenuItem>
            </Menu>
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({

});


export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ClusterMarker);