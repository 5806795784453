import React, {FunctionComponent} from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = {
    message: string,
    variant: 'success' | 'error' | 'warning',
}

interface variantIcon {
    [key: string]: React.ComponentType<SvgIconProps>;
}

const bgColor = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    warning: {
        backgroundColor: amber[700],
    }
}));

const variantIcon: variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
};

const Message: FunctionComponent<Props>  = (props) => {
    const classes = bgColor() as Record<"success" | "error" | "warning", string>;
    const { message, variant } = props;
    const Icon = variantIcon[variant];
    return (
        <SnackbarContent
            className={clsx(classes[variant])}
            message={
                <span className="d-flex align-items-center">
                    <Icon className="mr-2" fontSize="small" />
                    {message}
                </span>
            }>
        </SnackbarContent>
    )
}

export default Message;