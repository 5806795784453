import { BillboardActions, BillboardActionTypes } from "./actions";
import { BillboardState, Billboard } from './types';
import { Reducer } from "redux";
import { GeoMark, PropertyValueSet } from 'sedi-webserverproxy';
import { PropertiesHelper } from "../../utils/PropertiesHelper";


const defaultState: BillboardState = {
    billboards: [],
    filterBillboards: [],
    billboardTariffs: [],
    billboardTariffsOwner: [],
    billboard: undefined,
    properties: [],
    geomarks: [],
    translations: [],
    roles: [],
    isInitialized: false,
    isInitializing: false,
    translationsLoaded: false,
    translationsLoading: false,
    propertiesLoading: false,
    geomarkSaving: false,
    geomarkSavingStart: false,
    billboadStatuses: [],
    // projectBillboardStatuses: [],
    billboadStatusesError: undefined,
    billboadStatusesIsLoading: false,
    saveStatusIsLoading: false,
    saveStatusError: undefined,
    selectedAreaName: '',
    selectedCityName: '',
    photoVersion: 0,
    uploadedPhotos: undefined,
    successSnackbar: false,
    openPrice: false,
    selectedSide: 'A1',
    getAddress: null,
    geoMarkTariffs: null,
    openTariffCard: false,
    tariffSuccess: false,
    openBrandsCard: false,
    brandsInfo: [],
    currentBrandInfo: null,
    nextBillboardStatuses: [],
    projectBillboardIds: [],
    openGeoMarkTariff: false,
    billboardsShortInfo: [],
    forbiddenBillboards: [],
    billboardShort: undefined,
    billboardProperties: undefined,
    propertySet: null,
    eventGeoMarks: [],
    fullMonthStatistic: [],
    openAddBillboard: false
};

export const billboardReducer: Reducer<BillboardState, BillboardActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case BillboardActionTypes.FULL_MONTH_STATISTIC:
            {
                return {
                    ...state,
                    fullMonthStatistic: action.fullMonthStatistic,
                };
            }
        case BillboardActionTypes.EVENT_GEO_MARKS:
            {
                return {
                    ...state,
                    eventGeoMarks: action.eventGeoMarks,
                };
            }
        case BillboardActionTypes.PROPERTY_SET:
            {
                return {
                    ...state,
                    propertySet: action.propertySet,
                };
            }
        case BillboardActionTypes.BILLBOARD_PROPERTIES:
            {
                return {
                    ...state,
                    billboardProperties: action.billboardProperties,
                };
            }
        case BillboardActionTypes.BILLBOARD_SHORT:
            {
                return {
                    ...state,
                    billboardShort: action.billboardShort,
                };
            }
        case BillboardActionTypes.BILLBOARDS_SHORT_INFO:
            {
                return {
                    ...state,
                    billboardsShortInfo: action.billboardsShortInfo,
                };
            }
        case BillboardActionTypes.FORBIDDEN_BILLBOARDS:
            {
                return {
                    ...state,
                    forbiddenBillboards: action.forbiddenBillboards,
                };
            }
        case BillboardActionTypes.DELETE_FROM_TO_ARRAYS:
            {
                return {
                    ...state,
                    billboardsShortInfo: action.billboardsShortInfo,
                    forbiddenBillboards: action.forbiddenBillboards
                };
            }
        case BillboardActionTypes.OPEN_GEO_MARK_TARIFF:
            {
                return {
                    ...state,
                    openGeoMarkTariff: action.openGeoMarkTariff,
                };
            }
        case BillboardActionTypes.PROJECT_BILLBOARD_IDS:
            {
                return {
                    ...state,
                    projectBillboardIds: action.projectBillboardIds,
                };
            }
        case BillboardActionTypes.CURRENT_BRAND_INFO:
            {
                return {
                    ...state,
                    currentBrandInfo: action.currentBrandInfo,
                };
            }
        case BillboardActionTypes.NEXT_BILLBOARD_STATUSES:
            {
                return {
                    ...state,
                    nextBillboardStatuses: action.nextBillboardStatuses,
                    billboadStatuses: action.nextBillboardStatuses,
                };
            }
        case BillboardActionTypes.GET_BRANDS_INFO:
            {
                return {
                    ...state,
                    brandsInfo: action.brandsInfo,
                };
            }
        case BillboardActionTypes.OPEN_BRANDS_CARD:
            {
                return {
                    ...state,
                    openBrandsCard: action.openBrandsCard,
                };
            }
        case BillboardActionTypes.OPEN_TARIFF_CARD:
            {
                return {
                    ...state,
                    openTariffCard: action.openTariffCard,
                };
            }
        case BillboardActionTypes.GEOMARK_TARIFFS:
            {
                return {
                    ...state,
                    geoMarkTariffs: action.geoMarkTariffs,
                };
            }
        case BillboardActionTypes.GET_ADDRESS:
            {
                return {
                    ...state,
                    getAddress: action.getAddress,
                };
            }
        case BillboardActionTypes.SELECTED_SIDE:
            {
                return {
                    ...state,
                    selectedSide: action.selectedSide,
                };
            }
        case BillboardActionTypes.INITIALIZE_SUCCESS:
            {
                return {
                    ...state,
                    isInitializing: false,
                    geomarks: action.geoMarks,
                    properties: action.propertySets,
                    billboards: BillboardHelper.getBillBoards(action.geoMarks, action.propertySets),
                    brandsInfo: action.brandsInfo,
                    roles: action.roles,
                    isInitialized: true
                };
            }
        case BillboardActionTypes.GET_BILLBOARS_AND_PROPERTIES:
            {
                return {
                    ...state,
                    isInitializing: false,
                    geomarks: action.geoMarks,
                    properties: action.propertySets,
                    billboards: BillboardHelper.getBillBoards(action.geoMarks, action.propertySets),
                    isInitialized: true
                };
            }
        case BillboardActionTypes.INITIALIZE_ERROR:
            {
                return {
                    ...state,
                    isInitializing: false,
                    isInitialized: false
                };
            }
        case BillboardActionTypes.INITIALIZE:
            {
                return {
                    ...state,
                    isInitializing: true,
                    isInitialized: false
                };
            }
        case BillboardActionTypes.FETCH_TRANSLATIONS:
            {
                return {
                    ...state,
                    translationsLoaded: false,
                    translationsLoading: true
                };
            }
        case BillboardActionTypes.FETCH_TRANSLATIONS_ERROR:
            {
                return {
                    ...state,
                    translationsLoaded: false,
                    translationsLoading: false
                };
            }
        case BillboardActionTypes.FETCH_TRANSLATIONS_SUCCESS:
            {
                return {
                    ...state,
                    translationsLoaded: true,
                    translationsLoading: false,
                    translations: action.translations
                };
            }
        case BillboardActionTypes.SAVE_GEOMARK_START:
            {
                return {
                    ...state,
                    geomarkSaving: true,
                };
            }
        case BillboardActionTypes.SAVE_GEOMARK_SUCCESS:
            {
                return {
                    ...state,
                    geomarkSaving: false,
                };
            }

        // Get Statuses
        case BillboardActionTypes.FETCH_BILLBOARD_STATUSES:
            return {
                ...state,
                billboadStatusesIsLoading: true,
                billboadStatusesError: undefined
            }

        case BillboardActionTypes.FETCH_BILLBOARD_STATUSES_ERROR:
            return {
                ...state,
                billboadStatusesIsLoading: false,
                billboadStatusesError: action.error
            }

        case BillboardActionTypes.FETCH_BILLBOARD_STATUSES_SUCCESS:
            const { replaceAll } = action;
            let newStatuses = action.statuses;
            // let newProjectBillboardStatuses = state.projectBillboardStatuses;
            // for(let status of newStatuses) {
            //     if(state.projectBillboardStatuses.some(s => s.geoMarkTimeStatusId === status.geoMarkTimeStatusId)) {
            //         continue;
            //     }
            //     newProjectBillboardStatuses = state.projectBillboardStatuses.concat(status);
            // }
            if (replaceAll === false){
                // нужно обновить только статусы указанных щитов
                // чтобы удалить Deleted
                const requestedBillboardIDs = action.billboardIDs;
                const filtered = state.billboadStatuses.filter(s => requestedBillboardIDs.indexOf(s.geoMarkId) < 0);
                    newStatuses = newStatuses.concat(filtered);
            }

            return {
                ...state,
                billboadStatusesIsLoading: false,
                billboadStatuses: newStatuses,
                // projectBillboardStatuses: newProjectBillboardStatuses
            }
        
        case BillboardActionTypes.CHANGE_BILLBOARD_STATUSES_SUCCESS:
            return {
                ...state,
                billboadStatusesIsLoading: false,
                billboadStatuses: action.statuses
            }

        // Save Statuses
        case BillboardActionTypes.SAVE_BILLBOARD_STATUS:
            return {
                ...state,
                saveStatusIsLoading: true,
                saveStatusError: undefined
            }

        case BillboardActionTypes.SAVE_BILLBOARD_STATUS_ERROR:
            return {
                ...state,
                saveStatusIsLoading: false,
                saveStatusError: action.error
            }

        case BillboardActionTypes.SAVE_BILLBOARD_STATUS_SUCCESS:
            return {
                ...state,
                saveStatusIsLoading: false,
                successSnackbar: action.successSnackbar
            }
        case BillboardActionTypes.SET_GEO_MARK_TARIFFS_SUCCESS:
            return {
                ...state,
                tariffSuccess: action.tariffSuccess
            }
        case BillboardActionTypes.CLEAR_BILLBOARD_STATUS_ERROR:
            return {
                ...state,
                saveStatusError: undefined
            }
        
        case BillboardActionTypes.CURRENT_BILLBOARD:
            return {
                ...state,
                billboard: action.billboard
            }
        case BillboardActionTypes.CURRENT_BILLBOARD_ID:
            const currentBillboard = state.billboards.find(item => item.id === action.billboardId);
            return {
                ...state,
                billboard: currentBillboard
            }
        case BillboardActionTypes.CHANGE_AREA_NAME:
            return {
                ...state,
                selectedAreaName: action.selectedAreaName
            }
        case BillboardActionTypes.CHANGE_CITY_NAME:
            return {
                ...state,
                selectedCityName: action.selectedCityName
            }
        case BillboardActionTypes.UPLOAD_PHOTO:
            return {
                ...state,
                photoVersion: action.photoVersion
            }
        case BillboardActionTypes.DELETE_PHOTO:
            return {
                ...state,
                photoVersion: action.photoVersion
            }
        case BillboardActionTypes.UPLOAD_PHOTO_SIDES:
            return {
                ...state,
                uploadedPhotos: action.uploadedPhotos
            }
        case BillboardActionTypes.GET_BILLBOARD_TARIFFS:
            return {
                ...state,
                billboardTariffs: action.billboardTariffs
            }
        case BillboardActionTypes.GET_BILLBOARD_TARIFFS_OWNER:
            return {
                ...state,
                billboardTariffsOwner: action.billboardTariffsOwner
            }
        // Delete projectBillboardStatuses and billboadStatuses
        case BillboardActionTypes.DELETE_GEOMARK_TIME_STATUS:
            // const newProjectStatus = state.projectBillboardStatuses.filter(status => !action.geoMarkTimeStatusIds.find((id: number) => status.geoMarkTimeStatusId === id))
            const newStatus = state.billboadStatuses.filter(status => !action.geoMarkTimeStatusIds.find((id: number) => status.geoMarkTimeStatusId === id))
            return {
                ...state,
                // projectBillboardStatuses: newProjectStatus,
                billboadStatuses: newStatus
            }
        // Для коммерческого предложения
        case BillboardActionTypes.FILTER_BILLBOARDS:
            return {
                ...state,
                filterBillboards: action.filterBillboards
            }
        case BillboardActionTypes.SUCCESS_SHACKBAR:
            return {
                ...state,
                successSnackbar: action.successSnackbar
            }
        case BillboardActionTypes.OPEN_PRICE:
            return {
                ...state,
                openPrice: action.openPrice
            }
        case BillboardActionTypes.OPEN_ADD_BILLBOARD:
            return {
                ...state,
                openAddBillboard: action.openAddBillboard
            }

        default:
            return state;
    }
};

class BillboardHelper {
    public static getBillBoards(geomarks: GeoMark[], properties: PropertyValueSet[]): Billboard[] {
        let result: Array<Billboard> = new Array<Billboard>();
        for (let i = 0; i < geomarks.length; i++) {
            let geomark = geomarks[i];
            if (!geomark || geomark.propertyValueSetId <= 0)
                continue;
            // var props = properties.find(pr => pr.propertyValueSetId === geomark.propertyValueSetId);
            // if (!props || !PropertiesHelper.arePropertiesValid(props)) {
            //     continue;
            // }
            // if (!props) {
            //     continue;
            // }
            var billboard = Billboard.createBillBoard(geomark, properties);
            if (billboard != null)
                result.push(billboard);
        }
        return result;
    }
}

export default billboardReducer;