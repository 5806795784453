import React, { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { BillboardsShortInfo } from '../../RemoteCommands/type';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    currentBillboards: BillboardsShortInfo[];
    onChange: (geoMarkId: number) => void;
    billboard: BillboardsShortInfo | undefined;
}

const ProjectNameAutocomplete:React.FC<Props> = (props) => {
    const { currentBillboards, onChange, billboard } = props;
    const [currentBillboard, setCurrentBillboard] = useState<BillboardsShortInfo | undefined>(billboard);

    const currentValueOnChange = (changeValue: BillboardsShortInfo) => {
        if(changeValue) {
            onChange(changeValue.geoMarkId);
        }
        setCurrentBillboard(changeValue);
    }

    return (
        <Autocomplete
            options={currentBillboards}
            getOptionLabel={(option: BillboardsShortInfo) => option.name}
            style={{ minWidth: 220}}
            value={currentBillboard}
            onChange={(event, changeValue) => currentValueOnChange(changeValue)}
            filterOptions={(options, state) => fliterOptions(options, state)}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    fullWidth
                    variant="outlined" 
                    size="small" 
                />
            }
        />
    );
}

const fliterOptions = (options: BillboardsShortInfo[], state: FilterOptionsState) => {
    const optionsArray: BillboardsShortInfo[] = options.filter(option => option.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 
        || option.name.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
    return optionsArray;
}

const mapStateToProps = (state: RootState) => ({
    
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNameAutocomplete);