import { GeoMarkStatus, ProjectGeoMarkStatus } from "../WebServerProxy";

export function getHighlightColor(status: ProjectGeoMarkStatus | GeoMarkStatus) : string {
    switch(status) {
        case ProjectGeoMarkStatus.Reserved :
            return '#ccc';
            break;
        case ProjectGeoMarkStatus.Booked :
            return '#ffce67';
            break;
        case ProjectGeoMarkStatus.Sold :
            return '#1ce9b5';
            break;
        case ProjectGeoMarkStatus.Paid :
            return '#4DB6AC';
            break;
        case ProjectGeoMarkStatus.New :
            return '#fff';
            break;
        case ProjectGeoMarkStatus._FreeOnlyForFrontend :
            return '#fff';
            break;
        default:
            return '#fff';
            break;
    }
}