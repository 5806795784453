import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = {
    message: string,
    variant: "success" | "error" | "warning",
    messageOpen: boolean,
    vertical: "top" | "bottom", 
    horizontal: "left" | "center" | "right",
    messageShackbarClose: () => void;
}

interface variantIcon {
    [key: string]: React.ComponentType<SvgIconProps>;
}

const variantIcon: variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
};

const MessageShackbar: React.FC<Props> = (props) =>  {
    const { message, variant, messageOpen, vertical, horizontal, messageShackbarClose } = props; 
    const Icon = variantIcon[variant];
    return (
        <Snackbar
            className={`messageShackbar ${variant}`}
            anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
            open={messageOpen}
            autoHideDuration={(variant === "error" || variant === "warning") ? null : 3000}
            onClose={messageShackbarClose}
            message={
                <span className="d-flex align-items-center">
                    <Icon className="mr-2" fontSize="small" />
                    {message}
                </span>
            }
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={messageShackbarClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        />
    );
}

export default MessageShackbar;