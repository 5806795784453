import React, { Dispatch, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import DeleteGeoObjectModal from './DeleteGeoObjectModal';
import EditGeomarkName from './EditGeomarkName';
import { IMultipleMarker } from '../map/types';
import { randomInteger } from '../../utils/randomInteger';
import { BrandGeoObjects, GeoObjectType } from '../../store/event/types';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
        selectedBrandMarker: (brandMarkers: IMultipleMarker[]) => void;
        geoMarks: BrandGeoObjects[] | null;
        setGeoMarks: React.Dispatch<React.SetStateAction<BrandGeoObjects[] | null>>
    }

const SearchOblectsTable:React.FC<Props> = (props) => {
    const { 
        brandGeoObjects,
        newBrandGeoObjects,
        selectedBrandMarker,
        geoMarks,
        addressValueAction,
        geoPointNullAction,
        setGeoMarks
    } = props;

    const [deleteGeoObjectOpen, setDeleteGeoObjectOpen] = useState(false);
    const [brandGeoObject, setBrandGeoObject] = useState<BrandGeoObjects | null>(null);

    const deleteGeoObjectClose = () => {
        setDeleteGeoObjectOpen(false);
    }

    const deleteGeoObject = (brandGeoObject: BrandGeoObjects) => {
        setBrandGeoObject(brandGeoObject);
        setDeleteGeoObjectOpen(true);
    }

    const showMarkerMapClick = (brandGeoObject: BrandGeoObjects) => {
        const brandMarker: IMultipleMarker = {
            id: randomInteger(1, 1000),
            address: brandGeoObject.Address,
            center: {
                lat: brandGeoObject.GeoPoint!.Latitude,
                lng: brandGeoObject.GeoPoint!.Longitude,
            },
            marker: null
        };

        selectedBrandMarker([brandMarker]);
    }

    const addAddressClick = (item: BrandGeoObjects) => {
        if(!item.GeoPoint) {
            const value = item.Address 
            ? item.Address.AddressString 
                || (item.Address.CityName + " " +  item.Address.StreetName + " " + item.Address.HouseNumber) 
            : "";
            addressValueAction(value);
            geoPointNullAction(true);
        }
    }

    const importGeoMarks = () => {
        if(geoMarks) {
            return geoMarks.map((item, i) => {
                return (
                    <TableRow 
                        key={'geoObject' + i}
                        style={item.GeoPoint ? {} : {backgroundColor: "rgb(255 255 0 / 9%)", cursor: "pointer"}}
                        onClick={() => addAddressClick(item)}>
                        <TableCell align="left">
                            {item.GeoMarkName}
                        </TableCell>
                        <TableCell align="left">
                            {item.Address 
                                ? item.Address.AddressString 
                                    || (item.Address.CityName + ", " +  item.Address.StreetName + ", " + item.Address.HouseNumber) 
                                : ""
                            }
                        </TableCell>
                        <TableCell align="center">
                            {item.GeoPoint &&
                                <>
                                    <div>{item.GeoPoint.Latitude}</div>
                                    <div>{item.GeoPoint.Longitude}</div>
                                </>
                            }
                        </TableCell>
                        <TableCell align="center">
                            <Button 
                                color="primary"
                                size="small"
                                onClick={() => showMarkerMapClick(item)}>
                                Показать
                            </Button>
                        </TableCell>
                        <TableCell align="center">
                            <Button 
                                color="primary"
                                size="small"
                                onClick={() => deleteGeoObject(item)}>
                                Удалить
                            </Button>
                        </TableCell>
                    </TableRow>
                )
            })
        }
    }

    return (
        <>
            <TableContainer component={Paper} className="calculateDistance">
                <Table className="calculateDistance__table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название геообъекта</TableCell>
                            <TableCell align="left">Адрес геообъекта</TableCell>
                            <TableCell align="center">Координаты геообъекта</TableCell>
                            <TableCell align="center">Показать на карте</TableCell>
                            <TableCell align="center">Удалить</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {importGeoMarks()}
                        {newBrandGeoObjects.map((item) => {
                            return (
                                <TableRow 
                                    key={'geoObject' + item.GeoMarkId}>
                                    <EditGeomarkName 
                                        geoObjectType={GeoObjectType.newGeoObject} 
                                        brandGeoObject={item} />
                                    <TableCell align="left">
                                        {item.Address ? item.Address.AddressString : ""}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.GeoPoint &&
                                            <>
                                                <div>{item.GeoPoint.Latitude}</div>
                                                <div>{item.GeoPoint.Longitude}</div>
                                            </>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button 
                                            color="primary"
                                            size="small"
                                            onClick={() => showMarkerMapClick(item)}>
                                            Показать
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button 
                                            color="primary"
                                            size="small"
                                            onClick={() => deleteGeoObject(item)}>
                                            Удалить
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }).reverse()}
                        {brandGeoObjects.map((item) => {
                            return (
                                <TableRow 
                                    key={item.GeoMarkId} 
                                    style={{backgroundColor: "rgb(40 167 69 / 10%)"}}>
                                    <EditGeomarkName 
                                        geoObjectType={GeoObjectType.geoObject} 
                                        brandGeoObject={item} />
                                    <TableCell align="left">
                                        {item.Address 
                                            ? item.Address.AddressString 
                                                || (item.Address.CityName + ", " +  item.Address.StreetName + ", " + item.Address.HouseNumber) 
                                            : ""
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.GeoPoint &&
                                            <>
                                                <div>{item.GeoPoint.Latitude}</div>
                                                <div>{item.GeoPoint.Longitude}</div>
                                            </>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button 
                                            color="primary"
                                            size="small"
                                            onClick={() => showMarkerMapClick(item)}>
                                            Показать
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button 
                                            color="primary"
                                            size="small"
                                            onClick={() => deleteGeoObject(item)}>
                                            Удалить
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }).reverse()}
                    </TableBody>
                </Table>
            </TableContainer>
            {brandGeoObject && 
                <DeleteGeoObjectModal 
                    deleteGeoObjectOpen={deleteGeoObjectOpen} 
                    deleteGeoObjectClose={deleteGeoObjectClose} 
                    brandGeoObject={brandGeoObject} />
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    brandGeoObjects: state.event.brandGeoObjects,
    newBrandGeoObjects: state.event.newBrandGeoObjects
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),
    geoPointNullAction: (geoPointNull: boolean) => 
        dispatch(actions.event.geoPointNullAction(geoPointNull)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchOblectsTable);