import { Reducer } from "redux";
import { BrandGeoObjects, EventState, SelectedFocus, StatisticsType } from "./types";
import { EventActions } from "./actions";
import { EventActionsTypes } from "./types";

const defaultState: EventState = {
    eventName: '',
    eventPatterns: {},
    agencyInfo: [],
    messageToUsers: [],
    currentAgency: null,
    agenciesOpen: false,
    statusStatisticsFull: [],
    statusStatistics: [],
    addNewAgency: false,
    errorMessage: '',
    successMessage: null,
    maxDistance: '500',
    maxDistanceSearch: false,
    showMarkers: false,
    addresses: [],
    selectedAddress: null,
    brandGeoObjects: [],
    newBrandGeoObjects: [],
    loading: false,
    openNextBillboard: false,
    addressValue: '',
    searchGeoObjectsToggle: false,
    setBrandBoolean: false,
    selectedFocus: SelectedFocus.none,
    salesStatisticsToggle: false,
    eventError: null,
    geoPointNull: false,
    monthYear: [],
    currentMonthYear: null,
    rollUpCards: [],
    statisticsType: StatisticsType.sale,
    fullMonthYear: false,
    additionalCost: 0,
    curretnSale: null,
    changeProjectStatus: false,
    disabledScoreSave: false,
    useAllFreeGeoMarkParts: false,
    usageInfo: null,
    openEmployeesCard: false,
    employeesCount: 0,
    additionalCostParameters: [],
    disabledEdit: false,
    openAdditionalTariff: false,
    additionalTariffs: [],
    additionalGeoMarkTariffs: [],
    additionalGeoMarkTariff: null,
    messageInfo: null,
    regions: [],
    cities: [],
    projectStatistics: false,
    cityStatistics: false,
    openSalesInformation: false,
    openCurrentSales: false,
    zIndex: 9,
    gid: undefined,
    dontSale: false,
    openBillsCard: false,
    openPhotocontrolCard: false
};

export const authReducer: Reducer<EventState, EventActions> = (state = defaultState, action) => {
    switch (action.type) {
        case EventActionsTypes.DONT_SALE:
            return {
                ...state,
                dontSale: action.dontSale
            };
        case EventActionsTypes.GID:
            return {
                ...state,
                gid: action.gid
            };
        case EventActionsTypes.Z_INDEX:
            return {
                ...state,
                zIndex: action.zIndex
            };
        case EventActionsTypes.OPEN_CURRENT_SALES:
            return {
                ...state,
                openCurrentSales: action.openCurrentSales
            };
        case EventActionsTypes.OPEN_SALES_INFORMATION:
            return {
                ...state,
                openSalesInformation: action.openSalesInformation
            };
        case EventActionsTypes.CITY_STATISTICS:
            return {
                ...state,
                cityStatistics: action.cityStatistics
            };
        case EventActionsTypes.PROJECT_STATISTICS:
            return {
                ...state,
                projectStatistics: action.projectStatistics
            };
        case EventActionsTypes.CITIES:
            return {
                ...state,
                cities: action.cities
            };
        case EventActionsTypes.REGIONS:
            return {
                ...state,
                regions: action.regions
            };
        case EventActionsTypes.MESSAGE_INFO:
            return {
                ...state,
                messageInfo: action.messageInfo
            };
        case EventActionsTypes.ADDITIONAL_GEO_MARK_TARIFF:
            return {
                ...state,
                additionalGeoMarkTariff: action.additionalGeoMarkTariff
            };
        case EventActionsTypes.ADDITIONAL_GEO_MARK_TARIFFS:
            return {
                ...state,
                additionalGeoMarkTariffs: action.additionalGeoMarkTariffs
            };
        case EventActionsTypes.ADITIONAL_TARIFFS:
            return {
                ...state,
                additionalTariffs: action.additionalTariffs
            };
        case EventActionsTypes.OPEN_ADITIONAL_TARIFF:
            return {
                ...state,
                openAdditionalTariff: action.openAdditionalTariff
            };
        case EventActionsTypes.OPEN_BILLS_CARD:
            return {
                ...state,
                openBillsCard: action.openBillsCard
            };
        case EventActionsTypes.DISABLED_EDIT:
            return {
                ...state,
                disabledEdit: action.disabledEdit
            };
        case EventActionsTypes.ADDITIONAL_COST_PARAMETETS:
            return {
                ...state,
                additionalCostParameters: action.additionalCostParameters
            };
        case EventActionsTypes.EMPLOYEES_COUNT:
            return {
                ...state,
                employeesCount: action.employeesCount
            };
        case EventActionsTypes.OPEN_EMPLOYEES_CARD:
            return {
                ...state,
                openEmployeesCard: action.openEmployeesCard
            };
        case EventActionsTypes.USAGE_INFO:
            return {
                ...state,
                usageInfo: action.usageInfo
            };
        case EventActionsTypes.USER_ALL_FREE_GOE_MARK_PARTS:
            return {
                ...state,
                useAllFreeGeoMarkParts: action.useAllFreeGeoMarkParts
            };
        case EventActionsTypes.DISABLED_SCORE_SAVE:
            return {
                ...state,
                disabledScoreSave: action.disabledScoreSave
            };
        case EventActionsTypes.CHANGE_PROJECT_STATUS:
            return {
                ...state,
                changeProjectStatus: action.changeProjectStatus
            };
        case EventActionsTypes.CURRENT_SALE:
            return {
                ...state,
                curretnSale: action.curretnSale
            };
        case EventActionsTypes.ADDITIONAL_COST:
            return {
                ...state,
                additionalCost: action.additionalCost
            };
        case EventActionsTypes.FULL_MONTH_YEAR:
            return {
                ...state,
                fullMonthYear: action.fullMonthYear
            };
        case EventActionsTypes.STATISTICS_TYPE:
            return {
                ...state,
                statisticsType: action.statisticsType
            };
        case EventActionsTypes.MONTH_YEAR:
            return {
                ...state,
                monthYear: action.monthYear
            };
        case EventActionsTypes.ROLL_UP_CARDS:
            return {
                ...state,
                rollUpCards: action.rollUpCards
            };
        case EventActionsTypes.ROLL_UP_CARD:
            const rollUpCards = state.rollUpCards.concat(action.rollUpCard);
            return {
                ...state,
                rollUpCards
            };
        case EventActionsTypes.CURRENT_MONTH_YEAR:
            return {
                ...state,
                currentMonthYear: action.currentMonthYear
            };
        case EventActionsTypes.GEO_POINT_NULL:
            return {
                ...state,
                geoPointNull: action.geoPointNull
            };
        case EventActionsTypes.EVENT_ERROR:
            return {
                ...state,
                eventError: action.eventError
            };
        case EventActionsTypes.SALES_STATISTICS_TOGGLE:
            return {
                ...state,
                salesStatisticsToggle: action.salesStatisticsToggle
            };
        case EventActionsTypes.SELECTED_FOCUS:
            return {
                ...state,
                selectedFocus: action.selectedFocus
            };
        case EventActionsTypes.SET_BRAND_BOOLEAN:
            return {
                ...state,
                setBrandBoolean: action.setBrandBoolean
            };
        case EventActionsTypes.SEARCH_GEO_OBJECTS_TOGGLE:
            return {
                ...state,
                searchGeoObjectsToggle: action.searchGeoObjectsToggle
            };
        case EventActionsTypes.ADDRESS_VALUE:
            return {
                ...state,
                addressValue: action.addressValue
            };
        case EventActionsTypes.OPEN_NEXT_BILLBOARD:
            return {
                ...state,
                openNextBillboard: action.openNextBillboard
            };
        case EventActionsTypes.LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case EventActionsTypes.GET_BRAND_GEO_OBJECTS:
            return {
                ...state,
                brandGeoObjects: action.brandGeoObjects
            };
        case EventActionsTypes.GET_NEW_BRAND_GEO_OBJECTS:
            return {
                ...state,
                newBrandGeoObjects: action.newBrandGeoObjects
            };
        case EventActionsTypes.GET_BRAND_GEO_OBJECT:
            let brandGeoObjectArray: BrandGeoObjects[] = state.brandGeoObjects;
            let newBrandGeoObjectArray: BrandGeoObjects[] = state.newBrandGeoObjects;
            if(brandGeoObjectArray.find(marker => marker.GeoMarkId === action.brandGeoObject.GeoMarkId)) {
                const idx = state.brandGeoObjects.findIndex((item) => item.GeoMarkId === action.brandGeoObject.GeoMarkId);
                const items = [
                    ...brandGeoObjectArray.slice(0, idx),
                    ...brandGeoObjectArray.slice(idx + 1)
                ];
                brandGeoObjectArray = items
            }

            if(newBrandGeoObjectArray.find(marker => marker.GeoMarkId === action.brandGeoObject.GeoMarkId)) {
                const idx = state.newBrandGeoObjects.findIndex((item) => item.GeoMarkId === action.brandGeoObject.GeoMarkId);
                const items = [
                    ...newBrandGeoObjectArray.slice(0, idx),
                    ...newBrandGeoObjectArray.slice(idx + 1)
                ];
                newBrandGeoObjectArray = items
            }

            if(action.brandGeoObject.GeoMarkId > 0) {
                brandGeoObjectArray = brandGeoObjectArray.concat(action.brandGeoObject);
            } else {
                newBrandGeoObjectArray = newBrandGeoObjectArray.concat(action.brandGeoObject);
            }

            return {
                ...state,
                brandGeoObjects: brandGeoObjectArray,
                newBrandGeoObjects: newBrandGeoObjectArray
            };
        case EventActionsTypes.SELECTED_ADDRESS:
            return {
                ...state,
                selectedAddress: action.selectedAddress
            };
        case EventActionsTypes.GET_ADDRESSES:
            return {
                ...state,
                addresses: action.addresses
            };
        case EventActionsTypes.SHOW_MARKERS:
            return {
                ...state,
                showMarkers: action.showMarkers
            };
        case EventActionsTypes.MAX_DISTANCE_SEARCH:
            return {
                ...state,
                maxDistanceSearch: action.maxDistanceSearch
            };
        case EventActionsTypes.MAX_DISTANCE:
            return {
                ...state,
                maxDistance: action.maxDistance
            };
        case EventActionsTypes.ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        case EventActionsTypes.SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.successMessage
            };
        case EventActionsTypes.ADD_NEW_AGENCY:
            return {
                ...state,
                addNewAgency: action.addNewAgency
            };
        case EventActionsTypes.STATUS_STATISTICS_FULL:
            return {
                ...state,
                statusStatisticsFull: action.statusStatisticsFull
            };
        case EventActionsTypes.STATUS_STATISTICS:
            return {
                ...state,
                statusStatistics: action.statusStatistics
            };
        case EventActionsTypes.AGENCIES_OPEN:
            return {
                ...state,
                agenciesOpen: action.agenciesOpen
            };
        case EventActionsTypes.EVENT_NAME:
            return {
                ...state,
                eventName: action.eventName
            };
        case EventActionsTypes.EVENT_PATTERNS:
            return {
                ...state,
                eventPatterns: action.eventPatterns
            };
        case EventActionsTypes.GET_AGENCY_INFO:
            return {
                ...state,
                agencyInfo: action.agencyInfo
            };
        case EventActionsTypes.MESSAGE_TO_USER:
            const newMessageToUsers = state.messageToUsers.concat(action.messageToUser);
            return {
                ...state,
                messageToUsers: newMessageToUsers
            };
        case EventActionsTypes.MESSAGE_TO_USERS:
            return {
                ...state,
                messageToUsers: action.messageToUser
            };
        case EventActionsTypes.CURRENT_AGENCY:
            return {
                ...state,
                currentAgency: action.currentAgency
            };
        case EventActionsTypes.OPEN_PHOTOCONTROL_CARD:
            return {
                ...state,
                openPhotocontrolCard: action.openPhotocontrolCard
            };
        default:
            return state;
    }
};

export default authReducer;