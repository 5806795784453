import React, { Dispatch, useEffect, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent,  
    DialogTitle, 
    Grid,
    IconButton
} from '@material-ui/core';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { GeoMarkTimeStatus } from 'sedi-webserverproxy';
import { SystemEvent } from '../../../RemoteCommands/SystemEvent';
import CloseIcon from '@material-ui/icons/Close';
import { DatesRange } from '../../bricks/InputDatesRange';
import { actions, RootState } from '../../../store';
import { Billboard } from '../../../store/billboards/types';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    duplicateOpen: boolean;
    duplicateClickClose: () => void;
    geoMarkTimeStatuses: GeoMarkTimeStatus[];
    GIDs: string[];
    setOpen: (value: React.SetStateAction<boolean>) => void;
    setDisabled: (value: React.SetStateAction<boolean>) => void;
}

const AddGeoMarkArrayConfirm:React.FC<Props> = (props) => {
    const {
        duplicateOpen,
        duplicateClickClose,
        currentGeoMarkStatus,
        projectID,
        geoMarkTimeStatuses,
        GIDs,
        projects,
        setOpen,
        setDisabled,
        filterBillboardsAction,
        useAllFreeGeoMarkParts,
        allFreeGeoMarkPartsAction
    } = props;

    const curretnProject = projects.find(item => item.projectId === projectID);

    const [dates, setDates] = useState<DatesRange>({
        DateFrom: curretnProject && moment(curretnProject.dateFrom, "YYYY-MM-DD").toDate(),
        DateTo: curretnProject && moment(curretnProject.dateTo, "YYYY-MM-DD").toDate()
    });

    useEffect(() => {
        setDates({
            DateFrom: curretnProject && moment(curretnProject.dateFrom, "YYYY-MM-DD").toDate(),
            DateTo: curretnProject && moment(curretnProject.dateTo, "YYYY-MM-DD").toDate()
        })
    }, [geoMarkTimeStatuses]);

    const duplicateClick = (_geoMarkTimeStatuses: GeoMarkTimeStatus[]) => {
        setDisabled(true);
        let newStatuses: GeoMarkTimeStatus[] = [];

        for(let geoMarkTimeStatus of _geoMarkTimeStatuses) {
            newStatuses.push({
                dateTimeEnd: moment(dates.DateTo).format('YYYY-MM-DDTHH:mm:ss'),
                dateTimeStart: moment(dates.DateFrom).format('YYYY-MM-DDTHH:mm:ss'),
                geoMarkId: geoMarkTimeStatus.geoMarkId,
                geoMarkPart: geoMarkTimeStatus.geoMarkPart,
                geoMarkStatus: currentGeoMarkStatus.value,
                geoMarkTimeStatusId: -1,
                projectId: projectID
            });
        }
        SystemEvent.EventSetGeoMarkTimeStatus(newStatuses, useAllFreeGeoMarkParts);
        duplicateClickClose();
        setTimeout(() => {
            setDisabled(false);
            setOpen(false);
            filterBillboardsAction([]);
        }, 1000);
        allFreeGeoMarkPartsAction(false);
    }

    const handleChangeDate = (isFirstDate: boolean, date: Date | null) => {
        const newDateValue = date ? date : undefined;
        if(isFirstDate) {
            setDates(prevState => ({
                ...prevState,
                DateFrom: newDateValue
            }))
        } else {
            setDates(prevState => ({
                ...prevState,
                DateTo: newDateValue
            }))
        }

        const dateFrom = moment(dates.DateFrom);
        const dateTo = moment(dates.DateTo);
        if(date) {
            if((isFirstDate && date) > (!isFirstDate && date)) {
                const durationDates = moment.duration(dateTo.diff(dateFrom)).asDays();
                setDates(prevState => ({
                    ...prevState,
                    DateTo: moment(dateTo).add(durationDates, 'days').toDate()
                }))
            }
        }
    }

    return (
        <Dialog
            open={duplicateOpen}
            className="duplicateConfirm">
            <DialogTitle className="duplicateConfirm__title">
                Добавить {
                    GIDs.map((item, index) => (
                        <span key={`show-gid-${index}`}>{ (index ? ', ' : '') + item }</span>)
                    )
                } поверхностей в проект: {curretnProject && curretnProject.projectName}
                <IconButton
                    className="duplicateConfirm__close"
                    onClick={duplicateClickClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item>
                        <DatePicker
                            selected={dates.DateFrom}
                            onChange={(date: Date | null) => handleChangeDate(true, date)}
                            selectsStart
                            startDate={dates.DateFrom}
                            endDate={dates.DateTo}
                            dateFormat="dd.MM.yyyy"
                            locale="ru"
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            selected={dates.DateTo}
                            onChange={(date: Date | null) => handleChangeDate(false, date)}
                            selectsStart
                            startDate={dates.DateFrom}
                            endDate={dates.DateTo}
                            dateFormat="dd.MM.yyyy"
                            locale="ru"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={duplicateClickClose} 
                    color="secondary">
                    Нет
                </Button>
                <Button 
                    color="primary" 
                    autoFocus
                    onClick={() => duplicateClick(geoMarkTimeStatuses)}>
                    Да
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state: RootState) => ({
    currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
    projectID: state.todo.projectID,
    projects: state.todo.projects,
    useAllFreeGeoMarkParts: state.event.useAllFreeGeoMarkParts
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    filterBillboardsAction: (filterBillboards: Billboard[]) => dispatch(actions.billboards.filterBillboardsAction(filterBillboards)),
    allFreeGeoMarkPartsAction: (useAllFreeGeoMarkParts: boolean) => 
        dispatch(actions.event.useAllFreeGeoMarkPartsAction(useAllFreeGeoMarkParts)),
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(AddGeoMarkArrayConfirm);