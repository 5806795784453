import { ClientItem, ClientType } from "../store/customers/types";
import { AgencyInfo } from "../store/event/types";
import { ClientInfo } from "../WebServerProxy";

export function clientsArray(companies: ClientInfo[], customers: ClientInfo[], agencies: AgencyInfo[]) {
    const clientInfo = companies.concat(customers);
    const client: ClientItem[] = [];
    for(let i = 0; i < clientInfo.length; i++) {
        client.push(
            new ClientItem(
                clientInfo[i].accountId, 
                clientInfo[i].name,
                clientInfo[i].isLegalEntity ? ClientType.company : ClientType.customer
            )
        )
    }
    for(let i = 0; i < agencies.length; i++) {
        client.push(
            new ClientItem(
                agencies[i].AccountId, 
                agencies[i].AgencyName,
                ClientType.agency,
            )
        )
    }
    return client;
}