import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { 
    Avatar, Button, CircularProgress, Dialog, DialogActions, 
    DialogContent, DialogTitle, IconButton, MenuItem,
    Menu, 
    Grid,
    TextField,
    Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { EmployeeInfoRole, IDetailedEmployeeInfo, Phones } from '../../RemoteCommands/type';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import GetAddressAutocomplete from '../bricks/GetAddressAutocomplete';
import './EmployeeModal.scss';
import GetCityAutocomplete from '../bricks/GetCityAutocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    employeeOpen: boolean;
    employeeClickClose: () => void;
    employeeDetailInfo: IDetailedEmployeeInfo;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setEmployeeId: (value: React.SetStateAction<number>) => void
}

let employeeRoles: EmployeeInfoRole[] = [
    {groupId: 48, role: "SalesManager", roleAllowed: true},
    {groupId: 48, role: "SalesDirector", roleAllowed: true},
    {groupId: 48, role: "DataOperator", roleAllowed: true},
    {groupId: 48, role: "AdminPMS", roleAllowed: true}
];

const EmployeeModal:React.FC<Props> = (props) => {
    const {
        employeeOpen,
        employeeClickClose,
        employeeDetailInfo,
        loading,
        setLoading,
        selectedAddress,
        setEmployeeId,
        addressValueAction
    } = props;

    const [edit, setEdit] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const inputPhoneFocus = useRef<HTMLInputElement>(null);
    const inputCountryFocus = useRef<HTMLInputElement>(null);
    const [employeeDetailInfoState, setEmployeeDetailInfoState] = useState<IDetailedEmployeeInfo>(employeeDetailInfo);
    
    useEffect(() => {
        setEmployeeDetailInfoState(employeeDetailInfo);
    }, [employeeDetailInfo]);

    const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAddClose = () => {
        setAnchorEl(null);
    };

    const addNewPhoneClick = () => {
        const phone: Phones = {
            number: "",
            phoneType: "MobileWork"
        }
        setEmployeeDetailInfoState(prevState => ({
            ...prevState!,
            phones: prevState!.phones.concat(phone)
        }));
        handleAddClose();
    }

    const addNewAddressClick = () => {
        if(employeeDetailInfoState.address === null) {
            setEmployeeDetailInfoState(prevState => ({
                ...prevState,
                address: {
                    countryName: "",
                    cityName: "",
                    streetName: "",
                    houseNumber: "",
                    apartmentNumber: "",
                    geoPoint: {
                        latitude: 0,
                        longitude: 0
                    }
                }
            }));
        }
        handleAddClose();
    }

    useEffect(() => {
        // if(inputPhoneFocus && inputPhoneFocus.current) {
        //     inputPhoneFocus.current.focus();
        // }
        // if(inputCountryFocus && inputCountryFocus.current) {
        //     inputCountryFocus.current.focus();
        // }
        if(employeeDetailInfoState && employeeDetailInfoState.employeeId === -1) {
            setEdit(true);
        }
        if(employeeDetailInfoState.address) {
            let addressString = employeeDetailInfoState.address.streetName +' '+ employeeDetailInfoState.address.houseNumber;
            addressValueAction(addressString);
        }
    }, [employeeDetailInfoState]);

    const modalClose = () => {
        employeeClickClose();
        setEdit(false);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i?: number) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "phones") {
            setEmployeeDetailInfoState(prevState => ({
                ...prevState,
                [name[i!]]: prevState.phones[i!].number = value
            }));
        } else if(name === "cityName" || name === "streetName" || name === "houseNumber" || name === "apartmentNumber") {
            setEmployeeDetailInfoState(prevState => ({
                ...prevState,
                address: {
                    ...prevState.address!,
                    [name]: value
                }
            }));
        } else if(name === "latitude" || name === "longitude") {
            setEmployeeDetailInfoState(prevState => ({
                ...prevState,
                address: {
                    ...prevState.address!,
                    geoPoint: {
                        ...prevState.address!.geoPoint!,
                        [name]: value
                    }
                }
            }));
        } else {
            setEmployeeDetailInfoState(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    }

    const handleCityChange = (newValue: string) => {
        setEmployeeDetailInfoState(prevState => ({
            ...prevState,
            address: {
                ...prevState.address!,
                cityName: newValue
            }
        }));
    }

    useEffect(() => {
        if(selectedAddress) {
            setEmployeeDetailInfoState(prevState => ({
                ...prevState,
                address: {
                    ...prevState.address!,
                    countryName: selectedAddress.co,
                    cityName: selectedAddress.t === "c" ? selectedAddress.v.replace(/ *\([^)]*\) */g, "") : selectedAddress.c.replace(/ *\([^)]*\) */g, ""),
                    streetName: selectedAddress.s,
                    houseNumber: selectedAddress.t === "h" ? selectedAddress.v : undefined,
                    apartmentNumber: "",
                    geoPoint: {
                        latitude: selectedAddress.g.lat,
                        longitude: selectedAddress.g.lon
                    }
                }
            }));
        }
    }, [selectedAddress]);

    const onCurrentRoleChange = (roles: EmployeeInfoRole[]) => {
        setEmployeeDetailInfoState(prevState => ({
            ...prevState,
            roles
        }));
    }

    useEffect(() => {
        SystemEvent.SubscribeEventChangeEmployeeData(
            "EventChangeEmployeeData", 
            (answer) => {
                setLoading(false);
                setEdit(false);
                setEmployeeId(answer.employeeId);
            }, 
            (error) => {
                setLoading(false);
                alert(error.errorMessage);
                errorCallback(error);
            }
        );
    }, []);

    const saveEmployeeClick = () => {
        setLoading(true);
        SystemEvent.EventChangeEmployeeData(employeeDetailInfoState);
    }

    const employeeInfoView = () => {
        if(loading) {
            return (
                <div className="loading">
                    <CircularProgress />
                </div>
            )
        } else {
            if(employeeDetailInfo) {
                return (
                    <DialogContent>
                        <div className="employee-modal__head">
                            <div className="employee-modal__head-left">
                                <Avatar className="employee-modal__avatar">
                                    <PersonOutlineOutlinedIcon />
                                </Avatar>
                                <div className="employee-modal__photo-icon">
                                    <PhotoCameraOutlinedIcon />
                                </div>
                            </div>
                            <div className="employee-modal__head-right">
                                <TextField
                                    className="cancelDraggable employee-modal__text-field"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    required={true}
                                    disabled={!edit}
                                    label="Имя"
                                    name="firstName" 
                                    value={employeeDetailInfoState.firstName}
                                    onChange={(event) => handleChange(event)}
                                />
                                <TextField
                                    className="cancelDraggable employee-modal__text-field"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    required={true}
                                    disabled={!edit}
                                    label="Фамилия" 
                                    name="secondName" 
                                    value={employeeDetailInfoState.secondName}
                                    onChange={(event) => handleChange(event)}
                                />
                            </div>
                        </div>
                        <div className="employee-modal__content">
                            <div className="employee-modal__title">Контактные данные</div>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="email"
                                size="small"
                                fullWidth
                                required={true}
                                disabled={!edit}
                                label="Электронный адрес" 
                                name="email" 
                                value={employeeDetailInfoState.email}
                                onChange={(event) => handleChange(event)}
                            />
                            {employeeDetailInfoState.phones.map((phone, i) => (
                                <TextField
                                    key={`phone-${i}`}
                                    className="cancelDraggable employee-modal__text-field"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    disabled={!edit}
                                    inputRef={inputPhoneFocus}
                                    label="Мобильный телефон" 
                                    name="phones" 
                                    value={phone.number}
                                    onChange={(event) => handleChange(event, i)}
                                />
                            ))}
                        </div>
                        {addressView(employeeDetailInfoState)}
                        <div className="employee-modal__content">
                            <div className="employee-modal__title">Примечания</div>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                multiline
                                rows={4}
                                variant="outlined"
                                inputRef={inputPhoneFocus}
                                label="Добавьте примечания" 
                                name="notes" 
                                value={employeeDetailInfoState.notes}
                                onChange={(event) => handleChange(event)}
                            />
                        </div>
                        <Autocomplete
                            multiple
                            options={employeeRoles}
                            disableCloseOnSelect
                            className="multiInputAutocomplate cancelDraggable"
                            getOptionLabel={(option: EmployeeInfoRole) => option.role}
                            onChange={(event: React.ChangeEvent<{}>, changeValue: EmployeeInfoRole[]) => onCurrentRoleChange(changeValue)}
                            defaultValue={employeeDetailInfoState.roles}
                            value={employeeDetailInfoState.roles}
                            disabled={!edit}
                            renderOption={(option: EmployeeInfoRole, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.role}
                                </React.Fragment>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={`Роли`}
                                    fullWidth
                                    size="small"
                                    className="multiInput"
                                />
                            )}
                        />
                    </DialogContent>
                )
            }
            
        }
    }

    const addressView = (employeeDetailInfo: IDetailedEmployeeInfo) => {
        const { address } = employeeDetailInfo;
        if(address) {
            return (
                <div className="employee-modal__content">
                    <div className="employee-modal__title">Адрес</div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {/* <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                variant="outlined"
                                fullWidth
                                disabled={!edit}
                                required={true}
                                inputRef={inputPhoneFocus}
                                label="Город" 
                                name="cityName" 
                                value={address.cityName}
                                onChange={(event) => handleChange(event)}
                            /> */}
                            <GetCityAutocomplete 
                                addressInfo={null} 
                                placeholder="Город*" 
                                value={address.cityName}
                                cityName={address.cityName}
                                disabled={!edit} 
                                inputClassName="employee-modal__address" 
                                onChange={(value: string) => handleCityChange(value)} />
                        </Grid>
                        <Grid item xs={9}>
                            <GetAddressAutocomplete 
                                addressInfo={null} 
                                placeholder="Улица, номер дома" 
                                disabled={!edit} 
                                inputClassName="employee-modal__address" 
                                cityName={address.cityName} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                inputRef={inputPhoneFocus}
                                label="Квартира" 
                                name="apartmentNumber" 
                                value={address.apartmentNumber}
                                onChange={(event) => handleChange(event)}
                                ref={inputCountryFocus}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                required={true}
                                inputRef={inputPhoneFocus}
                                label="Страна" 
                                name="countryName" 
                                value={address.countryName}
                                onChange={(event) => handleChange(event)}
                            />
                        </Grid> */}
                        {/* <Grid item xs={6}>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                inputRef={inputPhoneFocus}
                                label="Улица" 
                                name="streetName" 
                                value={address.streetName}
                                onChange={(event) => handleChange(event)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                inputRef={inputPhoneFocus}
                                label="Номер дома" 
                                name="houseNumber" 
                                value={address.houseNumber}
                                onChange={(event) => handleChange(event)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                inputRef={inputPhoneFocus}
                                label="Номер квартиры" 
                                name="apartmentNumber" 
                                value={address.apartmentNumber}
                                onChange={(event) => handleChange(event)}
                                ref={inputCountryFocus}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                required={true}
                                inputRef={inputPhoneFocus}
                                label="Широта" 
                                name="latitude" 
                                value={address.geoPoint!.latitude}
                                onChange={(event) => handleChange(event)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                required={true}
                                inputRef={inputPhoneFocus}
                                label="Долгота" 
                                name="longitude" 
                                value={address.geoPoint!.longitude}
                                onChange={(event) => handleChange(event)}
                            />
                        </Grid> */}
                    </Grid>
                </div>
            )
        }
    }

    const employeeTitle = () => {
        if(employeeDetailInfo && employeeDetailInfo.employeeId > 0) {
            if(edit) {
                return "Редактировать сотрудника";
            }
        } else {
            return "Добавить сотрудника";
        }
    }

    const employeeInfoBtnGroup = () => {
        if(edit) {
            return (
                <DialogActions>
                    <Button
                        color="primary"
                        startIcon={<AddIcon />}
                        endIcon={<ExpandMoreIcon />}
                        onClick={handleAddClick}
                    >
                        Добавить
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleAddClose}>
                        <MenuItem onClick={addNewPhoneClick}>Телефон</MenuItem>
                        {(employeeDetailInfo && employeeDetailInfo.address === null || employeeDetailInfo === null) &&
                            <MenuItem onClick={addNewAddressClick}>Адрес</MenuItem>
                        }
                    </Menu>
                    <Button 
                        className="mr-2" 
                        variant="contained" 
                        color="secondary"
                        disableElevation
                        onClick={() => setEdit(false)}>
                        Отмена
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary"
                        disableElevation
                        onClick={saveEmployeeClick}>
                        {employeeDetailInfo ? "Сохранить" : "Создать"}
                    </Button>
                </DialogActions>
            )
        }
    }

    return (
        <Dialog
            open={employeeOpen}
            onClose={modalClose}
            fullWidth
            maxWidth="sm"
            className="employee-modal">
            <DialogTitle>
                <IconButton 
                    style={{ position: 'absolute', top: '5px', right: '5px' }}
                    onClick={modalClose}>
                    <CloseIcon />
                </IconButton>
                {employeeDetailInfo && !edit &&
                    <IconButton 
                        style={{ position: 'absolute', top: '5px', right: '45px' }}
                        onClick={() => setEdit(true)}>
                        <EditIcon />
                    </IconButton>
                }
                {employeeTitle()}
            </DialogTitle>
            {employeeInfoView()}
            {employeeInfoBtnGroup()}
        </Dialog>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        selectedAddress: state.event.selectedAddress,
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),  
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeModal);