export enum RemoteCommandName {
  Ping = 'Ping',
  SendAction = 'SendAction',
  Login = 'Login',
  LoginWithLicenceKey = 'LoginWithLicenceKey',
  Logout = 'Logout',
  SendActivationKey = 'SendActivationKey',
  LoginWithActivationKey = 'LoginWithActivationKey',
  SendHandleEventResult = 'SendHandleEventResult',
  SendSystemEvent = 'SendSystemEvent'
}


export class RemoteParameterJson {
  public Name: string;
  public ValueJson: string;

  constructor(name: string, value: any) {
      this.Name = name;
      if (typeof value === "number" || typeof value === "string" || typeof value === "boolean")
          this.ValueJson = String(value);
      else
          this.ValueJson = JSON.stringify(value);
  }
}

export class RemoteCommandJson {
  public CommandId: string | null = null;
  public IsResult: boolean = false;

  //----- Response-----
  public Result: string | null= null;
  public Exception: string | null = null;
  public ExceptionKey: string | null = null;

  //----- Request-----
  public Command: RemoteCommandName | null = null;
  public CountParameters: number | null = null;
  public Parameters: Array<RemoteParameterJson> = [];


  public static parse(commandJson: string) : RemoteCommandJson {
    const parsedJson = JSON.parse(commandJson);

    let command = new RemoteCommandJson();
    
    command.Result = parsedJson.Result;
    command.Exception = parsedJson.Exception;
    command.ExceptionKey = parsedJson.ExceptionKey;
    
    command.CommandId = parsedJson.CommandId;
    command.IsResult = parsedJson.IsResult;
    
    command.Command = parsedJson.Command;
    command.CountParameters = parsedJson.CountParameters;
    command.Parameters = (parsedJson.Parameters || []).map((p: any) => new RemoteParameterJson(p.Name, p.ValueJson));

    return command;
  }


  public static CreateForSend(command: RemoteCommandName, parameters: Array<RemoteParameterJson>, commandId: string | null): RemoteCommandJson {

    var commandJson = new RemoteCommandJson();

    commandJson.CountParameters = parameters.length;
    commandJson.Command = command;
    commandJson.Parameters = parameters;

    commandJson.CommandId = commandId;

    return commandJson;
  }

  public static CreateForSendResult(commandId: string, result: any, exception: string, exceptionKey: string): RemoteCommandJson {
    var commandJson = new RemoteCommandJson();

    commandJson.CommandId = commandId;
    commandJson.Exception = exception;
    commandJson.ExceptionKey = exceptionKey;
    commandJson.IsResult = true;

    commandJson.Result = JSON.stringify(result);

    return commandJson;
  }


  public GetParameter<T>(name: string): T | null {
    const param = this.Parameters.find(item => item.Name === name );
    if (!param){
      return null;
    }

    return JSON.parse(param.ValueJson) as T;
  }


  public GetResult(): any {
    return this.Result ? JSON.parse(this.Result) : null;
  }
}