import React, { FunctionComponent } from 'react';


type Props = {
    date: Date;
};


const DateAsString: FunctionComponent<Props> = ({ date }) => {

    const formatedDate = date.toLocaleDateString('ru');

    return (
        <>
            {formatedDate}
        </>
    );
};


export default DateAsString;