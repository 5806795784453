import { ISales, Statistic } from "../RemoteCommands/type";

export function groupSales(statistic: Statistic[]) {
    let result: ISales[] = [];
    const sales = statistic.flatMap(item => item.sales); 
    for(let sale of sales) {
        const findSale = result.find(item => item.name === sale.name);
        if(findSale) {
            findSale.soldTotalCount += sale.soldTotalCount;
            findSale.soldTotalSum += sale.soldTotalSum;
            findSale.paidTotalSum += sale.paidTotalSum;
            findSale.debtSum += sale.debtSum;
            findSale.designSum += sale.designSum;
            findSale.installationSum += sale.installationSum;
            findSale.printSum += sale.printSum;
            findSale.additionalInstallationSum += sale.additionalInstallationSum;
            findSale.usagePercent += sale.usagePercent;
        } else {
            const copySale = Object.assign({}, sale);
            result.push(copySale);
        }
    }
    return result;
}