import { 
    ActionFromBackend, 
    ReduxTodoActionsTypes, 
    TodoActionsState, 
    lowerActionFromBackend, 
    ProjectDiscount, 
    ProjectPeriod, 
    ProjectStatuses, 
    ProjectTypes, 
    Coworkers, 
    IProjectFilter
} from './types';
import { Action, ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { GetProjectsFilter, getWebServerProxy, Project } from '../../WebServerProxy';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { ICommercialOffer, Discount } from 'sedi-webserverproxy/dist/controllers/webclient/types/CommercialOffer';
import { DatesRange } from '../../components/bricks/InputDatesRange';
import { ExceededTimeProject } from '../../utils/onlyUnique';


export interface IAddTodoAction extends Action<ReduxTodoActionsTypes.ADD_TODO_ACTION> {
    todoAction: ActionFromBackend
}

export interface IOpenProjectModal extends Action<ReduxTodoActionsTypes.OPEN_PROJECT_MODAL> {
    openProjectModal: boolean
}


export interface ISetActiveProject extends Action<ReduxTodoActionsTypes.SET_ACTIVE_PROJECT> {
    projectID: number
}

export interface ISetAllProjects extends Action<ReduxTodoActionsTypes.SET_ALL_PROJECTS> {
    projects: Project[]
}

export interface IAllProjects extends Action<ReduxTodoActionsTypes.ALL_PROJECTS> {
    allProjects: Project[]
}

export interface ICurrentActiveCompany extends Action<ReduxTodoActionsTypes.CURRENT_ACTIVE_COMPANY> {
    currentCompanyAccountId: number
}
export interface IOpenSelectedBillboards extends Action<ReduxTodoActionsTypes.OPEN_SELECTED_BILLBOARDS> {
    openSelectedBillboards: boolean
}

export interface ICommercialOfferAction extends Action<ReduxTodoActionsTypes.COMMERCIAL_OFFER> {
    commercialOffer: ICommercialOffer
}

export interface ICommercialOfferNullAction extends Action<ReduxTodoActionsTypes.COMMERCIAL_OFFER_NULL> {}

export interface IOfferIdAction extends Action<ReduxTodoActionsTypes.COMMERCIAL_OFFER_ID> {
    offerId: number
}

export interface ICommercialOfferDiscountAction extends Action<ReduxTodoActionsTypes.COMMERCIAL_OFFER_DISCOUNT> {
    discounts: Discount[]
}

export interface IButtonNegativeAction extends Action<ReduxTodoActionsTypes.BUTTON_NEGATIVE> {
    permissionResolutionNegative: boolean
}

export interface INoteAction extends Action<ReduxTodoActionsTypes.NOTE> {
    note: string
}

export interface ITotalDiscountTextAction extends Action<ReduxTodoActionsTypes.TOTAL_DISCOUNT_TEXT> {
    totalDiscountText: string
}

export interface IProjectDiscount extends Action<ReduxTodoActionsTypes.PROJECT_DISCOUNT> {
    projectDiscount: ProjectDiscount[]
}

export interface IDiscountOpen extends Action<ReduxTodoActionsTypes.DISCOUNT_OPEN> {
    discountOpen: boolean
}

export interface IProjectPeriod extends Action<ReduxTodoActionsTypes.PROJECT_PERIOD> {
    projectPeriod: ProjectPeriod[]
}

export interface ICurrentGeoMarkStatus extends Action<ReduxTodoActionsTypes.CURRENT_GEOMARK_STATUS> {
    currentGeoMarkStatus: ProjectStatuses
}

export interface IDates extends Action<ReduxTodoActionsTypes.DATES_FROM_TO> {
    dates: DatesRange,
}

export interface IProjectStatuses extends Action<ReduxTodoActionsTypes.PROJECT_STATUSES> {
    projectStatuses: ProjectStatuses[],
}

export interface IProjectTypes extends Action<ReduxTodoActionsTypes.PROJECT_TYPES> {
    projectTypes: ProjectTypes[]
}

export interface ICoworkers extends Action<ReduxTodoActionsTypes.COWORKERS> {
    coworkers: Coworkers[]
}

export interface IProjectFilterAction extends Action<ReduxTodoActionsTypes.PROJECT_FILTER> {
    projectFilter: IProjectFilter
}
export interface IOpenAdditionalCost extends Action<ReduxTodoActionsTypes.OPEN_ADDITIONAL_COST> {
    openAdditionalCost: boolean
}

export interface IExceededTimeProject extends Action<ReduxTodoActionsTypes.EXCEEDED_TIME_PROJECTS> {
    exceededTimeProjects: ExceededTimeProject[]
}

export interface IExceededTime extends Action<ReduxTodoActionsTypes.EXCEEDED_TIME_PROJECT> {exceededTimeProject: ExceededTimeProject | null}

export type TodoActions = IAddTodoAction | IOpenProjectModal | ISetActiveProject | ISetAllProjects 
    | ICurrentActiveCompany | IOpenSelectedBillboards | ICommercialOfferAction 
    | ICommercialOfferDiscountAction | IOfferIdAction | ICommercialOfferNullAction 
    | IButtonNegativeAction | INoteAction | ITotalDiscountTextAction | IProjectDiscount
    | IDiscountOpen | IProjectPeriod | ICurrentGeoMarkStatus | IDates 
    | IProjectStatuses | IProjectTypes | ICoworkers | IAllProjects
    | IProjectFilterAction | IOpenAdditionalCost | IExceededTimeProject
    | IExceededTime;

export function exceededTimeProjectsAction(exceededTimeProjects: ExceededTimeProject[]) {
    const action: IExceededTimeProject = {
        type: ReduxTodoActionsTypes.EXCEEDED_TIME_PROJECTS,
        exceededTimeProjects
    }
    return action;
}

export function exceededTimeProjectAction(exceededTimeProject: ExceededTimeProject | null) {
    const action: IExceededTime = {
        type: ReduxTodoActionsTypes.EXCEEDED_TIME_PROJECT,
        exceededTimeProject
    }
    return action;
}

export function openAdditionalCostAction(openAdditionalCost: boolean) {
    const action: IOpenAdditionalCost = {
        type: ReduxTodoActionsTypes.OPEN_ADDITIONAL_COST,
        openAdditionalCost
    }
    return action;
}

export function projectFilterAction(projectFilter: IProjectFilter) {
    const action: IProjectFilterAction = {
        type: ReduxTodoActionsTypes.PROJECT_FILTER,
        projectFilter
    }
    return action;
}
export function datesAction(dates: DatesRange) {
    const action: IDates = {
        type: ReduxTodoActionsTypes.DATES_FROM_TO,
        dates
    }
    return action;
}
export function currentGeoMarkStatusAction(currentGeoMarkStatus: ProjectStatuses) {
    const action: ICurrentGeoMarkStatus = {
        type: ReduxTodoActionsTypes.CURRENT_GEOMARK_STATUS,
        currentGeoMarkStatus
    }
    return action;
}
export function projectPeriodAction(projectPeriod: ProjectPeriod[]) {
    const action: IProjectPeriod = {
        type: ReduxTodoActionsTypes.PROJECT_PERIOD,
        projectPeriod
    }
    return action;
}
export function discountOpenAction(discountOpen: boolean) {
    const action: IDiscountOpen = {
        type: ReduxTodoActionsTypes.DISCOUNT_OPEN,
        discountOpen
    }
    return action;
}
export function projectDiscountAction(projectDiscount: ProjectDiscount[]) {
    const action: IProjectDiscount = {
        type: ReduxTodoActionsTypes.PROJECT_DISCOUNT,
        projectDiscount
    }
    return action;
}

export function addTodoAction(todoAction: ActionFromBackend) {
    const action: IAddTodoAction = {
        type: ReduxTodoActionsTypes.ADD_TODO_ACTION,
        todoAction
    }
    return action;
}

export function openProject(openProjectModal: boolean) {
    const action: IOpenProjectModal = {
        type: ReduxTodoActionsTypes.OPEN_PROJECT_MODAL,
        openProjectModal
    }
    return action;
}

export function openSelectedBillboardsAction(openSelectedBillboards: boolean) {
    const action: IOpenSelectedBillboards = {
        type: ReduxTodoActionsTypes.OPEN_SELECTED_BILLBOARDS,
        openSelectedBillboards
    }
    return action;
}


export function setActiveProjectActionCreator(projectID: number) {
    const action: ISetActiveProject = {
        type: ReduxTodoActionsTypes.SET_ACTIVE_PROJECT,
        projectID
    }
    return action;
}

export function setAllProjects(projects: Project[]) {
    const action: ISetAllProjects = {
        type: ReduxTodoActionsTypes.SET_ALL_PROJECTS,
        projects
    }
    return action;
}

export function currentCompanyAction(currentCompanyAccountId: number) {
    const action: ICurrentActiveCompany = {
        type: ReduxTodoActionsTypes.CURRENT_ACTIVE_COMPANY,
        currentCompanyAccountId
    }
    return action;
}


export const getFirstActionCreator: ActionCreator<ThunkAction<Promise<any>, TodoActionsState, string, IAddTodoAction>> = () => {
    return async (dispatch: ThunkDispatch<TodoActionsState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();            
            const result = await wsProxy.ToDo.GetFirstAction();
            if (result.Success === false) {
                throw new Error(result.Message);
            }
            if (result.Result == undefined) {
                throw new Error('first action is undefined');
            }

            const lowerCamelAction = result.Result as lowerActionFromBackend;
            const normalizedAction : ActionFromBackend = {
                ActionName: lowerCamelAction.actionName,
                Description: lowerCamelAction.description,
                Items: lowerCamelAction.items.map(item => ({ 
                    Text: item.text, 
                    Buttons: item.buttons.map(btn => ({ 
                        Text: btn.text, 
                        FrontendAction: btn.frontendAction
                    }))
                })),
                SequenceNumber: lowerCamelAction.sequenceNumber
            }

            const gettingPeopleAction: IAddTodoAction = {
                type: ReduxTodoActionsTypes.ADD_TODO_ACTION,
                todoAction: normalizedAction
              };
              dispatch(gettingPeopleAction);
        }
        catch (e) {
            // TODO error log
            console.log('Ошибка getFirstActionCreator:' + e);
        }
    }
}

export const sendSystemEventAction: ActionCreator<any> = (systemEvent: string) => {
    return async () => {
        try {
            await ServerProxy.SendSystemEvent(systemEvent);
        } catch (e) {
            alert(e);
        }
    }
}

export const commercialOfferAction: ActionCreator<ThunkAction<Promise<any>, TodoActionsState, string, IAddTodoAction>> = 
    (offerId: number = 0, projectId: number) => {
    return async (dispatch: ThunkDispatch<TodoActionsState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();
            if(offerId > 0 || projectId > 0)  {
                const result = await wsProxy.WebClient.GetCommercialOffer(offerId, projectId);
                if (result.Success === false) {
                    throw new Error(`Search error ${result.Message}`);
                }

                if(result.Result!.discounts === null) {
                    result.Result!.discounts = []
                }

                const successAction: ICommercialOfferAction = {
                    type: ReduxTodoActionsTypes.COMMERCIAL_OFFER,
                    commercialOffer: result.Result!
                };

                dispatch(successAction);
            }          
        }
        catch (e) {
            // TODO error log
            console.log('Ошибка getFirstActionCreator:' + e);
        }
    }
}

export function commercialOfferDiscountsAction() {
    const action: ICommercialOfferNullAction = {
        type: ReduxTodoActionsTypes.COMMERCIAL_OFFER_NULL,
    }
    return action;
}

export const getProjectsAction: ActionCreator<ThunkAction<Promise<any>, TodoActionsState, string, IAddTodoAction>> = 
    (filter: GetProjectsFilter) => {
    return async (dispatch: ThunkDispatch<TodoActionsState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();
            const result = await wsProxy.ToDo.GetProjects(filter);
            if (result.Success === false) {
                throw new Error(`Search error ${result.Message}`);
            }

            const successAction: ISetAllProjects = {
                type: ReduxTodoActionsTypes.SET_ALL_PROJECTS,
                projects: result.Result!
            }

            dispatch(successAction);      
        }
        catch (e) {
            // TODO error log
            console.log('Ошибка getFirstActionCreator:' + e);
        }
    }
}

export const getAllProjectAction: ActionCreator<ThunkAction<Promise<any>, TodoActionsState, string, IAddTodoAction>> = 
    (filter: GetProjectsFilter) => {
    return async (dispatch: ThunkDispatch<TodoActionsState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();
            const result = await wsProxy.ToDo.GetProjects(filter);
            if (result.Success === false) {
                throw new Error(`Search error ${result.Message}`);
            }

            const successAction: IAllProjects = {
                type: ReduxTodoActionsTypes.ALL_PROJECTS,
                allProjects: result.Result!
            }

            dispatch(successAction);      
        }
        catch (e) {
            // TODO error log
            console.log('Ошибка getFirstActionCreator:' + e);
        }
    }
}

export function commercialOfferNullAction() {
    const action: ICommercialOfferNullAction = {
        type: ReduxTodoActionsTypes.COMMERCIAL_OFFER_NULL,
    }
    return action;
}

export function commercialOfferDiscountAction(discounts: Discount[]) {
    const action: ICommercialOfferDiscountAction = {
        type: ReduxTodoActionsTypes.COMMERCIAL_OFFER_DISCOUNT,
        discounts
    }
    return action;
}

export function commercialOfferIdAction(offerId: number) {
    const action: IOfferIdAction = {
        type: ReduxTodoActionsTypes.COMMERCIAL_OFFER_ID,
        offerId
    }
    return action;
}

export function buttonNegativeAction(permissionResolutionNegative: boolean) {
    const action: IButtonNegativeAction = {
        type: ReduxTodoActionsTypes.BUTTON_NEGATIVE,
        permissionResolutionNegative
    }
    return action;
}

export function noteAction(note: string) {
    const action: INoteAction = {
        type: ReduxTodoActionsTypes.NOTE,
        note
    }
    return action;
}

export function totalDiscountTextAction(totalDiscountText: string) {
    const action: ITotalDiscountTextAction = {
        type: ReduxTodoActionsTypes.TOTAL_DISCOUNT_TEXT,
        totalDiscountText
    }
    return action;
}

export function projectStatusesAction(projectStatuses: ProjectStatuses[]) {
    const action: IProjectStatuses = {
        type: ReduxTodoActionsTypes.PROJECT_STATUSES,
        projectStatuses
    }
    return action;
}

export function projectTypesAction(projectTypes: ProjectTypes[]) {
    const action: IProjectTypes = {
        type: ReduxTodoActionsTypes.PROJECT_TYPES,
        projectTypes
    }
    return action;
}

export function coworkersAction(coworkers: Coworkers[]) {
    const action: ICoworkers = {
        type: ReduxTodoActionsTypes.COWORKERS,
        coworkers
    }
    return action;
}
