import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { RootState, actions } from '../../store'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Tooltip, TableContainer, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TableSortLabel } from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete'
import { GeoMarkTimeStatus } from "sedi-webserverproxy"
import moment from 'moment';
import EditTariff from './EditTariff';
import { TariffCost } from './TariffTable';
import MessageShackbar from '../bricks/MessageShackbar';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DatePicker from "react-datepicker";
import CopyDateConfirm from './CopyDateConfirm'
import { BillboardsShortInfo, IGeoMark, IGeoMarkTimeStatuses } from '../../RemoteCommands/type'
import DuplicateConfirm from './ProjectSelector/DuplicateConfirm'
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> &  {
    geoMarks: IGeoMark[];
    disabled: boolean;
    geoMarkGid: string | undefined;
}

const PropertiesTable:React.FC<Props> = (props) => {
    const { 
        deleteGeoMartTimeStatuses, 
        billboardShortAction, 
        getAdditionalBillboardAction, 
        currentGeoMarkStatus, projectID,
        geoMarks,
        billboardsShortInfo,
        disabled,
        geoMarkGid,
        successMessageAction,
        gid
    } = props;

    const [tableHeight, setTableHeight] = useState<number | null>(null);

    const [statusError, setStatusError] = useState({
        message: "",
        error: false
    });

    const [dateRangeText, setDateRangeText] = useState<string | null>(null);
    const [consfirmOpen, setConsfirmOpen] = useState(false);
    const [duplicateOpen, setDuplicateOpen] = useState(false);

    const [geoMarksState, setGeoMarksState] = useState<IGeoMark[]>([]);
    const [deleteGeoMark, setDeleteGeoMark] = useState(false);
    const [dateString, setDateString] = useState('');
    const [isDateStart, setIsDateStart] = useState(false);
    const [geoMarkTimeStatusId, setGeoMarkTimeStatusId] = useState(-1);
    const prevDate = useRef('');

    const [geoMarkTimeStatus, setGeoMarkTimeStatus] = useState<IGeoMarkTimeStatuses | null>(null);
    const [geoMarkId, setGeoMarkId] = useState(-1);

    const deleteGeoMarkClose = () => {
        setDeleteGeoMark(false);
    }

    const duplicateClickClose = () => {
        setDuplicateOpen(false);
    }

    useEffect(() => {
        setGeoMarksState(geoMarks);
    }, [geoMarks]);

    const consfirmClickOpen = (dateTimeString: string) => {
        const dateStringFormat = moment(dateTimeString).format('DD.MM.YYYY');
        setDateString(dateStringFormat);
        setConsfirmOpen(true);
    }

    const consfirmClickClose = () => {
        setConsfirmOpen(false);
    }

    const statusErrorClose = () => {
        setStatusError({
            message: "",
            error: false,
        })
    }

    const deleteGeoMartTimeStatusesClick = (geoMarkTimeStatus: IGeoMarkTimeStatuses) => {
        let newGeoMarks: IGeoMark[] = [];
        for(let geoMark of geoMarksState) {
            const { geoMarkTimeStatuses } = geoMark;
            const newGeoMarkTimeStatuses = geoMarkTimeStatuses.filter((item) => item.geoMarkTimeStatusId !== geoMarkTimeStatus.geoMarkTimeStatusId);
            const newItem = {...geoMark, geoMarkTimeStatuses: newGeoMarkTimeStatuses};
            if(newGeoMarkTimeStatuses.length > 0) {
                newGeoMarks.push(newItem);
            }
        }
        setGeoMarksState(newGeoMarks);
        deleteGeoMartTimeStatuses([geoMarkTimeStatus.geoMarkTimeStatusId], projectID);
    }

    const deleteAllGeoMartTimeStatusesClick = () => {
        let newGeoMarkTimeStatusIds: number[] = [];
        for(let geoMark of geoMarksState) {
            const { geoMarkTimeStatuses } = geoMark;
            for(let geoMarkTimeStatus of geoMarkTimeStatuses) {
                newGeoMarkTimeStatusIds.push(geoMarkTimeStatus.geoMarkTimeStatusId)
            }
        }
        setGeoMarksState([]);
        deleteGeoMartTimeStatuses(newGeoMarkTimeStatusIds, projectID);
        setDeleteGeoMark(false);
    }

    const duplicateClick = (geoMarkTimeStatus: IGeoMarkTimeStatuses, geoMarkId: number) => {
        setGeoMarkTimeStatus(geoMarkTimeStatus);
        setGeoMarkId(geoMarkId);
        setDuplicateOpen(true);
    }

    const handleChangeDate = (isFirstDate: boolean, geoMarkTimeStatus: IGeoMarkTimeStatuses, geoMark: IGeoMark, date: Date | null) => {
        const newDateValue = date ? date : undefined;
        let newGeoMarksState = geoMarksState.slice();
        for(let geoMark of newGeoMarksState) {
            const { geoMarkTimeStatuses } = geoMark;
            const findGeoMark = geoMarkTimeStatuses.find(item => item.geoMarkTimeStatusId === geoMarkTimeStatus.geoMarkTimeStatusId);
            if(findGeoMark && newDateValue) {
                if (isFirstDate) {
                    prevDate.current = findGeoMark.dateTimeStart
                    findGeoMark.dateTimeStart = moment(newDateValue).format('YYYY-MM-DDTHH:mm:ss');
                } else {
                    prevDate.current = findGeoMark.dateTimeEnd
                    findGeoMark.dateTimeEnd = moment(newDateValue).format('YYYY-MM-DDTHH:mm:ss');
                }
                if(findGeoMark.dateTimeEnd <= findGeoMark.dateTimeStart) {
                    setDateRangeText('Дата от меньше или равно дата до');
                    return;
                }
            }
        }
        setGeoMarksState(newGeoMarksState);
        if(newDateValue) {
            const selectedStatus: GeoMarkTimeStatus = {
                geoMarkTimeStatusId: geoMarkTimeStatus.geoMarkTimeStatusId,
                geoMarkId: geoMark.geoMarkId,
                dateTimeStart: geoMarkTimeStatus.dateTimeStart,
                dateTimeEnd: geoMarkTimeStatus.dateTimeEnd,
                geoMarkStatus: currentGeoMarkStatus.value,
                projectId: projectID,
                geoMarkPart: geoMarkTimeStatus.geoMarkPart
            }
            SystemEvent.EventSetGeoMarkTimeStatus([selectedStatus], false);
        }
        setGeoMarkTimeStatusId(geoMarkTimeStatus.geoMarkTimeStatusId);
        setIsDateStart(isFirstDate);
    }

    const ttClick = (geoMarkId: number) => {
        getAdditionalBillboardAction(geoMarkId)
    }

    useEffect(() => {
        const height = document.querySelector('.projectTableContainer')!.clientHeight;
        setTableHeight(height);
    }, [geoMarksState]);

    useEffect(() => {
        SystemEvent.SubscribeEventChangedCommercialOffer(
            "PropertiesTable", 
            (answer) => {
                const message = "Информация успешно сохранена";
                successMessageAction(message)
                SystemEvent.EventGetProjectInfo(projectID);
            }, 
            (error) => errorCallback(error)
        );
    }, []);

    const gidBgColor = (geoMarkTimeStatus: IGeoMarkTimeStatuses) => {
        if(geoMarkTimeStatus.notExistingPart) {
            return { backgroundColor: "#f44336", borderRadius: 4, cursor: "pointer" }
        } if(geoMarkTimeStatus.isOverSale) {
            return { backgroundColor: "#F4B136", borderRadius: 4, cursor: "pointer" }
        }else if(geoMarkGid && geoMarkTimeStatus.gid === geoMarkGid.toString()) {
            return { backgroundColor: "#f44336", borderRadius: 4, cursor: "pointer" }
        }
        if(geoMarkTimeStatus.gid === gid) { 
            return { backgroundColor: "rgb(28, 233, 181)" }
        }
    }

    const getGeoMarkTimeStatusGIDTable = (geoMarkTimeStatus: IGeoMarkTimeStatuses, index: number) => {
        return (
            <Table key={`table-geoMark-gid-${geoMarkTimeStatus.gid}-${index}`}> 
                <TableBody>
                    <TableRow 
                        key={`geoMark-gid-${geoMarkTimeStatus.gid}-${index}`} 
                        className="priceTableRow"
                        style={gidBgColor(geoMarkTimeStatus)}>
                        <TableCell 
                            className="priceTableCell"
                            style={geoMarkTimeStatus.isOverSale || geoMarkTimeStatus.notExistingPart ? { color: "#ffffff" } : geoMarkGid && geoMarkTimeStatus.gid === geoMarkGid.toString() ? { color: "#ffffff" } : {}}>
                            {geoMarkTimeStatus.gid}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>);
    }

    return (
        <TableContainer 
            component={Paper} 
            className="projectTableContainer"
            style={tableHeight && tableHeight > 580 ? {overflowX: "auto"} : {overflowX: "inherit"}}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{minWidth: 180}}><Translate id='BillboardListAddress'/></TableCell>
                        <TableCell align="center"><Translate id='BillboardDetailsGid' /></TableCell>
                        <TableCell align="center" style={{minWidth: 244}}>Свойства</TableCell>
                        <TableCell align="center">Дата от</TableCell>
                        <TableCell align="center">Дата включительно</TableCell>
                        <TableCell align="center">Прайс:</TableCell>
                        <TableCell align="center">Прайс со скидкой:</TableCell>
                        <TableCell align="center">TT</TableCell>
                        <TableCell align="center" style={{minWidth: 76}}>
                            {!disabled && 
                                <Tooltip title="Удалить все" placement="top">
                                    <DeleteIcon 
                                        fontSize="small" 
                                        onClick={() => setDeleteGeoMark(true)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            }
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {geoMarksState.map(geoMark => {
                        const billboard = billboardsShortInfo.find(item => item.geoMarkId === geoMark.geoMarkId);
                        const findGid = geoMark.geoMarkTimeStatuses.find(item => item.gid === gid);
                        return (
                            <TableRow 
                                key={`properties-${geoMark.geoMarkId}`}
                                style={findGid && {backgroundColor: "rgb(237, 247, 237)"}}>
                                <TableCell 
                                    component="th" 
                                    scope="row">
                                    <div 
                                        className="nameLink"
                                        onClick={() => billboardShortAction(billboard)}>
                                        {geoMark.geoMarkName}
                                    </div>
                                </TableCell>
                                <TableCell 
                                    align="center"
                                    className="cancelDraggable">
                                    {geoMark.geoMarkTimeStatuses.map((geoMarkTimeStatus, index) => {
                                        if(geoMarkTimeStatus.notExistingPart) {
                                            return (
                                                <Tooltip 
                                                    key={`tooltip-geoMark-gid-${geoMarkTimeStatus.gid}-${index}`}
                                                    title="Несуществующая сторона" 
                                                    placement="top"
                                                >
                                                    { getGeoMarkTimeStatusGIDTable(geoMarkTimeStatus, index) }
                                                </Tooltip>
                                            )
                                        } else if(geoMarkTimeStatus.isOverSale) {
                                            return (
                                                <Tooltip 
                                                    key={`tooltip-geoMark-gid-${geoMarkTimeStatus.gid}-${index}`}    
                                                    title="Дополнительная продажа" 
                                                    placement="top"
                                                >
                                                    { getGeoMarkTimeStatusGIDTable(geoMarkTimeStatus, index) }
                                                </Tooltip>
                                            )
                                        } else {
                                            return (
                                                getGeoMarkTimeStatusGIDTable(geoMarkTimeStatus, index)
                                            )
                                        }
                                    })}
                                </TableCell>
                                <TableCell align="center">
                                    {geoMark.geoMarkTimeStatuses.map((geoMarkTimeStatus, index) => (
                                        <Table key={`table-geoMarkTimeStatusId-${geoMarkTimeStatus.geoMarkTimeStatusId}-${index}`}> 
                                            <TableBody>
                                                <TableRow 
                                                    key={`geoMarkTimeStatusId-${geoMarkTimeStatus.geoMarkTimeStatusId}-${index}`} 
                                                    className="priceTableRow">
                                                    <TableCell className="priceTableCell">
                                                        {geoMarkTimeStatus.properties}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    ))}
                                </TableCell>
                                <TableCell align="center">
                                    {geoMark.geoMarkTimeStatuses.map((geoMarkTimeStatus, index) => {
                                        const dateFrom: Date = moment(geoMarkTimeStatus.dateTimeStart, "YYYY-MM-DD").toDate();
                                        const dateTo: Date = moment(geoMarkTimeStatus.dateTimeEnd, "YYYY-MM-DD").toDate();
                                        return (
                                            <Table key={`table-geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${dateFrom}-${index}`}> 
                                                <TableBody>
                                                    <TableRow 
                                                    key={`geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${dateFrom}-${index}`}  
                                                        className="priceTableRow">
                                                        <TableCell className="priceTableCell dateCopy">
                                                            <DatePicker
                                                                selected={dateFrom}
                                                                onChange={(date: Date | null) => handleChangeDate(true, geoMarkTimeStatus, geoMark, date)}
                                                                selectsStart
                                                                startDate={dateFrom}
                                                                endDate={dateTo}
                                                                dateFormat="dd.MM.yyyy"
                                                                locale="ru"
                                                                className="statusesDate"
                                                            />
                                                            {(prevDate.current.length > 0 && geoMarkTimeStatus.geoMarkTimeStatusId === geoMarkTimeStatusId && isDateStart) &&
                                                                <Tooltip title="Копировать" placement="top">
                                                                    <FileCopyIcon 
                                                                        fontSize="small" 
                                                                        className="dateCopy__icon"
                                                                        onClick={() => consfirmClickOpen(geoMarkTimeStatus.dateTimeStart)}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        )
                                    })}
                                </TableCell>
                                <TableCell align="center">
                                    {geoMark.geoMarkTimeStatuses.map((geoMarkTimeStatus, index) => {
                                        const dateFrom = moment(geoMarkTimeStatus.dateTimeStart, "YYYY-MM-DD").toDate();
                                        const dateTo = moment(geoMarkTimeStatus.dateTimeEnd, "YYYY-MM-DD").toDate();
                                        return (
                                            <Table key={`table-geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${dateTo}-${index}`}> 
                                                <TableBody>
                                                    <TableRow 
                                                        key={`geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${dateTo}-${index}`} 
                                                        className="priceTableRow">
                                                        <TableCell className="priceTableCell dateCopy">
                                                            <DatePicker
                                                                selected={dateTo}
                                                                onChange={(date: Date | null) => handleChangeDate(false, geoMarkTimeStatus, geoMark, date)}
                                                                selectsStart
                                                                startDate={dateFrom}
                                                                endDate={dateTo}
                                                                dateFormat="dd.MM.yyyy"
                                                                locale="ru"
                                                                className="statusesDate"
                                                            />
                                                            {(prevDate.current.length > 0 && geoMarkTimeStatus.geoMarkTimeStatusId === geoMarkTimeStatusId && !isDateStart) &&
                                                                <Tooltip title="Копировать" placement="top">
                                                                    <FileCopyIcon 
                                                                        fontSize="small" 
                                                                        className="dateCopy__icon"
                                                                        onClick={() => consfirmClickOpen(geoMarkTimeStatus.dateTimeEnd)}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        )
                                    })}
                                </TableCell>
                                <TableCell align="center">
                                    {geoMark.geoMarkTimeStatuses.map((geoMarkTimeStatus, index) => (
                                        <Table key={`table-geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${geoMarkTimeStatus.price}-${index}`}> 
                                            <TableBody>
                                                <TableRow 
                                                    key={`geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${geoMarkTimeStatus.price}-${index}`} 
                                                    className="priceTableRow">
                                                    <TableCell className="priceTableCell">
                                                        {geoMarkTimeStatus.price}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    ))}
                                </TableCell>
                                <TableCell align="center">
                                    {geoMark.geoMarkTimeStatuses.map((geoMarkTimeStatus, index) => (
                                        <Table key={`table-geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${geoMarkTimeStatus.geoMarkTimeStatusId}-${index}`}> 
                                            <TableBody>
                                                <TableRow 
                                                    key={`geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${geoMarkTimeStatus.geoMarkTimeStatusId}-${index}`} 
                                                    className="priceTableRow">
                                                    <TableCell className="priceTableCell">
                                                        <EditTariff 
                                                            price={geoMarkTimeStatus.priceWithDiscount} 
                                                            tooltipText="Редактировать скидку" 
                                                            selected={TariffCost.none} 
                                                            side="A"
                                                            geoMarkTimeStatus={geoMarkTimeStatus} />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    ))}
                                </TableCell>
                                <TableCell 
                                    align="center">
                                        <a href={geoMark.ttLink} data-fancybox={`group-${geoMark.geoMarkId}`}>
                                            <button 
                                                className="tt" 
                                                onClick={() => ttClick(geoMark.geoMarkId)}>
                                                tt
                                            </button>
                                        </a>
                                </TableCell>
                                <TableCell align="center">
                                    {!disabled && 
                                        geoMark.geoMarkTimeStatuses.map((geoMarkTimeStatus, index) => {
                                            return (
                                                <Table key={`table-geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${geoMarkTimeStatus.geoMarkTimeStatusId}-${index}`}> 
                                                    <TableBody>
                                                        <TableRow 
                                                            key={`geoMarkTimeStatus-${geoMarkTimeStatus.gid}-${geoMarkTimeStatus.geoMarkTimeStatusId}-${index}`} 
                                                            className="priceTableRow">
                                                            <TableCell className="priceTableCell">
                                                                <Tooltip title="Удалить" placement="top">
                                                                    <DeleteIcon 
                                                                        fontSize="small" 
                                                                        onClick={() => deleteGeoMartTimeStatusesClick(geoMarkTimeStatus)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </Tooltip>
                                                                <Tooltip title="Дублировать" placement="top">
                                                                    <FileCopyIcon 
                                                                        fontSize="small" 
                                                                        onClick={() => duplicateClick(geoMarkTimeStatus, geoMark.geoMarkId)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            )
                                        })
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <MessageShackbar 
                message={statusError.message}
                variant="error"
                messageOpen={statusError.error} 
                vertical="top"
                horizontal="center" 
                messageShackbarClose={statusErrorClose} />
            {dateRangeText && 
                <MessageShackbar 
                    message={dateRangeText}
                    variant="error"
                    messageOpen={dateRangeText ? true : false} 
                    vertical="top"
                    horizontal="center" 
                    messageShackbarClose={() => setDateRangeText(null)} />
            }
            <CopyDateConfirm 
                consfirmOpen={consfirmOpen}
                consfirmClickClose={consfirmClickClose} 
                dateString={dateString} 
                geoMarks={geoMarksState} 
                setGeoMarks={setGeoMarksState} 
                isDateStart={isDateStart} 
                prevDate={prevDate.current} 
                setGeoMarkTimeStatusId={setGeoMarkTimeStatusId} />
            {(geoMarkTimeStatus && geoMarkId > 0 ) &&
                <DuplicateConfirm 
                    duplicateOpen={duplicateOpen} 
                    duplicateClickClose={duplicateClickClose} 
                    geoMarkTimeStatus={geoMarkTimeStatus} 
                    geoMarkId={geoMarkId} />
            }
            <Dialog
                open={deleteGeoMark}
                onClose={deleteGeoMarkClose}>
                <DialogTitle>Удалить все поверхности</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы действительно хотите удалить все поверхности?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={deleteGeoMarkClose} 
                        color="primary">
                        Отмена
                    </Button>
                    <Button 
                        color="secondary" 
                        autoFocus 
                        onClick={deleteAllGeoMartTimeStatusesClick}>
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </TableContainer>
    )
}

const mapStateToProps = (state: RootState) => ({
    projectPeriod: state.todo.projectPeriod,
    currentBrand: state.billboards.currentBrandInfo,
    projectDiscount: state.todo.projectDiscount,
    currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
    projectID: state.todo.projectID,
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    gid: state.event.gid
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    deleteGeoMartTimeStatuses: (geoMarkTimeStatusIds: number[], projectID: number) => 
        dispatch(actions.billboards.deleteGeoMartTimeStatuses(geoMarkTimeStatusIds, projectID)),
    getBillboardTariffsAction: (geoMarkIds: number[]) => 
        dispatch(actions.billboards.getBillboardTariffsAction(geoMarkIds)),
    billboardShortAction: (billboardShort: BillboardsShortInfo | undefined) => 
        dispatch(actions.billboards.billboardShortAction(billboardShort)),
    getAdditionalBillboardAction: (geoMarkId: number) => 
        dispatch(actions.billboards.getAdditionalBillboardAction(geoMarkId)),
    successMessageAction: (message: string) => 
        dispatch(actions.event.successMessageAction(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesTable);