import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { EmployeeBrandsInfo } from 'sedi-webserverproxy';
import './bricks.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
    label: string;
    brandsInfo: EmployeeBrandsInfo[],
    onChange: (changeValue: EmployeeBrandsInfo[]) => void,
    currentValue: EmployeeBrandsInfo[]
}
const BrandsAutocomplate: FunctionComponent<Props> = (props) => {
    const { label, brandsInfo, onChange, currentValue } = props;

    const selectedBrands = currentValue ? currentValue.map(brand => brandsInfo.filter(b => b.brandId === brand.brandId)[0]) : [];

    return (
        <Autocomplete
            multiple
            options={brandsInfo}
            disableCloseOnSelect
            className="multiInputAutocomplate"
            getOptionLabel={(option: EmployeeBrandsInfo) => option.brandName}
            onChange={(event: React.ChangeEvent<{}>, changeValue: EmployeeBrandsInfo[]) => onChange(changeValue)}
            defaultValue={selectedBrands}
            value={selectedBrands}
            renderOption={(option: EmployeeBrandsInfo, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.brandName}
                </React.Fragment>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    fullWidth
                    size="small"
                    className="multiInput"
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
}

const mapStateToProps = (state: RootState) => ({
    brandsInfo: state.billboards.brandsInfo
});

export default connect(mapStateToProps)(BrandsAutocomplate);