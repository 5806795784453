import React, { Dispatch } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { actions, RootState, selectors } from '../../store';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BrandGeoObjects } from '../../store/event/types';
import { Discount } from 'sedi-webserverproxy/dist/controllers/webclient/types/CommercialOffer';
import { removeItemAtIndex } from '../../utils/replaceItem';
import { ChangedCommercialOfferParmas } from '../../RemoteCommands/type';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    deleteOpen: boolean;
    deleteCloseHandle: () => void;
    discount: Discount;
    offerId: number;
}

const DeleteDiscountModal:React.FC<Props> = (props) => {
    const { 
        deleteOpen,  
        deleteCloseHandle,
        discount,
        commercialOffer,
        commercialOfferDiscountAction,
        projectID,
        offerId
    } = props;

    const deleteDiscountClick = (dsicount: Discount) => {
        if(!commercialOffer) {
            return;
        }
        const { discounts } = commercialOffer;
        const idx = discounts.findIndex((item) => item === dsicount);
        const newList = removeItemAtIndex(discounts, idx);
        commercialOfferDiscountAction(newList);
        const payload: ChangedCommercialOfferParmas = {
            projectId: projectID,
            offerId: offerId,
            financialDiscounts: newList
        }
        SystemEvent.EventChangedCommercialOffer(payload);
        deleteCloseHandle();
    };


    return (
        <Dialog
            open={deleteOpen}
            onClose={deleteCloseHandle}>
            <DialogTitle>Удалить</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы действительно хотите удалить?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={deleteCloseHandle} color="primary">
                    Отмена
                </Button>
                <Button color="secondary" autoFocus onClick={() => deleteDiscountClick(discount)}>
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state: RootState) => ({
    commercialOffer: state.todo.commercialOffer,
    projectID: selectors.todo.getProjectID(state.todo),
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    newBrandGeoObjectsAction: (newBrandGeoObjects: BrandGeoObjects[]) => 
        dispatch(actions.event.newBrandGeoObjectsAction(newBrandGeoObjects)),
    commercialOfferDiscountAction: (discounts: Discount[]) => 
        dispatch(actions.todo.commercialOfferDiscountAction(discounts))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDiscountModal);