import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Billboard } from '../../store/billboards/types';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { BillboardsShortInfo } from '../../RemoteCommands/type';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    billboard: BillboardsShortInfo | undefined;
    onBillboardChange: (billboard: BillboardsShortInfo | undefined) => void;
}

const AddressAutocomplete:React.FC<Props> = (props) => {
    const { billboardsShortInfo, currentAgency, onBillboardChange, billboard } = props;

    const currentValueOnChange = (changeValue: BillboardsShortInfo) => {
        if(changeValue) {
            onBillboardChange(changeValue);
        } else {
            onBillboardChange(undefined);
        }
    }
    
    const billboardsSort = billboardsShortInfo.sort((a: BillboardsShortInfo, b: BillboardsShortInfo) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    return (
        <Autocomplete
            options={billboardsSort}
            getOptionLabel={option => option.name}
            value={billboard}
            onChange={(event: React.ChangeEvent<{}>, changeValue: BillboardsShortInfo) => currentValueOnChange(changeValue)}
            filterOptions={(options: BillboardsShortInfo[], state: FilterOptionsState) => fliterOptionsAdress(options, state)}
            renderOption={option => <span className="tariffAddressOption">{option.name}</span>}
            disabled={currentAgency ? false : true}
            renderInput={params => (
                <TextField 
                    {...params}
                    label="Выберите адрес"
                    variant="outlined" 
                    fullWidth 
                    size="small" />
            )}
        />
    );
}

function fliterOptionsAdress(options: BillboardsShortInfo[], state: FilterOptionsState) {
    const optionsArray: BillboardsShortInfo[] = options.filter(option => option.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 || option.name.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
    return optionsArray;
}

const mapStateToProps = (state: RootState) => ({
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    currentAgency: state.event.currentAgency,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    currentBillboard: (newBillboard: Billboard) => dispatch(actions.billboards.currentBillboard(newBillboard))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressAutocomplete);

