import React, { useEffect, useState } from 'react';
import { RootState, selectors } from '../../store';
import { connect } from 'react-redux';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    FormControl, 
    Input, 
    InputAdornment, 
    Tooltip 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { TariffCost } from './TariffTable';
import { BillboardTariffs } from 'sedi-webserverproxy';
import moment from 'moment';
import { ChangedCommercialOfferParmas, IGeoMarkTimeStatuses } from '../../RemoteCommands/type';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';

type Props = 
    ReturnType<typeof mapStateToProps> & {
    price?: number | string;
    tooltipText: string;
    selected: TariffCost;
    side: string;
    geoMarkTimeStatus: IGeoMarkTimeStatuses
}

const EditTariff:React.FC<Props> = (props) => {
    const { price, tooltipText, projectID, geoMarkTimeStatus } = props;
    const [edit, setEdit] = useState(false);
    const [currentPrice, setCurrentPrice] = useState<number | string | undefined>(price);
    const [currentTariffState, setCurrentTariffState] = useState<BillboardTariffs | null>(null);
    const [changeDiscount, setChangeDiscount] = useState(false);

    const dateFrom = moment(geoMarkTimeStatus.dateTimeStart, 'YYYY/MM/DD');
    const dateTo = moment(geoMarkTimeStatus.dateTimeEnd, 'YYYY/MM/DD');
    
    const monthFrom = parseInt(dateFrom.format('M'));
    const yearFrom  = parseInt(dateFrom.format('YYYY'));

    const monthTo = parseInt(dateTo.format('M'));
    const yearTo  = parseInt(dateTo.format('YYYY'));

    const changeDiscountClose = () => {
        setChangeDiscount(false);
        setCurrentPrice(price);
        setEdit(false);
    }

    useEffect(() => {
        setCurrentPrice(price);
    }, [price]);

    useEffect(() => {
        setCurrentTariffState({
            totalCost: Number(currentPrice),
            side: geoMarkTimeStatus.geoMarkPart[0],
            boardId: geoMarkTimeStatus.gid.substring(0, 7),
            month: monthFrom,
            year: yearFrom,
            monthTo: monthTo,
            yearTo: yearTo
        });
    }, [currentPrice, geoMarkTimeStatus]);

    const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCurrentPrice(event.target.value);
    }
    
    const savePriceClick = () => {
        // const builder = new EventBuilder(`EventChangedCommercialOffer`);
        //     builder.AddParameter('ProjectId', projectID);
        //     builder.AddParameter('BoardCostInfo', JSON.stringify(currentTariffState));
        // ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        const payload: ChangedCommercialOfferParmas = {
            projectId: projectID,
            boardCostInfo: currentTariffState
        }
        SystemEvent.EventChangedCommercialOffer(payload);
        changeDiscountClose();
    }

    return (
        <span>
            {edit ? 
                <div className="priceDiscountEdit">
                    <FormControl size="small">
                        <Input
                            value={currentPrice}
                            onChange={onInputChange}
                            type="number"
                            endAdornment={
                                <InputAdornment position="end">
                                    <SaveIcon onClick={savePriceClick} />
                                </InputAdornment>
                            }
                            autoFocus
                            onBlur={() => setChangeDiscount(true)}
                        />
                    </FormControl>
                </div>
            :
                <div className="priceDiscount">
                    {currentPrice}
                    <Tooltip title={tooltipText} placement="top">
                        <EditIcon 
                            className="priceDiscount__icon" 
                            onClick={() => setEdit(true)}
                        />
                    </Tooltip>
                </div>
            }
            <Dialog
                open={changeDiscount}
                onClose={changeDiscountClose}>
                <DialogTitle>Поменять скидку для стороны {geoMarkTimeStatus.gid}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы действительно хотите поменять скидку?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button 
                    onClick={changeDiscountClose} 
                    color="primary">
                    Отмена
                </Button>
                <Button 
                    color="secondary" 
                    autoFocus 
                    onClick={savePriceClick}>
                    Сохранить
                </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}

const mapStateToProps = (state: RootState) => ({
    projectID: selectors.todo.getProjectID(state.todo),
});

export default connect(mapStateToProps)(EditTariff);