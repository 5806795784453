import React, { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { ClientItem, ClientType } from '../../store/customers/types';
import { AgencyInfo, SelectedFocus } from '../../store/event/types';
import { sortAlphabeticallyClientItem } from '../../utils/sortAlphabetically';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { clientsArray } from '../../utils/clientsArray';
import { EmployeeBrandsInfo } from 'sedi-webserverproxy';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    disabled?: boolean;
    selectedCard?: boolean;
}

const ClientItemAutocomplete: React.FC<Props> = (props) => {
    const { 
        companies,
        customers,
        agencyInfo,
        clientItemAction,
        clientItem,
        currentAgencyAction,
        setActiveProject,
        disabled,
        selectedFocus,
        currentBrandInfoAction
    } = props;

    let inputRef: any;

    const changeClient = async (event: React.ChangeEvent<{}>, changeValue: ClientItem) => {
        clientItemAction(changeValue);
        currentAgencyAction(null);
    }

    const clientArray = () => {
        const client = clientsArray(companies, customers, agencyInfo);
        return sortAlphabeticallyClientItem(client);
    }; 

    const renderOptionSelect = (option: ClientItem) => {
        let optionType: string;

        if(option.customerType === ClientType.company) {
            optionType = 'Компания';
        } else if(option.customerType === ClientType.agency) {
            optionType = 'Агентства';
        } else {
            optionType = '';
        }

        return (
            <span className="projectItemSelect">
                <span>{option.name}</span>
                <span className="projectItemSelect__type">
                    {optionType}
                </span>
            </span>
        );
    };

    useEffect(() => {
        if(selectedFocus === SelectedFocus.company) {
            inputRef.focus();
        }
    }, [selectedFocus]);

    const closeClientItem = () => {
        setActiveProject(-1);
        currentBrandInfoAction(null)
    }

    return (
        <Autocomplete
            options={clientArray()}
            getOptionLabel={(option: ClientItem) => option.name}
            renderOption={(option: ClientItem) => renderOptionSelect(option)}
            value={clientItem}
            onChange={(event: React.ChangeEvent<{}>, changeValue) => 
                changeClient(event, changeValue)}
            freeSolo
            filterOptions={(options: ClientItem[], state: FilterOptionsState) => 
                fliterOptions(options, state)}
            closeIcon={
                <CloseIcon 
                    fontSize="small" 
                    onClick={closeClientItem} 
                />
            }
            disabled={disabled || false}
            className="cancelDraggable"
            renderInput={(params) => 
                <TextField 
                    {...params}
                    label="Выберите компанию"
                    variant="outlined" 
                    fullWidth 
                    size="small" 
                    // InputLabelProps={{ shrink: true }} 
                    inputRef={input => {
                        inputRef = input;
                    }}
                />
            }
        />
    )
}

const fliterOptions = (options: ClientItem[], state: FilterOptionsState) => {
    const optionsArray: ClientItem[] = options.filter(option => option.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 
        || option.name.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
    return optionsArray;
}

const mapStateToProps = (state: RootState) => ({
    companies: state.customers.companies,
    customers: state.customers.customers,
    agencyInfo: state.event.agencyInfo,
    clientItem: state.customers.clientItem,
    selectedFocus: state.event.selectedFocus,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    clientItemAction: (clientItem: ClientItem) => 
        dispatch(actions.customers.clientItemAction(clientItem)),
    currentAgencyAction: (currentAgency: AgencyInfo | null) => 
        dispatch(actions.event.currentAgencyAction(currentAgency)),
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    currentBrandInfoAction: (currentBrand: EmployeeBrandsInfo | null) => 
        dispatch(actions.billboards.currentBrandInfoAction(currentBrand))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientItemAutocomplete);