import React, { useState } from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, TextField, FormControl, OutlinedInput, InputAdornment } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';

type Props = {
    addAgencyOpen: boolean;
    addAgencyClickClose: () => void;
}

const AddAgency:React.FC<Props> = ({ addAgencyOpen, addAgencyClickClose }) => {
    const [value, setValue] = useState('');
    const [baseDiscount, setBaseDiscount] = useState('');

    const saveAgency = () => {
        if(value.includes('"')) {
            alert("Вы не можете использовать кавычки в имени агентства");
            return;
        }
        const builderAgency = new EventBuilder(`EventChangeAgencyInfo`);
        builderAgency.AddParameter('AgencyId', '0');
        builderAgency.AddParameter('AgencyName', value);
        builderAgency.AddParameter('CommandType', 'AddAgency');
        builderAgency.AddParameter('AgencyDiscount', baseDiscount);
        ServerProxy.SendSystemEvent(builderAgency.GetSystemEvent());
        addAgencyClickClose();
        setValue('');
        setBaseDiscount('');
    }

    return (
        <Dialog
            open={addAgencyOpen}
            onClose={addAgencyClickClose}
            fullWidth
            maxWidth="sm">
            <DialogTitle>
                <IconButton aria-label="delete" style={{ position: 'absolute', top: '5px', right: '5px' }} onClick={addAgencyClickClose}>
                    <CloseIcon />
                </IconButton>
                Агентства
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth 
                    label="Агентства" 
                    variant="outlined" 
                    size="small" 
                    value={value}
                    onChange={(event) => setValue(event.target.value)} 
                />
                 <div className="baseDicount">
                <div className="baseDicount__label">Базовая скидка</div>

                <FormControl variant="outlined" size="small">
                    <OutlinedInput
                        endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                        }
                        labelWidth={0}
                        type="number"
                        value={baseDiscount}
                        onChange={(event) => setBaseDiscount(event.target.value)}
                    />
                </FormControl>
            </div>
            </DialogContent>
            <DialogActions>
                <Button className="mr-2" variant="contained" color="secondary" onClick={addAgencyClickClose}>
                    <Translate id='ClientDetailCancel' />
                </Button>
                <Button variant="contained" color="primary" onClick={saveAgency}>
                    Добавить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddAgency;