export class WebServiceLoginResult {
    Username?: string;
    SipLogin?: string;
    SipPassword?: string;
    Id?:number;
    AccountId?: number;
    GroupAccountId?:number;
    UserRole?:string;
    ErrorMessage?:string;
    LicenceKey?: string;
    Error?: WebServiceLoginResults;
}

export enum WebServiceLoginResults {
    Ok = "Ok",
    LoginOrPasswordIncorrect = "LoginOrPasswordIncorrect",
    KeyIncorrect = "KeyIncorrect",
    ServerError = "ServerError",
    KeyNotFound = "KeyNotFound"
}