import React, { Dispatch, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { GeoMarkTariffs, BillboardTariff, GetGeoMarkTariff, Billboard } from '../../store/billboards/types';
import { TableRow, TableCell, Tooltip, TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import useClipboard from "react-use-clipboard";
import TariffFormControl from './TariffFormControl';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { BillboardsShortInfo } from '../../RemoteCommands/type';


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    tariffItem: GeoMarkTariffs;
    geoMarkId: number;
    addChecked: boolean;
    addCheckedCose: () => void;
    agencyTariffColor: (tariffItem: GeoMarkTariffs) => any;
}

const TariffTableCell:React.FC<Props> = (props) => {
    const { 
        tariffItem, 
        billboardTariffsOwner,
        geoMarkId, 
        currentAgency, 
        addChecked, 
        addCheckedCose, 
        currentBrand,
        agencyTariffColor,
        billboardsShortInfo
    } = props;

    const [editChecked, setEditChecked] = useState(false);
    const [tariffSate, setTariffState] = useState<GeoMarkTariffs>(tariffItem);
    const [billboard, setBillboard] = useState<BillboardsShortInfo | null>(null);
    const [nextName, setNextName] = useState('');
    const [currentCopy, setCurrentCopy] = useState('');
    const [isCopied, setCopied] = useClipboard(currentCopy);

    let newNextName: string;
    
    const onlyCopyClick = () => {
        setCopied();
        newNextName = nextName;
        onlyCopy();
    }

    const onlyCopy = () => {
        if(newNextName === "february" && currentCopy === tariffSate.february.toString()) {
            newNextName = "march";
            onlyCopy();
            return;
        }
        if(newNextName === "march" && currentCopy === tariffSate.march.toString()) {
            newNextName = "april";
            onlyCopy();
            return;
        }
        if(newNextName === "april" && currentCopy === tariffSate.april.toString()) {
            newNextName = "may";
            onlyCopy();
            return;
        }
        if(newNextName === "may" && currentCopy === tariffSate.may.toString()) {
            newNextName = "june";
            onlyCopy();
            return;
        }
        if(newNextName === "june" && currentCopy === tariffSate.june.toString()) {
            newNextName = "july";
            onlyCopy();
            return;
        }
        if(newNextName === "july" && currentCopy === tariffSate.july.toString()) {
            newNextName = "august";
            onlyCopy();
            return;
        }
        if(newNextName === "august" && currentCopy === tariffSate.august.toString()) {
            newNextName = "september";
            onlyCopy();
            return;
        }
        if(newNextName === "september" && currentCopy === tariffSate.september.toString()) {
            newNextName = "october";
            onlyCopy();
            return;
        }
        if(newNextName === "october" && currentCopy === tariffSate.october.toString()) {
            newNextName = "november";
            onlyCopy();
            return;
        }
        if(newNextName === "november" && currentCopy === tariffSate.november.toString()) {
            newNextName = "december";
            onlyCopy();
            return;
        }
        
        setTariffState(prev => ({...prev, ...{
            [newNextName]: Number(currentCopy)
        }}));
    }

    const allCopyClick = () => {
        setCopied();
        setTariffState(prev => ({...prev, ...{
            january: Number(currentCopy),
            february: Number(currentCopy),
            march: Number(currentCopy),
            april: Number(currentCopy),
            may: Number(currentCopy),
            june: Number(currentCopy),
            july: Number(currentCopy),
            august: Number(currentCopy),
            september: Number(currentCopy),
            october: Number(currentCopy),
            november: Number(currentCopy),
            december: Number(currentCopy)
        }}));
    }

    const KeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.keyCode == 88 && event.ctrlKey) {
            onlyCopyClick()
        }
        if(event.keyCode == 66 && event.ctrlKey) {
            allCopyClick();
        }
    }

    const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setTariffState(prev => ({...prev, ...{
            [event.target.name]: parseInt(event.target.value)
        }}));
        setCurrentCopy(event.target.value)
    };

    const changeSelectHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTariffState({
            ...tariffSate,
            side: event.target.value as string
        });
      };

    const editOpenClick = (tariffItem: GeoMarkTariffs) => {
        setTariffState(tariffItem);
        setEditChecked(true);
    }

    const deleteTariffClick = (tariffItem: GeoMarkTariffs) => {
        const brandId = currentBrand ? currentBrand.brandId : 0;
        const builder = new EventBuilder(`EventDeleteGeoMarkTariffs`);
        builder.AddParameter('GeoMarkId', tariffItem.geoMarkId);
        builder.AddParameter('OwnerAccountId', currentAgency!.AccountId);
        builder.AddParameter('BrandId', brandId);
        ServerProxy.SendSystemEvent(builder.GetSystemEvent());
    }

    useEffect(() => {
        const currentBillboard = billboardsShortInfo.find(item => item.geoMarkId === tariffSate.geoMarkId)!;
        setBillboard(currentBillboard);
    }, [tariffItem]);


    const saveClick = () => {
        const currentBillboardTariff = billboardTariffsOwner.find(item => item.geoMarkId === tariffSate.geoMarkId);
        let currentMonth: BillboardTariff[] = [];
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.january, 1));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.february, 2));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.march, 3));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.april, 4));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.may, 5));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.june, 6));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.july, 7));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.august, 8));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.september, 9));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.october, 10));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.november, 11));
        currentMonth.push(new BillboardTariff(tariffSate.side, tariffSate.december, 12));

        const payload = {
            geoMarkTariffId: currentBillboardTariff ? currentBillboardTariff.geoMarkTariffId : -1,
            geoMarkId: tariffSate.geoMarkId,
            billboardTariffs: currentMonth,
            changeTime: currentBillboardTariff ? currentBillboardTariff.changeTime : new Date(),
            ownerAccountId: currentBillboardTariff ? currentBillboardTariff.ownerAccountId : currentAgency!.AccountId,
            brandId: currentBrand ? currentBrand.brandId : 0,
        }
        const newGetGeoMarkTariff = new GetGeoMarkTariff(payload);

        // const builder = new EventBuilder(`EventSetGeoMarkTariffs`);
        //     builder.AddParameter('GeoMarkTariffs', JSON.stringify(newGetGeoMarkTariff));
        //     ServerProxy.SendSystemEvent(builder.GetSystemEvent());

        SystemEvent.EventSetGeoMarkTariffs(newGetGeoMarkTariff);
        addCheckedCose();
        setEditChecked(false);
    }

    const currentValueOnChange = (changeValue: BillboardsShortInfo) => {
        if(changeValue) {
            setBillboard(changeValue);
            setTariffState(prevState => ({
                ...prevState,
                name: changeValue.name,
                constructionType: "",
                format: "",
            }));
        }
    }

    useEffect(() => {
        if(tariffItem.geoMarkId === geoMarkId && addChecked) {
            setEditChecked(true); 
        }
    }, [addChecked, geoMarkId]);

    // const billboardsSort = billboards.sort((a: Billboard, b: Billboard) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    if(editChecked) {
        return (
            <TableRow key={tariffItem.geoMarkId} className="tariffEdit">
                <TableCell component="th" scope="row" style={{ width: 310 }}>
                    <Autocomplete
                        options={billboardsShortInfo}
                        getOptionLabel={option => option.name}
                        value={billboard}
                        onChange={(event: React.ChangeEvent<{}>, changeValue: BillboardsShortInfo) => currentValueOnChange(changeValue)}
                        filterOptions={(options: Billboard[], state: FilterOptionsState) => fliterOptionsAdress(options, state)}
                        renderOption={option => <span className="tariffAddressOption">{option.name}</span>}
                        renderInput={params => (
                            <TextField 
                                {...params}
                                fullWidth 
                                size="small" />
                        )}
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField 
                        value={tariffSate.constructionType} 
                        size="small" 
                        fullWidth 
                        name="constructionType"
                        onChange={changeInputHandler} />
                </TableCell>
                <TableCell align="center">
                    <TextField 
                        value={tariffSate.format}
                        size="small" 
                        fullWidth 
                        name="format"
                        onChange={changeInputHandler} />
                </TableCell>
                <TableCell align="center">
                    {addChecked ?
                        <FormControl size="small">
                            <Select
                                value={tariffSate.side}
                                name="side"
                                onChange={changeSelectHandler}
                                autoFocus={tariffSate.side === '' ? true : false}>
                                <MenuItem value="A">A</MenuItem>
                                <MenuItem value="B">B</MenuItem>
                            </Select>
                        </FormControl>
                        :
                        <TextField 
                            value={tariffSate.side}
                            size="small" 
                            fullWidth 
                            name="side"
                            onChange={changeInputHandler} />
                    }
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.january === 0 ? '' : tariffSate.january} 
                        name="january"
                        nextName="february" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.february === 0 ? '' : tariffSate.february} 
                        name="february"
                        nextName="march" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.march === 0 ? '' : tariffSate.march} 
                        name="march"
                        nextName="april" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.april === 0 ? '' : tariffSate.april} 
                        name="april"
                        nextName="may" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.may === 0 ? '' : tariffSate.may} 
                        name="may"
                        nextName="june" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.june === 0 ? '' : tariffSate.june} 
                        name="june"
                        nextName="july" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.july === 0 ? '' : tariffSate.july} 
                        name="july"
                        nextName="august" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.august === 0 ? '' : tariffSate.august} 
                        name="august"
                        nextName="september" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.september === 0 ? '' : tariffSate.september} 
                        name="september"
                        nextName="october" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.october === 0 ? '' : tariffSate.october} 
                        name="october"
                        nextName="november" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.november === 0 ? '' : tariffSate.november} 
                        name="november"
                        nextName="december" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <TariffFormControl 
                        value={tariffSate.december === 0 ? '' : tariffSate.december} 
                        name="december"
                        nextName="" 
                        changeInputHandler={changeInputHandler} 
                        setNextName={(value: string) => setNextName(value)} 
                        keyPress={KeyPress} 
                        allCopyClick={allCopyClick}
                        onlyCopyClick={onlyCopyClick}
                    />
                </TableCell>
                <TableCell align="center">
                    <Tooltip title="Сохранить" placement="top">
                        <CheckIcon 
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={saveClick} />
                    </Tooltip>
                    <Tooltip title="Отменить" placement="top">
                        <CloseIcon 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => setEditChecked(false)} />
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    } else {
        return (
            <TableRow 
                key={tariffItem.geoMarkId}
                style={{backgroundColor: agencyTariffColor(tariffItem)}}>
                <TableCell component="th" scope="row" style={{ width: 310 }}>{tariffItem.name}</TableCell>
                <TableCell align="center">{tariffItem.constructionType}</TableCell>
                <TableCell align="center">{tariffItem.format}</TableCell>
                <TableCell align="center">{tariffItem.side}</TableCell>
                <TableCell align="center">{tariffItem.january === 0 ? '' : tariffItem.january}</TableCell>
                <TableCell align="center">{tariffItem.february === 0 ? '' : tariffItem.february}</TableCell>
                <TableCell align="center">{tariffItem.march === 0 ? '' : tariffItem.march}</TableCell>
                <TableCell align="center">{tariffItem.april === 0 ? '' : tariffItem.april}</TableCell>
                <TableCell align="center">{tariffItem.may === 0 ? '' : tariffItem.may}</TableCell>
                <TableCell align="center">{tariffItem.june === 0 ? '' : tariffItem.june}</TableCell>
                <TableCell align="center">{tariffItem.july === 0 ? '' : tariffItem.july}</TableCell>
                <TableCell align="center">{tariffItem.august === 0 ? '' : tariffItem.august}</TableCell>
                <TableCell align="center">{tariffItem.september === 0 ? '' : tariffItem.september}</TableCell>
                <TableCell align="center">{tariffItem.october === 0 ? '' : tariffItem.october}</TableCell>
                <TableCell align="center">{tariffItem.november === 0 ? '' : tariffItem.november}</TableCell>
                <TableCell align="center">{tariffItem.december === 0 ? '' : tariffItem.december}</TableCell>
                <TableCell align="center">
                    <Tooltip title="Редактировать" placement="top">
                        <EditIcon 
                            fontSize="small" 
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={() => editOpenClick(tariffItem)} />
                    </Tooltip>
                    <Tooltip title="Удалить" placement="top">
                        <DeleteIcon 
                            fontSize="small" 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => deleteTariffClick(tariffItem)} />
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    billboardTariffsOwner: state.billboards.billboardTariffsOwner,
    currentAgency: state.event.currentAgency,
    currentBrand: state.billboards.currentBrandInfo,
    billboardsShortInfo: state.billboards.billboardsShortInfo
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    currentBillboardAction: (newBillboard: Billboard) => dispatch(actions.billboards.currentBillboard(newBillboard))
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffTableCell);

function fliterOptionsAdress(options: Billboard[], state: FilterOptionsState) {
    const optionsArray: Billboard[] = options.filter(option => 
        option.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 
        || option.name.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
    return optionsArray;
}