import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Slider from "react-slick";
import Fancybox from '../../hooks/FancyboxHook';
import { Settings } from '../../utils/settings';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Tooltip, Fab, Menu, MenuItem } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { convertBase64 } from '../../utils/convertBase64';
import moment from 'moment';
import { DatesRange } from '../bricks/InputDatesRange';
import { ImageType } from '../../RemoteCommands/type';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

type Props = {
    uploadedPhotos: string[];
    geoMarkId: number;
    selectedSide: string;
    deletePhotoAction: (geoMarkId: number, sideName: string) => Promise<any>;
    dates: DatesRange;
    imageTypes: ImageType[];
};

export const SlickCarousel = (props: Props) => {
    const { uploadedPhotos, geoMarkId, selectedSide, deletePhotoAction, dates, imageTypes } = props;
    const [loader, setLoader] = useState(false);
    const [imageType, setImageType] = useState('');
    const [editEl, setEditEl] = React.useState<null | HTMLElement>(null);
    const [deleteEl, setDeleteEl] = React.useState<null | HTMLElement>(null);
    const [photoVersion, setPhotoVersion] = useState(new Date().getTime());
    const [imageFindTypes, setImageFindTypes] = useState<ImageType[]>([]);

    const sideImageTypes = useCallback(() => {
        const result: ImageType[] = [];
        const sideNumber = parseInt(selectedSide.replace(/[^\d]/g, ''));
        const name = (selectedSide.includes('B') || sideNumber % 2 === 0) ? "2" : "1";
        for(let imageType of imageTypes) {
            if(name === '1') {
                if(imageType.imageType.includes(name) && !imageType.imageType.includes('Side2Additional1')) {
                    result.push(imageType);
                }
            } else {
                if(imageType.imageType.includes(name) && !imageType.imageType.includes('Side1Additional2')) {
                    result.push(imageType);
                }
            }
        }
        if(!result.some(item => item.imageType == "TT2"))
        {
            const ttImageType = imageTypes.find(item => item.imageType === "TT");
            if(ttImageType) {
                result.push(ttImageType);
            }
        }
        return result;
    }, [imageTypes, selectedSide]);

    useMemo(() => {
        const result: ImageType[] = [];
        for(let image of imageTypes) {
            if(uploadedPhotos.some(item => item === image.imageType)) {
                result.push(image);
            }
        }
        setImageFindTypes(result);
    }, [uploadedPhotos]);

    const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEditEl(event.currentTarget);
    };

    const getBillboardInfo = () => {
        const dateStart = moment.utc(dates.DateFrom, "YYYY-MM-DD").format('DD.MM.YYYY');
        const dateEnd = moment.utc(dates.DateTo, "YYYY-MM-DD").format('DD.MM.YYYY');
        SystemEvent.EventGetBillboardInfo(geoMarkId, dateStart, dateEnd);
    }

    const deletePhoto = async (type: string) => {
        uploadedPhotos.splice( uploadedPhotos.indexOf(selectedSide), 1 );
        await deletePhotoAction(geoMarkId, type);
        getBillboardInfo();
    }

    const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDeleteEl(event.currentTarget);
    };

    const handleEditClose = (type: ImageType) => {
        setImageType(type.imageType);
        setEditEl(null);
    };

    const handleDeleteClose = (type: ImageType) => {
        deletePhoto(type.imageType);
        setDeleteEl(null);
    }

    useEffect(() => {
        setLoader(true);
        SystemEvent.SubscribeEventSetBillboardPhoto(
            "SlickCarousel", 
            (answer) => {
                setPhotoVersion(new Date().getTime());
                getBillboardInfo();
            }, 
            (error) => errorCallback(error)
        );
        setTimeout(() => {
            setLoader(false);
        }, 600);
    }, []);

    const fileSelectedHandler = async (event: React.ChangeEvent<HTMLInputElement>, billboardId: number) => {
        try {
            const target = event.target as HTMLInputElement;
            const photo: File = (target.files as FileList)[0];

            const base64: string = await convertBase64(photo);
            const base64Replace = base64.replace(/^data:(.*,)?/, '');
            SystemEvent.EventSetBillboardPhoto(billboardId, imageType, base64Replace);
            target.value = "";

        } catch(e) {
            console.log('Ошибка fileSelectedHandler' + e);
        }
        
    }

    if(loader) {
        return null;
    }

    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        className: "mainSlider mainCarousel"
    };

    return (
        <div className="billboardDetailRow">
            <Fancybox options={{ infinite: false, placeFocusBack: false }}>
                <Slider {...settings}>
                    {uploadedPhotos.map(photo => {
                        const imgSrc = Settings.useNewUrl ?
                        `${Settings.imgUrlNew + geoMarkId}&imagetype=${photo}`: 
                        `${Settings.imgUrl + geoMarkId}&sideName=${photo}&v=${photoVersion}`;
                        const imgSrcBig = Settings.useNewUrl ?
                        `${Settings.imgUrlNew + geoMarkId}&imagetype=${photo}&bigPhoto=true` 
                        : `${Settings.imgUrl + geoMarkId}&sideName=${photo}&bigPhoto=true&v=${photoVersion}`;
                        return (
                            <a 
                                key={`carousel-${photo}`}
                                data-fancybox="gallery"
                                data-onFocus={false}
                                href={imgSrcBig}
                                className="mainSlider__item">
                                <img src={imgSrc} alt="" />
                            </a>
                        )
                    })}
                </Slider>
            </Fancybox>
            <input
                id="upload-photo-file" 
                type="file"
                onChange={(event) => fileSelectedHandler(event, geoMarkId)}
                className="d-none" />
            <Tooltip title="Загрузить изображение">
                <Fab 
                    size="small" 
                    color="default"
                    className="billboardDetailRow__upload"
                    onClick={handleEditClick}>
                    <PhotoCamera fontSize="small" />
                </Fab>
            </Tooltip>
            <Menu
                id="photo-menu"
                anchorEl={editEl}
                keepMounted
                open={Boolean(editEl)}
                onClose={() => setEditEl(null)}>
                {sideImageTypes().map(type => (
                    <MenuItem onClick={() => handleEditClose(type)}>
                        <label htmlFor="upload-photo-file">{type.localization}</label>
                    </MenuItem>
                ))}
            </Menu>
            {imageFindTypes.length > 0 &&
                <Tooltip title="Удалить изображение" placement="top">
                    <Fab 
                        size="small" 
                        color="default"
                        className="billboardDetailRow__delete"
                        onClick={handleDeleteClick}>
                        <DeleteIcon 
                            fontSize="small" />
                    </Fab>
                </Tooltip>
            } 
            <Menu
                id="photo-menu-delete"
                anchorEl={deleteEl}
                keepMounted
                open={Boolean(deleteEl)}
                onClose={() => setDeleteEl(null)}>
                {imageFindTypes.map(type => (
                    <MenuItem onClick={() => handleDeleteClose(type)}>{type.localization}</MenuItem>
                ))}
            </Menu>     
        </div>
    );
};