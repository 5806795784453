import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ActionFromBackend } from '../../../../../../store/todo/types';
import { RootState, selectors } from '../../../../../../store';
import { ClientModal, ConnectedClientModal } from '../../../../../../pages/ClientModal';
import { ClientInfo, PhoneInfo } from 'sedi-webserverproxy';
import { ActionFromBackendButton } from '../../../../../../store/todo/types';
import Button from '@material-ui/core/Button';
import GroupIcon from '@material-ui/icons/Group';

type Props = ReturnType<typeof mapStateToProps> & {
    action: ActionFromBackendButton
}


type State = {
    showClientModal: boolean,
    companyInfo: ClientInfo
}


class ButtonOpenCallerCompanyProfile extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let company = new ClientInfo();
        company.isLegalEntity = true;

        this.state = {
            showClientModal: false,
            companyInfo: company
        }
    }


    componentDidUpdate(prevProps: Props) {
        const { company } = this.props;

        if (company && company !== prevProps.company) {
            this.setState({ companyInfo: company });
        }
    }

    
    render() {
        const { showClientModal, companyInfo } = this.state;
        const { action } = this.props;

        return (
            <>
                <Button 
                    variant="contained" 
                    color="primary"
                    size="small"
                    startIcon={<GroupIcon />}
                    onClick={() => this.setState({ showClientModal: true })}>
                    <span className="buttonText">{action.Text}</span>
                </Button>
                <ConnectedClientModal
                    show={showClientModal}
                    onClose={() => this.setState({ showClientModal: false })}
                    clientInfo={companyInfo} />
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    let customerAccountID = selectors.sip.GetCallerAccountID(state.sip);

    const customer = selectors.customers.getCustomerByAccountID(state.customers, customerAccountID);
    let company: ClientInfo | undefined;
        
    if (customer && customer.customerCompanyId !== null && customer.customerCompanyId > 0) {
        company = selectors.customers.getCompanyByID(state.customers, customer.customerCompanyId);
    }

    return {
        company
    }
};


export default connect(
    mapStateToProps
)(ButtonOpenCallerCompanyProfile);