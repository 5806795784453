import React, { Component } from 'react';
import Select, { SelectChangeEvent } from './Select';
import { ButtonGroup, Button } from '@material-ui/core';
import IconFastForward from '@material-ui/icons/FastForward';
import IconFastRewind from '@material-ui/icons/FastRewind';
import './bricks.scss'


type Props = {
    rangeType: RangeType,
    onChangeRangeType: (rangeType: RangeType) => void,
    onShift: (increase: boolean, rangeType: RangeType) => void
};


export enum RangeType {
    Week = 'Week',
    TwoWeeks = 'TwoWeeks',
    Month = 'Month'
}


type State = {
    rangeType: RangeType,
};


export default class DatesShift extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            rangeType: props.rangeType
        };
    }


    handleChangeRangeType(event: SelectChangeEvent) {
        const newRangeType = event.target.value as RangeType;

        this.setState({ rangeType: newRangeType});

        this.props.onChangeRangeType(newRangeType);
    }   


    handleClickShiftDates(increase: boolean) {
        const { rangeType } = this.state;
        
        this.props.onShift(increase, rangeType);
    }


    render() {
        const { rangeType } = this.state;

        return (            
            <ButtonGroup className='ml-3' size="small" aria-label="small outlined button group">
                
                <Button
                    onClick ={() => this.handleClickShiftDates(false)} >
                    <IconFastRewind />
                </Button>
                
                <Button className="datesShifSelect">
                    <Select
                        margin="dense"
                        value={rangeType}
                        dataSource={RangeType}
                        inputProps={{className: 'mx-2'}}
                        onChange={event => this.handleChangeRangeType(event)}
                        color="primary">
                    </Select>
                </Button>
                
                <Button
                    onClick={() => this.handleClickShiftDates(true)} >
                    <IconFastForward />
                </Button>

            </ButtonGroup>
        );
    }
}