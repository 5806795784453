import React, { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Tooltip } from '@material-ui/core';
import { EmployeeBrandsInfo } from 'sedi-webserverproxy';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import AddIcon from '@material-ui/icons/Add';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { GeoMarkTariffsParams } from '../../RemoteCommands/type';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    disabled: boolean;
    label?: string;
}

const BrandAutocomplete:React.FC<Props> = (props) => {
    const { 
        brandsInfo, 
        currentAgency, 
        currentBrandInfoAction, 
        disabled, 
        currentBrandInfo,
        label
    } = props;

    const [addBrandIcon, setAddBrandIcon] = useState(false);
    const [newBrandName, setNewBrandName] = useState("");

    const currentValueOnChange = (changeValue: EmployeeBrandsInfo) => {
        if(changeValue) {
            if(currentAgency) {
                // const builder = new EventBuilder(`EventGetGeoMarkTariffs`);
                //     builder.AddParameter('OwnerAccountId', currentAgency.AccountId);
                //     builder.AddParameter('BrandId', changeValue.brandId);
                //     ServerProxy.SendSystemEvent(builder.GetSystemEvent());
                const payload: GeoMarkTariffsParams = {
                    ownerAccountId: currentAgency.AccountId,
                    brandId: changeValue.brandId
                }
                SystemEvent.EventGetGeoMarkTariffs(payload);
            }
        } else {
            if(currentAgency) {
                // const builder = new EventBuilder(`EventGetGeoMarkTariffs`);
                //     builder.AddParameter('OwnerAccountId', currentAgency.AccountId);
                //     ServerProxy.SendSystemEvent(builder.GetSystemEvent());
                const payload: GeoMarkTariffsParams = {
                    ownerAccountId: currentAgency.AccountId
                }
                SystemEvent.EventGetGeoMarkTariffs(payload);
            }
        }
        currentBrandInfoAction(changeValue);
    }

    const fliterOptions = (options: EmployeeBrandsInfo[], state: FilterOptionsState) => {
        const optionsArray: EmployeeBrandsInfo[] = 
            options.filter(option => option.brandName.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 
                || option.brandName.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);

        if(optionsArray.length === 0) {
            setNewBrandName(state.inputValue)
            setAddBrandIcon(true);
        } else {
            setNewBrandName("");
            setAddBrandIcon(false);
        }
        return optionsArray
    }

    const addNewBrandClick = () => {
        if(newBrandName.includes('"')) {
            alert("Вы не можете использовать кавычки в имени бренда");
            return;
        }
        const brand = {
            BrandId: -1,
            BrandName: newBrandName,
            Description: null
        }
        const builder = new EventBuilder(`EventSetBrand`);
            builder.AddParameter('Brand', JSON.stringify(brand));
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
    }

    return (
        <div className="brandAutocomplete">
            <Autocomplete
                options={brandsInfo}
                getOptionLabel={(option: EmployeeBrandsInfo) => option.brandName}
                style={{ minWidth: 260 }}
                value={currentBrandInfo}
                onChange={(event, changeValue) => currentValueOnChange(changeValue)}
                // disabled={disabled}
                filterOptions={(options: EmployeeBrandsInfo[], state: FilterOptionsState) => fliterOptions(options, state)}
                className="cancelDraggable"
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        fullWidth 
                        label={label ? label : "Выберите бренд"} 
                        variant="outlined" 
                        size="small" 
                    />
                }
            />
            {addBrandIcon && 
                <Tooltip 
                    title="Добавить новый бренд" 
                    placement="top">
                    <AddIcon 
                        className="addNewBrand" 
                        onClick={addNewBrandClick} />
                </Tooltip>
            }
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    brandsInfo: state.billboards.brandsInfo,
    currentAgency: state.event.currentAgency,
    currentBrandInfo: state.billboards.currentBrandInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    currentBrandInfoAction: (currentBrand: EmployeeBrandsInfo) => 
        dispatch(actions.billboards.currentBrandInfoAction(currentBrand))
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandAutocomplete);