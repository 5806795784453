import axios from 'axios';

const instance = axios.create({
    baseURL: `https://test2.sedi.ru/handlers/`,
})

export const AutocompleteAPI = {
    async autocomplete(search: string) {
        return await instance.get(`autocomplete.ashx?q=addr&types=street,object,city&search=${search}`).then(res => {
            return res.data;
        })
    },

    async autocompleteAllHouse(city: string, street: string) {
        return await instance.get(`autocomplete.ashx?q=addr&city=${city}&streetobj=${street}&house=*`).then(res => {
            return res.data;
        })
    },

    async autocompleteHouse(city: string, street: string, house: string) {
        return await instance.get(`autocomplete.ashx?q=addr&city=${city}&streetobj=${street}&house=${house}`).then(res => {
            return res.data;
        })
    },
}