import { EventParameters } from "../../utils/eventHelpers";
import { AssignedObject, GeoMarkStatus, IGetAddressItem } from "sedi-webserverproxy";
import { EventError } from "../../RemoteCommands/SystemEvent";
import { MonthYear } from "../../utils/getMonthYear";
import { AdditionalGeoMarkTariffs, BillboardsShortInfo, IAdditionalCostParameters, IBillboardsUsageInfoItems, ISales, TariffParameterConditions } from "../../RemoteCommands/type";

export interface EventState {
    eventName: string,
    eventPatterns: { [id: string]: EventParameters };
    agencyInfo: AgencyInfo[],
    messageToUsers: MessageToUser[],
    currentAgency: AgencyInfo | null,
    agenciesOpen: boolean,
    statusStatisticsFull: StatusStatisticsFull[],
    statusStatistics: StatusStatistics[],
    addNewAgency: boolean,
    errorMessage: string,
    successMessage: string | null,
    maxDistance: string,
    maxDistanceSearch: boolean,
    showMarkers: boolean,
    addresses: IGetAddressItem[],
    selectedAddress: IGetAddressItem | null,
    brandGeoObjects: BrandGeoObjects[],
    newBrandGeoObjects: BrandGeoObjects[],
    loading: boolean,
    openNextBillboard: boolean,
    addressValue: string,
    searchGeoObjectsToggle: boolean,
    setBrandBoolean: boolean,
    selectedFocus: SelectedFocus,
    salesStatisticsToggle: boolean,
    eventError: EventError | null,
    geoPointNull: boolean,
    monthYear: MonthYear[],
    currentMonthYear: MonthYear | null,
    rollUpCards: RollUpCard[];
    statisticsType: StatisticsType;
    fullMonthYear: boolean;
    additionalCost: number;
    curretnSale: ISales | null;
    changeProjectStatus: boolean;
    disabledScoreSave: boolean;
    useAllFreeGeoMarkParts: boolean;
    usageInfo: IBillboardsUsageInfoItems | null,
    openEmployeesCard: boolean;
    employeesCount: number;
    additionalCostParameters: IAdditionalCostParameters[];
    disabledEdit: boolean;
    openAdditionalTariff: boolean;
    additionalTariffs: TariffParameterConditions[];
    additionalGeoMarkTariffs: AdditionalGeoMarkTariffs[];
    additionalGeoMarkTariff: AdditionalGeoMarkTariffs | null;
    messageInfo: MessageInfo | null;
    regions: string[],
    cities: string[],
    projectStatistics: boolean;
    cityStatistics: boolean;
    openSalesInformation: boolean;
    openCurrentSales: boolean;
    zIndex: number;
    gid: string | undefined;
    dontSale: boolean;
    openBillsCard: boolean;
    openPhotocontrolCard: boolean;
}

export type MessageInfo = {
    text: string;
    variant: "success" | "error" | "warning";
}

export type RollUpCard = {
    title: string,
    key: string,
}

export enum EventActionsTypes {
    EVENT_PATTERNS = 'EVENT_PATTERNS',
    GET_AGENCY_INFO = 'GET_AGENCY_INFO',
    MESSAGE_TO_USER = 'MESSAGE_TO_USER',
    MESSAGE_TO_USERS = 'MESSAGE_TO_USERS',
    CURRENT_AGENCY = 'CURRENT_AGENCY',
    EVENT_NAME = 'EVENT_NAME',
    AGENCIES_OPEN = 'AGENCIES_OPEN',
    STATUS_STATISTICS = 'STATUS_STATISTICS',
    STATUS_STATISTICS_FULL = 'STATUS_STATISTICS_FULL',
    ADD_NEW_AGENCY = 'ADD_NEW_AGENCY',
    ERROR_MESSAGE = 'ERROR_MESSAGE',
    SUCCESS_MESSAGE = 'SUCCESS_MESSAGE',
    MAX_DISTANCE = 'MAX_DISTANCE',
    MAX_DISTANCE_SEARCH = 'MAX_DISTANCE_SEARCH',
    SHOW_MARKERS = 'SHOW_MARKERS',
    GET_ADDRESSES = 'GET_ADDRESSES',
    SELECTED_ADDRESS = 'SELECTED_ADDRESS',
    GET_BRAND_GEO_OBJECTS = 'GET_BRAND_GEO_OBJECTS',
    GET_NEW_BRAND_GEO_OBJECTS = 'GET_NEW_BRAND_GEO_OBJECTS',
    GET_BRAND_GEO_OBJECT = 'GET_BRAND_GEO_OBJECT',
    LOADING = 'LOADING',
    OPEN_NEXT_BILLBOARD = 'OPEN_NEXT_BILLBOARD',
    BRAND_MARKERS = 'BRAND_MARKERS',
    ADDRESS_VALUE = 'ADDRESS_VALUE',
    SEARCH_GEO_OBJECTS_TOGGLE = 'SEARCH_GEO_OBJECTS_TOGGLE',
    SET_BRAND_BOOLEAN = 'SET_BRAND_BOOLEAN',
    SELECTED_FOCUS = 'SELECTED_FOCUS',
    SALES_STATISTICS_TOGGLE = 'SALES_STATISTICS_TOGGLE',
    EVENT_ERROR = 'EVENT_ERROR',
    GEO_POINT_NULL = 'GEO_POINT_NULL',
    MONTH_YEAR = 'MONTH_YEAR',
    CURRENT_MONTH_YEAR = 'CURRENT_MONTH_YEAR',
    ROLL_UP_CARD = 'ROLL_UP_CARD',
    ROLL_UP_CARDS = 'ROLL_UP_CARDS',
    STATISTICS_TYPE = 'STATISTICS_TYPE',
    FULL_MONTH_YEAR = 'FULL_MONTH_YEAR',
    ADDITIONAL_COST = 'ADDITIONAL_COST',
    CURRENT_SALE = 'CURRENT_SALE',
    CHANGE_PROJECT_STATUS = 'CHANGE_PROJECT_STATUS',
    DISABLED_SCORE_SAVE = 'DISABLED_SCORE_SAVE',
    USER_ALL_FREE_GOE_MARK_PARTS = 'USER_ALL_FREE_GOE_MARK_PARTS',
    USAGE_INFO = 'USAGE_INFO',
    OPEN_EMPLOYEES_CARD = 'OPEN_EMPLOYEES_CARD',
    EMPLOYEES_COUNT = 'EMPLOYEES_COUNT',
    ADDITIONAL_COST_PARAMETETS = 'ADDITIONAL_COST_PARAMETETS',
    DISABLED_EDIT = 'DISABLED_EDIT',
    OPEN_ADITIONAL_TARIFF = 'OPEN_ADITIONAL_TARIFF',
    ADITIONAL_TARIFFS = 'ADITIONAL_TARIFFS',
    ADDITIONAL_GEO_MARK_TARIFFS = 'ADDITIONAL_GEO_MARK_TARIFFS',
    ADDITIONAL_GEO_MARK_TARIFF = 'ADDITIONAL_GEO_MARK_TARIFF',
    MESSAGE_INFO = 'MESSAGE_INFO',
    CITIES = 'CITIES',
    REGIONS = 'REGIONS',
    PROJECT_STATISTICS = 'PROJECT_STATISTICS',
    CITY_STATISTICS = 'CITY_STATISTICS',
    OPEN_SALES_INFORMATION = 'OPEN_SALES_INFORMATION',
    OPEN_CURRENT_SALES = 'OPEN_CURRENT_SALES',
    Z_INDEX = 'Z_INDEX',
    GID = 'GID',
    DONT_SALE = 'DONT_SALE',
    OPEN_BILLS_CARD = 'OPEN_BILLS_CARD',
    OPEN_PHOTOCONTROL_CARD = 'OPEN_PHOTOCONTROL_CARD'
}

export enum SelectedFocus {
    company = "company",
    project = "project",
    none = "none"
}

export enum StatisticsType {
    sale = "sale",
    client = "client",
    employee = "employee",
    region = "region",
    city = "city",
    constructionType = "constructionType",
    loadingConstructions = "loadingConstructions"
}

export interface AgencyInfo {
    AccountId: number,
    AgencyId: number,
    AgencyMemberIds: number[],
    AgencyName: string,
    AgencyDiscount: number,
}

export interface IMessageToUser {
    messageId: number,
    messageText: string,
}

export class MessageToUser implements IMessageToUser {
    messageId: number
    messageText: string;
    constructor(messageId: number, messageText: string) {
        this.messageId = messageId;
        this.messageText = messageText;
    }
}

export interface StatusStatisticsFull {
    Count: number,
    Month: number,
    Status: GeoMarkStatus,
    Year: number,
    Description: string,
}

export interface StatusStatistics {
    Gid: string,
    Month: number,
    Status: GeoMarkStatus,
    Year: number,
}

export type GeoPoint = {
    Latitude: number;
    Longitude: number;
}

export type GeoObjectAddress = { 
    CountryName: string, 
    CityName: string, 
    StreetName: string, 
    HouseNumber: string,
    ObjectName: string,
    GeoPoint: GeoPoint
}
export interface IGeoObjectBillboards {
    GeoMarkId: number,
    GeoMarkName: string,
    Address: GeoObjectAddress,
    GeoPoint: GeoPoint
}

export interface IBrandGeoObjects {
    GeoMarkId: number;
    GeoMarkName: string;
    Address: BrandAddress | null;
    GeoPoint: GeoPoint | null;
    Billboards: IGeoObjectBillboards[] | null;
}
export class BrandGeoObjects implements IBrandGeoObjects {
    GeoMarkId: number;
    GeoMarkName: string;
    Address: BrandAddress | null;
    GeoPoint: GeoPoint | null;
    Billboards: IGeoObjectBillboards[] | null;
    
    constructor(GeoMarkId?: number, GeoMarkName?: string, Address?: BrandAddress, GeoPoint?: GeoPoint | null, Billboards?: IGeoObjectBillboards[]) {
        this.GeoMarkId = GeoMarkId ? GeoMarkId : 0;
        this.GeoMarkName = GeoMarkName ? GeoMarkName : '';
        this.Address = Address ? Address : null;
        this.GeoPoint = GeoPoint ? GeoPoint : null;
        this.Billboards = Billboards ? Billboards : null;
    }
}

export type BrandAddress = {
    AddressString: string,
    ApartmentNumber?: string,
    AreaDistrictName?: string,
    AreaName?: string,
    CityId?: number,
    CityName?: string,
    CountryName?: string,
    EntranceNumber?: number,
    GeoPoint?: GeoPoint,
    HouseNumber?: string,
    Id?: number,
    LocalityName?: string,
    NearestPoint?: string,
    ObjectName?: string,
    PostalCode?: string,
    Precision?: number,
    StreetName?: string
}

export interface ImportBrandsAnswer {
    executionPercent: number;
    geoMarks: BrandGeoObjects[] | null;
}

export type BrandGeoObjectBillboard = {
    address: AssignedObject | undefined;
    billboard: BillboardsShortInfo;
}

export enum GeoObjectType {
    newGeoObject = "newGeoObject",
    importGeoObject = "importGeoObject",
    geoObject = "geoObject"
}

export type SortOrder = {
    order: OrderType;
    orderBy: string;
}
export enum OrderType {
    asc = "asc",
    desc = "desc"
}