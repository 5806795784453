import React from 'react';
import AdditionalCostFormControl from './AdditionalCostFormControl';

type Props = {
    value: string | number;
    name: string;
    changeInputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const AdditionalCostFormControlContainer:React.FC<Props> = (props) => {
    const {
        value,
        name,
        changeInputHandler,
        disabled
    } = props;

    return (
        <AdditionalCostFormControl 
            value={value}
            name={name}
            changeInputHandler={changeInputHandler} 
            disabled={disabled} />
    );
}

export default AdditionalCostFormControlContainer;