import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { ActionFromBackend } from '../../../../store/todo/types';
import ActionItem from './components/ActionItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField } from '@material-ui/core';
import { textCut } from '../../../../utils/textCut';


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    action: ActionFromBackend,
    // порядовый номер, вычисляется на родителе, т.к. action.SequenceNumber может быть с шагом больше 1
    sequenceNumber: number,
}


const ToDoAction: React.FunctionComponent<Props> = ({ action, permissionResolutionNegative, note, noteAction }) => {

    const [allText, setAllText] = useState(false);

    const actionItems = action.Items.map((item) =>
        <ActionItem key={item.Text + Math.random()} actionItem={item} />
    );

    const limit = 57;
    const arrowToggle = action.Description && action.Description.length >= limit;
    const showButtons = !arrowToggle || allText;
    const showTitle = action.Header && action.Header.length > 0;

    const notificationChange = (value: string) => {
        noteAction(value);
    }

    return (
        <div className={`notification ${action.ActionName.includes('FirstAction') ? 'fixedButton' : ''}`}>
            <div className="notification__title">
                {showTitle && action.Header}
            </div>
            <div className="notification__content">
                {/* <span>{action.SequenceNumber}{' '}</span> */}
                {arrowToggle && <ExpandMoreIcon onClick={() => setAllText(!allText)} className={`notification__more ${allText && 'toggle'}`}/>}
                <div className="notification__desc">
                    {allText ? action.Description : textCut(action.Description, limit)}
                </div>
                {action.ActionName.includes('SendCommercialOfferToController') && permissionResolutionNegative && 
                    <div className="notification__textField">
                        <TextField
                            label="Примечание"
                            autoFocus
                            multiline
                            variant="outlined" 
                            size="small" 
                            fullWidth 
                            value={note}
                            onChange={(event) => notificationChange(event.target.value)}/>
                    </div>
                }
                {showButtons && actionItems}
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    permissionResolutionNegative: state.todo.permissionResolutionNegative,
    note: state.todo.note,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    noteAction: (note: string) => dispatch(actions.todo.noteAction(note))
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(ToDoAction);