import React from 'react';
import Button from './Button';
import { ActionFromBackendButton } from '../../../../../../store/todo/types';
import { SystemEventName } from 'sedi-webserverproxy';
import { getWebServerProxy } from '../../../../../../WebServerProxy';


type Props = {
    action: ActionFromBackendButton
}


const ButtonCompleteCurrentAction: React.FunctionComponent<Props> = ({ action }) => {
    return (
        <Button text={action.Text} onClick={completeCurrentAction} />
    );
}


const completeCurrentAction = () => {
    getWebServerProxy().WebClient.SaveSystemEvent(SystemEventName.CompleteCurrentAction, []);
}


export default ButtonCompleteCurrentAction;