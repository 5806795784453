import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
    scoreOpen: boolean;
    handleScoreClose: () => void;
};

const ScoreModal:React.FC<Props>  = (props) => {
    const {
        scoreOpen,
        handleScoreClose
    } = props;

    return (
        <Dialog
            open={scoreOpen}
            onClose={handleScoreClose}
        >
            <DialogTitle>
                Расчетный счет
                <IconButton 
                    style={{ position: 'absolute', top: '5px', right: '5px' }} 
                    onClick={handleScoreClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro, earum? Voluptate cupiditate eaque placeat possimus. 
                    Neque accusamus iure id officia, asperiores recusandae in, alias quisquam ab necessitatibus cumque odit corporis.
                </p>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro, earum? Voluptate cupiditate eaque placeat possimus. 
                    Neque accusamus iure id officia, asperiores recusandae in, alias quisquam ab necessitatibus cumque odit corporis.
                </p>
            </DialogContent>
        </Dialog>
    );
}

export default ScoreModal;