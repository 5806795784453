import React, {Component, Dispatch} from 'react';
import Autocomplete, { RenderInputParams } from '@material-ui/lab/Autocomplete';
import { ClientInfo, GetCustomersFilter } from 'sedi-webserverproxy';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { TextField } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import InputEmailValidate from './inputEmailValidate';
import { EmailValue } from '../../utils/validateEmail';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import './CompanyAutocomplete.scss';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { sortAlphabeticallyClientInfo } from '../../utils/sortAlphabetically';
import { Translate } from 'react-localize-redux';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    currentValue: ClientInfo | string | null;
    onChange: (currentCompany: ClientInfo | null) => void;
    label?: any;
    onChangeTextField?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    autoFocus?: boolean;
    type?: 'name' | 'email' | 'INN';
    value?: string;
    onEmailChange?: (value: EmailValue) => void;
    InputProps?: Partial<OutlinedInputProps> | undefined;
    onClick?: ((event: any) => void) | undefined;
    IsLegalEntity?: boolean;
    projectSelector?: boolean;
    onAddNewEmail?: () => void;
    addCompanyOrClient?: boolean;
}

class CompanyAutocomplete extends Component<Props> {
    // static defaultProps = {type: 'name'}

    textFieldChange(params: RenderInputParams) {
        const { type, onEmailChange, label, onChangeTextField, autoFocus, value, onClick, IsLegalEntity, onAddNewEmail } = this.props;

        if(type === 'email' && onEmailChange !== undefined) {
            // const inputParams = Object.assign({}, params);
            return (
                <div className="email-autocomplete">
                    <InputEmailValidate 
                        {...params} 
                        label={label ? label : "Email"}
                        value={value}
                        fullWidth
                        onEmailChange={(e) => onEmailChange(e)} 
                        onClickDelete={onClick} 
                        IsLegalEntity={IsLegalEntity} 
                        onAddNewEmail={onAddNewEmail}/>
                </div>
            )
        } else {
            return (
                <TextField 
                    {...params} 
                    label={label ? label : <Translate id='SelectedСompany' />}
                    onChange={onChangeTextField}
                    fullWidth
                    autoFocus={autoFocus}
                    autoComplete="off"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}/>
            )
        }
    }

    async handleClickResetProjects() {
        const { setActiveProject } = this.props;
        setActiveProject(-1);
    }

    closeIconProjectSelector(projectSelector: boolean | undefined) {
        if(projectSelector !== undefined && projectSelector === true) {
            return <CloseIcon 
                        fontSize="small" 
                        onClick={this.handleClickResetProjects.bind(this)} 
                    />
        } else {
            return;
        }
    }

    getOptionValue(option: ClientInfo) {
        const { type } = this.props;

        if(type === 'email' && type !== undefined && option.emails !== undefined && option.emails !== null && option.emails.length > 0) {
            return option.emails[0].email ? option.emails[0].email : ''
        } else if(type === 'INN' && type !== undefined) {
            return option.inn ? option.inn : ''
        } else {
            return option.name ? option.name : ''
        }
    }

    companyChange(changeValue: ClientInfo) {
        const { onChange, currentCompanyAction, discountOpenAction } = this.props;
        currentCompanyAction(changeValue);
        onChange(changeValue);
        discountOpenAction(false);
        
    }

    onInputCompanyChange(value: string) {
        const { customerCompanyLoaded, IsLegalEntity } = this.props;
        if(IsLegalEntity === false && value.length > 2) {
            customerCompanyLoaded({ name: value, isLegal: IsLegalEntity });
        }
    }

    optionsArray() {
        const { IsLegalEntity, customers, companies, type } = this.props;
        const customersSort = sortAlphabeticallyClientInfo(customers);
        const companiesSort = sortAlphabeticallyClientInfo(companies);
        let filteredCompanies = companiesSort;
        if(type === 'INN') {
            filteredCompanies = companies.filter(company => company.inn);
        }

        if(IsLegalEntity !== undefined && !IsLegalEntity) {
            return customersSort
        } else {
            return filteredCompanies
        }

        // if(addCompanyOrClient !== undefined && addCompanyOrClient) {
        //     return [];
        // } else {
        //     if(IsLegalEntity !== undefined && !IsLegalEntity) {
        //         return customersSort
        //     } else {
        //         return filteredCompanies
        //     }
        // }
    }

    render() {
        const { value, type, currentValue, IsLegalEntity, projectSelector } = this.props;
        const inputVal = value === null ? '' : value;

        return (
            <Autocomplete
                className="autocomplete-clear-focus autoComplateOffCompany"
                options={this.optionsArray()}
                getOptionLabel={(option: ClientInfo) => this.getOptionValue(option)}
                renderOption={(option: ClientInfo) => renderOptionSelect(option, type)}
                value={currentValue}
                // inputValue={inputVal}
                defaultValue={inputVal}
                onChange={(event: React.ChangeEvent<{}>, changeValue: ClientInfo) => this.companyChange(changeValue)}
                onInputChange={(event: React.ChangeEvent<{}>, value: any) => this.onInputCompanyChange(value)}
                filterOptions={(options: ClientInfo[], state: FilterOptionsState) => fliterOptionsCompany(options, state, type)}
                freeSolo
                disableOpenOnFocus={true}
                disableClearable={type === 'email' ? (IsLegalEntity !== undefined && IsLegalEntity === false ? false : true) : false}
                renderInput={params => (
                    this.textFieldChange(params)
                )}
                closeIcon={this.closeIconProjectSelector(projectSelector)} />
        )
    }
}

function fliterOptionsCompany(options: ClientInfo[], state: FilterOptionsState, type: string | undefined) {
    if(type === 'email' && type !== undefined) {
        let emailOptionsArray: ClientInfo[] = options.filter(option => option.emails.find(e => e.email.toLowerCase().indexOf(state.inputValue.toLowerCase()) >-1) !== undefined);
        return emailOptionsArray;
    } else if(type === 'INN' && type !== undefined) {
        let INNOptionsArray: ClientInfo[] = options.filter(option => option.inn !== null && option.inn.indexOf(state.inputValue) === 0);
        return INNOptionsArray;
    } else {
        const optionsArray: ClientInfo[] = options.filter(option => option.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 || option.name.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
        return optionsArray;
    }
}

function renderOptionSelect(option: ClientInfo, type: string | undefined) {
    const renderSelectName = <span>{option.name}</span>
    const renderSelectINN = <span>{option.inn}</span>
    const renderSelectEmail = 
        <React.Fragment>
            {option.name !== '' && 
                <><span>{option.name}</span><span className="pl-1 pr-1">-</span></>}
            {option.emails.map(email => {
                return <span className="pr-1" key={email.emailId}>{email.email}</span>
            })} 
        </React.Fragment>   
    if(type === 'email' && type !== undefined) {
        return renderSelectEmail;
    } else if(type === 'INN' && type !== undefined) {
        return renderSelectINN
    } else {
        return renderSelectName;
    }
}

const mapStateToProps = (state: RootState) => ({
    companies: state.customers.companies,
    customers: state.customers.customers,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setActiveProject: (projectID: number) => dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    currentCompanyAction: (currentCompany: ClientInfo) => dispatch(actions.customers.currentCompanyAction(currentCompany)),
    discountOpenAction: (discountOpen: boolean) => dispatch(actions.todo.discountOpenAction(discountOpen)),
    customerCompanyLoaded: (filter: GetCustomersFilter) => 
        dispatch(actions.customers.customerCompanyLoaded(filter)),
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(CompanyAutocomplete);