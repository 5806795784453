import React, { FunctionComponent, Dispatch, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { Translate, getTranslate } from "react-localize-redux";
import { Card, FormControl, InputLabel, Select, MenuItem, Tooltip, Fab } from '@material-ui/core';
import { onlyUnique } from '../../utils/onlyUnique';
import { sortAlphabeticallyString } from '../../utils/sortAlphabetically';
import CloseIcon from '@material-ui/icons/Close';
import { errorCallback, IProperties, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { IPropertySet } from 'sedi-webserverproxy';
import { BillboardFilters, BillboardsShortInfo, GeoObjectStatus } from '../../RemoteCommands/type';
import { PropertyFilter } from '../../store/app/types';
import './bricks.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
        margin: '0.35rem 0.25rem',
        minWidth: 175,
    },
    button: {
        margin: '0.35rem 0.25rem',
        minWidth: '115px',
        height: '39px'
    }
  }),
);

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
    toggleFilter: boolean;
    nextBillboardFilter?: boolean,
}

const AdvancedFilter: FunctionComponent<Props> = (props) => {

    const { 
        toggleFilter, constructionType, filterPropertyBillbords, billboardAB, filterPropertyBillbordsAB,
        nextBillboardFilter, allResetFilters, propertySet, billboardFiltersAction, billboardFilters, 
        propertyFilterAction,  propertyFilter, selectedAreaName, selectedCityName, dontSaleFilter, billboardsShortInfoAction
    } = props;

    const classes = useStyles();
    const eventKey = useRef<string>("");

    filterPropertyBillbords(constructionType)
    

    const allResetFiltersShow = () => {
        if(billboardFilters && billboardFilters.propertyValueIds && billboardFilters.propertyValueIds.length > 0) {
            return (
                <Tooltip title="Сбросить фильтр" placement="right">
                    <Fab size="small" color="default"
                        className="filter-billboard__icon-clear"
                        onClick={() => allResetFilters()}>
                        <CloseIcon color="secondary" />
                    </Fab>
                </Tooltip>
            )
        }
    }

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof billboardFilters;
        const value = parseInt(event.target.value as string);
        const filter = {...propertyFilter};
        filter[name] = value;
        propertyFilterAction(filter);

        SystemEvent.SubscribeEventGetBillboardsShortInfo(
            "AdvancedFilter", 
            (answer) => {
                if(answer.eventKey === eventKey.current) {
                    billboardsShortInfoAction(answer.billboards);
                }
            }, (error) => {
                errorCallback(error);
            }
        );

        const valueIds = Object.values(filter).filter(item => !Number.isNaN(item) && item !== null);
        let newbillboardFilters: BillboardFilters = {
            propertyValueIds: valueIds,
            region: selectedAreaName ? (selectedAreaName === "Все" ? undefined : selectedAreaName) : undefined,
            city: selectedCityName ? selectedCityName : undefined,
            status: dontSaleFilter ? GeoObjectStatus.outOfService : GeoObjectStatus.used
        }
        if(name === "side") {
            filterPropertyBillbordsAB(event.target.value as string);
            newbillboardFilters.side = event.target.value as string;
        }
        billboardFiltersAction(newbillboardFilters);
        eventKey.current = SystemEvent.EventGetBillboardsShortInfo(newbillboardFilters);
    };

    const propertyStyle = (propertyName: string) => {
        if(propertyName === "Format") {
            return { minWidth: 115 }
        }
        if(propertyName === "Light") {
            return { minWidth: 115 }
        }
        return {}
    }

    const propertyLocalizedName = (property: IProperties) => {
        if(property.propertyName === "Way1") {
            return <Translate id='BillboardDetailsDirection'/>
        }
        return property.localizedName
    }

    // const propertyConstructionType = billboards.map(billboard => billboard.constructionType);
    // const propertyFormat = billboards.map(billboard => billboard.format);
    // const propertySideA = billboards.map(billboard => billboard.way1);
    // const propertySideB = billboards.map(billboard => billboard.way2);
    // const propertyType = billboards.map(billboard => billboard.type);

    return (
        <Card 
            className={
                `${nextBillboardFilter ? 'next-billboard-filter' : 'filter-billboard'} 
                ${toggleFilter && 'active'}`
            }
        >
            {propertySet && propertySet.properties
                .filter(property => 
                    property.propertyName === "ConstructionType" 
                    || property.propertyName === "Way1"
                    || property.propertyName === "Format" 
                    || property.propertyName === "Light" 
                    || property.propertyName === "Type" 
                )
                .map(property => {
                    const propertyName = propertyFilter[property.propertyName];
                    return (
                        <FormControl 
                            key={`property-filter-${property.propertyId}`}
                            size="small" 
                            className={`${classes.formControl} cancelDraggable`}
                            style={propertyStyle(property.propertyName)}
                            >
                            <InputLabel>
                                {propertyLocalizedName(property)}
                            </InputLabel>
                            <Select
                                value={propertyName ? propertyName : ""}
                                onChange={handleChange}
                                name={property.propertyName}>
                                <MenuItem value=""><em>All</em></MenuItem>
                                {property.values.map(item => (
                                    <MenuItem 
                                        key={'menu-item-' + item.valueId}
                                        value={item.valueId}>
                                        {item.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> 
                    )
                })
            }
            <FormControl size="small" className={`${classes.formControl} cancelDraggable`}>
                <InputLabel>Сторона</InputLabel>
                <Select
                    value={billboardAB}
                    name="side"
                    onChange={handleChange}>
                    <MenuItem value=""><em>All</em></MenuItem>
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                </Select>
            </FormControl>
            {/* <FormControl variant="outlined" size="small" className={`${classes.formControl} cancelDraggable`}>
                <InputLabel ref={inputLabel}><Translate id='BillboardDetailsConstructionType'/></InputLabel>
                <Select
                    value={constructionType}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => filterPropertyBillbords(event.target.value as string)}
                    labelWidth={labelWidth}>
                    <MenuItem value=""><em>All</em></MenuItem>
                    {onlyUniqueAndSortAlphabetically(propertyConstructionType).map(item => {
                        return <MenuItem value={item} key={'menu-item-' + item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>  
            <FormControl variant="outlined" size="small" className={`${classes.formControl} cancelDraggable`}>
                <InputLabel ref={inputLabelSide}><Translate id='BillboardDetailsDirection'/></InputLabel>
                <Select
                    value={selectedSide}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => filterPropertyBillbordsSide(event.target.value as string)}
                    labelWidth={labelWidthSide}>
                    <MenuItem value=""><em>All</em></MenuItem>
                    {onlyUniqueAndSortAlphabeticallySide(propertySideA, propertySideB).map(item => {
                        return <MenuItem value={item} key={'menu-item-' + item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" className={`${classes.formControl} cancelDraggable`} style={{minWidth: '115px'}}>
                <InputLabel ref={inputLabelFormat}><Translate id='BillboardDetailsFormat'/></InputLabel>
                <Select
                    value={format}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => filterPropertyBillbordsFormat(event.target.value as string)}
                    labelWidth={labelWidthFormat}>
                    <MenuItem value=""><em>All</em></MenuItem>
                    {onlyUniqueAndSortAlphabetically(propertyFormat).map(item => {
                        return <MenuItem value={item} key={'menu-item-' + item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" className={`${classes.formControl} cancelDraggable`}>
                <InputLabel ref={inputLabelLight}><Translate id='BillboardDetailsLight'/></InputLabel>
                <Select
                    value={light}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => filterPropertyBillbordsLight(event.target.value as string)}
                    labelWidth={labelWidthLight}>
                    <MenuItem value=""><em>All</em></MenuItem>
                    <MenuItem value={"true"}>Да</MenuItem>
                    <MenuItem value={"false"}>Нет</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" className={`${classes.formControl} cancelDraggable`}>
                <InputLabel ref={inputLabelType}><Translate id='BillboardDetailsType'/></InputLabel>
                <Select
                    value={billboardType}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => filterPropertyBillbordsType(event.target.value as string)}
                    labelWidth={labelWidthType}>
                    <MenuItem value=""><em>All</em></MenuItem>
                    {onlyUniqueAndSortAlphabetically(propertyType).map(item => {
                        return <MenuItem value={item} key={'menu-item-' + item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" className={`${classes.formControl} cancelDraggable`} style={{minWidth: '115px'}}>
                <InputLabel ref={inputLabelFormat}>Сторона</InputLabel>
                <Select
                    value={billboardAB}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => filterPropertyBillbordsAB(event.target.value as string)}
                    labelWidth={labelWidthFormat}>
                    <MenuItem value=""><em>All</em></MenuItem>
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                </Select>
            </FormControl> */}
            {nextBillboardFilter && allResetFiltersShow()}
            {/* <Button 
                variant="contained" 
                color="secondary" 
                className={classes.button}
                onClick={() => allResetFilters()}>Сбросить</Button>    */}
        </Card>
    )
}

const onlyUniqueAndSortAlphabetically = (properties: string[]) => {
    const onlyUniqueItem = onlyUnique(properties);
    const selectOptions = sortAlphabeticallyString(onlyUniqueItem);
    return selectOptions;
}

const onlyUniqueAndSortAlphabeticallySide = (sideA: string[], sideB: string[]) => {
    const getAllSide = sideA.concat(sideB);
    const onlyUniqueItem = [...new Set(getAllSide)];
    const selectOptions = sortAlphabeticallyString(onlyUniqueItem);
    return selectOptions;
}

const mapStateToProps = (state: RootState) => ({
    translate: getTranslate(state.localize),
    constructionType: state.app.constructionType,
    format: state.app.format,
    selectedSide: state.app.selectedSide,
    light: state.app.light,
    billboardType: state.app.billboardType,
    billboardAB: state.app.billboardAB,
    propertySet: state.billboards.propertySet,
    billboardFilters: state.app.billboardFilters,
    propertyFilter: state.app.propertyFilter,
    selectedAreaName: state.billboards.selectedAreaName,
    selectedCityName: state.billboards.selectedCityName,
    dontSaleFilter: state.app.dontSaleFilter
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    filterPropertyBillbords: (constructionType: string) => dispatch(actions.app.filterPropertyBillbords(constructionType)),
    filterPropertyBillbordsFormat: (format: string) => dispatch(actions.app.filterPropertyBillbordsFormat(format)),
    filterPropertyBillbordsSide: (selectedSide: string) => dispatch(actions.app.filterPropertyBillbordsSide(selectedSide)),
    filterPropertyBillbordsLight: (light: string) => dispatch(actions.app.filterPropertyBillbordsLight(light)),
    filterPropertyBillbordsType: (billboardType: string) => dispatch(actions.app.filterPropertyBillbordsType(billboardType)),
    filterPropertyBillbordsAB: (billboardAB: string) => dispatch(actions.app.filterPropertyBillbordsAB(billboardAB)),
    allResetFilters: () => dispatch(actions.app.allResetFilters()),
    propertySetAction: (propertySet: IPropertySet | null) => 
        dispatch(actions.billboards.propertySetAction(propertySet)),
    billboardFiltersAction: (billboardFilters: BillboardFilters | undefined) => 
        dispatch(actions.app.billboardFiltersAction(billboardFilters)),
    propertyFilterAction: (propertyFilter: PropertyFilter) => 
        dispatch(actions.app.propertyFilterAction(propertyFilter)),
    billboardsShortInfoAction: (billboardsShortInfo: BillboardsShortInfo[]) => 
        dispatch(actions.billboards.billboardsShortInfoAction(billboardsShortInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilter);