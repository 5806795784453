import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import Draggable from 'react-draggable';
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, InputAdornment, TextField, Tooltip, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { RollUpCard } from '../../store/event/types';
import PhotocontrolCardTable from './PhotocontrolCardTable';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { BillboardPhotoControlInfo, Period } from '../../RemoteCommands/type';
import './Photocontrol.scss';
import DatePicker from "react-datepicker";
import { DatesRange } from '../bricks/InputDatesRange';
import moment from 'moment';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

const PhotocontrolCard:React.FC<Props> = (props) => {
    const {
        draggablePositions,
        draggablePositionAction,
        draggable,
        rollUpCards,
        rollUpCardAction,
        openPhotocontrolCardAction,
        openPhotocontrolCard
    } = props;

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [dates, setDates] = useState<DatesRange>({
        DateFrom: new Date(),
        DateTo: new Date()
    });
    const [billboardsPhotocontrol, setBillboardsPhotocontrol] = useState<BillboardPhotoControlInfo[]>([]);
    const [billboardsPhotocontrolFiltered, setBillboardsPhotocontrolFiltered] = useState<BillboardPhotoControlInfo[]>([]);

    const dateFromChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateFrom: date ? date : undefined
        }));
    }

    const dateToChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateTo: date ? date : undefined
        }));
    }

    useEffect(() => {
        SystemEvent.SubscribeEventGetGeoMarkPhotoControlInfo(
            "PhotocontrolCard", 
            (answer) => {
                setLoading(false);
                if(answer && answer.billBoardsPhotoControl) {
                    setBillboardsPhotocontrol(answer.billBoardsPhotoControl);
                }
            }, (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
        getBillboardsPhotocontrol();
    }, []);

    const getBillboardsPhotocontrol = () => {
        setLoading(true);
        const period: Period = {
            Month: Number(moment(dates.DateFrom).format('M')),
            Year: Number(moment(dates.DateFrom).format('YYYY')),
            MonthTo: Number(moment(dates.DateTo).format('M')),
            YearTo: Number(moment(dates.DateTo).format('YYYY'))
        }
        SystemEvent.EventGetGeoMarkPhotoControlInfo(period, -1);
    };

    useEffect(() => {
        getBillboardsPhotocontrol();
    }, [dates]);

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        var position: DefaultPosition = { x, y };
        if(position.x < 0)
            position.x = 0;
        if(position.y < 0)
            position.y = 0;
        draggablePositionAction({
            key: 'PhotocontrolCard',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Фотоконтроль",
            key: "PhotocontrolCard"
        });
    }

    const handleSeachChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setSearch(value);
    }

    useEffect(() => {
        filterBillboardsPhotocontrol();
    }, [search, billboardsPhotocontrol]);

    const filterBillboardsPhotocontrol = () => {
        if(search.length > 0) {
            setBillboardsPhotocontrolFiltered(
                billboardsPhotocontrol.filter(billboardPhotocontrol => {
                var found = false;
                if(billboardPhotocontrol.geoMarkName && billboardPhotocontrol.geoMarkName.toLowerCase().indexOf(search.toLowerCase()) >= 0)
                    found = true;
                if(billboardPhotocontrol.photoControllerName && billboardPhotocontrol.photoControllerName.toLowerCase().indexOf(search.toLowerCase()) >= 0)
                    found = true;
                if(billboardPhotocontrol.region && billboardPhotocontrol.region.toLowerCase().indexOf(search.toLowerCase()) >= 0)
                    found = true;
                if(billboardPhotocontrol.gid && billboardPhotocontrol.gid.toLowerCase().indexOf(search.toLowerCase()) >= 0)
                    found = true;
                return found;
            }));
        } else {
            setBillboardsPhotocontrolFiltered(billboardsPhotocontrol);
        }
    }

    const draggablePosition = draggablePositions.find(item => item.key === "PhotocontrolCard");
    const rollUpCardHas = rollUpCards.some(item => item.key === "PhotocontrolCard");

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card 
                className={`
                    selectedCard PhotocontrolCard 
                    ${openPhotocontrolCard ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="employees__close">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                className="mt-1 ml-1"
                                onClick={() => openPhotocontrolCardAction(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="flex-start" >
                            <Grid item>
                                <span className="billBoardDetailedUsageInfo__title">Фотоконтроль</span>
                            </Grid>
                            <Grid item>
                                <TextField 
                                        label="Поиск" 
                                        type="search" 
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={search}
                                        onChange={(event) => handleSeachChange(event)}
                                        className="cancelDraggable"
                                        InputProps={{
                                            endAdornment: 
                                                <InputAdornment position="end">
                                                    <SearchOutlinedIcon className="employees__search-icon" />
                                                </InputAdornment>,
                                        }}
                                    />
                            </Grid>
                            <Grid item>
                                <div className="chartDate">
                                    <DatePicker
                                        selected={dates.DateFrom}
                                        onChange={(date: Date | null) => dateFromChange(date)}
                                        selectsStart
                                        startDate={dates.DateFrom}
                                        endDate={dates.DateTo}
                                        dateFormat="MMMM yyyy"
                                        showMonthYearPicker
                                        //isClearable
                                        locale="ru"
                                        placeholderText="Дата от"
                                    />
                                    <DatePicker
                                        selected={dates.DateTo}
                                        onChange={(date: Date | null) => dateToChange(date)}
                                        selectsStart
                                        startDate={dates.DateFrom}
                                        endDate={dates.DateTo}
                                        dateFormat="MMMM yyyy"
                                        isClearable
                                        showMonthYearPicker
                                        locale="ru"
                                        placeholderText="Дата до"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="cancelDraggable pt-0">
                    {loading ?
                        <div className="loading">
                            <CircularProgress />
                        </div>
                        :
                        <PhotocontrolCardTable 
                            billboardsPhotocontrol={billboardsPhotocontrolFiltered}
                            />
                    }
                </CardContent>
            </Card>
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
        openPhotocontrolCard: state.event.openPhotocontrolCard
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    openPhotocontrolCardAction: (openPhotocontrolCard: boolean) => 
        dispatch(actions.event.openPhotocontrolCardAction(openPhotocontrolCard))
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotocontrolCard);