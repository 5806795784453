import { BillboardState } from './types';

export function allBillboards(state: BillboardState) {
  return state.billboards;
}
export function allProperties(state: BillboardState) {
  return state.properties;
}


export function getAllStatuses(state: BillboardState) {
  return state.billboadStatuses;
}


export function getSaveStatusError(state: BillboardState) {
  return state.saveStatusError;
}