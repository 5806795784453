import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { EmployeeBrandsInfo } from 'sedi-webserverproxy';

type Props = {
    deleteBrandOpen: boolean;
    deleteBrandClose: () => void;
    brand: EmployeeBrandsInfo;
}

const DeleteBrandModal:React.FC<Props> = (props) => {
    const { 
        deleteBrandOpen,  
        deleteBrandClose,
        brand
    } = props;

    const deleteGeoObject = () => {
        const builder = new EventBuilder(`EventDeleteBrand`);
            builder.AddParameter('BrandId', brand.brandId);
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        deleteBrandClose();
    }


    return (
        <Dialog
            open={deleteBrandOpen}
            onClose={deleteBrandClose}>
            <DialogTitle>Удалить {brand.brandName}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы действительно хотите удалить {brand.brandName}?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={deleteBrandClose} color="primary">
                    Отмена
                </Button>
                <Button 
                    color="secondary" 
                    autoFocus 
                    onClick={deleteGeoObject}>
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default DeleteBrandModal;