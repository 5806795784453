import React, { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { AutocompleteType, GetProjectParmas, IGetAutocompleteList } from '../../RemoteCommands/type';
import CloseIcon from '@material-ui/icons/Close';
import { ProjectType } from 'sedi-webserverproxy';
import { localStorageGetItem } from '../../utils/storage';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    label: string;
    disabled?: boolean;
    type: AutocompleteType;
    selectedCard?: boolean;
}

const GetAutocompleteList:React.FC<Props> = (props) => {
    const {
        label,
        disabled,
        type,
        setActiveProject,
        selectedCard
    } = props;

    const [autocompleteResult, setAutocompleteResult] = useState<IGetAutocompleteList[]>([]);

    const handleChange = (changeValue: IGetAutocompleteList) => {
        if(changeValue) {
            if(selectedCard) {
                setActiveProject(changeValue.id);
            } else {
                const payload: GetProjectParmas = {
                    projectType: ProjectType.CommonProject,
                    projectIds: [changeValue.id],
                    language: localStorageGetItem("lang")
                }
                SystemEvent.EventGetProjects(payload);
            }
        } else {
            if(!selectedCard) {
                const payload: GetProjectParmas = {
                    projectType: ProjectType.CommonProject,
                    projectIds: [],
                    language: localStorageGetItem("lang")
                }
                SystemEvent.EventGetProjects(payload);
            }
        }
    }

    const handleInputChange = (value: string) => {
        if(value.length === 0) {
            setAutocompleteResult([]);
        } else {
            SystemEvent.EventGetAutocompleteList(value, type);
            SystemEvent.SubscribeEventGetAutocompleteList(
                "EventGetAutocompleteList", 
                (answer) => {
                    setAutocompleteResult(answer.autocompleteResult);
                }, 
                (error) => errorCallback(error)
            );
        }
    }

    return (
        <Autocomplete
            options={autocompleteResult}
            getOptionLabel={(option: IGetAutocompleteList) => option.name}
            onChange={(event: React.ChangeEvent<{}>, changeValue: IGetAutocompleteList) => handleChange(changeValue)}
            onInputChange={(event: React.ChangeEvent<{}>, value: string) => handleInputChange(value)}
            filterOptions={(options: any[], state: FilterOptionsState) => fliterOptions(options, state)}
            disabled={disabled}
            style={{ minWidth: 270 }}
            renderInput={params => (
                <TextField 
                    {...params}
                    label={label ? label : "Выберите проект"}
                    variant="outlined" 
                    size="small"
                    fullWidth 
                />
            )}
            noOptionsText={<span>Нет проектов</span>}
            closeIcon={
                <CloseIcon 
                    fontSize="small" 
                    onClick={() => setAutocompleteResult([])} 
                />
            }
            className="cancelDraggable"
        />
    );
}

const fliterOptions = (options: IGetAutocompleteList[], state: FilterOptionsState) => {
    const optionsArray: IGetAutocompleteList[] = 
        options.filter(option => option.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 
            || option.name.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
    return optionsArray
}

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID))
});

export default connect(mapStateToProps, mapDispatchToProps)(GetAutocompleteList);