import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { DefaultPosition } from '../../../store/app/types';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import AdditionalCostTableContainer from '../AdditionalCostTable';
import { errorCallback, SystemEvent } from '../../../RemoteCommands/SystemEvent';
import { IAdditionalCostParameters } from '../../../RemoteCommands/type';
import { EventBuilder } from '../../../utils/eventHelpers';
import { ServerProxy } from '../../../RemoteCommands/ServerProxy';
import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    defaultPosition: DefaultPosition;
    draggable: boolean;
    onStop: (event: any, ui: any) => void;
    rollUpCardHas: boolean;
    openAdditionalCost: boolean;
    rolleUpClick: () => void;
    cardCloseClick: () => void;
    projectName: string;
    projectID: number;
    disabledScoreSave: boolean;
}

const AdditionalCost:React.FC<Props> = (props) => {
    const { 
        defaultPosition,
        draggable,
        onStop,
        rollUpCardHas,
        openAdditionalCost,
        rolleUpClick,
        cardCloseClick,
        projectName,
        projectID,
        disabledScoreSave,
        additionalCostParametersAction,
        additionalCostParameters
    } = props;

    const [loading, setLoading] = useState(false);
    const [additionalCostNotSaved, setAdditionalCostNotSaved] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);

    useEffect(() => {
        setLoadingPage(true);
        SystemEvent.EventGetProjectAdditionalCostParameters(projectID);
        SystemEvent.SubscribeEventGetProjectAdditionalCostParameters(
            "AdditionalCost", 
            (answer) => {
                additionalCostParametersAction(answer.additionalCostParameters);
                setAdditionalCostNotSaved(answer.additionalCostNotSaved);
                const builder = new EventBuilder(`EventGetCommercialOfferDiscounts`);
                    builder.AddParameter('ProjectId', projectID);
                    ServerProxy.SendSystemEvent(builder.GetSystemEvent());
                setTimeout(() => {
                    setLoadingPage(false);
                }, 500)
            }, 
            (error) => {
                setLoadingPage(false);
                errorCallback(error)
            }
        );
    }, [projectID]);

    const saveScoreClick = () => {
        setLoading(true);
        SystemEvent.EventSaveProjectAdditionalCosts(projectID, additionalCostParameters);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={defaultPosition}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card 
                className={`
                    selectedCard additionalCost ${openAdditionalCost ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `} 
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                onClick={cardCloseClick}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={1} alignItems="center" justify="space-between">
                            <Grid item>
                                <div className="box-title">
                                    Дополнительная стоимость {projectName}
                                </div>
                            </Grid>
                            {additionalCostNotSaved &&
                                <Grid item>
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={saveScoreClick}
                                        startIcon={
                                            loading &&
                                                <CircularProgress 
                                                    color="secondary" 
                                                    size={16} />
                                        }
                                        disabled={loading || disabledScoreSave}>
                                        Сохранить
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    }
                />
                <CardContent className="pt-1 overflowScroll">
                    {loadingPage 
                        ? 
                            <div className="loading">
                                <CircularProgress />
                            </div>
                        :
                            <Grid 
                                container 
                                spacing={1} 
                                alignItems="center" 
                                justify="space-between"
                                className="mb-1">
                                <Grid item xs={12}>
                                    <AdditionalCostTableContainer 
                                        additionalCostParameters={additionalCostParameters} 
                                        additionalCostNotSaved={additionalCostNotSaved} />
                                </Grid>
                            </Grid>
                    }
                </CardContent>
            </Card>
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => ({
    additionalCostParameters: state.event.additionalCostParameters
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    additionalCostParametersAction: (additionalCostParameters: IAdditionalCostParameters[]) => 
        dispatch(actions.event.additionalCostParametersAction(additionalCostParameters))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCost);