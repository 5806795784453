import React, { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { Card, CardContent, CardHeader, Grid, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';
import { DefaultPosition } from '../../store/app/types';
import BrandsCardTable from './BrandsCardTable';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import SearchOblectsBrand from './SearchOblectsBrand';
import { IMultipleMarker } from '../map/types';
import './bricks.scss';
import BrandAutocomplete from '../Tariff/BrandAutocomplete';
import RemoveIcon from '@material-ui/icons/Remove';
import { RollUpCard } from '../../store/event/types';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
        selectedBrandMarker: (brandMarkers: IMultipleMarker[]) => void;
    }

const BrandsCard: React.FC<Props> = (props) => {
    const { 
        openBrandsCard, 
        draggable,
        positionBrandsCard,
        positionBrandsCardAction,
        openBrandsCardAction,
        selectedBrandMarker,
        rollUpCardAction,
        rollUpCards
    } = props;

    const brandsCardClick = () => {
        openBrandsCardAction(false);
    }

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        positionBrandsCardAction(position);
    };

    useEffect(() => {
        if(openBrandsCard) {
            const builder = new EventBuilder(`EventGetBrands`);
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }
    }, [openBrandsCard]);

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Бренды",
            key: "BrandsCard"
        });
    }

    const rollUpCardHas = rollUpCards.some(item => item.key === "BrandsCard");

    return (
        <>
            <Draggable
                handle=".brandsCard"
                defaultPosition={positionBrandsCard}
                disabled={!draggable}
                onStop={onStop}>
                <Card 
                    className={`
                        brandsCard ${openBrandsCard ? 'openSelectedCard' : 'closeSelectedCard'}
                        ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                    `}
                    style={{cursor: draggable ? 'move' : 'auto'}}>
                    <CardHeader
                        action={
                            <div className="button-group">
                                <Tooltip
                                    title="Свернуть"
                                    placement="top">
                                    <IconButton
                                        onClick={rolleUpClick}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                                <IconButton
                                    onClick={brandsCardClick}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        }
                        title={
                            <Grid 
                                container 
                                justify="space-between" 
                                spacing={2}
                            >
                                <Grid item>
                                    <div className="box-title">Бренды</div>
                                </Grid>
                                <Grid item xs={5}>
                                    <BrandAutocomplete 
                                        disabled={false} 
                                        label="Найти или добавить новый бренд" />
                                </Grid>
                            </Grid>
                        }
                    />
                    <CardContent className="billboardList__table">
                        <BrandsCardTable 
                            selectedBrandMarker={(brandMarkers: IMultipleMarker[]) => 
                                selectedBrandMarker(brandMarkers)
                            }
                        />
                    </CardContent>
                </Card>
            </Draggable>
            <SearchOblectsBrand 
                selectedBrandMarker={selectedBrandMarker} />
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    draggable: state.app.draggable,
    positionBrandsCard: state.app.positionBrandsCard,
    openBrandsCard: state.billboards.openBrandsCard,
    rollUpCards: state.event.rollUpCards
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    maxDistanceSearchAction: (maxDistanceSearch: boolean) => 
        dispatch(actions.event.maxDistanceSearchAction(maxDistanceSearch)),
    positionBrandsCardAction: (positionBrandsCard: DefaultPosition) => 
        dispatch(actions.app.positionBrandsCardAction(positionBrandsCard)),
    openBrandsCardAction: (openBrandsCard: boolean) => 
        dispatch(actions.billboards.openBrandsCardAction(openBrandsCard)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard))
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandsCard);