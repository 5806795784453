import React from 'react';
import { withLocalize, LocalizeContextProps } from "react-localize-redux";
import { Dropdown} from 'react-bootstrap';
import { localStorageSetItem } from '../utils/storage';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import "./Menu.scss"


// todo сохранить настроки в redux, оттуда уже в starage с остальными настройками (карты и др.)

const LanguageSelector = (props: LocalizeContextProps) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRuClose = () => {
        props.setActiveLanguage("ru"); localStorageSetItem("lang", "ru");
        setAnchorEl(null);
    };

    const handleEnClose = () => {
        props.setActiveLanguage("en");localStorageSetItem("lang", "en");
        setAnchorEl(null);
    };

    return (
        <>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className="lang-button">
                <GTranslateIcon className="pr-1" /> {props.activeLanguage.name}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleRuClose}><span className="lang-icon"><span className="flag-icon flag-icon-ru flag-icon-squared"/>RU</span></MenuItem>
                <MenuItem onClick={handleEnClose}><span className="lang-icon"><span className="flag-icon flag-icon-us flag-icon-squared"/>EN</span></MenuItem>
            </Menu>
            {/* <Dropdown>
                <Dropdown.Toggle id="lang_nav_menu" variant="outline-light"><i className="fas fa-globe"></i>{props.activeLanguage.name}</Dropdown.Toggle>
                <Dropdown.Menu className="btn btn-outline-light">
                <Dropdown.Item onClick={() => {
                    props.setActiveLanguage("ru");
                    localStorageSetItem("lang", "ru");
                    }}><span className="flag-icon flag-icon-ru flag-icon-squared"/>Ру</Dropdown.Item>
                <Dropdown.Item onClick={() => {
                    props.setActiveLanguage("en");
                    localStorageSetItem("lang", "en");
                    }}><span className="flag-icon flag-icon-us flag-icon-squared" />En</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> */}
        </>
    )
};

export default withLocalize(LanguageSelector);
