import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { Card, CardContent, CardHeader, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import CloseIcon from '@material-ui/icons/Close';
import { ISales, SalesPaymentAccountStatisticResult, SalesPaymentRegionStatisticResult, StatisticFilter, StatisticRegionFilter } from '../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { DatesRange } from '../bricks/InputDatesRange';
import moment from 'moment';
import { RollUpCard, StatisticsType } from '../../store/event/types';
import RemoveIcon from '@material-ui/icons/Remove';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
        dates: DatesRange,
    }

const CurrentSalesStatistic:React.FC<Props> = (props) => {
    const {
        draggable,
        draggablePositionAction,
        draggablePositions,
        curretnSale,
        curretnSaleAction,
        dates,
        statisticsType,
        rollUpCardAction,
        rollUpCards,
        openCurrentSalesAction
    } = props;

    const [salesStatisticResult, setSalesStatisticResult] = useState<SalesPaymentAccountStatisticResult | null>(null);
    const [salesRegionStatisticResult, setSalesRegionStatisticResult] = useState<SalesPaymentRegionStatisticResult | null>(null);

    useEffect(() => {
        if(curretnSale) {
            SystemEvent.SubscribeEventGetSalesPaymentAccountStatistic(
                "EventGetSalesPaymentAccountStatistic", 
                (answer) => {
                    setSalesStatisticResult(answer.salesPaymentAccountStatisticResult);
                }, 
                (error) => errorCallback(error)
            );
            SystemEvent.SubscribeEventGetSalesPaymentRegionStatistic(
                "EventGetSalesPaymentRegionStatistic", 
                (answer) => {
                    setSalesRegionStatisticResult(answer.salesPaymentRegionStatisticResult)
                }, 
                (error) => errorCallback(error)
            );
            if(statisticsType === StatisticsType.region) {
                const statisticFilter: StatisticRegionFilter = {
                    Year: parseInt(moment(dates.DateFrom).format('YYYY')),
                    Month: parseInt(moment(dates.DateFrom).format('M')),
                    YearTo: parseInt(moment(dates.DateTo).format('YYYY')),
                    MonthTo: parseInt(moment(dates.DateTo).format('M')),
                    RegionName: curretnSale.name
                };
                SystemEvent.EventGetSalesPaymentRegionStatistic(statisticFilter);
            }
            else {
                const statisticFilter: StatisticFilter = {
                    Year: parseInt(moment(dates.DateFrom).format('YYYY')),
                    Month: parseInt(moment(dates.DateFrom).format('M')),
                    YearTo: parseInt(moment(dates.DateTo).format('YYYY')),
                    MonthTo: parseInt(moment(dates.DateTo).format('M')),
                    BuyerAccountId: statisticsType === StatisticsType.client ? curretnSale.accountId : undefined,
                    SellerAccountId: statisticsType === StatisticsType.employee ? curretnSale.accountId : undefined
                };
                SystemEvent.EventGetSalesPaymentAccountStatistic(statisticFilter);
            }
        }
    }, [curretnSale, dates]);

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'CurrentSalesStatistic',
            position
        });
    };

    const titleView = () => {
        const dateFrom = moment(dates.DateFrom).format('MMMM yyyy'),
            dateTo = moment(dates.DateTo).format('MMMM yyyy');
        let type: string = "";
        if(statisticsType === StatisticsType.client) {
            type = "клиент";
        } else if(statisticsType === StatisticsType.employee) {
            type = "сотрудник";
        } else {
            type = ""
        }
        return (
            <div className="box-title">
                Продажи {dateFrom} - {dateTo}, {type} {curretnSale && curretnSale.name}
            </div>
        )
    }

    const draggablePosition = draggablePositions.find(item => item.key === "CurrentSalesStatistic");
    const rollUpCardHas = rollUpCards.some(item => item.key === "CurrentSalesStatistic");
    const rolleUpClick = () => {
        rollUpCardAction({
            title: `Продажи статистика`,
            key: "CurrentSalesStatistic"
        });
    }

    const onClose = () => {
        openCurrentSalesAction(false);
        curretnSaleAction(null);
    }

    const currentStatisticView = () => {
        if(statisticsType === StatisticsType.region) {
            return (
                <CardContent className="pt-0">
                    <TableContainer component={Paper} className="calculateDistance">
                        <Table className="calculateDistance__table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Сотрудник</TableCell>
                                    <TableCell>Клиент</TableCell>
                                    <TableCell align="center">Количество</TableCell>
                                    <TableCell align="center">Общая сумма</TableCell>
                                    <TableCell align="center">Средняя сумма</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {salesRegionStatisticResult && 
                                    salesRegionStatisticResult.statistic.map((item) => (
                                        <TableRow key={item.buyerAccountId + Math.random()}>
                                            <TableCell>{item.sellerName}</TableCell>
                                            <TableCell>{item.buyerName}</TableCell>
                                            <TableCell align="center">{item.itemsCount}</TableCell>
                                            <TableCell align="center">{item.totalCost}</TableCell>
                                            <TableCell align="center">{item.averageItemCost}</TableCell>
                                        </TableRow>
                                    ))
                                }
                                {salesRegionStatisticResult && 
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align="center">
                                            <strong>{salesRegionStatisticResult.totalItem.itemsCount}</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{salesRegionStatisticResult.totalItem.totalCost}</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{salesRegionStatisticResult.totalItem.averageItemCost}</strong>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            )
        } else {
            return (
                <CardContent className="pt-0">
                    <TableContainer component={Paper} className="calculateDistance">
                        <Table className="calculateDistance__table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{salesStatisticResult && salesStatisticResult.header}</TableCell>
                                    <TableCell align="center">Регион</TableCell>
                                    <TableCell align="center">Количество</TableCell>
                                    <TableCell align="center">Общая сумма</TableCell>
                                    <TableCell align="center">Средняя сумма</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {salesStatisticResult && 
                                    salesStatisticResult.statistic.map((item) => (
                                        <TableRow key={item.accountName + Math.random()}>
                                            <TableCell>{item.accountName}</TableCell>
                                            <TableCell align="center">{item.region}</TableCell>
                                            <TableCell align="center">{item.itemsCount}</TableCell>
                                            <TableCell align="center">{item.totalCost}</TableCell>
                                            <TableCell align="center">{item.averageItemCost}</TableCell>
                                        </TableRow>
                                    ))
                                }
                                {salesStatisticResult && 
                                    <TableRow>
                                        <TableCell>
                                            <strong>{salesStatisticResult.totalItem.accountName}</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{salesStatisticResult.totalItem.region}</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{salesStatisticResult.totalItem.itemsCount}</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{salesStatisticResult.totalItem.totalCost}</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{salesStatisticResult.totalItem.averageItemCost}</strong>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            )
        }
    }

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}>
            <Card 
                className={`
                    selectedCard salesStatistics 
                    ${curretnSale ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                className="mt-1 ml-1"
                                onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                {titleView()}
                            </Grid>
                        </Grid>
                    }
                />
                {currentStatisticView()}
            </Card>    
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        curretnSale: state.event.curretnSale,
        statisticsType: state.event.statisticsType,
        rollUpCards: state.event.rollUpCards,
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    salesStatisticsToggleAction: (salesStatisticsToggle: boolean) => 
        dispatch(actions.event.salesStatisticsToggleAction(salesStatisticsToggle)),  
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    curretnSaleAction: (curretnSale: ISales | null) => 
        dispatch(actions.event.curretnSaleAction(curretnSale)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),  
    openCurrentSalesAction: (openCurrentSales: boolean) => 
        dispatch(actions.event.openCurrentSalesAction(openCurrentSales))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentSalesStatistic);