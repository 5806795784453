import React from 'react';
import { Sip } from '../../../../../sip/sip';
import { connect } from 'react-redux';
import { RootState, selectors } from '../../../../../store';


type Props = ReturnType<typeof mapStateToProps>;


class CallerAccountInfo extends React.Component<Props>{

    render() {
        const { phoneNumber, callerInfo } = this.props;

        return (
            <div className="small">
                <div className="d-block">
                    {/* <span className="flag-icon flag-icon-ru"></span> */}
                    {phoneNumber}
                </div>
                <div>
                    <span>{callerInfo && callerInfo.name}</span>
                    {/* <strong>{callerInfo && callerInfo.companyName}</strong> */}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state: RootState) => ({
    phoneNumber: selectors.sip.GetCallerPhoneNumber(state.sip),
    callerInfo: selectors.sip.GetCallerAccountInfo(state.sip)
});


export default connect(
    mapStateToProps
)(CallerAccountInfo);