import React from 'react';
import { DatesRange } from "../../../components/bricks/InputDatesRange";
import BillboardStatusColor from "../../../components/billboards/BillboardStatusColor/BillboardStatusColor";

type Props = {
    handleChangeDates: (dates: DatesRange) => void
    dates: DatesRange;
}

const BillboardStatus = (props: Props) => {
    // const {handleChangeDates, dates} = props;
    return (
        <div>
            {/* <div className="mb-1">
                <InputDatesRange 
                    showShiftControl={true}
                    allowUndefinedValues={false}
                    onChange={(dates) => { handleChangeDates(dates)}} 
                    dates={dates}/>
            </div> */}
            
            <BillboardStatusColor />

        </div>
    )
};

function datesNextMonth(dates: DatesRange) {
    const {DateFrom, DateTo} = dates;

    if(DateFrom !== undefined && DateTo !== undefined) {
        let dateFrom = new Date(DateFrom);
        let dateTo = new Date(DateTo);
        const nextMonthFrom = new Date(dateFrom.setMonth(dateFrom.getMonth() + 1, 1));
        const nextMonthTo = new Date(dateTo.setMonth(dateTo.getMonth() + 2, 1));

        return (
            dates = {
                DateFrom: nextMonthFrom,
                DateTo: nextMonthTo
            }
        );
    }
}

export default BillboardStatus;