import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { AdditionalGeoMarkTariffs } from '../../RemoteCommands/type';

type Props = {
    open: boolean;
    onClose: () => void;
    geoMarkTariff: AdditionalGeoMarkTariffs
}

function DeleteAdditionalGeoMarkTariff(props: Props) {
    const { 
        open,  
        onClose,
        geoMarkTariff
    } = props;

    const handleClick = () => {
        SystemEvent.EventDeleteAdditionalGeoMarkTariff(geoMarkTariff.tariffParameterId);
        onClose();
    }


    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md">
                <DialogTitle>Удалить</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы действительно хотите удалить {`${geoMarkTariff.cost}/${geoMarkTariff.parameters.map(item => item.parameterValue).join('/')}`}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={onClose} 
                        color="primary">
                        Отмена
                    </Button>
                    <Button 
                        color="secondary" 
                        autoFocus 
                        onClick={handleClick}>
                        Удалить
                    </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAdditionalGeoMarkTariff;