import { BillboardState, Billboard, IGeoMarkTariffs, IBrandsInfo } from './types';
import { Action, ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { GeoMark, PropertyValueSet, Translation, getWebServerProxy, 
    ApiResponse, GetPropertiesFilter, NewPropertyValueSetId, initWebServerProxy,
    GeoMarkTimeStatus,
    EmployeeBrandsInfo,
    RolesInfo,
    AdditionalBillboardInfo,
    GetGeoMarkTariffs,
    ITariffInfo,
} from "../../WebServerProxy";
import { Dispatch } from 'react';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { IGetAddress } from '../../api/AddressCacheAPI';
import { localStorageGetItem } from '../../utils/storage';
import { IPropertySet, SystemEvent } from '../../RemoteCommands/SystemEvent';
import moment from 'moment';
import { DatesRange } from '../../components/bricks/InputDatesRange';
import { BillboardsShortInfo, EventGeoMarks, FullMonthStatistic } from '../../RemoteCommands/type';
import { IBillboardProperties } from '../../utils/PropertiesHelper';
import store from '..';
import { removeItemAtIndex } from '../../utils/replaceItem';

export enum BillboardActionTypes {
    FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS',
    FETCH_TRANSLATIONS_ERROR = 'FETCH_TRANSLATIONS_ERROR',
    FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS',
    INITIALIZE = 'INITIALIZE_ACTION',
    INITIALIZE_ERROR = 'INITIALIZE_ERROR',
    INITIALIZE_SUCCESS = 'INITIALIZE_SUCCESS',
    SAVE_GEOMARK_SUCCESS = 'GEOMARK_UPDATE_SUCCESS',
    SAVE_GEOMARK_START = 'SAVE_GEOMARK_START',
    FETCH_BILLBOARD_STATUSES = 'FETCH_BILLBOARD_STATUSES',
    FETCH_BILLBOARD_STATUSES_ERROR = 'FETCH_BILLBOARD_STATUSES_ERROR',
    FETCH_BILLBOARD_STATUSES_SUCCESS = 'FETCH_BILLBOARD_STATUSES_SUCCESS',
    CHANGE_BILLBOARD_STATUSES_SUCCESS = 'CHANGE_BILLBOARD_STATUSES_SUCCESS',
    SAVE_BILLBOARD_STATUS = 'SAVE_BILLBOARD_STATUS',
    SET_GEO_MARK_TARIFFS = 'SET_GEO_MARK_TARIFFS',
    SET_GEO_MARK_TARIFFS_SUCCESS = 'SET_GEO_MARK_TARIFFS_SUCCESS',
    SAVE_BILLBOARD_STATUS_ERROR = 'SAVE_BILLBOARD_STATUS_ERROR',
     SAVE_BILLBOARD_STATUS_SUCCESS = 'SAVE_BILLBOARD_STATUS_SUCCESS',
    CLEAR_BILLBOARD_STATUS_ERROR = 'CLEAR_BILLBOARD_STATUS_ERROR',
    CURRENT_BILLBOARD = 'CURRENT_BILLBOARD',
    CHANGE_AREA_NAME = 'CHANGE_AREA_NAME',
    CHANGE_CITY_NAME = 'CHANGE_CITY_NAME',
    UPLOAD_PHOTO = 'UPLOAD_PHOTO',
    DELETE_PHOTO = 'DELETE_PHOTO',
    UPLOAD_PHOTO_SIDES = 'UPLOAD_PHOTO_SIDES',
    GET_BILLBOARD_TARIFFS = 'GET_BILLBOARD_TARIFFS',
    DELETE_GEOMARK_TIME_STATUS = 'DELETE_GEOMARK_TIME_STATUS',
    FETCH_BILLBOARD_PROJECT_STATUSES = 'FETCH_BILLBOARD_PROJECT_STATUSES',
    FILTER_BILLBOARDS = 'FILTER_BILLBOARDS',
    SUCCESS_SHACKBAR = 'SUCCESS_SHACKBAR',
    OPEN_PRICE = 'OPEN_PRICE',
    SELECTED_SIDE = 'SELECTED_SIDE',
    GET_ADDRESS = 'GET_ADDRESS',
    GEOMARK_TARIFFS = 'GEOMARK_TARIFFS',
    OPEN_TARIFF_CARD = 'OPEN_TARIFF_CARD',
    GET_BILLBOARD_TARIFFS_OWNER = 'GET_BILLBOARD_TARIFFS_OWNER',
    OPEN_BRANDS_CARD = 'OPEN_BRANDS_CARD',
    GET_BRANDS_INFO =  'GET_BRANDS_INFO',
    CURRENT_BRAND_INFO = 'CURRENT_BRAND_INFO',
    NEXT_BILLBOARD_STATUSES = 'NEXT_BILLBOARD_STATUSES',
    PROJECT_BILLBOARD_IDS = 'PROJECT_BILLBOARD_IDS',
    GET_BILLBOARS_AND_PROPERTIES = 'GET_BILLBOARS_AND_PROPERTIES',
    CURRENT_BILLBOARD_ID = 'CURRENT_BILLBOARD_ID',
    OPEN_GEO_MARK_TARIFF = 'OPEN_GEO_MARK_TARIFF',
    BILLBOARDS_SHORT_INFO = 'BILLBOARDS_SHORT_INFO',
    BILLBOARD_SHORT = 'BILLBOARD_SHORT',
    BILLBOARD_PROPERTIES = 'BILLBOARD_PROPERTIES',
    PROPERTY_SET = 'PROPERTY_SET',
    EVENT_GEO_MARKS = 'EVENT_GEO_MARKS',
    FULL_MONTH_STATISTIC = 'FULL_MONTH_STATISTIC',
    FORBIDDEN_BILLBOARDS = 'FORBIDDEN_BILLBOARDS',
    DELETE_FROM_TO_ARRAYS = 'DELETE_FROM_TO_ARRAYS',
    OPEN_ADD_BILLBOARD = 'OPEN_ADD_BILLBOARD'
}


export interface IFetchingTranslationsAction extends Action<BillboardActionTypes.FETCH_TRANSLATIONS> {
}
export interface IFetchTranslationsErrorAction extends Action<BillboardActionTypes.FETCH_TRANSLATIONS_ERROR> {
    error: string,
}
export interface IFetchTranslationsSuccessAction extends Action<BillboardActionTypes.FETCH_TRANSLATIONS_SUCCESS> {
    translations: Translation[],
}
export interface IInitializeAction extends Action<BillboardActionTypes.INITIALIZE> {
}
export interface IInitializeErrorAction extends Action<BillboardActionTypes.INITIALIZE_ERROR> {
    error: string,
}
export interface IInitializeSuccessAction extends Action<BillboardActionTypes.INITIALIZE_SUCCESS> {
    propertySets: PropertyValueSet[],
    geoMarks: GeoMark[],
    brandsInfo: EmployeeBrandsInfo[],
    roles: RolesInfo[];
}

export interface IGetBillboardsAndPropertiesAction extends Action<BillboardActionTypes.GET_BILLBOARS_AND_PROPERTIES> {
    propertySets: PropertyValueSet[],
    geoMarks: GeoMark[],
}
export interface IGeoMarkUpdateSuccessAction extends Action<BillboardActionTypes.SAVE_GEOMARK_SUCCESS> {
}
export interface IGeoMarkUpdateStartAction extends Action<BillboardActionTypes.SAVE_GEOMARK_START> {
}

export interface IFetchBillboardStastusesAction extends Action<BillboardActionTypes.FETCH_BILLBOARD_STATUSES> {}
export interface IFetchBillboardStastusesErrorAction extends Action<BillboardActionTypes.FETCH_BILLBOARD_STATUSES_ERROR> {
    error: string,
}
export interface IFetchBillboardStastusesSuccessAction extends Action<BillboardActionTypes.FETCH_BILLBOARD_STATUSES_SUCCESS> {
    statuses: GeoMarkTimeStatus[],
    replaceAll: boolean,
    billboardIDs: number[],
}

export interface IChangeBillboardStastusesSuccessAction extends Action<BillboardActionTypes.CHANGE_BILLBOARD_STATUSES_SUCCESS> {
    statuses: GeoMarkTimeStatus[],
    projectIds: number[],
}

export interface IFathcBillboardStastusesAction extends Action<BillboardActionTypes.FETCH_BILLBOARD_PROJECT_STATUSES> {
    statuses: GeoMarkTimeStatus[]
}

export interface IDeleteGeomarkTimeStatusAction extends Action<BillboardActionTypes.DELETE_GEOMARK_TIME_STATUS> {
    geoMarkTimeStatusIds: number[]
}


export interface ISaveBillboardStastusAction extends Action<BillboardActionTypes.SAVE_BILLBOARD_STATUS> {}

export interface ISetGeoMarkTariffsAction extends Action<BillboardActionTypes.SET_GEO_MARK_TARIFFS> {}
export interface ISaveBillboardStastusErrorAction extends Action<BillboardActionTypes.SAVE_BILLBOARD_STATUS_ERROR> {
    error: string,
}
export interface IClearBillboardStastusErrorAction extends Action<BillboardActionTypes.CLEAR_BILLBOARD_STATUS_ERROR> {}
export interface ISaveBillboardStastusSuccessAction extends Action<BillboardActionTypes.SAVE_BILLBOARD_STATUS_SUCCESS> {
    successSnackbar: boolean
}

export interface ISetGeoMarkTariffsSuccessAction extends Action<BillboardActionTypes.SET_GEO_MARK_TARIFFS_SUCCESS> {
    tariffSuccess: boolean
}
export interface ICurrentBillboardAction extends Action<BillboardActionTypes.CURRENT_BILLBOARD> {
    billboard: Billboard
}

export interface ICurrentBillboardIdAction extends Action<BillboardActionTypes.CURRENT_BILLBOARD_ID> {
    billboardId: number
}

export interface IFilterBillboardsAction extends Action<BillboardActionTypes.FILTER_BILLBOARDS> {
    filterBillboards: Billboard[]
}

export interface IChangeAreaNameAction extends Action<BillboardActionTypes.CHANGE_AREA_NAME> {
    selectedAreaName: string
}

export interface IChangeCityNameAction extends Action<BillboardActionTypes.CHANGE_CITY_NAME> {
    selectedCityName: string
}

export interface IUploadPhotoAction extends Action<BillboardActionTypes.UPLOAD_PHOTO> {
    photoVersion: number
}

export interface IDeletePhotoAction extends Action<BillboardActionTypes.DELETE_PHOTO> {
    photoVersion: number
}

export interface IUploadPhotoSidesAction extends Action<BillboardActionTypes.UPLOAD_PHOTO_SIDES> {
    uploadedPhotos: AdditionalBillboardInfo
}

export interface IGetBillboardTariffsAction extends Action<BillboardActionTypes.GET_BILLBOARD_TARIFFS> {
    billboardTariffs: GetGeoMarkTariffs[],
}

export interface IGetBillboardTariffsOwnerAction extends Action<BillboardActionTypes.GET_BILLBOARD_TARIFFS_OWNER> {
    billboardTariffsOwner: GetGeoMarkTariffs[],
}

export interface ISuccessSnackbarAction extends Action<BillboardActionTypes.SUCCESS_SHACKBAR> {
    successSnackbar: boolean,
}

export interface IOpenPriceAction extends Action<BillboardActionTypes.OPEN_PRICE> {
    openPrice: boolean
}

export interface ISelectedSide extends Action<BillboardActionTypes.SELECTED_SIDE> {
    selectedSide: string
}

export interface IGetAddressAction extends Action<BillboardActionTypes.GET_ADDRESS> {
    getAddress: IGetAddress
}

export interface IGeoMarkTariffsAction extends Action<BillboardActionTypes.GEOMARK_TARIFFS> {
    geoMarkTariffs: IGeoMarkTariffs
}

export interface ITariffCard extends Action<BillboardActionTypes.OPEN_TARIFF_CARD> {
    openTariffCard: boolean
}

export interface IOpenBrandsCard extends Action<BillboardActionTypes.OPEN_BRANDS_CARD> {
    openBrandsCard: boolean
}

export interface IBrandsInfoAction extends Action<BillboardActionTypes.GET_BRANDS_INFO> {
    brandsInfo: IBrandsInfo[]
}

export interface ICurrentBrandInfo extends Action<BillboardActionTypes.CURRENT_BRAND_INFO> {
    currentBrandInfo: IBrandsInfo | null
}

export interface INextBillboardStatuses extends Action<BillboardActionTypes.NEXT_BILLBOARD_STATUSES> {
    nextBillboardStatuses: GeoMarkTimeStatus[]
}
export interface IProjectBillboardIds extends Action<BillboardActionTypes.PROJECT_BILLBOARD_IDS> {
    projectBillboardIds: number[]
}

export interface IOpenGeoMarkTariff extends Action<BillboardActionTypes.OPEN_GEO_MARK_TARIFF> {
    openGeoMarkTariff: boolean
}

export interface IBillboardsShortInfo extends Action<BillboardActionTypes.BILLBOARDS_SHORT_INFO> {
    billboardsShortInfo: BillboardsShortInfo[]
}
export interface IForbiddenBillboards extends Action<BillboardActionTypes.FORBIDDEN_BILLBOARDS> {
    forbiddenBillboards: BillboardsShortInfo[]
}

export interface IBillboardShort extends Action<BillboardActionTypes.BILLBOARD_SHORT> {
    billboardShort: BillboardsShortInfo | undefined
}

export interface IBillboardPropertiesAction extends Action<BillboardActionTypes.BILLBOARD_PROPERTIES> {
    billboardProperties: IBillboardProperties | undefined
}
export interface IPropertySetAction extends Action<BillboardActionTypes.PROPERTY_SET> {
    propertySet: IPropertySet | null
}
export interface IEventGeoMarks extends Action<BillboardActionTypes.EVENT_GEO_MARKS> { eventGeoMarks: EventGeoMarks[] }
export interface IFullMonthStatistic extends Action<BillboardActionTypes.FULL_MONTH_STATISTIC> { fullMonthStatistic: FullMonthStatistic[] }
export interface IDeleteToArray extends Action<BillboardActionTypes.DELETE_FROM_TO_ARRAYS> { 
    billboardsShortInfo: BillboardsShortInfo[],
    forbiddenBillboards: BillboardsShortInfo[]
 }

 export interface IOpenAddBillboard extends Action<BillboardActionTypes.OPEN_ADD_BILLBOARD> {
    openAddBillboard: boolean
}

export type BillboardActions =
    | IInitializeAction
    | IInitializeErrorAction
    | IInitializeSuccessAction
    | ICurrentBillboardAction
    | IFetchingTranslationsAction
    | IFetchTranslationsErrorAction
    | IGeoMarkUpdateSuccessAction
    | IGeoMarkUpdateStartAction
    | IFetchTranslationsSuccessAction
    | IFetchBillboardStastusesAction
    | IFetchBillboardStastusesErrorAction
    | IFetchBillboardStastusesSuccessAction
    | IChangeBillboardStastusesSuccessAction
    | ISaveBillboardStastusAction
    | ISaveBillboardStastusErrorAction
    | IClearBillboardStastusErrorAction
    | ISaveBillboardStastusSuccessAction
    | IChangeAreaNameAction
    | IChangeCityNameAction
    | IUploadPhotoAction
    | IDeletePhotoAction
    | IUploadPhotoSidesAction
    | IGetBillboardTariffsAction
    | IGetBillboardTariffsOwnerAction
    | IDeleteGeomarkTimeStatusAction
    | IFathcBillboardStastusesAction
    | IFilterBillboardsAction
    | ISuccessSnackbarAction
    | IOpenPriceAction
    | ISelectedSide
    | IGetAddressAction
    | IGeoMarkTariffsAction
    | ITariffCard
    | ISetGeoMarkTariffsAction
    | ISetGeoMarkTariffsSuccessAction
    | IOpenBrandsCard
    | IBrandsInfoAction
    | ICurrentBrandInfo
    | INextBillboardStatuses
    | IProjectBillboardIds
    | IGetBillboardsAndPropertiesAction
    | ICurrentBillboardIdAction
    | IOpenGeoMarkTariff
    | IBillboardsShortInfo
    | IBillboardShort
    | IBillboardPropertiesAction
    | IPropertySetAction
    | IEventGeoMarks
    | IFullMonthStatistic
    | IForbiddenBillboards
    | IDeleteToArray
    | IOpenAddBillboard;


export const initializeActionCreator: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IInitializeSuccessAction>> = (key: string) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            initWebServerProxy(key);
            const wsProxy = getWebServerProxy();

            const fetchingAction: IInitializeAction = {
                type: BillboardActionTypes.INITIALIZE
            };

            dispatch(fetchingAction);

            // companies
            // const companies: ApiResponse<ClientInfo[]> = await wsProxy.WebClient.GetAllCompanies();
            // if (companies.Success === false) {
            //     throw new Error(companies.Message || 'GetAllCompanies error undefined')
            // }
            // if (companies.Result === undefined){
            //     throw new Error('GetAllCompanies returns undefined')
            // }

            // const companiesArray = companies.Result;

            // brands
            const brands: ApiResponse<EmployeeBrandsInfo[]> = await wsProxy.WebClient.GetBrands();
            if (brands.Success === false) {
                throw new Error(brands.Message || 'GetBrands error undefined')
            }
            if (brands.Result === undefined){
                throw new Error('GetBrands returns undefined')
            }

            const brandsArray = brands.Result;

            // roles
            const roles: ApiResponse<RolesInfo[]> = await wsProxy.WebClient.GetCustomerRoles();
            if (roles.Success === false) {
                throw new Error(brands.Message || 'GetCustomerRoles error undefined')
            }
            if (roles.Result === undefined){
                throw new Error('GetCustomerRoles returns undefined')
            }

            const rolesArray = roles.Result;

            // billboards
            // const billboardsFilter = {}
            // const geomarks = await wsProxy.WebClient.GetBillboards(billboardsFilter);
            // if (geomarks.Success === false) {
            //     throw new Error(geomarks.Message || 'GetBillboards error undefined')
            // }
            // if (geomarks.Result === undefined){
            //     throw new Error('GetBillboards returns undefined')
            // }

            // const billboardsArray = geomarks.Result;

            // properties
            // const propertySetIds = [
            //     ...new Set(billboardsArray.map((value) => { return value.propertyValueSetId }).filter(id => id > 0))
            // ];

            // let propertiesArray : PropertyValueSet[] = [];
            // if (propertySetIds.length > 0) {
            //     const propertiesFilter : GetPropertiesFilter = {
            //         propertyValueSetIds: propertySetIds
            //     }
            //     const properties = await wsProxy.WebClient.GetProperties(propertiesFilter);

            //     if (properties.Success === false) {
            //         throw new Error(geomarks.Message || 'GetProperties error undefined')
            //     }
            //     if (properties.Result === undefined){
            //         throw new Error('GetProperties returns undefined')
            //     }

            //     propertiesArray = properties.Result;

            //     let propertyValueSetIdsB: number[] = [];
            //     for(let property of propertiesArray) {
            //         const propertyValue = property.propertyValues.find(pv => pv.property.propertyName === "PropertyValueSetIdSideB");
            //         if(propertyValue) {
            //             const propertyValueSetIdSide = propertyValue.value;
            //             propertyValueSetIdsB.push(parseInt(propertyValueSetIdSide));
            //         }
            //     }
            //     if(propertyValueSetIdsB.length > 0) {
            //         const ids = onlyUnique(propertyValueSetIdsB);
            //         const propertiesFilter : GetPropertiesFilter = {
            //             propertyValueSetIds: ids
            //         }
            //         const propertiesB = await getWebServerProxy().WebClient.GetProperties(propertiesFilter);
            //         if (propertiesB.Result) {
            //             propertiesArray = propertiesArray.concat(propertiesB.Result);
            //         }
            //     }
            // }

            const successAction: IInitializeSuccessAction = {
                type: BillboardActionTypes.INITIALIZE_SUCCESS,
                geoMarks: [], //billboardsArray,
                propertySets: [], //propertiesArray,
                brandsInfo: brandsArray,
                roles: rolesArray
            };

            dispatch(successAction);

            // SystemEvent.SubscribeEventGetBillboardsShortInfo(
            //     "actions", 
            //     (answer) => {
            //         const successBillboardsShortInfo: IBillboardsShortInfo = {
            //             type: BillboardActionTypes.BILLBOARDS_SHORT_INFO,
            //             billboardsShortInfo: answer.billboards
            //         };
        
            //         dispatch(successBillboardsShortInfo);
            //     }, (error) => {
            //         errorCallback(error);
            // });

            // SystemEvent.EventGetBillboardsShortInfo(undefined);

            const builder = new EventBuilder(`EventUserGetFullYearStatistics`);
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());

            const builderAgencies = new EventBuilder(`EventGetAgencies`);
                builderAgencies.AddParameter('AgencyIds', "");
                ServerProxy.SendSystemEvent(builderAgencies.GetSystemEvent());
            
            // const builderProjectFilter = new EventBuilder(`EventGetProjectFilters`);
            //     builderProjectFilter.AddParameter('Language', localStorageGetItem('lang') || 'ru');
            // ServerProxy.SendSystemEvent(builderProjectFilter.GetSystemEvent());

            SystemEvent.EventGetProjectFilters(localStorageGetItem('lang') || 'ru');

            // const builderProjects = new EventBuilder(`EventGetProjects`);
            //     builderProjects.AddParameter('ProjectType', ProjectType.CommonProject);
            // ServerProxy.SendSystemEvent(builderProjects.GetSystemEvent());
        } 
        catch (e) {
            const failAction: IInitializeErrorAction = {
                type: BillboardActionTypes.INITIALIZE_ERROR,
                error: e.message
            };
            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(failAction);
        }
    }
}

export const getBillboardsAndPropertiesAction: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IGetBillboardsAndPropertiesAction>> 
    = () => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();

            // billboards
            const billboardsFilter = {}
            const geomarks = await wsProxy.WebClient.GetBillboards(billboardsFilter);
            if (geomarks.Success === false) {
                throw new Error(geomarks.Message || 'GetBillboards error undefined')
            }
            if (geomarks.Result === undefined){
                throw new Error('GetBillboards returns undefined')
            }

            const billboardsArray = geomarks.Result;

            // properties
            const propertySetIds = [
                ...new Set(billboardsArray.map((value) => { return value.propertyValueSetId }).filter(id => id > 0))
            ];

            let propertiesArray : PropertyValueSet[] = [];
            if (propertySetIds.length > 0) {
                const propertiesFilter : GetPropertiesFilter = {
                    propertyValueSetIds: propertySetIds
                }
                const properties = await wsProxy.WebClient.GetProperties(propertiesFilter); 

                if (properties.Success === false) {
                    throw new Error(geomarks.Message || 'GetProperties error undefined')
                }
                if (properties.Result === undefined){
                    throw new Error('GetProperties returns undefined')
                }

                propertiesArray = properties.Result;
            }

            const successAction: IGetBillboardsAndPropertiesAction = {
                type: BillboardActionTypes.GET_BILLBOARS_AND_PROPERTIES,
                geoMarks: billboardsArray,
                propertySets: propertiesArray,
            };

            dispatch(successAction);             
        } catch (e) {
            const failAction: IFetchBillboardStastusesErrorAction = {
                type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES_ERROR,
                error: e.message
            };
            
            dispatch(failAction);
        }
    }
}

export const currentBillboard: ActionCreator<ICurrentBillboardAction> = (newBillboard: Billboard) => {
    return {
        type: BillboardActionTypes.CURRENT_BILLBOARD,
        billboard: newBillboard
    }
}

export const currentBillboardIdAction: ActionCreator<ICurrentBillboardIdAction> = (billboardId: number) => {
    return {
        type: BillboardActionTypes.CURRENT_BILLBOARD_ID,
        billboardId
    }
}

export const filterBillboardsAction: ActionCreator<IFilterBillboardsAction> = (filterBillboards: Billboard[]) => {
    return {
        type: BillboardActionTypes.FILTER_BILLBOARDS,
        filterBillboards
    }
}

export const changeAreaName: ActionCreator<IChangeAreaNameAction> = (selectedAreaName: string) => {
    return {
        type: BillboardActionTypes.CHANGE_AREA_NAME,
        selectedAreaName
    }
}

export const changeCityName: ActionCreator<IChangeCityNameAction> = (selectedCityName: string) => {
    return {
        type: BillboardActionTypes.CHANGE_CITY_NAME,
        selectedCityName
    }
}

export const getAddressAction: ActionCreator<IGetAddressAction> = (getAddress: IGetAddress) => {
    return {
        type: BillboardActionTypes.GET_ADDRESS,
        getAddress
    }
}

export const fetchTranslationsActionCreator: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IFetchTranslationsSuccessAction>> = () => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            initWebServerProxy('');
            const wsProxy = getWebServerProxy();

            const fetchingAction: IFetchingTranslationsAction = {
                type: BillboardActionTypes.FETCH_TRANSLATIONS
            };
            dispatch(fetchingAction);

            const result = await wsProxy.WebClient.GetLocalization({});
            if (result.Success === false){
                const failAction: IFetchTranslationsErrorAction = {
                    type: BillboardActionTypes.FETCH_TRANSLATIONS_ERROR,
                    error: result.Message!
                };

                dispatch(failAction);
            }

            const successAction: IFetchTranslationsSuccessAction = {
                type: BillboardActionTypes.FETCH_TRANSLATIONS_SUCCESS,
                translations: result.Result!
            };

            dispatch(successAction);                   
        } catch (e) {
            const failAction: IFetchTranslationsErrorAction = {
                type: BillboardActionTypes.FETCH_TRANSLATIONS_ERROR,
                error: e.message
            };
            
            dispatch(failAction);
        }
    }
}


export const updateBillboard: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IGeoMarkUpdateSuccessAction>> = (propertySet: IPropertySet, geomark: GeoMark) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();

            const saveGeomarkStartAction: IGeoMarkUpdateStartAction = {
                type: BillboardActionTypes.SAVE_GEOMARK_START
            };

            dispatch(saveGeomarkStartAction);
            const resultPropertyValueSet : ApiResponse<NewPropertyValueSetId> = await wsProxy.WebClient.SavePropertySet(propertySet);
            // if (resultPropertyValueSet.Success === false){
            //     const failAction: IFetchTranslationsErrorAction = {
            //         type: BillboardActionTypes.SAVE_GEOMARK_ERROR,
            //         error: resultPropertyValueSet.Message!
            //     };

            //     dispatch(failAction);
            // }

            geomark.propertyValueSetId = resultPropertyValueSet.Result!.propertyValueSetId;

            const resultSaveGeoMark = await await wsProxy.WebClient.SetGeoMark(geomark);
            // if (resultSaveGeoMark.Success === false){
            //     const failAction: IFetchTranslationsErrorAction = {
            //         type: BillboardActionTypes.SAVE_GEOMARK_ERROR,
            //         error: resultSaveGeoMark.Message!
            //     };

            //     dispatch(failAction);
            // }

            const saveGeomarkSuccessAction: IGeoMarkUpdateSuccessAction = {
                type: BillboardActionTypes.SAVE_GEOMARK_SUCCESS
            };
            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(saveGeomarkSuccessAction);
        } catch (e) {
            console.error(e);
            const failAction: IFetchTranslationsErrorAction = {
                type: BillboardActionTypes.FETCH_TRANSLATIONS_ERROR,
                error: e.message
            };
            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(failAction);
        }
    }
}


export const fetchBillboardStatusesActionCreator: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IFetchBillboardStastusesAction>> 
    = (billboardIDs: number[], dateStart: Date, dateEnd: Date, replaceAll: boolean) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();

            const fetchingAction: IFetchBillboardStastusesAction = {
                type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES
            };
            dispatch(fetchingAction);

            const result = await wsProxy.WebClient.GetGeoMarksTimeStatuses(billboardIDs, dateStart, dateEnd);
            if (result.Success === false){
                const failAction: IFetchBillboardStastusesErrorAction = {
                    type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES_ERROR,
                    error: result.Message!
                };

                dispatch(failAction);
            }

            const successAction: IFetchBillboardStastusesSuccessAction = {
                type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES_SUCCESS,
                statuses: result.Result!,
                billboardIDs,
                replaceAll
            };

            dispatch(successAction);                   
        } catch (e) {
            const failAction: IFetchBillboardStastusesErrorAction = {
                type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES_ERROR,
                error: e.message
            };
            
            dispatch(failAction);
        }
    }
}

export const nextBillboardStatusesAction: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, INextBillboardStatuses>> 
    = (billboardIDs: number[], dateStart: Date, dateEnd: Date) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();

            const fetchingAction: IFetchBillboardStastusesAction = {
                type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES
            };
            dispatch(fetchingAction);

            const result = await wsProxy.WebClient.GetGeoMarksTimeStatuses(billboardIDs, dateStart, dateEnd);
            if (result.Success === false){
                const failAction: IFetchBillboardStastusesErrorAction = {
                    type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES_ERROR,
                    error: result.Message!
                };

                dispatch(failAction);
            }

            const successAction: INextBillboardStatuses = {
                type: BillboardActionTypes.NEXT_BILLBOARD_STATUSES,
                nextBillboardStatuses: result.Result!,
            };

            dispatch(successAction);                   
        } catch (e) {
            const failAction: IFetchBillboardStastusesErrorAction = {
                type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES_ERROR,
                error: e.message
            };
            
            dispatch(failAction);
        }
    }
}

export const changeBillboardStatusesActionCreator: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IFetchBillboardStastusesAction>> 
    = (projectIds: number[]) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();

            const fetchingAction: IFetchBillboardStastusesAction = {
                type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES
            };
            dispatch(fetchingAction);

            const result = await wsProxy.WebClient.GetGeoMarkTimeStatusesByProjectIds(projectIds);
            if (result.Success === false){
                const failAction: IFetchBillboardStastusesErrorAction = {
                    type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES_ERROR,
                    error: result.Message!
                };

                dispatch(failAction);
            }

            const successAction: IChangeBillboardStastusesSuccessAction = {
                type: BillboardActionTypes.CHANGE_BILLBOARD_STATUSES_SUCCESS,
                statuses: result.Result!,
                projectIds
            };

            dispatch(successAction);                   
        } catch (e) {
            const failAction: IFetchBillboardStastusesErrorAction = {
                type: BillboardActionTypes.FETCH_BILLBOARD_STATUSES_ERROR,
                error: e.message
            };
            
            dispatch(failAction);
        }
    }
}

export const saveBillboardStatusActionCreator: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, ISaveBillboardStastusAction>> 
    = (status: GeoMarkTimeStatus) => {
    return async (dispatch: Dispatch<Action> & ThunkDispatch<any, any, Action>) => {
        try {
            const wsProxy = getWebServerProxy();

            const fetchingAction: ISaveBillboardStastusAction = {
                type: BillboardActionTypes.SAVE_BILLBOARD_STATUS
            };
            dispatch(fetchingAction);

            const result = await wsProxy.WebClient.SetGeoMarkTimeStatuses(status)
            if (result.Success === false){
                const failAction: ISaveBillboardStastusErrorAction = {
                    type: BillboardActionTypes.SAVE_BILLBOARD_STATUS_ERROR,
                    error: result.Message!
                };

                dispatch(failAction);
            }

            const successAction: ISaveBillboardStastusSuccessAction = {
                type: BillboardActionTypes.SAVE_BILLBOARD_STATUS_SUCCESS,
                // successSnackbar: status.geoMarkStatus === 'Reserved' && result.Result!.success,
                successSnackbar: result.Result!.success
            };

            SystemEvent.EventGetProjectInfo(status.projectId);

            dispatch(successAction);     

            dispatch(fetchBillboardStatusesActionCreator([status.geoMarkId], status.dateTimeStart, status.dateTimeEnd, false));
        } catch (e) {
            console.log(e);
            // const failAction: ISaveBillboardStastusErrorAction = {
            //     type: BillboardActionTypes.SAVE_BILLBOARD_STATUS_ERROR,
            //     error: e.message
            // };
            
            // dispatch(failAction);
        }
    }
}

export const setGeoMarkTariffsAction: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, ISetGeoMarkTariffsAction>> 
    = (tariffInfo: ITariffInfo, projectID: number) => {
    return async (dispatch: Dispatch<Action> & ThunkDispatch<any, any, Action>) => {
        try {
            const wsProxy = getWebServerProxy();

            const fetchingAction: ISetGeoMarkTariffsAction = {
                type: BillboardActionTypes.SET_GEO_MARK_TARIFFS
            };
            dispatch(fetchingAction);

            const result = await wsProxy.WebClient.SetGeoMarkTariffs(tariffInfo)
            if (result.Success === false){
                const failAction: ISaveBillboardStastusErrorAction = {
                    type: BillboardActionTypes.SAVE_BILLBOARD_STATUS_ERROR,
                    error: result.Message!
                };

                dispatch(failAction);
            }

            if(result.Success === true) {
                const builder = new EventBuilder(`EventGetCommercialOfferDiscounts`);
                builder.AddParameter('ProjectId', projectID);
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            }

            const successAction: ISetGeoMarkTariffsSuccessAction = {
                type: BillboardActionTypes.SET_GEO_MARK_TARIFFS_SUCCESS,
                tariffSuccess: result.Result!.success
            };

            dispatch(successAction);

        } catch (e) {
            console.log('SetGeoMarkTimeStatuses:', e);
        }
    }
}

export const uploadPhotoAction: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IUploadPhotoAction>> 
    = (geoMarkId: number, sideName: string, selectedPhoto: FormData, dates?: DatesRange) => {
    return async (dispatch: Dispatch<Action> & ThunkDispatch<any, any, Action>) => {
        try {
            const wsProxy = getWebServerProxy();

            const result = await wsProxy.WebClient.SetBillboardPhoto(geoMarkId, sideName,  selectedPhoto);

            if(result.Success === true) {
                const successAction: IUploadPhotoAction = {
                    type: BillboardActionTypes.UPLOAD_PHOTO,
                    photoVersion: new Date().getTime()
                };
    
                dispatch(successAction);

                if(dates) {
                    const dateStart = moment.utc(dates.DateFrom, "YYYY-MM-DD").format('DD.MM.YYYY');
                    const dateEnd = moment.utc(dates.DateTo, "YYYY-MM-DD").format('DD.MM.YYYY');
                    SystemEvent.EventGetBillboardInfo(geoMarkId, dateStart, dateEnd);
                }
            }

        } catch (e) {
            console.log(`Ошибка uploadPhotoAction ${e.message}`);
        }
    }
}

export const deletePhotoAction: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IDeletePhotoAction>> 
    = (geoMarkId: number, sideName: string) => {
    return async (dispatch: Dispatch<Action> & ThunkDispatch<any, any, Action>) => {
        try {
            const wsProxy = getWebServerProxy();

            const result = await wsProxy.WebClient.DeleteBillboardPhoto(geoMarkId, sideName);
            if(result.Success === true) {
                const successAction: IDeletePhotoAction = {
                    type: BillboardActionTypes.DELETE_PHOTO,
                    photoVersion: new Date().getTime()
                };
    
                dispatch(successAction)
            }

        } catch (e) {
            console.log(`Ошибка deletePhotoAction ${e.message}`);
        }
    }
}

export const resetSaveErrorActionCreator = () => {
    return (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {

        const fetchingAction: IClearBillboardStastusErrorAction = {
            type: BillboardActionTypes.CLEAR_BILLBOARD_STATUS_ERROR
        };
        dispatch(fetchingAction);
    }
}

export const getAdditionalBillboardAction: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IUploadPhotoSidesAction>> 
    = (geoMarkId: number) => {
    return async (dispatch: Dispatch<Action> & ThunkDispatch<any, any, Action>) => {
        try {
            const wsProxy = getWebServerProxy();

            const result = await (await wsProxy.WebClient.GetAdditionalBillboardInfo(geoMarkId));

            const successAction: IUploadPhotoSidesAction = {
                type: BillboardActionTypes.UPLOAD_PHOTO_SIDES,
                uploadedPhotos: result.Result!
            };

            dispatch(successAction)

        } catch (e) {
            console.log(`Ошибка deletePhotoAction ${e.message}`);
        }
    }
}

export const getBillboardTariffsAction: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IGetBillboardTariffsAction>> 
    = (geoMarkIds: number[]) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();

            const result = await wsProxy.WebClient.GetGeoMarkTariffs(geoMarkIds);

            const successAction: IGetBillboardTariffsAction = {
                type: BillboardActionTypes.GET_BILLBOARD_TARIFFS,
                billboardTariffs: result.Result!
            }
            dispatch(successAction);
                   
        } catch (e) {
            console.log(`Ошибка getBillboardTariffsAction ${e.message}`);
        }
    }
}

export const deleteGeoMartTimeStatuses: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, any>> 
= (geoMarkTimeStatusIds: number[], projectID?: number) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const wsProxy = getWebServerProxy();

            const result = await wsProxy.WebClient.DeleteGeoMartTimeStatuses(geoMarkTimeStatusIds);

            const successAction: IDeleteGeomarkTimeStatusAction = {
                type: BillboardActionTypes.DELETE_GEOMARK_TIME_STATUS,
                geoMarkTimeStatusIds
            };
            
            dispatch(successAction);

            if(result.Success === true && projectID) {
                SystemEvent.EventGetProjectInfo(projectID);
            } else {
                alert("Что то пошло не так!");
            }

        } catch (err) {
            alert(`Ошибка deleteGeoMartTimeStatuses ${err.message}`);
            console.log(`Ошибка deleteGeoMartTimeStatuses ${err.message}`)
        }
    }
}

export const successSnackbarAction: ActionCreator<ISuccessSnackbarAction> = (successSnackbar: boolean) => {
    return {
        type: BillboardActionTypes.SUCCESS_SHACKBAR,
        successSnackbar
    }
}

export const openPriceAction: ActionCreator<IOpenPriceAction> = (openPrice: boolean) => {
    return {
        type: BillboardActionTypes.OPEN_PRICE,
        openPrice
    }
}

export const selectedSideAction: ActionCreator<ISelectedSide> = (selectedSide: string) => {
    return {
        type: BillboardActionTypes.SELECTED_SIDE,
        selectedSide
    }
}

export const getGeoMarkTariffsAction: ActionCreator<IGetBillboardTariffsAction> = (billboardTariffs: GetGeoMarkTariffs[]) => {
    return {
        type: BillboardActionTypes.GET_BILLBOARD_TARIFFS,
        billboardTariffs
    }
}

export const getGeoMarkTariffsOwnerAction: ActionCreator<IGetBillboardTariffsOwnerAction> = (billboardTariffsOwner: GetGeoMarkTariffs[]) => {
    return {
        type: BillboardActionTypes.GET_BILLBOARD_TARIFFS_OWNER,
        billboardTariffsOwner
    }
}

export const geoMarkTariffsAction: ActionCreator<IGeoMarkTariffsAction> = (geoMarkTariffs: IGeoMarkTariffs) => {
    return {
        type: BillboardActionTypes.GEOMARK_TARIFFS,
        geoMarkTariffs
    }
}

export const openTariffCardAction: ActionCreator<ITariffCard> = (openTariffCard: boolean) => {
    return {
        type: BillboardActionTypes.OPEN_TARIFF_CARD,
        openTariffCard
    }
}

export const openBrandsCardAction: ActionCreator<IOpenBrandsCard> = (openBrandsCard: boolean) => {
    return {
        type: BillboardActionTypes.OPEN_BRANDS_CARD,
        openBrandsCard
    }
}

export const brandsInfoAction: ActionCreator<IBrandsInfoAction> = (brandsInfo: IBrandsInfo[]) => {
    return {
        type: BillboardActionTypes.GET_BRANDS_INFO,
        brandsInfo
    }
}

export const currentBrandInfoAction: ActionCreator<ICurrentBrandInfo> = (currentBrandInfo: IBrandsInfo | null) => {
    return {
        type: BillboardActionTypes.CURRENT_BRAND_INFO,
        currentBrandInfo
    }
}

export const projectBillboardIdsAction: ActionCreator<IProjectBillboardIds> = (projectBillboardIds: number[]) => {
    return {
        type: BillboardActionTypes.PROJECT_BILLBOARD_IDS,
        projectBillboardIds
    }
}

export const openGeoMarkTariffAction: ActionCreator<IOpenGeoMarkTariff> = (openGeoMarkTariff: boolean) => {
    return {
        type: BillboardActionTypes.OPEN_GEO_MARK_TARIFF,
        openGeoMarkTariff
    }
}

export const billboardsShortInfoAction: ActionCreator<IBillboardsShortInfo> = (billboardsShortInfo: BillboardsShortInfo[]) => {
    return {
        type: BillboardActionTypes.BILLBOARDS_SHORT_INFO,
        billboardsShortInfo
    }
}

export const deleteTwoArraysAction: ActionCreator<IDeleteToArray> = (geoMarkId: number) => {
    const billboardsShortInfo = store.getState().billboards.billboardsShortInfo;
    const forbiddenBillboards = store.getState().billboards.forbiddenBillboards;
    const index = billboardsShortInfo.findIndex((listItem) => listItem.geoMarkId === geoMarkId);
    const indexForbidden = forbiddenBillboards.findIndex((listItem) => listItem.geoMarkId === geoMarkId);
    const newList = removeItemAtIndex(billboardsShortInfo, index);
    const newListForbidden = removeItemAtIndex(forbiddenBillboards, indexForbidden);
    return {
        type: BillboardActionTypes.DELETE_FROM_TO_ARRAYS,
        billboardsShortInfo: newList,
        forbiddenBillboards: newListForbidden
    }
}

export const forbiddenBillboardsAction: ActionCreator<IForbiddenBillboards> = (forbiddenBillboards: BillboardsShortInfo[]) => {
    return {
        type: BillboardActionTypes.FORBIDDEN_BILLBOARDS,
        forbiddenBillboards
    }
}

export const billboardShortAction: ActionCreator<IBillboardShort> = (billboardShort: BillboardsShortInfo | undefined) => {
    return {
        type: BillboardActionTypes.BILLBOARD_SHORT,
        billboardShort
    }
}

export const billboardPropertiesAction: ActionCreator<IBillboardPropertiesAction> = (billboardProperties: IBillboardProperties | undefined) => {
    return {
        type: BillboardActionTypes.BILLBOARD_PROPERTIES,
        billboardProperties
    }
}

export const propertySetAction: ActionCreator<IPropertySetAction> = (propertySet: IPropertySet | null) => {
    return {
        type: BillboardActionTypes.PROPERTY_SET,
        propertySet
    }
}

export const eventGeoMarksAction: ActionCreator<IEventGeoMarks> = (eventGeoMarks: EventGeoMarks[]) => {
    return {
        type: BillboardActionTypes.EVENT_GEO_MARKS,
        eventGeoMarks
    }
}

export const fullMonthStatisticAction: ActionCreator<IFullMonthStatistic> = (fullMonthStatistic: FullMonthStatistic[]) => {
    return {
        type: BillboardActionTypes.FULL_MONTH_STATISTIC,
        fullMonthStatistic
    }
}

export const openAddBillboardAction: ActionCreator<IOpenAddBillboard> = (openAddBillboard: boolean) => {
    return {
        type: BillboardActionTypes.OPEN_ADD_BILLBOARD,
        openAddBillboard
    }
}