import React from 'react';
import { Box, CircularProgress, CircularProgressProps, Typography } from "@material-ui/core";

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box 
            position="relative" 
            display="inline-flex">
            <CircularProgress 
                variant="determinate" 
                size={24}
                color="secondary"
                {...props} />
            <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            >
                <Typography 
                    variant="caption" 
                    component="div" 
                    color="textSecondary"
                    className="persentImportExcel">
                    {`${Math.round(
                        props.value,
                    )}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;