import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import DatesShift, { RangeType } from './DatesShift';
import InputDate from './InputDate';
import { Translate } from "react-localize-redux";
import { GeoMarkTimeStatus } from 'sedi-webserverproxy';
import moment from 'moment';


type Props = ReturnType<typeof mapDispatchToProps> & {
    onChange: (datesRange: DatesRange) => void,
    dates: DatesRange,
    showShiftControl?: boolean,
    allowUndefinedValues?: boolean,
    labelTextFrom?: string,
    labelTextTo?: string,
    currentStatuses?: GeoMarkTimeStatus[],
    disabled?: boolean,
};


export type DatesRange = {
    DateFrom: Date | undefined,
    DateTo: Date | undefined
};


type State = {
    rangeType: RangeType,
    dates: DatesRange,
    currentStatusId: number,
};


type _HtmlInputElementDate = {
    valueAsDate: Date | null
};


class InputDatesRange extends React.Component<Props, State> {

    public static defaultProps: any = {
        showShiftControl: false,
        allowUndefinedValues: true,
        labelTextFrom: <Translate id='DateFrom' />,
        labelTextTo: <Translate id='DateTo' />,
    }


    constructor(props: Props) {
        super(props);

        const { dates, allowUndefinedValues } = props;

        if(dates && dates.DateFrom === undefined && dates.DateTo === undefined && !allowUndefinedValues)
            Object.assign(dates, getWeekDates());

        this.state = {
            rangeType: RangeType.Week,
            dates: dates || (allowUndefinedValues ? { DateFrom: undefined, DateTo: undefined } : getWeekDates()),
            currentStatusId: 0,
        };
    }

    componentDidUpdate(prevProps: Props) {
        const { currentStatuses, onChange } = this.props;

        if(currentStatuses !== prevProps.currentStatuses) {
            if(currentStatuses && currentStatuses.length !== 0 && currentStatuses[0].geoMarkTimeStatusId !== this.state.currentStatusId) {
                const initialDate = {
                    DateFrom: moment(currentStatuses[0].dateTimeStart).toDate(),
                    DateTo: moment(currentStatuses[0].dateTimeEnd).toDate(),
                }
                onChange(initialDate);
                this.setState({
                    currentStatusId: currentStatuses[0].geoMarkTimeStatusId
                });
            }
        }
    }


    handleChangeRangeType(rangeType: RangeType) {
        this.setState({ rangeType });
        const { dates, onChange } = this.props;

        let newDateFrom = new Date(dates.DateTo || 0);
        let dateTo = new Date(dates.DateTo || 0);
        if(rangeType === RangeType.Week)
            newDateFrom.setDate(dateTo.getDate() - 7);
        if(rangeType === RangeType.TwoWeeks)
            newDateFrom.setDate(dateTo.getDate() - 14);
        if(rangeType === RangeType.Month)
            newDateFrom.setDate(dateTo.getDate() - 30);
        
        const newDates = {DateFrom: newDateFrom, DateTo: dateTo}
        onChange(newDates);
        this.setState({
            dates: newDates  
        });
        this.triggerOnChange(newDates);
    }


    handleChangeDate(isFirstDate: boolean, date: Date | null) {
        const newDateValue = date ? date : undefined;
        const { allowUndefinedValues } = this.props;

        if (allowUndefinedValues === false && newDateValue === undefined) {
            return;
        }

        const { rangeType } = this.state;
        const { dates, onChange } = this.props;

        let newDates = dates;
        if (isFirstDate) {
            newDates.DateFrom = newDateValue
        } else {
            newDates.DateTo = newDateValue
        }

        // if(dates.DateFrom && dates.DateTo && newDateValue) {
        //     const dateFrom = moment(dates.DateFrom).format('DD-MM-YYYY');
        //     const dateTo = moment(dates.DateTo).format('DD-MM-YYYY');
        //     if(dateFrom === dateTo) {
        //         newDates = changeDates(rangeType, isFirstDate, newDateValue)
        //     }
        // }

        // if (newDates.DateFrom !== undefined
        //     && newDates.DateTo !== undefined
        //     && newDateValue !== undefined
        //     && newDates.DateFrom > newDates.DateTo) {
        //     newDates = changeDates(rangeType, isFirstDate, newDateValue)
        // }

        onChange(newDates);
        this.setState({
            dates: newDates  
        });
        this.triggerOnChange(newDates);
    }


    handleClickShiftDates(increase: boolean) {
        const { rangeType } = this.state;
        const { dates, onChange } = this.props;

        const newDates = shiftDates(rangeType, dates, increase);

        onChange(newDates);

        this.triggerOnChange(newDates);
    }


    triggerOnChange(dates: DatesRange) {
        const { onChange } = this.props;
        onChange(dates);
    }

    clearDateClick(whatDate: "first" | "last") {
        const { dates, datesAction, onChange } = this.props;

        if(whatDate === "first") {
            onChange({
                ...dates,
                DateFrom: undefined
            });
            datesAction({
                ...dates,
                DateFrom: undefined
            })
        }
        if(whatDate === "last") {
            onChange({
                ...dates,
                DateTo: undefined
            });
            datesAction({
                ...dates,
                DateTo: undefined
            })
        }
    }

    render() {
        const { rangeType } = this.state;
        const { showShiftControl, labelTextFrom, labelTextTo, disabled, dates } = this.props;
        return (
            <div className="d-flex align-items-center status-date-range cancelDraggable">
                <div className="inputDate mr-3">
                    <InputDate
                        label={labelTextFrom}
                        valueAsDate={dates.DateFrom}
                        onChange={(date: any) => this.handleChangeDate(true, date)}
                        disabled={disabled}
                        placeholderText="Дата от"
                        dates={dates}
                    />
                </div>
                <div className="inputDate">
                    <InputDate
                        label={labelTextTo}
                        valueAsDate={dates.DateTo}
                        onChange={(date: any) => this.handleChangeDate(false, date)}
                        disabled={disabled}
                        placeholderText="Дата до"
                        dates={dates}
                    />
                </div>

                {showShiftControl &&
                    <DatesShift
                        rangeType={rangeType}
                        onChangeRangeType={rangeType => this.handleChangeRangeType(rangeType)}
                        onShift={(increase) => this.handleClickShiftDates(increase)}
                    />
                }
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    datesAction: (dates: DatesRange) => dispatch(actions.todo.datesAction(dates)),
});

export default connect(null, mapDispatchToProps)(InputDatesRange);

function changeDates(rangeType: RangeType, isFirstDate: boolean, newDate?: Date) {

    let dateFrom = new Date(newDate || 0);
    let dateTo = new Date(newDate || 0);
    
    if (rangeType === RangeType.Week || rangeType === RangeType.TwoWeeks) {
        const offset = (rangeType === RangeType.Week ? 7 : 14) - 1;

        if (isFirstDate) {
            dateTo.setDate(dateTo.getDate() + offset);
        }
        else {
            dateFrom.setDate(dateFrom.getDate() - offset);
        }
    }
    else if (rangeType === RangeType.Month) {
        if (isFirstDate) {
            dateTo.setMonth(dateTo.getMonth() + 1);
        }
        // else {
        //     dateFrom.setMonth(dateFrom.getMonth() - 1);
        // }
    }

    return {
        DateFrom: dateFrom,
        DateTo: dateTo
    }
}


export function getWeekDates(): DatesRange {
    const today = new Date();
    // ! dateFrom.getDay() === 0 для воскресенья
    const currentDayOfWeek = today.getDay() - 1;

    let dateFrom = new Date(today);
    dateFrom.setDate(dateFrom.getDate() - currentDayOfWeek);

    let dateTo = new Date(today);
    dateTo.setDate(dateTo.getDate() + 6 - currentDayOfWeek);

    return {
        DateFrom: dateFrom,
        DateTo: dateTo
    }
}

export function getMonthDates(): DatesRange {
    // const today = new Date();
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');
    const dateFromAddYear = moment(startOfMonth).add(11, "month").format('YYYY-MM-DD hh:mm');

    const DateFrom = new Date(startOfMonth);
    const DateTo = new Date(dateFromAddYear);

    return {
        DateFrom,
        DateTo
    }
}


function shiftDates(rangeType: RangeType, dates: DatesRange, increase: boolean) {
    const direction = increase ? 1 : -1;

    let dateFrom = new Date(dates.DateFrom || 0);
    let dateTo = new Date(dates.DateTo || 0);

    if (rangeType === RangeType.Week) {
        dateFrom.setDate(dateFrom.getDate() + direction * 7);
        dateTo.setDate(dateTo.getDate() + direction * 7);
    }
    else if (rangeType === RangeType.TwoWeeks) {
        dateFrom.setDate(dateFrom.getDate() + direction * 7 * 2);
        dateTo.setDate(dateTo.getDate() + direction * 7 * 2);
    }
    else if (rangeType === RangeType.Month) {
        dateFrom.setMonth(dateFrom.getMonth() + direction);
        dateTo.setMonth(dateTo.getMonth() + direction);
    }

    return {
        DateFrom: dates.DateFrom ? dateFrom : undefined,
        DateTo: dates.DateTo ? dateTo : undefined
    }
}