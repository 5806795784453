import React, { Dispatch } from 'react'
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './bricks.scss'
import { RootState, actions } from '../../store';

type Props = {
    successSnackbarAction: (successSnackbar: boolean) => void
    successSnackbar: boolean
    label: string
}

const SuccessSnackbar:React.FC<Props> = ({ successSnackbarAction, successSnackbar, label }) => {

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        successSnackbarAction(false)
    };

    return (
        <Snackbar
            className="successSnackbar"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={successSnackbar}
            onClose={handleClose}
            autoHideDuration={5000}
            message={
                <>
                    <CheckCircleIcon />
                    <span className="pl-2">{label}</span>
                </>
            }
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        />
    )
}

const mapStateToProps = (state: RootState) => ({
    successSnackbar: state.billboards.successSnackbar
})

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    successSnackbarAction: (successSnackbar: boolean) => dispatch(actions.billboards.successSnackbarAction(successSnackbar))
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(SuccessSnackbar)