import React, { Component } from 'react';
import TextFieldMaterial, { TextFieldProps } from '@material-ui/core/TextField';
import { PhoneValue, formatedPhone } from '../../utils/format';

type Props = TextFieldProps & {
    onPhoneChange: (value: PhoneValue) => void;
}

type State = PhoneValue;

export default class InputPhoneFormated extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = formatedPhone(props.value as string);
    }
    /** 
     * Форматирование при изменении поле телефона с использованием libphonenumber-js
    */
    handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        let rawValue = event.target.value;

        if(rawValue.length > 0 && rawValue[0] !== '+') {
            rawValue = '+' + rawValue[0];
        }

        const newState = formatedPhone(rawValue);
        this.setState(newState);
        this.props.onPhoneChange(newState);
    }

    render() {
        const { onPhoneChange, error, ...inputProps } = this.props;
        const { formatedValue, isValid, maskPhone } = this.state;
        return (
            <TextFieldMaterial 
                {...inputProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={maskPhone}
                onChange={(event) => this.handleChange(event)}
                value={formatedValue}
                id="testers"
                helperText= {
                    error !== false && 
                    (formatedValue.length > 0 && 
                        (!isValid && 'Количество не совпадает')
                    )
                }
                error= {
                    error !== false && 
                    (formatedValue.length > 0 && 
                        (!isValid && true)
                    )
                }
            />
        );
    }
};
