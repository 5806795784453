import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Button, Card, Checkbox, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import EmployeeModal from '../EmployeeModal/EmployeeModal';
import Draggable from 'react-draggable';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { RollUpCard } from '../../store/event/types';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { EmployeeFilter, IDetailedEmployeeInfo, IShortEmployeesInfo, WorkMode } from '../../RemoteCommands/type';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { avatarLetters } from '../../utils/avatarLetters';
import ContextMenuCall from '../bricks/ContextMenuCall';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EmployeeCallModal from '../EmployeeCallModal/EmployeeCallModal';
import './Employees.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
        openEmployeesCard: boolean;
    }

export const employeeRoles: string[] = [
    "SalesManager",
    "SalesDirector",
    "DataOperator",
    "AdminPMS"
];

const Employees:React.FC<Props> = (props) => {
    const {
        draggablePositions,
        draggablePositionAction,
        draggable,
        openEmployeesCard,
        rollUpCards,
        openEmployeesCardAction,
        rollUpCardAction,
        employeesCountAction
    } = props;

    const [employeeOpen, setEmployeeOpen] = useState(false);
    const [employeeCallOpen, setEmployeeCallOpen] = useState(false);
    const [shortAllEmployeesInfo, setShortAllEmployeesInfo] = useState<IShortEmployeesInfo[]>([]);
    const [shortEmployeesInfo, setShortEmployeesInfo] = useState<IShortEmployeesInfo[]>([]);
    const [employeeDetailInfo, setEmployeeDetailInfo] = useState<IDetailedEmployeeInfo | null>(null);
    const [employeeId, setEmployeeId] = useState<number>(-1); 
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [roles, setRoles] = useState<string[]>([]);
    const [workMode, setWorkMode] = useState<WorkMode>(WorkMode.All);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [sipAccount, setSipAccount] = useState<string | null>(null);

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);

    const contextMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, _shortEmployeesInfo: IShortEmployeesInfo) => {
        event.preventDefault();
        setAnchorEl(null);
        setSipAccount(_shortEmployeesInfo.sipAccount)
        setEmployeeId(_shortEmployeesInfo.employeeId);
        setAnchorEl(event.currentTarget);
    };

    const contextMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    });

    useEffect(() => {
        SystemEvent.SubscribeEventGetEmployeesShortInfo(
            "EventGetEmployeesShortInfo", 
            (answer) => {
                setShortAllEmployeesInfo(answer.shortEmployeesInfo);
                setShortEmployeesInfo(answer.shortEmployeesInfo);
                employeesCountAction(answer.shortEmployeesInfo.length);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, []);

    useEffect(() => {
        shortEmployeesFilter();
    }, [search, roles, workMode]);

    const shortEmployeesFilter = () => {
        const employeeFilter: EmployeeFilter = {
            textFilter: search,
            roles: roles.length > 0 ? roles : null,
            workMode: workMode
        }

        const employesEmpty = shortAllEmployeesInfo.some((employee) => {
            const fullName = employee.firstName + " " + employee.secondName; 
            return fullName.toLowerCase().search(search.toLowerCase()) != -1;
        });

        if(shortAllEmployeesInfo.length >= 200 || !employesEmpty || search.length === 0) {
            SystemEvent.EventGetEmployeesShortInfo(employeeFilter);
        } else {
            let searchHandle: IShortEmployeesInfo[] = [];
            searchHandle = shortAllEmployeesInfo.filter(employee => {
                const fullName = employee.firstName + " " + employee.secondName; 
                return fullName.toLowerCase().indexOf(search.toLowerCase()) === 0 || fullName.toLowerCase().indexOf(' ' + search.toLowerCase()) > 0
            });
            setShortEmployeesInfo(searchHandle);
        }
    }

    useEffect(() => {
        if(employeeId > 0) {
            setLoading(true);
            SystemEvent.SubscribeEventGetEmployeeDetailedInfo(
                "EventGetEmployeeDetailedInfo", 
                (answer) => {
                    setEmployeeDetailInfo(answer.detailedEmployeeInfo);
                    setLoading(false);
                }, 
                (error) => {
                    errorCallback(error);
                    setLoading(false);
                }
            );
            if(employeeId) {
                SystemEvent.EventGetEmployeeDetailedInfo(employeeId);
            }
        }
    }, [employeeId]);

    const employeeDetailedClick = (employeeId: number) => {
        setEmployeeId(employeeId);
    }

    const employeeClickOpen = () => {
        setEmployeeOpen(true);
        setAnchorEl(null);
    }

    const employeeClickClose = () => {
        setEmployeeOpen(false);
    }

    const employeeCallClickOpen = () => {
        setEmployeeCallOpen(true);
        setAnchorEl(null);
    }

    const employeeCallClickClose = () => {
        setEmployeeCallOpen(false);
    }

    const addEmploeeClick = () => {
        const _employeeDetailInfo: IDetailedEmployeeInfo = {
            employeeId: -1,
            address: {
                countryName: "",
                cityName: "",
                streetName: "",
                houseNumber: "",
                apartmentNumber: "",
                geoPoint: {
                    latitude: 0,
                    longitude: 0
                }
            },
            email: "",
            firstName: "",
            secondName: "",
            gender: false,
            notes: null,
            phones: [{
                    number: "",
                    phoneType: "MobileWork"
            }],
            roles: []
        }
        setEmployeeDetailInfo(_employeeDetailInfo);
        setEmployeeId(-1);
        setEmployeeOpen(true);
    }

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'Employees',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Сотрудники",
            key: "Employees"
        });
    }

    const onCurrentRoleChange = (roles: string[]) => {
        setRoles(roles)
    }

    const handleSeachChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setSearch(value);
    }

    const draggablePosition = draggablePositions.find(item => item.key === "Employees");
    const rollUpCardHas = rollUpCards.some(item => item.key === "Employees");

    return (
        <Draggable
            handle=".employees"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
                <Card
                    className={`
                        employees ${openEmployeesCard ? 'openSelectedCard' : 'closeSelectedCard'}
                        ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                    `}
                    style={{cursor: draggable ? 'move' : 'auto'}}>
                    <Drawer
                        className="employees__drawer"
                        variant="permanent">
                        <div className="employees__drawerContainer">
                            <div className="employees__close">
                                <Tooltip
                                    title="Свернуть"
                                    placement="top">
                                    <IconButton
                                        onClick={rolleUpClick}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                                <IconButton 
                                    className="mt-1 ml-1"
                                    onClick={() => openEmployeesCardAction(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className="employees__header">
                                <div className="employees__head">
                                    <div className="employees__title">Сотрудники</div>
                                    <Button 
                                        color="primary"
                                        disableElevation
                                        className="employees__btn"
                                        onClick={addEmploeeClick}>
                                        Добавить сотрудника
                                    </Button>
                                </div>
                                <div className="employees__autocomplete">  
                                    <Autocomplete
                                        multiple
                                        options={employeeRoles}
                                        disableCloseOnSelect
                                        className="multiInputAutocomplate cancelDraggable"
                                        getOptionLabel={(option: string) => option}
                                        onChange={(event: React.ChangeEvent<{}>, changeValue: string[]) => onCurrentRoleChange(changeValue)}
                                        defaultValue={roles ? roles : []}
                                        value={roles ? roles : []}
                                        renderOption={(option: string, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option}
                                            </React.Fragment>
                                        )}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={`Фильтр по роли`}
                                                fullWidth
                                                size="small"
                                                className="multiInput"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="employees__activity-mode">
                                    <FormControl 
                                        variant="outlined" 
                                        fullWidth 
                                        size="small"
                                        className="cancelDraggable">
                                        <InputLabel ref={inputLabel}>Режим активности</InputLabel>
                                        <Select
                                            labelWidth={labelWidth}
                                            value={workMode}
                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => 
                                                setWorkMode(event.target.value as WorkMode)
                                            }>
                                            <MenuItem value="">
                                                <em>Все</em>
                                            </MenuItem>
                                            <MenuItem value={WorkMode.Online}>Онлайн</MenuItem>
                                            <MenuItem value={WorkMode.Offline}>Оффлайн</MenuItem>
                                            <MenuItem value={WorkMode.Pushline}>Пуш-лайн</MenuItem>
                                        </Select>
                                    </FormControl>    
                                </div>
                                <div className="employees__search">
                                    <TextField 
                                        label="Поиск" 
                                        type="search" 
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={search}
                                        onChange={(event) => handleSeachChange(event)}
                                        className="cancelDraggable"
                                        InputProps={{
                                            endAdornment: 
                                                <InputAdornment position="end">
                                                    <SearchOutlinedIcon className="employees__search-icon" />
                                                </InputAdornment>,
                                        }}
                                    />
                                </div>
                            </div>
                            <List className="employees__list">
                                {shortEmployeesInfo.map((item) => (
                                    <ListItem 
                                        button
                                        key={`employees__list-${item.employeeId}`}
                                        className={`employees__list-item cancelDraggable ${item.employeeId === employeeId ? "active" : ""}`}
                                        onContextMenu={(event) => contextMenuOpen(event, item)}>
                                        <div 
                                            className="employees__wrap"
                                            onContextMenu={(e)=> e.preventDefault()}
                                            onClick={() => employeeDetailedClick(item.employeeId)}>
                                            <ListItemIcon>
                                                <Avatar className="employees__list-avatar">
                                                    {avatarLetters(item.firstName + " " + item.secondName)}
                                                </Avatar>
                                            </ListItemIcon>
                                            <div 
                                                className={`employees__workMode`}
                                                style={{backgroundColor: employeeWorkMode(item.workMode)}}></div>
                                        </div>
                                        <ListItemText 
                                            primary={`${item.firstName} ${item.secondName}`}
                                            onContextMenu={(e)=> e.preventDefault()} 
                                            onClick={() => employeeDetailedClick(item.employeeId)} />
                                        <ListItemIcon
                                            onClick={(event) => contextMenuOpen(event, item)}>
                                            <MoreVertIcon />
                                        </ListItemIcon>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Drawer>
                    {/* <EmployeeItem 
                        employeeClickOpen={employeeClickOpen} 
                        employeeDetailInfo={employeeDetailInfo} 
                        loading={loading}
                    /> */}
                    {employeeDetailInfo && 
                        <EmployeeModal 
                            employeeOpen={employeeOpen} 
                            employeeClickClose={employeeClickClose} 
                            employeeDetailInfo={employeeDetailInfo}
                            loading={loading} 
                            setLoading={setLoading} 
                            setEmployeeId={setEmployeeId} />
                    }
                    <EmployeeCallModal 
                        open={employeeCallOpen}
                        handleClose={employeeCallClickClose} 
                        employeeDetailInfo={employeeDetailInfo} 
                        loading={loading} 
                        sipAccount={sipAccount} />
                    <ContextMenuCall 
                        contextMenuClose={contextMenuClose}
                        anchorEl={anchorEl} 
                        sipAccount={sipAccount} 
                        employeeClickOpen={employeeClickOpen} 
                        employeeCallClickOpen={employeeCallClickOpen} />
                </Card>
        </Draggable>
    );
}

const employeeWorkMode = (workMode: number) => {
    let status: string;
    switch (workMode) {
        case 0:
            status = "#44b700";
            break;
        case 1:
            status = "#ccc"
            break;
        case 5:
          status = "orange"
          break;
        default:
            status = "#ccc"
    }
    return status;
}

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    openEmployeesCardAction: (openEmployeesCard: boolean) => 
        dispatch(actions.event.openEmployeesCardAction(openEmployeesCard)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    employeesCountAction: (employeesCount: number) => 
        dispatch(actions.event.employeesCountAction(employeesCount))
});

export default connect(mapStateToProps, mapDispatchToProps)(Employees);