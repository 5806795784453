import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../../../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { ActionFromBackendButton } from '../../../../../../store/todo/types';
import { Button } from '@material-ui/core';
import ProjectDiscount from '../../../../../Projects/ProjectDiscount';
import { onlyUnique } from '../../../../../../utils/onlyUnique';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    action: ActionFromBackendButton
}

const ButtonOpenProjectDiscount: React.FunctionComponent<Props> = ({ action, commercialOfferAction, billboardsShortInfo, billboadStatuses }) => {
    const [discountOpen, setDiscountOpen] = useState(false);
    const offerId = action.SystemEventParameters && action.SystemEventParameters.find(item => item.ParameterName === 'OfferId');
    const projectId = action.SystemEventParameters && action.SystemEventParameters.find(item => item.ParameterName === 'ProjectId');

    const currentStatuses = billboadStatuses.filter(status => status.projectId === (projectId && parseInt(projectId.ParameterValue)));
    const bilbordIds = onlyUnique(currentStatuses.map(b => b.geoMarkId));
    const currentBillboards = bilbordIds.map(billboardId => {
        const billboardFilter = billboardsShortInfo.filter(billboard => billboard.geoMarkId === billboardId);
        return billboardFilter[0];
    });

    const discountClickOpen = async () => {
        if(offerId && projectId) {
            await commercialOfferAction(parseInt(offerId.ParameterValue), parseInt(projectId.ParameterValue));
            setDiscountOpen(true);
        }
    };

    const discountClickClose = () => {
        setDiscountOpen(false);
    };
    return (
        <>
            <Button 
                variant="contained" 
                color="primary"
                size="small"
                onClick={discountClickOpen}>
                {action.Text}
            </Button>
            <ProjectDiscount 
                discountOpen={discountOpen} 
                discountClickClose={discountClickClose} 
                currentBillboards={currentBillboards} />
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    billboadStatuses: state.billboards.billboadStatuses,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    commercialOfferAction: (offerId: number, projectId: number) => dispatch(actions.todo.commercialOfferAction(offerId, projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonOpenProjectDiscount);