import React, { Dispatch, useEffect, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { RootState, selectors, actions } from '../../store'
import { Billboard } from '../../store/billboards/types'
import { GeoMarkTimeStatus, ProjectGeoMarkStatus } from 'sedi-webserverproxy'
import { DatesRange } from './InputDatesRange';
import { Badge, Tooltip } from '@material-ui/core';
import moment from 'moment';
import AddGeoMarkArrayConfirm from '../Projects/ProjectSelector/AddGeoMarkArrayConfirm';
import MessageShackbar from './MessageShackbar';
import './bricks.scss';
import { GeoObjectStatus } from '../../RemoteCommands/type';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    statuses: GeoMarkTimeStatus[]
    billboardAB: string,
    projectID: number,
    dates: DatesRange,
    openProjectChange: (openProjectModal: boolean) => void,
}

const ProjectAddSnackbar:React.FC<Props> = (props) => {
    const { 
        statuses, billboardAB, dates, projectID, openProjectChange, setActiveProject, 
        openSelectedBillboardsAction, currentGeoMarkStatus, billboardFilters,
        selectedAreaName, billboardsShortInfo, selectedCityName
    } = props;

    const [open, setOpen] = useState(false);
    const [addGeoMarkOpen, setAddGeoMarkOpen] = useState(false);
    const [geoMarkTimeStatuses, setGeoMarkTimeStatuses] = useState<GeoMarkTimeStatus[]>([]);
    const [GIDs, setGIDs] = useState<string[]>([]);
    const [statusError, setStatusError] = useState({
        message: "",
        error: false
    });
    const [disabled, setDisabled] = useState(false);

    const statusErrorClose = () => {
        setStatusError({
            message: "",
            error: false
        });
    }

    const geoMarkClickClose = () => {
        setAddGeoMarkOpen(false);
    }

    useEffect(() => {
        filterBillboardsEmpty()
    }, [billboardsShortInfo])

    const filterBillboardsEmpty = () => {
        if(billboardFilters && billboardFilters.status === GeoObjectStatus.outOfService) {
            return setOpen(false)
        }
        if(
            billboardsShortInfo.length > 0 && 
            (
                (billboardFilters && billboardFilters.propertyValueIds && billboardFilters.propertyValueIds.length > 0) 
                || (selectedAreaName && selectedAreaName.length > 0) 
                || selectedAreaName === "Все" || (selectedCityName && selectedCityName.length > 0)
            )
        ) {
            setOpen(true)
        } 
        else {
            setOpen(false)
        }
    }

    const filterOffer = (billboard: Billboard) => {
        let sidesAll: string[]
        const filterStatuses = statuses.filter(status => status.geoMarkId === billboard.id)
        if(billboardAB === 'B') {
            sidesAll = billboard.getSideB()
        } else {
            sidesAll = billboard.getSideA()
        }

        for(let status of filterStatuses.filter(status => status.geoMarkStatus === 'Paid' || status.geoMarkStatus === 'Booked')) {
            const index = filterStatuses.indexOf(status, 0);
            if (index > -1) {
                filterStatuses.splice(index, 1);
            }
        }

        if(sidesAll.length === 0) {
            return null
        }

        for(let side of sidesAll) {
            if(!filterStatuses.some(status => status.geoMarkPart === side && status.geoMarkStatus === 'Reserved')) {
                return side
            }
        }
        return sidesAll[0]
    }

    const addProject = () => { 
        if(projectID > 0) {
            setActiveProject(projectID);
            openSelectedBillboardsAction(true);
        }

        if(projectID <= 0) {
            setStatusError({
                message: "Необходимо выбрать проект",
                error: true
            });
            openProjectChange(true);
        } else {
            // setOpen(false)
            // filterBillboardsAction([]);
        }

        let newGeoMarkTimeStatus: GeoMarkTimeStatus[] = [];
        let _GIDs: string[] = [];

        for(let billboard of billboardsShortInfo) {
            // console.log('billboard:', billboard)
            // const sideName = filterOffer(billboard)
            // console.log('sideName:', sideName)
            // if(sideName !== null) {
            //     if (dates.DateFrom === undefined
            //         || dates.DateTo === undefined) {
            //         return;
            //     }
            //     newGeoMarkTimeStatus.push({
            //         dateTimeEnd: moment(dates.DateTo).format('YYYY-MM-DDTHH:mm:ss'),
            //         dateTimeStart: moment(dates.DateFrom).format('YYYY-MM-DDTHH:mm:ss'),
            //         geoMarkId: billboard.id,
            //         geoMarkPart: sideName,
            //         geoMarkStatus: currentGeoMarkStatus.Value as ProjectGeoMarkStatus,
            //         geoMarkTimeStatusId: -1,
            //         projectId: projectID
            //     });
            // }
            if(billboardAB) {
                if(billboardAB === "A" && billboard.side1Fit) {
                    newGeoMarkTimeStatus.push({
                        dateTimeEnd: moment(dates.DateTo).format('YYYY-MM-DDTHH:mm:ss'),
                        dateTimeStart: moment(dates.DateFrom).format('YYYY-MM-DDTHH:mm:ss'),
                        geoMarkId: billboard.geoMarkId,
                        geoMarkPart: "A1",
                        geoMarkStatus: currentGeoMarkStatus.value as ProjectGeoMarkStatus,
                        geoMarkTimeStatusId: -1,
                        projectId: projectID
                    });
                    _GIDs.push(billboard.gid + "A1");
                } else if(billboardAB === "B" && billboard.side2Fit) {
                    newGeoMarkTimeStatus.push({
                        dateTimeEnd: moment(dates.DateTo).format('YYYY-MM-DDTHH:mm:ss'),
                        dateTimeStart: moment(dates.DateFrom).format('YYYY-MM-DDTHH:mm:ss'),
                        geoMarkId: billboard.geoMarkId,
                        geoMarkPart: "B1",
                        geoMarkStatus: currentGeoMarkStatus.value as ProjectGeoMarkStatus,
                        geoMarkTimeStatusId: -1,
                        projectId: projectID
                    });
                    _GIDs.push(billboard.gid + "B1");
                }
            } else {
                if(billboard.side1Fit) {
                    newGeoMarkTimeStatus.push({
                        dateTimeEnd: moment(dates.DateTo).format('YYYY-MM-DDTHH:mm:ss'),
                        dateTimeStart: moment(dates.DateFrom).format('YYYY-MM-DDTHH:mm:ss'),
                        geoMarkId: billboard.geoMarkId,
                        geoMarkPart: "A1",
                        geoMarkStatus: currentGeoMarkStatus.value as ProjectGeoMarkStatus,
                        geoMarkTimeStatusId: -1,
                        projectId: projectID
                    });
                    _GIDs.push(billboard.gid + "A1");
                }
                if(billboard.side2Fit) {
                    newGeoMarkTimeStatus.push({
                        dateTimeEnd: moment(dates.DateTo).format('YYYY-MM-DDTHH:mm:ss'),
                        dateTimeStart: moment(dates.DateFrom).format('YYYY-MM-DDTHH:mm:ss'),
                        geoMarkId: billboard.geoMarkId,
                        geoMarkPart: "B1",
                        geoMarkStatus: currentGeoMarkStatus.value as ProjectGeoMarkStatus,
                        geoMarkTimeStatusId: -1,
                        projectId: projectID
                    });
                    _GIDs.push(billboard.gid + "B1");
                }
            }
        }

        if(newGeoMarkTimeStatus.length > 0 && projectID > 0) {
            setGeoMarkTimeStatuses(newGeoMarkTimeStatus);
            setGIDs(_GIDs);
            setAddGeoMarkOpen(true);
            // SystemEvent.EventSetGeoMarkTimeStatus(newGeoMarkTimeStatus);
        }
        // for(let side of sidesReserved) {
        //     if(projectID <= 0) {
        //         openProjectChange(true);
        //     } else {
        //         setStatus(side as GeoMarkTimeStatus)
        //         setOpen(false)
        //         filterBillboardsAction([])
        //     }
        // }
    }

    const projectBadgeCount = () => {
        let newBillboards = billboardsShortInfo.length;
        if(billboardAB === "A") {
            return newBillboards = billboardsShortInfo.filter(item => item.side1Fit === true).length;
        }
        if(billboardAB === "B") {
            return newBillboards = billboardsShortInfo.filter(item => item.side2Fit === true).length;
        }
        return newBillboards;
    }

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    };

    useEffect(() => {
        setDisabled(currentGeoMarkStatus.value === "Paid" || currentGeoMarkStatus.value === "Sold");
    }, [currentGeoMarkStatus]);

    // const disabledStatus = currentGeoMarkStatus.Value === "Paid" || currentGeoMarkStatus.Value === "Sold";

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className="ProjectAddSnackbar"
                open={open}
                onClose={handleClose}
                message={
                    <Tooltip title={disabled ? 'Проект оплачено или продано' : (projectID > 0 ? 'project-' + projectID : 'Добавить в проект')} placement="top">
                        <Badge 
                            badgeContent={projectBadgeCount()} 
                            color="secondary"
                            max={9999}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                size="small"
                                onClick={() => addProject()}
                                disabled={disabled}
                                // startIcon={disabled && 
                                //     <CircularProgress 
                                //         color="secondary" 
                                //         size={24} 
                                //         thickness={4} 
                                //     />
                                // }
                                >
                                Добавить в проект
                            </Button>
                        </Badge>
                    </Tooltip>
                }
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <AddGeoMarkArrayConfirm 
                duplicateOpen={addGeoMarkOpen} 
                duplicateClickClose={geoMarkClickClose} 
                geoMarkTimeStatuses={geoMarkTimeStatuses} 
                GIDs={GIDs} 
                setOpen={setOpen}
                setDisabled={setDisabled}
            />
            <MessageShackbar 
                message={statusError.message}
                variant="warning"
                messageOpen={statusError.error} 
                vertical="top"
                horizontal="center" 
                messageShackbarClose={statusErrorClose} />
        </>
    )
}

const mapStateToProps = (state: RootState) => {
    let projectID = selectors.todo.getProjectID(state.todo);

    return {
        statuses: selectors.billboards.getAllStatuses(state.billboards),
        billboardAB: state.app.billboardAB,
        projectID,
        currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
        billboardType: state.app.billboardType,
        constructionType: state.app.constructionType,
        selectedAreaName: state.billboards.selectedAreaName,
        billboardsShortInfo: state.billboards.billboardsShortInfo,
        billboardFilters: state.app.billboardFilters,
        selectedCityName: state.billboards.selectedCityName
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    openProjectChange: (openProjectModal: boolean) => dispatch(actions.todo.openProject(openProjectModal)),
    setActiveProject: (projectID: number) => dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(ProjectAddSnackbar)