import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { ActionFromBackendButton } from '../../../../../../store/todo/types';
import Button from '@material-ui/core/Button';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

type Props = 
    ReturnType<typeof mapDispatchToProps> & {
    action: ActionFromBackendButton
}

const ButtonOpenProjectModal: React.FunctionComponent<Props> = (props) => {
    const {
        openProjectChange, 
        action, 
        setActiveProject, 
        openSelectedBillboardsAction, 
        currentCompanyAction
    } = props;

    const projectId = action.SystemEventParameters && action.SystemEventParameters.find(item => item.ParameterName === 'ProjectId');
    const recipientAccountId = action.SystemEventParameters && action.SystemEventParameters.find(item => item.ParameterName === 'RecipientAccountId');
    
    if(projectId && recipientAccountId) {
        const currentProjectSelected = () => {
            currentCompanyAction(parseInt(recipientAccountId.ParameterValue));
            setActiveProject(parseInt(projectId.ParameterValue));
            openSelectedBillboardsAction(true);
        }
        return (
            <Button 
                variant="contained" 
                color="primary"
                size="small"
                onClick={currentProjectSelected}>
                {action.Text}
            </Button>
        )
    } else {
        return (
            <Button 
                variant="contained" 
                color="primary"
                size="small"
                startIcon={<CreateNewFolderIcon />}
                onClick={() => openProjectChange(true)}>
                <span className="buttonText">{action.Text}</span>
            </Button>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    openProjectChange: (openProjectModal: boolean) => dispatch(actions.todo.openProject(openProjectModal)),
    setActiveProject: (projectID: number) => dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    currentCompanyAction: (currentCompanyAccountId: number) => dispatch(actions.todo.currentCompanyAction(currentCompanyAccountId)),
});

export default connect(null, mapDispatchToProps)(ButtonOpenProjectModal);