import React, { FunctionComponent, Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Action } from 'redux';
import { ProjectGeoMarkStatus } from 'sedi-webserverproxy';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './BillboardStatusColor.scss';
import { getHighlightStyleFunc } from '../../../utils/getHighlightStyleFunc';

type Props = 
    ReturnType<typeof mapDispatchToProps>
    & ReturnType<typeof mapStateToProps>;

const BillboardStatusColor:FunctionComponent<Props> = (props) => {
    const { reservedChecked, bookedChecked, soldChecked, paidChecked, freeChecked, statusCheckboxChange } = props;
    return (
        <Grid container spacing={0}>
            <Grid item xs={2} className="billboard-status">
                <FormControlLabel
                    style={getHighlightStyleFunc(ProjectGeoMarkStatus._FreeOnlyForFrontend)}
                    className="billboard-status__label border border-left-0"
                    control={
                    <Checkbox
                        checked={freeChecked}
                        onChange={(event) => statusCheckboxChange(reservedChecked, bookedChecked, soldChecked, paidChecked, event.target.checked)}
                        color="default"
                        className="billboard-status__checkbox"
                    />
                    }
                    label="Cвободно"
                />
            </Grid>
            <Grid item xs={2} className="billboard-status">
                <FormControlLabel
                    style={getHighlightStyleFunc(ProjectGeoMarkStatus.Reserved)}
                    className="billboard-status__label"
                    control={
                    <Checkbox
                        checked={reservedChecked}
                        onChange={(event) => statusCheckboxChange(event.target.checked, bookedChecked, soldChecked, paidChecked, freeChecked)}
                        color="default"
                        className="billboard-status__checkbox"
                    />
                    }
                    label="Резерв"
                />
            </Grid>
            <Grid item xs={2} className="billboard-status">
                <FormControlLabel
                    style={getHighlightStyleFunc(ProjectGeoMarkStatus.Booked)}
                    className="billboard-status__label"
                    control={
                    <Checkbox
                        checked={bookedChecked}
                        onChange={(event) => statusCheckboxChange(reservedChecked, event.target.checked, soldChecked, paidChecked, freeChecked)}
                        color="default"
                        className="billboard-status__checkbox"
                    />
                    }
                    label="Бронь"
                />
            </Grid>
            <Grid item xs={2} className="billboard-status">
                <FormControlLabel
                    style={{backgroundColor: '#1DE9B6'}}
                    className="billboard-status__label"
                    control={
                    <Checkbox
                        checked={soldChecked}
                        onChange={(event) => statusCheckboxChange(reservedChecked, bookedChecked, event.target.checked, paidChecked, freeChecked)}
                        color="default"
                        className="billboard-status__checkbox"
                    />
                    }
                    label="Продано"
                />
            </Grid>
            <Grid item xs={2} className="billboard-status">
                <FormControlLabel
                    style={getHighlightStyleFunc(ProjectGeoMarkStatus.Paid)}
                    className="billboard-status__label"
                    control={
                    <Checkbox
                        checked={paidChecked}
                        onChange={(event) => statusCheckboxChange(reservedChecked, bookedChecked, soldChecked, event.target.checked, freeChecked)}
                        color="default"
                        className="billboard-status__checkbox"
                    />
                    }
                    label="Оплачено"
                />
            </Grid>
        </Grid>
    );
};

function getHighlightLabel(status: ProjectGeoMarkStatus) : string {
    if (status === ProjectGeoMarkStatus.Reserved){
        return ProjectGeoMarkStatus.Reserved;
    }
    else if (status === ProjectGeoMarkStatus.Booked){
        return ProjectGeoMarkStatus.Booked;
    }
    else if (status === ProjectGeoMarkStatus.Paid){
        return ProjectGeoMarkStatus.Paid;
    }

    return 'Free';
}


const mapStateToProps = (state: RootState) => ({
    reservedChecked: state.app.reservedChecked,
    bookedChecked: state.app.bookedChecked,
    soldChecked: state.app.soldChecked,
    paidChecked: state.app.paidChecked,
    freeChecked: state.app.freeChecked
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    statusCheckboxChange: (reservedChecked: boolean, bookedChecked: boolean, soldChecked: boolean, paidChecked: boolean, freeChecked: boolean) => 
    dispatch(actions.app.statusCheckbox(reservedChecked, bookedChecked, soldChecked, paidChecked, freeChecked))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillboardStatusColor);