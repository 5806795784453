import React, { Dispatch } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { RootState, selectors } from '../../../store';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GetGeoMarkTariffs } from 'sedi-webserverproxy';
import { EventBuilder } from '../../../utils/eventHelpers';
import { ServerProxy } from '../../../RemoteCommands/ServerProxy';
import { TariffCost } from '../TariffTable';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
    deletePriceOpen: boolean;
    deletePriceCloseClick: () => void;
    billboardTariffs: GetGeoMarkTariffs[],
    selectedDelete: TariffCost,
    text: string,
}

const DeletePriceModal:React.FC<Props> = (props) => {
    const { 
        deletePriceOpen,  
        deletePriceCloseClick,
        selectedDelete,
        text,
        projectID
    } = props;

    const clearPriceClick = () => {
        if(selectedDelete === "print") {
            const builder = new EventBuilder(`EventSetAdditionalTariffCosts`);
            builder.AddParameter('ProjectId', projectID);
            builder.AddParameter('PrintCost', '0');
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        } else if(selectedDelete === "additionalMontage") {
            const builder = new EventBuilder(`EventSetAdditionalTariffCosts`);
            builder.AddParameter('ProjectId', projectID);
            builder.AddParameter('AdditionalInstallationCost', '0');
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        } else if(selectedDelete === "montage") {
            const builder = new EventBuilder(`EventSetAdditionalTariffCosts`);
            builder.AddParameter('ProjectId', projectID);
            builder.AddParameter('InstallationCost', '0');
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        } else {
            return;
        }

        if(projectID > 0) {
            const builder = new EventBuilder(`EventGetCommercialOfferDiscounts`);
            builder.AddParameter('ProjectId', projectID);
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }
        deletePriceCloseClick();
    }

    return (
        <Dialog
            open={deletePriceOpen}
            onClose={deletePriceCloseClick}>
            <DialogTitle>Удалить цену за {text}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Вы действительно хотите удалить цену за {text}?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={deletePriceCloseClick} color="primary">
                Отмена
            </Button>
            <Button color="secondary" autoFocus onClick={clearPriceClick}>
                Удалить
            </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state: RootState) => ({
    projectID: selectors.todo.getProjectID(state.todo),
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DeletePriceModal);