import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Translate, getTranslate } from 'react-localize-redux';
import {
  ClientInfo,
  EmailInfo,
  PhoneInfo,
  EmployeeBrandsInfo,
  RolesInfo,
  Project,
} from 'sedi-webserverproxy';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Action } from 'redux';
import { RootState, selectors, actions } from '../store';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Grid from '@material-ui/core/Grid';
import TextField from '../components/bricks/TextField';
import InputPhoneFormated from '../components/bricks/inputPhoneFormated';
import ButtonMaterial from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Message from '../components/bricks/Message';
import { validateEmail } from '../utils/validateEmail';
import { formatedPhone } from '../utils/format';
import ButtonsGroup from '@material-ui/core/ButtonGroup';
import CustomerRelations from '../components/CustomersList/components/CustomerRelations/CustomerRelations';
import CompanyAutocomplete from '../components/bricks/CompanyAutocomplete';
import './ClientModal.scss';
import BrandsAutocomplate from '../components/bricks/BrandsAutopomplete';
import RolesAutocomplate from '../components/bricks/RolesAutocomplete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { Fab, Tooltip } from '@material-ui/core';
import { getWebServerProxy } from '../WebServerProxy';
import AddIcon from '@material-ui/icons/Add';
import AgenciesModal from '../components/bricks/AgenciesModal';
import AgenciesAutocomplete from '../components/bricks/AgenciesAutocomplete';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import AddressCompany from '../components/bricks/AddressCompany';
import { ServerProxy } from '../RemoteCommands/ServerProxy';
import { ClientItem, ClientType, ICompanyInfo } from '../store/customers/types';
import { EventBuilder } from '../utils/eventHelpers';
import { AgencyInfo } from '../store/event/types';

enum InputFocus {
  None,
  Name,
  Phone,
  Email,
}

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    show: boolean;
    onClose: () => void;
    clientInfo: ClientInfo;
    clientInfoByInn?: ClientInfo;
    addButtonHidden?: boolean | undefined;
    addCompanyClient?: (clientInfo: ClientInfo) => void;
    customerCompanyId?: number;
    deletedCustomers?: ClientInfo[];
  };

type State = {
    clientInfo: ClientInfo;
    phonesIsValid: boolean[];
    emailIsValid: boolean[];
    validated: boolean;
    focus: InputFocus;
    customersInCompany: ClientInfo[];
    openCompanyCustomer: ClientInfo | undefined;
    waitForClose: boolean;
    deletedCustomers: ClientInfo[];
    agenciesOpen: boolean;
    officialAddress: string;
    officeAddress: string;
    postalAddress: string;
    addCompanyOrClient: boolean
};

export class ClientModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (
      props.clientInfo.phones === null ||
      props.clientInfo.phones.length === 0
    ) {
      props.clientInfo.phones = [new PhoneInfo()];
    }
    if (
      props.clientInfo.emails === null ||
      props.clientInfo.emails.length === 0
    ) {
      props.clientInfo.emails = [new EmailInfo()];
    }

    this.state = {
        clientInfo: props.clientInfo,
        validated: false,
        focus: InputFocus.Name,
        phonesIsValid: [],
        emailIsValid: [],
        customersInCompany: [],
        openCompanyCustomer: undefined,
        waitForClose: false,
        deletedCustomers: [],
        agenciesOpen: false,
        officialAddress: '',
        officeAddress: '',
        postalAddress: '',
        addCompanyOrClient: false,
    };
  }

    resetState() {
        this.props.currentCompanyAction(null);
        this.setState({
            clientInfo: new ClientInfo(),
            validated: false,
            focus: InputFocus.Name,
            phonesIsValid: [],
            emailIsValid: [],
            customersInCompany: [],
            openCompanyCustomer: undefined,
            waitForClose: false,
            deletedCustomers: [],
            agenciesOpen: false,
            officialAddress: '',
            officeAddress: '',
            postalAddress: '',
            addCompanyOrClient: true,
        });
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { clientInfo } = this.state;

        if (
            this.props.waitForSave === false &&
            prevProps.waitForSave === true &&
            this.props.saveError === undefined &&
            this.state.waitForClose === true
        ) {
            this.notCloseModal();
            this.props.resetSaveError();
        }

        if (this.props.show === true && prevProps.show === false) {
            this.setState({ focus: InputFocus.Name });
            this.props.resetSaveError();
        }

        // if (this.props.clientInfo !== prevProps.clientInfo) {
        //   // ! обязательно копируем, чтобы не модифицировать в store
        //   let newClient = this.props.clientInfo.getCopy();
        //   if (newClient.emails.length === 0) {
        //     newClient.emails.push(new EmailInfo());
        //   }

        //   const phonesIsValid = newClient.phones.map(
        //     (phone) => formatedPhone(phone.phoneNumber).isValid
        //   );
        //   const emailIsValid = newClient.emails.map(
        //     (email) => validateEmail(email.email).isValid
        //   );

        //   this.setState({
        //     clientInfo: newClient,
        //     phonesIsValid,
        //     emailIsValid,
        //     customersInCompany: [],
        //   });

        //   this.props.resetSaveError();
        // }

        // if (
        //     this.props.currentCompany !== prevProps.currentCompany &&
        //     this.props.currentCompany === null
        // ) {

        //     let newClient = (prevProps.currentCompany !== null // ???
        //         ? prevProps.currentCompany
        //         : this.props.clientInfo
        //     ).getCopy();

        //     newClient.name = '';

        //     this.setState({
        //         clientInfo: newClient,
        //     });
        // }

        if (clientInfo.id !== prevState.clientInfo.id) {
            const companyInfo = {
                agencyId: 0,
                officialAddress: {
                addressString: '',
                },
                officeAddress: {
                addressString: '',
                },
                postalAddress: {
                addressString: '',
                },
            };
            this.props.companyInfoAction(companyInfo);
            if(clientInfo.id > 0 && clientInfo.isLegalEntity) {
                const builderCompanyInfo = new EventBuilder(`EventUserGetCompanyInfo`);
                builderCompanyInfo.AddParameter('CompanyId', clientInfo.id);
                ServerProxy.SendSystemEvent(builderCompanyInfo.GetSystemEvent());
            }
            const agencyInfo = {
                AccountId: 0,
                AgencyId: 0,
                AgencyMemberIds: [],
                AgencyName: '',
                AgencyDiscount: 0,
            };
            this.props.currentAgencyAction(agencyInfo);
        }
    }

  notCloseModal() {
    const notClose = window.location.pathname === '/customers';
    if ((notClose && this.state.clientInfo.id > 0) || !notClose) {
      this.props.onClose();
    } else {
      let client = new ClientInfo();
      client.isLegalEntity = this.state.clientInfo.isLegalEntity;
      this.setState({
        clientInfo: client,
      });
    }
  }

  onNameChanged(name: string) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.name = name;

    this.setState({ clientInfo: clientInfo });
  }

  onCurrentCompanyChange(currentCompany: ClientInfo | null) {
    if (
      currentCompany !== null &&
      (currentCompany.phones === null || currentCompany.phones.length === 0)
    ) {
      currentCompany.phones = [new PhoneInfo()];
    }

    if (
      currentCompany !== null &&
      (currentCompany.emails === null || currentCompany.emails.length === 0)
    ) {
      currentCompany.emails = [new EmailInfo()];
    }

    if (currentCompany !== null) {
      this.setState({ clientInfo: currentCompany });
    }
    
    if(currentCompany) {
        const clientInfo: ClientItem = {
            accountId: currentCompany.accountId,
            name: currentCompany.name,
            customerType: ClientType.company,
        }
        this.props.clientItemAction(clientInfo);
    }
  }

  onCurrentBrandChange(brands: EmployeeBrandsInfo[]) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.employeeBrands = brands;
    this.setState({ clientInfo: clientInfo });
  }

  onCurrentRoleChange(roles: RolesInfo[]) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.roles = roles;
    this.setState({ clientInfo: clientInfo });
  }

  onFirstNameChanged(name: string) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.firstName = name;
    if (clientInfo.firstName === '') {
      this.setState({ clientInfo: this.props.clientInfo });
    } else {
      this.setState({ clientInfo: clientInfo });
    }
  }
  onSecondNameChanged(name: string) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.secondName = name;
    this.setState({ clientInfo: clientInfo });
  }
  onPatronymicChanged(patronymic: string) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.patronymic = patronymic;
    this.setState({ clientInfo: clientInfo });
  }
  extensionNumberChange(extensionNumber: string, i: number) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.phones[i].extensionNumber = extensionNumber;
    this.setState({ clientInfo: clientInfo });
  }

  onPhoneChanged(phone: string, isValid: boolean, i: number) {
    if (
      this.state.clientInfo.phones === null ||
      this.state.clientInfo.phones.length === 0
    ) {
      this.state.clientInfo.phones = [new PhoneInfo()];
    }
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.phones[i].phoneNumber = phone;
    let phonesIsValid = this.state.phonesIsValid;
    phonesIsValid[i] = isValid;
    this.setState({
      clientInfo: clientInfo,
      phonesIsValid,
      focus: InputFocus.None,
    });
  }
  onDeletePhone(phone: string, i: number) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.phones.splice(i, 1);
    let phonesIsValid = this.state.phonesIsValid;
    phonesIsValid.splice(i, 1);
    this.setState({
      clientInfo: clientInfo,
      phonesIsValid,
      focus: InputFocus.None,
    });
  }
  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return -Math.floor(Math.random() * (max - min)) * max;
  }
  onAddNewPhone() {
    let getRandomId = this.getRandomInt(1, 1000);
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.phones.push({
      phoneId: getRandomId,
      phoneNumber: '',
      extensionNumber: '',
    });
    let phonesIsValid = this.state.phonesIsValid;
    phonesIsValid.push(false);
    this.setState({
      clientInfo: clientInfo,
      phonesIsValid,
      focus: InputFocus.Phone,
    });
  }
  onEmailChanged(email: string, isValid: boolean, i: number) {
    if (
      this.state.clientInfo.emails === null ||
      this.state.clientInfo.emails.length === 0
    ) {
      this.state.clientInfo.emails = [new EmailInfo()];
    }
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.emails[i].email = email;
    let emailIsValid = this.state.emailIsValid;
    emailIsValid[i] = isValid;
    this.setState({
      clientInfo: clientInfo,
      emailIsValid,
      focus: InputFocus.None,
    });
  }
  onDeleteEmail(email: string, i: number) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.emails.splice(i, 1);
    this.setState({
      clientInfo: clientInfo,
      focus: InputFocus.None,
    });
  }
  onAddNewEmail() {
    let getRandomId = this.getRandomInt(1, 1000);
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.emails.push({ emailId: getRandomId, email: '' });
    this.setState({
      clientInfo: clientInfo,
      focus: InputFocus.Email,
    });
  }
  onINNChanged(inn: string) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.inn = inn;
    this.setState({ clientInfo: clientInfo });
  }

  IsEmailValid(email: string) {
    if (email === '') return true;
    return this.state.clientInfo.emails.some(
      (email) => validateEmail(email.email).isValid
    );
  }

  IsPhoneValid(phone: string) {
    if (phone === '') return true;
    return this.state.clientInfo.phones.some(
      (phone) => formatedPhone(phone.phoneNumber).isValid
    );
  }

  companyCustomerModalShow() {
    const customer = new ClientInfo();
    customer.isLegalEntity = !this.state.clientInfo.isLegalEntity;
    this.setState({
      openCompanyCustomer: customer,
    });
  }

  companyCustomerModalClose() {
    this.setState({
      openCompanyCustomer: undefined,
    });
  }

  handleChangeRelations(newRelations: ClientInfo[]) {
    const { clientInfo } = this.state;

    if (clientInfo.isLegalEntity) {
      this.setState({ customersInCompany: newRelations });
    } else {
      clientInfo.customerCompanyId =
        newRelations.length > 0 ? newRelations[0].id : -1;
      this.setState({ clientInfo });
    }
  }

  handleDeletedRelation(deletedRelation: ClientInfo) {
    const { deletedCustomers } = this.state;

    deletedCustomers.push(deletedRelation);
    this.setState({ deletedCustomers });
  }

  onNoteChange(description: string) {
    let clientInfo = this.state.clientInfo!.getCopy();
    clientInfo.description = description;
    this.setState({ clientInfo: clientInfo });
  }

  onSaving(e: any) {
    const { clientInfo, customersInCompany, deletedCustomers } = this.state;
    const {
      addCompanyClient,
      currentAgency,
      currentCompany,
      companyInfo,
    } = this.props;

    if(clientInfo.name.includes('"')) {
        alert("Вы не можете использовать кавычки в имени компании");
        return;
    }

    const emailIsValid =
      this.state.clientInfo.emails.length === 0 ||
      this.state.clientInfo.emails.some((email) =>
        this.IsEmailValid(email.email)
      );

    /**
     * Проверка на валидность PhoneTextField
     */
    const phonesIsValid =
      this.state.clientInfo.phones.length === 0 ||
      this.state.clientInfo.phones.some((phone) =>
        this.IsPhoneValid(phone.phoneNumber)
      );

    if (!emailIsValid || !phonesIsValid) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    if (addCompanyClient && this.props.customerCompanyId !== undefined) {
      this.state.clientInfo.customerCompanyId = this.props.customerCompanyId;
      addCompanyClient(clientInfo);
    }

    for (let customer of deletedCustomers) {
      customer.customerCompanyId = null;
      this.props.saveClient(customer, customersInCompany);
    }

    this.setState({
      waitForClose: true,
    });

    if (
      this.state.clientInfo.phones[0] &&
      this.state.clientInfo.phones[0].phoneNumber === ''
    ) {
      this.state.clientInfo.phones.length = 0;
    }

    this.props.saveClient(this.state.clientInfo, customersInCompany);

    const addressOfficialObj = {
      addressString: this.state.officialAddress,
    };

    if(clientInfo.id > 0) {
        const builder = new EventBuilder(`EventChangeCompanyAddress`);
        builder.AddParameter('CompanyId', clientInfo.id);
        builder.AddParameter('AddressType', 'Official');
        builder.AddJsonParameter('Address', addressOfficialObj);
        ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        const addressOfficeObj = {
            addressString: this.state.officeAddress,
        };
        builder.AddParameter('CompanyId', clientInfo.id.toString());
        builder.AddParameter('AddressType', 'Office');
        builder.AddJsonParameter('Address', addressOfficeObj);
        ServerProxy.SendSystemEvent(builder.GetSystemEvent());
    
        const addressPostalObj = {
            addressString: this.state.postalAddress,
        };
        builder.AddParameter('CompanyId', clientInfo.id.toString());
        builder.AddParameter('AddressType', 'Postal');
        builder.AddJsonParameter('Address', addressPostalObj);
        ServerProxy.SendSystemEvent(builder.GetSystemEvent());
    }

    if(currentAgency && currentCompany) {
        const builderAgencyDel = new EventBuilder(`EventChangeAgencyInfo`);
        builderAgencyDel.AddParameter('AgencyId', companyInfo.agencyId.toString());
        builderAgencyDel.AddParameter('AgencyName', currentAgency.AgencyName);
        builderAgencyDel.AddParameter('CommandType', 'DeleteCompaniesFromAgency');
        builderAgencyDel.AddParameter(
            'CompanyIds',
            JSON.stringify([currentCompany.id])
        );
        ServerProxy.SendSystemEvent(builderAgencyDel.GetSystemEvent());

        const builderAgency = new EventBuilder(`EventChangeAgencyInfo`);
        builderAgency.AddParameter('AgencyId', currentAgency.AgencyId.toString());
        builderAgency.AddParameter('AgencyName', currentAgency.AgencyName);
        builderAgency.AddParameter('CommandType', 'AddCompaniesToAgency');
        builderAgency.AddParameter(
            'CompanyIds',
            JSON.stringify([currentCompany.id])
        );
        ServerProxy.SendSystemEvent(builderAgency.GetSystemEvent());
    }

    setTimeout(() => {
        if(clientInfo.id > 0) {
            const builderCompanyInfo = new EventBuilder(`EventUserGetCompanyInfo`);
            builderCompanyInfo.AddParameter('CompanyId', clientInfo.id.toString());
            ServerProxy.SendSystemEvent(builderCompanyInfo.GetSystemEvent());
        }
    }, 700);
  }

  fetchProjects = async () => {
    const { setAllProjects, setActiveProject } = this.props;
    const { accountId } = this.state.clientInfo;
    if (accountId <= 0) {
      setAllProjects([]);
      return;
    }
    const response = await getWebServerProxy().ToDo.GetProjects({
      customerAccountId: accountId,
    });
    if (response.Success === false) {
      alert(response.Message!);
    }

    // setWaiting(false);

    const fetchedProjects = response.Result || [];
    setAllProjects(fetchedProjects || []);
    if (fetchedProjects.length === 1) {
      setActiveProject(fetchedProjects[0].projectId);
    }
    if (fetchedProjects.length === 0) {
      const projectName = 'project ' + new Date().toLocaleString();
      const response = await getWebServerProxy().ToDo.CreateProjectByTemplate(
        accountId,
        projectName
      );

      if (response.Success === false) {
        alert(response.Message!);
      } else {
        setActiveProject(response.Result!.id);
        this.fetchProjects();
      }
    }
  };

  openProjectModal = async () => {
    const {
      currentCompanyAction,
      openSelectedBillboardsAction,
      onClose,
    } = this.props;
    this.fetchProjects();
    currentCompanyAction(this.state.clientInfo);
    openSelectedBillboardsAction(true);
    onClose();
  };

  openProjectClick = async () => {
    const {
      currentCompany,
      currentCompanyAction,
      openProjectChange,
    } = this.props;
    if (currentCompany) {
      currentCompanyAction(this.state.clientInfo);
    }
    openProjectChange(true);
  };

    clientModalClose = () => {
        this.props.onClose();
        this.setState({
            clientInfo: this.props.clientInfo
        })
    }

  render() {
    if (!this.state.clientInfo) return null;

    const {
      waitForSave,
      saveError,
      show,
      clientInfoByInn,
      currentCompany,
      addButtonHidden,
    } = this.props;

    const {
      officialAddress,
      officeAddress,
      postalAddress,
    } = this.props.companyInfo;

    const {
      name,
      firstName,
      inn,
      isLegalEntity,
      id: clientId,
      description,
    } = this.state.clientInfo;

    let title: string = String(
      isLegalEntity
        ? this.props.translate('ClientDetailCompany')
        : this.props.translate('ClientDetailClient')
    );

    const phones =
      this.state.clientInfo.phones && this.state.clientInfo.phones.length > 0
        ? this.state.clientInfo.phones
        : [{ phoneId: 0, phoneNumber: '', extensionNumber: '' } as PhoneInfo];
    const emails =
      this.state.clientInfo.emails && this.state.clientInfo.emails.length > 0
        ? this.state.clientInfo.emails
        : [{ emailId: 0, email: '' } as EmailInfo];

    return (
      <>
        <Dialog
          open={show}
          onClose={this.clientModalClose}
          className="client-modal"
        >
          <DialogTitle className="pb-1">
            <IconButton
              style={{ position: 'absolute', top: '5px', right: '5px' }}
              onClick={this.clientModalClose}
            >
              <CloseIcon />
            </IconButton>
            {title} #{clientId} {this.state.clientInfo.name}
          </DialogTitle>

          <form noValidate autoComplete="off">
            <fieldset disabled={waitForSave}>
              <DialogContent className="pt-1 pb-1">
                <Grid container spacing={1}>
                  {/* Физ Лицо */}
                  {!isLegalEntity && (
                    <>
                      <Grid item xs={12}>
                        <div className="companyAutocomplete">
                          <CompanyAutocomplete
                            currentValue={currentCompany}
                            value={firstName}
                            onChange={(currentCompany) =>
                              this.onCurrentCompanyChange(
                                currentCompany !== null ? currentCompany : null
                              )
                            }
                            label={<Translate id="ClientDetailName" />}
                            onChangeTextField={(event) =>
                              this.onFirstNameChanged(event.target.value)
                            }
                            autoFocus={this.state.focus === InputFocus.Name}
                            IsLegalEntity={isLegalEntity}
                            addCompanyOrClient={this.state.addCompanyOrClient}
                          />

                          <Tooltip
                            title="Добавить нового клиента"
                            placement="top"
                          >
                            <IconButton
                              className="companyAutocomplete__label"
                              onClick={this.resetState.bind(this)}
                            >
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={<Translate id="ClientDetailSecondName" />}
                          value={this.state.clientInfo.secondName}
                          onChange={(e) =>
                            this.onSecondNameChanged(e.target.value)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {phones.map((p, i) => (
                          <div
                            className="phone-row"
                            key={'phones-customer-' + p.phoneId}
                          >
                            <InputPhoneFormated
                              key={p.phoneId}
                              label={<Translate id="ClientDetailPhone" />}
                              value={p.phoneNumber}
                              onPhoneChange={(e) =>
                                this.onPhoneChanged(e.value, e.isValid, i)
                              }
                              autoFocus={this.state.focus === InputFocus.Phone}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {i !== 0 && (
                                      <IconButton
                                        edge="end"
                                        onClick={(e: any) =>
                                          this.onDeletePhone(e.target.value, i)
                                        }
                                      >
                                        <CloseIcon fontSize="small" />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              label="Добавочный"
                              variant="outlined"
                              className="phone-row__item"
                              value={p.extensionNumber ? p.extensionNumber : ''}
                              onChange={(event) =>
                                this.extensionNumberChange(
                                  event.target.value,
                                  i
                                )
                              }
                            />
                          </div>
                        ))}
                        <ButtonMaterial
                          className="mt-1 mb-1"
                          variant="contained"
                          color="primary"
                          onClick={this.onAddNewPhone.bind(this)}
                        >
                          <Translate id="ClientDetailAddPhone" />
                        </ButtonMaterial>
                      </Grid>
                      <Grid item xs={12}>
                        <CompanyAutocomplete
                          currentValue={currentCompany}
                          value={emails.length > 0 ? emails[0].email : ''}
                          onChange={(currentCompany) =>
                            this.onCurrentCompanyChange(
                              currentCompany !== null ? currentCompany : null
                            )
                          }
                          label="Email"
                          onEmailChange={(e) =>
                            this.onEmailChanged(e.value, e.isValid, 0)
                          }
                          type="email"
                          IsLegalEntity={isLegalEntity}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BrandsAutocomplate
                          label="Бренд"
                          onChange={(changeValue) =>
                            this.onCurrentBrandChange(changeValue)
                          }
                          currentValue={this.state.clientInfo.employeeBrands}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <RolesAutocomplate
                          label="Должность"
                          onChange={(changeValue) =>
                            this.onCurrentRoleChange(changeValue)
                          }
                          currentValue={this.state.clientInfo.roles}
                          shrink={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Примечание"
                          multiline
                          fullWidth
                          rowsMax="6"
                          value={description}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.onNoteChange(event.target.value)}
                        />
                      </Grid>
                    </>
                  )}

                  {/* Юр Лицо */}
                  {isLegalEntity && (
                    <>
                      <Grid item xs={12}>
                        <div className="companyAutocomplete">
                          <CompanyAutocomplete
                            currentValue={currentCompany}
                            value={name}
                            onChange={(currentCompany) =>
                              this.onCurrentCompanyChange(
                                currentCompany !== null ? currentCompany : null
                              )
                            }
                            label={<Translate id="ClientDetailName" />}
                            onChangeTextField={(event) =>
                              this.onNameChanged(event.target.value)
                            }
                            autoFocus={this.state.focus === InputFocus.Name}
                            addCompanyOrClient={this.state.addCompanyOrClient}
                            IsLegalEntity={true}
                          />

                          <Tooltip
                            title="Добавить новую компанию"
                            placement="top"
                          >
                            <IconButton
                              className="companyAutocomplete__label"
                              onClick={this.resetState}
                            >
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>

                        {clientInfoByInn &&
                        clientInfoByInn.name !== this.state.clientInfo.name ? (
                          <>
                            <ButtonsGroup
                              className="mt-1 mb-1"
                              variant="contained"
                              color="primary"
                              aria-label="full-width button group"
                            >
                              <ButtonMaterial
                                variant="outlined"
                                color="primary"
                                disabled
                              >
                                <Translate id="ClientDetailFoundName" />
                              </ButtonMaterial>

                              <ButtonMaterial
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  this.onNameChanged(clientInfoByInn!.name)
                                }
                              >
                                {clientInfoByInn.name}
                              </ButtonMaterial>
                            </ButtonsGroup>
                          </>
                        ) : null}
                      </Grid>

                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={6}>
                          <CompanyAutocomplete
                            currentValue={currentCompany}
                            value={inn}
                            onChange={(currentCompany) =>
                              this.onCurrentCompanyChange(
                                currentCompany !== null ? currentCompany : null
                              )
                            }
                            label={<Translate id="ClientDetailINN" />}
                            onChangeTextField={(event) =>
                              this.onINNChanged(event.target.value)
                            }
                            type="INN"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <div className="agenciesAutocomplete">
                            <AgenciesAutocomplete
                              label="Агентства"
                              autoFocus={false}
                            />
                            <Tooltip
                              title="Открыть Информацию по агентствам"
                              placement="top"
                            >
                              <IconButton
                                className="agenciesAutocomplete__label"
                                onClick={() =>
                                  this.props.agenciesOpenAction(true)
                                }
                              >
                                <OpenInBrowserIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        {phones.map((p, i) => (
                          <InputPhoneFormated
                            key={'phones-company-' + p.phoneId}
                            label={<Translate id="ClientDetailPhone" />}
                            value={p.phoneNumber}
                            onPhoneChange={(e) =>
                              this.onPhoneChanged(e.value, e.isValid, i)
                            }
                            fullWidth
                            autoFocus={this.state.focus === InputFocus.Phone}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={(e: any) =>
                                      this.onDeletePhone(e.target.value, i)
                                    }
                                  >
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                  <Tooltip
                                    title={
                                      <Translate id="ClientDetailAddPhone" />
                                    }
                                    placement="top"
                                  >
                                    <IconButton
                                      edge="end"
                                      onClick={this.onAddNewPhone.bind(this)}
                                    >
                                      <AddIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        ))}
                      </Grid>

                      <Grid item xs={12}>
                        {emails.map((email, i) => (
                          <CompanyAutocomplete
                            key={'emails-company' + email.emailId}
                            currentValue={currentCompany}
                            value={email.email}
                            onChange={(currentCompany) =>
                              this.onCurrentCompanyChange(
                                currentCompany !== null ? currentCompany : null
                              )
                            }
                            label="Email"
                            onEmailChange={(e) =>
                              this.onEmailChanged(e.value, e.isValid, i)
                            }
                            type="email"
                            onClick={(e) =>
                              this.onDeleteEmail(e.target.value, i)
                            }
                            onAddNewEmail={this.onAddNewEmail.bind(this)}
                          />
                        ))}
                      </Grid>

                      <Grid item xs={12}>
                        <AddressCompany
                          label="Адрес Офиса"
                          onChange={(value: string) =>
                            this.setState({ officialAddress: value })
                          }
                          defaultValue={officialAddress.addressString}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AddressCompany
                          label="Адрес Юридический"
                          onChange={(value: string) =>
                            this.setState({ officeAddress: value })
                          }
                          defaultValue={officeAddress.addressString}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AddressCompany
                          label="Адрес Почтовый"
                          onChange={(value: string) =>
                            this.setState({ postalAddress: value })
                          }
                          defaultValue={postalAddress.addressString}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Примечание"
                          multiline
                          fullWidth
                          rowsMax="6"
                          value={description}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.onNoteChange(event.target.value)}
                        />
                      </Grid>
                    </>
                  )}
                  {addButtonHidden !== true && (
                    <Grid item xs={12}>
                      <CustomerRelations
                        isLegalEntity={isLegalEntity}
                        customerID={clientId}
                        onChange={(newRelations) =>
                          this.handleChangeRelations(newRelations)
                        }
                        onDeleted={(deletedRelation) =>
                          this.handleDeletedRelation(deletedRelation)
                        }
                        openCompanyCustomer={this.state.openCompanyCustomer}
                        companyCustomerModalClose={this.companyCustomerModalClose.bind(
                          this
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                {saveError !== undefined &&
                  this.state.waitForClose === true && (
                    <Message message={saveError} variant="error" />
                  )}
              </DialogContent>
              <DialogActions
                className={`pt-2 pl-4 pr-4 pb-4 ${
                  addButtonHidden !== true
                    ? 'justify-content-between'
                    : 'justify-content-flex-end'
                }`}
              >
                <div>
                  {
                    addButtonHidden !== true && (
                      <Tooltip
                        title={
                          isLegalEntity
                            ? 'Добавить сотрудника'
                            : 'Добавить компанию'
                        }
                        placement="top"
                      >
                        <Fab
                          onClick={this.companyCustomerModalShow.bind(this)}
                          size="medium"
                          color="default"
                          className="mr-3"
                        >
                          {isLegalEntity ? (
                            <PersonAddIcon />
                          ) : (
                            <LocationCityIcon />
                          )}
                        </Fab>
                      </Tooltip>
                    )
                    // <ButtonMaterial
                    //     variant="contained"
                    //     onClick={this.companyCustomerModalShow.bind(this)}>
                    //     {isLegalEntity ? 'Добавить сотрудника' : 'Добавить компанию'}
                    // </ButtonMaterial>
                  }
                  {/* {isLegalEntity && (
                    <Tooltip title="Собрать программу" placement="top">
                      <Fab
                        onClick={this.openProjectModal.bind(this)}
                        size="medium"
                        color="default"
                        className="mr-3"
                      >
                        <NoteAddIcon />
                      </Fab>
                    </Tooltip>
                  )} */}
                  {/* <Tooltip title="Проекты" placement="top">
                    <Fab
                      onClick={this.openProjectClick}
                      size="medium"
                      color="default"
                    >
                      <CreateNewFolderIcon />
                    </Fab>
                  </Tooltip> */}
                </div>
                <div>
                  <ButtonMaterial
                    className="mr-2"
                    variant="contained"
                    color="secondary"
                    onClick={this.clientModalClose}
                    disabled={waitForSave}
                  >
                    <Translate id="ClientDetailCancel" />
                  </ButtonMaterial>
                  <ButtonMaterial
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.onSaving(e)}
                    disabled={waitForSave}
                  >
                    {isLegalEntity ? (
                        <Translate
                            id={
                            clientId > 0
                                ? 'ClientDetailSave'
                                : 'ClientDetailAddCompany'
                            }
                        />
                    ) : (
                        <>
                            {clientId > 0 
                                ? 
                                    <Translate id="ClientDetailSave" />
                                :   'Добавить клиента'
                            }
                        </>
                    )}
                  </ButtonMaterial>
                </div>
              </DialogActions>
            </fieldset>
          </form>
        </Dialog>
        {this.props.agenciesOpen && 
            <AgenciesModal />
        }
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  let currentCompanyAccountId = selectors.sip.GetCallerAccountID(state.sip);

  if (currentCompanyAccountId <= 0) {
    currentCompanyAccountId = selectors.todo.getCurrentCompanyAccountId(
      state.todo
    );
  }

  return {
    translate: getTranslate(state.localize),
    waitForSave: state.customers.waitForSave,
    saveError: state.customers.saveError,
    projects: state.todo.projects,
    companies: state.customers.companies,
    companyInfo: state.customers.companyInfo,
    currentAgency: state.event.currentAgency,
    currentCompany: state.customers.currentCompany,
    agenciesOpen: state.event.agenciesOpen,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    saveClient: (client: ClientInfo, customersInCompany: ClientInfo[]) =>
        dispatch(
        actions.customers.saveCustomersActionCreator(client, customersInCompany)
        ),
    resetSaveError: () => dispatch(actions.customers.ResetSaveErrorAction()),
    currentCompanyAction: (currentCompany: ClientInfo | null) =>
        dispatch(actions.customers.currentCompanyAction(currentCompany)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) =>
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    setActiveProject: (projectID: number) =>
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    setAllProjects: (projects: Project[]) =>
        dispatch(actions.todo.setAllProjects(projects)),
    companyInfoAction: (companyInfo: ICompanyInfo) =>
        dispatch(actions.customers.companyInfoAction(companyInfo)),
    currentAgencyAction: (currentAgency: AgencyInfo) =>
        dispatch(actions.event.currentAgencyAction(currentAgency)),
    agenciesOpenAction: (agenciesOpen: boolean) =>
        dispatch(actions.event.agenciesOpenAction(agenciesOpen)),
    openProjectChange: (openProjectModal: boolean) =>
        dispatch(actions.todo.openProject(openProjectModal)),
    clientItemAction: (clientItem: ClientItem) => 
        dispatch(actions.customers.clientItemAction(clientItem)),   
});

export const ConnectedClientModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ClientModal);
