import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText, Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import { ChangeProjectParams } from '../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { GeoMarkStatus } from 'sedi-webserverproxy';

type Props = {
    open: boolean;
    handleClose: () => void;
    projectId: number;
    projectStatus: GeoMarkStatus;
}

function ControlDateModal(props: Props) {
    const { open, handleClose, projectId, projectStatus } = props;
    
    const [controlDateString, setControlDateString] = useState<String>("");
    const [disabledButtonSend, setDisabledButtonSend] = useState<boolean | undefined>(false);

    useEffect(() => {
        SystemEvent.SubscribeEventChangeProject(
            "ControlDateModal", 
            (answer) => {
                setDisabledButtonSend(false);
                handleClose();
                console.log("ControlDateModal", answer)
            }, 
            (error) => {
                setDisabledButtonSend(false);
                errorCallback(error);
            }
        );
        let today = new Date();
        setControlDateString(moment(today).add(7, "day").format('YYYY-MM-DD'));
    }, []);

    const controlChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setControlDateString(event.target.value);
    }

    const changeProjectHandle = () => {
        const payload: ChangeProjectParams = {
            projectId: projectId,
            projectStatus: projectStatus,
            controlDateTime: controlDateString.toString()
        }
        setDisabledButtonSend(true);
        
        SystemEvent.EventChangeProject(payload);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}>
            <DialogTitle>Укажите контрольную дату бронирования</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Установите контрольную дату включительно, после которой бронирование снимется автоматически.
                </DialogContentText>
                <TextField
                    label="Контрольная дата"
                    type="date"
                    defaultValue={controlDateString} 
                    onChange={controlChangeDate}
                    
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    Отмена
                </Button>
                <Button  disabled={disabledButtonSend} color="primary" autoFocus onClick={changeProjectHandle}>
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ControlDateModal;