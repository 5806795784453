import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import Draggable from "react-draggable";
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Action, AnyAction } from 'redux';
import { RootState, actions, selectors } from '../../store';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import { RollUpCard } from '../../store/event/types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { IconButton, CardHeader, Grid, Tooltip, FormControl, InputLabel, Select, makeStyles, Theme, createStyles, MenuItem, Box} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import './BillsCard.scss';
import BillsCardTable from './BillsCardTable';
import { SystemEvent, errorCallback } from '../../RemoteCommands/SystemEvent';
import { GetProjectParmas, PaymentBillInfo, PaymentBillsFilter, QiwiBillStatus } from '../../RemoteCommands/type';
import FilterByBillStatuses from '../bricks/FilterByBillStatuses';
import ProjectOnlyAutocomplete from '../bricks/ProjectOnlyAutocomplete';
import { Project, ProjectType } from 'sedi-webserverproxy';
import { localStorageGetItem } from '../../utils/storage';
import ClientItemAutocomplete from '../bricks/ClientItemAutocomplete';
import { BillStatuses } from '../../store/bills/types';
import InputDatesRange, { DatesRange } from '../bricks/InputDatesRange';
import moment from 'moment';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

const BillsCard:React.FC<Props> = (props) => {
    const classes = useStyles();
    const {
        openBillsCardAction,
        draggablePositions,
        draggablePositionAction,
        draggable,
        rollUpCards,
        rollUpCardAction,
        successMessage,
        loadingAction,
        projectID,
        setAllProjects,
        projects,
        userInfo,
        coworkers,
        clientitem
    } = props;

    const [state, setState] = useState<PaymentBillsFilter>({
        projectId: projectID,
        billStatuses: [QiwiBillStatus.Invoiced],
        customerAccountId: undefined,
        responsibleAccountId: undefined,
        dateStart: undefined,
        dateEnd: undefined
    });

    const [dates, setDates] = useState<DatesRange>({
        DateFrom: undefined,
        DateTo: undefined
    });

    const draggablePosition = draggablePositions.find(item => item.key === "BillsCard");
    const rollUpCardHas = rollUpCards.some(item => item.key === "BillsCard");

    const [paymentBills, setPaymentBills] = useState<PaymentBillInfo[]>([]);
    const [loadingBills, setLoadingBills] = useState<boolean>(false);
    const [loadingProjects, setLoadingProjects] = useState<boolean>(false);

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'BillsCard',
            position
        });
    };

    const userRoleTypeProjects = userInfo && (userInfo.userRole === "SalesDirector" || userInfo.userRole === "AdminPMS");

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Счета",
            key: "BillsCard"
        });
    }

    const handleClose = () => {
        openBillsCardAction(false);
    }
    
    const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof state;
        setState(prevState => ({
            ...prevState,
            [name]: event.target.value,
        }));
    };
    
    const handleChangeDates = (dates: DatesRange) => {
        let _dates: DatesRange = dates;
        if(dates.DateFrom && dates.DateTo && (dates.DateTo <= dates.DateFrom)) {
            const pluseMonthDateTo = moment(dates.DateFrom, "YYYY-MM-DD").add(1, 'month').toDate();
            _dates = {
                DateFrom: dates.DateFrom,
                DateTo: pluseMonthDateTo
            }
        }
        
        setDates({DateFrom: _dates.DateFrom, DateTo: _dates.DateTo});
    }

    useEffect(() => {
        SystemEvent.SubscribeEventGetPaymentBills(
            "BillsCard", 
            (answer) => {
                setLoadingBills(false);
                if(!loadingProjects){
                    loadingAction(false);
                }
                setPaymentBills(answer.paymentBills);
            }, 
            (error) => errorCallback(error)
        );

        SystemEvent.SubscribeEventGetProjects(
            "ProjectCard", 
            (answer) => {
                setLoadingProjects(false);
                if(!loadingBills){
                    loadingAction(false);
                }
                
                setAllProjects(answer.projectsInfo);
            }, 
            (error) => {
                setLoadingProjects(false);
                loadingAction(false);
                errorCallback(error)
            }
        );
    }, []);

    useEffect(() => {
        //if(successMessage) {
            getBillsInfo();
            if(projects.length == 0) {
                getProjectsInfo();
            }
        //}
    }, [ state.responsibleAccountId, projectID, state.billStatuses, clientitem, dates ]);

    const getProjectsInfo = () => {
        loadingAction(true);

        const params: GetProjectParmas = {
            projectType: ProjectType.CommonProject,
            projectStatus: undefined,
            language: localStorageGetItem("lang")
        }
        SystemEvent.EventGetProjects(params);
    }

    const getBillsInfo = () => {
        loadingAction(true);
        
        state.projectId = projectID > 0 ? projectID : undefined;
        
        state.customerAccountId = clientitem ? clientitem.accountId : undefined;
        
        if(dates.DateFrom) {
            state.dateStart = moment(dates.DateFrom).format('yyyy-MM-DD HH:mm:ss');
        }
        if(dates.DateTo) {
            state.dateEnd = moment(dates.DateTo).format('yyyy-MM-DD HH:mm:ss');
        }
        SystemEvent.EventGetPaymentBills(state);
    }

    const billStatusesChange = (statuses: BillStatuses[]) => {
        setState(prevState =>({
            ...prevState,
            billStatuses: statuses.map(item => item.value)
        }));
    }

    return (
        <Draggable
            handle=".selectedBillsCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card
                className={`
                    selectedBillsCard ${true ? 'openSelectedBillsCard' : 'closeSelectedBillsCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Закрыть"
                                placement='top'>
                                <IconButton 
                                    aria-label="settings" 
                                    className="mt-1 ml-1"
                                    onClick={() => {handleClose();}}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={1}>
                                <div className="box-title">Счета</div>
                            </Grid>
                            <Grid item>
                                    <ClientItemAutocomplete 
                                        disabled={false} />
                            </Grid>
                            <Grid item>
                                <ProjectOnlyAutocomplete 
                                    disabled={false}
                                    />
                            </Grid>
                            {userRoleTypeProjects && 
                                    <Grid item>
                                        <FormControl 
                                            variant="outlined" 
                                            size="small" 
                                            className={`${classes.formControl} cancelDraggable`}>
                                            <InputLabel>Ответственный</InputLabel>
                                            <Select
                                                value={state.responsibleAccountId}
                                                onChange={handleSelectChange}
                                                name="responsibleAccountId"
                                                labelWidth={146}>
                                                <MenuItem value={undefined}>Все</MenuItem>
                                                {coworkers.map(item => (
                                                    <MenuItem 
                                                        key={item.id} 
                                                        value={item.id}>
                                                            {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                            }
                            <Box width="100%" />
                            <Grid item xs={1} />
                            <Grid item>
                                <FilterByBillStatuses 
                                    onChange={billStatusesChange}
                                />
                            </Grid>
                            {/* <Grid item xs={3}>
                                <CompanyAutocomplete 
                                    currentValue={currentCompany}
                                    onChange={(value) => onCompanyChanged(value !== null ? value.id : -1)} />
                            </Grid> */}
                            
                            {/* <Box width="100%" />
                            <Grid item xs={1} /> */}
                            <Grid item>
                                    <InputDatesRange 
                                        showShiftControl={true}
                                        allowUndefinedValues={false}
                                        onChange={(dates) => handleChangeDates(dates)}  
                                        dates={dates}
                                        disabled={false} />
                            </Grid>
                            {/* <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={addTariffClick}
                                disabled={billboardShort ? false : true} >
                                Добавить
                            </Button>
                            </Grid> */}
                        </Grid>
                    }
                />
                <CardContent className="pt-0">
                    <BillsCardTable 
                        paymentBills={paymentBills}    
                    />
                </CardContent>
                <CardContent className='pt-0'>
                    <div className="totalBillsAmount">
                        {`
                           Всего ${paymentBills.length} счетов на сумму ${paymentBills.reduce((prev, current) => prev + current.paymentAmount, 0)} руб.
                        `}
                    </div>
                </CardContent>
            </Card>
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => {
    let projectID = selectors.todo.getProjectID(state.todo);
    
    return {
        projectID,
        openBillsCard: state.event.openBillsCard,
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
        successMessage: state.event.successMessage,
        billStatuses: state.bills.billStatuses,
        projects: state.todo.projects,
        userInfo: state.user.userInfo,
        coworkers: state.todo.coworkers,
        clientitem: state.customers.clientItem
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    openBillsCardAction: (openBillsCard: boolean) => 
        dispatch(actions.event.openBillsCardAction(openBillsCard)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
    dispatch(actions.event.rollUpCardAction(rollUpCard)),
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    loadingAction: (loading: boolean) => dispatch(actions.event.loadingAction(loading)),
    setAllProjects: (projects: Project[]) => 
        dispatch(actions.todo.setAllProjects(projects))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillsCard);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
        width: 200
    },
  }),
);
