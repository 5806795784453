import React, { FunctionComponent, Dispatch, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import WidgetAutocomplete from '../WidgetAutocomplete/WidgetAutocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import { IMultipleMarker } from '../map/types';
import { Tooltip } from '@material-ui/core';
import { AddressAPI, AddressAutocomplete, IAddressParameters, IGetAddressItem } from 'sedi-webserverproxy';
import { EventGeoMarks } from '../../RemoteCommands/type';
import './bricks.scss';

type  Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    selectedMultipleMarker: (multipleMarker: IMultipleMarker) => void;
    value: string;
    handleInputChange: (value: string) => void;
}

const AdvancedAutocomplete: FunctionComponent<Props> = (props) => {
    const {  
        selectedMultipleMarker,
        selectedAddressAction,
        addressesAction,
        selectedAreaName,
        selectedAddress,
        eventGeoMarksAction,
        addressValueAction,
        handleInputChange,
        value
    } = props;

    const helperNew = new AddressAutocomplete(new AddressAPI());

    const [currentselectedMarker, setCurrentselectedMarker] = useState<IMultipleMarker | null>(null);
    const [doneTitle, setDoneTitle] = useState("");
    const [addressItems, setAddressItems] = useState<IGetAddressItem[]>([]);

    useEffect(() => {
        if(currentselectedMarker) {
            setDoneTitle("Добавить маркер");
        } else {
            setDoneTitle("Уточнить поиск");
        }
    }, [currentselectedMarker]);

    const onChangeInput = (newValue: string) => {
        handleInputChange(newValue)
    }

    const doneInsertMarker = async () => {
        if(currentselectedMarker) {
            selectedMultipleMarker(currentselectedMarker);
            selectedAddressAction(null);
            addressesAction([]);
            onChangeInput("");
            setCurrentselectedMarker(null);
        } else {
            let parameters: IAddressParameters;
            if(selectedAddress) {
                parameters = {
                    searchText: value.replace(/[,]/g, ''),
                    searchType: null,
                    city: selectedAddress.c,
                    street: selectedAddress.s,
                    lat: selectedAddress.g.lat,
                    lon: selectedAddress.g.lon,
                    countryName: selectedAddress.co,
                    searchMethod: "CheckAddress"
                }
            } else {
                parameters = {
                    searchText: value.replace(/[,]/g, ''),
                    searchType: null,
                    searchMethod: "CheckAddress",
                    countryName: "Russia"
                }
            }
            const response = await helperNew.GetAddresses(parameters);
            if (response.Success === false) {
                return alert(response.Message);
            }

            setAddressItems(response.Result!.items);
        }
    }

    const clearSelectedAddress = () => {
        onChangeInput("");
        addressValueAction("");
        eventGeoMarksAction([]);
        addressesAction([]);
        selectedAddressAction(null);
        setCurrentselectedMarker(null);
    }

    return (
        <div className="advancedAutocomplete-row">
            <WidgetAutocomplete 
                value={value}
                inputClassName="advancedAutocomplete"
                inputClassNameLoading="advancedAutocomplete"
                onChangeInput={(newValue: string) => onChangeInput(newValue)} 
                cityClassName="advancedAutocomplete-city" 
                selectedMultipleMarker={(multipleMarker: IMultipleMarker) => 
                    setCurrentselectedMarker(multipleMarker)} 
                addressItems={addressItems} 
                autoFocusProps={true} />
            {selectedAreaName !== "Все" && 
                <Tooltip title={doneTitle}>
                    <DoneIcon 
                        className="done-icon"
                        onClick={doneInsertMarker} />
                </Tooltip>
            }
            <Tooltip title="Очистить поле">
                <ClearIcon 
                    className="advancedAutocomplete-icon"
                    onClick={clearSelectedAddress} />
            </Tooltip>
        </div>
    )
};

const mapStateToProps = (state: RootState) => ({
    selectedAreaName: state.billboards.selectedAreaName,
    selectedAddress: state.event.selectedAddress,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    selectedAddressAction: (selectedAddress: IGetAddressItem | null) => 
        dispatch(actions.event.selectedAddressAction(selectedAddress)),
    addressesAction: (addresses: IGetAddressItem[]) => 
        dispatch(actions.event.addressesAction(addresses)),
    eventGeoMarksAction: (eventGeoMarks: EventGeoMarks[]) => 
        dispatch(actions.billboards.eventGeoMarksAction(eventGeoMarks)),
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),  
});


export default connect(mapStateToProps, mapDispatchToProps)(AdvancedAutocomplete);