import React from 'react';
import { connect } from 'react-redux';
import { Sip } from '../../../../../sip/sip';
import { RootState } from '../../../../../store';


type Props = ReturnType<typeof mapStateToProps>;


class CallButtons extends React.Component<Props>{

    acceptCall() {
        try {
            Sip.Answer();
        } catch (e) {
            console.log(e);
        }
    }

    refuseCall() {
        try {
            Sip.HangUp();
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { isEstablished } = this.props;

        return (
            <div className="btn-group" role="group" aria-label="Basic example">
                {!isEstablished &&
                    <button type="button" className="btn btn-success" onClick={this.acceptCall.bind(this)}>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                    </button>
                }
                <button type="button" className="btn btn-danger" onClick={this.refuseCall.bind(this)}>
                    <i className="fa fa-phone-slash" aria-hidden="true"></i>
                </button>
            </div>
        );
    }
}


const mapStateToProps = (state: RootState) => ({
    isEstablished: state.sip.isEstablished
});


export default connect(
    mapStateToProps
)(CallButtons);