import { ClientInfo, GetCustomersFilter } from 'sedi-webserverproxy';


export type CustomerSearch = {
    componentID: string;
    filters: GetCustomersFilter;
    isLoading: boolean;
    errorText: string | undefined;
    foundCustomersIDs: number[];
    foundCompaniesIDs: number[];
    foundByInn: ClientInfo | undefined;
}


export interface CustomerState {
    search: CustomerSearch[];
    filters: GetCustomersFilter;
    waitForSave: boolean;
    saveError: string | undefined;
    companies: ClientInfo[];
    customers: ClientInfo[];
    currentCompany: ClientInfo | null,
    companyInfo: ICompanyInfo,
    clientItem: ClientItem | null,
    successClient: string | null
}

export interface ICompanyInfo {
    agencyId: number,
    officialAddress: AddressString,
    officeAddress: AddressString,
    postalAddress: AddressString,
}

type AddressString = {
    addressString: string;
}

export enum ClientType {
    company = "company",
    customer = 'customer',
    agency = "agency",
}
export class ClientItem {
    accountId: number;
    name: string;
    customerType: ClientType;

    constructor(accountId: number, name: string, customerType: ClientType) {
        this.accountId = accountId;
        this.name = name;
        this.customerType = customerType;
    }
}
