import React, { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { IGeoMark } from '../../RemoteCommands/type';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    geoMarks: IGeoMark[];
    onChange: (geoMarkId: number) => void;
    geoMark: IGeoMark | undefined;
}

const ProjectGeoMarkNameAutocomplete:React.FC<Props> = (props) => {
    const { geoMarks, onChange, geoMark } = props;
    const [currentGeoMark, setCurrentGeoMark] = useState<IGeoMark | undefined>(geoMark);

    const currentValueOnChange = (changeValue: IGeoMark) => {
        if(changeValue) {
            onChange(changeValue.geoMarkId);
        } else {
            onChange(-1);
        }
        setCurrentGeoMark(changeValue);
    }

    return (
        <Autocomplete
            options={geoMarks}
            getOptionLabel={(option: IGeoMark) => option.geoMarkName}
            style={{ minWidth: 220}}
            value={currentGeoMark}
            onChange={(event, changeValue) => currentValueOnChange(changeValue)}
            filterOptions={(options, state) => fliterOptions(options, state)}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    fullWidth
                    variant="outlined" 
                    size="small" 
                />
            }
        />
    );
}

const fliterOptions = (options: IGeoMark[], state: FilterOptionsState) => {
    const optionsArray: IGeoMark[] = options.filter(option => option.geoMarkName.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 
        || option.geoMarkName.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
    return optionsArray;
}

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectGeoMarkNameAutocomplete);