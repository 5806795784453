import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState, selectors, actions } from '../../store';
import { RouteComponentProps } from "react-router";
import { Billboard } from '../../store/billboards/types';
import GoogleMapReact, { ChangeEventValue, Coords, Bounds } from "google-map-react";
import { getTranslate } from "react-localize-redux";
import {IPropertySet, GeoMark, GeoMarkTimeStatus, LatLong, AssignedObject } from 'sedi-webserverproxy';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import BUILD_PARAMS from '../../utils/build';
import BillboardStatus from '../billboards/BillboardStatus/BillbaordStatus';
import {DatesRange} from "../bricks/InputDatesRange";
import MarkerBillboard from './components/MarkerBillboard';
import ClusterMarker from './components/ClusterMarker';
import supercluster, { Cluster } from 'points-cluster';
import WidgetPanel from '../WidgetPanel/WidgetPanel';
import ResetButton from './components/ResetButton';
import AdvancedSearch from '../bricks/AdvancedSearch';
import { Paper } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AdvancedFilter from '../bricks/AdvancedFilter';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import ProjectSelectedCard from '../Projects/ProjectSelectedCard';
import ProjectAddSnackbar from '../bricks/ProjectAddSnackbar';
import SuccessSnackbar from '../bricks/SuccessSnackbar';
import MessageShackbar from '../bricks/MessageShackbar';
import DateInterval from '../bricks/DateInterval';
import TariffCard from '../Tariff/TariffCard';
import { IMultipleMarker } from './types';
import ProjectCard from '../Projects/ProjectSelector/ProjectCard';
import NextBillboardList from '../bricks/NextBillboardList';
import { calculateDistance } from '../../utils/calculateDistance';
import BrandsCard from '../bricks/BrandsCard';
import { BrandAddress, BrandGeoObjects, GeoPoint, IGeoObjectBillboards, RollUpCard } from '../../store/event/types';
import SalesStatistics from '../Statistics/SalesStatistics';
import { errorCallback, EventError, SystemEvent } from '../../RemoteCommands/SystemEvent';
import RollUpBtn from '../bricks/RollUpBtn';
import AdditionalCostContainer from '../Projects/AdditionalCost';
import BillBoardDetailedUsageInfo from '../BillBoardDetailedUsageInfo/BillBoardDetailedUsageInfo';
import Employees from '../Employees/Employees';
import GeoMarkTariffCard from '../Tariff/GeoMarkTariffCard';
import AdditionalTariffs from '../AdditionalTariffs/AdditionalTariffs';
import { BillboardFilters, BillboardsShortInfo, FullMonthStatistic, GeoObjectStatus } from '../../RemoteCommands/type';
import BillboardInfo from '../BillboardInfo/BillboardInfo';
import './map.css';
import CityStatistics from '../Statistics/CityStatistics';
import DontSaleCard from '../DontSaleCard/DontSaleCard';
import FilterListIcon from '@material-ui/icons/FilterList';
import BillsCard from '../Tariff/BillsCard';
import BillboardAdd from '../billboards/BillboardAdd/BillboardAdd';
import PhotocontrolCard from '../Photocontrol/PhotocontrolCard';

/*global google*/

type Props = RouteComponentProps<{}> 
    & ReturnType<typeof mapDispatchToProps>
    & ReturnType<typeof mapStateToProps> & {
        getAdditionalBillboardAction: (geoMarkId: number) => Promise<any>,
        filterBillboardsAction: (filterBillboards: Billboard[]) => void
    };

type State = {
    showFilter: boolean,
    modalToShow: number,
    mapOptions: {
        center: Coords,
        zoom: number,
        bounds: Bounds;
    },
    clusters: Cluster[],
    anchorEl?: null | HTMLElement,
    toggleFilter: boolean,
    messageOpen: boolean,
    statusError: boolean,
    statusErrorMessage: string,
    errorMessageOpen: boolean,
    multipleMarkers: IMultipleMarker[],
    successMessageOpen: boolean,
    projectCard: boolean,
    brandMarkers: IMultipleMarker[]
} 

type MarkerBillboard = {
    id: number;
    lat: number;
    lng: number;
    numPoints: number;
    points: number;
}

// class FilterBillboardGroup {
//     public free = 0;
//     public reserved = 0;
//     public booked = 0;
//     public paid = 0;
//     public sold = 0;
//     // public get free(): number {
//     //     return this.total - this.reserved - this.booked - this.paid - this.sold;
//     // }
//     constructor(free: number, reserved: number, booked: number, paid: number, sold: number) {
//         this.free = free;
//         this.reserved = reserved;
//         this.booked = booked;
//         this.paid = paid;
//         this.sold = sold;
//     }
// }


const centerCoords ={ lat: 55.752256, lng: 37.619345 };

let MAP = {
    defaultZoom: 10,
    defaultCenter: centerCoords,
    options: {
      maxZoom: 19,
      fullscreenControl: false
    },
};

class Map extends Component<Props, State> {
    
    map: any;
    maps: any;
    marker: any;
    static _infowindow: google.maps.InfoWindow;
    markers: google.maps.Marker[] = [];
    
    constructor(props: Props) {
        super(props);
        (window as any).deleteMarker = this.deleteMarker;
        (window as any).deleteBrandMarker = this.deleteBrandMarker;
        (window as any).deleteMarkers = this.deleteMarkers;
        (window as any).deleteBrandMarkers = this.deleteBrandMarkers;
        (window as any).nextBillboardClick = this.nextBillboardClick;
        this.map = React.createRef();
        this.state = {
            showFilter: false,
            modalToShow: 0,
            mapOptions: {
                center: MAP.defaultCenter,
                zoom: MAP.defaultZoom,
                bounds: {
                    nw: MAP.defaultCenter,
                    ne: MAP.defaultCenter,
                    sw: MAP.defaultCenter,
                    se: MAP.defaultCenter
                }
            },
            clusters: [],
            anchorEl: null,
            toggleFilter: false,
            messageOpen: false,
            statusError: false,
            statusErrorMessage: '',
            errorMessageOpen: false,
            multipleMarkers: [],
            successMessageOpen: false,
            projectCard: false,
            brandMarkers: []
        }
    }

    private eventKey = "";

    messageShackbarClose() {
        this.setState({
            messageOpen: false
        });
    }

    errorMessageClose() {
        this.setState({
            errorMessageOpen: false
        });
        this.props.errorMessageAction('');
    }
    
    getMapBounds(billboardsShortInfo: BillboardsShortInfo[]) {
        try {
            const bounds = new google.maps.LatLngBounds();
    
            billboardsShortInfo.forEach((billboard) => {
                bounds.extend(new google.maps.LatLng(
                    billboard.geoPoint ? billboard.geoPoint.latitude : 0,
                    billboard.geoPoint ? billboard.geoPoint.longitude : 0,
                ));
            });
            return bounds;
        } catch(error) {
            console.log("getMapBounds", error)
        }
    };
    
    bindResizeListener(map: any, maps: any, bounds: google.maps.LatLngBounds) {
        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });
    };
    
    updatePosition(lat: number, lng: number, map: any)  {
        const { clusterZoomLevel } = this.props;
        const center = new google.maps.LatLng(lat, lng);
        const bounds = new google.maps.LatLngBounds();
              bounds.extend(center);
        map.setCenter(center);
        map.fitBounds(bounds);
        map.setZoom(clusterZoomLevel + 1);
        // this.marker.setPosition(center);
    }

    fitBoundsMarker(markers: IMultipleMarker[]) {
        const bounds = new google.maps.LatLngBounds();
        const map = this.map.current.map_;

        for(let marker of markers) {
            bounds.extend(marker.center);
        }

        if(markers.length > 0) {
            map.fitBounds(bounds);
        }
    }

    onAddMultipleMarker = (multipleMarker: IMultipleMarker) => {
        this.addMarker(multipleMarker);
        this.setState((prevSate: State) => {
            const list = [...prevSate.multipleMarkers, multipleMarker];
            return {
                ...prevSate,
                multipleMarkers: list
            }
        });
    };

    // Adds a marker to the map and push to the array.
    addMarker(multipleMarker: IMultipleMarker) {
        const { lat, lng } = multipleMarker.center;
        const mapLatLng = new google.maps.LatLng(lat, lng);
        const marker = new google.maps.Marker({
            position: mapLatLng,
            animation: google.maps.Animation.DROP,
            map: this.map.current.map_,
        });
        this.markers.push(marker);
        multipleMarker.marker = marker;
        this.multipleMarkerPosition(multipleMarker);
    }

    onAddBrandMarker = (brandMarkers: IMultipleMarker[], brandGeoObject?: BrandGeoObjects) => {
        this.deleteBrandMarkers();
        brandMarkers.map(item => {
            this.addBrandMarker(item);
            this.brandMarkerPosition(item);
        });
        this.setState({
            brandMarkers
        });
        if(brandGeoObject) {
            const { Billboards } = brandGeoObject;
            if(Billboards) {
                const bounds = new google.maps.LatLngBounds();
                const map = this.map.current.map_;

                for(let billboard of Billboards) {
                    bounds.extend({
                        lat: billboard.GeoPoint.Latitude,
                        lng: billboard.GeoPoint.Longitude
                    });
                }

                if(Billboards.length > 0) {
                    map.fitBounds(bounds);
                }
            }
        }
    };

    onShowNextBillboards = (brandMarkers: IMultipleMarker[], brandGeoObject?: BrandGeoObjects) => {
        this.deleteBrandMarkers();
        let geoPoints: LatLong[] = [];
        const bounds = new google.maps.LatLngBounds();
        const map = this.map.current.map_;
        if(brandGeoObject) {
            const { Billboards } = brandGeoObject;
            if(Billboards) {
                brandMarkers.map(item => {
                    geoPoints.push({
                        latitude: item.center.lat,
                        longitude: item.center.lng
                    });
                    this.addBrandMarker(item);
                    this.brandMarkerPosition(item);
                });
                for(let billboard of Billboards) {
                    geoPoints.push({
                        latitude: billboard.GeoPoint.Latitude,
                        longitude: billboard.GeoPoint.Longitude
                    });
                }

                if(geoPoints.length > 0) {
                    setTimeout(() => {
                        for(let geoPoint of geoPoints) {
                            bounds.extend({
                                lat: geoPoint.latitude,
                                lng: geoPoint.longitude
                            });
                        }
                        map.fitBounds(bounds);
                    }, 500)
                }
            }
        }
    };

    showProjectBillboardsPoints(geoPoints: LatLong[], assignedObjects: AssignedObject[]) {
        this.deleteBrandMarkers();
        const bounds = new google.maps.LatLngBounds();
        const map = this.map.current.map_;

        for(let geoPoint of geoPoints.concat(assignedObjects.map(item => item.geoPoint))) {
            bounds.extend({
                lat: geoPoint.latitude,
                lng: geoPoint.longitude
            });
        }

        for(let assignedObject of assignedObjects) {
            const { latitude, longitude } = assignedObject.geoPoint;
            const mapLatLng = new google.maps.LatLng(latitude, longitude);
            const marker = new google.maps.Marker({
                position: mapLatLng,
                animation: google.maps.Animation.DROP,
                map: this.map.current.map_,
            });
            this.markers.push(marker);

            const contentString = `
                    <div class="infoWindow">
                        <div 
                            class="infoWindow__content">
                            ${
                                assignedObject.cityName  
                                + (assignedObject.cityName && ", ") + assignedObject.streetName 
                                + (assignedObject.streetName && ", ") + assignedObject.houseNumber
                            }
                        </div>
                    </div>
            `;
            const infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            marker.addListener("click", () => {
                infowindow.open(map, marker);
            });
        }

        if(geoPoints.length > 0 || assignedObjects.length > 0) {
            map.fitBounds(bounds);
        }
    }

    // Adds a marker to the map and push to the array.
    addBrandMarker(brandMarker: IMultipleMarker) {
        const { lat, lng } = brandMarker.center;
        const mapLatLng = new google.maps.LatLng(lat, lng);
        const marker = new google.maps.Marker({
            position: mapLatLng,
            animation: google.maps.Animation.DROP,
            map: this.map.current.map_,
        });
        this.markers.push(marker);
        brandMarker.marker = marker;
    };

    brandMarkerPosition(brandMarker: IMultipleMarker) {
        Map._infowindow = new google.maps.InfoWindow();
        const map = this.map.current.map_;

        if(brandMarker.marker) {
            const contentString = `
                    <div class="infoWindow">
                        <div 
                            class="infoWindow__content">
                            ${brandMarker.address 
                                ? brandMarker.address.AddressString 
                                    || (brandMarker.address.CityName + ", " +  brandMarker.address.StreetName + ", " + brandMarker.address.HouseNumber) 
                                : ""
                            }
                        </div>
                        <div 
                            class="infoWindow__link"
                            onclick="window.deleteBrandMarker(${brandMarker.id})">
                            Удалить маркер
                        </div>
                        <div 
                            class="infoWindow__link"
                            onclick="window.deleteBrandMarkers()">
                            Удалить все маркеры
                        </div>
                    </div>
            `;
            google.maps.event.addListener(brandMarker.marker, 'click', (function(marker) {
                return function() {
                    Map._infowindow.setContent(contentString);
                    Map._infowindow.open(map, marker);
                }
            })(brandMarker.marker));
        }
    }

    // Deletes all markers in the array by removing references to them.
    deleteBrandMarkers = () => {
        for (let marker of this.markers) {
            marker.setMap(null);
        }
        this.markers = [];
        this.setState({
            brandMarkers: []
        });
    }
    
    // Deletes all markers in the array by removing references to them.
    deleteMarkers = () => {
        for (let markers of this.markers) {
            markers.setMap(null);
        }
        this.markers = [];
        this.setState({
            multipleMarkers: []
        });
    }

    deleteMarker = (id: number) => {
        const { multipleMarkers } = this.state;
        const idx = multipleMarkers.findIndex((item) => item.id === id);
        const items = [
            ...multipleMarkers.slice(0, idx),
            ...multipleMarkers.slice(idx + 1)
        ];
        this.setState({
            multipleMarkers: items
        });
        const multipleMarker = multipleMarkers.find(item => item.id === id);
        if(multipleMarker && multipleMarker.marker) {
              multipleMarker.marker.setMap(null);

              const index = this.markers.findIndex((item) => item === multipleMarker.marker);
              const markers = [
                ...this.markers.slice(0, index),
                ...this.markers.slice(index + 1)
              ];
              this.markers = markers;
        }
    }

    deleteBrandMarker = (id: number) => {
        const { brandMarkers } = this.state;
        const idx = brandMarkers.findIndex((item) => item.id === id);
        const items = [
            ...brandMarkers.slice(0, idx),
            ...brandMarkers.slice(idx + 1)
        ];
        this.setState({
            brandMarkers: items
        });
        const brandMarker = brandMarkers.find(item => item.id === id);
        if(brandMarker && brandMarker.marker) {
            brandMarker.marker.setMap(null);

              const index = this.markers.findIndex((item) => item === brandMarker.marker);
              const markers = [
                ...this.markers.slice(0, index),
                ...this.markers.slice(index + 1)
              ];
              this.markers = markers;
        }
    }

    findDistance(lat: number, lng: number, id: number, address: BrandAddress | null, geoMarkName?: string,) {
        const {
            maxDistance, 
            brandGeoObjectAction,
            constructionType,
            format,
            selectedSide,
            light,
            billboardType,
            billboardsShortInfo
        } = this.props;

        const findBillboards = billboardsShortInfo.filter(item => 
            calculateDistance(lat, lng, item.geoPoint.latitude, item.geoPoint.longitude) < parseInt(maxDistance)
        );

        let resultFilter: IGeoObjectBillboards[] = [];
        for (let billboard of findBillboards) {
            // const fitConstructionType = billboard.constructionType ===  constructionType || billboard.constructionTypeB ===  constructionType || constructionType === '';
            // const fitFormat = billboard.format ===  format || format === '';
            // const fitSide = billboard.way1 ===  selectedSide || billboard.way2 ===  selectedSide || selectedSide === '';
            // const boolLight = (billboard.light +'') === light || light === '';
            // const fitType = billboard.type ===  billboardType || billboard.typeB ===  billboardType || billboardType === '';
            const geoPoint: GeoPoint = {
                Latitude: billboard.geoPoint.latitude,
                Longitude: billboard.geoPoint.longitude
            }
            const resultBrandGeoObject: IGeoObjectBillboards = {
                GeoMarkId: billboard.geoMarkId,
                GeoMarkName: billboard.name,
                Address: {
                    CountryName: '', 
                    CityName: '', 
                    StreetName: '', 
                    ObjectName: '',
                    HouseNumber: '',
                    GeoPoint: {
                        Latitude: billboard.geoPoint.latitude,
                        Longitude: billboard.geoPoint.longitude
                    }
                },
                GeoPoint: geoPoint
            }
            resultFilter.push(resultBrandGeoObject);
        }
        const brandGeoObject: BrandGeoObjects = {
            GeoMarkId: id,
            GeoMarkName: geoMarkName ? geoMarkName : (address && address.AddressString ? address.AddressString : ''),
            Address: address,
            GeoPoint: { Latitude: lat, Longitude: lng },
            Billboards: resultFilter
        }

        brandGeoObjectAction(brandGeoObject);
        // this.setState({
        //     findBillboards,
        // });
    } 

    nextBillboardClick = (lat: number, lng: number, id: number, address: string) => {
        const brandAddress: BrandAddress = {
            AddressString: address,
            Id: id,
            GeoPoint: {
                Latitude: lat,
                Longitude: lng
            }
        }
        const { 
            openNextBillboardAction,
            rollUpCards,
            rollUpCardsAction
        } = this.props;
        this.findDistance(lat, lng, id, brandAddress);
        openNextBillboardAction(true);
        const idx = rollUpCards.findIndex((item) => item.key === "NextBillboardList");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    multipleMarkerPosition(multipleMarker: IMultipleMarker) {
        Map._infowindow = new google.maps.InfoWindow();
        const map = this.map.current.map_;

        if(multipleMarker.marker) {
            const contentString = `
                    <div class="infoWindow">
                        <div 
                            class="infoWindow__content">
                            ${multipleMarker.address 
                                ? multipleMarker.address.AddressString 
                                    || (multipleMarker.address.CityName + ", " +  multipleMarker.address.StreetName + ", " + multipleMarker.address.HouseNumber) 
                                : ""
                            }
                        </div>
                        <div 
                            class="infoWindow__link"
                            onclick="window.nextBillboardClick(
                                ${multipleMarker.center.lat}, 
                                ${multipleMarker.center.lng}, 
                                ${multipleMarker.id}, 
                                '${multipleMarker.address ? multipleMarker.address.AddressString : ""}',
                            )">
                            Показать ближайшие конструкции
                        </div>
                        <div 
                            class="infoWindow__link"
                            onclick="window.deleteMarker(${multipleMarker.id})">
                            Удалить маркер
                        </div>
                        <div 
                            class="infoWindow__link"
                            onclick="window.deleteMarkers()">Удалить все маркеры</div>
                    </div>
            `;
            google.maps.event.addListener(multipleMarker.marker, 'click', (function(marker) {
                return function() {
                    Map._infowindow.setContent(contentString);
                    Map._infowindow.open(map, marker);
                }
            })(multipleMarker.marker));
        }
    }
    
    apiIsLoaded (map: any, maps: any, billboardsShortInfo: BillboardsShortInfo[]) {
        const { clusterZoomLevel, billboardShortAction } = this.props
        if(billboardsShortInfo.length > 0) {
            // Get bounds by our places
            const bounds = this.getMapBounds(billboardsShortInfo);
            // Fit map to bounds
            map.fitBounds(bounds);
        }
        // Bind the resize listener
        // this.bindResizeListener(map, maps, bounds);
        google.maps.event.addListener(map, 'zoom_changed', function() {
            const zoomLevel = map.getZoom();
            if (clusterZoomLevel >= zoomLevel) {
                billboardShortAction(undefined);
            }
        });
    };

    handleChangeDates(dates: DatesRange){
        const { datesAction } = this.props;
        datesAction(dates);
    }

    presentFilter = () => {
        let oldFilter = this.state.showFilter;
        this.setState(() => ({
            showFilter: !oldFilter
        }));
    }


    navigateToBillboard = (billboard: BillboardsShortInfo) => () => {
        const { billboardShortAction } = this.props;

        billboardShortAction(billboard);
    }
    
    componentDidUpdate(prevProps: Props, prevState: State) {
        const { 
            reservedChecked, bookedChecked, 
            soldChecked, paidChecked, freeChecked, 
            billboardShort, constructionType, format, 
            selectedSide, light, billboardType, selectedAreaName, 
            eventName, selectedCityName, saveError, getAddress,
            errorMessage, successClient, brandGeoObjects, maxDistanceSearchAction,
            maxDistanceSearch, showMarkers, newBrandGeoObjects, eventError, showMarkersAction,
            billboardsShortInfo
        } = this.props;

        const { multipleMarkers, brandMarkers } = this.state;

        if(showMarkers !== prevProps.showMarkers && showMarkers === true) {
            const bounds = new google.maps.LatLngBounds();
            const map = this.map.current.map_;

            for(let brandGeoObject of brandGeoObjects) {
                if(brandGeoObject.Billboards) {
                    for(let billboard of brandGeoObject.Billboards) {
                        bounds.extend({
                            lat: billboard.GeoPoint.Latitude,
                            lng: billboard.GeoPoint.Longitude
                        });
                    }
                }
            }

            if(brandGeoObjects.length > 0) {
                map.fitBounds(bounds);
            }
            showMarkersAction(false);
        }

        if(maxDistanceSearch !== prevProps.maxDistanceSearch && maxDistanceSearch === true) {
            brandGeoObjects.map(brandGeoObject => {
                if(brandGeoObject.GeoPoint && brandGeoObject.GeoPoint.Latitude && brandGeoObject.GeoPoint.Longitude) {
                    return (
                        this.findDistance(
                            brandGeoObject.GeoPoint.Latitude, 
                            brandGeoObject.GeoPoint.Longitude, 
                            brandGeoObject.GeoMarkId,
                            brandGeoObject.Address,
                            brandGeoObject.GeoMarkName
                        )
                    )
                }
            });
            newBrandGeoObjects.map(brandGeoObject => {
                if(brandGeoObject.GeoPoint && brandGeoObject.GeoPoint.Latitude && brandGeoObject.GeoPoint.Longitude) {
                    return (
                        this.findDistance(
                            brandGeoObject.GeoPoint.Latitude, 
                            brandGeoObject.GeoPoint.Longitude, 
                            brandGeoObject.GeoMarkId, 
                            brandGeoObject.Address,
                            brandGeoObject.GeoMarkName
                        )
                    )
                }
            });
            maxDistanceSearchAction(false);
        }

        if(successClient !== prevProps.successClient && successClient !== null) {
            this.setState({
                successMessageOpen: true
            });
        }

        if(eventError !== prevProps.eventError && eventError !== null) {
            this.setState({
                successMessageOpen: true
            });
        }

        if (billboardsShortInfo.length !== prevProps.billboardsShortInfo.length) {
            this.fitBoundsByBillboards(billboardsShortInfo);
            this.createClusters(this.props);
        }
        else if(reservedChecked !== prevProps.reservedChecked || bookedChecked !== prevProps.bookedChecked 
            || soldChecked !== prevProps.soldChecked
            || paidChecked !== prevProps.paidChecked 
            || freeChecked !== prevProps.freeChecked 
            || selectedSide !== prevProps.selectedSide) {
            this.createClusters(this.props)
        } else if (billboardShort !== prevProps.billboardShort && billboardShort !== undefined) {
            let center = {
                lat: billboardShort.geoPoint ? billboardShort.geoPoint.latitude : 0,
                lng: billboardShort.geoPoint ? billboardShort.geoPoint.longitude : 0
            }
            this.updatePosition(center.lat, center.lng, this.map.current.map_);
        } else if(getAddress !== prevProps.getAddress && getAddress) {
            let center = {
                lat: getAddress.g.lat,
                lng: getAddress.g.lon
            }
            this.updatePosition(center.lat, center.lng, this.map.current.map_);

        }

        if(multipleMarkers !== prevState.multipleMarkers) {
            this.fitBoundsMarker(multipleMarkers);
        }

        if(brandMarkers !== prevState.brandMarkers) {
            // if(showMarkers) {
            //     return;
            // }
            this.fitBoundsMarker(brandMarkers);
        }

        // if (dates !== prevProps.dates || projectID !== prevProps.projectID) {
        //     this.updateBillboardStatuses()
        // }

        if (
            selectedAreaName !== prevProps.selectedAreaName 
            || selectedCityName !== prevProps.selectedCityName 
            || constructionType !== prevProps.constructionType 
            || format !== prevProps.format || selectedSide !== prevProps.selectedSide 
            || light !== prevProps.light || billboardType !== prevProps.billboardType
        ) {  
            brandGeoObjects.map(brandGeoObject => (
                this.findDistance(
                    brandGeoObject.GeoPoint!.Latitude, 
                    brandGeoObject.GeoPoint!.Longitude, 
                    brandGeoObject.GeoMarkId,
                    brandGeoObject.Address,
                    brandGeoObject.GeoMarkName
                )
            ));
            newBrandGeoObjects.map(brandGeoObject => (
                this.findDistance(
                    brandGeoObject.GeoPoint!.Latitude, 
                    brandGeoObject.GeoPoint!.Longitude, 
                    brandGeoObject.GeoMarkId, 
                    brandGeoObject.Address,
                    brandGeoObject.GeoMarkName
                )
            ));
            this.getFilterBillboard();
        }

        if(eventName !== prevProps.eventName) {
            if(eventName === "EventSendCommercialOfferToClient") {
                this.setState({
                    messageOpen: true
                });
            }
        }

        if (saveError && saveError !== prevProps.saveError){
            this.setState({ statusError: true, statusErrorMessage: saveError });
            this.props.resetSaveError();
        }

        if(errorMessage !== prevProps.errorMessage && errorMessage !== "") {
            this.setState({
                errorMessageOpen: true
            });
        }
    }

    getFilterBillboard() {
        const {
            billboardsShortInfo
        } = this.props

        // const selectedBillboard = billboards.filter((b: Billboard) => 
        //     ((selectedAreaName !== '' && b.addressInfo && b.addressInfo.areaName === selectedAreaName) 
        //     || (selectedCityName !== '' && b.addressInfo && b.addressInfo.cityName === selectedCityName))
        //     && (constructionType === '' || b.constructionType === constructionType || b.constructionTypeB === constructionType)
        //     && (format === '' || b.format === format)
        //     && (selectedSide === '' || b.way1 ===  selectedSide || b.way2 ===  selectedSide)
        //     && (light === '' || (b.light +'') === light)
        //     && (billboardType === '' || b.type === billboardType || b.typeB === billboardType))

        // if(selectedAreaName === "Все") {
        //     filterBillboardsAction(billboards)
        // } else {
        //     filterBillboardsAction(selectedBillboard)
        // }

        // if(selectedBillboard.length > 0) {
        //     const bounds = this.getMapBounds(selectedBillboard);
        //     this.map.current.map_.fitBounds(bounds)
        // }

        const bounds = this.getMapBounds(billboardsShortInfo);
        this.map.current.map_.fitBounds(bounds)
    }


    updateBillboard = async (propertySet:IPropertySet,billboardId:number) => {
        const geaMark = this.props.geomarks.find((_geaMark) => _geaMark.id === billboardId);
        if(geaMark){
            await this.props.updateBillboard(propertySet,geaMark);
        }
    };


    fillterBillboards(fullMonthStatistic: FullMonthStatistic[], billboardsShortInfo: BillboardsShortInfo[]) {
        const { reservedChecked, bookedChecked, soldChecked, paidChecked, freeChecked } = this.props;

        const billboardIds = billboardsShortInfo.map(billboard => billboard.geoMarkId);
        const filterStatistics = fullMonthStatistic.filter(s => billboardIds && billboardIds.indexOf(s.geoMarkId) >= 0);

        let resultFilter: BillboardsShortInfo[] = [];

        // for (let billboard of billboards) {
        //     const clusterBillboard = billboards.find(b => b.id === billboard.id) as Billboard;
        //     const reservedCount = filterStatuses.filter(status => status.geoMarkId === billboard.id && status.geoMarkStatus === 'Reserved').length;
        //     const bookedCount = filterStatuses.filter(status => status.geoMarkId === billboard.id && status.geoMarkStatus === 'Booked').length;
        //     const paidCount = filterStatuses.filter(status => status.geoMarkId === billboard.id && status.geoMarkStatus === 'Paid').length;
        //     const soldCount = filterStatuses.filter(status => status.geoMarkId === billboard.id && status.geoMarkStatus === 'Sold').length;
        //     const total = clusterBillboard.sideACount + clusterBillboard.sideBCount;
        //     const group = new FilterBillboardGroup(total,reservedCount,bookedCount,paidCount,soldCount);
        //     const fitForStatus = (reservedChecked && group.reserved > 0 || bookedChecked && group.booked > 0 || soldChecked && group.sold > 0
        //         || paidChecked && group.paid > 0 || freeChecked && group.free === group.total);
        //     const fitConstructionType = billboard.constructionType ===  constructionType || billboard.constructionTypeB ===  constructionType || constructionType === '';
        //     const fitFormat = billboard.format ===  format || format === '';
        //     const fitSide = billboard.way1 ===  selectedSide || billboard.way2 ===  selectedSide || selectedSide === '';
        //     const boolLight = (billboard.light +'') === light || light === '';
        //     const fitType = billboard.type ===  billboardType || billboard.typeB ===  billboardType || billboardType === '';

        //     if(fitForStatus && fitConstructionType && fitFormat && fitSide && boolLight && fitType) {
        //         resultFilter.push(billboard);
        //     }
        // }

        for (let statistic of filterStatistics) {
            // const clusterBillboard = billboardsShortInfo.find(b => b.geoMarkId === billboard.geoMarkId) as BillboardsShortInfo;
            // const reservedCount = filterStatuses.filter(status => status.geoMarkId === billboard.geoMarkId && status.geoMarkStatus === 'Reserved').length;
            // const bookedCount = filterStatuses.filter(status => status.geoMarkId === billboard.geoMarkId && status.geoMarkStatus === 'Booked').length;
            // const paidCount = filterStatuses.filter(status => status.geoMarkId === billboard.geoMarkId && status.geoMarkStatus === 'Paid').length;
            // const soldCount = filterStatuses.filter(status => status.geoMarkId === billboard.geoMarkId && status.geoMarkStatus === 'Sold').length;
            // const total = 12; // После того как Ира добавить стороны sideCount1 + sideCount2
            const reservedCount = statistic.statuses.find(item => item.status === 'Reserved')!.sideCount;
            const bookedCount = statistic.statuses.find(item => item.status === 'Booked')!.sideCount;
            const soldCount = statistic.statuses.find(item => item.status === 'Sold')!.sideCount;
            const paidCount = statistic.statuses.find(item => item.status === 'Paid')!.sideCount;
            const freeCount = statistic.statuses.find(item => item.status === 'Free')!.sideCount;
            const fitForStatus = (reservedChecked && reservedCount > 0 || bookedChecked && bookedCount > 0 || soldChecked && soldCount > 0
                || paidChecked && paidCount > 0 || freeChecked && freeCount > 0);

            if(fitForStatus) {
                const filterBillboards = billboardsShortInfo.find(item => item.geoMarkId === statistic.geoMarkId);
                if(filterBillboards) {
                    resultFilter.push(filterBillboards);
                }
            }
        }

        return resultFilter

    }

    markersBillboard() {
        const { billboardsShortInfo, fullMonthStatistic } = this.props;

        const statisticBillbaords = this.fillterBillboards(fullMonthStatistic, billboardsShortInfo);
        
        const markersBillboard = statisticBillbaords.map((billboard) => ({
            id: billboard.geoMarkId,
            lat: billboard.geoPoint ? billboard.geoPoint.latitude : 0,
            lng: billboard.geoPoint ? billboard.geoPoint.longitude : 0,
        }));

        return markersBillboard;
    }
    
    getClusters = () => {
        const { clusterZoomLevel } = this.props;
        const clusters = supercluster(this.markersBillboard(), {
          minZoom: 0,
          maxZoom: clusterZoomLevel,
          radius: 120
        });
    
        return clusters(this.state.mapOptions);
    };

    createClusters = (props: any) => {
        this.setState({
            clusters: this.state.mapOptions.bounds ? this.getClusters() : [],
        });
    };
    
    handleMapChange = (value: ChangeEventValue) => {
        this.setState(
            {
              mapOptions: {
                center: value.center,
                zoom: value.zoom,
                bounds: value.bounds,
              },
            },
            () => {
                this.createClusters(this.props);
            }
        );
    };

    fitBoundsByBillboards = (billboardsShort: BillboardsShortInfo[]) => {
        if(billboardsShort.length === 0) return;
        const bounds = this.getMapBounds(billboardsShort);
        if(bounds && this.map.current.map_) {
            this.map.current.map_.fitBounds(bounds);
        }
    }

    resetBounds = () => {
        const { billboardsShortInfo } = this.props;
        this.fitBoundsByBillboards(billboardsShortInfo);
    }

    handleClusterClick = (cluster: Cluster) => {
        const { billboardsShortInfo } = this.props;
        const clusterId = cluster.points.map(point => point.id);
        const fillterBillboard = billboardsShortInfo.filter(billboard => clusterId.indexOf(billboard.geoMarkId) >= 0);
        this.fitBoundsByBillboards(fillterBillboard);
    }

    clusterContextMenuOpen = (cluster: Cluster) => (event: React.MouseEvent<HTMLButtonElement | HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    clusterContextMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleFilter() {
        this.setState({
            toggleFilter: !this.state.toggleFilter
        })
    }

    allResetFiltersShow() {
        const { billboardFilters, allResetFilters } = this.props;
        if(billboardFilters && billboardFilters.propertyValueIds && billboardFilters.propertyValueIds.length > 0) {
            return (
                <Tooltip title="Сбросить фильтр" placement="right">
                    <Fab size="small" color="default"
                        className="filter-billboard__icon-clear"
                        onClick={() => allResetFilters()}>
                        <CloseIcon color="secondary" />
                    </Fab>
                </Tooltip>
            )
        }
    }

    statusErrorClose () {
        this.setState({statusError: false});
    }

    successMessageClose() {
        this.setState({
            successMessageOpen: false
        });
        this.props.successClientAction(null);
        this.props.eventErrorAction(null)
    }

    resetFilterBillboards() {
        const { billboardFiltersAction, dontSaleFilterAction, billboardsShortInfoAction, allResetFilters} = this.props;
        SystemEvent.SubscribeEventGetBillboardsShortInfo(
            "Map", 
            (answer) => {
                if(answer.eventKey === this.eventKey) {
                    billboardsShortInfoAction(answer.billboards);
                    allResetFilters();
                }
            }, (error) => {
                errorCallback(error);
            }
        );
        let newbillboardFilters: BillboardFilters = {
            propertyValueIds: [],
            status: GeoObjectStatus.used
        }
        billboardFiltersAction(newbillboardFilters);
        dontSaleFilterAction(false);
        this.eventKey = SystemEvent.EventGetBillboardsShortInfo(newbillboardFilters);
    }


    render() {
        const { statuses, clusterMinPoints, billboardShort, dates, rollUpCards, dontSaleFilter } = this.props;
        const { toggleFilter, messageOpen } = this.state;
        return (
            <div className="map-container" style={{ height: '100vh', width: '100%', position: 'relative' }}>
                {dontSaleFilter && 
                    <Tooltip title="Сбросить фильтр конструкций запрещеные к продаже">
                        <Fab 
                            color="secondary" 
                            size="small" 
                            className="resetFilter"
                            onClick={this.resetFilterBillboards.bind(this)}>
                            <FilterListIcon />
                        </Fab>
                    </Tooltip>
                }
                <div className="rollUpBtnRow">
                    {rollUpCards.map(item => (
                        <span key={item.key}>
                            <RollUpBtn rollUpCard={item} />
                        </span>
                    ))}
                </div>
                <DateInterval
                    selectedBillboard={billboardShort} />
                <ResetButton resetBounds={this.resetBounds} />
                <ProjectAddSnackbar dates={dates}/>
                <SuccessSnackbar label="Добавлено в проект!" />
                <Paper className="advancedSearch">
                    <AdvancedSearch 
                        advancedSearch={true} 
                        label="Поиск"
                        selectedMultipleMarker={(multipleMarker: IMultipleMarker) => 
                            this.onAddMultipleMarker(multipleMarker)
                        }
                    />
                </Paper>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: BUILD_PARAMS.GOOGLE_MAPS_API_KEY || ''}}
                    defaultZoom={MAP.defaultZoom}
                    defaultCenter={MAP.defaultCenter}
                    options={MAP.options}
                    onChange={this.handleMapChange}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps, this.props.billboardsShortInfo)}
                    ref={this.map}
                    >
                    {this.state.clusters.map((cluster: Cluster) => {
                        if(cluster.numPoints < clusterMinPoints) {
                            const { billboardShort, billboardsShortInfo } = this.props;
                            const billboardIdInCluster = cluster.points.map(point => point.id);
                            const billboardInCluster = billboardsShortInfo.filter(billboard => billboardIdInCluster.indexOf(billboard.geoMarkId) >= 0 && (!billboardShort || billboard.geoMarkId === billboardShort.geoMarkId));
                            const billboardInMarker = billboardInCluster.map(billboard => { 
                                return (
                                    <MarkerBillboard 
                                        lat={billboard.geoPoint ? billboard.geoPoint.latitude : 0} 
                                        lng={billboard.geoPoint ? billboard.geoPoint.longitude : 0}
                                        statuses={getBillboardStatuses(statuses, billboard)}
                                        dates={dates}
                                        text={billboard.gid} 
                                        key={'billboard-' + billboard.geoMarkId}
                                        onClick={this.navigateToBillboard(billboard)}>
                                    </MarkerBillboard>
                                );
                            })

                            return billboardInMarker;

                        } else {
                            return (
                                !billboardShort &&
                                <ClusterMarker
                                    key={'cluster-' + cluster.points[0].id}
                                    lat={cluster.wy}
                                    lng={cluster.wx}
                                    count={cluster.numPoints}
                                    onClick={() => this.handleClusterClick(cluster)}
                                    onContextMenu={() => this.clusterContextMenuOpen(cluster)}
                                    clusterContextMenuClose={this.clusterContextMenuClose}
                                    anchorEl={this.state.anchorEl}
                                    billboardIds={getBilboardIds(cluster)}>
                                </ClusterMarker>
                            );
                        }
                    })}
                </GoogleMapReact>
                {/* <ProjectSelector currentCompanyAccountId={currentCompanyAccountId} /> */}
                {this.props.openProjectModal && 
                    <ProjectCard 
                        handleChangeDates={this.handleChangeDates.bind(this)} 
                        dates={dates} />
                }
                <WidgetPanel positionPanel="left" settingsKey="statusToggle">
                    <div className="filter-billboard-row">
                        <Fab color="primary" size="medium" 
                            className="filter-billboard__icon"
                            onClick={this.handleFilter.bind(this)}>
                            <KeyboardArrowDownIcon style={{transform: this.state.toggleFilter ? 'rotate(180deg)' : 'rotate(0)'}} />
                        </Fab>
                        {this.allResetFiltersShow()}
                    </div>
                    <div className="map-cards">
                        <div className="map-cards__block date-range border border-medium p-2 bg-light rounded mb-1">
                            <BillboardStatus 
                                handleChangeDates={this.handleChangeDates.bind(this)} 
                                dates={dates}
                            />
                        </div>
                        <AdvancedFilter toggleFilter={toggleFilter}/>
                        <div className="billboardSelected">
                            { billboardShort !== undefined &&                       
                                // <BillBoardDetail
                                //     billboard={undefined}
                                //     statuses={getBillboardStatuses(statuses, billboardShort)}
                                //     onClose={()=> this.props.billboardShortAction(undefined)}
                                //     translate={this.props.translateFunc}
                                //     billboardSaving={this.props.billboardSaving}
                                //     onUpdateBillboard={this.updateBillboard}
                                //     dates={dates}
                                // />
                                <BillboardInfo 
                                    billboardShort={billboardShort} 
                                    dates={dates}
                                />
                            }
                        </div>
                    </div>
                </WidgetPanel>
                {this.props.openSelectedBillboards && 
                    <ProjectSelectedCard 
                        handleChangeDates={this.handleChangeDates.bind(this)} 
                        dates={dates} 
                        showProjectBillboardsPoints={(geoPoints: LatLong[], assignedObjects: AssignedObject[]) => 
                            this.showProjectBillboardsPoints(geoPoints, assignedObjects)} 
                    />
                }
                {this.props.openTariffCard && 
                    <TariffCard />
                }
                {this.props.openAdditionalTariff && 
                    <AdditionalTariffs />
                }
                {this.props.openBillsCard && 
                    <BillsCard />
                }
                {this.props.openBrandsCard && 
                    <BrandsCard 
                        selectedBrandMarker={(brandMarkers: IMultipleMarker[]) => 
                            this.onAddBrandMarker(brandMarkers)
                        }
                    />
                }
                {this.props.salesStatisticsToggle && 
                    <SalesStatistics />
                }
                {this.props.cityStatistics &&
                    <CityStatistics />
                }
                {this.props.openAdditionalCost && 
                    <AdditionalCostContainer />
                }
                {this.props.dontSale && 
                    <DontSaleCard />
                }
                {this.props.openPhotocontrolCard && 
                    <PhotocontrolCard />
                }
                {/* {this.props.openAddBillboard && 
                    <BillboardAdd
                    isEdit={false}
                    billboard={billboardShort}   />
                } */}
                <MessageShackbar 
                    message="Письмо успешно отправлено!"
                    variant="success"
                    messageOpen={messageOpen} 
                    vertical="bottom"
                    horizontal="center" 
                    messageShackbarClose={this.messageShackbarClose.bind(this)} />
                <MessageShackbar 
                    message={this.state.statusErrorMessage}
                    variant="error"
                    messageOpen={this.state.statusError} 
                    vertical="top"
                    horizontal="center" 
                    messageShackbarClose={this.statusErrorClose.bind(this)} />
                <MessageShackbar 
                    message={this.props.errorMessage}
                    variant="error"
                    messageOpen={this.state.errorMessageOpen} 
                    vertical="top"
                    horizontal="center" 
                    messageShackbarClose={this.errorMessageClose.bind(this)} />
                {this.props.successClient && 
                    <MessageShackbar 
                        message={this.props.successClient}
                        variant="success"
                        messageOpen={this.state.successMessageOpen} 
                        vertical="top"
                        horizontal="center" 
                        messageShackbarClose={this.successMessageClose.bind(this)} />
                }
                {this.props.eventError && 
                    <MessageShackbar 
                        message={this.props.eventError.errorMessage}
                        variant="error"
                        messageOpen={this.state.successMessageOpen} 
                        vertical="top"
                        horizontal="center" 
                        messageShackbarClose={this.successMessageClose.bind(this)} />
                }
                {this.props.openNextBillboard &&
                    <NextBillboardList 
                        openNextBillboard={this.props.openNextBillboard} 
                        nextBillboardClose={() => this.props.openNextBillboardAction(false)} 
                        selectedBrandMarker={(brandMarkers: IMultipleMarker[], brandGeoObject: BrandGeoObjects) => 
                            this.onShowNextBillboards(brandMarkers, brandGeoObject)
                        } />
                }
                {this.props.usageInfo && 
                    <BillBoardDetailedUsageInfo />
                }
                {this.props.openEmployeesCard &&
                    <Employees 
                        openEmployeesCard={this.props.openEmployeesCard} />
                }
                {this.props.openGeoMarkTariff && 
                    <GeoMarkTariffCard />
                }
                {this.props.successMessage && 
                    <MessageShackbar 
                        message={this.props.successMessage}
                        variant="success"
                        messageOpen={Boolean(this.props.successMessage)} 
                        vertical="top"
                        horizontal="center" 
                        messageShackbarClose={() => this.props.successMessageAction(null)} />
                }
            </div>
        );
    }
}

const getBilboardIds = (cluster: Cluster) => {
    const billboardIds = cluster.points.map(billboard => {
        return billboard.id;
    });

    return billboardIds;
}


const getBillboardStatuses = (statuses: GeoMarkTimeStatus[], billboard: BillboardsShortInfo) => {
    return statuses
        // .filter(s => s.geoMarkId === billboard.geoMarkId && billboard.getAllSides().indexOf(s.geoMarkPart) >= 0);
        .filter(s => s.geoMarkId === billboard.geoMarkId);
}


const mapStateToProps = (state: RootState) => ({
    // billboards: selectors.billboards.allBillboards(state.billboards),
    filterBillboards: state.billboards.filterBillboards,
    geomarks: state.billboards.geomarks,
    translateFunc: getTranslate(state.localize),
    billboardSaving: state.billboards.geomarkSaving,
    statuses: selectors.billboards.getAllStatuses(state.billboards),
    clusterZoomLevel: state.app.clusterZoomLevel,
    clusterMinPoints: state.app.clusterMinPoints,
    reservedChecked: state.app.reservedChecked,
    bookedChecked: state.app.bookedChecked,
    soldChecked: state.app.soldChecked,
    paidChecked: state.app.paidChecked,
    freeChecked: state.app.freeChecked,
    currentCompanyAccountId: state.todo.currentCompanyAccountId,
    selectedBillboard: state.billboards.billboard,
    billboardShort: state.billboards.billboardShort,
    constructionType: state.app.constructionType,
    format: state.app.format,
    selectedSide: state.app.selectedSide,
    light: state.app.light,
    billboardType: state.app.billboardType,
    billboardAB: state.app.billboardAB,
    selectedAreaName: state.billboards.selectedAreaName,
    selectedCityName: state.billboards.selectedCityName,
    eventName: state.event.eventName,
    saveError: selectors.billboards.getSaveStatusError(state.billboards),
    dates: state.todo.dates,
    projectID: selectors.todo.getProjectID(state.todo),
    getAddress: state.billboards.getAddress,
    errorMessage: state.event.errorMessage,
    successClient: state.customers.successClient,
    maxDistance: state.event.maxDistance,
    brandGeoObjects: state.event.brandGeoObjects,
    newBrandGeoObjects: state.event.newBrandGeoObjects,
    maxDistanceSearch: state.event.maxDistanceSearch,
    showMarkers: state.event.showMarkers,
    openNextBillboard: state.event.openNextBillboard,
    openSelectedBillboards: state.todo.openSelectedBillboards,
    openProjectModal: state.todo.openProjectModal,
    openTariffCard: state.billboards.openTariffCard,
    openAdditionalTariff: state.event.openAdditionalTariff,
    openBrandsCard: state.billboards.openBrandsCard,
    salesStatisticsToggle: state.event.salesStatisticsToggle,
    eventError: state.event.eventError,
    rollUpCards: state.event.rollUpCards,
    openAdditionalCost: state.todo.openAdditionalCost,
    usageInfo: state.event.usageInfo,
    openEmployeesCard: state.event.openEmployeesCard,
    openGeoMarkTariff: state.billboards.openGeoMarkTariff,
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    fullMonthStatistic: state.billboards.fullMonthStatistic,
    billboardFilters: state.app.billboardFilters,
    cityStatistics: state.event.cityStatistics,
    dontSale: state.event.dontSale,
    dontSaleFilter: state.app.dontSaleFilter,
    successMessage: state.event.successMessage,
    openBillsCard: state.event.openBillsCard,
    openAddBillboard: state.billboards.openAddBillboard,
    openPhotocontrolCard: state.event.openPhotocontrolCard,
});


const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    updateBillboard: (propertySet:IPropertySet,geaMark:GeoMark) => dispatch(actions.billboards.updateBillboard(propertySet,geaMark)),
    billboardShortAction: (billboardShort: BillboardsShortInfo | undefined) => 
        dispatch(actions.billboards.billboardShortAction(billboardShort)),
    allResetFilters: () => dispatch(actions.app.allResetFilters()),
    getAdditionalBillboardAction: (geoMarkId: number) => dispatch(actions.billboards.getAdditionalBillboardAction(geoMarkId)),
    filterBillboardsAction: (filterBillboards: Billboard[]) => dispatch(actions.billboards.filterBillboardsAction(filterBillboards)),
    resetSaveError: () => dispatch(actions.billboards.resetSaveErrorActionCreator()),
    datesAction: (dates: DatesRange) => dispatch(actions.todo.datesAction(dates)),
    errorMessageAction: (errorMessage: string) => dispatch(actions.event.errorMessageAction(errorMessage)),
    successClientAction: (successClient: string | null) => 
        dispatch(actions.customers.successClientAction(successClient)),
    brandGeoObjectAction: (brandGeoObject: BrandGeoObjects) => 
        dispatch(actions.event.brandGeoObjectAction(brandGeoObject)),
    maxDistanceSearchAction: (maxDistanceSearch: boolean) => 
        dispatch(actions.event.maxDistanceSearchAction(maxDistanceSearch)),
    showMarkersAction: (showMarkers: boolean) => 
        dispatch(actions.event.showMarkersAction(showMarkers)),
    openNextBillboardAction: (openNextBillboard: boolean) => 
        dispatch(actions.event.openNextBillboardAction(openNextBillboard)),
    eventErrorAction: (eventError: EventError | null) => 
        dispatch(actions.event.eventErrorAction(eventError)),
    rollUpCardsAction: (rollUpCards: RollUpCard[]) => 
        dispatch(actions.event.rollUpCardsAction(rollUpCards)),
    dontSaleFilterAction: (dontSale: boolean) => 
        dispatch(actions.app.dontSaleFilterAction(dontSale)),
    billboardFiltersAction: (billboardFilters: BillboardFilters | undefined) => 
        dispatch(actions.app.billboardFiltersAction(billboardFilters)),
    billboardsShortInfoAction: (billboardsShortInfo: BillboardsShortInfo[]) => 
        dispatch(actions.billboards.billboardsShortInfoAction(billboardsShortInfo)),
    successMessageAction: (successMessage: string | null) => 
        dispatch(actions.event.successMessageAction(successMessage)),
});


export default connect(
    mapStateToProps,mapDispatchToProps
)(Map);