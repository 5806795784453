import { CustomerActions, CustomerActionTypes, IUpdateCustomerFilters } from "./actions";
import { CustomerState, CustomerSearch } from './types';
import { Reducer } from "redux";
import { ClientInfo, GetCustomersFilter } from "sedi-webserverproxy";


export const getInitialFilterState = () => {
    const filter: GetCustomersFilter = {
        name: '',
        phone: '',
        email: '',
        inn: '',
        isLegal: true,
        isVip: null,
        companyId: undefined,
        registrationDateFrom: null,
        registrationDateTo: null,
        orderFrom: null,
        orderTo: null,
        accountId: undefined,
    }

    return filter;
}


export const getInitialSearchState = () => {
    const search: CustomerSearch = {
        componentID: '',
        filters: getInitialFilterState(),
        isLoading: false,
        errorText: undefined,
        foundCustomersIDs: [],
        foundCompaniesIDs: [],
        foundByInn: undefined
    };

    return search;
}


const defaultState: CustomerState = {
    search: [],
    filters: getInitialFilterState(),
    customers: [],
    companies: [],
    currentCompany: null,
    waitForSave: false,
    saveError: undefined,
    companyInfo: {
        agencyId: 0,
        officialAddress: {
            addressString: ''
        },
        officeAddress: {
            addressString: ''
        },
        postalAddress: {
            addressString: ''
        },
    },
    clientItem: null,
    successClient: null
};


export const searchReducer: Reducer<CustomerSearch[], CustomerActions> = (searches = [], action) => {
    // не у всех экшенов есть componentID
    const componentID = (action as IUpdateCustomerFilters).componentID;
    if (componentID === undefined) {
        return searches;
    }

    let modifiedSearch: CustomerSearch | undefined;
    // запоминаем исходные параметры
    let sourceSearch = searches.find(s => s.componentID === componentID);

    if (sourceSearch === undefined) {
        // если это первый экшен контрола, то добавляем доля него данные
        sourceSearch = getInitialSearchState();
        sourceSearch.componentID = componentID;

        modifiedSearch = sourceSearch;
    }

    switch (action.type) {

        case CustomerActionTypes.UPDATE_CUSTOMER_FILTERS:
            modifiedSearch = {
                ...sourceSearch,
                filters: action.filters
            };
            break;

        case CustomerActionTypes.SEARCH_CUSTOMERS:
            modifiedSearch = {
                ...sourceSearch,
                foundCustomersIDs: action.filter.isLegal === false ? [] : sourceSearch.foundCustomersIDs,
                foundCompaniesIDs: action.filter.isLegal === true ? [] : sourceSearch.foundCompaniesIDs,
                errorText: undefined,
                isLoading: true
            };
            break

        case CustomerActionTypes.SEARCH_CUSTOMERS_ERROR:
            modifiedSearch = {
                ...sourceSearch,
                errorText: action.error,
                isLoading: false
            };
            break;

        case CustomerActionTypes.SEARCH_CUSTOMERS_SUCCESS:
            modifiedSearch = {
                ...sourceSearch,
                foundCustomersIDs: action.filter.isLegal === false ? action.customers.map(c => c.id) : sourceSearch.foundCustomersIDs,
                foundCompaniesIDs: action.filter.isLegal === true ? action.customers.map(c => c.id) : sourceSearch.foundCompaniesIDs,
                isLoading: false
            };
            break;

        case CustomerActionTypes.SEARCH_INN:
            modifiedSearch = {
                ...sourceSearch,
                foundByInn: undefined,
                isLoading: true
            };
            break;

        case CustomerActionTypes.SEARCH_INN_SUCCESS:
            modifiedSearch = {
                ...sourceSearch,
                foundByInn: action.company,
                isLoading: false
            };
            break;
    }

    let resultSearches = searches;
    if (modifiedSearch !== undefined) {
        // иначе TS ругается, что может быть undefined в .filter()
        const defindedModifiedSearch = modifiedSearch;
        resultSearches = searches
            .filter(s => s.componentID !== defindedModifiedSearch.componentID)
            .concat([defindedModifiedSearch]);
    }

    return resultSearches;
}

//@ts-ignore
export const customerReducer: Reducer<CustomerState, CustomerActions> = (state = defaultState, action) => {
    const sourceSearches = state.search;
    const modifiedSearches = searchReducer(sourceSearches, action);

    switch (action.type) {

        case CustomerActionTypes.SUCCESS_CLIENT:
            return {
                ...state,
                successClient: action.successClient
            };
        case CustomerActionTypes.GET_COMPANY_INFO:
            return {
                ...state,
                companyInfo: action.companyInfo
            };
        case CustomerActionTypes.CLIENT_ITEM:
            return {
                ...state,
                clientItem: action.clientItem
            };
        case CustomerActionTypes.ADDRESS_OFFICIAL:
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    officialAddress: {
                        addressString: action.addressOfficial
                    }
                }
            };
        case CustomerActionTypes.ADDRESS_OFFICE:
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    officeAddress: {
                        addressString: action.addressOffice
                    }
                }
            };
        case CustomerActionTypes.ADDRESS_POSTAL:
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    postalAddress: {
                        addressString: action.addressPostal
                    }
                }
            };
        case CustomerActionTypes.CURRENT_COMAPNY:
            return {
                ...state,
                currentCompany: action.currentCompany
            };
        case CustomerActionTypes.CUSTOMERS_COMPANIES_LOADED:
            return {
                ...state,
                customers: action.filter.isLegal === false ? joinCustomers(state.customers, action.customers) : state.customers,
                companies: action.filter.isLegal === true ? joinCustomers(state.companies, action.customers) : state.companies
            };

        case CustomerActionTypes.SEARCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                search: modifiedSearches,
                customers: action.filter.isLegal === false ? joinCustomers(state.customers, action.customers) : state.customers,
                companies: action.filter.isLegal === true ? joinCustomers(state.companies, action.customers) : state.companies,
            };

        case CustomerActionTypes.RESET_SAVE_ERROR:
            return {
                ...state,
                search: modifiedSearches,
                saveError: undefined
            };

        case CustomerActionTypes.SAVE_CUSTOMER:
            return {
                ...state,
                search: modifiedSearches,
                waitForSave: true,
                saveError: undefined
            };

        case CustomerActionTypes.SAVE_CUSTOMER_ERROR:
            return {
                ...state,
                search: modifiedSearches,
                waitForSave: false,
                saveError: action.error
            };

        case CustomerActionTypes.SAVE_CUSTOMER_SUCCESS:
            let { customers, companies } = state;
            let index = -1;
            let isNewCustomer = false;

            const savedCustomer = action.customer;
            const isLegalEntity = savedCustomer.isLegalEntity;

            if (isLegalEntity === false) {
                action.customer.name = action.customer.secondName + ' ' + action.customer.firstName;

                index = customers.findIndex(c => savedCustomer.id === c.id);
                customers = joinCustomers(customers, [savedCustomer]);
                modifiedSearches.forEach(s => {
                    s.foundCustomersIDs.push(savedCustomer.id);
                });
            }
            else {
                index = companies.findIndex(c => savedCustomer.id === c.id);
                companies = joinCustomers(companies, [savedCustomer]);
                modifiedSearches.forEach(s => {
                    s.foundCompaniesIDs.push(savedCustomer.id);
                });
            }

            isNewCustomer = index < 0;

            return {
                ...state,
                search: modifiedSearches,
                waitForSave: false,
                saveError: undefined,
                customers,
                companies
            };
    }

    if (sourceSearches !== modifiedSearches) {
        return {
            ...state,
            search: modifiedSearches,
        }
    }

    return state;
};


function joinCustomers(prevClients: ClientInfo[], newClients: ClientInfo[]) {
    const newClientIDs = newClients.map(client => client.id);
    const prevNotFounded = prevClients.filter(client => newClientIDs.indexOf(client.id) < 0);
    const allClients = prevNotFounded.concat(newClients);

    return allClients;
}


export default customerReducer;