import React, { FunctionComponent } from 'react';
import SelectMaterial, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Translate } from "react-localize-redux";


type Props = SelectProps & {
    // TODO добавить поддержку других типов key-value
    dataSource: object
};


type SelectChangeParams = {
    name?: string,
    value: unknown
}


export type SelectChangeEvent = React.ChangeEvent<SelectChangeParams>;


const Select: FunctionComponent<Props> = ({ dataSource, ...selectProps }) => {

    const selectOptions = Object.entries(dataSource)
        .map(([enumKey, enumValue]) => <MenuItem key={enumKey} value={enumValue}><Translate id={enumKey} /></MenuItem>);

    return (
        <SelectMaterial {...selectProps}>
            {selectOptions}
        </SelectMaterial>
    );
};


export default Select;