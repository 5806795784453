export default function swDev() {

    function urlBase64ToUint8Array(base64String: string) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');
       
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
       
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    function determineAppServerKey() {
        const vapiPublicKey = "BC6DVftJN7K-_SJw5Hpt9ttQmlpy8Wd3JyGwUEQnT6NOOWOEbSisUq6iQ0KRPvwKIsHRGoYt9KLr9QgCBhIARJU";
        return urlBase64ToUint8Array(vapiPublicKey);
    }

    const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
    navigator.serviceWorker.register(swUrl).then((response) => {
        console.warn("response:", response);
        return response.pushManager.getSubscription()
        .then(function(subscription) {
            return response.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: determineAppServerKey()
            })
        })
    });
}