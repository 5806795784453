/* App.js */
import React, { Component } from 'react';
import CanvasJSReact from '../../utils/Canvasjs/canvasjs.react';
import { BillboardsAverageMonthUsage, IBillboardsUsageInfoItems} from '../../RemoteCommands/type';
import { getMonthYearDate } from '../../utils/getMonthYear';
import { DatesRange } from './InputDatesRange';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

type Props = {
    billboardsUsageInfoResult: IBillboardsUsageInfoItems[];
    billboardsAverageMonthUsage: BillboardsAverageMonthUsage[];
    dates: DatesRange;
    isFixedSum: boolean;
}
type State = {
    chartType: string;
}

class GraphsChartsUsageInfo extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    getDataPoints() {
        const { dates, billboardsAverageMonthUsage } = this.props;

        let dataPoints: DataPoint[] = [];
        // const allItems = billboardsUsageInfoResult.flatMap(a => a.items);
        // const billboardGID = billboardsUsageInfoResult.map(item => item.gid);
        for(let monthYear of getMonthYearDate(dates)) {
            const items = billboardsAverageMonthUsage.filter(usage => usage.month === monthYear.monthNumber && usage.year.toString() === monthYear.year);
            // const y = items.length > 0 ? items.map(item => item.usagePercent).reduce(function(acc, val) { return acc + val }, 0) / billboardGID.length : 0;
            const y = items.map(item => item.usagePercent)[0]
            dataPoints.push({
                label: monthYear.month +" "+ monthYear.year,
                y
            });
        }
        return dataPoints;
    }

	render() {

        let options = {
            width: 968,
            animationEnabled: true,
			exportEnabled: true,
			theme: "light2", //"light1", "dark1", "dark2"
			axisY: {
				includeZero: true,
				suffix: this.props.isFixedSum ? " руб." : "%"
            },
			data: [
                {
                    // Change type to "doughnut", "line", "splineArea", etc.
                    type: "column",
                    dataPoints: this.getDataPoints()
                }
            ]
        }
		
		return (
            <div className="сanvasJSChart">
                <CanvasJSChart options={options} />
            </div>
		);
	}
}

export interface DataPoint {
    label: string;
    y: number;
}

export default GraphsChartsUsageInfo;