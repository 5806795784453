import React, {FunctionComponent} from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

type InputField = {
    label: string;
    value: number | string;
    handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
}

const InputField: FunctionComponent<InputField> = (props) => {
    const { label, value, handleChange } = props;
    return (
        <Grid item xs={12}>
            <FormControlLabel
                value="start"
                labelPlacement="start"
                label={label}
                control={
                    <TextField
                        id="outlined-name"
                        value={value}
                        variant="outlined"
                        margin="dense"
                        className="ml-2"
                        type="number"
                        onChange={handleChange}
                    />
                }>
            </FormControlLabel>
        </Grid>
    )
}

export default InputField;