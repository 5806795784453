import { IMessage, MessageHub } from "./MessageHub";

export interface ITestMessage extends IMessage {
    progress: number;
}

export class MessageHelper {
    static sendProgress(progress: number) {
        const sendMessage: ITestMessage = {
            messageKey: "sendProgressKey", 
            progress
        };

        MessageHub.Send(sendMessage);
    }

    static subscribeProgress(pageName: string, callback: (message: ITestMessage) => void) {
        MessageHub.Subscribe(
            "sendProgressKey", 
            pageName, 
            (message) => callback(message as ITestMessage)
        );
    }
}