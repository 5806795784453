import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../../store';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { MessageInfo, RollUpCard } from '../../../store/event/types';
import { DefaultPosition } from '../../../store/app/types';
import { 
    Button, Card, CardActions, CardContent, CardHeader, 
    Checkbox, CircularProgress, FormControlLabel, Grid, 
    IconButton, Paper, Tooltip 
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldUniversal from '../../bricks/TextFieldUniversal';
import GetAddressAutocomplete from '../../bricks/GetAddressAutocomplete';
import { 
    AddressInfo, IProperty
} from 'sedi-webserverproxy';
import { errorCallback, GeoMarkParams, IProperties, PropertyValues, SystemEvent } from '../../../RemoteCommands/SystemEvent';
import FormControlUniversal from '../../bricks/FormControlUniversal';
import { SelectChangeEvent } from '../../bricks/Select';
import './BillboardAdd.scss';
import { BillboardsShortInfo, GeoObjectStatus, ISideProperties } from '../../../RemoteCommands/type';
import { DatesRange } from '../../bricks/InputDatesRange';
import moment from 'moment';
import { removeItemAtIndex } from '../../../utils/replaceItem';
import BUILD_PARAMS from '../../../utils/build';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    openAddBillboard: boolean;
    addBillboardCloseClick: () => void;
    billboard: BillboardsShortInfo;
    sideProperties: ISideProperties[];
    name: string;
    isEdit: boolean;
    isSide: boolean;
    dates: DatesRange;
    geoObjectStatus: GeoObjectStatus;
}

const BillboardAdd:React.FC<Props> = (props) => {
    const {
        positionBillboardAddAction,
        draggable,
        positionBillboardAdd,
        rollUpCards,
        rollUpCardAction,
        openAddBillboard,
        selectedAddress,
        addBillboardCloseClick,
        billboard,
        sideProperties,
        name,
        isEdit,
        propertySet,
        isSide,
        messageInfoAction,
        dates,
        userInfo,
        geoObjectStatus,
        billboardsShortInfoAction,
        billboardsShortInfo,
        forbiddenBillboardsAction,
        forbiddenBillboards,
        dontSaleFilter,
        billboardShortAction,
        deleteTwoArraysAction
    } = props;

    const [addressInfo] = useState<AddressInfo | null>(null);
    const [geoMarkParams, setGeoMarkParams] = useState<GeoMarkParams>({
        geoMarkId: -1,
        geoMarkName: "",
        geoMarkTypeId: BUILD_PARAMS.GEO_MARK_TYPE_ID,
        objectId: ""
    });
    const initailCheced = geoObjectStatus === GeoObjectStatus.used ? true : false;
    const currentPropertyValues = useRef<{[name: string]: number}>({});
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = React.useState(initailCheced);
    const eventKeySaveGeoMark = useRef<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const billboardSide = isSide ? "Side1" : "Side2";

    const changeHandler = (value: string | number, name: string) => {
        if(name === "latitude" || name === "longitude") {
            if(selectedAddress) {
                const city = selectedAddress.c ? selectedAddress.c + ', ' : ''; 
                const street = selectedAddress.s ? selectedAddress.s + ', ' : '';
                setGeoMarkParams(prevState => ({
                    ...prevState,
                    address: {
                        countryName: selectedAddress.co,
                        cityName: selectedAddress.c,
                        streetName: selectedAddress.s,
                        objectName: selectedAddress.t === "o" ? selectedAddress.v : "",
                        houseNumber: selectedAddress.t === "h" ? selectedAddress.v : "",
                        geoPoint: {
                            latitude: selectedAddress.g.lat,
                            longitude: selectedAddress.g.lon
                        },
                        addressString: city + street + selectedAddress.v,
                    }
                }));
            }

            if(name === "latitude") {
                setGeoMarkParams(prevState => ({
                    ...prevState,
                    geoPoint: {
                        ...prevState.geoPoint!,
                        latitude: Number(value)
                    }
                }));
            }
            if(name === "longitude") {
                setGeoMarkParams(prevState => ({
                    ...prevState,
                    geoPoint: {
                        ...prevState.geoPoint!,
                        longitude: Number(value)
                    }
                }));
            }
        } else {
            setGeoMarkParams(prev => ({...prev, ...{
                [name]: value as any
            }}));
        }   
    }

    const propertySelectChange = (event: SelectChangeEvent) => {
        const propertyId = Number(event.target.name);
        const propertyValueId = event.target.value as any;
        const properties = currentPropertyValues.current;
        properties[propertyId] = propertyValueId;
        currentPropertyValues.current = properties;
    }

    useEffect(() => {
        if(isEdit) {
            let values: { [name: string]: number } = {};
            for(let property of sideProperties) {
                values[property.propertyId] = property.propertyValueId
            }
            currentPropertyValues.current = values;
        }
        SystemEvent.SubscribeEventSaveGeoMark(
            "BillboardAdd", 
            (answer) => {
                const propertyValues: PropertyValues[] = [];
                for (const [key, value] of Object.entries(currentPropertyValues.current)) {
                    propertyValues.push({
                        PropertyId: Number(key),
                        PropertyValueId: value
                    })
                }
                SystemEvent.EventSaveGeomarkProperties(answer.geoMarkId, propertyValues, billboardSide);
            }, 
            (error) => errorCallback(error)
        );
        SystemEvent.SubscribeEventSaveGeomarkProperties(
            "BillboardAdd", 
            (answer) => {
                if(isEdit) {
                    messageInfoAction({
                        text: "Успешно отредактировали!",
                        variant: "success"
                    });
                    setLoading(false);
                } else {
                    messageInfoAction({
                        text: "Успешно добавлено!",
                        variant: "success"
                    });
                    setLoading(false);
                }
                addBillboardCloseClick();
                const dateStart = moment.utc(dates.DateFrom, "YYYY-MM-DD").format('DD.MM.YYYY');
                const dateEnd = moment.utc(dates.DateTo, "YYYY-MM-DD").format('DD.MM.YYYY');
                SystemEvent.EventGetBillboardInfo(billboard.geoMarkId, dateStart, dateEnd);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
    }, []);

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        positionBillboardAddAction(position);
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: `${isEdit ? "Редактировать" : "Добавить"} billboard`,
            key: "AdditionalCost"
        });
    }

    const saveGeomarkClick = () => {
        // setLoading(true);
        if(geoMarkParams) {
            eventKeySaveGeoMark.current = SystemEvent.EventSaveGeoMark(geoMarkParams);
        }
    }

    const saveEditGeomarkClick = () => {
        if(initailCheced !== checked || name !== geoMarkParams.geoMarkName) {
            const geoMark: GeoMarkParams = {
                geoMarkId: billboard.geoMarkId,
                geoMarkName: name !== geoMarkParams.geoMarkName ? geoMarkParams.geoMarkName : billboard.name,
                geoMarkTypeId: BUILD_PARAMS.GEO_MARK_TYPE_ID,
                objectId: billboard.gid,
                geoMarkStatus: checked ? GeoObjectStatus.used : GeoObjectStatus.outOfService
            }
            eventKeySaveGeoMark.current = SystemEvent.EventSaveGeoMark(geoMark);
            const indexActive = billboardsShortInfo.findIndex((listItem) => listItem.geoMarkId === billboard.geoMarkId && !checked);
            if(indexActive > 0) {
                if(dontSaleFilter) {
                    deleteTwoArraysAction(billboard.geoMarkId);
                } else {
                    const newList = removeItemAtIndex(billboardsShortInfo, indexActive);
                    billboardsShortInfoAction(newList);
                    forbiddenBillboardsAction([
                        ...forbiddenBillboards,
                        billboard
                    ]);
                }
                billboardShortAction(undefined);
            }
        } else {
            const propertyValues: PropertyValues[] = [];
            for (const [key, value] of Object.entries(currentPropertyValues.current)) {
                propertyValues.push({
                    PropertyId: Number(key),
                    PropertyValueId: value
                });
            }
            setLoading(true);
            SystemEvent.EventSaveGeomarkProperties(billboard.geoMarkId, propertyValues, billboardSide);
        }
    }

    const initialValueFunc = (property: IProperty) => {
        if(isEdit) {
            const billboardProperty = sideProperties.find(item => item.propertyId === property.propertyId);
            if(billboardProperty) {
                return billboardProperty.propertyValueId
            }
        }
        return "";
    }

    const filterPropertyId = (item: IProperties) => {
        if(isEdit) {
            if(isSide) {
                return (item.propertyId !== 442 && item.propertyId !== 440 && item.propertyId !== 452);
            } 
            return (item.propertyId !== 443 && item.propertyId !== 439 && item.propertyId !== 449 && item.propertyId !== 450)
        } 
        return item
    }

    const rollUpCardHas = rollUpCards.some(item => item.key === "AddBillboard");

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={positionBillboardAdd}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card 
                className={`
                    selectedCard ${openAddBillboard ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `} 
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                onClick={() => addBillboardCloseClick()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <div className="box-title">
                                    {isEdit ? "Редактировать конструкцию"  : "Добавить"}
                                </div>
                            </Grid>
                            {isEdit && userInfo && userInfo.userRole === "SalesDirector" &&
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        className="allowed-sales" 
                                        control={
                                            <Checkbox 
                                                checked={checked}
                                                onChange={handleChange}
                                                color="primary" />
                                        } 
                                        label="Разрешена для продаж" />
                                </Grid>
                            }
                        </Grid>
                    }
                />
                <CardContent className="add-billboard">
                    <Grid container spacing={2}>
                        {!isEdit && 
                            <Grid item xs={12}>
                                <Paper variant="outlined" className="p-3">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <GetAddressAutocomplete 
                                                addressInfo={addressInfo} 
                                                placeholder="Поиск адресов" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextFieldUniversal 
                                                label="Широта" 
                                                type="number" 
                                                value={selectedAddress ? selectedAddress.g.lat : undefined} 
                                                onChange={(value: string | number, name: string) => changeHandler(value, name)} 
                                                name="latitude" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextFieldUniversal 
                                                label="Долгота" 
                                                type="number" 
                                                value={selectedAddress ? selectedAddress.g.lon : undefined} 
                                                onChange={(value: string | number, name: string) => changeHandler(value, name)} 
                                                name="longitude" />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextFieldUniversal 
                                label="Названия" 
                                required={true} 
                                onChange={(value: string | number, name: string) => changeHandler(value, name)} 
                                name="geoMarkName" 
                                value={isEdit ? name : geoMarkParams.geoMarkName} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldUniversal 
                                label="GID" 
                                required={true} 
                                onChange={(value: string | number, name: string) => changeHandler(value, name)} 
                                name="objectId" 
                                value={isEdit ? billboard.gid : ""} 
                                disabled={isEdit} />
                        </Grid>
                        {propertySet && 
                            propertySet.properties
                                .filter(item => 
                                    filterPropertyId(item)
                                )
                                .filter(item => item.propertyName !== "PropertyValueSetIdSideB")
                                .filter(item => item.propertyName !== "PropertyValueSetIdSide2")
                                .map(item => {
                                    return (
                                        <Grid 
                                            key={item.propertyId}
                                            item 
                                            xs={12}>
                                            <FormControlUniversal 
                                                labelText={item.localizedName} 
                                                initialValue={initialValueFunc(item)} 
                                                onChange={(event: SelectChangeEvent) => propertySelectChange(event)} 
                                                propertyValues={item.values} 
                                                name={item.propertyId.toString()} />
                                        </Grid>
                                    )
                            })
                        }
                    </Grid>
                </CardContent>
                <CardActions className="cardActions">
                    <Button 
                        color="secondary"
                        variant="contained"
                        onClick={addBillboardCloseClick}>
                        Отменить
                    </Button>
                    {isEdit ? 
                        <Button 
                            color="primary"
                            variant="contained"
                            onClick={saveEditGeomarkClick}
                            disabled={loading}
                            startIcon={loading && 
                                <CircularProgress 
                                    color="secondary" 
                                    size={24} 
                                    thickness={4} 
                                />
                            }>
                            Сохранить
                        </Button>
                    :
                        <Button 
                            color="primary"
                            variant="contained"
                            onClick={saveGeomarkClick}
                            disabled={loading}
                            startIcon={loading && 
                                <CircularProgress 
                                    color="secondary" 
                                    size={24} 
                                    thickness={4} 
                                />
                            }>
                            Добавить
                        </Button>
                    }
                </CardActions>
            </Card>
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => ({
    rollUpCards: state.event.rollUpCards,
    draggable: state.app.draggable,
    positionBillboardAdd: state.app.positionBillboardAdd,
    selectedAddress: state.event.selectedAddress,
    selectedSide: state.billboards.selectedSide,
    propertySet: state.billboards.propertySet,
    userInfo: state.user.userInfo,
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    forbiddenBillboards: state.billboards.forbiddenBillboards,
    dontSaleFilter: state.app.dontSaleFilter
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    positionBillboardAddAction: (positionBillboardAdd: DefaultPosition) => 
        dispatch(actions.app.positionBillboardAddAction(positionBillboardAdd)),
    messageInfoAction: (messageInfo: MessageInfo | null) => 
        dispatch(actions.event.messageInfoAction(messageInfo)),
    billboardsShortInfoAction: (billboardsShortInfo: BillboardsShortInfo[]) => 
        dispatch(actions.billboards.billboardsShortInfoAction(billboardsShortInfo)),
    forbiddenBillboardsAction: (billboardsShortInfo: BillboardsShortInfo[]) => 
        dispatch(actions.billboards.forbiddenBillboardsAction(billboardsShortInfo)),
    billboardShortAction: (billboardShort: BillboardsShortInfo | undefined) => 
        dispatch(actions.billboards.billboardShortAction(billboardShort)),
    deleteTwoArraysAction: (id: number) => dispatch(actions.billboards.deleteTwoArraysAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillboardAdd);