import React from 'react';
import { IncomingCall } from './IncomingCall';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import ActionsStack from './ActionsStack';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ButtonsGroup from './ToDoAction/components/ButtonsGroup';


type Props = ReturnType<typeof mapStateToProps>;


class PortalToast extends React.Component<Props>{

    render() {
        const { hasActiveCall, isIncomingCall, TodoActions } = this.props;
        const openMessageDialog = TodoActions.find(item => item.DoIntermediate === 'OpenMessageDialog')

        // if(openMessageDialog) {
        //     return (
        //         <Dialog
        //             open={true}>
        //             <DialogTitle>{openMessageDialog.Header}</DialogTitle>
        //             <DialogContent>
        //                 {openMessageDialog.Description}
        //             </DialogContent>
        //             <DialogActions>
        //                 <ButtonsGroup actionButtons={openMessageDialog.Items[0].Buttons} />
        //             </DialogActions>
        //         </Dialog>
        //     );
        // }

        return (
            <Card className="table-bordered notificationRow">
                <ActionsStack />
                {hasActiveCall &&
                    <IncomingCall isIncomingCall={isIncomingCall} />
                }
            </Card>
        );
    }
}


const mapStateToProps = (state: RootState) => ({
    hasActiveCall: state.sip.hasActiveCall,
    isIncomingCall: state.sip.isIncoming,
    TodoActions: state.todo.TodoActions,
});


export default connect(
    mapStateToProps
)(PortalToast);