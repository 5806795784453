import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import AdditionalCostTable from './AdditionalCostTable';
import { IAdditionalCostParameters } from '../../../RemoteCommands/type';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    additionalCostParameters: IAdditionalCostParameters[];
    additionalCostNotSaved: boolean;
};

export const AdditionalCostTableContainer:React.FC<Props> = (props) => {
    const {
        projectID,
        currentGeoMarkStatus,
        additionalCostParameters,
        additionalCostNotSaved,
        disabledScoreSave,
        disabledScoreSaveAction,
        additionalCostParametersAction,
        disabledEditAction
    } = props;

    return (
        <AdditionalCostTable 
            additionalCostParameters={additionalCostParameters} 
            projectID={projectID} 
            currentGeoMarkStatus={currentGeoMarkStatus} 
            additionalCostNotSaved={additionalCostNotSaved} 
            disabledScoreSave={disabledScoreSave} 
            disabledScoreSaveAction={disabledScoreSaveAction} 
            additionalCostParametersAction={additionalCostParametersAction} 
            disabledEditAction={disabledEditAction} />
    );
}

const mapStateToProps = (state: RootState) => ({
    projectID: state.todo.projectID,
    currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
    disabledScoreSave: state.event.disabledScoreSave
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    disabledScoreSaveAction: (disabledScoreSave: boolean) => 
        dispatch(actions.event.disabledScoreSaveAction(disabledScoreSave)),
    additionalCostParametersAction: (additionalCostParameters: IAdditionalCostParameters[]) => 
        dispatch(actions.event.additionalCostParametersAction(additionalCostParameters)),
    disabledEditAction: (disabledEdit: boolean) => 
        dispatch(actions.event.disabledEditAction(disabledEdit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCostTableContainer);