import React, { Dispatch, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { RootState, selectors, actions } from '../../store'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import FirstPageIcon from '@material-ui/icons/FirstPage'
import DeleteIcon from '@material-ui/icons/Delete'
import { Tooltip, IconButton } from "@material-ui/core"
import { Settings } from '../../utils/settings'
import CloseIcon from '@material-ui/icons/Close';
import DeletePriceModal from './ProjectSelector/DeletePriceModal'
import { GeoMarkTimeStatus } from 'sedi-webserverproxy'
import { BillboardsShortInfo } from '../../RemoteCommands/type'

export enum TariffCost {
    montage = "montage",
    additionalMontage = "additionalMontage",
    print = "print",
    none = "none",
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
    currentBillboards: BillboardsShortInfo[],
    bilbordIds: number[],
    projectBillboardStatuses: GeoMarkTimeStatus[];
    setProjectBillboardStatuses: React.Dispatch<React.SetStateAction<GeoMarkTimeStatus[]>>
}

const TariffTable:React.FC<Props> = (props) => {

    const { 
        billboardTariffs, 
        currentBillboards, 
        projectID, 
        deleteGeoMartTimeStatuses, 
        getAdditionalBillboardAction, 
        openPriceAction, 
        projectDiscount, 
        billboardShort,
        projectBillboardStatuses, 
        setProjectBillboardStatuses
    } = props;
    
    const [deletePriceOpen, setDeletePriceOpen] = useState(false);
    const [selectedDelete, setSelectedDelete] = useState<TariffCost>(TariffCost.none);
    const [text, setText] = useState('');

    const deletePriceCloseClick = () => {
        setDeletePriceOpen(false);
        setSelectedDelete(TariffCost.none);
        setText('');
    };

    const deleteAllGeoMartTimeStatuses = (billboard: BillboardsShortInfo) => {
        const geoMarkTimeStatusIds = projectBillboardStatuses.filter(status => status.geoMarkId === billboard.geoMarkId).map(b => b.geoMarkTimeStatusId);
        const projectBillboards = projectBillboardStatuses.filter(status => status.geoMarkId !== billboard.geoMarkId)
        deleteGeoMartTimeStatuses(geoMarkTimeStatusIds);
        setProjectBillboardStatuses(projectBillboards);
    }

    const ttClick = (geoMarkId: number) => {
        getAdditionalBillboardAction(geoMarkId)
    }

    const showTariffs = () => {
        openPriceAction(false)
    }

    const clearAdditionalInstallationCost = () => {
        setDeletePriceOpen(true);
        setSelectedDelete(TariffCost.additionalMontage);
        setText('доп монтаж');
    }

    const clearPrintPrice = () => {
        setDeletePriceOpen(true);
        setSelectedDelete(TariffCost.print);
        setText('печать');
    }

    const clearMontagePrice = () => {
        setDeletePriceOpen(true);
        setSelectedDelete(TariffCost.montage);
        setText('монтаж');
    }

    return (
        <>
        <Table size="small" stickyHeader >
            <TableHead>
                <TableRow>
                    <TableCell style={{minWidth: 180}}><Translate id='BillboardListAddress'/></TableCell>
                    <TableCell align="center">Поверхность:</TableCell>
                    <TableCell style={{width: 110}} align="center">
                        <div>Монтаж:</div>
                        <Tooltip title="Удалить цену" placement="top">
                            <CloseIcon 
                                fontSize="small"
                                className="clearPrice"
                                onClick={clearMontagePrice}
                            />
                        </Tooltip>
                    </TableCell>
                    <TableCell style={{width: 114}} align="center">
                        <div>Доп монтаж:</div>
                        <Tooltip title="Удалить цену" placement="top">
                            <CloseIcon 
                                fontSize="small"
                                className="clearPrice"
                                onClick={clearAdditionalInstallationCost}
                            />
                        </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                        <div>Печать:</div>
                        <Tooltip title="Удалить цену" placement="top">
                            <CloseIcon 
                                fontSize="small"
                                className="clearPrice"
                                onClick={clearPrintPrice}
                            />
                        </Tooltip>
                    </TableCell>
                    <TableCell align="center">Прайс:</TableCell>
                    <TableCell align="center">Прайс со скидкой:</TableCell>
                    <TableCell align="center">TT</TableCell>
                    <TableCell align="center"><Translate id='BillboardDetailsGid' /></TableCell>
                    <TableCell align="center">
                        <Tooltip title="Показать свойства" placement="top">
                            <IconButton 
                                onClick={showTariffs} 
                                style={{backgroundColor: '#3f51b5', color: '#fff'}}>
                                <FirstPageIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {currentBillboards.map((billboard: BillboardsShortInfo) => {
                    const currentTariffA = projectDiscount.find(item => item.Gid === billboard.gid && item.Side.includes("A"));
                    const currentTariffB = projectDiscount.find(item => item.Gid === billboard.gid && item.Side.includes("B")); 

                    const tariff = billboardTariffs.find(price => price.geoMarkId === billboard.geoMarkId);

                    const showA = projectBillboardStatuses.some(status => 
                        status.geoMarkId === billboard.geoMarkId 
                        && status.geoMarkPart.includes('A') 
                        && status.projectId === projectID)
                    const showB = projectBillboardStatuses.some(status => 
                        status.geoMarkId === billboard.geoMarkId 
                        && status.geoMarkPart.includes('B') 
                        && status.projectId === projectID)

                    const totalCostA = tariff && tariff.billboardTariffs[0] ? tariff.billboardTariffs[0].totalCost : ''
                    const totalCostB = tariff && tariff.billboardTariffs[1] ? tariff.billboardTariffs[1].totalCost : ''

                    const sideTT = 'TT'
                    const imgSrcBig = Settings.useNewUrl ?  
                    `${Settings.imgUrlNew + billboard.geoMarkId}&imagetype=${sideTT}&bigPhoto=true`
                    : `${Settings.imgUrl + billboard.geoMarkId}&sideName=${sideTT}&bigPhoto=true`

                    return (
                        <>
                            <TableRow key={`tariff-${billboard.geoMarkId}`}>
                                <TableCell component="th" scope="row">
                                    <div className="nameLink" 
                                        onClick={() => billboardShort(billboard)}>
                                        {billboard.name}
                                    </div>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {currentTariffA && 
                                        <TableRow className="priceTableRow">
                                            <TableCell className="priceTableCell">A</TableCell>
                                        </TableRow>
                                    }
                                    {currentTariffB && 
                                        <TableRow className="priceTableRow">
                                            <TableCell className="priceTableCell">B</TableCell>
                                        </TableRow>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {showA && 
                                        <TableRow className="priceTableRow">
                                            <TableCell className="priceTableCell">
                                                {totalCostA}
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {showB && 
                                        <TableRow className="priceTableRow">
                                            <TableCell className="priceTableCell">
                                                {totalCostB}
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <a href={imgSrcBig} data-fancybox={`group${billboard.geoMarkId}`}>
                                        <button className="tt" onClick={() => ttClick(billboard.geoMarkId)}>tt</button>
                                    </a>
                                </TableCell>
                                <TableCell align="center">{billboard.gid}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title="Delete" placement="top">
                                        <DeleteIcon 
                                            fontSize="small" 
                                            onClick={() => deleteAllGeoMartTimeStatuses(billboard)}
                                            style={{ cursor: 'pointer' }}
                                            />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
        </Table>
        <DeletePriceModal 
            deletePriceOpen={deletePriceOpen}
            deletePriceCloseClick={deletePriceCloseClick} 
            billboardTariffs={billboardTariffs}
            selectedDelete={selectedDelete} 
            text={text} />
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    billboardTariffs: state.billboards.billboardTariffs,
    billboardTariffsOwner: state.billboards.billboardTariffsOwner,
    projectID: selectors.todo.getProjectID(state.todo),
    projectDiscount: state.todo.projectDiscount,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    deleteGeoMartTimeStatuses: (geoMarkTimeStatusIds: number[]) => dispatch(actions.billboards.deleteGeoMartTimeStatuses(geoMarkTimeStatusIds)),
    getAdditionalBillboardAction: (geoMarkId: number) => dispatch(actions.billboards.getAdditionalBillboardAction(geoMarkId)),
    openPriceAction: (openPrice: boolean) => dispatch(actions.billboards.openPriceAction(openPrice)),
    billboardShort: (billboardShort: BillboardsShortInfo | undefined) => dispatch(actions.billboards.billboardShortAction(billboardShort)),
    getBillboardTariffsAction: (geoMarkIds: number[]) => dispatch(actions.billboards.getBillboardTariffsAction(geoMarkIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffTable)