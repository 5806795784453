import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AgenciesAutocomplete from './AgenciesAutocomplete';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  List,
  Tooltip,
  DialogActions,
  Button,
  ListItemIcon,
  TextField,
  Fab,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import AddIcon from '@material-ui/icons/Add';
import AddAgency from './AddAgency';
import { Translate } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ClientInfo, Project } from 'sedi-webserverproxy';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import SaveIcon from '@material-ui/icons/Save';
import { getWebServerProxy } from '../../WebServerProxy';
import ProjectSelectItem from '../Projects/ProjectSelector/ProjectSelectItem';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AgenciesModal: React.FC<Props> = (props) => {
    const {
        companies,
        currentAgency,
        agencyInfo,
        currentCompany,
        companyInfo,
        agenciesOpenAction,
        agenciesOpen,
        setActiveProject,
        setAllProjects,
        projects,
        addNewAgencyAction,
    } = props;
    const [addAgencyOpen, setAddAgencyOpen] = useState(false);
    const [companyOpen, setCompanyOpen] = useState(false);
    const [addCompany, setAddCompany] = useState<ClientInfo | null>(null);
    const [waiting, setWaiting] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [baseDiscount, setBaseDiscount] = useState('');

    const addAgencyClickOpen = () => {
        setAddAgencyOpen(true);
        addNewAgencyAction(false);
    };

    const addAgencyClickClose = () => {
        setAddAgencyOpen(false);
    };

    const currentAgencyInfo = agencyInfo.find(
        (agency) =>
        agency.AgencyId === (currentAgency ? currentAgency.AgencyId : -1)
    );

    const currentCompanies = companies.filter(
        (company) =>
        currentAgencyInfo &&
        currentAgencyInfo.AgencyMemberIds.indexOf(company.id) >= 0
    );

  const deleteAgency = (id: number) => {
        const builderAgency = new EventBuilder(`EventChangeAgencyInfo`);
        builderAgency.AddParameter('AgencyId', currentAgency!.AgencyId.toString());
        builderAgency.AddParameter('AgencyName', currentAgency!.AgencyName);
        builderAgency.AddParameter('CommandType', 'DeleteCompaniesFromAgency');
        builderAgency.AddParameter('CompanyIds', JSON.stringify([id]));
        ServerProxy.SendSystemEvent(builderAgency.GetSystemEvent());
  };

  const agencyChangeClick = () => {
    if (currentCompany) {
        const builderAgencyDel = new EventBuilder(`EventChangeAgencyInfo`);
        builderAgencyDel.AddParameter(
            'AgencyId',
            companyInfo.agencyId.toString()
        );
        builderAgencyDel.AddParameter('AgencyName', currentAgency!.AgencyName);
        builderAgencyDel.AddParameter('CommandType', 'DeleteCompaniesFromAgency');
        builderAgencyDel.AddParameter(
            'CompanyIds',
            JSON.stringify([currentCompany.id])
        );
        ServerProxy.SendSystemEvent(builderAgencyDel.GetSystemEvent());

        const builderAgency = new EventBuilder(`EventChangeAgencyInfo`);
        builderAgency.AddParameter(
            'AgencyId',
            currentAgency!.AgencyId.toString()
        );
        builderAgency.AddParameter('AgencyName', currentAgency!.AgencyName);
        builderAgency.AddParameter('CommandType', 'AddCompaniesToAgency');
        builderAgency.AddParameter(
            'CompanyIds',
            JSON.stringify([currentCompany.id])
        );
        ServerProxy.SendSystemEvent(builderAgency.GetSystemEvent());
        agenciesOpenAction(false);

        setTimeout(() => {
            const builderCompanyInfo = new EventBuilder(`EventUserGetCompanyInfo`);
            builderCompanyInfo.AddParameter(
            'CompanyId',
            currentCompany.id.toString()
            );
            ServerProxy.SendSystemEvent(builderCompanyInfo.GetSystemEvent());
        }, 700);
    } else {
        const builderAgency = new EventBuilder(`EventChangeAgencyInfo`);
        builderAgency.AddParameter('AgencyId', currentAgency!.AgencyId.toString());
        builderAgency.AddParameter('AgencyName', currentAgency!.AgencyName);
        builderAgency.AddParameter('CommandType', 'ChangeAgency');
        builderAgency.AddParameter('AgencyDiscount', baseDiscount);
        ServerProxy.SendSystemEvent(builderAgency.GetSystemEvent());
        agenciesOpenAction(false);
    }
  };

  const agencyProjects = async () => {
    if (currentAgency && currentAgency.AccountId <= 0) {
      setAllProjects([]);
      return;
    }
    if (currentAgency) {
      const response = await getWebServerProxy().ToDo.GetProjects({
        customerAccountId: currentAgency.AccountId,
      });
      if (response.Success === false) {
        setErrorText(response.Message!);
      }
      setWaiting(false);

      const fetchedProjects = response.Result || [];
      setAllProjects(fetchedProjects);
      if (fetchedProjects.length === 1) {
        setActiveProject(fetchedProjects[0].projectId);
      }
    }
  };

  useEffect(() => {
    setWaiting(true);
    setErrorText('');
    agencyProjects();
    if(currentAgency) {
        if(currentAgency.AgencyDiscount === 0) {
            return setBaseDiscount('');
        }
        setBaseDiscount(currentAgency.AgencyDiscount.toString());
    } else if(currentAgency === null) {
        setBaseDiscount('');
    }
  }, [currentAgency]);

  const saveCompany = () => {
    const builderAgency = new EventBuilder(`EventChangeAgencyInfo`);
        builderAgency.AddParameter('AgencyId', currentAgency!.AgencyId.toString());
        builderAgency.AddParameter('AgencyName', currentAgency!.AgencyName);
        builderAgency.AddParameter('CommandType', 'AddCompaniesToAgency');
        builderAgency.AddParameter('CompanyIds', JSON.stringify([addCompany!.id]));
        ServerProxy.SendSystemEvent(builderAgency.GetSystemEvent());
        setCompanyOpen(false);
        setAddCompany(null);
  };

  const addProjectAgencyClick = async () => {
    setWaiting(true);
    setErrorText('');
    const projectName = 'project ' + new Date().toLocaleString();
    if (currentAgency) {
      const response = await getWebServerProxy().ToDo.CreateProjectByTemplate(
        currentAgency.AccountId,
        projectName
      );
      setWaiting(false);
      if (response.Success === false) {
        setErrorText(response.Message!);
      } else {
        setActiveProject(response.Result!.id);
        agencyProjects();
      }
    }
  };

  const projectNodes = projects.map((project) => {
    return (
      <ListItem
        dense
        className="editListItem"
        key={'agencyProject-' + project.projectId}
      >
        {currentAgency && (
          <ProjectSelectItem
            project={project}
            accountId={currentAgency.AccountId}
          />
        )}
      </ListItem>
    );
  });

  return (
    <>
      <Dialog
        open={agenciesOpen}
        onClose={() => agenciesOpenAction(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <IconButton
            aria-label="delete"
            style={{ position: 'absolute', top: '5px', right: '5px' }}
            onClick={() => agenciesOpenAction(false)}
          >
            <CloseIcon />
          </IconButton>
          Агентства
        </DialogTitle>

        <DialogContent>
          <div className="agenciesAutocomplete">
            <AgenciesAutocomplete label="Имя" autoFocus={false} />

            <Tooltip title="Добавить агентства" placement="top">
              <IconButton
                className="agenciesAutocomplete__label"
                onClick={addAgencyClickOpen}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>

          <div className="baseDicount">
            <div className="baseDicount__label">Базовая скидка</div>

            <FormControl variant="outlined" size="small">
              <OutlinedInput
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                labelWidth={0}
                type="number"
                value={baseDiscount}
                onChange={(event) => setBaseDiscount(event.target.value)}
              />
            </FormControl>
          </div>

          <List>
            <div className="titleBorder">
              <span>Компании</span>
            </div>

            {currentCompanies.map((company) => (
              <ListItem button key={`agency-company-${company.id}`}>
                <ListItemText primary={company.name} />

                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => deleteAgency(company.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}

            {companyOpen && (
              <Autocomplete
                className="mt-2"
                options={companies}
                getOptionLabel={(option: ClientInfo) => option.name}
                value={addCompany}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  changeValue: ClientInfo
                ) => setAddCompany(changeValue)}
                freeSolo
                filterOptions={(
                  options: ClientInfo[],
                  state: FilterOptionsState
                ) => fliterOptions(options, state)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Выберите компанию"
                    variant="outlined"
                    fullWidth
                    size="small"
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            )}
            {addCompany ? (
              <ListItem button onClick={saveCompany}>
                <ListItemIcon>
                  <SaveIcon />
                </ListItemIcon>

                <ListItemText primary={<Translate id="ClientDetailSave" />} />
              </ListItem>
            ) : (
              <ListItem
                button
                disabled={waiting}
                onClick={() => setCompanyOpen(true)}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>

                <ListItemText
                  primary={<Translate id="ClientDetailAddCompany" />}
                />
              </ListItem>
            )}
          </List>
        </DialogContent>

        <DialogActions className="agencyActions">
          <div>
            {/* <Tooltip title="Проекты" placement="top">
              <Fab
                onClick={() => openProjectChange(true)}
                size="medium"
                color="default"
              >
                <CreateNewFolderIcon />
              </Fab>
            </Tooltip> */}
          </div>

          <div>
            <Button
              className="mr-2"
              variant="contained"
              color="secondary"
              onClick={() => agenciesOpenAction(false)}
            >
              <Translate id="ClientDetailCancel" />
            </Button>
            {waiting ? 
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addAgencyClickOpen}>
                    Добавить агентства
                </Button>
            : 
                <Button
                    variant="contained"
                    color="primary"
                    onClick={agencyChangeClick}>
                    <Translate id="ClientDetailSave" />
                </Button>}
          </div>
        </DialogActions>
      </Dialog>

      <AddAgency
        addAgencyOpen={addAgencyOpen}
        addAgencyClickClose={addAgencyClickClose}
      />
    </>
  );
};

const fliterOptions = (options: ClientInfo[], state: FilterOptionsState) => {
  const optionsArray: ClientInfo[] = options.filter(
    (option) =>
      option.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 ||
      option.name.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) >
        0
  );
  return optionsArray;
};

const mapStateToProps = (state: RootState) => ({
  companies: state.customers.companies,
  currentAgency: state.event.currentAgency,
  agencyInfo: state.event.agencyInfo,
  currentCompany: state.customers.currentCompany,
  companyInfo: state.customers.companyInfo,
  agenciesOpen: state.event.agenciesOpen,
  projects: state.todo.projects,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    currentCompanyAction: (currentCompany: ClientInfo) =>
        dispatch(actions.customers.currentCompanyAction(currentCompany)),
    agenciesOpenAction: (agenciesOpen: boolean) =>
        dispatch(actions.event.agenciesOpenAction(agenciesOpen)),
    openProjectChange: (openProjectModal: boolean) =>
        dispatch(actions.todo.openProject(openProjectModal)),
    setActiveProject: (projectID: number) =>
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    setAllProjects: (projects: Project[]) =>
        dispatch(actions.todo.setAllProjects(projects)),
    addNewAgencyAction: (addNewAgency: boolean) =>
        dispatch(actions.event.addNewAgencyAction(addNewAgency)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesModal);
