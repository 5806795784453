import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Action } from 'redux';
import Grid from '@material-ui/core/Grid';
import InputField from '../components/InputField';
import { FormControlLabel, Checkbox, Button, Tooltip } from '@material-ui/core';
import { errorCallback, SystemEvent, EventError } from '../../../RemoteCommands/SystemEvent';
import { Period } from '../../../RemoteCommands/type';
import CircularProgressWithLabel from '../../bricks/CircularProgressWithLabel';
import { MessageHelper } from '../../MessageHub/MessageHelper';
import DatePicker from "react-datepicker";
import { DatesRange } from '../../bricks/InputDatesRange';
import moment from 'moment';
import MessageShackbar from '../../bricks/MessageShackbar';
import RegionAutocomplete from '../../bricks/RegionAutocomplete';
import { downloadExcel } from '../../../utils/downloadFiles';
import ImportSalesDialog from '../../bricks/ImportSalesDialog';
import Message from '../../bricks/Message';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

const MapSettings:React.FC<Props> = (props) => {
    const {
        clusterZoomLevel, 
        clusterMinPoints, 
        mapSettingsState, 
        allFreeGeoMarkPartsAction,
        useAllFreeGeoMarkParts,
        draggable, 
        draggableAction,
        userInfo,
        eventError,
        eventErrorAction
    } = props;

    const [executionPercent, setExecutionPercent] = useState<number | null>(null);
    const [importSalesPercent, setImportSalesPercent] = useState<number | null>(null);
    const [excelFileBytes, setExcelFileBytes] = useState<string | null>(null);
    const [disabled, setDisabled] = useState(false);
    const [regionName, setRegionName] = useState<string | null| undefined>(undefined);
    const [dates, setDates] = useState<DatesRange>({
        DateFrom: undefined,
        DateTo: undefined
    });
    const [dateForImportSales, setDateForImportSales] = useState<Date | null>(null);
    const [disabledImportSales, setDisabledImportSales] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [importSalesOpen, setImportSalesOpen] = useState(false);

    const dateFromChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateFrom: date ? date : undefined
        }));
    }

    const dateToChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateTo: date ? date : undefined
        }));
    }

    useEffect(() => {
        SystemEvent.SubscribeEventGenerateBillboardsFullExcelFile(
            "MapSettings", 
            (answer) => {
                setExecutionPercent(Number(answer.executionPercent));
                setExcelFileBytes(answer.excelFileBytes);
                setDisabled(true);
            }, 
            (error) => {
                setDisabled(false);
                errorCallback(error)
            }
        );
        MessageHelper.subscribeProgress("MapSettings", (data) => {
            setExecutionPercent(data.progress);
        });
        SystemEvent.SubscribeEventImportProjectSales(
            "MapSettings", 
            (answer) => {
                if(answer.resultText) {
                    setErrorText(answer.resultText);
                }
                const percent = Number(answer.executionPercent);
                if(percent < 100) {
                    setDisabledImportSales(true);
                    setImportSalesPercent(percent);
                } else {
                    setDisabledImportSales(false);
                    setImportSalesPercent(null);
                }
            }, 
            (error) => errorCallback(error)
        );
    }, []);

    useEffect(() => {
        if(dates.DateFrom && dates.DateTo) {
            setDisabled(false);
        } else {
            setDisabled(true)
        }
    }, [dates])

    useEffect(() =>{
        setDisabledImportSales(!!!dateForImportSales);
        if(errorText.length > 0)
            setErrorText("");
    }, [dateForImportSales])

    useEffect(() => {
        if(excelFileBytes) {
            downloadExcel(excelFileBytes, "BillboardsFullExcelFile");
            setExecutionPercent(null);
            setDates({
                DateFrom: undefined,
                DateTo: undefined 
            });
        }
    }, [excelFileBytes]);

    const exportExcelClick = () => {
        if(dates.DateFrom && dates.DateTo) {
            const period: Period = {
                Month: Number(moment(dates.DateFrom).format('M')),
                Year: Number(moment(dates.DateFrom).format('YYYY')),
                MonthTo: Number(moment(dates.DateTo).format('M')),
                YearTo: Number(moment(dates.DateTo).format('YYYY'))
            }
            const region = regionName ? regionName : undefined;
            SystemEvent.EventGenerateBillboardsFullExcelFile(period, region);
        }
    }

    const handleMessageClose = () => {
        eventErrorAction(null);
    }

    const handleImportSalesClose = () => {
        setImportSalesOpen(false);
    }

    const userRoleTypeProjects = userInfo && (userInfo.userRole === "SalesDirector" || userInfo.userRole === "AdminPMS");

    return (
        <>
            <Grid container spacing={1}>
                <Grid item>
                    <InputField 
                        label="Максимальный масштаб карты, на котором включается кластеризация:"
                        value={clusterZoomLevel}
                        handleChange={(event) => mapSettingsState(parseInt(event.target.value) || clusterZoomLevel, clusterMinPoints)}/>
                    <InputField 
                        label="Минимальный размер кластера:"
                        value={clusterMinPoints}
                        handleChange={(event) => mapSettingsState(clusterZoomLevel, parseInt(event.target.value) || clusterMinPoints)} />
                    <FormControlLabel
                        className="ml-2"
                        control={
                            <Checkbox
                                checked={draggable}
                                onChange={(event) => draggableAction(event.target.checked)}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Перетаскивать формуляр проекта"
                    />
                    <br />
                    {userRoleTypeProjects && 
                        <FormControlLabel
                            className="ml-2"
                            control={
                                <Checkbox
                                    checked={useAllFreeGeoMarkParts}
                                    onChange={(event) => allFreeGeoMarkPartsAction(event.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Добавить все свободные поверхности"
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2} className="ml-3">
                            <RegionAutocomplete 
                                regionName={regionName}
                                setRegionName={setRegionName} />
                        </Grid>
                        <Grid item>
                            <div className="chartDate">
                                <DatePicker
                                    selected={dates.DateFrom}
                                    onChange={(date: Date | null) => dateFromChange(date)}
                                    selectsStart
                                    startDate={dates.DateFrom}
                                    endDate={dates.DateTo}
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    isClearable
                                    locale="ru"
                                    placeholderText="Дата от"
                                />
                                <DatePicker
                                    selected={dates.DateTo}
                                    onChange={(date: Date | null) => dateToChange(date)}
                                    selectsStart
                                    startDate={dates.DateFrom}
                                    endDate={dates.DateTo}
                                    dateFormat="MMMM yyyy"
                                    isClearable
                                    showMonthYearPicker
                                    locale="ru"
                                    placeholderText="Дата до"
                                />
                            </div>
                        </Grid>
                        <Grid item>
                            <Tooltip placement="top" title="Сохранить конструкции в Excel">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={
                                        executionPercent 
                                        ?   
                                            <>
                                                {/*executionPercent !== 100 && */
                                                    <CircularProgressWithLabel value={executionPercent} />
                                                }
                                            </> 
                                        : <img src="https://img.icons8.com/color/24/000000/ms-excel.png" />
                                    }
                                    onClick={exportExcelClick}
                                    disabled={disabled}>
                                    Сохранить в Excel
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                {userInfo && userInfo.userRole === "AdminPMS" &&
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={2} className="ml-3">
                                <DatePicker
                                        selected={dateForImportSales}
                                        showMonthYearPicker
                                        dateFormat="MMMM yyyy"
                                        onChange={(date: Date) => setDateForImportSales(date)}
                                        selectsStart
                                        isClearable
                                        locale="ru"
                                        placeholderText="Месяц продаж"
                                    />
                            </Grid>
                            <Grid item>
                                <Tooltip placement="top" title="Импортировать продажи из Excel">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={
                                            importSalesPercent 
                                            ?   
                                                <>
                                                    {importSalesPercent !== 100 && 
                                                        <CircularProgressWithLabel value={importSalesPercent} />
                                                    }
                                                </> 
                                            : <img src="https://img.icons8.com/color/24/000000/ms-excel.png" />
                                        }
                                        onClick={() => setImportSalesOpen(true)}
                                        disabled={disabledImportSales}>
                                        Импортировать продажи
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {errorText.length > 0 &&
                <Grid item xs={12}>
                    <Message message={errorText} variant="error" />
                </Grid>
            }
            </Grid>
            {eventError && 
                <MessageShackbar 
                    message={eventError.errorMessage}
                    variant="error"
                    messageOpen={Boolean(eventError.errorCode)} 
                    vertical="top"
                    horizontal="center" 
                    messageShackbarClose={handleMessageClose} />
            }
            {(dateForImportSales != null) &&
                <ImportSalesDialog
                    importSalesOpen={importSalesOpen}
                    importSalesClickClose={handleImportSalesClose}
                    importDate={dateForImportSales} />
            }
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    clusterZoomLevel: state.app.clusterZoomLevel,
    clusterMinPoints: state.app.clusterMinPoints,
    draggable: state.app.draggable,
    useAllFreeGeoMarkParts: state.event.useAllFreeGeoMarkParts,
    userInfo: state.user.userInfo,
    eventError: state.event.eventError,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    mapSettingsState: (clusterZoomLevel: number, clusterMinPoints: number) => 
        dispatch(actions.app.changeMapSettingsAction(clusterZoomLevel, clusterMinPoints)),
    draggableAction: (draggable: boolean) => 
        dispatch(actions.app.draggableAction(draggable)),
    allFreeGeoMarkPartsAction: (useAllFreeGeoMarkParts: boolean) => 
        dispatch(actions.event.useAllFreeGeoMarkPartsAction(useAllFreeGeoMarkParts)),
    eventErrorAction: (eventError: EventError | null) => 
        dispatch(actions.event.eventErrorAction(eventError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapSettings);