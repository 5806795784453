import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, selectors, actions } from '../../store';
import { connect } from 'react-redux';
import { 
    IconButton, FormControl, Select, Menu, MenuItem, Button, Table, TableHead, TableRow, TableCell, 
    TableBody, Tooltip, FormControlLabel, Checkbox, Card, CardContent, CardActions, CardHeader, TableContainer, Paper 
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Message from '../bricks/Message';
import { Discount } from 'sedi-webserverproxy/dist/controllers/webclient/types/CommercialOffer';
import Draggable from 'react-draggable';
import { DefaultPosition } from '../../store/app/types';
import ProjectNameAutocomplete from './ProjectNameAutocomplete';
import MessageShackbar from '../bricks/MessageShackbar';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DiscountFormControl from './DiscountFormControl';
import moment from 'moment';
import { BillboardsShortInfo, ChangedCommercialOfferParmas, IGeoMark, IGeoMarkTimeStatuses } from '../../RemoteCommands/type';
import ProjectGeoMarkNameAutocomplete from './ProjectGeoMarkNameAutocomplete';
import DeleteDiscountModal from '../bricks/DeleteDiscountModal';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { GeoMarkTimeStatus } from "sedi-webserverproxy";
import _ from 'lodash';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
    discountOpen: boolean;
    discountClickClose: () => void;
    currentBillboards: BillboardsShortInfo[];
    geoMarks?: IGeoMark[];
}

const ProjectDiscount:React.FC<Props> = (props) => {
    const { 
        discountOpen, discountClickClose, 
        currentBillboards, billboadStatuses, 
        projectID, commercialOffer, 
        commercialOfferDiscountAction, billboardsShortInfo, 
        clientItem, defaultPositionDiscountAction, 
        defaultPositionDiscount,
        successMessage,
        successMessageAction,
        geoMarks,
        cities,
        propertySet
    } = props;
    const [, updateState] = React.useState();
    //@ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [confirmChecked, setConfirmChecked] = React.useState(false);
    const [successMessageOpen, setSuccessMessageOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [typePercent, setTypePercent] = useState(true);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [discount, setDiscount] = useState<Discount | null>(null);

    const deleteOpenHandle = () => {
        setDeleteOpen(true);
    }

    const deleteCloseHandle = () => {
        setDeleteOpen(false);
    }

    //const currentStatuses = billboadStatuses.filter(item => item.projectId === projectID);
    const currentStatuses = geoMarks && geoMarks[0].geoMarkTimeStatuses || [];

    const maxMinDate = (statuses: IGeoMarkTimeStatuses[]) => {
        let dates: Date[] = [];
        for(let status of statuses) {
            dates.push(new Date(status.dateTimeStart))
            dates.push(new Date(status.dateTimeEnd))
        }
        const monthNumber = dates.map(date => moment(date).month() + 1);
        const min = _.min(monthNumber);
        const max = _.max(monthNumber);
        return {
            min,
            max
        }
    }
    
    let initDiscount = {
        geoMarkId: -1,
        fixedSum: 0,
        discountPercent: null, 
        constructionSide: null, 
        constructionType: "", 
        month: 0, 
        city: "",
        boardId: null
    }
    const bilbordconstructionType = propertySet ? propertySet.properties.find(property => property.propertyName === "ConstructionType") : undefined;

    const allmonth = [
        {key: 0, label: 'Все'},
        {key: 1, label: 'Январь'},
        {key: 2, label: 'Февраль'},
        {key: 3, label: 'Март'},
        {key: 4, label: 'Апрель'},
        {key: 5, label: 'Май'},
        {key: 6, label: 'Июнь'},
        {key: 7, label: 'Июль'},
        {key: 8, label: 'Август'},
        {key: 9, label: 'Сентябрь'},
        {key: 10, label: 'Октябрь'},
        {key: 11, label: 'Ноябрь'},
        {key: 12, label: 'Декабрь'},
    ];

    const minMonth = maxMinDate(currentStatuses).min || 0;
    const maxMonth = maxMinDate(currentStatuses).max || 0;
    const month = allmonth.filter(item => item.key === 0 || (item.key >= minMonth && item.key <= maxMonth));

    // const [discounts, setDiscounts] = React.useState<Discount[]>([initDiscount]);
    const [error, setError] = useState({ error: false, text: '' });
    const [discountIndex, setDiscountIndex] = useState<number[]>([]);
    const offerId = commercialOffer ? commercialOffer.offerId : 0;

    const successMessageClose = () => {
        setSuccessMessageOpen(false);
        successMessageAction(null)
    }

    useEffect(() => {
        if(successMessage !== null && successMessageOpen) {
            setSuccessMessageOpen(true);
            discountClickClose();
        }
    }, [successMessage]);

    useEffect(() => {
        if(commercialOffer === null) {
            commercialOfferDiscountAction([initDiscount]);
        }
    }, [commercialOffer]);

    const addNewDiscount = () => {
        if(!commercialOffer) {
            return;
        }
        const { discounts } = commercialOffer;
        const newDiscounts = [...discounts, initDiscount]
        commercialOfferDiscountAction(newDiscounts);
    }

    const discountChange = (item: Discount) => {
        unique();
    };

    const sideChange = (item: Discount) => (event: React.ChangeEvent<{ value: unknown }>) => {
        item.constructionSide = event.target.value as string;
        unique();
        forceUpdate();
    };

    const typeChange = (item: Discount) => (event: React.ChangeEvent<{ value: unknown }>) => {
        item.constructionType = event.target.value as string;
        unique();
        forceUpdate();
    };

    const monthChange = (item: Discount) => (event: React.ChangeEvent<{ value: unknown }>) => {
        item.month = event.target.value as number;
        unique();
        forceUpdate();
    };

    const cityChange = (item: Discount) => (event: React.ChangeEvent<{ value: unknown }>) => {
        item.city = event.target.value as string;
        unique();
        forceUpdate();
    };

    const constructionSideIdChange = (item: Discount, geoMarkId: number) => {
        const billboard = billboardsShortInfo.find(item => item.geoMarkId === geoMarkId);
        item.geoMarkId = geoMarkId;
        item.boardId = billboard ? billboard.gid : null;
        unique();
        forceUpdate();
    }

    const discountDelete = (_dsicount: Discount) => {
        setDiscount(_dsicount);
        deleteOpenHandle();
    };

    const unique = () => { 
        const currentDiscountIndex = [];
        if(!commercialOffer) {
            return;
        }
        const { discounts } = commercialOffer;
        if(discounts) {
            for(let i = 0; i < discounts.length; i++) {
                for(let j = i + 1; j < discounts.length; j++) {
                    if(
                        discounts[i].constructionSide === discounts[j].constructionSide 
                        && discounts[i].constructionType === discounts[j].constructionType 
                        && discounts[i].month === discounts[j].month 
                        && discounts[i].discountPercent 
                        && discounts[j].discountPercent 
                        && discounts[i].boardId === discounts[j].boardId
                    ) {
                        currentDiscountIndex.push(i);
                        currentDiscountIndex.push(j);
                    }
                }
            }
        }
        setDiscountIndex(currentDiscountIndex);
        if(currentDiscountIndex.length > 0) {
            setError({
                error: true,
                text: 'Установлены одни и те же значение'
            });
        } else {
            setError({
                error: false,
                text: ''
            }); 
        }
    }

    useEffect(() => {
        if(commercialOffer && commercialOffer.discounts.length > 0) {
            unique();
        }
    }, [commercialOffer]);

    const saveDiscounts = () => {
        if(!commercialOffer) {
            return;
        }
        const { discounts } = commercialOffer;
        commercialOfferDiscountAction(discounts);
        const payload: ChangedCommercialOfferParmas = {
            projectId: projectID,
            offerId: offerId,
            financialDiscounts: discounts
        }
        SystemEvent.EventChangedCommercialOffer(payload);

        setTimeout(() => {
            if(confirmChecked) {
                const builder = new EventBuilder(`EventSendCommercialOfferToController`);
                    builder.AddParameter('OfferId', offerId);
                    builder.AddParameter('ProjectId', projectID);
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            }
        }, 700);

        discountClickClose();
    }

    const confirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmChecked(event.target.checked);
    };

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        defaultPositionDiscountAction(position);
    };

    const typeDiscountOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const typeDiscountClose = () => {
        setAnchorEl(null);
    };

    const typeDiscountChange = (percent: boolean) => {
        setTypePercent(percent);
        typeDiscountClose();
    };

    return (
        <>
        <Draggable
            handle=".draggableModal"
            defaultPosition={defaultPositionDiscount}
            onStop={onStop}>
            <Card className={`draggableModal projectDiscount ${discountOpen ? 'show' : 'hidden'}`}>
                <CardHeader
                    action={
                        <IconButton onClick={discountClickClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                    title={`${clientItem && clientItem.name} cкидки`}
                    
                />
                <CardContent>
                    <TableContainer component={Paper} className="discount-container">
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{width: 170, minWidth: 120}}>
                                        {typePercent ? "Скидка" : "Сумма"}
                                        <IconButton 
                                            size="small"
                                            onClick={typeDiscountOpen}>
                                            <ArrowDropDownIcon fontSize="inherit" />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={typeDiscountClose}
                                        >
                                            <MenuItem 
                                                value="persent" 
                                                onClick={() => typeDiscountChange(true)}>
                                                Скидка
                                            </MenuItem>
                                            <MenuItem 
                                                value="summa" 
                                                onClick={() => typeDiscountChange(false)}>
                                                Сумма
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                    <TableCell align="center" style={{width: 118}}>Конструкция</TableCell>
                                    <TableCell align="center" style={{width: 118}}>Сторона</TableCell>
                                    <TableCell align="center" style={{width: 148}}>Тип конструкции</TableCell>
                                    <TableCell align="center" style={{width: 140}}>Месяц</TableCell>
                                    <TableCell align="center" style={{width: 140}}>Город</TableCell>
                                    <TableCell align="center" style={{width: 40}}>
                                        {/* <Tooltip title="Сохранить" placement="top">
                                            <Fab 
                                                color="primary" 
                                                size="small" 
                                                disabled={error.error ? true : false} 
                                                onClick={saveDiscounts}>
                                                <SaveIcon fontSize="small" />
                                            </Fab>
                                        </Tooltip> */}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {commercialOffer && commercialOffer.discounts.map((item, index) => {
                                    const billboard = billboardsShortInfo.find(billboard => billboard.gid === item.boardId); 
                                    const geoMark = geoMarks && geoMarks.find(geoMark => geoMark.geoMarkId === item.geoMarkId && item.boardId !== null);
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="center">
                                                <DiscountFormControl 
                                                    currentDiscount={item}
                                                    discountChange={(currentDiscount) => discountChange(currentDiscount)}
                                                    discountIndex={discountIndex}
                                                    index={index}
                                                    typePercent={typePercent}
                                                    error={error.error}
                                                    typeDiscountChange={(persent) => typeDiscountChange(persent)}
                                                    discountOpen={discountOpen}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                {geoMarks ? 
                                                    <ProjectGeoMarkNameAutocomplete 
                                                        geoMarks={geoMarks} 
                                                        onChange={(geoMarkId: number) => constructionSideIdChange(item, geoMarkId)} 
                                                        geoMark={geoMark} />
                                                    :
                                                    <ProjectNameAutocomplete 
                                                        billboard={billboard}
                                                        currentBillboards={currentBillboards}
                                                        onChange={(geoMarkId: number) => constructionSideIdChange(item, geoMarkId)} />
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl variant="outlined" fullWidth margin="dense" error={error.error && discountIndex.some(i => i === index )}>
                                                    <Select
                                                        value={item.constructionSide}
                                                        name="side"
                                                        onChange={sideChange(item)}
                                                        displayEmpty>
                                                        <MenuItem value="">Все</MenuItem>
                                                        <MenuItem value='A'>А</MenuItem>
                                                        <MenuItem value='B'>B</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl variant="outlined" fullWidth margin="dense" error={error.error && discountIndex.some(i => i === index )}>
                                                    <Select
                                                        value={item.constructionType}
                                                        name="type"
                                                        onChange={typeChange(item)}
                                                        displayEmpty>
                                                        <MenuItem value="">Все</MenuItem>
                                                        {bilbordconstructionType && bilbordconstructionType.values.map(construction => (
                                                            <MenuItem key={construction.valueId} value={construction.value}>
                                                                {construction.value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl variant="outlined" fullWidth margin="dense" error={error.error && discountIndex.some(i => i === index )}>
                                                    <Select
                                                        value={item.month}
                                                        // name="month"
                                                        onChange={monthChange(item)}>
                                                        {month.map((item) => (
                                                            <MenuItem key={item.key + Math.random()} value={item.key}>{item.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl variant="outlined" fullWidth margin="dense" error={error.error && discountIndex.some(i => i === index )}>
                                                    <Select
                                                        value={item.city}
                                                        name="city"
                                                        onChange={cityChange(item)}
                                                        displayEmpty>
                                                        <MenuItem value="">Все</MenuItem>
                                                        {cities.map((item) => (
                                                            <MenuItem key={item} value={item}>{item}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Delete" placement="top">
                                                    <DeleteIcon 
                                                        fontSize="small"
                                                        onClick={() => discountDelete(item)}
                                                        style={{ cursor: 'pointer' }}/>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {error.error && 
                        <Message message={error.text} variant="error" />
                    }
                </CardContent>
                <CardActions className="projectDiscountActions">
                    <div>
                        <Button color="primary" variant="outlined" onClick={addNewDiscount}>Добавить скидку</Button>
                        <FormControlLabel
                            className="projectConfirm"
                            control={<Checkbox color="primary" checked={confirmChecked} onChange={confirmChange} />}
                            label="Отправить на согласование к директору?"
                        />
                    </div>
                    <Button 
                        variant="contained"
                        color="primary" 
                        size="small" 
                        disabled={error.error ? true : false}  
                        onClick={saveDiscounts}
                        startIcon={<SaveIcon />}>
                        Сохранить
                    </Button>
                </CardActions>
            </Card>
        </Draggable>
        {successMessage && 
            <MessageShackbar 
                message={successMessage}
                variant="success"
                messageOpen={successMessageOpen} 
                vertical="top"
                horizontal="center" 
                messageShackbarClose={successMessageClose} />
        }
        {discount && 
            <DeleteDiscountModal 
                deleteOpen={deleteOpen} 
                deleteCloseHandle={deleteCloseHandle} 
                discount={discount} 
                offerId={offerId} />
        }
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    billboadStatuses: state.billboards.billboadStatuses,
    projectID: selectors.todo.getProjectID(state.todo),
    commercialOffer: state.todo.commercialOffer,
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    clientItem: state.customers.clientItem,
    defaultPositionDiscount: state.app.defaultPositionDiscount,
    successMessage: state.event.successMessage,
    cities: state.event.cities,
    propertySet: state.billboards.propertySet
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    sendSystemEventAction: (systemEvent: string) => 
        dispatch(actions.todo.sendSystemEventAction(systemEvent)),
    commercialOfferDiscountAction: (discounts: Discount[]) => 
        dispatch(actions.todo.commercialOfferDiscountAction(discounts)),
    defaultPositionDiscountAction: (defaultPositionDiscount: DefaultPosition) => 
        dispatch(actions.app.defaultPositionDiscountAction(defaultPositionDiscount)),
    successMessageAction: (successMessage: string | null) => 
        dispatch(actions.event.successMessageAction(successMessage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDiscount);