import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { textCut } from '../../../utils/textCut';
import { MessageToUser } from '../../../store/event/types';

type Props = {
    message: MessageToUser
    onDelete: (idx: any) => void
}

const MessageContent:React.FC<Props> = (props) => {
    const { message, onDelete } = props;
    const [allText, setAllText] = useState(false);
    const limit = 120;
    const arrowToggle = message.messageText.length >= limit;

    return (
        <div className="notificationGroup__item" key={message.messageId}>
            <div className="notificationGroup__iconGroup">
                <CloseIcon onClick={() => onDelete(message.messageId)} />
                {arrowToggle && <ExpandMoreIcon onClick={() => setAllText(!allText)} className={`notification__more ${allText && 'toggle'}`}/>}
            </div>
            {/* <div className="notificationGroup__title">Александр Иванов</div> */}
            <div className="notificationGroup__desc">{allText ? message.messageText : textCut(message.messageText, limit)}</div>
            {/* <div className="notificationGroup__date">12:30</div> */}
        </div>
    );
}

export default MessageContent;