import React, { Dispatch, useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../../store';
import { connect } from 'react-redux';
import { getAllTodoActions } from '../../../store/todo/selectors';
import ToDoAction from './ToDoAction/ToDoAction';
import MessageContent from './MessageContent';
import { IMessageToUser } from '../../../store/event/types';
import ExceededProject from './ExceededProject';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const ActionsStack: React.FunctionComponent<Props> = (props) => {
    const { 
        todoActions, 
        messageToUsers, 
        messageToUserArrayAction,
        exceededTimeProjects
    } = props;

    const notificationEl = useRef<HTMLDivElement | null>(null);
    const [notificationHeight, setNotificationHeight] = useState<number>(0);

    const actionItems = _.sortBy(todoActions, a => -a.SequenceNumber)
        .map((action, index) => 
            <ToDoAction 
                key={action.ActionName}
                action={action} 
                sequenceNumber={index + 1} />
        );
    
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(notificationEl.current) {
            setNotificationHeight(notificationEl.current.offsetHeight);
        }
    }, [show]);

    const showMoreClick = () => {
        setShow(!show);
    }

    const scrollHeight = notificationHeight > 498;

    const deleteClick = (id: number) => {
        const idx = messageToUsers.findIndex((item) => item.messageId === id);
        messageToUserArrayAction([
            ...messageToUsers.slice(0, idx),
            ...messageToUsers.slice(idx + 1)
        ])
    }

    const allDeleteClick = () => {
        messageToUserArrayAction([]);
    }

    return (
        <>
            {(messageToUsers.length > 0 || exceededTimeProjects.length > 0) && 
                <div className="notificationGroupRow">
                    <div className="notification__title">Монитор активности</div>
                    <div 
                        className={`${exceededTimeProjects.length > 0 ? 'notificationGroup' : ''}`}
                        style={{ maxHeight: 300, overflowY: 'auto' }}>
                        {exceededTimeProjects.map(project => (
                            <ExceededProject
                                key={project.projectId} 
                                project={project} />
                        ))}
                    </div>
                    {messageToUsers.length > 0 && 
                        <div ref={notificationEl} style={show ? {maxHeight: 500} : {}} className={`notificationGroup ${scrollHeight ? 'notificationGroup_all' : ''}`}>
                            {messageToUsers.map((message, i) => {
                                return (
                                    <MessageContent 
                                        key={i}
                                        message={message}  
                                        onDelete={(idx) => deleteClick(idx)} />
                                )
                            }).reverse().slice(0, 3)}
                            {show && messageToUsers.map((message, i) => {
                                return (
                                    <MessageContent 
                                        key={i}
                                        message={message}
                                        onDelete={(id) => deleteClick(id)} />
                                )
                            }).reverse().slice(3)}
                        </div>
                    }
                    {messageToUsers.length > 3 &&
                        <div className="notificationGroup__more">
                            <span onClick={showMoreClick}>{show ? 'Кратко' : `Подробнее (${messageToUsers.length - 3})` }</span>
                            <span 
                                className="notificationGroup__clear"
                                onClick={allDeleteClick}>Очистить все</span>
                        </div>
                    }
                </div>
            }
            {actionItems}
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    todoActions: getAllTodoActions(state.todo),
    messageToUsers: state.event.messageToUsers,
    exceededTimeProjects: state.todo.exceededTimeProjects
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    messageToUserArrayAction: (messageToUser: IMessageToUser[]) => dispatch(actions.event.messageToUserArrayAction(messageToUser)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionsStack);