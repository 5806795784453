import { ClientItem, CustomerState, ICompanyInfo } from './types';
import { Action, ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ClientInfo, GetCustomersFilter } from 'sedi-webserverproxy';
import { getWebServerProxy } from '../../WebServerProxy';

export enum CustomerActionTypes {
    SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS',
    SEARCH_CUSTOMERS_ERROR = 'SEARCH_CUSTOMERS_ERROR',
    SEARCH_CUSTOMERS_SUCCESS = 'SEARCH_CUSTOMERS_SUCCESS',
    SAVE_CUSTOMER = 'SAVE_CUSTOMER',
    SAVE_CUSTOMER_ERROR = 'SAVE_CUSTOMER_ERROR',
    SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS',
    RESET_SAVE_ERROR = 'RESET_SAVE_ERROR',
    UPDATE_CUSTOMER_FILTERS = 'UPDATE_CUSTOMER_FILTERS',
    SEARCH_INN = 'SEARCH_INN',
    SEARCH_INN_SUCCESS = 'SEARCH_INN_SUCCESS',
    CUSTOMERS_COMPANIES_LOADED = 'CUSTOMERS_COMPANIES_LOADED',
    ADDRESS_OFFICIAL = 'ADDRESS_OFFICIAL',
    ADDRESS_OFFICE = 'ADDRESS_OFFICE',
    ADDRESS_POSTAL = 'ADDRESS_POSTAL',
    GET_COMPANY_INFO = 'GET_COMPANY_INFO',
    CURRENT_COMAPNY = 'CURRENT_COMAPNY',
    CLIENT_ITEM = 'CLIENT_ITEM',
    SUCCESS_CLIENT = 'SUCCESS_CLIENT',
}

export interface ICompanyInfoAction extends Action<CustomerActionTypes.GET_COMPANY_INFO> {
    companyInfo: ICompanyInfo;
}

export interface ICurrentCompany extends Action<CustomerActionTypes.CURRENT_COMAPNY> {
    currentCompany: ClientInfo | null; 
}

export interface IAddressOfficial extends Action<CustomerActionTypes.ADDRESS_OFFICIAL> {
    addressOfficial: string;
}
export interface IAddressOffice extends Action<CustomerActionTypes.ADDRESS_OFFICE> {
    addressOffice: string;
}
export interface IAddressPostal extends Action<CustomerActionTypes.ADDRESS_POSTAL> {
    addressPostal: string;
}
export interface ISearchCustomersAction extends Action<CustomerActionTypes.SEARCH_CUSTOMERS> {
    componentID: string,
    filter: GetCustomersFilter
}
export interface ISearchCustomersErrorAction extends Action<CustomerActionTypes.SEARCH_CUSTOMERS_ERROR> {
    componentID: string,
    error: string,
}
export interface ISearchCustomersSuccessAction extends Action<CustomerActionTypes.SEARCH_CUSTOMERS_SUCCESS> {
    componentID: string,
    customers: ClientInfo[],
    filter: GetCustomersFilter
}
export interface ICustomersCompaniesLoadedAction extends Action<CustomerActionTypes.CUSTOMERS_COMPANIES_LOADED> {
    filter: GetCustomersFilter,
    customers: ClientInfo[]
}
export interface ISaveCustomerAction extends Action<CustomerActionTypes.SAVE_CUSTOMER> {
}
export interface ISaveCustomerErrorAction extends Action<CustomerActionTypes.SAVE_CUSTOMER_ERROR> {
    error: string,
}
export interface ISaveCustomerSuccessAction extends Action<CustomerActionTypes.SAVE_CUSTOMER_SUCCESS> {
    customer: ClientInfo,
}
export interface IResetSaveErrorAction extends Action<CustomerActionTypes.RESET_SAVE_ERROR> {
}
export interface IUpdateCustomerFilters extends Action<CustomerActionTypes.UPDATE_CUSTOMER_FILTERS> {
    componentID: string,
    filters: GetCustomersFilter,
}
export interface ISearchInnAction extends Action<CustomerActionTypes.SEARCH_INN> {
    componentID: string,
}
export interface ISearchInnSuccessAction extends Action<CustomerActionTypes.SEARCH_INN_SUCCESS> {
    componentID: string,
    company: ClientInfo
}

export interface IClientItem extends Action<CustomerActionTypes.CLIENT_ITEM> {
    clientItem: ClientItem | null,
}

export interface ISuccessClient extends Action<CustomerActionTypes.SUCCESS_CLIENT> {
    successClient: string | null,
}

export type CustomerActions =
    | ISearchCustomersAction
    | ISearchCustomersErrorAction
    | ISearchCustomersSuccessAction
    | ISearchInnAction
    | ISearchInnSuccessAction
    | ISaveCustomerAction
    | ISaveCustomerErrorAction
    | ISaveCustomerSuccessAction
    | IUpdateCustomerFilters
    | IResetSaveErrorAction
    | ICustomersCompaniesLoadedAction
    | IAddressOfficial
    | IAddressOffice
    | IAddressPostal
    | ICompanyInfoAction
    | ICurrentCompany
    | IClientItem
    | ISuccessClient;

export const searchCustomersActionCreator: ActionCreator<ThunkAction<Promise<any>, CustomerState, void, ISearchCustomersSuccessAction>> =
    (componentID: string, filter: GetCustomersFilter) => {
        return async (dispatch: ThunkDispatch<CustomerState, void, Action<any>>) => {
            try {
                const wsProxy = getWebServerProxy();

                const fetchingAction: ISearchCustomersAction = {
                    type: CustomerActionTypes.SEARCH_CUSTOMERS,
                    componentID,
                    filter
                };

                dispatch(fetchingAction);

                if (filter.phone !== undefined) {
                    filter.phone = filter.phone.replace(/\s/g, '');
                }

                const result = await wsProxy.WebClient.GetCustomers(filter);
                if (result.Success === false) {
                    throw new Error(`Search error ${result.Message}`);
                }

                const successAction: ISearchCustomersSuccessAction = {
                    type: CustomerActionTypes.SEARCH_CUSTOMERS_SUCCESS,
                    componentID,
                    filter,
                    customers: result.Result!
                };

                dispatch(successAction);

                // если поиск в базе не нашел ни одного клиента
                // поищем его в сторонних справочниках по ИНН
                const inn = filter.inn || '';
                const isSearchByInn = inn.length > 0;
                if (isSearchByInn && result.Result !== undefined && result.Result.length === 0) {
                    const fetchingActionInn: ISearchInnAction = {
                        type: CustomerActionTypes.SEARCH_INN,
                        componentID
                    };

                    dispatch(fetchingActionInn);

                    const resultByINN = await wsProxy.WebClient.GetClientByINN(inn);
                    if (resultByINN.Success === false) {
                        throw new Error(`INN search error ${resultByINN.Message}`);
                    }

                    const successInnAction: ISearchInnSuccessAction = {
                        type: CustomerActionTypes.SEARCH_INN_SUCCESS,
                        componentID,
                        company: resultByINN.Result!
                    };

                    dispatch(successInnAction);
                }
            }
            catch (e) {
                const failAction: ISearchCustomersErrorAction = {
                    type: CustomerActionTypes.SEARCH_CUSTOMERS_ERROR,
                    componentID,
                    error: e.message
                };

                dispatch(failAction);
            }
        }
    }

export const customerCompanyLoaded: ActionCreator<ThunkAction<Promise<any>, CustomerState, void, ICustomersCompaniesLoadedAction>> =
    (filter: GetCustomersFilter) => {
        return async (dispatch: ThunkDispatch<CustomerState, void, Action<any>>) => {
            try {

                const wsProxy = getWebServerProxy();

                const result = await wsProxy.WebClient.GetCustomers(filter);
                if (result.Success === false) {
                    throw new Error(`Search error ${result.Message}`);
                }

                const successAction: ICustomersCompaniesLoadedAction = {
                    type: CustomerActionTypes.CUSTOMERS_COMPANIES_LOADED,
                    filter,
                    customers: result.Result!
                };

                return dispatch(successAction);

            }
            catch (e) {
                console.log(`Ошибка в customerCompanyLoaded: ${e}`);
            }
        }
}

export const saveCustomersActionCreator: ActionCreator<ThunkAction<Promise<any>, CustomerState, void, ISaveCustomerErrorAction>> =
    (client: ClientInfo, customersInCompany?: ClientInfo[]) => {
        return async (dispatch: ThunkDispatch<CustomerState, void, Action<any>>) => {
            try {
                const isLegalEntity = client.isLegalEntity;
                const wsProxy = getWebServerProxy();

                const fetchingAction: ISaveCustomerAction = {
                    type: CustomerActionTypes.SAVE_CUSTOMER
                };
                dispatch(fetchingAction);

                const clientForSave = client.getCopy();
                clientForSave.emails = clientForSave.emails
                    .filter(email => email.email.trim().length > 0);

                const result = await wsProxy.WebClient.SaveClient(clientForSave);
                if (result.Success === false) {
                    throw new Error(result.Message);
                }

                if(result.Success === true) {
                    const successAction: ISuccessClient = {
                        type: CustomerActionTypes.SUCCESS_CLIENT,
                        successClient: 'Вы добавили клиента ' + result.Result!.id
                    };
                    dispatch(successAction);
                }

                const customerID = result.Result!.id;

                let newCustomer = new ClientInfo(clientForSave);
                newCustomer.id = customerID;
                newCustomer.accountId = result.Result!.accountId;

                const successAction: ISaveCustomerSuccessAction = {
                    type: CustomerActionTypes.SAVE_CUSTOMER_SUCCESS,
                    customer: newCustomer
                };

                // TODO перейти на redux-saga
                if (isLegalEntity && customersInCompany && customersInCompany.length > 0) {
                    for (let customer of customersInCompany) {
                        const customerWithCompany = customer.getCopy();
                        customerWithCompany.customerCompanyId = customerID;

                        const resultChangeCompany = await wsProxy.WebClient.SaveClient(customerWithCompany);
                        if (resultChangeCompany.Success === false) {
                            throw new Error(resultChangeCompany.Message);
                        }
                    };
                }

                dispatch(successAction);
            }
            catch (e) {
                const failAction: ISaveCustomerErrorAction = {
                    type: CustomerActionTypes.SAVE_CUSTOMER_ERROR,
                    error: e.message
                };

                dispatch(failAction);
            }
        }
    }

export const successClientAction: ActionCreator<ISuccessClient> = (successClient: string | null) => {
    return {
        type: CustomerActionTypes.SUCCESS_CLIENT,
        successClient
    }
}

export function updateCustomerFiltersAction(componentID: string, filter: GetCustomersFilter) {
    const action: IUpdateCustomerFilters = {
        type: CustomerActionTypes.UPDATE_CUSTOMER_FILTERS,
        componentID,
        filters: filter
    }
    return action;
}


export function ResetSaveErrorAction() {
    const action: IResetSaveErrorAction = {
        type: CustomerActionTypes.RESET_SAVE_ERROR
    }

    return action;
}

export function companyInfoAction(companyInfo: ICompanyInfo) {
    const action: ICompanyInfoAction = {
        type: CustomerActionTypes.GET_COMPANY_INFO,
        companyInfo,
    }

    return action;
}

export function addressOfficalAction(addressOfficial: string) {
    const action: IAddressOfficial = {
        type: CustomerActionTypes.ADDRESS_OFFICIAL,
        addressOfficial
    }
    return action;
}

export function addressOfficeAction(addressOffice: string) {
    const action: IAddressOffice = {
        type: CustomerActionTypes.ADDRESS_OFFICE,
        addressOffice
    }
    return action;
}

export function addressPostalAction(addressPostal: string) {
    const action: IAddressPostal = {
        type: CustomerActionTypes.ADDRESS_POSTAL,
        addressPostal
    }
    return action;
}

export function currentCompanyAction(currentCompany: ClientInfo | null) {
    const action: ICurrentCompany = {
        type: CustomerActionTypes.CURRENT_COMAPNY,
        currentCompany
    }
    return action;
}

export function clientItemAction(clientItem: ClientItem | null) {
    const action: IClientItem = {
        type: CustomerActionTypes.CLIENT_ITEM,
        clientItem
    }
    return action;
}