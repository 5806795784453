import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, selectors } from '../../store';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ClientInfo } from 'sedi-webserverproxy';
import SendEmailSelect from './SendEmailSelect';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import PreviewModal from './PreviewModal';
import { getWebServerProxy } from '../../WebServerProxy';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';

type Props = ReturnType<typeof mapStateToProps> & {
  currentCompany: ClientInfo | null
  handleCloseSend: () => void
  openSendEmail: boolean,
  offerId: number;
  sandLoadingTrue: () => void;
}

const SendEmail:React.FC<Props> = ({ handleCloseSend, openSendEmail, currentCompany, commercialOffer, offerId, projectID, sandLoadingTrue }) => {
    const [emailSubject, setEmailSubject] = useState('');
    const [emailText, setEmailText] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [htmlPreview, setHtmlPreview] = useState('');
    const [email, setEmail] = useState('');

    const initialCustomers: ClientInfo[] = [];
    const [customers, setCustomers] = useState(initialCustomers);

    useEffect(() => {
        if(currentCompany) {
            loadCustomersByCompany(currentCompany.id);
        }
    }, [openSendEmail, currentCompany]);

    const loadCustomersByCompany = async (companyID: number) => {
        const response = await getWebServerProxy().WebClient.GetCustomers({ companyId: companyID });
        if (response.Success) {
            setCustomers(response.Result || []);
            return;
        }

        alert(response.Message || '');
    }

    const companyEmail =  currentCompany ? (currentCompany.emails[0] ? currentCompany.emails[0].email : '') : ''
    const individualEmail = currentCompany ? customers.filter(customer => customer.customerCompanyId === currentCompany.id) : [];

    const emailChange = (value: string) => {
        setEmail(value);
    }

    const previewClickOpen = async () => {
        setPreviewOpen(true);
        try {
            const wsProxy = getWebServerProxy();            
            const result = await wsProxy.WebClient.PrepareOfferMail(offerId, emailText);
            if (result.Success === false) {
                throw new Error(`Search error ${result.Message}`);
            }
            setHtmlPreview(result.Result!);
        } catch (err) {
            console.log('Ошибка PrepareOfferMail', err.message)
        }
    }

    const sendEmailClick = async () => {
        const systemEvent = `EventSendCommercialOfferToClient;${offerId};${projectID};${email};${emailSubject};${emailText}`;
        handleCloseSend();
        sandLoadingTrue();
        try {
            await ServerProxy.SendSystemEvent(systemEvent);
        } catch (e) {
            console.log('Ошибка', e.Message);
        }
    }

    const previewClickClose = () => {
        setPreviewOpen(false);
    }

    useEffect(() => {
        setEmail('');
    }, [projectID])

    useEffect(() => {
        if(commercialOffer) {
            setEmailSubject(commercialOffer.emailSubject);
            setEmailText(commercialOffer.emailText);
        } else {
            setEmailSubject('');
            setEmailText('');
        }
    }, [commercialOffer]);
    
    return (
        <div>
        <Dialog 
            open={openSendEmail} 
            onClose={handleCloseSend}
            fullWidth={true}
            maxWidth="sm">
            <DialogTitle>
                Отправить на почту
                <IconButton aria-label="delete" style={{ position: 'absolute', top: '5px', right: '5px' }} 
                    onClick={handleCloseSend}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
            <form noValidate autoComplete="off">
                <div className="pb-4">
                    {individualEmail.length > 0 || companyEmail !== ''
                    ? 
                        <SendEmailSelect 
                            companyEmail={companyEmail} 
                            individualEmail={individualEmail} 
                            emailChange={(value) => emailChange(value)}/>
                    : 
                        <TextField
                            label="Email адрес"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            type="email"
                            fullWidth
                            variant="outlined"
                            size="small" 
                            autoFocus />
                    }
                </div>
                <div className="pb-4">
                    <TextField
                        label="Тема письма"
                        value={emailSubject}
                        onChange={(event) => setEmailSubject(event.target.value)}
                        type="email"
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </div>
                <div className="pb-4">
                    <TextField
                        label="Excel файл"
                        type="email"
                        value="Excel.xlsx"
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </div>
                <div className="pb-2">
                    <TextField
                        label="Текст"
                        multiline
                        rows="4"
                        value={emailText}
                        onChange={(event) => setEmailText(event.target.value)}
                        fullWidth
                        variant="outlined" />
                </div>
            </form>
            </DialogContent>
            <DialogActions className="pb-4 pr-4 pl-4 justify-content-between">
                <Button onClick={previewClickOpen} variant="contained" color="default">Превью</Button>
                <div>
                    <Button onClick={handleCloseSend} variant="contained" color="secondary" className="mr-2">Отмена</Button>
                    <Button onClick={sendEmailClick} variant="contained" color="primary">Отправить</Button>
                </div>
            </DialogActions>
        </Dialog>
        <PreviewModal 
            previewOpen={previewOpen} 
            previewClickClose={previewClickClose} 
            htmlPreview={htmlPreview}/>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    customers: state.customers.customers,
    commercialOffer: state.todo.commercialOffer,
    projectID: selectors.todo.getProjectID(state.todo),
});

export default connect(mapStateToProps)(SendEmail)