import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';

type Props = {
    label: string;
    type?: "text" | "number";
    value?: string | number;
    required?: boolean;
    onChange: (value: string | number, name: string) => void;
    name: string;
    className?: string;
    variant?: any;
    disabled?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    multiline?: boolean;
    rows?: number;
}

const TextFieldUniversal: FunctionComponent<Props> = (props) => {
    const { 
        label,
        type,
        value,
        required,
        onChange,
        name,
        className,
        variant,
        disabled,
        inputRef,
        multiline,
        rows
    } = props;

    const inputFocus = useRef<HTMLInputElement>(null);
    let input = useInput(value ? value : "", name, onChange);

    return (
        <TextField
            className={`cancelDraggable ${className}`}
            label={label}
            type={type || "text"}
            variant={variant ? variant : "outlined" }
            size="small"
            fullWidth
            required={required}
            disabled={disabled}
            inputRef={inputRef}
            multiline={multiline}
            rows={rows} 
            {...input.bind}
        />
    )
};

export function useInput(initialValue: string | number, nameProps: string, onChangeProps: any) {
    const [value, setValue] = useState(initialValue);
    const [name, setName] = useState(nameProps);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        onChangeProps(value, name);
    }, [value]);
  
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setValue(event.target.value);
        setName(event.target.name);
    }
  
    const clear = () => setValue('');
  
    return {
      bind: {value, name, onChange},
      value,
      name,
      clear
    }
}


export default TextFieldUniversal;