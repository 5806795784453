import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { Tooltip } from '@material-ui/core';
import WidgetAutocomplete from '../WidgetAutocomplete/WidgetAutocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { AddressAPI, AddressAutocomplete, AddressInfo, IAddressParameters, IGetAddressItem } from 'sedi-webserverproxy';
import DoneIcon from '@material-ui/icons/Done';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    addressInfo: AddressInfo | null;
    placeholder?: string;
    disabled?: boolean;
    inputClassName?: string;
    regionName?: string;
    cityName?: string;
}

const GetAddressAutocomplete:React.FC<Props> = (props) => {
    const { 
        addressInfo,
        addressValue,
        addressValueAction,
        placeholder,
        disabled,
        inputClassName,
        selectedAddress,
        cityName,
        addressesAction,
        selectedAddressAction
    } = props;

    const helperNew = new AddressAutocomplete(new AddressAPI());

    const [addressItems, setAddressItems] = useState<IGetAddressItem[]>([]);
    
    const addressChange = (newValue: string) => {
        addressValueAction(newValue);
    }

    const donereRefineSearch = async () => {
        let parameters: IAddressParameters;
        if(selectedAddress) {
            parameters = {
                searchText: addressValue.replace(/[,]/g, ''),
                searchType: null,
                city: selectedAddress.c,
                street: selectedAddress.s,
                lat: selectedAddress.g.lat,
                lon: selectedAddress.g.lon,
                countryName: selectedAddress.co,
                searchMethod: "CheckAddress"
            }
        } else {
            parameters = {
                searchText: addressValue.replace(/[,]/g, ''),
                searchType: null,
                searchMethod: "CheckAddress",
                countryName: "Russia"
            }
        }
        const response = await helperNew.GetAddresses(parameters);
        if (response.Success === false) {
            return alert(response.Message);
        }

        setAddressItems(response.Result!.items);
        addressesAction(response.Result!.items);
        if(response.Result!.items.length === 0) {
            selectedAddressAction(null);
        }
    }

    return (
        <div className="getAddressAutocomplete-row cancelDraggable">
            <WidgetAutocomplete 
                value={addressValue}
                // regionPlusIds={[78, 98, 178]}
                inputClassName={`getAddressAutocomplete ${inputClassName}`}
                inputClassNameLoading="getAddressAutocomplete"
                onChangeInput={(newValue: string) => addressChange(newValue)} 
                cityClassName="getAddressAutocomplete-city" 
                lat={addressInfo ? addressInfo.geoPoint.latitude : 0}
                lon={addressInfo ? addressInfo.geoPoint.longitude : 0} 
                errorClassName="getAddressAutocomplete-error" 
                placeholder={placeholder ? placeholder : ""}
                disabled={disabled} 
                addressItems={addressItems} 
                cityName={cityName} 
                onlyCity={false} />
            {!disabled &&
                <Tooltip title="Уточнить поиск" placement="top">
                    <DoneIcon 
                        className="done-icon"
                        onClick={donereRefineSearch} 
                        />
                </Tooltip>
            }
            {!disabled && 
                <Tooltip title="Очистить поле" placement="top">
                    <ClearIcon 
                        className="getAddressAutocomplete-icon"
                        onClick={() => addressChange("")} />
                </Tooltip>
            }
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    addressValue: state.event.addressValue,
    selectedAddress: state.event.selectedAddress
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),  
    addressesAction: (addresses: IGetAddressItem[]) => 
        dispatch(actions.event.addressesAction(addresses)),
    selectedAddressAction: (selectedAddress: IGetAddressItem | null) => 
        dispatch(actions.event.selectedAddressAction(selectedAddress)),
});


export default connect(
    mapStateToProps,mapDispatchToProps
)(GetAddressAutocomplete);