import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import moment from 'moment';
import { 
    Button,
    CircularProgress,
    Paper,  
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow 
} from '@material-ui/core';
//import '../BillsTable.scss';
import { PaymentBillInfo } from '../../RemoteCommands/type';
import { errorCallback, IEventGetPaymentBillDocument, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { downloadJpg } from '../../utils/downloadFiles';

type Props = ReturnType<typeof mapStateToProps> &{
    paymentBills: PaymentBillInfo[]
}

const BillsCardTable:React.FC<Props> = (props) => {
    const {
        loading,
        paymentBills,
        billStatuses
    } = props;

    const [loadingBillFile, setLoadingBillFile] = useState<boolean>(false);
    const [billFile, setBillFile] = useState<IEventGetPaymentBillDocument>();
    const [billId, setBillId] = useState<number>(-1);

    useEffect(() => {
        SystemEvent.SubscribeEventGetPaymentBillDocument(
            "BillsCardTable", 
            (answer) => {
                setBillFile(answer);
                //setScoreOpen(true);
                //SystemEvent.EventGetProjectAdditionalCostParameters(projectID);
                setLoadingBillFile(false);
                //setShowAdditionalCostBtn(true);
            }, 
            (error) => {
                errorCallback(error);
                setLoadingBillFile(false);
            }
        );
    }, []);

    useEffect(() => {
        if(billFile && billFile.documentImage) {
            downloadJpg(billFile.documentImage, `Счет ${billId}`);
            setBillId(-1);
        }
    }, [billFile]);

    const showBillFileClick = (billId: number) => {
        setLoadingBillFile(true); 
        setBillId(billId);
    
        SystemEvent.EventGetPaymentBillDocument(billId);
    }

    return (
        <TableContainer 
            component={Paper}
            className="tableContainer">
            {loading ? 
                <div className="loading">
                    <CircularProgress />
                </div>
            :
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align="center">Плательщик</TableCell>
                            <TableCell align="center">Получатель</TableCell>
                            <TableCell align="center">Сумма</TableCell>
                            <TableCell align="center">Время создания</TableCell>
                            {/* {!statisticType && 
                                <TableCell align="center" style={{width: 220}}>Создатель</TableCell>
                            } */}
                            <TableCell align="center">Статус</TableCell>
                            <TableCell align="center">Комментарий</TableCell>
                            <TableCell align="center">Счет</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paymentBills.map((bill) => {
                            //const findGeoMarkStatus = projectStatuses.find(status => status.value === item.projectStatus);
                            const localizedBillStatus = billStatuses.find(status => status.value === bill.status);
                            const disabled = loadingBillFile && billId === bill.billId;
                            return (
                                <TableRow key={bill.billId}>
                                    {/* <EditProjectName 
                                        project={item} /> */}
                                    <TableCell align="center">{bill.billId}</TableCell>
                                    <TableCell align="center">{bill.payerName}</TableCell>
                                    <TableCell align="center">{bill.recipientName}</TableCell>
                                    <TableCell align="center">{bill.paymentAmount}</TableCell>
                                    <TableCell align="center">{moment(bill.registrationDate).format("DD.MM.YYYY")}</TableCell>
                                    <TableCell align='center'>{localizedBillStatus ? localizedBillStatus.localization : bill.status}</TableCell>
                                    <TableCell align='center'>{bill.comment}</TableCell>
                                    <TableCell align='center'>
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            onClick={() => showBillFileClick(bill.billId)}
                                            className=""
                                            startIcon={
                                                disabled && 
                                                    <CircularProgress 
                                                        color="secondary" 
                                                        size={16} />
                                            }
                                            disabled={disabled}>
                                            Скачать счет
                                        </Button>
                                    </TableCell>
                                    {/* {!statisticType && 
                                        <TableCell align="center">
                                            <EditProjectCreatorName 
                                                project={item} />
                                        </TableCell>
                                    } */}
                                    {/* <TableCell align="right">
                                        {findGeoMarkStatus && 
                                            <ProjectStatusSelect 
                                                projectId={item.projectId} 
                                                surfacesCount={item.surfacesCount} 
                                                projectStatus={findGeoMarkStatus} 
                                            />
                                        }
                                    </TableCell> */}
                                </TableRow>
                            ) 
                        })}
                        <TableRow>
                            <TableCell><strong>Итого:</strong></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"><strong>{paymentBills.reduce((prev, current) => prev + current.paymentAmount, 0)}</strong></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            }
        </TableContainer>
    );
}

const mapStateToProps = (state: RootState) => ({
    loading: state.event.loading,
    billStatuses: state.bills.billStatuses
});

export default connect(mapStateToProps)(BillsCardTable);