import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions, RootState, selectors } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { 
    Badge, Button, Card, CardContent, CardHeader, FormControl, 
    Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, 
    Menu
} from '@material-ui/core';
import NextBillboardTable from './NextBillboardTable';
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';
import { DefaultPosition } from '../../store/app/types';
import AdvancedFilter from './AdvancedFilter';
import BrandAutocomplete from '../Tariff/BrandAutocomplete';
import InputDatesRange, { DatesRange } from './InputDatesRange';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { 
    BrandAddress, BrandGeoObjectBillboard, BrandGeoObjects, 
    GeoPoint, RollUpCard, SelectedFocus 
} from '../../store/event/types';
import { IMultipleMarker } from '../map/types';
import { 
    AddressInfo, AssignedObject, GeoMarkTimeStatus, 
    IGetAddressItem, ProjectGeoMarkStatus 
} from 'sedi-webserverproxy';
import GetAddressAutocomplete from './GetAddressAutocomplete';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { MessageHelper } from '../MessageHub/MessageHelper';
import ImportExcelDialog from './ImportExcelDialog';
import { randomInteger } from '../../utils/randomInteger';
import RemoveIcon from '@material-ui/icons/Remove';
import moment from 'moment';
import { BillboardsShortInfo } from '../../RemoteCommands/type';
import './bricks.scss';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    openNextBillboard: boolean;
    nextBillboardClose: () => void;
    selectedBrandMarker: (brandMarkers: IMultipleMarker[], brandGeoObject: BrandGeoObjects) => void;
}

const NextBillboardList: React.FC<Props> = (props) => {
    const { 
        openNextBillboard, 
        nextBillboardClose,
        maxDistance,
        maxDistanceAction,
        maxDistanceSearchAction,
        draggable,
        positionNextBillboard,
        positionNextBillboardAction,
        currentBrandInfo,
        brandGeoObjects,
        newBrandGeoObjects,
        newBrandGeoObjectsAction,
        selectedBrandMarker,
        datesAction,
        billboardsShortInfo,
        addresses,
        selectedAddress,
        addressesAction,
        selectedAddressAction,
        addressValueAction,
        projectID,
        statuses,
        billboardAB,
        rollUpCards, 
        rollUpCardAction,
        regions
    } = props;

    const [dates, setDates] = useState<DatesRange>({
        DateFrom: undefined,
        DateTo: undefined
    });

    const [selectedRegion, setSelectedRegion] = useState('all');
    const [regionName, setRegionName] = useState("");
    const [importExcelOpen, setImportExcelOpen] = useState(false);
    const [progress, setProgress] = React.useState<number | null>(null);
    const [disabledProject, setDisabledProject] = useState(true);
    const [successProject, setSuccessProject] = useState(false);

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleGeoObjectsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleGeoObjectsClose = () => {
        setAnchorEl(null);
    };

    const geoObjectId = -Math.random() * (5000 - 1) + 1;
    const disabledSearch = brandGeoObjects.length === 0 && newBrandGeoObjects.length === 0;

    const selectedAddressInfo = (regionName: string) => {
        setRegionName(regionName)
    }

    const importExcelClickClose = () => {
        setImportExcelOpen(false);
    }

    useEffect(() => {
        SystemEvent.SubscribeEventImportBrands(
            "SearchOblectsBrand", 
            (answer) => {
                let newGeoMarks: BrandGeoObjects[] | null = null
                if(answer.geoMarks) {
                    const parseGeoMarks = JSON.parse(answer.geoMarks);
                    newGeoMarks = parseGeoMarks.map((item: any) => {
                        if(item.GeoMarkId < 1) {
                            item.GeoMarkId = -randomInteger(1, 1000);
                        }
                        return (
                            Object.assign({ Billbaords: null }, item)
                        )
                    })
                }
                setProgress(Number(answer.executionPercent));
                // setGeoMarks(newGeoMarks);
                if(newGeoMarks) {
                    newBrandGeoObjectsAction(newGeoMarks)
                }
            }, 
            (error) => errorCallback(error)
        );
        MessageHelper.subscribeProgress("SearchOblectsBrand", (data) => {
            setProgress(data.progress);
        });
        SystemEvent.SubscribeEventSetGeoMarkTimeStatus(
            "SearchObjectsBrand", 
            (answer) => {
                console.log(answer);
            }, 
            (error) => { 
                setDisabledProject(false);
                setSuccessProject(false);
                errorCallback(error);
            }
        );
    }, []);

    useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    });

    useEffect(() => {
        if(openNextBillboard) {
            setTimeout(() => {
                maxDistanceSearchAction(true);
            }, 1000)
        }
    }, [openNextBillboard]);

    const handleChangeDates = (dates: DatesRange) => {
        setDates(dates);
        datesAction(dates);
    }

    const nextBillboardClick = () => {
        nextBillboardClose();
    }

    const saveBrandGeoObjects = () => {
        for(let geoObject of newBrandGeoObjects) {
            if(geoObject.GeoMarkId < 1) {
                geoObject.GeoMarkId = -1;
            }
        }

        if(currentBrandInfo) {
            const builder = new EventBuilder(`EventSetBrandGeoObjects`);
                builder.AddParameter('BrandId', currentBrandInfo.brandId);
                builder.AddParameter('BrandGeoObjects', JSON.stringify(newBrandGeoObjects));
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            newBrandGeoObjectsAction([]);
            // if(geoMarks) {
            //     let newGeoMarks: BrandGeoObjects[] = [];
            //     const builderGeoMarks = new EventBuilder(`EventSetBrandGeoObjects`);
            //         builderGeoMarks.AddParameter('BrandId', currentBrandInfo.brandId);
            //         builderGeoMarks.AddParameter('BrandGeoObjects', JSON.stringify(geoMarks));
            //         ServerProxy.SendSystemEvent(builderGeoMarks.GetSystemEvent());
            //     for(let geoMark of geoMarks) {
            //         if(geoMark.GeoPoint === null) {
            //             newGeoMarks.push(geoMark)
            //         }
            //     }
            //     setGeoMarks(newGeoMarks);
            // }
        }
    }

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        positionNextBillboardAction(position);
    };

    const searchClick = () => {
        if(selectedAddress !== null) {
            const city = selectedAddress.c ? selectedAddress.c + ', ' : ''; 
            const street = selectedAddress.s ? selectedAddress.s + ', ' : '';
            const address: BrandAddress = {
                AddressString: city + street + selectedAddress.v,
                CityName: city,
                CountryName: selectedAddress.co,
                GeoPoint: {
                    Latitude: selectedAddress.g.lat,
                    Longitude: selectedAddress.g.lon
                },
                HouseNumber: selectedAddress.s ? selectedAddress.v : undefined,
                StreetName: selectedAddress.s
            }
            const geoObjectName = street + selectedAddress.v.replace(/ *\([^)]*\) */g, "");
            const brandItem: BrandGeoObjects = {
                GeoMarkId: geoObjectId,
                GeoMarkName: geoObjectName,
                Address: address,
                GeoPoint: {
                    Latitude: selectedAddress.g.lat,
                    Longitude: selectedAddress.g.lon
                },
                Billboards: null
            }
            newBrandGeoObjectsAction([...newBrandGeoObjects, brandItem]);
        } else {
            const items = addresses.map((item) => {
                const city = item.c ? item.c + ', ' : ''; 
                const street = item.s ? item.s + ', ' : '';
                const address: BrandAddress = {
                    AddressString: city + street + item.v,
                    CityName: city,
                    CountryName: item.co,
                    GeoPoint: {
                        Latitude: item.g.lat,
                        Longitude: item.g.lon
                    },
                    HouseNumber: item.s ? item.v : undefined,
                    StreetName: item.s
                }
                const geoObjectName = item.v.replace(/ *\([^)]*\) */g, "");
                const geoObjectID = -Math.random() * (5000 - 1) + 1;
                const center: GeoPoint = {
                    Latitude: item.g.lat,
                    Longitude: item.g.lon
                }
                return new BrandGeoObjects(geoObjectID, geoObjectName, address, center);
            });
            const itemsConcat = newBrandGeoObjects.concat(items);
            newBrandGeoObjectsAction(itemsConcat);
        }
        selectedAddressAction(null);
        addressesAction([]);
        addressValueAction('');
    }

    const filterOffer = (billboard: BillboardsShortInfo) => {
        const filterStatuses = statuses.filter(status => status.geoMarkId === billboard.geoMarkId)
        for(let status of filterStatuses.filter(status => status.geoMarkStatus === 'Paid' || status.geoMarkStatus === 'Booked')) {
            const index = filterStatuses.indexOf(status, 0);
            if (index > -1) {
                filterStatuses.splice(index, 1);
            }
        }
        let sidesAll: string[] = [];
        
        if(billboard.boardSides && billboard.boardSides.length > 0){
            sidesAll = billboard.boardSides.filter(item => item.startsWith(billboardAB));
        }

        if(sidesAll.length === 0) {
            return null
        }

        for(let side of sidesAll) {
            if(!filterStatuses.some(status => status.geoMarkPart === side && status.geoMarkStatus === 'Reserved')) {
                return side
            }
        }
        return sidesAll[0]
    }

    const addBillboardsProject = () => { 
        const { 
            dates, currentGeoMarkStatus, clientItem, 
            openSelectedBillboardsAction, selectedFocusAction, 
            setStatus
        } = props;
        
        if (dates.DateFrom === undefined
            || dates.DateTo === undefined) {
            return;
        }

        let filterBillboards: BrandGeoObjectBillboard[] = [];
        

        const allBrandGeoObjects = brandGeoObjects.concat(newBrandGeoObjects);

        // if(geoMarks) {
        //     allBrandGeoObjects.concat(geoMarks);
        // }

        for(let brandGeoObject of allBrandGeoObjects) {
            if(brandGeoObject.Billboards) {
                for(let geoObjectBillboard of brandGeoObject.Billboards) {
                    const findBillboards = billboardsShortInfo.filter(billboard => billboard.geoMarkId === geoObjectBillboard.GeoMarkId)
                    for(let findBillboard of findBillboards) {
                        if(filterBillboards.some(item => item.billboard.geoMarkId === findBillboard.geoMarkId)) {
                            continue;
                        }
                        
                        const { Address } = brandGeoObject;
                        let assignedObject: AssignedObject | undefined = undefined;
                        
                        if(Address) {
                            assignedObject = {
                                cityName: Address.CityName ? Address.CityName : "",
                                streetName: Address.StreetName ? Address.StreetName : "",
                                objectName: Address.ObjectName ? Address.ObjectName : "",
                                houseNumber: Address.HouseNumber ? Address.HouseNumber : "",
                                geoPoint: {
                                    latitude: Address.GeoPoint ? Address.GeoPoint.Latitude : 0,
                                    longitude: Address.GeoPoint ? Address.GeoPoint.Longitude : 0,
                                }
                            }
                        }
                        
                        filterBillboards.push({
                            address: assignedObject,
                            billboard: findBillboard
                        });
                    }
                }
            }
        }

        const sidesReserved = filterBillboards.map((item: BrandGeoObjectBillboard) => {
            const sideName = filterOffer(item.billboard)
            if(sideName !== null) {
                if (dates.DateFrom === undefined
                    || dates.DateTo === undefined) {
                    return;
                }
                const newStatus: GeoMarkTimeStatus = {
                    dateTimeEnd: moment(dates.DateTo).format('YYYY-MM-DDTHH:mm:ss'),
                    dateTimeStart: moment(dates.DateFrom).format('YYYY-MM-DDTHH:mm:ss'),
                    geoMarkId: item.billboard.geoMarkId,
                    geoMarkPart: sideName,
                    geoMarkStatus: currentGeoMarkStatus.value as ProjectGeoMarkStatus,
                    geoMarkTimeStatusId: -1,
                    projectId: projectID,
                    assignedObject: item.address
                }
                return newStatus
            }
            return null;
        }).filter(item => item !== null);

        
        if(projectID <= 0) {
            openSelectedBillboardsAction(true);
            if(!clientItem) {
                selectedFocusAction(SelectedFocus.company);
            } else {
                selectedFocusAction(SelectedFocus.project);
            }
        } else {
            SystemEvent.EventSetGeoMarkTimeStatus(sidesReserved as GeoMarkTimeStatus[], false);
            selectedFocusAction(SelectedFocus.none);
            setDisabledProject(true);
            setSuccessProject(true);
        }

        // for(let side of sidesReserved) {
        //     if(projectID <= 0) {
        //         openSelectedBillboardsAction(true);
        //         if(!clientItem) {
        //             selectedFocusAction(SelectedFocus.company);
        //         } else {
        //             selectedFocusAction(SelectedFocus.project);
        //         }
        //     } else {
        //         //setStatus(side as GeoMarkTimeStatus);
        //         SystemEvent.EventSetGeoMarkTimeStatus([side as GeoMarkTimeStatus], false);
        //         selectedFocusAction(SelectedFocus.none);
        //         setDisabledProject(true);
        //         setSuccessProject(true);
        //     }
        // }
    }

    useEffect(() => {
        if(!successProject) {
            brandGeoObjects.map(item => {
                if(item.Billboards !== null && (item.Billboards && item.Billboards.length > 0)) {
                    setDisabledProject(false);
                }
            });
    
            newBrandGeoObjects.map(item => {
                if(item.Billboards !== null && (item.Billboards && item.Billboards.length > 0)) {
                    setDisabledProject(false);
                }
            });
        }
    });

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Ближайшие конструкции",
            key: "NextBillboardList"
        });
    }

    const clearAllGeoObjects = () => {
        newBrandGeoObjectsAction([]);
        handleGeoObjectsClose();
    }

    const deleteNoGeoObjects = () => {
        let geoObjects: BrandGeoObjects[] = newBrandGeoObjects;
        for(var i = 0; i < geoObjects.length; i++) {
            const geoObjectBillboards = geoObjects[i].Billboards;
            if(geoObjectBillboards === null || (geoObjectBillboards !== null && geoObjectBillboards.length === 0)) {
                geoObjects.splice(i, 1); 
                i--; 
            }
        }
        newBrandGeoObjectsAction(geoObjects);
        handleGeoObjectsClose();
    }

    const rollUpCardHas = rollUpCards.some(item => item.key === "NextBillboardList");

    return (
        <>
        <Draggable
            handle=".billboardList"
            defaultPosition={positionNextBillboard}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card 
                className={`
                    billboardList ${openNextBillboard ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    title={
                        <>
                            <Grid 
                                container 
                                spacing={2} 
                                justify="space-between" 
                                alignItems="center">
                                <Grid item>
                                    <div className="billboardList__title">Ближайшие конструкции</div>
                                </Grid>
                                <Grid item>
                                    <div className="billboardList__right">
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            disabled={disabledProject}
                                            onClick={addBillboardsProject}>
                                            Добавить в проект
                                        </Button>
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            disabled>
                                            Сохранить в Excel
                                        </Button>
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            disabled>
                                            Создать проект рекламной рассылки
                                        </Button>
                                        <Tooltip
                                            title="Свернуть"
                                            placement="top">
                                            <IconButton
                                                onClick={rolleUpClick}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton
                                            onClick={nextBillboardClick}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid 
                                container 
                                spacing={2} 
                                justify="space-between" 
                                alignItems="center">
                                <Grid item>
                                    <div className="billboardList__left">
                                        <label className="billboardList__left-label">Максимальное удаление:</label>
                                        <TextField 
                                            size="small" 
                                            variant="outlined" 
                                            value={maxDistance} 
                                            type="number" 
                                            style={{ width: 110 }}
                                            className="cancelDraggable"
                                            onChange={(event) => 
                                                maxDistanceAction(event.target.value)} />
                                        <span className="billboardList__left-span">метров</span>
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            disabled={disabledSearch}
                                            onClick={() => maxDistanceSearchAction(true)}>
                                            Поиск
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Grid 
                                        container 
                                        spacing={2} 
                                        justify="flex-end" 
                                        alignItems="center">
                                        <Grid item>
                                            <FormControl 
                                                variant="outlined" 
                                                size="small" 
                                                style={{ width: 150 }}
                                                className="cancelDraggable">
                                                <InputLabel ref={inputLabel}>Выберите регион</InputLabel>
                                                <Select
                                                    value={selectedRegion}
                                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => 
                                                        setSelectedRegion(event.target.value as string)}
                                                    labelWidth={labelWidth}>
                                                    <MenuItem value="all">Все</MenuItem>
                                                    {regions.map(item => {
                                                        return (
                                                            <MenuItem 
                                                                value={item} 
                                                                key={'menu-item-' + item}>
                                                                    <span   
                                                                        onClick={() => selectedAddressInfo(item)}>
                                                                        {item}
                                                                    </span>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <GetAddressAutocomplete 
                                                addressInfo={null} 
                                                regionName={regionName} />
                                        </Grid>
                                        <Grid item>
                                            <Tooltip 
                                                title={`Добавление объектов бренда ${currentBrandInfo && currentBrandInfo.brandName || ""}`}
                                                placement="top">
                                                    <Badge 
                                                        badgeContent={selectedAddress ? 1 : addresses.length} 
                                                        color="secondary">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={searchClick}
                                                            disabled={selectedAddress || addresses.length > 0 ? false : true}>
                                                            Добавить
                                                        </Button>
                                                    </Badge>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <AdvancedFilter 
                                        toggleFilter={true} 
                                        nextBillboardFilter={true} />
                                </Grid>
                                <Grid item xs={7}>
                                    <Grid 
                                        container
                                        spacing={1}
                                        className="saveBrandGeoObjects">
                                        <Grid item xs={12}>
                                            <InputDatesRange 
                                                showShiftControl={false}
                                                allowUndefinedValues={true}
                                                onChange={(dates) => handleChangeDates(dates)}
                                                dates={dates}
                                                disabled={false} />
                                        </Grid>
                                        <Grid item>
                                            <BrandAutocomplete 
                                                disabled={false} /> 
                                        </Grid>
                                        <Grid item>
                                            <Tooltip 
                                                title="Сохранить как объекты бренда"
                                                placement="top">
                                                <Button 
                                                    variant="contained" 
                                                    color="primary"
                                                    onClick={saveBrandGeoObjects}>
                                                    Сохранить
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip 
                                                title="Импортировать объекты из Excel-таблицы"
                                                placement="top">
                                                <Button 
                                                    variant="contained"
                                                    color="primary"
                                                    endIcon={
                                                        progress && 
                                                            <>
                                                                {progress !== 100 && 
                                                                    <CircularProgressWithLabel value={progress} />
                                                                }
                                                            </>
                                                    }
                                                    onClick={() => setImportExcelOpen(true)}>
                                                    Импортировать
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip 
                                                title="Очистить формуляр"
                                                placement="top">
                                                <Button 
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleGeoObjectsClick}
                                                    disabled={newBrandGeoObjects.length > 0 ? false : true}>
                                                    Очистить
                                                </Button>
                                            </Tooltip>
                                            <Menu
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleGeoObjectsClose}
                                            >
                                                <MenuItem onClick={clearAllGeoObjects}>Очистить все</MenuItem>
                                                <MenuItem onClick={deleteNoGeoObjects}>Удалить строки без ближайших конструкций</MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>          
                                </Grid>
                            </Grid>
                        </>
                    }
                />
                <CardContent className="billboardList__table">
                    <NextBillboardTable
                        dates={dates} 
                        selectedBrandMarker={(brandMarkers: IMultipleMarker[], brandGeoObject: BrandGeoObjects) => 
                            selectedBrandMarker(brandMarkers, brandGeoObject)
                        } 
                        openNextBillboard={openNextBillboard}/>
                </CardContent>
            </Card>
        </Draggable>
        <ImportExcelDialog 
            importExcelOpen={importExcelOpen} 
            importExcelClickClose={importExcelClickClose} />
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    maxDistance: state.event.maxDistance,
    draggable: state.app.draggable,
    positionNextBillboard: state.app.positionNextBillboard,
    currentBrandInfo: state.billboards.currentBrandInfo,
    brandGeoObjects: state.event.brandGeoObjects,
    newBrandGeoObjects: state.event.newBrandGeoObjects,
    maxDistanceSearch: state.event.maxDistanceSearch,
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    addresses: state.event.addresses,
    selectedAddress: state.event.selectedAddress,
    eventError: state.event.eventError,
    projectID: state.todo.projectID,
    statuses: selectors.billboards.getAllStatuses(state.billboards),
    billboardAB: state.app.billboardAB,
    dates: state.todo.dates,
    currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
    clientItem: state.customers.clientItem,
    rollUpCards: state.event.rollUpCards,
    regions: state.event.regions
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    maxDistanceAction: (maxDistance: string) => 
        dispatch(actions.event.maxDistanceAction(maxDistance)),
    maxDistanceSearchAction: (maxDistanceSearch: boolean) => 
        dispatch(actions.event.maxDistanceSearchAction(maxDistanceSearch)),
    positionNextBillboardAction: (positionNextBillboard: DefaultPosition) => 
        dispatch(actions.app.positionNextBillboardAction(positionNextBillboard)),
    newBrandGeoObjectsAction: (newBrandGeoObjects: BrandGeoObjects[]) => 
        dispatch(actions.event.newBrandGeoObjectsAction(newBrandGeoObjects)),
    datesAction: (dates: DatesRange) => dispatch(actions.todo.datesAction(dates)),
    addressesAction: (addresses: IGetAddressItem[]) => 
        dispatch(actions.event.addressesAction(addresses)),
    selectedAddressAction: (selectedAddress: IGetAddressItem | null) => 
        dispatch(actions.event.selectedAddressAction(selectedAddress)),
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => 
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    selectedFocusAction: (selectedFocus: SelectedFocus) => 
        dispatch(actions.event.selectedFocusAction(selectedFocus)),
    setStatus: (status:GeoMarkTimeStatus) => 
        dispatch(actions.billboards.saveBillboardStatusActionCreator(status)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard))
});

export default connect(mapStateToProps, mapDispatchToProps)(NextBillboardList);