import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, selectors, actions } from '../../store';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';       
import { IconButton, CardHeader, Grid, Button, Link, CircularProgress, Badge } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getTranslate, Translate } from "react-localize-redux";
import { EmployeeBrandsInfo, LatLong, AssignedObject, Project, ProjectGeoMarkStatus, GetCustomersFilter } from 'sedi-webserverproxy';
import PropertiesTable from './PropertiesTable';
import MailIcon from '@material-ui/icons/Mail';
import Tooltip from '@material-ui/core/Tooltip';
import SendEmail from '../bricks/SendEmail';
import { getWebServerProxy } from '../../WebServerProxy';
import Draggable from 'react-draggable';
import { DefaultPosition } from '../../store/app/types';
import InputDatesRange, { DatesRange, getMonthDates } from '../bricks/InputDatesRange';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { EventBuilder } from '../../utils/eventHelpers';
import ClientItemAutocomplete from '../bricks/ClientItemAutocomplete';
import BrandAutocomplete from '../Tariff/BrandAutocomplete';
import ProjectStatusSelect from './ProjectStatusSelect';
import { RollUpCard, SelectedFocus } from '../../store/event/types';
import { ProjectStatuses, ProjectType } from '../../store/todo/types';
import RemoveIcon from '@material-ui/icons/Remove';
import ProjectOnlyAutocomplete from '../bricks/ProjectOnlyAutocomplete';
import { ClientItem } from '../../store/customers/types';
import ScoreModal from './ScoreModal';
import ProjectDiscountComponent from './ProjectDiscount';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { AutocompleteType, BillboardsShortInfo, GetProjectParmas, ProjectInfo } from '../../RemoteCommands/type';
import GetAutocompleteList from '../bricks/GetAutocompleteList';
import { textCut } from '../../utils/textCut';
import { clientsArray } from '../../utils/clientsArray';
import { localStorageGetItem } from '../../utils/storage';
import { MessageHelper } from '../MessageHub/MessageHelper';
import { downloadExcel } from '../../utils/downloadFiles';
import CircularProgressWithLabel from '../bricks/CircularProgressWithLabel';
import './Projects.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    handleChangeDates: (dates: DatesRange) => void;
    dates: DatesRange;
    showProjectBillboardsPoints: (geoPoints: LatLong[], assignedObjects: AssignedObject[]) => void;
}

const ProjectSelectedCard: React.FC<Props> = (props) => {
    const { billboardsShortInfo, projectID, currentCompany, projects, setActiveProject, 
        openSelectedBillboardsAction, openSelectedBillboards,
        commercialOfferAction, commercialOffer, eventName, totalDiscountTextAction, discountOpenAction, discountOpen, 
        draggable, defaultPositionAction, defaultPosition, currentGeoMarkStatusAction, currentGeoMarkStatus, 
        setActiveProjectActionCreator, handleChangeDates, currentBrand, currentBrandAction, brands,
        selectedFocus, clientItem, rollUpCards, rollUpCardAction, datesAction, clientItemAction, currentBrandInfoAction,
        openAdditionalCostAction, successSnackbar, projectStatuses, loadingStatus, loadingAction, billboardsShortInfoAction,
        billboardFilters, companies, customers, agencyInfo, gidAction, setAllProjects, successMessage, customerCompanyLoaded
    } = props;

    const [openSendEmail, setOpenSendEmail] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [currentBillboards, setCurrentBillboards] = useState<BillboardsShortInfo[]>([]);
    const [scoreOpen, setScoreOpen] = useState(false);
    const [currentProject, setCurrentProject] = useState<Project | null>(null);
    const [loadingPage, setLoadingPage] = useState(false);
    const [additionalCost, setAdditionalCost] = useState<number>(0);
    const [geoMarkGid, setGeoMarkGid] = useState<string | undefined>(undefined);
    const [executionPercent, setExecutionPercent] = useState<number | null>(null);
    const [excelFileBytes, setExcelFileBytes] = useState<string | null>(null);
    const [disabled, setDisabled] = useState(false);

    const [projectInfo, setProjectInfo] = useState<ProjectInfo | null>(null);
    const [dates, setDates] = useState<DatesRange>({
        DateFrom: undefined,
        DateTo: undefined
    });
    const [allText, setAllText] = useState(false);
    const limit = 300;
    const arrowToggle = projectInfo ? projectInfo.financesString.length >= limit : false;

    useEffect(() => {
        SystemEvent.SubscribeEventSetGeoMarkTimeStatus(
            "ProjectSelectedCard", 
            (answer) => {
                SystemEvent.EventGetProjectInfo(projectID);
            }, 
            (error) => {
                errorCallback(error);
                SystemEvent.EventGetProjectInfo(projectID);
            }
        );
    }, [projectID]);

    useEffect(() => {
        SystemEvent.SubscribeEventSaveProjectAdditionalCosts(
            "ProjectSelectedCard", 
            (answer) => {
                setAdditionalCost(answer);
                SystemEvent.EventGetProjectAdditionalCostParameters(projectID);
            }, 
            (error) => errorCallback(error)
        );
        SystemEvent.SubscribeEventGenerateCommercialOfferExcelFile(
            "ProjectSelectedCard", 
            (answer) => {
                setExecutionPercent(Number(answer.executionPercent));
                setExcelFileBytes(answer.excelFileBytes);
            }, 
            (error) => {
                setDisabled(false);
                errorCallback(error)
            }
        );
        MessageHelper.subscribeProgress("ProjectSelectedCard", (data) => {
            setExecutionPercent(data.progress);
        });
    }, []);

    useEffect(() => {
        if(excelFileBytes) {
            const fileName = `Project-${projectID}`
            downloadExcel(excelFileBytes, fileName);
            setExecutionPercent(null);
            setDisabled(false);
        }
    }, [excelFileBytes]);

    useMemo(() => {
        SystemEvent.SubscribeEventGetProjects(
            "ProjectSelectedCard", 
            (answer) => {
                loadingAction(false);
                setAllProjects(answer.projectsInfo);
            }, 
            (error) => {
                loadingAction(false);
                errorCallback(error);
            }
        );
        if(clientItem) {
            const payload: GetProjectParmas = {
                projectType: ProjectType.CommonProject,
                ownerAccountId: clientItem.accountId,
                language: localStorageGetItem("lang")
            }
            SystemEvent.EventGetProjects(payload);
        }
    }, [clientItem]);

    useEffect(() => {
        if(successMessage) {
            SystemEvent.EventGetProjectInfo(projectID);
        }
    }, [successMessage]);

    useEffect(() => {
        SystemEvent.SubscribeEventChangeProject(
            "ProjectSelectedCard", 
            (answer) => {
                loadingAction(false);
                SystemEvent.EventGetProjectInfo(projectID);
                setGeoMarkGid(undefined);
            }, 
            (error) => {
                loadingAction(false);
                SystemEvent.EventGetProjectInfo(projectID);
                setGeoMarkGid(error.errorParam)
                errorCallback(error)
            }
        );
        SystemEvent.SubscribeEventGetProjectInfo(
            "ProjectSelectedCard", 
            (answer) => {
                setProjectInfo(answer);
                let _currentBillboards: BillboardsShortInfo[] =  [];
                for(let geoMark of answer.geoMarks) {
                    const findBillboards = billboardsShortInfo.filter(item => item.geoMarkId === geoMark.geoMarkId);
                    _currentBillboards = findBillboards;
                }
                setCurrentBillboards(_currentBillboards);
                currentGeoMarkStatusAction({
                    localization: answer.localizedStatus,
                    value: answer.projectStatus as ProjectGeoMarkStatus
                });
                setTimeout(() => {
                    setLoadingPage(false);
                }, 500);
            }, 
            (error) => {
                setLoadingPage(false);
                errorCallback(error);
            }
        );
        if(projectID > 0) {
            setLoadingPage(true);
            SystemEvent.EventGetProjectInfo(projectID);
        } else {
            setProjectInfo(null);
            setLoadingPage(false);
        }
    }, [projectID]);

    useEffect(() => {
        if(projectInfo) {
            setProjectInfo({
                ...projectInfo,
                additionalCost: additionalCost
            });
        }
    }, [additionalCost]);

    const handleScoreClose = () => {
        setScoreOpen(false);

    }

    let inputRef: any;

    useEffect(() => {
        if(selectedFocus === SelectedFocus.project) {
            if(projects.length === 0 && clientItem) {
                const projectName = 'project ' + new Date().toLocaleString();
                const builder = new EventBuilder(`EventCreateProject`);
                    builder.AddParameter('ProjectOwnerAccountId', clientItem.accountId);
                    builder.AddParameter('ProjectName', projectName);
                if(currentBrand) {
                    builder.AddParameter('BrandId', currentBrand.brandId);
                } 
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            }
            inputRef.focus();
        }
    }, [selectedFocus]);

    const offerId = commercialOffer ? commercialOffer.offerId : 0;

    const discountClickOpen = async() => {
        await commercialOfferAction(offerId, projectID);
        discountOpenAction(true);
    };

    const discountClickClose = () => {
        discountOpenAction(false);
    };

    const handleOpenSend = async() => {
        await commercialOfferAction(offerId, projectID);
        setOpenSendEmail(true);
    };

    const handleCloseSend = () => {
        setOpenSendEmail(false);
    };

    const getExelFile = async () => {
        setDisabled(true);
        // setLoading(true)
        // try {
        //     const blob = await getWebServerProxy().WebClient.GenerateOffer(projectID);
        //     const name = `Project-${projectID}.xlsx`
        //     saveByteFile(blob, name)
        // } catch (err) {
        //     console.log('Ошибка GenerateOffer', err)
        // }
        // setLoading(false)
        SystemEvent.EventGenerateCommercialOfferExcelFile(projectID);
    }

    const sandLoadingTrue = () => {
        setSendLoading(true);
    }

    useEffect(() => {
        if(eventName === "EventSendCommercialOfferToClient") {
            setSendLoading(false);
        }
    }, [eventName]);

    useEffect(() => {
        totalDiscountTextAction('');
    }, [currentCompany, projectID]);

    const projectCardClose = () => {
        openSelectedBillboardsAction(false);
        discountOpenAction(false);
        setActiveProjectActionCreator(-1);
        datesAction(getMonthDates());
        setDates(getMonthDates());
        clientItemAction(null);
        setActiveProject(-1);
        currentBrandInfoAction(null);
        openAdditionalCostAction(false);
        gidAction(undefined);
        currentGeoMarkStatusAction({
            value: ProjectGeoMarkStatus.New,
            localization: "Новый"
        });
        SystemEvent.EventGetBillboardsShortInfo(billboardFilters);
    }

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        defaultPositionAction(position);
    };

    const getProject = useCallback(async () => {
        if(projectID > 0) {
            const response = await getWebServerProxy().ToDo.GetProject(projectID);
            if(response.Result) {
                const client = clientsArray(companies, customers, agencyInfo);
                const findClient = client.find(item => item.accountId === response.Result!.customerAccountId);
                if(findClient) {
                    clientItemAction(findClient);
                }
                else {
                    customerCompanyLoaded({ accountId: response.Result!.customerAccountId, isLegal: false }).then(result => {
                        if (result && result.customers && result.customers.length > 0 && result.customers[0]){
                            clientItemAction(result.customers[0]);
                        }
                    });
                }
                const brand = brands.find(item => item.brandId === response.Result!.brandId);
                currentBrandAction(brand);
                setCurrentProject(response.Result);
            }
        }
    }, [projectID]);

    const eventGetCommercialOfferDiscounts = () => {
        if(projectID > 0) {
            const builder = new EventBuilder(`EventGetCommercialOfferDiscounts`);
                builder.AddParameter('ProjectId', projectID);
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }
    };


    useEffect(() => {
        if(currentProject) {
            const dates = {
                DateFrom: currentProject.dateFrom,
                DateTo: currentProject.dateTo
            }
            datesAction(dates);
            setDates(dates);
        }
    }, [currentProject])

    useEffect(() => {
        eventGetCommercialOfferDiscounts();
    }, [successSnackbar]);

    useEffect(() => {
        const currentProject = projects.find(project => project.projectId === projectID);
        if(currentProject) {
            const currentStatus = projectStatuses.find(item => item.value === currentProject.projectStatus);
            if(currentStatus) {
                currentGeoMarkStatusAction(currentStatus);
            }
        }
        eventGetCommercialOfferDiscounts();
        getProject();
    }, [projectID]);  

    useEffect(() => {
        if(offerId > 0) {
            commercialOfferAction(offerId, projectID);
        }
    }, [offerId, projectID, openSelectedBillboards]);

    const disabledStatus = currentGeoMarkStatus && (currentGeoMarkStatus.value === "Paid" || currentGeoMarkStatus.value === "Sold");

    const showOnMapClick = () => {
        if(!projectInfo) {
            return;
        }
        const billboards = projectInfo.geoMarks.map(geoMark => {
            const findBillboards = billboardsShortInfo.filter(billboard => billboard.geoMarkId === geoMark.geoMarkId);
            return findBillboards[0]
        });
        
        billboardsShortInfoAction(billboards);
    }

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Текущий проект",
            key: "ProjectSelectedCard"
        });
    }

    const rollUpCardHas = rollUpCards.some(item => item.key === "ProjectSelectedCard");

    const currentProjectCardView = () => {
        if(loadingPage) {
            return (
                <div className="loading">
                    <CircularProgress />
                </div>
            )
        } else {
            return (
                <>
                    <div className="discountRow">
                        <div className="discountRow__discount">
                            <div className="linkDiscount" onClick={discountClickOpen}><Translate id='SetDiscount' /></div>
                            <div className="totalDiscountText">{projectInfo && (allText ? projectInfo.financesString : textCut(projectInfo.financesString, limit))}
                                {!allText && <span> Сумма всего: {projectInfo && projectInfo.projectTotalCost} руб.</span>}
                                {arrowToggle && 
                                    <span onClick={() => setAllText(!allText)} className={`notification__more link ${allText && 'toggle'}`}>
                                        {allText ? " Свернуть" : " Подробнее"}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <Grid 
                        container 
                        spacing={1} 
                        alignItems="center" 
                        justify="space-between"
                        className="mb-1">
                        <Grid item xs={10}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3}>
                                    <BrandAutocomplete disabled={projectID > 0 ? false : true} />
                                </Grid>
                                <Grid item>
                                    <div className="dateRange">
                                        <InputDatesRange 
                                            showShiftControl={false}
                                            allowUndefinedValues={false}
                                            onChange={(dates) => { handleChangeDates(dates)}}  
                                            dates={dates} 
                                            disabled={true} />
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            className="ml-2"
                                            onClick={showOnMapClick}>
                                            Показать все на карте
                                        </Button>
                                        <Badge
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            badgeContent={
                                                (projectInfo && projectInfo.additionalCost) 
                                                    ? projectInfo.additionalCost 
                                                    : additionalCost
                                            } 
                                            color="error"
                                            max={9999999}
                                            showZero
                                        >
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                className="ml-2"
                                                onClick={() => openAdditionalCostAction(true)}
                                                disabled={Boolean(projectInfo && projectInfo.geoMarks.length === 0)}>
                                                Доп стоимость
                                            </Button>
                                        </Badge>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {loadingStatus && 
                                <CircularProgress className="mt-2" size={20} />
                            }
                        </Grid>
                        {projectID > 0 && 
                            <Grid item>
                                {projectInfo &&
                                    <ProjectStatusSelect 
                                        projectId={projectID} 
                                        surfacesCount={projectInfo.geoMarkTimeStatusesCount} 
                                        projectStatus={currentGeoMarkStatus} 
                                    />
                                }
                            </Grid>
                        }
                    </Grid>
                    {projectInfo && 
                        <PropertiesTable 
                            geoMarks={projectInfo.geoMarks}
                            disabled={disabledStatus} 
                            geoMarkGid={geoMarkGid} />
                    }
                </>
            )
        }
    }

    return (
        <>
            <Draggable
                handle=".selectedCard"
                defaultPosition={defaultPosition}
                disabled={!draggable}
                onStop={onStop}
                cancel=".cancelDraggable">
                <Card 
                    className={`
                        selectedCard ${openSelectedBillboards ? 'openSelectedCard' : 'closeSelectedCard'}
                        ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                    `} 
                    style={{cursor: draggable ? 'move' : 'auto'}}>
                    <CardHeader
                        action={
                            <div className="button-group">
                                <Tooltip
                                    title="Свернуть"
                                    placement="top">
                                    <IconButton
                                        onClick={rolleUpClick}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                                <IconButton
                                    onClick={projectCardClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        }
                        title={
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <div className="box-title">Проект</div>
                                </Grid>
                                <Grid item xs={4}>
                                    <ClientItemAutocomplete 
                                        selectedCard={true} />
                                </Grid>
                                <Grid item xs={3}>
                                    {clientItem ? 
                                        <ProjectOnlyAutocomplete 
                                            disabled={false} 
                                            label="Выберите проект" 
                                            inputRef={(input) => inputRef = input} />
                                    : 
                                        <GetAutocompleteList 
                                            label="Выберите проект" 
                                            type={AutocompleteType.Project} 
                                            selectedCard={true} />
                                    }
                                </Grid>
                                <Grid item xs={4} className="text-right">
                                    <Tooltip title="Отправить на почту" placement="top">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleOpenSend}
                                            startIcon={sendLoading ? <CircularProgress color="secondary" size={24} thickness={4} /> : <MailIcon />}
                                            disabled={sendLoading ? true : false}
                                            className="mr-3"
                                        >
                                            Отправить
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Сохранить в Exel" placement="top">
                                    <Badge
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        badgeContent={projectInfo && projectInfo.geoMarks.length} 
                                        color="error"
                                        max={9999}
                                    >
                                        <Link 
                                            id="download_link"
                                            onClick={() => getExelFile()}>
                                                <Badge 
                                                    badgeContent={projectInfo && projectInfo.geoMarkTimeStatusesCount} 
                                                    color="error"
                                                    max={9999}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={disabled}
                                                        startIcon={
                                                            executionPercent 
                                                            ?   
                                                                <>
                                                                    {executionPercent !== 100 && 
                                                                        <CircularProgressWithLabel value={executionPercent} />
                                                                    }
                                                                </> 
                                                            : <img src="https://img.icons8.com/color/24/000000/ms-excel.png" />
                                                        }>
                                                        Сохранить
                                                    </Button>
                                                </Badge>
                                        </Link>
                                    </Badge>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        }
                    />
                    <CardContent className="pt-0">
                        {currentProjectCardView()}
                    </CardContent>
                </Card>    
            </Draggable>
            {openSendEmail && 
                <SendEmail 
                    handleCloseSend={handleCloseSend}
                    openSendEmail={openSendEmail} 
                    currentCompany={currentCompany}
                    offerId={offerId} 
                    sandLoadingTrue={sandLoadingTrue}/>
            }
            {discountOpen && projectInfo &&
                <ProjectDiscountComponent 
                    discountOpen={discountOpen} 
                    discountClickClose={discountClickClose} 
                    currentBillboards={currentBillboards} 
                    geoMarks={projectInfo.geoMarks} />
            }
            {scoreOpen && 
                <ScoreModal 
                    scoreOpen={scoreOpen}
                    handleScoreClose={handleScoreClose} />
            }
        </>
    )
}

const mapStateToProps = (state: RootState) => {
    let currentCompanyAccountId = selectors.sip.GetCallerAccountID(state.sip);

    if (currentCompanyAccountId <= 0){
        currentCompanyAccountId = selectors.todo.getCurrentCompanyAccountId(state.todo)
    }

    return {
        billboardsShortInfo: state.billboards.billboardsShortInfo,
        translateFunc: getTranslate(state.localize),
        projectID: selectors.todo.getProjectID(state.todo),
        companies: state.customers.companies,
        customers: state.customers.customers,
        agencyInfo: state.event.agencyInfo,
        projects: state.todo.projects,
        openSelectedBillboards: state.todo.openSelectedBillboards,
        commercialOffer: state.todo.commercialOffer,
        eventName: state.event.eventName,
        currentCompany: state.customers.currentCompany,
        discountOpen: state.todo.discountOpen,
        draggable: state.app.draggable,
        defaultPosition: state.app.defaultPosition,
        currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
        currentBrand: state.billboards.currentBrandInfo,
        brands: state.billboards.brandsInfo,
        clientItem: state.customers.clientItem,
        currentAgency: state.event.currentAgency,
        selectedFocus: state.event.selectedFocus,
        rollUpCards: state.event.rollUpCards,
        successSnackbar: state.billboards.successSnackbar,
        projectStatuses: state.todo.projectStatuses,
        loadingStatus: state.event.loading,
        billboardFilters: state.app.billboardFilters,
        successMessage: state.event.successMessage
}};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => 
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    getBillboardTariffsAction: (geoMarkIds: number[]) => 
        dispatch(actions.billboards.getBillboardTariffsAction(geoMarkIds)),
    commercialOfferAction: (offerId: number, projectId: number) => 
        dispatch(actions.todo.commercialOfferAction(offerId, projectId)),
    totalDiscountTextAction: (totalDiscountText: string) => 
        dispatch(actions.todo.totalDiscountTextAction(totalDiscountText)),
    discountOpenAction: (discountOpen: boolean) => 
        dispatch(actions.todo.discountOpenAction(discountOpen)),
    defaultPositionAction: (defaultPosition: DefaultPosition) => 
        dispatch(actions.app.defaultPositionAction(defaultPosition)),
    currentGeoMarkStatusAction: (currentGeoMarkStatus: ProjectStatuses) => 
        dispatch(actions.todo.currentGeoMarkStatusAction(currentGeoMarkStatus)),
    setActiveProjectActionCreator: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    sendSystemEventAction: (systemEvent: string) => 
        dispatch(actions.todo.sendSystemEventAction(systemEvent)),
    currentBrandAction: (currentBrand: EmployeeBrandsInfo | undefined) => 
        dispatch(actions.billboards.currentBrandInfoAction(currentBrand)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    datesAction: (dates: DatesRange) => dispatch(actions.todo.datesAction(dates)),
    clientItemAction: (clientItem: ClientItem | null) => 
        dispatch(actions.customers.clientItemAction(clientItem)),
    currentBrandInfoAction: (currentBrand: EmployeeBrandsInfo | null) => 
        dispatch(actions.billboards.currentBrandInfoAction(currentBrand)),
    openAdditionalCostAction: (openAdditionalCost: boolean) => 
        dispatch(actions.todo.openAdditionalCostAction(openAdditionalCost)),
    loadingAction: (loading: boolean) => dispatch(actions.event.loadingAction(loading)),
    billboardsShortInfoAction: (billboardsShortInfo: BillboardsShortInfo[]) => 
        dispatch(actions.billboards.billboardsShortInfoAction(billboardsShortInfo)),
    gidAction: (gid: string | undefined) => 
        dispatch(actions.event.gidAction(gid)),
    setAllProjects: (projects: Project[]) => 
        dispatch(actions.todo.setAllProjects(projects)),
    customerCompanyLoaded: (filter: GetCustomersFilter) => 
        dispatch(actions.customers.customerCompanyLoaded(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelectedCard);