import React, { useEffect, useState } from 'react';
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Translate } from 'react-localize-redux';
import { GeoMarkTariffs } from '../../store/billboards/types';
import TariffTableCell from './TariffTableCell';
import { AgencyInfo } from '../../store/event/types';

type Props = {
    geoMarkTariffs: GeoMarkTariffs[];
    geoMarkId: number;
    addChecked: boolean;
    addCheckedCose: () => void;
    currentAgency: AgencyInfo | null;
}

const TariffTable:React.FC<Props> = (props) => {
    const { geoMarkTariffs, geoMarkId, addChecked, addCheckedCose, currentAgency } = props;

    const [agencyTariffs, setAgencyTariffs] = useState<GeoMarkTariffs[]>([]);

    useEffect(() => {
        setAgencyTariffs(geoMarkTariffs);
        if(currentAgency === null) {
            setAgencyTariffs([]);
        }
    }, [geoMarkTariffs, currentAgency]);

    const agencyTariffColor = (tariffItem: GeoMarkTariffs) => {
        const tariffItemFilterA = agencyTariffs.some(item => item.geoMarkId === tariffItem.geoMarkId && item.side === "A");
        const tariffItemFilterB = agencyTariffs.some(item => item.geoMarkId === tariffItem.geoMarkId && item.side === "B");
        if(tariffItemFilterA && tariffItemFilterB) {
            return "rgba(0, 0, 0, 0.04)"
        } else {
            return "#fff"
        }
    }

    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell style={{minWidth: 180}}><Translate id='BillboardListAddress'/></TableCell>
                    <TableCell style={{width: 110}} align="center"><Translate id='BillboardDetailsConstructionType'/></TableCell>
                    <TableCell align="center"><Translate id='BillboardDetailsFormat' /></TableCell>
                    <TableCell align="center">Сторона</TableCell>
                    <TableCell align="center">Январь</TableCell>
                    <TableCell align="center">Февраль</TableCell>
                    <TableCell align="center">Март</TableCell>
                    <TableCell align="center">Апрель</TableCell>
                    <TableCell align="center">Май</TableCell>
                    <TableCell align="center">Июнь</TableCell>
                    <TableCell align="center">Июль</TableCell>
                    <TableCell align="center">Август</TableCell>
                    <TableCell align="center">Сентябрь</TableCell>
                    <TableCell align="center">Октябрь</TableCell>
                    <TableCell align="center">Ноябрь</TableCell>
                    <TableCell align="center">Декабрь</TableCell>
                    <TableCell align="center">Действия</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {agencyTariffs.map(tariffItem => (
                    <TariffTableCell 
                        key={tariffItem.geoMarkId + Math.random()} 
                        tariffItem={tariffItem} 
                        geoMarkId={geoMarkId}
                        addChecked={addChecked}
                        addCheckedCose={addCheckedCose} 
                        agencyTariffColor={agencyTariffColor} />
                )).reverse()}
            </TableBody>
        </Table>
    );
}

export default TariffTable;