import { 
    GeoMark, 
    PropertyValueSet, 
    LatLong, 
    Translation, 
    GeoMarkTimeStatus, 
    AddressInfo, 
    EmployeeBrandsInfo, 
    RolesInfo, 
    AdditionalBillboardInfo, 
    GetGeoMarkTariffs, 
    BillboardTariffs
} from 'sedi-webserverproxy';
import { IBillboardProperties, PropertiesHelper } from "../../utils/PropertiesHelper";
import { IGetAddress } from '../../api/AddressCacheAPI';
import { BillboardsShortInfo, EventGeoMarks, FullMonthStatistic } from '../../RemoteCommands/type';
import { IPropertySet } from '../../RemoteCommands/SystemEvent';


export enum DefaultProperties {
    NumberOfSeatsOnSide1 = 'NumberOfSeatsOnSide1',
    NumberOfSeatsOnSide2 = 'NumberOfSeatsOnSide2',
    Format = 'Format',
    Way1 = 'Way1',
    Way2 = 'Way2',
    Type = 'Type',
    ConstructionType = 'ConstructionType',
    Light = 'Light',
    WayA_OddNumbers = 'WayA_OddNumbers',
    // WayB_OddNumbers = 'WayB_OddNumbers',
    WorkingTime = 'WorkingTime',
    ShowingsPerDay = 'ShowingsPerDay'
}

export class Billboard {
    constructor(id: number, gid: string, sideACount: number, sideBCount: number, isAOdd: boolean, format: string,
        type: string,
        light: boolean,
        constructionType: string,
        way1: string,
        way2: string,
        properties: PropertyValueSet,
        name: string,
        location: LatLong,
        description: string,
        propertyValueSetId:number,
        addressInfo: AddressInfo,
        workingTime: string,
        showingsPerDay: string,
        typeB: string,
        constructionTypeB: string) {
        this.id = id;
        this.GID = gid;
        this.sideACount = sideACount;
        this.sideBCount = sideBCount;
        this.isAOdd = isAOdd;
        this.format = format;
        this.type = type;
        this.light = light;
        this.constructionType = constructionType;
        this.way1 = way1;
        this.way2 = way2;
        this.properties = properties;
        this.name = name;
        this.location = location;
        this.description = description;
        this.propertyValueSetId = propertyValueSetId;
        this.addressInfo = addressInfo;
        this.workingTime = workingTime;
        this.showingsPerDay = showingsPerDay;
        this.typeB = typeB;
        this.constructionTypeB = constructionTypeB;
    }

    id: number;
    GID: string;
    sideACount: number;
    sideBCount: number;
    isAOdd: boolean;
    format: string;
    type: string;
    light: boolean;
    constructionType: string;
    way1: string;
    way2: string;
    properties: PropertyValueSet;
    name: string;
    location: LatLong;
    description: string;
    propertyValueSetId: number;
    addressInfo: AddressInfo;
    workingTime: string;
    showingsPerDay: string;
    typeB: string;
    constructionTypeB: string;

    getSideA():string[] {
        return this.getSide("A", this.sideACount, this.isAOdd);
    }
    getSideB():string[] {
        return this.getSide("B", this.sideBCount, false);
    }
    getAllSides(){
        return this.getSideA().concat(this.getSideB());
    }
    private getSide(letter:string, count:number, isOdd:boolean): string[] {
        let r = new Array<string>(count);
        let c: number = 1;
        for (var i = 0; i < count; i++) {
            r[i] = `${letter}${c}`;
            c = c + (isOdd ? 2 : 1);
        }
        return r;
    }
    getWayBySide(side:string): string {
        if(this.isAOdd) {
            return side[0] === 'A' ? this.way1 : this.way2;
        } else {
            const sideNumber = parseInt(side.replace(/[^\d]/g, ''));
            console.log("sideNumber:", sideNumber)
            if(sideNumber % 2 === 0) {
                return this.way2
            } else {
                return this.way1
            }
        }
    }

    static createBillBoard(geoMark: GeoMark, allProperties: PropertyValueSet[]): Billboard|null {
        const properties = allProperties.find(pr => pr.propertyValueSetId === geoMark.propertyValueSetId);
        // if (!PropertiesHelper.arePropertiesValid(properties))
        //     return null;
        if(!properties) {
            return null;
        }
        const propertyValue = properties.propertyValues.find(item => item.property.propertyNameLocal === "PropertyValueSetIdSideB");

        let propertiesB = properties;
        if(propertyValue) {
            const propertyValueSetIdB =  parseInt(propertyValue.value);
            const findPropertiesB = allProperties.find(pr => pr.propertyValueSetId === propertyValueSetIdB);
            if(findPropertiesB) {
                propertiesB = findPropertiesB;
            }
        }

        return new Billboard(
            geoMark.id,
            geoMark.objectId,
            parseInt(PropertiesHelper.getPropertyValue(properties, DefaultProperties.NumberOfSeatsOnSide1, '0')),
            parseInt(PropertiesHelper.getPropertyValue(properties, DefaultProperties.NumberOfSeatsOnSide2, '0')),
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.WayA_OddNumbers, '') === 'true',
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.Format, '')!,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.Type, '')!,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.Light, '') === 'true',
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.ConstructionType, '')!,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.Way1, '')!,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.Way2, '')!,
            properties, geoMark.name, geoMark.points[0], geoMark.description || '',
            geoMark.propertyValueSetId, geoMark.addressInfo,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.WorkingTime, '')!,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.ShowingsPerDay, '')!,
            PropertiesHelper.getPropertyValue(propertiesB, DefaultProperties.Type, '')!,
            PropertiesHelper.getPropertyValue(propertiesB, DefaultProperties.ConstructionType, '')!
        );
    }
}

export interface BillboardState {
    geomarks: GeoMark[];
    properties: PropertyValueSet[];
    billboards: Billboard[];
    filterBillboards: Billboard[];
    billboardTariffs: GetGeoMarkTariffs[];
    billboardTariffsOwner: GetGeoMarkTariffs[];
    billboard: Billboard | undefined;
    isInitialized: boolean;
    isInitializing: boolean;
    translationsLoading: boolean;
    translationsLoaded: boolean;
    propertiesLoading: boolean;
    geomarkSavingStart: boolean;
    geomarkSaving: boolean;
    translations: Translation[];
    // TODO перенести в customers
    roles: RolesInfo[];
    billboadStatusesIsLoading: boolean;
    billboadStatusesError: string | undefined;
    billboadStatuses: GeoMarkTimeStatus[];
    // projectBillboardStatuses: GeoMarkTimeStatus[];
    saveStatusIsLoading: boolean;
    saveStatusError: string | undefined;
    selectedAreaName: string;
    selectedCityName: string;
    photoVersion: number,
    uploadedPhotos: AdditionalBillboardInfo | undefined,
    successSnackbar: boolean,
    openPrice: boolean,
    selectedSide: string,
    getAddress: IGetAddress | null,
    geoMarkTariffs: IGeoMarkTariffs | null,
    openTariffCard: boolean,
    tariffSuccess: boolean,
    openBrandsCard: boolean,
    brandsInfo: EmployeeBrandsInfo[],
    currentBrandInfo: EmployeeBrandsInfo | null,
    nextBillboardStatuses: GeoMarkTimeStatus[];
    projectBillboardIds: number[];
    openGeoMarkTariff: boolean;
    billboardsShortInfo: BillboardsShortInfo[];
    forbiddenBillboards: BillboardsShortInfo[];
    billboardShort: BillboardsShortInfo | undefined,
    billboardProperties: IBillboardProperties | undefined,
    propertySet: IPropertySet | null,
    eventGeoMarks: EventGeoMarks[],
    fullMonthStatistic: FullMonthStatistic[],
    openAddBillboard: boolean
}

export interface IBrandsInfo {
    brandId: number,
    brandName: string,
    description: string | null,
    geoMarksCount: number
}
export interface BillboardPrice {
  installation : number;
  extraInstallation : number;
  print : number;
  price : number;
}

export interface IGeoMarkTariffs {
    geoMarkId: number,
    name: string,
    constructionType: string,
    format: string,
    side: string,
    january: number,
    february: number;
    march: number;
    april: number;
    may: number;
    june: number;
    july: number;
    august: number;
    september: number;
    october: number;
    november: number;
    december: number;
}

export class GeoMarkTariffs implements IGeoMarkTariffs {
    geoMarkId: number;
    name: string;
    constructionType: string;
    format: string;
    side: string;
    january: number;
    february: number;
    march: number;
    april: number;
    may: number;
    june: number;
    july: number;
    august: number;
    september: number;
    october: number;
    november: number;
    december: number;


    constructor(source?: IGeoMarkTariffs) {
        this.geoMarkId = source ? source.geoMarkId : 0;
        this.name = source ? source.name : '';
        this.constructionType = source ? source.constructionType : '';
        this.format = source ? source.format : '';
        this.side = source ? source.side : '';
        this.january = source ? source.january : 0;
        this.february = source ? source.february : 0;
        this.march = source ? source.march : 0;
        this.april = source ? source.april : 0;
        this.may = source ? source.may : 0;
        this.june = source ? source.june : 0;
        this.july = source ? source.july : 0;
        this.august = source ? source.august : 0;
        this.september = source ? source.september : 0;
        this.october = source ? source.october : 0;
        this.november = source ? source.november : 0;
        this.december = source ? source.december : 0;
    }
}

export class GetGeoMarkTariff implements GetGeoMarkTariffs {
    geoMarkTariffId: number;
    geoMarkId: number;
    billboardTariffs: BillboardTariffs[];
    changeTime: Date;
    ownerAccountId: number;
    brandId: number;

    constructor(source?: GetGeoMarkTariffs) {
        this.geoMarkTariffId = source ? source.geoMarkTariffId : 0;
        this.geoMarkId = source ? source.geoMarkId : 0;
        this.billboardTariffs = source ? source.billboardTariffs : [];
        this.changeTime = source ? source.changeTime : new Date();
        this.ownerAccountId = source ? source.ownerAccountId : 0;
        this.brandId = source ? source.brandId : 0;
    }   
}

export class BillboardTariff implements BillboardTariffs {
    side: string;
    totalCost: number;
    month: number;
    year: number;
    monthTo?: number;
    yearTo?: number;
    boardId: string | null;

    constructor(side?: string, totalCost?: number, month?: number, year?: number, monthTo?: number, yearTo?:number, boardId?: string) {
        this.side = side ? side : '';
        this.totalCost = totalCost ? totalCost : 0;
        this.month = month ? month : 0;
        this.year = year ? year : 0;
        this.monthTo = monthTo ? monthTo : undefined;
        this.yearTo = yearTo ? yearTo : undefined;
        this.boardId = boardId ? boardId : null;
    }
}