import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState,  actions } from '../store';
import { withRouter, RouteComponentProps, Switch } from 'react-router';
import { Route, Redirect } from 'react-router';
import Map from "../components/map/Map";
import BillboardList from "../components/BillboardList";
import Menu from '../components/Menu';
import LoginPage from './LoginPage';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { withLocalize, LocalizeContextProps } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import SipPhone from '../components/SipPhone';
import 'react-toastify/dist/ReactToastify.css';
import { GetCustomersFilter, GetProjectsFilter } from 'sedi-webserverproxy';
import CustomersList from '../components/CustomersList/CustomersList';
import Portal from '../components/portal';
import Settings from '../components/Settings/Settings';
import { localStorageGetItem } from '../utils/storage';
import WidgetPanel from '../components/WidgetPanel/WidgetPanel';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { Paper, Tooltip } from '@material-ui/core';
import PortalToast from '../components/portal/components/PortalToast';
import { GetEvents } from '../api/EventAPI';
import { EventParameters, EventStorsge } from '../utils/eventHelpers';
import { getWebServerProxy } from '../WebServerProxy';
import { errorCallback, IPropertySet, SystemEvent } from '../RemoteCommands/SystemEvent';
import { Coworkers, ProjectStatuses, ProjectTypes } from '../store/todo/types';
import { MessageInfo } from '../store/event/types';
import MessageShackbar from '../components/bricks/MessageShackbar';
import { BillboardsShortInfo, FullMonthStatistic, GeoObjectStatus } from '../RemoteCommands/type';
import moment from 'moment';
import { ExceededTimeProject, uniqueExceededTimeProject } from '../utils/onlyUnique';
import ExceededProjectConfirm from '../components/portal/components/ExceededProjectConfirm';
import { BillStatuses } from '../store/bills/types';

type IState = {
    isToggle: boolean;
    phonePopover: HTMLButtonElement | null;
    billboardLoading: boolean;
}

type Props = RouteComponentProps<{}> 
    & ReturnType<typeof mapDispatchToProps>
    & ReturnType<typeof mapStateToProps> 
    & LocalizeContextProps;

// let projects: ExceededTimeProject[] = [];
class AppStack extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.phoneClick = this.phoneClick.bind(this);
        this.state = {
            isToggle: false,
            phonePopover: null,
            billboardLoading: false,
        }
        let lang = localStorageGetItem("lang");
        if (lang === null)
            lang = "ru";
        this.props.initialize(({
            languages: [
                { name: "En", code: "en" },
                { name: "Ру", code: "ru" }
            ],
            translation: [],
            options: {
                renderToStaticMarkup,
                renderInnerHtml: true,
                defaultLanguage: lang
            }
        }) as any);
    }

    private eventKey = "";

    phoneClick() {
        this.setState(state => ({
          isToggle: !state.isToggle
        }));
    }

    addTranslations(lang:string) {
        let texts = this.props.translations.filter(tr => tr.language === lang);
        let srt: { [id: string]: string; } = {};
        for (let i = 0; i < texts.length; i++)
            srt[texts[i].key] = texts[i].text;
        this.props.addTranslationForLanguage(srt, lang);
    }

    componentDidMount() {
        const {
            projectStatusesAction,
            projectTypesAction,
            coworkersAction,
            billboardsShortInfoAction,
            regionsAction, 
            citiesAction,
            dates,
            fullMonthStatisticAction,
            propertySetAction,
            exceededTimeProjectsAction,
            exceededTimeProjects,
            billStatusesAction
        } = this.props;

        if (!this.props.isAuthorized && !this.props.isAuthorizing && this.props.licenceKey) {
            this.props.loginWithKey(this.props.licenceKey);
        }

        let projects = exceededTimeProjects.concat();

        SystemEvent.SubscribeEventProjectEditingTimeExceeded(
            "AppStack", 
            (answer) => {
                projects = uniqueExceededTimeProject(projects, answer.eventParameters)
                exceededTimeProjectsAction(projects);
            }, 
            (error) => {
                errorCallback(error);
            }
        );

        SystemEvent.SubscribeEventGetBillboardsShortInfo(
            "AppStack", 
            (answer) => {
                this.setState({
                    billboardLoading: false
                })
                if(answer.eventKey === this.eventKey) {
                    billboardsShortInfoAction(answer.billboards);
                }

            }, (error) => {
                this.setState({
                    billboardLoading: false
                });
                errorCallback(error);
            }
        );

        SystemEvent.SubscribeEventGetPropertySet(
            "EventGetPropertySet", 
            (answer) => {
                propertySetAction(answer.propertySet);
            }, 
            (error) => {
                errorCallback(error)
            }
        );
        const lang = localStorageGetItem("lang") || "ru"
        SystemEvent.EventGetPropertySet(lang);

        SystemEvent.SubscribeEventGetProjectFilters(
            "AppStack", 
            (answer) => {
                projectStatusesAction(answer.projectFilters.projectStatuses)
                projectTypesAction(answer.projectFilters.projectTypes)
                coworkersAction(answer.projectFilters.coworkers)
                billStatusesAction(answer.projectFilters.billStatuses);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventGetFullMonthStatistic(
            "AppStack",
            (answer) => {
                fullMonthStatisticAction(answer.fullMonthStatistic);
            }, (error) => {
                errorCallback(error);
            }
        )
        if(dates && dates.DateFrom) {
            const year = moment(dates.DateFrom).format('YYYY');
            const month = moment(dates.DateFrom).format('M');
            SystemEvent.EventGetFullMonthStatistic(+year, +month);
            // updateStatuses(billboardIDS, dates.DateFrom, dates.DateTo, true);
        }

        SystemEvent.SubscribeEventGetGeoMarkFilters(
            "AppStack", 
            (answer) => {
                regionsAction(answer.geoMarkFilters.regions);
                citiesAction(answer.geoMarkFilters.cities);
            }, (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.EventGetGeoMarkFilters();
    }

    getEvents = async () => {
        const parameters: GetEvents = {
            systemEventName: "",
            eventType: null,
        }
        const response = await getWebServerProxy().Event.GetEvents(parameters);
        const eventPatterns: { [id: string]: EventParameters; } = {};
        if(response.Result)
        for (let eventPattern of  response.Result) {
            eventPatterns[eventPattern.systemEventName] = 
            new EventParameters(eventPattern.eventParameters, eventPattern.eventResponseParameters);
        }
        EventStorsge.EventParametersPatterns = eventPatterns;
    }

    componentDidUpdate(prevProps: Props) {
        const { customerCompanyLoaded, billboardFilters, selectedAreaName, selectedCityName, dontSaleFilter } = this.props;
        if (!this.props.translationsLoaded && !this.props.translationsLoading) {
            this.props.loadTranslations();
        }
        if (this.props.translationsLoaded && !prevProps.translationsLoaded) {
            this.addTranslations("ru");
            this.addTranslations("en");
        }
        if (!this.props.isSipInitialized && !this.props.isSipInitializing && this.props.sipLogin && this.props.sipPassword && !this.props.lastSipError && this.props.userInfo) {
            let sipLogin = this.props.sipLogin!;
            let sipPassword = this.props.sipPassword!;
            this.props.initializeSip(sipLogin, sipPassword, this.props.userInfo.username);
        }
        if (this.props.isAuthorized && !this.props.isInitialized && !this.props.isInitializing && this.props.licenceKey) {
            this.props.initializeData(this.props.licenceKey);
            this.setState({
                billboardLoading: true
            });
            this.eventKey = SystemEvent.EventGetBillboardsShortInfo(billboardFilters);
            this.props.getAllProjectAction({});
            this.getEvents();
            customerCompanyLoaded({isLegal: true} as GetCustomersFilter);
            customerCompanyLoaded({isLegal: false} as GetCustomersFilter);
        }
        if (!this.props.isAuthorized && !this.props.isAuthorizing && this.props.licenceKey) {
            this.props.loginWithKey(this.props.licenceKey);
        }
        if(selectedAreaName !== prevProps.selectedAreaName || selectedCityName !== prevProps.selectedCityName) {
            if(billboardFilters) {
                this.eventKey = SystemEvent.EventGetBillboardsShortInfo({
                    ...billboardFilters,
                    region: selectedAreaName ? (selectedAreaName === "Все" ? undefined : selectedAreaName) : undefined,
                    city: selectedCityName ? selectedCityName : undefined,
                    status: dontSaleFilter ? GeoObjectStatus.outOfService : GeoObjectStatus.used
                });
            } else {
                this.eventKey = SystemEvent.EventGetBillboardsShortInfo({
                    propertyValueIds: [],
                    region: selectedAreaName ? (selectedAreaName === "Все" ? undefined : selectedAreaName) : undefined,
                    city: selectedCityName ? selectedCityName : undefined,
                    status: dontSaleFilter ? GeoObjectStatus.outOfService : GeoObjectStatus.used
                });
            }
        }
        // if(selectedCityName !== prevProps.selectedCityName) {
        //     if(billboardFilters) {
        //         this.eventKey = SystemEvent.EventGetBillboardsShortInfo({
        //             ...billboardFilters,
        //             city: selectedCityName ? selectedCityName : undefined
        //         });
        //     } else {
        //         this.eventKey = SystemEvent.EventGetBillboardsShortInfo({
        //             propertyValueIds: [],
        //             city: selectedCityName ? selectedCityName : undefined
        //         });
        //     }
        // }
        //if (this.props.hasActiveCall && this.props.isIncoming) {
        //    let incomingToastOptions = {
        //        position: "bottom-right",
        //        autoClose: false,
        //        hideProgressBar: false,
        //        closeOnClick: false,
        //        pauseOnHover: true,
        //        draggable: true,
        //        closeButton: false,
        //        className: 'black-background'
        //    };
        //}
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            phonePopover: event.currentTarget
        })
    };

    handleClose() {
        this.setState({
            phonePopover: null
        })
    };
    

    render() {
        const { location, messageInfo, messageInfoAction, exceededTimeProject } = this.props;
        const { billboardLoading } = this.state;

        let mapPageClass = '';
        if (location.pathname == "/map"){
            mapPageClass = 'map-page';
        }

        if (this.props.isInitializing || this.props.isAuthorizing || !this.props.translationsLoaded || billboardLoading) {
            return (<div className="main-preloader"><div className="pulse"></div></div>);
        }

        if (!this.props.isAuthorized)
        {
            return (
                <div id="app" className="wrapper">
                    <LoginPage/>
                </div>
            );
        }
        else
            return (
                <>
                    <div id="app" className="wrapper">
                        <Menu />
                        <div id="content" className={mapPageClass}>
                            <Route exact path="/" render={() => <Redirect to="/map" />}/>
                            <Switch>
                                <Route path="/:tab(map)" component={Map} />
                                <Route path="/:tab(billboardList)" component={BillboardList}/>
                                <Route path="/:tab(customers)" render={renderCustomersList}/>
                                <Route path="/:tab(settings)" component={Settings}/>
                            </Switch>
                            {this.props.sipDisconnectedError ?
                                <Tooltip title="Звонок не доступен" placement="top">
                                    <button 
                                        className={`call-phone is-animating ${this.state.phonePopover !== null ? 'call-close' : ''}`} 
                                        style={this.props.sipDisconnectedError ? {backgroundColor: "red"} : {}}
                                    />
                                </Tooltip>
                            :
                                <button 
                                    className={`call-phone is-animating ${this.state.phonePopover !== null ? 'call-close' : ''}`} 
                                    onClick={this.handleClick.bind(this)}
                                />
                            }
                            {/* <div className={`call-popup ${this.state.isToggle ? 'show' : 'hide'}`}>
                                    <div className="call-popup__content">
                                        <Icon className="call-popup__close" onClick={this.phoneClick}>close</Icon>
                                        <SipPhone ></SipPhone>
                                    </div>
                                <div className="call-popup__backdrop" onClick={this.phoneClick}></div>
                            </div> */}
                            
                            <Popover
                                open={Boolean(this.state.phonePopover)}
                                anchorEl={this.state.phonePopover}
                                onClose={this.handleClose.bind(this)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Paper className="p-3" style={{maxWidth: '340px'}}>
                                    <IconButton aria-label="delete" style={{ position: 'absolute', top: '5px', right: '5px' }}
                                        onClick={this.handleClose.bind(this)}>
                                        <CloseIcon />
                                    </IconButton>
                                    <SipPhone ></SipPhone>
                                </Paper>
                            </Popover>
                            <Portal />
                        </div>                            
                        {/* <SipPhone /> */}
                        {messageInfo &&
                            <MessageShackbar 
                                message={messageInfo.text}
                                variant={messageInfo.variant}
                                messageOpen={Boolean(messageInfo)} 
                                vertical="top"
                                horizontal="center" 
                                messageShackbarClose={() => messageInfoAction(null)} />
                        }
                        {exceededTimeProject && 
                            <ExceededProjectConfirm 
                                exceededTimeProject={exceededTimeProject} />
                        }
                    </div>
                    {/* чтобы компоненты внутри toast имели доступ к store и Router */}
                    <div className="widget-panel-task" style={{position: 'relative'}}>
                        <WidgetPanel positionPanel="right" settingsKey="phoneToggle">
                            {/* <ToastContainer /> */}
                            <PortalToast />
                            {/* <div style={{width: '325px'}}></div> */}
                        </WidgetPanel>
                    </div>
                </>
            );
    }
}


const renderCustomersList = () => <CustomersList componentID="" />;


const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.user.isAuthorized,
    isAuthorizing: state.user.isAuthorizing,
    lastError: state.user.lastError,
    licenceKey: state.user.licenceKey,
    sipLogin: state.user.sipLogin,
    sipPassword: state.user.sipPassword,
    userInfo: state.user.userInfo,

    isInitialized: state.billboards.isInitialized,
    isInitializing: state.billboards.isInitializing,
    translationsLoading: state.billboards.translationsLoading,
    translationsLoaded: state.billboards.translationsLoaded,
    translations: state.billboards.translations,

    isSipInitialized: state.sip.isInitialized,
    isSipInitializing: state.sip.isInitializing,
    phoneNumber: state.sip.phoneNumber,
    hasActiveCall: state.sip.hasActiveCall,
    isEstablished: state.sip.isEstablished,
    isIncoming: state.sip.isIncoming,
    isMuted: state.sip.isMuted,
    lastSipError: state.sip.lastError,
    eventPatterns: state.event.eventPatterns,
    sipDisconnectedError: state.sip.sipDisconnectedError,
    messageInfo: state.event.messageInfo,
    billboardFilters: state.app.billboardFilters,
    dates: state.todo.dates,
    selectedAreaName: state.billboards.selectedAreaName,
    selectedCityName: state.billboards.selectedCityName,
    dontSaleFilter: state.app.dontSaleFilter,
    exceededTimeProjects: state.todo.exceededTimeProjects,
    exceededTimeProject: state.todo.exceededTimeProject
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    initializeData: (key:string) => dispatch(actions.billboards.initializeActionCreator(key)),
    loginWithKey: (key: string) => dispatch(actions.user.authorizeWithKeyActionCreator(key)),
    loadTranslations: () => dispatch(actions.billboards.fetchTranslationsActionCreator()),
    initializeSip: (login:string, pass:string, username:string) => dispatch(actions.sip.initializeSipActionCreator(login, pass, username)),
    customerCompanyLoaded: (filter: GetCustomersFilter) => dispatch(actions.customers.customerCompanyLoaded(filter)),
    eventPatternsAction: (eventPatterns: { [id: string]: EventParameters }) => dispatch(actions.event.eventPatternsAction(eventPatterns)),
    getAllProjectAction: (filter: GetProjectsFilter) => dispatch(actions.todo.getAllProjectAction(filter)),
    projectStatusesAction: (projectStatuses: ProjectStatuses[]) => dispatch(actions.todo.projectStatusesAction(projectStatuses)),
    projectTypesAction: (projectTypes: ProjectTypes[]) => dispatch(actions.todo.projectTypesAction(projectTypes)),
    coworkersAction: (coworkers: Coworkers[]) => dispatch(actions.todo.coworkersAction(coworkers)),
    messageInfoAction: (messageInfo: MessageInfo | null) => 
        dispatch(actions.event.messageInfoAction(messageInfo)),
    billboardsShortInfoAction: (billboardsShortInfo: BillboardsShortInfo[]) => 
        dispatch(actions.billboards.billboardsShortInfoAction(billboardsShortInfo)),
    regionsAction: (regions: string[]) => 
        dispatch(actions.event.regionsAction(regions)),
    citiesAction: (cities: string[]) => 
        dispatch(actions.event.citiesAction(cities)),
    fullMonthStatisticAction: (fullMonthStatistic: FullMonthStatistic[]) => 
        dispatch(actions.billboards.fullMonthStatisticAction(fullMonthStatistic)),
    propertySetAction: (propertySet: IPropertySet | null) => 
        dispatch(actions.billboards.propertySetAction(propertySet)),
    exceededTimeProjectsAction: (exceededTimeProjects: ExceededTimeProject[]) => 
        dispatch(actions.todo.exceededTimeProjectsAction(exceededTimeProjects)),
    billStatusesAction: (billStatuses: BillStatuses[]) => 
        dispatch(actions.bills.billStatusesAction(billStatuses)),
});

export default withLocalize(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AppStack)));

//export default withLocalize(AppStack);
