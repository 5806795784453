import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import './bricks.scss';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { BillStatuses } from '../../store/bills/types';
import { QiwiBillStatus } from '../../RemoteCommands/type';


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    onChange: (billStatuses: BillStatuses[]) => void;
}

const FilterByBillStatuses: React.FC<Props> = (props) => {
    const { 
        billStatuses,
        onChange,
    } = props;

    const handleChange = (event: React.ChangeEvent<{}>, changeValue: BillStatuses[]) => {
        onChange(changeValue);
    }

    const renderOptionSelect = (option: BillStatuses) => {
        return (
            <span className="projectItemSelect">
                <span>{option.localization}</span>
            </span>
        );
    };

    // const getLocalizedBillStatus = (status: BillStatuses) => {
    //     let localizedStatus: string = status;
    //     if(billStatuses && billStatuses.length > 0) {
    //         let found = billStatuses.find(item => item.value === status);
    //         if(found)
    //         localizedStatus = found.localization;
    //     }
    //     return localizedStatus;
    // }

    const renderOptionLabel = (option: BillStatuses) => {     
        return option.localization //getLocalizedBillStatus(option);
    };

    return (
        <Autocomplete
            multiple
            options={billStatuses}
            disableCloseOnSelect
            getOptionLabel={(option: BillStatuses) => renderOptionLabel(option)}
            defaultValue={billStatuses.filter(item => item.value == QiwiBillStatus.Invoiced)}
            onChange={(event: React.ChangeEvent<{}>, changeValue: BillStatuses[]) => 
                handleChange(event, changeValue)}
            className="cancelDraggable"
            //renderOption={(option: BillStatuses) => renderOptionSelect(option)}
            filterOptions={(options: BillStatuses[], state: FilterOptionsState) => 
                fliterOptions(options, state)}
            
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    variant="outlined" 
                    label="Фильтр по статусам счетов"  
                    fullWidth 
                    size="small" 
                    className="multiInput" />
            )}
        />
    )
}

const fliterOptions = (options: BillStatuses[], state: FilterOptionsState) => {
    const optionsArray: BillStatuses[] = options.filter(option => option.localization.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 
        || option.localization.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
    return optionsArray;
}

const mapStateToProps = (state: RootState) => ({
    billStatuses: state.bills.billStatuses,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(FilterByBillStatuses);