import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState,  actions } from '../store';
import { Link, NavLink } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';
import "./Menu.scss"
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Action } from 'redux';
import { UserInfo } from "../store/user/types";
import { withLocalize, LocalizeContextProps, Translate } from "react-localize-redux";
import LanguageSelector from './LanguageSelector';
import Icon from '@material-ui/core/Icon';
import { Tooltip } from '@material-ui/core';
import { RollUpCard } from '../store/event/types';
import { openAddBillboardAction } from '../store/billboards/actions';
var packageJson = require('../../package.json');

export const PAGE_URL = {
    Map: '/map'
};

type StateProps = {
    userInfo?: UserInfo;
    showFullMenu: boolean;
    openTariffCard: boolean;
    openBrandsCard: boolean;
    openNextBillboard: boolean;
    rollUpCards: RollUpCard[];
    openEmployeesCard: boolean;
    openAdditionalTariff: boolean;
    openBillsCard: boolean;
}
type Props = RouteComponentProps<{}> & ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & StateProps & LocalizeContextProps;

type State = {
    loginToggle: boolean;
    financeToggle: boolean;
    constructionsToggle: boolean;
}

class Menu extends React.Component<Props, State> {

    state = {
        loginToggle: false,
        financeToggle: false,
        constructionsToggle: false
    }

    financeToggleClick() {
        this.setState(state => ({
            financeToggle: !state.financeToggle
        }));
    }

    constructionsToggleClick() {
        this.setState(state => ({
            constructionsToggle: !state.constructionsToggle
        }));
    }

    toggleMenu() {
        this.props.toggleMenu();
    }

    loginToggleClick() {
        this.setState(state => ({
            loginToggle: !state.loginToggle
        }));
    };

    openNextBillboardClick() {
        const { 
            openNextBillboardAction,
            rollUpCards,
            rollUpCardsAction
        } = this.props;
        openNextBillboardAction(true);
        const idx = rollUpCards.findIndex((item) => item.key === "NextBillboardList");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    };

    addBillboardOpenClick = () => {
        //openAddBillboardAction(true);
        const { 
            openAddBillboardAction,
            rollUpCards,
            rollUpCardsAction
        } = this.props;
        openAddBillboardAction(true);
        const idx = rollUpCards.findIndex((item) => item.key === "AddBillboard");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    };

    openEmployeesClick() {
        const { 
            openEmployeesCardAction,
            rollUpCards,
            rollUpCardsAction
        } = this.props;
        openEmployeesCardAction(true);
        const idx = rollUpCards.findIndex((item) => item.key === "Employees");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    dontSaleClick() {
        const { 
            dontSaleAction,
            rollUpCards,
            rollUpCardsAction
        } = this.props;
        dontSaleAction(true);
        const idx = rollUpCards.findIndex((item) => item.key === "DontSaleCard");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    renderlistItems(list: any[]) {
        return list.map((item) => (
            <li className="active" key={item.title}>
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                        <i className="fas fa-ad"></i> {item.title}</a>
                    <ul className="collapse list-unstyled" id="homeSubmenu">
                        {item.links
                            .map((p: { title: string | number | undefined; path: string; icon: any; }) => (
                                <li key={p.path}>
                                    <Link to={p.path}>
                                        <i className={"fas fa-" + p.icon}></i> {p.title}</Link>
                                </li>
                            ))}
                    </ul>
                </li>
        ));
    }

    render() {
        const { 
            userInfo, openTariffCardAction, openTariffCard, openBrandsCardAction, 
            openBrandsCard, openNextBillboard, openEmployeesCard, openAdditionalTariffAction, 
            openAdditionalTariff, dontSale, openBillsCardAction, openBillsCard, openAddBillboard, openPhotocontrolCard, openPhotocontrolCardAction
        } = this.props;
        const { financeToggle, constructionsToggle } = this.state;

        return (
            <nav id="sidebar" className={`sidebar ${!this.props.showFullMenu ? "sidebar_small" : "sidebar_large"}`}>
                <div className="sidebar__header" onClick={this.toggleMenu.bind(this)}>
                    <div className="sidebar__logo" id="sidebarCollapse">
                        <span className="sidebar__logo-desctop">BlackBoard</span>
                        <span className="sidebar__logo-mobile">BB</span>
                    </div>
                    <div className="sidebar__burger"><Icon>menu</Icon></div>
                </div>
                <div className="sidebar-lang">
                    <LanguageSelector/>
                </div>
                <ul className="menu-nav">
                    <Tooltip  
                        placement="right"
                        title={
                            !this.props.showFullMenu ? <Translate id='MenuBillboardMap'/> : ""
                        }>
                        <li className="menu-nav__item" key='Карта'>
                            <NavLink activeClassName='is-active' to={PAGE_URL.Map}>
                                <Icon>map</Icon><span className="text"><Translate id='MenuBillboardMap'/></span>
                            </NavLink>
                        </li>
                    </Tooltip>
                    {userInfo && userInfo.userRole === "AdminPMS" && 
                        <>
                            <Tooltip  
                                placement="right"
                                title={
                                    !this.props.showFullMenu ? <Translate id='MenuBillboardList'/> : ""
                                }>
                                <li className="menu-nav__item" key='Список'>
                                    <NavLink activeClassName='is-active' to='/billboardList'>
                                        <Icon>view_list</Icon><span className="text"><Translate id='MenuBillboardList'/></span>
                                    </NavLink>
                                </li>
                            </Tooltip>
                            <Tooltip  
                                placement="right"
                                title={
                                    !this.props.showFullMenu ? <Translate id='MenuCustomers'/> : ""
                                }>
                                <li className="menu-nav__item" key='Клиенты'>
                                    <NavLink activeClassName='is-active' to='/customers'>
                                        <Icon>people</Icon><span className="text"><Translate id='MenuCustomers'/></span>
                                    </NavLink>
                                </li>
                            </Tooltip>
                        </>
                    }
                    
                    {/* #region Финансы*/}
                    <li className="menu-nav__item" key='Финансы'>
                        <Tooltip  
                            placement="right"
                            title={
                                !this.props.showFullMenu ? "Финансы" : ""
                            }>
                            <span className="menu-nav__toggle" onClick={this.financeToggleClick.bind(this)}>
                                <Icon>monetization_on</Icon><span className="text">Финансы</span><Icon className="dropdown__icon">
                                    {financeToggle ? "arrow_drop_up" : "arrow_drop_down"}
                                </Icon>
                            </span>
                        </Tooltip>
                        <ul className={`dropdown ${financeToggle ? "active" : ""}`}>
                            <Tooltip  
                                placement="right"
                                title={
                                    !this.props.showFullMenu ? "Тарифы агентства" : ""
                                }>
                                <li className="dropdown__item">
                                    <NavLink 
                                        to='/map' 
                                        className={openTariffCard ? 'is-active' : ''} 
                                        onClick={() => openTariffCardAction(true)}>
                                        <Icon>price_change</Icon><span className="text">Тарифы агентства</span>
                                    </NavLink>
                                </li>
                            </Tooltip>
                            <Tooltip  
                                placement="right"
                                title={
                                    !this.props.showFullMenu ? "Доп стоимость" : ""
                                }>
                                <li className="dropdown__item">
                                    <NavLink 
                                        to='/map' 
                                        className={openAdditionalTariff ? 'is-active' : ''} 
                                        onClick={() => openAdditionalTariffAction(true)}>
                                        <Icon>payments</Icon><span className="text">Доп стоимость</span>
                                    </NavLink>
                                </li>
                            </Tooltip>
                            <Tooltip  
                                placement="right"
                                title={
                                    !this.props.showFullMenu ? "Счета" : ""
                                }>
                                <li className="dropdown__item">
                                    <NavLink 
                                        to='/map' 
                                        className={openBillsCard ? 'is-active' : ''} 
                                        onClick={() => openBillsCardAction(true)}>
                                        <Icon>account_balance</Icon><span className="text">Счета</span>
                                    </NavLink>
                                </li>
                            </Tooltip>
                        </ul>
                    </li>
                    {/* #endregion Финансы */}

                    {/* #region Конструкции */}
                    <li className="menu-nav__item" key="Конструкции">
                        <Tooltip  
                            placement="right"
                            title={
                                !this.props.showFullMenu ? "Конструкции" : ""
                            }>
                            <span className="menu-nav__toggle" onClick={this.constructionsToggleClick.bind(this)}>
                                <Icon>dashboard</Icon><span className="text">Конструкции</span><Icon className="dropdown__icon">
                                    {constructionsToggle ? "arrow_drop_up" : "arrow_drop_down"}
                                </Icon>
                            </span>
                        </Tooltip>
                        <ul className={`dropdown ${constructionsToggle ? "active" : ""}`}>
                            {/* <Tooltip  
                                    placement="right"
                                    title={
                                        !this.props.showFullMenu ? "Добавить новую конструкцию" : ""
                                    }>
                                    <li className="dropdown__item">
                                        <NavLink 
                                            to='/map' 
                                            className={openAddBillboard ? 'is-active' : ''} 
                                            onClick={this.addBillboardOpenClick.bind(this)}>
                                            <Icon>dashboard_customize</Icon><span className="text">Добавить новую конструкцию</span>
                                        </NavLink>
                                    </li>
                            </Tooltip> */}
                            <Tooltip  
                                placement="right"
                                title={
                                    !this.props.showFullMenu ? "Ближ. конструкции" : ""
                                }>
                                <li className="dropdown__item">
                                    <NavLink 
                                        to='/map' 
                                        className={openNextBillboard ? 'is-active' : ''} 
                                        onClick={this.openNextBillboardClick.bind(this)}>
                                        <Icon>location_on</Icon><span className="text">Ближ. конструкции</span>
                                    </NavLink>
                                </li>
                            </Tooltip>
                            {userInfo && userInfo.userRole === "SalesDirector" && 
                                <Tooltip  
                                    placement="right"
                                    title={
                                        !this.props.showFullMenu ? "Конструкции запрещеные к продаже" : ""
                                    }>
                                    <li className="dropdown__item">
                                        <NavLink 
                                            to='/map'
                                            className={dontSale ? 'is-active' : ''} 
                                            onClick={this.dontSaleClick.bind(this)}>
                                            <Icon>do_disturb</Icon><span className="text">Запрещеные к продаже</span>
                                        </NavLink>
                                    </li>
                                </Tooltip>
                            }
                        </ul>
                    </li>
                    {/* #endregion Конструкции */}

                    <Tooltip  
                        placement="right"
                        title={
                            !this.props.showFullMenu ? "Бренды" : ""
                        }>
                        <li className="menu-nav__item" key='Бренды'>
                            <NavLink to='/map' className={openBrandsCard ? 'is-active' : ''} onClick={() => openBrandsCardAction(true)}>
                                <Icon>library_add_check</Icon><span className="text">Бренды</span>
                            </NavLink>
                        </li>
                    </Tooltip>
                    
                    <Tooltip  
                        placement="right"
                        title={
                            !this.props.showFullMenu ? "Фотоконтроль" : ""
                        }>
                        <li className="menu-nav__item" key='Фотоконтроль'>
                            <NavLink to='/map' className={openPhotocontrolCard ? 'is-active' : ''} onClick={() => openPhotocontrolCardAction(true)}>
                                <Icon>photo_library</Icon><span className="text">Фотоконтроль</span>
                            </NavLink>
                        </li>
                    </Tooltip>

                    <Tooltip  
                        placement="right"
                        title={
                            !this.props.showFullMenu ? "Сотрудники" : ""
                        }>
                        <li className="menu-nav__item" key='Сотрудники'>
                            <NavLink 
                                to='/map'
                                className={openEmployeesCard ? 'is-active' : ''} 
                                onClick={this.openEmployeesClick.bind(this)}>
                                <Icon>people</Icon><span className="text">Сотрудники</span>
                            </NavLink>
                        </li>
                    </Tooltip>
                    
                    <Tooltip  
                        placement="right"
                        title={
                            !this.props.showFullMenu ? "Настройки" : ""
                        }>
                        <li className="menu-nav__item" key='Настройки'>
                            <NavLink activeClassName='is-active' to='/settings'>
                                <Icon>settings</Icon><span className="text">Настройки</span>
                            </NavLink>
                        </li>
                    </Tooltip>
                </ul>
                <ul className="profile-nav">
                    <Tooltip  
                        placement="right-start"
                        title={
                            !this.props.showFullMenu ? <Translate id='MenuProfile'/> : ""
                        }>
                        <li className="profile-nav__item" key='Профиль'>
                            <span className="login-menu" data-toggle="collapse" aria-expanded="false" onClick={this.loginToggleClick.bind(this)}>
                                <Icon>person</Icon><span className="text">{this.props.userInfo ? this.props.userInfo.username : <Translate id='MenuProfile'/>}</span>
                            </span>
                            <ul className={`profile-nav__drop ${this.state.loginToggle && 'active'}`}>
                                <li key='logout'>
                                    <a style={{ cursor: 'pointer' }} onClick={() => this.props.logout()}>
                                        <Icon>exit_to_app</Icon> 
                                        <span className="text"><Translate id='MenuLogout' /></span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </Tooltip>
                </ul>    
                <div className="sidebar-version">
                    v {packageJson.version}
                </div>            
            </nav>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    userInfo: state.user.userInfo,
    showFullMenu: state.app.showFullMenu,
    openTariffCard: state.billboards.openTariffCard,
    openBrandsCard: state.billboards.openBrandsCard,
    openNextBillboard: state.event.openNextBillboard,
    rollUpCards: state.event.rollUpCards,
    openEmployeesCard: state.event.openEmployeesCard,
    openAdditionalTariff: state.event.openAdditionalTariff,
    dontSale: state.event.dontSale,
    openBillsCard: state.event.openBillsCard,
    openAddBillboard: state.billboards.openAddBillboard,
    openPhotocontrolCard: state.event.openPhotocontrolCard
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    logout: () => dispatch(actions.user.logoutActionCreator()),
    togglePhone: () => dispatch(actions.app.changePhoneControlStateAction()),
    toggleMenu: () => dispatch(actions.app.changeMenuStateAction()),
    openTariffCardAction: (openTariffCard: boolean) => dispatch(actions.billboards.openTariffCardAction(openTariffCard)),
    openBrandsCardAction: (openBrandsCard: boolean) => dispatch(actions.billboards.openBrandsCardAction(openBrandsCard)),
    openNextBillboardAction: (openNextBillboard: boolean) => 
        dispatch(actions.event.openNextBillboardAction(openNextBillboard)),
    rollUpCardsAction: (rollUpCards: RollUpCard[]) => 
        dispatch(actions.event.rollUpCardsAction(rollUpCards)),
    openEmployeesCardAction: (openEmployeesCard: boolean) => 
        dispatch(actions.event.openEmployeesCardAction(openEmployeesCard)),
    openAdditionalTariffAction: (openAdditionalTariff: boolean) => 
        dispatch(actions.event.openAdditionalTariffAction(openAdditionalTariff)),
    dontSaleAction: (dontSale: boolean) => 
        dispatch(actions.event.dontSaleAction(dontSale)),
    openBillsCardAction: (openBillsCard: boolean) => 
        dispatch(actions.event.openBillsCardAction(openBillsCard)),
    openAddBillboardAction: (openAddBillboard: boolean) => 
        dispatch(actions.billboards.openAddBillboardAction(openAddBillboard)),
    openPhotocontrolCardAction: (openPhotocontrolCard: boolean) => 
        dispatch(actions.event.openPhotocontrolCardAction(openPhotocontrolCard))
});

export default withLocalize(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu)));
