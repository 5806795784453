import React from "react";
import { Billboard } from "../store/billboards/types";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
type Props = {
    billboard: Billboard,
    onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void,
}

class BillboardListItem extends React.Component<Props> {
    render() {
        const {
            billboard,
            onClick
        } = this.props;

        return (
            // <tr onClick={onClick} style={{cursor:'pointer'}}>
            //     <td>{billboard.name}</td>
            //     <td>{billboard.format}</td>
            //     <td>{billboard.wayA}</td>
            //     <td>{billboard.GID}</td>
            //     <td>{billboard.type}</td>
            // </tr>
            <TableRow onClick={onClick} style={{cursor:'pointer'}}>
                <TableCell component="th" scope="row">
                    {billboard.name}
                </TableCell>
                <TableCell align="center">
                    {billboard.format}
                </TableCell>
                <TableCell align="center">
                    {billboard.way1}
                </TableCell>
                <TableCell align="center">
                    {billboard.GID}
                </TableCell>
                <TableCell align="right">
                    {billboard.type}
                </TableCell>
            </TableRow>
        );
    };
}


export default BillboardListItem;