import React, { FunctionComponent } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
// import TextField from './TextField';
// import moment from 'moment';
import DatePicker from "react-datepicker";
import { DatesRange } from './InputDatesRange';
// import ru from 'date-fns/locale/ru';

type Props = TextFieldProps & {
    valueAsDate: Date | undefined,
    disabled: boolean | undefined,
    onChange: (date: Date | null) => void,
    placeholderText?: string,
    dates: DatesRange
};


const InputDate: FunctionComponent<Props> = ({ valueAsDate, disabled, onChange, placeholderText, dates }) => {
    // const dateMonth = moment(valueAsDate).format('yyyy-MM');
    return (
            // <TextField {...inputProps}
            //     type="month"
            //     value={valueAsDate ? dateMonth : ""}
            //     disabled={disabled ? disabled : false}
            // />
            <DatePicker
                selected={valueAsDate}
                onChange={(date: Date | null) => onChange(date)}
                selectsStart
                startDate={dates.DateFrom}
                endDate={dates.DateTo}
                dateFormat="dd.MM.yyyy"
                isClearable={disabled ? false : true}
                locale="ru"
                placeholderText={placeholderText}
                disabled={disabled ? disabled : false}
            />
        );
};


export default InputDate;