import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IEventGeneratePaymentBill, IEventGetPaymentBillDocument } from '../../RemoteCommands/SystemEvent';

type Props = {
    scoreOpen: boolean;
    handleScoreClose: () => void;
    generatePaymentBill?: IEventGeneratePaymentBill;
    billFile?: IEventGetPaymentBillDocument;
};

const ScoreConfirm:React.FC<Props>  = (props) => {
    const {
        scoreOpen,
        handleScoreClose,
        generatePaymentBill,
        billFile
    } = props;

    const [file, setFile] = useState<File | null>(null);

    const convBase64ToFile = (b64Data: any) => {
        var byteCharacters = atob(b64Data);

        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {type: "application/msword"});
        var blobUrl = URL.createObjectURL(blob);
        console.log('blobUrl:', blobUrl);
        return blob
    }

    function dataURLtoFile(dataurl:any, filename: string) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        setFile(new File([u8arr], filename, {type:mime}));
    }

    useEffect(() => {
        //Usage example:
        if(generatePaymentBill != undefined) {
            var file = dataURLtoFile('data:text/plain;base64,' + generatePaymentBill.fileBytes,'Word.txt');
            console.log('Word', file);
        }
        // convBase64ToFile('data:text/plain;base64,' + generatePaymentBill.fileBytes);
    }, []);

    return (
        <Dialog
            open={scoreOpen}
            onClose={handleScoreClose}
        >
            <DialogTitle>Расчетный счет</DialogTitle>
            {generatePaymentBill &&
                <DialogContent>
                    <img src={`data:image/jpeg;base64, ${generatePaymentBill.fileBytes}`} style={{width:'200px', height:"200px"}} />
                    {/* <iframe src={`data:text/plain;base64,${generatePaymentBill.fileBytes}`} height="100%" width="100%"></iframe> */}
                    {/* <iframe className="doc" src={`https://docs.google.com/gview?url=${convBase64ToFile(generatePaymentBill.fileBytes)}&embedded=true`}></iframe> */}
                    {/* <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${convBase64ToFile(generatePaymentBill.fileBytes)}`}></iframe>
                    <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=http://writing.engr.psu.edu/workbooks/formal_report_template.doc' width='100%' height='700px'></iframe> */}
                </DialogContent>
            }
            {billFile &&
                <DialogContent>
                    <img src={`data:image/jpeg;base64, ${billFile.documentImage}`} style={{width:'200px', height:"200px"}} />
                    {/* <iframe src={`data:text/plain;base64,${generatePaymentBill.fileBytes}`} height="100%" width="100%"></iframe> */}
                    {/* <iframe className="doc" src={`https://docs.google.com/gview?url=${convBase64ToFile(generatePaymentBill.fileBytes)}&embedded=true`}></iframe> */}
                    {/* <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${convBase64ToFile(generatePaymentBill.fileBytes)}`}></iframe>
                    <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=http://writing.engr.psu.edu/workbooks/formal_report_template.doc' width='100%' height='700px'></iframe> */}
                </DialogContent>
            }
            <DialogActions>
                <Button onClick={handleScoreClose} color="secondary">
                    Отмена
                </Button>
                <Button onClick={handleScoreClose} color="primary" autoFocus>
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ScoreConfirm;