import React, { FunctionComponent } from 'react';
import { ButtonGroup } from '@material-ui/core';
import SideButton from './SideButton';
import { DatesRange } from '../../../bricks/InputDatesRange';
import { IBillboardStatuses } from '../../../../RemoteCommands/type';


type Props = {
    sides: string[],
    onClick: (sideID: string) => void,
    statuses: IBillboardStatuses[],
    dates: DatesRange,
    selectedSide: string
};


const SideGroup: FunctionComponent<Props> = ({ sides, dates, statuses, selectedSide, onClick }) => {

    const sideButtons = sides.map((sideID) => {
        const sideStatus = statuses.find(s => s.geoMarkPart === sideID);
        const isSelectedSide = sideID === selectedSide;        

        return (
            <SideButton key={sideID}
                sideID={sideID}
                isSelectedSide={isSelectedSide}
                status={sideStatus}
                dates={dates}
                onClick={() => onClick(sideID)}
            />
        );
    });

    return (
        <ButtonGroup size="small" aria-label="small outlined button group" className="mb-2">
            {sideButtons}
        </ButtonGroup>
    );
};


export default SideGroup;