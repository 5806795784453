import { combineReducers } from 'redux';
import { localizeReducer } from "react-localize-redux";

import billboards from './billboards/reducer';
import app from './app/reducer';
import sip from './sip/reducer';
import user from './user/reducer';
import customers from './customers/reducer';
import todo from './todo/reducer';
import event from './event/reducer';
import bills from './bills/reducer'


const rootReducer = combineReducers({
    billboards,
    user,
    app,
    sip,
    customers,
    localize: localizeReducer,
    todo,
    event,
    bills
});


export default rootReducer;