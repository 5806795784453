import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, selectors, actions } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { ListItemIcon, Radio, TextField, ListItemText } from '@material-ui/core';
import { Project, GetProjectResult } from 'sedi-webserverproxy';
import { EventBuilder } from '../../../utils/eventHelpers';
import { ServerProxy } from '../../../RemoteCommands/ServerProxy';
import { getWebServerProxy } from '../../../WebServerProxy';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    project: Project;
    accountId: number;
    setTenderProjects?: React.Dispatch<React.SetStateAction<GetProjectResult>>
}

const ProjectSelectItem:React.FC<Props> = (props) => {
    const { 
        setActiveProject, 
        openProjectChange, 
        openSelectedBillboardsAction, 
        commercialOfferNullAction, 
        discountOpenAction,
        project, 
        projectID, 
        agenciesOpenAction,
        clientItem,
        currentBrandInfo,
        setTenderProjects,
        setAllProjects
    } = props;

    const [editProject, setEditProject] = useState(false);
    const [projectName, setProjectName] = useState(project.projectName);

    const currentProjectSelected = (projectId: number) => {
        setActiveProject(projectId);
        openProjectChange(false);
        openSelectedBillboardsAction(true);
        commercialOfferNullAction();
        discountOpenAction(false);
        agenciesOpenAction(false);
    }

    const onSaveProjectName = async () => {
        setEditProject(false);
        const builder = new EventBuilder(`EventChangeProject`);
        builder.AddParameter('ProjectId', project.projectId.toString());
        builder.AddParameter('ProjectName', projectName);
        ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        const response = await getWebServerProxy().ToDo.GetProjects({
            ownerAccountId: clientItem ? clientItem.accountId : undefined, 
            brandId:  currentBrandInfo ? currentBrandInfo.brandId : undefined
        });
        const fetchedProjects = response.Result || [];
        if(setTenderProjects !== undefined) {
            setTenderProjects(fetchedProjects);
        } else {
            setAllProjects(fetchedProjects);
        }
    }

    // const projectDisplayName = `project ${project.projectId} [${project.createTime.toLocaleString()}]`;
    const labelId = `checkbox-list-label-${project.projectId}`;

    return (
        <div className="editProject">
            <ListItemIcon>
                <Radio
                    edge="start"
                    checked={project.projectId === projectID}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={() => currentProjectSelected(project.projectId)}
                />
            </ListItemIcon>
            <div className="editProject__item">
                {editProject ?
                    <TextField 
                        value={projectName}
                        onChange={(event) => setProjectName(event.target.value)}
                        fullWidth
                        label="Имя проекта" 
                        variant="outlined" 
                        size="small" 
                        autoFocus
                        onBlur={onSaveProjectName}/>
                :
                    <ListItemText primary={projectName} onClick={() => setEditProject(true)} />
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        projectID: selectors.todo.getProjectID(state.todo),
        clientItem: state.customers.clientItem,
        currentBrandInfo: state.billboards.currentBrandInfo
    }
};


const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    openProjectChange: (openProjectModal: boolean) => 
        dispatch(actions.todo.openProject(openProjectModal)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => 
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    commercialOfferNullAction: () => 
        dispatch(actions.todo.commercialOfferNullAction()),
    discountOpenAction: (discountOpen: boolean) => 
        dispatch(actions.todo.discountOpenAction(discountOpen)),
    setAllProjects: (projects: Project[]) => 
        dispatch(actions.todo.setAllProjects(projects)),
    agenciesOpenAction: (agenciesOpen: boolean) =>
        dispatch(actions.event.agenciesOpenAction(agenciesOpen)),
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(ProjectSelectItem);