export function downloadExcel(excel: string, fileName: string) {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excel}`;
    const downloadLink = document.createElement("a");
    const name = `${fileName}.xlsx`;

    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
}

export function downloadJpg(jpg: string, fileName: string) {
    const linkSource = `data:image/jpeg;base64,${jpg}`;
    const downloadLink = document.createElement("a");
    const name = `${fileName}.jpg`;

    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
}