import React, { Dispatch, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import { TableCell, TextField } from '@material-ui/core';
import { GetProjectResult, Project } from 'sedi-webserverproxy';
import { ChangeProjectParams } from '../../../RemoteCommands/type';
import { SystemEvent } from '../../../RemoteCommands/SystemEvent';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    project: Project;
    setTenderProjects?: React.Dispatch<React.SetStateAction<GetProjectResult>>;
}

const EditProjectName:React.FC<Props> = (props) => {
    const { 
        project,
        setActiveProject,
        openSelectedBillboardsAction,
        commercialOfferNullAction, 
        discountOpenAction,
        agenciesOpenAction,
        openProjectChange
    } = props;
    const [editProject, setEditProject] = useState(false);
    const [projectName, setProjectName] = useState(project.projectName);

    const currentProjectSelected = (projectInfo: Project) => {
        setActiveProject(projectInfo.projectId);
        openSelectedBillboardsAction(true);
        commercialOfferNullAction();
        discountOpenAction(false);
        agenciesOpenAction(false);
        openProjectChange(false);
    }

    const onSaveProjectName = async () => {
        if(projectName.includes('"')) {
            alert("Вы не можете использовать кавычки");
            return;
        }
        const payload: ChangeProjectParams = {
            projectId: project.projectId,
            projectName: projectName
        }

        SystemEvent.EventChangeProject(payload);
        setEditProject(false);
    }
    
    return (
        <TableCell 
            component="th" 
            scope="row"
            className="geoMarkName cancelDraggable">
            {!editProject ? 
                <span>
                    <span 
                        className="link"
                        onClick={() => currentProjectSelected(project)}>
                        {projectName}
                    </span>
                    <CreateIcon onClick={() => setEditProject(true)} />
                </span>
                : 
                <TextField 
                    variant="outlined" 
                    value={projectName} 
                    size="small"
                    onChange={(event) => setProjectName(event.target.value)}
                    fullWidth
                    autoFocus={editProject}
                    onBlur={onSaveProjectName}
                />
            }
        </TableCell>
    );
}

const mapStateToProps = (state: RootState) => ({
    clientItem: state.customers.clientItem,
    currentBrandInfo: state.billboards.currentBrandInfo
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setAllProjects: (projects: Project[]) => 
        dispatch(actions.todo.setAllProjects(projects)),
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => 
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    commercialOfferNullAction: () => 
        dispatch(actions.todo.commercialOfferNullAction()),
    discountOpenAction: (discountOpen: boolean) => 
        dispatch(actions.todo.discountOpenAction(discountOpen)),
    agenciesOpenAction: (agenciesOpen: boolean) =>
        dispatch(actions.event.agenciesOpenAction(agenciesOpen)),
    openProjectChange: (openProjectModal: boolean) => 
        dispatch(actions.todo.openProject(openProjectModal))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectName);