import { AssignedObject, Project, ProjectGeoMarkStatus } from "sedi-webserverproxy"
import { ICommercialOffer } from "sedi-webserverproxy/dist/controllers/webclient/types/CommercialOffer"
import { DatesRange } from "../../components/bricks/InputDatesRange"
import { BillboardsShortInfo } from "../../RemoteCommands/type"
import { ExceededTimeProject } from "../../utils/onlyUnique"

export enum BackendActionType {
    SetCustomerInformation = 'SetCustomerInformation',
    SetBoardInformationProject = 'SetBoardInformationProject'
}


export type ActionFromBackend = {
    ActionName: BackendActionType;
    Description: string,
    Header?: string,
    SequenceNumber: number,
    DoIntermediate?: FrontendAction,
    Items: ActionFromBackendItem[],
    SendEventOnOpening?: string,
    ClearActions?: boolean,
}


export type ActionFromBackendItem = {
    Text: string;
    Buttons: ActionFromBackendButton[]
}


export type ActionFromBackendButton = {
    Text: string,
    FrontendAction: FrontendAction,
    SystemEvent?: string,
    SystemEventParameters?: ActionSystemEventParameter[]
}

type ActionSystemEventParameter = {
    ParameterName: string,
    ParameterValue: string,
    NeedFillParameter: boolean,
}


export enum FrontendAction {
    CompleteCurrentAction = 'CompleteCurrentAction', // завершение текущего экшена
    OpenCallerCustomerProfile = 'OpenCallerCustomerProfile', // Открыть формуляр регистрации информации о звонящем физ.лице
    OpenCallerCompanyProfile = 'OpenCallerCompanyProfile', // Открыть формуляр компании звонящего (редактирование/создание)
    OpenProjects = 'OpenProjects', // Открывает модальное окно для добавления поректа
    OpenMessageDialog = 'OpenMessageDialog', //Открыть окно с сообщением и кнопками для ответов
    SendSystemEvent = 'SendSystemEvent',
    OpenProjectDiscount = 'OpenProjectDiscount',
}


export type lowerActionFromBackend = {
    actionName: BackendActionType;
    description: string,
    sequenceNumber: number,
    //DoIntermediate?: FrontendAction,
    items: lowerActionFromBackendItem[]
}


export type lowerActionFromBackendItem = {
    text: string;
    buttons: lowerActionFromBackendButton[]
}


export type lowerActionFromBackendButton = {
    text: string,
    frontendAction: FrontendAction
}


/* ниже 2 варианта команды от сервера */

// 1) нет инфо о клиенте
const firstAction: ActionFromBackend = {
    ActionName: BackendActionType.SetCustomerInformation,
    Description: 'Сохранение информации о звонящем (SetCustomerInformation)',
    SequenceNumber: 1,
    //DoIntermediate: FrontendAction.OpenCallerCustomerProfile, // Открыть формуляр регистрации информации о звонящем физ.лице
    Items: [
        {
            Text: 'Введите информацию о звонящем и при необходимости информацию о его юридическом лице. Открыть информацию о его юридическом лице?',
            Buttons: [
                {
                    Text: 'ДА',
                    FrontendAction: FrontendAction.OpenCallerCompanyProfile
                }
            ]
        }
    ]
}


// 2) уже есть инфо о клиенте
const firstAction2: ActionFromBackend = {
    ActionName: BackendActionType.SetCustomerInformation,
    Description: 'Сохранение информации о звонящем (SetCustomerInformation)',
    SequenceNumber: 1,
    //DoIntermediate: FrontendAction.OpenCallerCustomerProfile, // ничего не нужно делать
    Items: [
        {
            Text: 'Вы можете дополнить или отредактировать информацию по юр. или физ. лицу звонящего',
            Buttons: [
                {
                    Text: 'Юр. лицо',
                    FrontendAction: FrontendAction.OpenCallerCompanyProfile
                },
                {
                    Text: 'Физ. лицо',
                    FrontendAction: FrontendAction.OpenCallerCustomerProfile
                }
            ]
        }
    ]
}


// регистрация щитов

const secondAction: ActionFromBackend = {
    ActionName: BackendActionType.SetBoardInformationProject,
    Description: 'Резервация щитов для заказчика (SetBoardInformationProject)',
    SequenceNumber: 2,
    //DoIntermediate: FrontendAction.OpenCallerCustomerProfile, // ничего не нужно делать
    Items: [
        {
            Text: 'Вы можете приступить к резервированию рекламных щитов ... нажмите кнопку "Завершено"',
            Buttons: [
                {
                    Text: 'Завершено',
                    FrontendAction: FrontendAction.CompleteCurrentAction
                },
                // Кнопка "начать"? чтобы открыть карту?
            ]
        }
    ]
}


export interface TodoActionsState {
    TodoActions: ActionFromBackend[],
    openProjectModal: boolean,
    projectID: number,
    currentCompanyAccountId: number,
    projects: Project[],
    openSelectedBillboards: boolean,
    commercialOffer: ICommercialOffer | null,
    permissionResolutionNegative: boolean,
    note: string,
    totalDiscountText: string,
    projectDiscount: ProjectDiscount[],
    discountOpen: boolean,
    projectPeriod: ProjectPeriod[],
    currentGeoMarkStatus: ProjectStatuses,
    dates: DatesRange,
    projectStatuses: ProjectStatuses[],
    projectTypes: ProjectTypes[],
    coworkers: Coworkers[],
    projectFilter: IProjectFilter,
    openAdditionalCost: boolean,
    exceededTimeProjects: ExceededTimeProject[],
    exceededTimeProject: ExceededTimeProject | null
}


export enum ReduxTodoActionsTypes {
    ADD_TODO_ACTION = 'ADD_TODO_ACTION',
    OPEN_PROJECT_MODAL = 'OPEN_PROJECT_MODAL',
    SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT',
    CURRENT_ACTIVE_COMPANY = 'CURRENT_ACTIVE_COMPANY',
    SET_ALL_PROJECTS = 'SET_ALL_PROJECTS',
    ALL_PROJECTS = 'ALL_PROJECTS',
    OPEN_SELECTED_BILLBOARDS = 'OPEN_SELECTED_BILLBOARDS',
    SEND_SYSTEM_EVENT = 'SEND_SYSTEM_EVENT',
    COMMERCIAL_OFFER = 'COMMERCIAL_OFFER',
    COMMERCIAL_OFFER_NULL = 'COMMERCIAL_OFFER_NULL',
    COMMERCIAL_OFFER_ID = 'COMMERCIAL_OFFER_ID',
    COMMERCIAL_OFFER_DISCOUNT = 'COMMERCIAL_OFFER_DISCOUNT',
    BUTTON_NEGATIVE = 'BUTTON_NEGATIVE',
    NOTE = 'NOTE',
    TOTAL_DISCOUNT_TEXT = 'TOTAL_DISCOUNT_TEXT',
    PROJECT_DISCOUNT = 'PROJECT_DISCOUNT',
    DISCOUNT_OPEN = 'DISCOUNT_OPEN',
    PROJECT_PERIOD = 'PROJECT_PERIOD',
    CURRENT_GEOMARK_STATUS = 'CURRENT_GEOMARK_STATUS',
    DATES_FROM_TO = "DATES_FROM_TO",
    PROJECT_STATUSES = "PROJECT_STATUSES",
    PROJECT_TYPES = "PROJECT_TYPES",
    COWORKERS = "COWORKERS",
    PROJECT_FILTER = "PROJECT_FILTER",
    OPEN_ADDITIONAL_COST = "OPEN_ADDITIONAL_COST",
    EXCEEDED_TIME_PROJECTS = "EXCEEDED_TIME_PROJECTS",
    EXCEEDED_TIME_PROJECT = 'EXCEEDED_TIME_PROJECT'
}

export enum ProjectType {
    CommonProject = 'CommonProject',
    ParentCommercialOfferProject = 'ParentCommercialOfferProject'
}

export interface ProjectDiscount {
    Cost: number,
    Gid: string,
    Side: string,
    DateFrom: Date,
    DateTo: Date
}

export interface ProjectPeriod {
    DateEnd: Date,
    DateStart: Date,
    Gid: string,
    Status: ProjectGeoMarkStatus
}

export type ProjectStatuses = {
    localization: string,
    value: ProjectGeoMarkStatus
}

export type ProjectTypes = {
    localization: string,
    value: ProjectType
}

export type Coworkers = {
    id: number,
    name: string,
    nameWithId: string
}

export interface IProjectFilter {
    projectType: ProjectType;
    projectStatus: undefined;
    coworker: undefined;
}

export type BillboardsAsassignedObject = {
    assignedObject: AssignedObject | undefined;
    billboard: BillboardsShortInfo;
}

export interface IProjectBillboards {
    id: number;
    address: string;
    GID: string;
    billboardProperties: IBillboardProperties[];
    tt: string;
}

export interface IBillboardProperties {
    geoMarkTimeStatusId: number;
    geoMarkId: number;
    GID: string;
    side: string;
    constructionType: string;
    format: string;
    type: string;
    price: number | undefined;
    priceDiscount: number | undefined;
    dateFrom: Date | undefined;
    dateTo: Date | undefined;
}