import store from "../store";
import BUILD_PARAMS from "../utils/build";

class Helper {
    static Timeout(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    static Uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

export type EventItem = {
    eventSequenceNumber: number,
    eventName: string,
    eventKey: string,
    evenTime: Date,
    eventType: string,
    eventParameters: { [id: string] : any; } 
}

export class EventsHandler {
    private static eventHandlers: { [id: string] : { [id: string] : (e:EventItem) => void; } } = {};

    public static Subscribe(eventName: string, listenerName: string, listener: (event: EventItem) => void) {
        if (!EventsHandler.eventHandlers[eventName]) {
            EventsHandler.eventHandlers[eventName] = {};
        }
        EventsHandler.eventHandlers[eventName][listenerName] = listener;
    }

    public static Handle(event: EventItem) {
        const eventHandler = EventsHandler.eventHandlers[event.eventName];
        if (eventHandler) {
            Object.values(eventHandler).forEach(listener => listener(event));
        }
    }
}

export class EventsQueue {
    private static server = "";
    private static deviceId = "";
    private static licenceKey = "";
    private static startEventNumber = 0;
    private static sendEventNumber = 0;
    private static logoutCallback = () => {};
    private static lastReceivedEventTime = new Date();

    private static sendQueue: EventItem[] = [];

    static Initialize(server: string, deviceId: string, licenseKey: string, logoutCallback: () => void) {
        EventsQueue.server = server;
        EventsQueue.deviceId = deviceId;
        EventsQueue.licenceKey = licenseKey;
        EventsQueue.logoutCallback = logoutCallback;

        EventsQueue.eventsReceiver();
        EventsQueue.eventsSender();
    }

    static GetLicenseKey() {
        const { licenceKey } = store.getState().user;
        //получать актуальное значение после авторизации
        if(licenceKey) {
            return licenceKey;
        }
        return EventsQueue.licenceKey;
    }
    static GetLanguage() {
        const { defaultLanguage } = store.getState().localize.options;
        if(defaultLanguage) {
            return defaultLanguage
        }
        return "ru";
    }
 
    private static async eventsReceiver() {
        while (true) {
            try {
                const url = `${EventsQueue.server}/api/EventsQueue/GetEvents?deviceId=${EventsQueue.deviceId}&licenseKey=${EventsQueue.GetLicenseKey()}&startEventNumber=${EventsQueue.startEventNumber}&lang=${EventsQueue.GetLanguage()}`;
                const result = await fetch(url);
                if (result.status !== 200) {
                    if (result.status === 401) {
                        EventsQueue.logoutCallback();
                    }
                    await Helper.Timeout(3000);
                    continue;
                }

                const events = await result.json();
                events.forEach((event: EventItem) => {
                    EventsQueue.lastReceivedEventTime = new Date();
                    EventsQueue.startEventNumber = event.eventSequenceNumber;
                    EventsHandler.Handle(event);
                });

            } catch (error) {
                console.log(error);
                await Helper.Timeout(5000);
            }
        }
    }

    /**
     * Отправка событий из очереди на сервер
     */
    private static async eventsSender() {
        while (true) {
            try {
                const event = EventsQueue.sendQueue.shift();
                if(!event){
                    await Helper.Timeout(100);
                    continue;
                }
                let sent = false;
                while (!sent) {
                    try {
                        sent = await EventsQueue.sendEventPrivate(event);
                    }
                    catch (e) { }
                    if (!sent) {
                        await Helper.Timeout(5000);
                    }
                }
            } catch (error) {
                console.log(error);
                await Helper.Timeout(5000);
            }
        }
    }

    private static async sendEventPrivate(event: EventItem) {
        const url = `${EventsQueue.server}/api/EventsQueue/SendEvent?deviceId=${EventsQueue.deviceId}&licenseKey=${EventsQueue.GetLicenseKey()}&lang=${EventsQueue.GetLanguage()}`;
        const config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(event)
        };

        const result = await fetch(url, config);
        if (result.status !== 200) {
            if (result.status === 401) {
                EventsQueue.logoutCallback();
                return false;
            }
            return false;
        }
        return true;
    }

    /**
     * Отправка события на сервер
     * @param {string} eventName 
     * @param {Object.<string, Object>} eventParameters 
     */
    public static SendEvent(eventName: string, eventParameters: { [id: string] : any }): string {
        EventsQueue.sendEventNumber++;
        const key = Helper.Uuidv4();
        const event: EventItem = {
            eventParameters: eventParameters,
            eventName: eventName,
            evenTime: new Date(),
            eventKey: key,
            eventSequenceNumber: EventsQueue.sendEventNumber,
            eventType: "Send"
        };

        EventsQueue.sendQueue.push(event);
        return key;
    }
}

//тут указывать 1) сервер 2) deviceId 3) licenceKey 4) колбэк на случай если ключ не валидный
EventsQueue.Initialize(BUILD_PARAMS.WEBAPI_URL! + ":9011", Helper.Uuidv4(), BUILD_PARAMS.WEBSERVER_APIKEY!, () => {
    alert("ключ авторизации недействительный");
});