export class EventStorsge {
    public static EventParametersPatterns: { [id: string]: EventParameters; };
}

export class EventBuilder {
    private _eventParametersPatterns: { [id: string]: EventParameters; };
    private _eventName: string;
    private _parameters: { [id: string]: string | number } = {};
  
    constructor(eventName: string) {
      this._eventParametersPatterns = EventStorsge.EventParametersPatterns;
      this._eventName = eventName;
    }
  
    public AddParameter(parameterName: string | number, parameterValue : string | number) : void {
      this._parameters[parameterName] = parameterValue;
    }

    public AddParameterDate(parameterName: string, parameterValue : any) : void {
        this._parameters[parameterName] = parameterValue;
      }

    public AddJsonParameter(parameterName: string, parameterValue : any) : void {
        this._parameters[parameterName] = JSON.stringify(parameterValue);
      }
  
    public GetSystemEvent(): string | null {
      const pattern = this._eventParametersPatterns[this._eventName];
      if (!pattern) {
        return null;
      }
  
      let event = this._eventName;
      const parameters = pattern.eventParameters.split(';');
  
      for (var i = 0; i < parameters.length; i++) {
        const value = this._parameters[parameters[i]];
        if (value) {
          event = event + ";" + value;
        } else {
          event = event + ";";
        }
      }
  
      return event;
    }
}

export class EventParameters {
    eventParameters: string;
    eventResponseParameters: string | null;
  
    constructor(eventParameters: string, eventResponseParameters: string | null) {
      this.eventParameters = eventParameters;
      this.eventResponseParameters = eventResponseParameters;
    }
}
  
export class EventParser {
    private _eventParametersPatterns: { [id: string]: EventParameters; };
    private _event: string;
  
    constructor(event: string) {
      this._eventParametersPatterns = EventStorsge.EventParametersPatterns;
      this._event = event;
    }
  
    public GetEventName() : string {
      const name = this._event.split(';')[0];
      return name;
    }
  
    public GetParameterValue(parameterName: string, forResponse: boolean): string {
      const pattern = this._eventParametersPatterns[this.GetEventName()];
      if (!pattern) {
            console.log('pattern not found ' + this.GetEventName());
            return "";
      }

      const patternParametrs = forResponse ? pattern.eventResponseParameters : pattern.eventParameters;
      if(!patternParametrs) {
            console.log('patternParametrs not found ' + parameterName);
            return '';
      }
      const parameters = patternParametrs.split(';');
      let parameterIndex = -1;
      for (var i = 0; i < parameters.length; i++)
      {
        if (parameters[i] === parameterName) {
          parameterIndex = i;
          break;
        }
      }
  
      if (parameterIndex === -1)
        return "";
  
      var values = this._event.split(';');
      if (parameterIndex > values.length - 2)
        return "";
  
      //event name index 0
      return values[parameterIndex + 1];
    }
}