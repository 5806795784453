/* App.js */
import React, { Component } from 'react';
import CanvasJSReact from '../../utils/Canvasjs/canvasjs.react';
import { SalesPaymentStatisticResult } from '../../RemoteCommands/type';
import { Button, ButtonGroup } from '@material-ui/core';
import { MonthYear } from '../../utils/getMonthYear';
import { StatisticsType } from '../../store/event/types';
import { groupSales } from '../../utils/groupSales';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

type Props = {
    salesPaymentStatisticResult: SalesPaymentStatisticResult;
    intervalMonthYears: () => MonthYear[];
    statisticsType: StatisticsType
}
type State = {
    chartType: string;
}

class GraphsCharts extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            chartType: "summa"
        }
    }

    chartTypePoint(type: string, label: string, y: number, dataPoints: DataPoint[]) {
        const { chartType } = this.state;
        if(chartType === type) {
            dataPoints.push({
                label,
                y
            });
        }
        return dataPoints;
    }

    getDataPoints() {
        const {salesPaymentStatisticResult, intervalMonthYears, statisticsType } = this.props;

        let dataPoints: DataPoint[] = [];
        if(statisticsType === StatisticsType.sale) {
            for(let monthYear of intervalMonthYears()) {
                const findStatistic = salesPaymentStatisticResult.statistic.find(item => 
                    item.month === monthYear.monthNumber && item.year === parseInt(monthYear.year)
                );
                if(findStatistic) {
                    this.chartTypePoint(
                        'count', 
                        monthYear.month +" "+ monthYear.year, 
                        findStatistic.totalItem.soldTotalCount, 
                        dataPoints
                    );
                    this.chartTypePoint(
                        'summa', 
                        monthYear.month +" "+ monthYear.year, 
                        findStatistic.totalItem.soldTotalSum + findStatistic.totalItem.designSum + findStatistic.totalItem.installationSum + findStatistic.totalItem.printSum + findStatistic.totalItem.additionalInstallationSum, 
                        dataPoints
                    );
                } else {
                    dataPoints.push({
                        label: monthYear.month +" "+ monthYear.year,
                        y: 0
                    })
                }
            }
        } else {
            const { statistic } = salesPaymentStatisticResult;
            groupSales(statistic).map(sale => {
                this.chartTypePoint(
                    'count', 
                    sale.name, 
                    sale.soldTotalCount, 
                    dataPoints
                );
                this.chartTypePoint(
                    'summa', 
                    sale.name, 
                    sale.soldTotalSum + sale.designSum + sale.installationSum + sale.printSum + sale.additionalInstallationSum, 
                    dataPoints
                );
            });
        }
        return dataPoints;
    }

    chartButtonClick(payload: string) {
        this.setState({
            chartType: payload
        })
    }

	render() {
        const { chartType } = this.state;

        let options = {
            width: 968,
            animationEnabled: true,
			exportEnabled: true,
			theme: "light2", //"light1", "dark1", "dark2"
			axisY: {
				includeZero: true,
				suffix: " руб."
            },
			data: [
                {
                    // Change type to "doughnut", "line", "splineArea", etc.
                    type: "column",
                    dataPoints: this.getDataPoints()
                }
            ]
        }
        if(chartType === "count") {
            options.axisY.suffix = ""
        }
		
		return (
            <div className="сanvasJSChart">
                <div className="сanvasJSChart__header">
                    <ButtonGroup>
                        <Button 
                            onClick={() => this.chartButtonClick("summa")}
                            color={chartType === "summa" ? "primary" : undefined}>Сумма</Button>
                        <Button 
                            onClick={() => this.chartButtonClick("count")}
                            color={chartType === "count" ? "primary" : undefined}>Количество</Button>
                    </ButtonGroup>
                </div>
                <CanvasJSChart options={options} />
            </div>
		);
	}
}

export interface DataPoint {
    label: string;
    y: number;
}

export default GraphsCharts;