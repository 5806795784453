import React, { FunctionComponent } from 'react';
import SelectMaterial from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SelectChangeEvent } from './Select';
import '../css/SelectOutlined.scss'

type Props = {
    // TODO добавить поддержку других типов key-value
    dataSource: object,
    value: string,
    onChange: (event: SelectChangeEvent) => void,
    labelText: string
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            padding: "10.5px 14px"
        },
        label: {
            transform: "translate(10px, 8px) scale(1)"
        }
    }),
);


const SelectOutlined: FunctionComponent<Props> = ({ dataSource, value, labelText, onChange }) => {

    const classes = useStyles();

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    const selectOptions = Object.entries(dataSource)
        .map(([enumKey, enumValue]) => parseInt(enumKey) > 10 
            ? <option className="option-menu-item" key={enumKey} value={enumKey}>{enumValue}</option> 
            : <MenuItem key={enumKey} value={enumKey}>{enumValue}</MenuItem>);

    return (
        <FormControl variant="outlined" className="w-100 mt-1">
            <InputLabel shrink 
                ref={inputLabel} 
                className={classes.label}>
                {labelText}
            </InputLabel>
            <SelectMaterial
                value={value}
                onChange={onChange}
                input={<OutlinedInput classes={{ input: classes.input }} 
                    labelWidth={labelWidth}
                    />
                }
            >
                {selectOptions}
            </SelectMaterial>
        </FormControl>
    );
};


export default SelectOutlined;