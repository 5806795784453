import { AdditionalGeoMarkTariffs, IAdditionalCostParameters } from "../RemoteCommands/type";
import store from "../store";
import * as todoActions from '../store/todo/actions';

const onlyUnique = (arr: any[]) => {
    return [...new Set(arr)];
}
export {onlyUnique}

export function uniqueAdditionalTariffs(array: AdditionalGeoMarkTariffs[]): PricingTypes[] {
    const result: PricingTypes[] = [];
    const map = new Map();
    for (const item of array) {
        if(!map.has(item.pricingType)){
            map.set(item.pricingType, true);    // set any value to Map
            result.push({
                pricingType: item.pricingType,
                pricingLocalization: item.pricingLocalization
            });
        }
    }
    return result;
}

export function uniqueAdditionalCosts(array: IAdditionalCostParameters[]): IAdditionalCostParameters[] {
    const result: IAdditionalCostParameters[] = [];
    const map = new Map();
    for (const item of array) {
        if(!map.has(item.costName)){
            map.set(item.costName, true);    // set any value to Map
            result.push(item);
        }
    }
    return result;
}

export type PricingTypes = {
    pricingType: string;
    pricingLocalization: string;
}

export type ExceededTimeProject = {
    creatorAccountId: string;
    employeeName: string;
    projectId: string;
    projectName: string;
    eventText: string;
}

export function uniqueExceededTimeProject(projects: ExceededTimeProject[], project: ExceededTimeProject): ExceededTimeProject[] {
    const userInfo = store.getState().user.userInfo;
    if(projects.length > 0 && (userInfo && userInfo.userRole !== "SalesDirector")) {
        store.dispatch(todoActions.exceededTimeProjectAction(projects[0]));
    }
    if(projects.some(item => item.projectId === project.projectId)) {
        return projects;
    }
    let result = projects.concat(project);
    if(userInfo && userInfo.userRole !== "SalesDirector") {
        result = result.filter(item => item.creatorAccountId === userInfo.accountId.toString());
    }
    return result;
}