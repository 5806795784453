import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, selectors } from '../../../../../../store';
import { ConnectedClientModal } from '../../../../../../pages/ClientModal';
import { ClientInfo, PhoneInfo } from 'sedi-webserverproxy';
import { ActionFromBackendButton } from '../../../../../../store/todo/types';
import { getWebServerProxy } from '../../../../../../WebServerProxy';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';


type Props = ReturnType<typeof mapStateToProps> & {
    action: ActionFromBackendButton
}


const ButtonOpenCallerCustomerProfile: React.FunctionComponent<Props> = ({ callerPhoneNumber, callerAccountID, action }) => {

    const [showClientModal, setShowClientModal] = useState(false);
    const [clientInfo, setClientInfo] = useState(new ClientInfo());


    useEffect(() => {
        fetchCustomer(callerAccountID);
    }, [callerAccountID, callerPhoneNumber])


    const fetchCustomer = async (customerAccountID: number) => {
        if (customerAccountID <= 0) {
            if (callerPhoneNumber) {
                let newClient = new ClientInfo();
                newClient.isLegalEntity = false;

                let newPhone = new PhoneInfo();
                newPhone.phoneNumber = callerPhoneNumber;
                newClient.phones.push(newPhone);

                setClientInfo(newClient);
            }
            return;
        }

        const response = await getWebServerProxy().WebClient.GetCustomers({ accountId: customerAccountID });
        if (response.Success && response.Result && response.Result.length > 0) {
            const fetchedCustomer = response.Result[0];
            setClientInfo(fetchedCustomer);
        }
    }
    
    return (
        <>
            <Button 
                variant="contained" 
                color="primary"
                size="small"
                startIcon={<PersonIcon />}
                onClick={() => setShowClientModal(true)}>
                <span className="buttonText">{action.Text}</span>
            </Button>
            <ConnectedClientModal
                show={showClientModal}
                onClose={() => setShowClientModal(false)}
                clientInfo={clientInfo} />
        </>
    );
}


const mapStateToProps = (state: RootState) => {
    let callerAccountID = selectors.sip.GetCallerAccountID(state.sip);

    return {
        callerPhoneNumber: selectors.sip.GetCallerPhoneNumber(state.sip),
        callerAccountID,
    }
};


export default connect(
    mapStateToProps
)(ButtonOpenCallerCustomerProfile);