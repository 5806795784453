import React, {useState, useEffect} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { AutocompleteAPI } from '../../api/AutocompleteAPI';

type Props = {
    label: string;
    onChange: (value: string) => void;
    defaultValue: string;
}

const AddressCompany:React.FC<Props> = ({ label, defaultValue, onChange }) => {

    const [valueFromView, setValueFromView] = useState('');
    const [valueFromCache, setValueFromCache] = useState('');
    const [addressFromCache, setAddressFromCache] = useState<AddressInfo[]>([]);
    const [addressFromView, setAddressFromView] = useState<AddressInfo[]>([]);

    useEffect(() => {
        setValueFromView(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        onChange(valueFromView);
    }, [valueFromView]);


    const [selectedStreet, setSelectedStreet] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [disableCloseOnSelect, setDisableCloseOnSelect] = useState(true);

    const searchChange = (value: string) => {
        setValueFromView(value);
        if(selectedCity !== '') {
            value = value.replace(selectedCity + ', ', "");
        }
        if(value.length >= 3) {
            if(valueFromCache === "" || addressFromCache.length > 200 || (value.toLowerCase().startsWith(valueFromCache.toLowerCase()) === false)) {
                if(selectedStreet !== '') {
                    const street = value.replace(selectedCity + ', ', "");
                    AutocompleteAPI.autocompleteAllHouse(selectedCity, street).then(data => {
                        setAddressFromCache(data);
                        setAddressFromView(data);
                        setValueFromCache(value);
                    });
                } else {
                    const street = value.replace(selectedCity + ', ', "");
                    AutocompleteAPI.autocomplete(street).then(data => {
                        setAddressFromCache(data);
                        setAddressFromView(data);
                        setValueFromCache(value);
                    });
                }
            }   else {
                let firstOptionsArray: AddressInfo[] = addressFromCache.filter(option => option.v.toLowerCase().indexOf(value.toLowerCase()) === 0);
                let secondOptionsArray: AddressInfo[] = addressFromCache.filter(option => option.v.toLowerCase().indexOf(' ' + value.toLowerCase()) > 0);
                const newAddressInfo = firstOptionsArray.concat(secondOptionsArray);
                setAddressFromView(newAddressInfo);
                if(selectedStreet !== '' && newAddressInfo.length === 0) {
                    const house = value.replace(selectedStreet + ', ', "").replace(selectedCity + ', ', "");
                    AutocompleteAPI.autocompleteHouse(selectedCity, selectedStreet, house).then(data => {
                        setAddressFromCache(data);
                        setAddressFromView(data);
                        setValueFromCache(value);
                    });
                }
            }
        } else {
            setAddressFromCache([]);
            setAddressFromView([]); 
            setValueFromCache('');
            setSelectedStreet('');
            setSelectedCity('');
        }
    }

    const renderOption = (option: AddressInfo) => {
        return (
            <div>
                <span>{option.c}, </span>
                <span>{option.v}</span>
            </div>
        )
    }

    const selectItem = (option: AddressInfo) => {
        if(!option) {
            return;
        }
        if(option.t === 'o' || option.t === 'h') {
            setDisableCloseOnSelect(false);
            setValueFromView(option.c + ', ' + option.v);
        };
        if(option.t === 's') {
            setDisableCloseOnSelect(true);
            setValueFromView(option.c + ', ' + option.v + ', ');
            setSelectedStreet(option.v);
            setSelectedCity(option.c);
            AutocompleteAPI.autocompleteAllHouse(option.c, option.v).then(data => {
                setAddressFromCache(data);
                setAddressFromView(data);
            });
        }
    }

    return (
        <Autocomplete
            className="addressCompany"
            options={addressFromView}
            getOptionLabel={(option) => valueFromView}
            renderOption={(option: AddressInfo) => renderOption(option)}
            onChange={(event: React.ChangeEvent<{}>, changeValue: AddressInfo) => selectItem(changeValue)}
            freeSolo
            disableCloseOnSelect={disableCloseOnSelect}
            value={valueFromView}
            renderInput={(params) => 
                <TextField 
                    {...params}
                    label={label}
                    variant="outlined" 
                    fullWidth 
                    size="small" 
                    InputLabelProps={{ shrink: true }} 
                    onChange={(event) => searchChange(event.target.value)} />
            }
        />
    )
}

export type Center = {
    lat: number,
    lng: number
}

type G = {
    lat: number,
    lon: number
}

export interface AddressInfo {
    n: number,
    v: string,
    c: string,
    g: G,
    t: string,
}


export default AddressCompany;