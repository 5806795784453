import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import { IBrandsInfo } from '../../store/billboards/types';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { IMultipleMarker } from '../map/types';
import { randomInteger } from '../../utils/randomInteger';
import { calculateDistance } from '../../utils/calculateDistance';
import { BrandGeoObjects, GeoPoint, IGeoObjectBillboards } from '../../store/event/types';
import DeleteBrandModal from './DeleteBrandModal';
import { EmployeeBrandsInfo } from 'sedi-webserverproxy';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    selectedBrandMarker: (brandMarkers: IMultipleMarker[]) => void;
}

const BrandsCardTable:React.FC<Props> = (props) => {
    const { 
        brandsInfo,
        currentBrandInfoAction,
        selectedBrandMarker,
        brandGeoObjects,
        billboardsShortInfo,
        brandGeoObjectsAction,
        maxDistance,
        openNextBillboardAction,
        currentBrandInfo,
        searchGeoObjectsToggleAction,
        openBrandsCardAction
    } = props;

    const [changeMarkers, setChangeMarkers] = useState(false);
    const [deleteBrandOpen, setDeleteBrandOpen] = useState(false);
    const [currentBrand, setCurrentBrand] = useState<EmployeeBrandsInfo | null>(null);

    const deleteBrandClose = () => {
        setDeleteBrandOpen(false);
    }

    const filterBrands = currentBrandInfo ? brandsInfo.filter(item => item.brandId === currentBrandInfo.brandId) : brandsInfo;

    const showMarkersClick = (brandId: number) => {
        const builder = new EventBuilder(`EventGetBrandGeoObjects`);
            builder.AddParameter('BrandId', brandId);
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        setTimeout(() => {
            setChangeMarkers(true);
        }, 700);
    }

    useEffect(() => {
        if(changeMarkers) {
            const brandMarkers = brandGeoObjects.map(item => {
                const brandMarker: IMultipleMarker = {
                    id: randomInteger(1, 1000),
                    address: item.Address,
                    center: {
                        lat: item.GeoPoint!.Latitude,
                        lng: item.GeoPoint!.Longitude,
                    },
                    marker: null
                }
                return brandMarker;
            });
            selectedBrandMarker(brandMarkers);
            setChangeMarkers(false);
        }
    }, [changeMarkers]);

    const showGeoObjectsClick = (brand: IBrandsInfo) => {
        setChangeMarkers(false);
        currentBrandInfoAction(brand);
        const builder = new EventBuilder(`EventGetBrandGeoObjects`);
            builder.AddParameter('BrandId', brand.brandId);
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        openNextBillboardAction(true);
        openBrandsCardAction(false);
    }

    const searchGeoObject = (brand: IBrandsInfo) => {
        setChangeMarkers(false);
        currentBrandInfoAction(brand);
        searchGeoObjectsToggleAction(true);
    }

    useEffect(() => {
        const brandMarkers = brandGeoObjects.map(brandGeoObject => {
            const findBillboards = billboardsShortInfo.filter(item => 
                calculateDistance(brandGeoObject.GeoPoint!.Latitude, 
                    brandGeoObject.GeoPoint!.Longitude, 
                    item.geoPoint.latitude, 
                    item.geoPoint.longitude) < parseInt(maxDistance)
            );

            let resultGeoObject: IGeoObjectBillboards[] = [];
            for (let billboard of findBillboards) {
                const geoPoint: GeoPoint = {
                    Latitude: billboard.geoPoint.latitude,
                    Longitude: billboard.geoPoint.longitude
                }
                const resultBrandGeoObject: IGeoObjectBillboards = {
                    GeoMarkId: billboard.geoMarkId,
                    GeoMarkName: billboard.name,
                    Address: {
                        CountryName: "", 
                        CityName: "", 
                        StreetName: "", 
                        HouseNumber: "", 
                        ObjectName: "",
                        GeoPoint: {
                            Latitude: billboard.geoPoint.latitude,
                            Longitude: billboard.geoPoint.longitude
                        }
                    },
                    GeoPoint: geoPoint
                }
                resultGeoObject.push(resultBrandGeoObject);
            }

            const geoObject: BrandGeoObjects = {
                GeoMarkId: brandGeoObject.GeoMarkId,
                GeoMarkName: brandGeoObject.Address ? brandGeoObject.Address.AddressString : '',
                Address: brandGeoObject.Address,
                GeoPoint: { 
                    Latitude: brandGeoObject.GeoPoint!.Latitude, 
                    Longitude: brandGeoObject.GeoPoint!.Longitude },
                Billboards: resultGeoObject
            }
            return geoObject;
        });
        brandGeoObjectsAction(brandMarkers);
    }, [currentBrandInfo, brandGeoObjects.length]);

    const deleteBrandClick = (brand: EmployeeBrandsInfo) => {
        setCurrentBrand(brand);
        setDeleteBrandOpen(true);
    }

    return (
        <>
            <TableContainer component={Paper} className="calculateDistance">
                <Table className="calculateDistance__table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Бренд</TableCell>
                            <TableCell align="left">Примечание</TableCell>
                            <TableCell align="center">Количество геообъектов</TableCell>
                            <TableCell align="center">Показать на карте геообъекты</TableCell>
                            <TableCell align="center">Показать список ближайших рекламных конструкций</TableCell>
                            {/* <TableCell align="center">Поиск/регистрация новых объектов</TableCell> */}
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterBrands.map(item => (
                            <TableRow key={item.brandId}>
                                <TableCell component="th" scope="row">
                                    {item.brandName}
                                </TableCell>
                                <TableCell align="left">
                                    {item.description}
                                </TableCell>
                                <TableCell align="center">
                                    {item.geoMarksCount}
                                </TableCell>
                                <TableCell align="center">
                                    <Button 
                                        color="primary"
                                        size="small"
                                        onClick={() => showMarkersClick(item.brandId)}>
                                        Показать
                                    </Button>
                                </TableCell>
                                <TableCell align="center">
                                    <Button 
                                        color="primary"
                                        size="small"
                                        onClick={() => showGeoObjectsClick(item)}>
                                        Показать
                                    </Button>
                                </TableCell>
                                {/* <TableCell align="center">
                                    <Button 
                                        color="primary"
                                        size="small"
                                        onClick={() => searchGeoObject(item)}>
                                        Поиск
                                    </Button>
                                </TableCell> */}
                                <TableCell>
                                    <Button 
                                        color="secondary"
                                        size="small"
                                        onClick={() => deleteBrandClick(item)}>
                                        Удалить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {currentBrand && 
                <DeleteBrandModal 
                    deleteBrandOpen={deleteBrandOpen} 
                    deleteBrandClose={deleteBrandClose} 
                    brand={currentBrand} />
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    brandsInfo: state.billboards.brandsInfo,
    brandGeoObjects: state.event.brandGeoObjects,
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    maxDistance: state.event.maxDistance,
    currentBrandInfo: state.billboards.currentBrandInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    currentBrandInfoAction: (currentBrandInfo: IBrandsInfo | null) => 
        dispatch(actions.billboards.currentBrandInfoAction(currentBrandInfo)),
    openNextBillboardAction: (openNextBillboard: boolean) => 
        dispatch(actions.event.openNextBillboardAction(openNextBillboard)),
    brandGeoObjectsAction: (brandGeoObjects: BrandGeoObjects[]) =>
        dispatch(actions.event.brandGeoObjectsAction(brandGeoObjects)),
    searchGeoObjectsToggleAction: (searchGeoObjectsToggle: boolean) => 
        dispatch(actions.event.searchGeoObjectsToggleAction(searchGeoObjectsToggle)),
    openBrandsCardAction: (openBrandsCard: boolean) => 
        dispatch(actions.billboards.openBrandsCardAction(openBrandsCard)),
});


export default connect(
    mapStateToProps,mapDispatchToProps
)(BrandsCardTable);