import React, { Dispatch } from "react";
import { connect } from "react-redux";
import { RootState, actions, selectors } from "../../store";
import ReactDataGrid from "react-data-grid";
import { registerLocale } from "react-datepicker";
import { withLocalize, LocalizeContextProps, Translate } from "react-localize-redux";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, Action } from "redux";
import { ConnectedClientModal } from "../../pages/ClientModal";
import Filter from "./components/Filter";
import DateAsString from "../bricks/DateAsString";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { ClientInfo, GetCustomersFilter } from 'sedi-webserverproxy';
import { mapStateToPropsLocalize } from "../../utils/store";


registerLocale("ru", ru);


type OwnProps = {
    onDoubleClickClient?: (clientInfo: ClientInfo) => void,
    componentID: string,
    allowAddNewCustomer: boolean,
    fixedFilter?: GetCustomersFilter
};


type Props = LocalizeContextProps
    & ReturnType<typeof mapDispatchToProps>
    & ReturnType<typeof mapStateToProps>
    & OwnProps;


type State = {
    showClientModal: boolean,
    clientInDetails?: ClientInfo,
    //rows: any
}


export class CustomersList extends React.Component<Props, State> {


    static defaultProps = {
        allowAddNewCustomer: true
    }


    constructor(props: Props) {
        super(props);

        this.state = {
            showClientModal: false,
            clientInDetails: undefined,
            //rows: this.props.foundCompanies
        }
    }


    navigateToclient(rowIndex: number) {
        const { filter, foundCompanies, foundCustomers, onDoubleClickClient } = this.props;

        const isLegalEntity = filter.isLegal === true;
        const customersToShow = isLegalEntity ? foundCompanies : foundCustomers;
        let clientInfo : ClientInfo;

        if (rowIndex >= 0 && rowIndex < customersToShow.length) {
            clientInfo = customersToShow[rowIndex];       
        }
        else  {
            clientInfo = new ClientInfo();
            clientInfo.isLegalEntity = isLegalEntity;
        }        

        if (onDoubleClickClient){
            onDoubleClickClient(clientInfo);
            return;
        }

        this.setState({clientInDetails: clientInfo, showClientModal: true});
    }

    componentDidMount() {
        const {componentID, filter} = this.props;
        this.props.updateFilterState(componentID, filter);
    }


    componentDidUpdate(prevProps: Props) {
        const { foundByInn } = this.props;

        if (foundByInn !== undefined && foundByInn !== prevProps.foundByInn) {            
            this.setState({clientInDetails: foundByInn, showClientModal: true});
        }        
    }


    handleClickSearch() {
        const { componentID, filter } = this.props;
        this.props.searchClients(componentID, filter);
    }

    reactDataGridHeight() {
        const windowHeight = window.innerHeight,
              filterHeight = document.querySelector('.customers-filter');
        if (filterHeight !== null) return windowHeight - filterHeight.clientHeight - 20;
    }

    render() {
        const { showClientModal, clientInDetails } = this.state;
        const { foundByInn, filter, foundCompanies, foundCustomers, componentID, allowAddNewCustomer, fixedFilter } = this.props;

        const customersToShow = filter.isLegal ? foundCompanies : foundCustomers;

        return (
            <div>
                <div className="mb-2">
                    <Filter
                        filter={filter}
                        disabled={this.props.isLoading}
                        onChange={(filter) => this.props.updateFilterState(componentID, filter)}
                        onClickSearch={() => this.handleClickSearch()}
                        fixedFilter={fixedFilter}
                    />
                </div>
                <div>
                    {customersToShow.length > 0 &&
                        //@ts-ignore
                        (<ReactDataGrid 
                            columns={columnsIsLegal(this.props.filter.isLegal)}
                            rowGetter={(i: number) => customersToShow[i]}
                            rowsCount={customersToShow.length} 
                            //@ts-ignore
                            onRowDoubleClick={this.navigateToclient.bind(this)}
                            minHeight={this.reactDataGridHeight()} 
                            // onGridSort={(sortColumn, sortDirection) =>
                            //     this.setState(sortRows(this.state.rows, sortColumn, sortDirection))
                            // }
                            />)
                    }
                </div>
                {
                    clientInDetails !== undefined &&
                    (<ConnectedClientModal
                        show={showClientModal}
                        clientInfo={clientInDetails}
                        clientInfoByInn={foundByInn}
                        onClose={() => this.setState({showClientModal: false})}
                    />)}
                
                { allowAddNewCustomer &&
                    <Fab color="primary" className="add-customers" onClick={() => this.navigateToclient(-1)}>
                        <AddIcon />
                    </Fab>
                }
            </div>
        );
    };
}


// const sortRows = (initialRows: any, sortColumn: string, sortDirection: "NONE" | "ASC" | "DESC") => (rows: any) => {
//     const comparer = (a: any, b: any): number => {
//       if (sortDirection === "ASC") {
//         return a[sortColumn] > b[sortColumn] ? 1 : -1;
//       } else if (sortDirection === "DESC") {
//         return a[sortColumn] < b[sortColumn] ? 1 : -1;
//       } 
//       return 0;
//     };

//     return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
// };

function columnsIsLegal(isLegal: boolean | null | undefined) {

    if(isLegal === true && isLegal !== null && isLegal !== undefined) {
        const columns: any = [
            { key: "id", name: "ID", resizable: true, width: 50, sortDescendingFirst: true },
            { key: "name", name: (<Translate id='CustomersListName' />), resizable: true },
            { key: "PhonesString", name: (<Translate id='CustomersListPhone' />), resizable: true },
            { key: "EmailsString", name: (<Translate id='CustomersListEmail' />), resizable: true },
            { key: "inn", name: (<Translate id='CustomersListINN' />), resizable: true },
            //{ key: "isVip", name: "IsVip", resizable: true, width: 50 },
            { key: "ordersCount", name: (<Translate id='CustomersListOrdersCount' />), resizable: true, width: 70 },
            { key: "registrationDate", name: (<Translate id='CustomersListRegistrationDate' />), resizable: true, formatter: DateFormatter },
        ];
        return columns
    } else {
        const columns: any = [
            { key: "id", name: "ID", resizable: true, width: 50 },
            { key: "name", name: (<Translate id='CustomersListName' />), resizable: true },
            { key: "PhonesString", name: (<Translate id='CustomersListPhone' />), resizable: true },
            { key: "EmailsString", name: (<Translate id='CustomersListEmail' />), resizable: true },
            { key: "ordersCount", name: (<Translate id='CustomersListOrdersCount' />), resizable: true, width: 70 },
            { key: "registrationDate", name: (<Translate id='CustomersListRegistrationDate' />), resizable: true, formatter: DateFormatter },
        ];
        return columns
    }
};

type GridValueFormatterProps = {
    value: any;
}


const DateFormatter = (props: GridValueFormatterProps) => {
    const value = props.value;

    if (value instanceof Date === false){
        return null;
    }

    const date = value as Date;
    if (date.getFullYear() < 2000){
        return null;
    }

    return (
        <DateAsString date={date} />
    );
};


const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
    ...mapStateToPropsLocalize(state),
    filter: selectors.customers.getFilter(state.customers, ownProps.componentID),
    foundCustomers: selectors.customers.getFoundCustomers(state.customers, ownProps.componentID),
    foundCompanies: selectors.customers.getFoundCompanies(state.customers, ownProps.componentID),
    companies: state.customers.companies,
    isLoading: selectors.customers.getIsLoading(state.customers, ownProps.componentID),
    foundByInn: selectors.customers.getFoundByInn(state.customers, ownProps.componentID)
});


const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    updateFilterState: (componentID: string, filter: GetCustomersFilter) => dispatch(actions.customers.updateCustomerFiltersAction(componentID, filter)),
    searchClients: (componentID: string, filter: GetCustomersFilter) => dispatch(actions.customers.searchCustomersActionCreator(componentID, filter)),
});


export default withLocalize(connect(
    mapStateToProps, mapDispatchToProps
)(CustomersList));