import React from 'react';
import { 
    FormControl, 
    Input, 
    InputAdornment, 
    Tooltip 
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

type Props = {
    value: string | number;
    name: string;
    nextName: string;
    changeInputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setNextName: (value: string) => void;
    keyPress: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    allCopyClick: () => void;
    onlyCopyClick: () => void;
}

function TariffFormControl(props: Props) {
    const { 
        value, 
        name, 
        changeInputHandler, 
        setNextName, 
        keyPress, 
        allCopyClick, 
        onlyCopyClick, 
        nextName 
    } = props;

    return (
        <FormControl className="tariffFormControl">
            <Input
                type="text"
                value={value}
                name={name}
                onChange={changeInputHandler}
                onClick={() => setNextName(nextName)}
                onKeyDown={keyPress}
                endAdornment={
                    <InputAdornment position="end" className="copyRow">
                        <Tooltip 
                            title={
                                <div>
                                    <div>Скопировать для всех</div>
                                    <div>Горячая клавиша Ctrl+B</div>
                                </div>
                            } 
                            placement="top"
                        >
                            <FileCopyIcon 
                                fontSize="small" 
                                onClick={allCopyClick} />
                        </Tooltip>
                        <Tooltip 
                            title={
                                <div>
                                    <div>Скопировать для одного</div>
                                    <div>Горячая клавиша Ctrl+X</div>
                                </div>
                            } 
                            placement="top"
                        >
                            <InsertDriveFileIcon 
                                fontSize="small"
                                onClick={onlyCopyClick} />
                        </Tooltip>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}

export default TariffFormControl;