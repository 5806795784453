import store from "../store";
import { EventItem, EventsHandler, EventsQueue } from "./EventsQueue";
import * as eventActions from '../store/event/actions';
import { 
    AutocompleteType, 
    GetAutocompleteList, 
    GetProjectAdditionalCostParameters, 
    IAdditionalCostParameters, 
    StatisticFilter, 
    SalesPaymentStatisticFilter, 
    SalesPaymentStatisticResult, 
    SalesPaymentAccountStatistic, 
    ProjectInfo, 
    StatisticRegionFilter, 
    SalesPaymentRegionStatistic, 
    GetBillboardInfo,
    BillboardUsageFilter,
    IEventGetBillBoardsUsageInfo,
    GetBillBoardsUsageInfo,
    EmployeeFilter,
    GetEmployeesShortInfo,
    Period,
    GetEmployeeDetailedInfo,
    IDetailedEmployeeInfo,
    GeoMarkTariffsParams,
    GetGeoMarkTariffsType,
    ChangeProjectParams,
    ChangedCommercialOfferParmas,
    GetProjectParmas,
    GetTariffParameterConditions,
    AdditionalGeoMarkTariffs,
    EventGetBillboardsShortInfo,
    BillboardFilters,
    GeoMarksFilter,
    EventGeoMarks,
    FullMonthStatistic,
    CreateProjectParams,
    RequestProjectBooking,
    PaymentBillsFilter,
    PaymentBillInfoType,
    BillboardsPhotoControlType
} from "./type";
import { GeoMarkTimeStatus, LatLong } from "../WebServerProxy";
import { GetGeoMarkTariff } from "../store/billboards/types";

export interface EventError {
    errorMessage: string;
    errorCode: string;
    errorParam?: any;
}

export interface EventUserIdentificationAnswer {
    diviceId: string;
}
export interface EventEventImportBrands {
    executionPercent: number;
    geoMarks: any;
}

export interface IEventGeneratePaymentBill {
    fileFormat: string;
    fileBytes: any;
}

export interface IEventSavePaymentBill {
    invoiceFileType: any;
    invoiceFileBytes: any;
    actOfPerformanceFileType: any;
    actOfPerformanceFileBytes: any;
}

export interface IEventGetPaymentBillDocument {
    documentImage: any;
}

export interface EventGetSalesPaymentStatistic {
    salesPaymentStatisticResult: SalesPaymentStatisticResult;
}

export type GeoMarkParams = {
    geoMarkId: number;
    geoMarkName: string;
    objectId: string;
    geoMarkTypeId: number;
    address?: Address;
    geoMarkStatus?: string;
    geoPoint?: LatLong
}

export type Address = {
    id?: number,
    countryName?: string,
    cityName?: string,
    localityName?: string,
    postalCode?: string,
    streetName?: string,
    objectName?: string,
    houseNumber?: string,
    apartmentNumber?: string,
    entranceNumber?: number,
    geoPoint?: LatLong,
    coordinatesOnly?: boolean,
    precision?: string,
    addressString?: string,
    areaDistrictName?: string,
    areaName?: string
}

export type EventSaveGeomarkResult = {
    geoMarkId: number;
    eventKey: string;
}
export interface IEventGetPropertySet {
    propertySet: IPropertySet
}

export interface IPropertySet {
    propertySetId: number;
    propertySetName: string;
    properties: IProperties[];
}
export interface IProperties {
    propertyId: number;
    propertyName: string;
    localizedName: string;
    propertyRequired: boolean;
    selectedValueId: number;
    values: IPropertyValues[];
};
export interface IPropertyValues {
    value: string;
    valueId: number;
};

export type PropertyValues = {
    PropertyId: number, 
    PropertyValueId: number
}

export interface EventEventImportProjectSales {
    executionPercent: number;
    resultText: string;
};

export class SystemEvent {
    public static EventUserIdentification(userIdentificationKey: string, email: boolean) {
        const eventName = "EventUserIdentification";
        const eventParameters = {
            "UserIdentificationKey": userIdentificationKey,
            "IdentificationKeyType": email ? "email" : "phone",
            "DeviceId": "62315621365123651652"
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventImportBrands(base64: any) {
        const eventName = "EventImportBrands";
        const eventParameters = {
            "ExcelFileBytes": base64,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGenerateBillboardsFullExcelFile(period: Period, region?: string) {
        const eventName = "EventGenerateBillboardsFullExcelFile";
        const eventParameters = {
            "Period": period,
            "Region": region
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetSalesPaymentStatistic(
        salesPaymentStatisticFilter: SalesPaymentStatisticFilter,
        salesPaymentGrouping?: string
    ) {
        const eventName = "EventGetSalesPaymentStatistic";
        const eventParameters = {
            "SalesPaymentStatisticFilter": salesPaymentStatisticFilter,
            "SalesPaymentGrouping": salesPaymentGrouping
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetProjectAdditionalCostParameters(projectId: number) {
        const eventName = "EventGetProjectAdditionalCostParameters";
        const eventParameters = {
            "ProjectId": projectId,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSaveProjectAdditionalCosts(projectId: number, additionalCostParameters: IAdditionalCostParameters[]) {
        const eventName = "EventSaveProjectAdditionalCosts";
        const eventParameters = {
            "ProjectId": projectId,
            "AdditionalCostParameters": additionalCostParameters,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGeneratePaymentBill(projectId: number) {
        const eventName = "EventGeneratePaymentBill";
        const eventParameters = {
            "ProjectId": projectId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSavePaymentBill(projectId: number) {
        const eventName = "EventSavePaymentBill";
        const eventParameters = {
            "ProjectId": projectId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetPaymentBillDocument(paymentBillId: number) {
        const eventName = "EventGetPaymentBillDocument";
        const eventParameters = {
            "PaymentBillId": paymentBillId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSaveGeoMark(geoMarkParams: GeoMarkParams): string {
        const eventName = "EventSaveGeoMark";
        const eventParameters = {
            "GeoMarkId": geoMarkParams.geoMarkId,
            "GeoMarkTypeId": geoMarkParams.geoMarkTypeId,
            "GeoMarkName": geoMarkParams.geoMarkName,
            "Address": geoMarkParams.address,
            "GeoMarkStatus": geoMarkParams.geoMarkStatus,
            "ObjectId": geoMarkParams.objectId,
            "GeoPoint": geoMarkParams.geoPoint,
        };
        return EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetPropertySet(lang: string) {
        const eventName = "EventGetPropertySet";
        const eventParameters = {
            "PropertySetName": "AdvertisingServices",
            "Language": lang
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSaveGeomarkProperties(geoMarkId: number, propertyValues: PropertyValues[], billboardSide: string) {
        const eventName = "EventSaveGeomarkProperties";
        const eventParameters = {
            "GeoMarkId": geoMarkId,
            "PropertyValues": propertyValues,
            "BillboardSide": billboardSide
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetAutocompleteList(text: string, type: AutocompleteType) {
        const eventName = "EventGetAutocompleteList";
        const eventParameters = {
            "Text": text,
            "AutocompleteType": type
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetSalesPaymentAccountStatistic(statisticFilter: StatisticFilter) {
        const eventName = "EventGetSalesPaymentAccountStatistic";
        const eventParameters = {
            "SalesPaymentStatisticFilter": statisticFilter,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetSalesPaymentRegionStatistic(statisticFilter: StatisticRegionFilter) {
        const eventName = "EventGetSalesPaymentRegionStatistic";
        const eventParameters = {
            "SalesPaymentStatisticFilter": statisticFilter,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetProjectInfo(projectId: number, language?: string) {
        const eventName = "EventGetProjectInfo";
        const eventParameters = {
            "ProjectId": projectId,
            "Language": language,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSetGeoMarkTimeStatus(geomarkTimeStatusParameters: GeoMarkTimeStatus[], useAllFreeGeoMarkParts: boolean) {
        const eventName = "EventSetGeoMarkTimeStatus";
        const eventParameters = {
            "GeomarkTimeStatusParameters": geomarkTimeStatusParameters,
            "UseAllFreeGeoMarkParts": useAllFreeGeoMarkParts
        };
        console.log(eventName, eventParameters);
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetBillboardInfo(geoMarkId: number, dateStart: string, dateEnd: string) {
        const eventName = "EventGetBillboardInfo";
        const eventParameters = {
            "GeoMarkId": geoMarkId,
            "DateStart": dateStart,
            "DateEnd": dateEnd,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetBillBoardsUsageInfo(billboardUsageFilter: BillboardUsageFilter, isFixedSum: boolean) {
        const eventName = "EventGetBillBoardsUsageInfo";
        const eventParameters = {
            "BillboardUsageFilter": billboardUsageFilter,
            "IsFixedSum": isFixedSum
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetBillBoardDetailedUsageInfo(geoMarkId: number, period: SalesPaymentStatisticFilter, customerAccountIds?: number[]) {
        const eventName = "EventGetBillBoardDetailedUsageInfo";
        const eventParameters = {
            "GeoMarkId": geoMarkId,
            "Period": period,
            "CustomerAccountIds": customerAccountIds
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetGeoMarkCustomers(geoMarkId: number, period: SalesPaymentStatisticFilter) {
        const eventName = "EventGetGeoMarkCustomers";
        const eventParameters = {
            "GeoMarkId": geoMarkId,
            "Period": period
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetEmployeesShortInfo(employeeFilter: EmployeeFilter) {
        const eventName = "EventGetEmployeesShortInfo";
        const eventParameters = {
            "EmployeeFilter": employeeFilter
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetEmployeeDetailedInfo(employeeId: number) {
        const eventName = "EventGetEmployeeDetailedInfo";
        const eventParameters = {
            "EmployeeId": employeeId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventChangeEmployeeData(employeeData: IDetailedEmployeeInfo) {
        const eventName = "EventChangeEmployeeData";
        const eventParameters = {
            "EmployeeData": employeeData
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetGeoMarkTariffs(payload: GeoMarkTariffsParams) {
        const eventName = "EventGetGeoMarkTariffs";
        const eventParameters = {
            "GeoMarkIds": payload.geoMarkIds,
            "OwnerAccountId": payload.ownerAccountId,
            "BrandId": payload.brandId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSetGeoMarkTariffs(payload: GetGeoMarkTariff) {
        const eventName = "EventSetGeoMarkTariffs";
        const eventParameters = {
            "GeoMarkTariffs": payload
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventChangeProject(payload: ChangeProjectParams) {
        const eventName = "EventChangeProject";
        const eventParameters = {
            "ProjectId": payload.projectId,
            "ProjectName": payload.projectName,
            "ProjectStatus": payload.projectStatus,
            "ProjectDescription": payload.projectDescription,
            "BrandId": payload.brandId,
            "CreatorAccountId": payload.creatorAccountId,
            "ControlDateTime": payload.controlDateTime
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventChangedCommercialOffer(payload: ChangedCommercialOfferParmas) {
        const eventName = "EventChangedCommercialOffer";
        const eventParameters = {
            "OfferId": payload.offerId,
            "ProjectId": payload.projectId,
            "FinancialDiscounts": payload.financialDiscounts,
            "BoardCostInfo": payload.boardCostInfo,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetProjects(payload: GetProjectParmas) {
        const eventName = "EventGetProjects";
        const eventParameters = {
            "ProjectType": payload.projectType,
            "DateStart": payload.dateStart,
            "DateEnd": payload.dateEnd,
            "ProjectStatus": payload.projectStatus,
            "BrandId": payload.brandId,
            "CreatorAccountId": payload.creatorAccountId,
            "OwnerAccountId": payload.ownerAccountId,
            "Language": payload.language,
            "ProjectIds": payload.projectIds
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSetBillboardPhoto(geoMarkId: number, billboardImageType: string, fileBytes: any) {
        const eventName = "EventSetBillboardPhoto";
        const eventParameters = {
            "GeoMarkId": geoMarkId,
            "BillboardImageType": billboardImageType,
            "FileBytes": fileBytes
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetAgencies(agencyIds: number[]) {
        const eventName = "EventGetAgencies";
        const eventParameters = {
            "AgencyIds": agencyIds
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetProjectFilters(language: string) {
        const eventName = "EventGetProjectFilters";
        const eventParameters = {
            "Language": language
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetTariffParameterConditions(tariffType: string) {
        const eventName = "EventGetTariffParameterConditions";
        const eventParameters = {
            "TariffType": tariffType
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetAdditionalGeoMarkTariffs() {
        const eventName = "EventGetAdditionalGeoMarkTariffs";
        const eventParameters = {};
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventChangeAdditionalGeoMarkTariff(additionalGeoMarkTariff: AdditionalGeoMarkTariffs) {
        const eventName = "EventChangeAdditionalGeoMarkTariff";
        const eventParameters = {
            "AdditionalGeoMarkTariff": additionalGeoMarkTariff
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventDeleteAdditionalGeoMarkTariff(tariffParameterId: number) {
        const eventName = "EventDeleteAdditionalGeoMarkTariff";
        const eventParameters = {
            "TariffParameterId": tariffParameterId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetBillboardsShortInfo(billboardFilters: BillboardFilters | undefined): string {
        const eventName = "EventGetBillboardsShortInfo";
        const eventParameters = {
            "BillboardFilters": billboardFilters
        };
        return EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetGeoMarkFilters() {
        const eventName = "EventGetGeoMarkFilters";
        const eventParameters = {};
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetGeoMarks(geoMarksFilter: GeoMarksFilter) {
        const eventName = "EventGetGeoMarks";
        const eventParameters = {
            "GeoMarksFilter": geoMarksFilter
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetFullMonthStatistic(year: number, month: number) {
        const eventName = "EventGetFullMonthStatistic";
        const eventParameters = {
            "Year": year,
            "Month": month
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetSalesPaymentGidDetailedStatistic(statisticFilter: StatisticFilter) {
        const eventName = "EventGetSalesPaymentGidDetailedStatistic";
        const eventParameters = {
            "SalesPaymentStatisticFilter": statisticFilter
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGenerateSalesPaymentGidDetailedStatisticFile(statisticFilter: StatisticFilter) {
        const eventName = "EventGenerateSalesPaymentGidDetailedStatisticFile";
        const eventParameters = {
            "SalesPaymentStatisticFilter": statisticFilter
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventCreateProject(payload: CreateProjectParams) {
        const eventName = "EventCreateProject";
        const eventParameters = {
            "ProjectOwnerAccountId": payload.projectOwnerAccountId,
            "ProjectName": payload.projectName,
            "BrandId": payload.brandId,
            "DateStart": payload.dateStart,
            "DateEnd": payload.dateEnd
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGenerateCommercialOfferExcelFile(projectId: number) {
        const eventName = "EventGenerateCommercialOfferExcelFile";
        const eventParameters = {
            "ProjectId": projectId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetGeoMarkImageTypes() {
        const eventName = "EventGetGeoMarkImageTypes";
        const eventParameters = {};
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventRequestProjectBooking(payload: RequestProjectBooking) {
        const eventName = "EventRequestProjectBooking";
        const eventParameters = {
            "ProjectId": payload.projectId,
            "BookingEndTime": payload.bookingEndTime,
            "ControlDateTime": payload.controlDateTime,
            "MessageSendingWay": payload.messageSendingWay
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetPaymentBills(paymentBillsFilter: PaymentBillsFilter) {
        const eventName = "EventGetPaymentBills";
        const eventParameters = {
            "PaymentBillsFilter": paymentBillsFilter
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    // Подписаться на события
    public static SubscribeEventRequestProjectBooking(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventRequestProjectBooking", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventProjectEditingTimeExceeded(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventProjectEditingTimeExceeded", pageName, (event: EventItem) => {
            if(event.eventType === "Retranslate") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetGeoMarkImageTypes(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventGetGeoMarkImageTypes", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const geoMarkImageTypes = event.eventParameters["geoMarkImageTypes"];
                callback({ 
                    geoMarkImageTypes
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGenerateCommercialOfferExcelFile(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventGenerateCommercialOfferExcelFile", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const excelFileBytes = event.eventParameters["excelFileBytes"];
                const executionPercent = event.eventParameters["executionPercent"];
                callback({ 
                    excelFileBytes,
                    executionPercent
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventCreateProject(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventCreateProject", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const project = event.eventParameters["project"];
                callback({ 
                    project
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetSalesPaymentGidDetailedStatistic(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventGetSalesPaymentGidDetailedStatistic", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const salesPaymentGidDetailedStatistic = event.eventParameters["salesPaymentGidDetailedStatistic"];
                callback({ 
                    salesPaymentGidDetailedStatistic
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGenerateSalesPaymentGidDetailedStatisticFile(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventGenerateSalesPaymentGidDetailedStatisticFile", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const excelFileBytes = event.eventParameters["excelFileBytes"];
                const executionPercent = event.eventParameters["executionPercent"];
                callback({ 
                    excelFileBytes,
                    executionPercent
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetFullMonthStatistic(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventGetFullMonthStatistic", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const fullMonthStatistic = event.eventParameters["fullMonthStatistic"] as FullMonthStatistic;
                callback({ 
                    fullMonthStatistic
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetGeoMarks(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventGetGeoMarks", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const geoMarks = event.eventParameters["geoMarks"] as EventGeoMarks;
                callback({ 
                    geoMarks
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetGeoMarkFilters(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventGetGeoMarkFilters", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const geoMarkFilters = event.eventParameters["geoMarkFilters"];
                callback({ 
                    geoMarkFilters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetBillboardsShortInfo(
        pageName: string, 
        callback: (answer: EventGetBillboardsShortInfo) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventGetBillboardsShortInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const billboards = event.eventParameters["billboards"];
                const eventKey = event.eventKey
                callback({ 
                    billboards,
                    eventKey
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventDeleteAdditionalGeoMarkTariff(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventDeleteAdditionalGeoMarkTariff", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const eventParameters = event.eventParameters;
                callback({ 
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventChangeAdditionalGeoMarkTariff(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventChangeAdditionalGeoMarkTariff", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const eventParameters  = event.eventParameters;
                callback({ 
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventUserIdentification(
        pageName: string, 
        callback: (answer: EventUserIdentificationAnswer) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventUserIdentification", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                callback({ 
                    diviceId: "555666787889"
                });
            } else {
                const errorMessage = event.eventParameters["ErrorMessage"];
                const errorCode = event.eventParameters["ErrorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventImportBrands(
        pageName: string, 
        callback: (answer: EventEventImportBrands) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventImportBrands", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const executionPercent = event.eventParameters["executionPercent"];
                const geoMarks = event.eventParameters["geoMarks"];
                callback({ 
                    executionPercent,
                    geoMarks
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetProjectAdditionalCostParameters(
        pageName: string, 
        callback: (answer: GetProjectAdditionalCostParameters) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetProjectAdditionalCostParameters", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const additionalCostNotSaved = event.eventParameters["additionalCostNotSaved"];
                const additionalCostParameters = event.eventParameters["additionalCostParameters"];
                callback({
                    additionalCostNotSaved,
                    additionalCostParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetSalesPaymentStatistic(
        pageName: string, 
        callback: (answer: EventGetSalesPaymentStatistic) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetSalesPaymentStatistic", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const salesPaymentStatisticResult = event.eventParameters["salesPaymentStatisticResult"];
                callback({ 
                    salesPaymentStatisticResult
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGeneratePaymentBill(
        pageName: string, 
        callback: (answer: IEventGeneratePaymentBill) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGeneratePaymentBill", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const fileBytes = event.eventParameters["fileBytes"];
                const fileFormat = event.eventParameters["fileFormat"];
                callback({
                    fileBytes,
                    fileFormat
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventSavePaymentBill(
        pageName: string, 
        callback: (answer: IEventSavePaymentBill) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSavePaymentBill", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const invoiceFileType = event.eventParameters["invoiceFileType"];
                const invoiceFileBytes = event.eventParameters["invoiceFileBytes"];
                const actOfPerformanceFileType = event.eventParameters["actOfPerformanceFileType"];
                const actOfPerformanceFileBytes = event.eventParameters["actOfPerformanceFileBytes"];
                callback({
                    invoiceFileType,
                    invoiceFileBytes,
                    actOfPerformanceFileType,
                    actOfPerformanceFileBytes
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetPaymentBillDocument(
        pageName: string, 
        callback: (answer: IEventGetPaymentBillDocument) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetPaymentBillDocument", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const documentImage = event.eventParameters["documentImage"];
                callback({
                    documentImage
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventSaveGeoMark(
        pageName: string, 
        callback: (answer: EventSaveGeomarkResult) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSaveGeoMark", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const geoMarkId = event.eventParameters["geoMarkId"];
                const eventKey = event.eventKey;
                callback({ 
                    geoMarkId,
                    eventKey
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetPropertySet(
        pageName: string, 
        callback: (answer: IEventGetPropertySet) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetPropertySet", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const propertySet = event.eventParameters["propertySet"];
                callback({ 
                    propertySet
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventSaveGeomarkProperties(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSaveGeomarkProperties", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const propertyValueSetId = event.eventParameters["propertyValueSetId"];
                callback({ 
                    propertyValueSetId
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetAutocompleteList(
        pageName: string, 
        callback: (answer: GetAutocompleteList) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetAutocompleteList", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const autocompleteResult = event.eventParameters["autocompleteResult"];
                callback({
                    autocompleteResult
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetSalesPaymentAccountStatistic(
        pageName: string, 
        callback: (answer: SalesPaymentAccountStatistic) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetSalesPaymentAccountStatistic", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const salesPaymentAccountStatisticResult = event.eventParameters["salesPaymentAccountStatisticResult"];
                callback({
                    salesPaymentAccountStatisticResult
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetSalesPaymentRegionStatistic(
        pageName: string, 
        callback: (answer: SalesPaymentRegionStatistic) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetSalesPaymentRegionStatistic", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const salesPaymentRegionStatisticResult = event.eventParameters["salesPaymentRegionStatisticResult"];
                callback({
                    salesPaymentRegionStatisticResult
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetProjectInfo(
        pageName: string, 
        callback: (answer: ProjectInfo) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetProjectInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const projectInfo = event.eventParameters["projectInfo"];
                callback(projectInfo);
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventSetGeoMarkTimeStatus(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSetGeoMarkTimeStatus", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const substituteBillboardSide = event.eventParameters["SubstituteBillboardSide"];
                callback(substituteBillboardSide);
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventSaveProjectAdditionalCosts(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSaveProjectAdditionalCosts", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const additionalCost = event.eventParameters["additionalCost"];
                callback(additionalCost);
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetBillboardInfo(
        pageName: string, 
        callback: (answer: GetBillboardInfo) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetBillboardInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const name  = event.eventParameters["name"];
                const address  = event.eventParameters["address"];
                const side1Properties  = event.eventParameters["side1Properties"];
                const side2Properties  = event.eventParameters["side2Properties"];
                const statuses  = event.eventParameters["statuses"];
                const uploadedPhotos  = event.eventParameters["uploadedPhotos"];
                const status = event.eventParameters["status"];
                callback({
                    name,
                    address,
                    side1Properties,
                    side2Properties,
                    statuses,
                    uploadedPhotos,
                    status
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetBillBoardsUsageInfo(
        pageName: string, 
        callback: (answer: IEventGetBillBoardsUsageInfo) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetBillBoardsUsageInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const billboardUsageFilter  = event.eventParameters["billboardUsageFilter"];
                const billboardsUsageInfoResult  = event.eventParameters["billboardsUsageInfoResult"];
                const billboardsAverageMonthUsage  = event.eventParameters["billboardsAverageMonthUsage"];
                const totalData  = event.eventParameters["totalData"];
                callback({
                    billboardUsageFilter,
                    billboardsUsageInfoResult,
                    billboardsAverageMonthUsage,
                    totalData
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetBillBoardDetailedUsageInfo(
        pageName: string, 
        callback: (answer: GetBillBoardsUsageInfo) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetBillBoardDetailedUsageInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const billboardDetailedUsageResult  = event.eventParameters["billboardDetailedUsageResult"];
                callback({
                    billboardDetailedUsageResult
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetGeoMarkCustomers(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetGeoMarkCustomers", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const customers  = event.eventParameters["customers"];
                callback({
                    customers
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGenerateBillboardsFullExcelFile(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGenerateBillboardsFullExcelFile", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const excelFileBytes  = event.eventParameters["excelFileBytes"];
                const executionPercent  = event.eventParameters["executionPercent"];
                callback({
                    excelFileBytes,
                    executionPercent
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetEmployeesShortInfo(
        pageName: string, 
        callback: (answer: GetEmployeesShortInfo) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetEmployeesShortInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const shortEmployeesInfo  = event.eventParameters["shortEmployeesInfo"];
                callback({
                    shortEmployeesInfo
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetEmployeeDetailedInfo(
        pageName: string, 
        callback: (answer: GetEmployeeDetailedInfo) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetEmployeeDetailedInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const detailedEmployeeInfo  = event.eventParameters["detailedEmployeeInfo"];
                callback({
                    detailedEmployeeInfo
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventChangeEmployeeData(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventChangeEmployeeData", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const employeeId  = event.eventParameters["employeeId"];
                callback({
                    employeeId
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetGeoMarkTariffs(
        pageName: string, 
        callback: (answer: GetGeoMarkTariffsType) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetGeoMarkTariffs", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const geoMarkTariffs  = event.eventParameters["geoMarkTariffs"];
                callback({
                    geoMarkTariffs
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventSetGeoMarkTariffs(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSetGeoMarkTariffs", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventChangeProject(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventChangeProject", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                const errorParam = event.eventParameters["geoMarkGid"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                    errorParam
                });
            }
        });
    }
    public static SubscribeEventChangedCommercialOffer(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventChangedCommercialOffer", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const offerId  = event.eventParameters["offerId"];
                const offerTotalSumText  = event.eventParameters["offerTotalSumText"];
                const costWithDiscounts  = event.eventParameters["costWithDiscounts"];
                callback({
                    offerId,
                    offerTotalSumText,
                    costWithDiscounts
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                const errorParam = event.eventParameters["geoMarkGid"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                    errorParam
                });
            }
        });
    }
    public static SubscribeEventGetProjects(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetProjects", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const projectsInfo  = event.eventParameters["projectsInfo"];
                callback({
                    projectsInfo
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode
                });
            }
        });
    }
    public static SubscribeEventSetBillboardPhoto(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSetBillboardPhoto", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode
                });
            }
        });
    }

    public static SubscribeEventGetAgencies(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetAgencies", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const agencyInfo  = event.eventParameters["agencyInfo"];
                callback({
                    agencyInfo
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode
                });
            }
        });
    }

    public static SubscribeEventGetProjectFilters(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetProjectFilters", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const projectFilters  = event.eventParameters["projectFilters"];
                callback({
                    projectFilters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode
                });
            }
        });
    }

    public static SubscribeEventGetTariffParameterConditions(
        pageName: string, 
        callback: (answer: GetTariffParameterConditions) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetTariffParameterConditions", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const tariffParameterConditions  = event.eventParameters["tariffParameterConditions"];
                callback({
                    tariffParameterConditions
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode
                });
            }
        });
    }

    public static SubscribeEventGetAdditionalGeoMarkTariffs(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetAdditionalGeoMarkTariffs", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const additionalGeoMarkTariffs  = event.eventParameters["additionalGeoMarkTariffs"];
                callback({
                    additionalGeoMarkTariffs
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode
                });
            }
        });
    }

    public static SubscribeEventGetPaymentBills(
        pageName: string, 
        callback: (answer: PaymentBillInfoType) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetPaymentBills", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const paymentBills  = event.eventParameters["paymentBills"];
                callback({
                    paymentBills
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static EventImportProjectSales(year: number, month: number, base64: any) {
        const eventName = "EventImportProjectSales";
        const eventParameters = {
            "Year": year,
            "Month": month,
            "ExcelFileBytes": base64,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static SubscribeEventImportProjectSales(
        pageName: string, 
        callback: (answer: EventEventImportProjectSales) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventImportProjectSales", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const executionPercent = event.eventParameters["executionPercent"];
                const resultText = event.eventParameters["resultText"];
                callback({ 
                    executionPercent,
                    resultText
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }
    
    public static EventGetGeoMarkPhotoControlInfo(period: Period, employeeId: number | undefined) {
        const eventName = "EventGetGeoMarkPhotoControlInfo";
        const eventParameters = {
            "Period": period,
            "EmployeeId": employeeId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static SubscribeEventGetGeoMarkPhotoControlInfo(
        pageName: string, 
        callback: (answer: BillboardsPhotoControlType) => void, 
        callbackError: (error: EventError) => void
    ) {
        EventsHandler.Subscribe("EventGetGeoMarkPhotoControlInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer") {
                const billBoardsPhotoControl = event.eventParameters["billBoardsPhotoControl"];
                callback({ 
                    billBoardsPhotoControl
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }
}

function subscribeError(event: EventItem, callbackError: (error: EventError) => void) {
    const errorMessage = event.eventParameters["ErrorMessage"];
    const errorCode = event.eventParameters["ErrorCode"];
    callbackError({ 
        errorMessage,
        errorCode,
    });
}

export function errorCallback(error: EventError) {
    console.log('error:', error);
    store.dispatch(eventActions.eventErrorAction(error));
}
