import { FormControl, InputAdornment, OutlinedInput } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Discount } from 'sedi-webserverproxy/dist/controllers/webclient/types/CommercialOffer';

type Props = {
    currentDiscount: Discount;
    discountChange: (currentDiscount: Discount) => void;
    discountIndex: number[];
    index: number;
    typePercent: boolean;
    error: boolean;
    typeDiscountChange: (percent: boolean) => void;
    discountOpen: boolean;
}


const DiscountFormControl:React.FC<Props> = (props) => {
    const { currentDiscount, discountChange, discountIndex, index, typePercent, error, typeDiscountChange, discountOpen } = props;
    const [, updateState] = React.useState();
    //@ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const discountCurrentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(typePercent) {
            currentDiscount.discountPercent = event.target.value !== '' ? parseInt(event.target.value) : null;
            currentDiscount.fixedSum = 0;
        } else {
            currentDiscount.fixedSum = parseInt(event.target.value);
            currentDiscount.discountPercent = 0;
        }
        discountChange(currentDiscount);
        forceUpdate();
    };

    useEffect(() => {
        if(currentDiscount.fixedSum > 0) {
            typeDiscountChange(false)
        }
        if(currentDiscount.discountPercent && currentDiscount.discountPercent > 0) {
            typeDiscountChange(true)
        }
    }, [discountOpen]);

    return (
        <FormControl 
            fullWidth 
            variant="outlined" 
            size="small" 
            error={error && discountIndex.some(i => i === index )}>
            <OutlinedInput
                value={typePercent ? currentDiscount.discountPercent : currentDiscount.fixedSum}
                name="discount"
                onChange={discountCurrentChange}
                endAdornment={
                    <InputAdornment position="end">
                        {typePercent ? '%' : 'p'}
                    </InputAdornment>
                }
                labelWidth={0}
                type="number"
            />
        </FormControl>
    );
}

export default DiscountFormControl;