import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Tooltip } from '@material-ui/core';
import { calculateDistance, sortByDistance } from '../../utils/calculateDistance';
import DeleteGeoObjectModal from './DeleteGeoObjectModal';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { BrandGeoObjects, IGeoObjectBillboards } from '../../store/event/types';
import { IMultipleMarker } from '../map/types';
import { randomInteger } from '../../utils/randomInteger';
import { DatesRange } from './InputDatesRange';
import { onlyUnique } from '../../utils/onlyUnique';
import NextBillboardSideTooltip from './NextBillboardSideTooltip';
import { GeoMarkTimeStatus, ProjectGeoMarkStatus } from 'sedi-webserverproxy';
import { getHighlightColor } from '../../utils/statusesColor';
import EditGeomarkName from './EditGeomarkName';
import { GeoObjectType } from '../../store/event/types';
import { BillboardsShortInfo } from '../../RemoteCommands/type';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    dates: DatesRange;
    selectedBrandMarker: (brandMarkers: IMultipleMarker[], brandGeoObject: BrandGeoObjects) => void;
    openNextBillboard: boolean;
}

const NextBillboardTable:React.FC<Props> = (props) => {
    const { 
        billboardShortAction,
        getAdditionalBillboardAction,
        showMarkersAction,
        brandGeoObjects,
        currentBrandInfo,
        newBrandGeoObjects,
        selectedBrandMarker,
        nextBillboardStatusesAction,
        nextBillboardStatuses,
        dates,
        maxDistanceSearch,
        openNextBillboard,
        addressValueAction,
        geoPointNullAction,
        brandGeoObjectsAction,
        billboardsShortInfo
    } = props;

    const [deleteGeoObjectOpen, setDeleteGeoObjectOpen] = useState(false);
    const [brandGeoObject, setBrandGeoObject] = useState<BrandGeoObjects | null>(null);
    const [allGeoObjectBillboards, setAllGeoObjectBillboards] = useState<IGeoObjectBillboards[]>([]);

    const deleteGeoObjectClose = () => {
        setDeleteGeoObjectOpen(false);
    }

    const openBillboardClick = (billboard: IGeoObjectBillboards) => () => {
        const findBillboard = billboardsShortInfo.find(item => item.geoMarkId === billboard.GeoMarkId);
        if(findBillboard) {
            billboardShortAction(findBillboard);
            getAdditionalBillboardAction(findBillboard.geoMarkId);
        }
    }

    const showMarkersClick = (brandGeoObject: BrandGeoObjects) => {
        if(brandGeoObject.GeoPoint) {
            const brandMarker: IMultipleMarker = {
                id: randomInteger(1, 1000),
                address: brandGeoObject.Address,
                center: {
                    lat: brandGeoObject.GeoPoint.Latitude,
                    lng: brandGeoObject.GeoPoint.Longitude,
                },
                marker: null
            }
            selectedBrandMarker([brandMarker], brandGeoObject);
            showMarkersAction(true);
        }
    }

    brandGeoObjects.map(brandGeoObject => {
        if(brandGeoObject.Billboards && brandGeoObject.GeoPoint) {
            return sortByDistance(brandGeoObject.Billboards, brandGeoObject.GeoPoint)
        }
    });

    newBrandGeoObjects.map(brandGeoObject => {
        if(brandGeoObject.Billboards && brandGeoObject.GeoPoint) {
            return sortByDistance(brandGeoObject.Billboards, brandGeoObject.GeoPoint)
        }
    });

    const deleteGeoObject = (brandGeoObject: BrandGeoObjects) => {
        setBrandGeoObject(brandGeoObject);
        setDeleteGeoObjectOpen(true);
    }

    const deleteMarkerClick = (brandGeoObject: BrandGeoObjects) => {
        const currentGeoObject = brandGeoObjects.find(item => item.GeoMarkId === brandGeoObject.GeoMarkId);
        const currentNewGeoObject = newBrandGeoObjects.find(item => item.GeoMarkId === brandGeoObject.GeoMarkId);
        if(currentGeoObject) {
            setBrandGeoObject(currentGeoObject);
        }
        if(currentNewGeoObject) {
            setBrandGeoObject(currentNewGeoObject);
        }
        setDeleteGeoObjectOpen(true);
    }

    useEffect(() => {
        if(currentBrandInfo && openNextBillboard) {
            const builder = new EventBuilder(`EventGetBrandGeoObjects`);
                builder.AddParameter('BrandId', currentBrandInfo.brandId);
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        };

        if(currentBrandInfo === null) {
            brandGeoObjectsAction([]);
        }
    }, [currentBrandInfo]);

    useEffect(() => {
        brandGeoObjects.map(item => {
            if(item.Billboards) {
                item.Billboards.map(billboard => {
                    setAllGeoObjectBillboards(oldArray => [...oldArray, billboard])
                })
            }
        })
    }, [brandGeoObjects]);

    const getNextBillboardStatuses = () => {
        const billboardIds = onlyUnique(allGeoObjectBillboards.map(item => item.GeoMarkId));
        if (dates.DateFrom && dates.DateTo) {
            nextBillboardStatusesAction(
                billboardIds,
                dates.DateFrom, 
                dates.DateTo
            );
        }
    }

    useEffect(() => {
        getNextBillboardStatuses();
    }, [dates.DateFrom, dates.DateTo, maxDistanceSearch]);

    const getSidesBillboard = (billboardID: number) => {
        if(nextBillboardStatuses.length === 0) {
            return;
        }

        const currentStatuses: GeoMarkTimeStatus[] = 
            nextBillboardStatuses.filter(status => status.geoMarkId === billboardID);

        let color = '#fff';
        for(let status of currentStatuses) {
            if(status.geoMarkStatus === ProjectGeoMarkStatus.Paid) {
                color = getHighlightColor(ProjectGeoMarkStatus.Paid);
            } else if(status.geoMarkStatus === ProjectGeoMarkStatus.Sold) {
                color = getHighlightColor(ProjectGeoMarkStatus.Sold);
            }
            else if(status.geoMarkStatus === ProjectGeoMarkStatus.Booked) {
                color = getHighlightColor(ProjectGeoMarkStatus.Booked);
            }
            else if(status.geoMarkStatus === ProjectGeoMarkStatus.Reserved) {
                color = getHighlightColor(ProjectGeoMarkStatus.Reserved);
            }
            else {
                color = getHighlightColor(ProjectGeoMarkStatus._FreeOnlyForFrontend);
            }
        }

        return (
            <Tooltip 
                title={
                    <NextBillboardSideTooltip 
                        billboardID={billboardID}/>
                }
                placement="top">
                <span 
                    className="nextBillboardStatuses" 
                    style={{ backgroundColor: color }}/>
            </Tooltip>
        );
    }

    const addAddressClick = (item: BrandGeoObjects, geoObjectType: GeoObjectType) => {
        if(geoObjectType === GeoObjectType.newGeoObject) {
            if(!item.GeoPoint || item.GeoPoint && (item.GeoPoint.Latitude === 90 && item.GeoPoint.Longitude === 180)) {
                const value = item.Address 
                ? item.Address.AddressString 
                    || (item.Address.CityName + " " +  item.Address.StreetName + " " + item.Address.HouseNumber) 
                : "";
                addressValueAction(value);
                geoPointNullAction(true);
            }
        }
    }

    const geoObjectStyle = (geoObject: BrandGeoObjects, geoObjectType: GeoObjectType) => {
        if(geoObject.GeoPoint === null || geoObject.GeoPoint && (geoObject.GeoPoint.Latitude === 90 && geoObject.GeoPoint.Longitude === 180)
        ) {
            return {
                backgroundColor: "rgb(255 255 0 / 9%)", 
                cursor: "pointer"
            }
        } else if(geoObjectType === GeoObjectType.geoObject) {
            return {
                backgroundColor: "rgb(40 167 69 / 10%)"
            }
        } else {
            return {}
        }
    }

    const brandGeoObjectsView = (geoObjects: BrandGeoObjects[], geoObjectType: GeoObjectType) => {
        return (
            geoObjects.map(geoObject => {
                return (
                    <TableRow 
                        key={geoObject.GeoMarkId}
                        style={geoObjectStyle(geoObject, geoObjectType)}
                        onClick={() => addAddressClick(geoObject, geoObjectType)}>
                        <EditGeomarkName 
                            geoObjectType={geoObjectType} 
                            brandGeoObject={geoObject} />
                        <TableCell align="left">
                            <span 
                                className="link"
                                onClick={() => showMarkersClick(geoObject)}>
                                {geoObject.Address 
                                    ? geoObject.Address.AddressString 
                                        || (geoObject.Address.CityName + ", " +  geoObject.Address.StreetName + ", " + geoObject.Address.HouseNumber) 
                                    : ""
                                }
                            </span>
                        </TableCell>
                        <TableCell align="left">
                            {
                                geoObject.Billboards && geoObject.Billboards.map(billboard => {
                                    const km = calculateDistance(
                                        geoObject.GeoPoint!.Latitude, 
                                        geoObject.GeoPoint!.Longitude, 
                                        billboard.GeoPoint.Latitude, 
                                        billboard.GeoPoint.Longitude);
                                    return (
                                        <div 
                                            key={billboard.GeoMarkId}
                                            className="nextBillboardRow">
                                            {getSidesBillboard(billboard.GeoMarkId)}
                                            <span 
                                                className="calculateDistance__tableCell"
                                                onClick={openBillboardClick(billboard)}>
                                                {billboard.GeoMarkName}
                                            </span>
                                            <span className="calculateDistance__tableCellKm">
                                                {km && (Math.floor((km / 1000) * 100) / 100)}км
                                            </span>
                                        </div>
                                    );
                                })
                            }   
                        </TableCell>
                        <TableCell align="center">
                            {geoObject.Billboards && geoObject.Billboards.length > 0 && 
                                <Tooltip title="Показать на карте" placement="top">
                                    <Button 
                                        color="primary"
                                        size="small"
                                        onClick={() => showMarkersClick(geoObject)}>
                                        Показать
                                    </Button>
                                </Tooltip>
                            }
                            <Tooltip title="Удалить геообъект" placement="top">
                                <Button 
                                    color="secondary"
                                    size="small"
                                    onClick={() => deleteMarkerClick(geoObject)}>
                                    Удалить
                                </Button>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                )
            }).reverse()
        )
    }

    return (
        <>
            <TableContainer component={Paper} className="calculateDistance">
                <Table className="calculateDistance__table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название геообъекта</TableCell>
                            <TableCell align="left">Адрес геообъекта</TableCell>
                            <TableCell align="left">Список ближайших конструкции с указанием удаления</TableCell>
                            <TableCell align="right" style={{width: 150}}>Удалить</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {brandGeoObjectsView(newBrandGeoObjects, GeoObjectType.newGeoObject)}
                        {brandGeoObjectsView(brandGeoObjects, GeoObjectType.geoObject)}
                    </TableBody>
                </Table>
            </TableContainer>
            {brandGeoObject &&
                <DeleteGeoObjectModal 
                    deleteGeoObjectOpen={deleteGeoObjectOpen} 
                    deleteGeoObjectClose={deleteGeoObjectClose} 
                    brandGeoObject={brandGeoObject} />
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    brandGeoObjects: state.event.brandGeoObjects,
    newBrandGeoObjects: state.event.newBrandGeoObjects,
    currentBrandInfo: state.billboards.currentBrandInfo,
    nextBillboardStatuses: state.billboards.nextBillboardStatuses,
    maxDistanceSearch: state.event.maxDistanceSearch,
    billboardsShortInfo: state.billboards.billboardsShortInfo
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    getAdditionalBillboardAction: (geoMarkId: number) => 
        dispatch(actions.billboards.getAdditionalBillboardAction(geoMarkId)),
    showMarkersAction: (showMarkers: boolean) => 
        dispatch(actions.event.showMarkersAction(showMarkers)),  
    nextBillboardStatusesAction: (billboardIDs: number[], dateStart: Date, dateEnd: Date) => 
        dispatch(actions.billboards.nextBillboardStatusesAction(billboardIDs, dateStart, dateEnd)),
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),
    geoPointNullAction: (geoPointNull: boolean) => 
        dispatch(actions.event.geoPointNullAction(geoPointNull)),
    brandGeoObjectsAction: (brandGeoObjects: BrandGeoObjects[]) =>
        dispatch(actions.event.brandGeoObjectsAction(brandGeoObjects)),
    billboardShortAction: (billboardShort: BillboardsShortInfo | undefined) => 
        dispatch(actions.billboards.billboardShortAction(billboardShort)),
});


export default connect(
    mapStateToProps,mapDispatchToProps
)(NextBillboardTable);