import { ProjectGeoMarkStatus } from "sedi-webserverproxy";
import store from "../store";
import { ProjectStatuses } from "../store/todo/types";

export function getProjectAvailableStatuses(currentStatus: ProjectGeoMarkStatus, surfacesCount: number | undefined): ProjectGeoMarkStatus[] {
    const userInfo = store.getState().user.userInfo;
    // if(userInfo && (userInfo.userRole !== "SalesDirector" && userInfo.userRole !== "AdminPMS")) {
    //     return [
    //         ProjectGeoMarkStatus.New, ProjectGeoMarkStatus.Reserved, ProjectGeoMarkStatus.BookingRequest, 
    //         ProjectGeoMarkStatus.Sold, ProjectGeoMarkStatus.Paid, ProjectGeoMarkStatus.Deleted
    //     ];
    // }
    if(currentStatus === ProjectGeoMarkStatus.New) {
        if(surfacesCount && surfacesCount > 0) {
            return [
                ProjectGeoMarkStatus.New, ProjectGeoMarkStatus.Reserved, ProjectGeoMarkStatus.Booked, 
                ProjectGeoMarkStatus.Sold, ProjectGeoMarkStatus.Paid, ProjectGeoMarkStatus.Deleted
            ];
        }
        return [
            ProjectGeoMarkStatus.New, ProjectGeoMarkStatus.Reserved, ProjectGeoMarkStatus.Booked, 
            ProjectGeoMarkStatus.Deleted
        ];
    }
    if(currentStatus === ProjectGeoMarkStatus.Reserved) {
        return [
            ProjectGeoMarkStatus.Reserved, ProjectGeoMarkStatus.Booked, ProjectGeoMarkStatus.Sold, 
            ProjectGeoMarkStatus.Paid, ProjectGeoMarkStatus.Deleted
        ];
    }
    if(currentStatus === ProjectGeoMarkStatus.Booked) {
        return [ProjectGeoMarkStatus.Reserved, ProjectGeoMarkStatus.Booked, ProjectGeoMarkStatus.Sold, ProjectGeoMarkStatus.Paid, ProjectGeoMarkStatus.Deleted];
    }
    if(currentStatus === ProjectGeoMarkStatus.Sold) {
        if(userInfo && (userInfo.userRole === "SalesDirector" || userInfo.userRole === "AdminPMS" 
            || (userInfo.userRole === "SalesManager" && store.getState().todo.projects.some(item => item.creatorAccountId === userInfo.accountId)))
        ) {
            return [ProjectGeoMarkStatus.Sold, ProjectGeoMarkStatus.Paid, ProjectGeoMarkStatus.InEditMode, ProjectGeoMarkStatus.Deleted];
        }
        return [ProjectGeoMarkStatus.Sold, ProjectGeoMarkStatus.Paid];
    }
    if(currentStatus === ProjectGeoMarkStatus.Paid) {
        return [ProjectGeoMarkStatus.Paid, ProjectGeoMarkStatus.Completed];
    }
    if(currentStatus === ProjectGeoMarkStatus.InEditMode) {
        return [ProjectGeoMarkStatus.InEditMode, ProjectGeoMarkStatus.Sold, ProjectGeoMarkStatus.Deleted];
    }
    if(currentStatus === ProjectGeoMarkStatus.Deleted) {
        return [ProjectGeoMarkStatus.Deleted];
    }
    if(currentStatus === ProjectGeoMarkStatus.Completed) {
        return [ProjectGeoMarkStatus.Completed];
    }
    return []
} 

export function getProjectLocalizedAvailableStatuses(currentStatus: ProjectGeoMarkStatus, surfacesCount: number | undefined): ProjectStatuses[] {
    const projectStatus = store.getState().todo.projectStatuses;
    const availableStatuses = getProjectAvailableStatuses(currentStatus, surfacesCount);
    let localizedStatuses: ProjectStatuses[] = [];
    for(let availableStatus of availableStatuses) {
        const findStatus = projectStatus.find(item => item.value === availableStatus);
        if(findStatus) {
            localizedStatuses.push(findStatus);
        } else {
            const newStatus: ProjectStatuses = {
                value: availableStatus,
                localization: availableStatus
            }
            localizedStatuses.push(newStatus);
        }
    }
    return localizedStatuses;
}