import BUILD_PARAMS from "./build"

export class Settings {
    public static get imgUrl(): string {
        return `${BUILD_PARAMS.WEBSERVER_URL}:9021/api/WebClient/GetBillboardPhoto?geoMarkId=`
        //if(window.location.hostname === 'localhost') {
        //    return 'https://test2.sedi.ru:9021/api/WebClient/GetBillboardPhoto?geoMarkId='
        //} else {
        //    return `https://${window.location.hostname}:9021/api/WebClient/GetBillboardPhoto?geoMarkId=`
        //}
    }
    public static get imgUrlNew(): string {
        return `${BUILD_PARAMS.SERVER_API_URL}/bbinfo?geoMarkId=`
        //if(window.location.hostname === 'localhost' || window.location.hostname === 'dev.blackboard.sedi.ru') {
        //    return 'https://test2.sedi.ru/bbinfo?geoMarkId='
        //} else if (window.location.hostname === 'office.bboutdoor.ru') {
        //    return 'https://api.bboutdoor.ru/bbinfo?geoMarkId='
        //} else {
        //    return `https://${window.location.hostname}/bbinfo?geoMarkId=`
        //}
    }
    public static get useNewUrl(): boolean{
        return true;
    }
    public static get imgPhotoUrl(): string {
        return `${BUILD_PARAMS.WEBSERVER_URL}:9021/api/WebClient/GetBillboardPhoto?geoMarkId=-1&imageId=`;
        //if(window.location.hostname === 'localhost') {
        //    return 'https://test2.sedi.ru:9021/api/WebClient/GetBillboardPhoto?geoMarkId=-1&imageId='
        //} else {
        //    return `https://${window.location.hostname}:9021/api/WebClient/GetBillboardPhoto?geoMarkId=-1&imageId=`
        //}
    }
}