import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import { DatesRange } from '../../../bricks/InputDatesRange';
import { linerGradient } from '../../../../utils/getHighlightStyle';
import { IBillboardStatuses } from '../../../../RemoteCommands/type';


type Props = {
    sideID: string,
    status?: IBillboardStatuses,
    onClick: () => void,
    dates: DatesRange,
    isSelectedSide: boolean
};


const SideButton: FunctionComponent<Props> = ({ sideID, status, dates, onClick, isSelectedSide, ...otherBtnProps }) => {

    const color = isSelectedSide ? 'secondary' : undefined;
    const style = linerGradient(dates, status);

    return (
        <Button style={style} onClick={onClick} {...otherBtnProps} color={color}>{sideID}</Button>
    );
};


export default SideButton;