import { TodoActions } from "./actions";
import { ReduxTodoActionsTypes, TodoActionsState } from './types';
import { Reducer } from "redux";
import { getMonthDates } from "../../components/bricks/InputDatesRange";
import { ProjectGeoMarkStatus } from "sedi-webserverproxy";
import { ProjectType } from "./types";


export const defaultState: TodoActionsState = {
    TodoActions: [],
    openProjectModal: false,
    projectID: -1,
    currentCompanyAccountId: -1,
    projects: [],
    openSelectedBillboards: false,
    commercialOffer: null,
    permissionResolutionNegative: false,
    note: '',
    totalDiscountText: '',
    projectDiscount: [],
    discountOpen: false,
    projectPeriod: [],
    currentGeoMarkStatus: {
        value: ProjectGeoMarkStatus.New,
        localization: "Новый"
    },
    dates: getMonthDates(),
    projectStatuses: [],
    projectTypes: [],
    coworkers: [],
    projectFilter: {
        projectType: ProjectType.CommonProject,
        projectStatus: undefined,
        coworker: undefined
    }, 
    openAdditionalCost: false,
    exceededTimeProjects: [],
    exceededTimeProject: null
};


const todoActionsReducer: Reducer<TodoActionsState, TodoActions> = (state = defaultState, action) => {
    switch (action.type) {
        case ReduxTodoActionsTypes.EXCEEDED_TIME_PROJECT:
            return {
                ...state,
                exceededTimeProject: action.exceededTimeProject
            };
        case ReduxTodoActionsTypes.EXCEEDED_TIME_PROJECTS:
            return {
                ...state,
                exceededTimeProjects: action.exceededTimeProjects
            };
        case ReduxTodoActionsTypes.OPEN_ADDITIONAL_COST:
            return {
                ...state,
                openAdditionalCost: action.openAdditionalCost
            };
        case ReduxTodoActionsTypes.PROJECT_FILTER:
            return {
                ...state,
                projectFilter: action.projectFilter
            };
        case ReduxTodoActionsTypes.DATES_FROM_TO:
            return {
                ...state,
                dates: action.dates
            };
        case ReduxTodoActionsTypes.CURRENT_GEOMARK_STATUS:
            return {
                ...state,
                currentGeoMarkStatus: action.currentGeoMarkStatus
            };
        case ReduxTodoActionsTypes.PROJECT_PERIOD:
            return {
                ...state,
                projectPeriod: action.projectPeriod
            };
        case ReduxTodoActionsTypes.DISCOUNT_OPEN:
            return {
                ...state,
                discountOpen: action.discountOpen
            };
        case ReduxTodoActionsTypes.PROJECT_DISCOUNT:
            return {
                ...state,
                projectDiscount: action.projectDiscount
            };
        case ReduxTodoActionsTypes.ADD_TODO_ACTION:
            const newToDoAction = action.todoAction;
            const similarActionIndex = state.TodoActions
                .findIndex(a => a.ActionName === newToDoAction.ActionName && a.SequenceNumber === newToDoAction.SequenceNumber);

            let newToDoes = state.TodoActions.concat();
            if (similarActionIndex >= 0){
                newToDoes.splice(similarActionIndex, 1, newToDoAction);
            }
            else {
                newToDoes.push(newToDoAction);
            }

            return {
                ...state,
                TodoActions: newToDoes
            };
        
        case ReduxTodoActionsTypes.OPEN_PROJECT_MODAL:
            return {
                ...state,
                openProjectModal: action.openProjectModal
            };

        case ReduxTodoActionsTypes.SET_ACTIVE_PROJECT:
            return {
                ...state,
                projectID: action.projectID
            };
        case ReduxTodoActionsTypes.CURRENT_ACTIVE_COMPANY:
            return {
                ...state,
                currentCompanyAccountId: action.currentCompanyAccountId
            };
        case ReduxTodoActionsTypes.SET_ALL_PROJECTS:
            return {
                ...state,
                projects: action.projects
            };
        case ReduxTodoActionsTypes.OPEN_SELECTED_BILLBOARDS:
            return {
                ...state,
                openSelectedBillboards: action.openSelectedBillboards
            };
        case ReduxTodoActionsTypes.COMMERCIAL_OFFER:
            return {
                ...state,
                commercialOffer: action.commercialOffer
            };
        case ReduxTodoActionsTypes.COMMERCIAL_OFFER_ID: 
            return {
                ...state,
                commercialOffer: {
                    ...state.commercialOffer!,
                    offerId: action.offerId
                }
            };
        case ReduxTodoActionsTypes.COMMERCIAL_OFFER_DISCOUNT:
            return {
                ...state,
                commercialOffer: {
                    ...state.commercialOffer!,
                    discounts: action.discounts
                }
            };
        case ReduxTodoActionsTypes.COMMERCIAL_OFFER_NULL:
            return {
                ...state,
                commercialOffer: null
            };
        case ReduxTodoActionsTypes.BUTTON_NEGATIVE:
            return {
                ...state,
                permissionResolutionNegative: action.permissionResolutionNegative
            };
        case ReduxTodoActionsTypes.NOTE:
            return {
                ...state,
                note: action.note
            };
        case ReduxTodoActionsTypes.TOTAL_DISCOUNT_TEXT:
            return {
                ...state,
                totalDiscountText: action.totalDiscountText
            };
        case ReduxTodoActionsTypes.PROJECT_STATUSES:
            return {
                ...state,
                projectStatuses: action.projectStatuses
            };
        case ReduxTodoActionsTypes.PROJECT_TYPES:
            return {
                ...state,
                projectTypes: action.projectTypes
            };
        case ReduxTodoActionsTypes.COWORKERS:
            return {
                ...state,
                coworkers: action.coworkers
            };
        default:
            return state;
    }
};


export default todoActionsReducer;