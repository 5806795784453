import React from 'react';
import { RootState, selectors } from '../../store';
import { connect } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';

type Props = ReturnType<typeof mapStateToProps> & {
    contextMenuClose: () => void,
    anchorEl: HTMLElement | null,
    sipAccount: string | null,
    employeeClickOpen: () => void,
    employeeCallClickOpen: () => void
}

const ContextMenuCall:React.FC<Props> = (props) => {
    const {
        contextMenuClose,
        anchorEl,
        sipNumber,
        sipAccount,
        employeeClickOpen,
        employeeCallClickOpen
    } = props;

    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={contextMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className="ml-2"
        >
            <MenuItem
                onClick={employeeClickOpen}>
                Открыть карточку сотрудника
            </MenuItem>
            <MenuItem
                onClick={() => contextMenuClose()}>
                Написать письмо
            </MenuItem>
            <MenuItem
                onClick={employeeCallClickOpen}>
                Позвонить
            </MenuItem>
        </Menu>
    );
};

const mapStateToProps = (state: RootState) => ({
    sipNumber: selectors.user.sipNumber(state.user)
});

export default connect(mapStateToProps)(ContextMenuCall);