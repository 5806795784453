import React, { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';       
import { IconButton, CardHeader, Grid, AppBar, Tabs, Tab, Table, Box, Tooltip, FormControlLabel, Checkbox, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DefaultPosition } from '../../store/app/types';
import moment from 'moment';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { SalesPaymentStatisticFilter, SalesPaymentStatisticResult } from '../../RemoteCommands/type';
import GraphsCharts from '../bricks/GraphsCharts';
import { DatesRange } from '../bricks/InputDatesRange';
import { MonthYear } from '../../utils/getMonthYear';
import { GeoMarkStatus } from 'sedi-webserverproxy';
import { RollUpCard, StatisticsType } from '../../store/event/types';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import StatisticsToPrint from './StatisticsToPrint';
import DatePicker from "react-datepicker";
import RegionAutocomplete from '../bricks/RegionAutocomplete';
import CityAutocomplete from '../bricks/CityAutocomplete';
import './Statistics.scss';
import RemoveIcon from '@material-ui/icons/Remove';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

const CityStatistics:React.FC<Props> = (props) => {
    const {
        draggable,
        positionSalesStatistics,
        positionSalesStatisticsAction,
        cityStatisticsAction,
        cityStatistics,
        currentMonthYear,
        monthYears,
        statisticsType,
        fullMonthYear,
        rollUpCardAction,
        rollUpCards
    } = props;

    const lastMonthYear = monthYears[monthYears.length - 1];
    const dateFrom = new Date(`${monthYears[0].year}-${monthYears[0].monthNumber}-01`);
    const dateTo = new Date(`${lastMonthYear.year}-${lastMonthYear.monthNumber}-01`);

    const [salesPaymentStatisticResult, setSalesPaymentStatisticResult] = useState<SalesPaymentStatisticResult | null>(null);
    const [regionName, setRegionName] = useState<string | null| undefined>(undefined);
    const [cityName, setCityName] = useState<string | null| undefined>(undefined);
    const [value, setValue] = React.useState(0);
    const [dates, setDates] = useState<DatesRange>({
        DateFrom: undefined,
        DateTo: undefined
    });
    const [isFixedSum, setIsFixedSum] = useState(false);

    const componentRef: any = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    useEffect(() => {
        if(dates.DateFrom && dates.DateTo) {
            const monthCount = monthDiff(dates.DateFrom, dates.DateTo);
            if(monthCount >= 12 || monthCount === 0) {
                const date = new Date(dates.DateFrom);
                const dateTo = new Date(date.setMonth(date.getMonth() + 11));
                setDates(prevState => ({
                    ...prevState,
                    DateTo: dateTo
                }))
            }
        }
    }, []);

    useEffect(() => {
        if(dates.DateFrom && dates.DateTo) {
            const monthCount = monthDiff(dates.DateFrom, dates.DateTo);
            if(monthCount >= 12 || monthCount === 0) {
                const date = new Date(dates.DateTo);
                const dateFrom = new Date(date.setMonth(date.getMonth() - 11));
                setDates(prevState => ({
                    ...prevState,
                    DateFrom: dateFrom
                }))
            }
        }
    }, []);

    const dateFromChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateFrom: date ? date : undefined
        }));
    }

    const dateToChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateTo: date ? date : undefined
        }));
    }

    useEffect(() => {
        if(cityStatistics) {
            setDates({
                DateFrom: dateFrom,
                DateTo: dateTo
            });
        }
    }, [cityStatistics]);

    useEffect(() => {
        if(fullMonthYear) {
            return (
                setDates({
                    DateFrom: dateFrom,
                    DateTo: dateTo
                })
            )
        };
        if((currentMonthYear && statisticsType !== StatisticsType.sale) || (currentMonthYear && statisticsType !== StatisticsType.loadingConstructions)) {
            const dateFromClient = new Date(`${currentMonthYear.year}-${currentMonthYear.monthNumber}-01`);
            setDates({
                DateFrom: dateFromClient,
                DateTo: dateFromClient
            });
        }
        if(statisticsType === StatisticsType.sale || statisticsType === StatisticsType.loadingConstructions) {
            setDates({
                DateFrom: dateFrom,
                DateTo: dateTo
            });
        }
    }, [currentMonthYear, fullMonthYear]);

    const monthDiff = (dateFrom: Date, dateTo: Date) => {
        let months;
            months = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
            months -= dateFrom.getMonth();
            months += dateTo.getMonth();
        return months <= 0 ? 0 : months;
    }

    const intervalMonthYears = () => {
        if(!dates.DateFrom || !dates.DateTo) {
            return monthYears;
        }

        const fromDate = dates.DateFrom;
        const result: MonthYear[] = [];
        const intervalCount = monthDiff(dates.DateFrom, dates.DateTo);
        for(let i = 0; i < intervalCount; i++) {
            const dateStart = moment(fromDate, "YYYY-MM-DD").add(i, 'month');
            result.push(new MonthYear(dateStart.format("MMM"), parseInt(dateStart.format('M')), dateStart.format("YYYY"), "", GeoMarkStatus._FreeOnlyForFrontend));
        }
        return result;
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        positionSalesStatisticsAction(position);
    };

    const salesStatisticsClose = () => {
        cityStatisticsAction(false)
    }

    const changeStatisticsHandler = useCallback(() => {
        if(dates.DateFrom && dates.DateTo) {
            let salesPaymentStatisticFilter: SalesPaymentStatisticFilter = {
                Year: Number(moment(dates.DateFrom).format('YYYY')),
                Month: Number(moment(dates.DateFrom).format('M')),
                YearTo: Number(moment(dates.DateTo).format('YYYY')),
                MonthTo: Number(moment(dates.DateTo).format('M'))
            };
            SystemEvent.EventGetSalesPaymentStatistic(salesPaymentStatisticFilter, "City");
        }
    }, [dates]);

    useEffect(() => {
        changeStatisticsHandler();
    }, [dates]);

    useMemo(() => {
        SystemEvent.SubscribeEventGetSalesPaymentStatistic(
            "CityStatistics", 
            (answer) => {
                const answerSales = answer.salesPaymentStatisticResult;
                setSalesPaymentStatisticResult(answerSales);
            }, 
            (error) => errorCallback(error)
        );
    }, []);

    const salesGrouping = () => {
        switch (statisticsType as StatisticsType) {
            case StatisticsType.city:
                return 'по городам (районам)';
            default: 
                return;
        }
    }

    const tabPanelView = () => {
        if(!currentMonthYear || !salesPaymentStatisticResult) {
            return null
        }
        const { header, statistic, totalItem } = salesPaymentStatisticResult;
        const allSoldTotalSum = () => {
            const allSum = totalItem.soldTotalSum + totalItem.designSum + totalItem.installationSum + totalItem.printSum + totalItem.additionalInstallationSum;
            return allSum;
        }
        return (
            <>
                <TabPanel value={value} index={0}>
                    <TableContainer component={Paper} className="calculateDistance">
                        <Table className="calculateDistance__table print" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{header}</TableCell>
                                    {
                                        ((
                                            statisticsType === StatisticsType.sale) 
                                                || (statisticsType === StatisticsType.region) 
                                                || (statisticsType === StatisticsType.city) 
                                                || (statisticsType === StatisticsType.constructionType
                                        )) &&
                                        <TableCell align="center">% Загрузки</TableCell>
                                    }
                                    <TableCell align="center">Кол-во продано</TableCell>
                                    <TableCell align="center">Сумма продано (руб.)</TableCell>
                                    <TableCell align="center">Сумма оплачено (руб.)</TableCell>
                                    <TableCell align="center">Задолженность (руб.)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statistic.map((item, i) => (
                                    item.sales.map(sale => (
                                        <TableRow key={sale.accountId + Math.random()}>
                                            <TableCell>
                                                {sale.name}
                                            </TableCell>
                                            {
                                                ((
                                                    statisticsType === StatisticsType.sale) 
                                                        || (statisticsType === StatisticsType.region) 
                                                        || (statisticsType === StatisticsType.city) 
                                                        || (statisticsType === StatisticsType.constructionType
                                                )) &&
                                                    <TableCell align="center">{sale.usagePercent}%</TableCell>
                                            }
                                            <TableCell align="center">{sale.soldTotalCount}</TableCell>
                                            <TableCell align="center">{sale.soldTotalSum + sale.designSum + sale.installationSum + sale.printSum + sale.additionalInstallationSum}</TableCell>
                                            <TableCell align="center">{sale.paidTotalSum}</TableCell>
                                            <TableCell align="center">{sale.debtSum}</TableCell>
                                        </TableRow>
                                    ))
                                ))}
                                {statistic.length > 0 &&
                                    <TableRow>
                                        <TableCell>
                                            <strong>{totalItem.name}</strong>
                                        </TableCell>
                                        {
                                            ((statisticsType === StatisticsType.sale) 
                                                || (statisticsType === StatisticsType.region) 
                                                || (statisticsType === StatisticsType.city) 
                                                || (statisticsType === StatisticsType.constructionType
                                            )) &&
                                            <TableCell align="center">
                                                <strong>{totalItem.usagePercent}%</strong>
                                            </TableCell>
                                        }
                                        <TableCell align="center">
                                            <strong>{totalItem.soldTotalCount}</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{allSoldTotalSum()}</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{totalItem.paidTotalSum}</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>{totalItem.debtSum}</strong>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <GraphsCharts 
                        salesPaymentStatisticResult={salesPaymentStatisticResult} 
                        intervalMonthYears={intervalMonthYears} 
                        statisticsType={statisticsType} />
                </TabPanel>
                <div className="totalStatistics">
                    {`
                        Всего продано/Оплачено: 
                        ${salesPaymentStatisticResult.totalItem.soldTotalCount} 
                        поверхности на Сумму 
                        ${
                            salesPaymentStatisticResult.totalItem.soldTotalSum 
                                + salesPaymentStatisticResult.totalItem.designSum 
                                + salesPaymentStatisticResult.totalItem.installationSum 
                                + salesPaymentStatisticResult.totalItem.printSum 
                                + salesPaymentStatisticResult.totalItem.additionalInstallationSum
                        } руб.
                    `}
                </div>
            </>
        )
    }

    const rollUpCardHas = rollUpCards.some(item => item.key === "CityStatistics");
    const rolleUpClick = () => {
        rollUpCardAction({
            title: `Статистика`,
            key: "CityStatistics"
        });
    }

    return (
        <>
        <Draggable
            handle=".selectedCard"
            defaultPosition={positionSalesStatistics}
            disabled={!draggable}
            onStop={onStop}>
            <Card 
                className={`
                    selectedCard salesStatistics constructionStatistics 
                    ${cityStatistics ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Распечатать"
                                placement="top">
                                <IconButton 
                                    onClick={handlePrint}>
                                    <PrintIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                className="ml-1"
                                onClick={salesStatisticsClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid 
                            container 
                            spacing={2} 
                            alignItems="center"
                            justify="space-between">
                            <Grid item xs={12}>
                                <Grid 
                                    container 
                                    spacing={1} 
                                    alignItems="center">
                                    {statisticsType === StatisticsType.loadingConstructions ?
                                        <Grid item xs={12}>
                                            <div className="box-title">
                                                Статистика загрузки конструкций
                                            </div>
                                        </Grid>
                                    : 
                                        <Grid item>
                                            <div className="box-title">
                                                Продажи {salesGrouping()} {regionName ? ", " + regionName : ""}
                                            </div>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <div className="chartDate">
                                            <DatePicker
                                                selected={dates.DateFrom}
                                                onChange={(date: Date | null) => dateFromChange(date)}
                                                selectsStart
                                                startDate={dates.DateFrom}
                                                endDate={dates.DateTo}
                                                dateFormat="MMMM yyyy"
                                                showMonthYearPicker
                                                isClearable
                                                locale="ru"
                                                placeholderText="Дата от"
                                            />
                                            <DatePicker
                                                selected={dates.DateTo}
                                                onChange={(date: Date | null) => dateToChange(date)}
                                                selectsStart
                                                startDate={dates.DateFrom}
                                                endDate={dates.DateTo}
                                                dateFormat="MMMM yyyy"
                                                isClearable
                                                showMonthYearPicker
                                                locale="ru"
                                                placeholderText="Дата до"
                                            />
                                        </div>
                                    </Grid>
                                    {statisticsType === StatisticsType.loadingConstructions &&
                                        <>
                                            <Grid item xs={3}>
                                                <RegionAutocomplete 
                                                    regionName={regionName}
                                                    setRegionName={setRegionName} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CityAutocomplete 
                                                    cityName={cityName} 
                                                    setCityName={setCityName} 
                                                    regionName={regionName} />
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="pt-0">
                    <AppBar 
                        position="static" 
                        color="default"
                        className="appBarStatistics">
                        <Tabs 
                            value={value} 
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary">
                            <Tab label="Таблица" {...a11yProps(0)} />
                            <Tab label="Диаграмма" {...a11yProps(1)} />
                        </Tabs>
                        {statisticsType === "loadingConstructions" &&
                            <FormControlLabel
                                className="isFixedSum"
                                control={
                                    <Checkbox
                                        checked={isFixedSum}
                                        onChange={(event) => setIsFixedSum(event.target.checked)}
                                        name="isFixedSum"
                                        color="primary"
                                    />
                                }
                                label="по деньгам"
                            />
                        }  
                    </AppBar>
                    {tabPanelView()}
                </CardContent>
            </Card>  
        </Draggable>
        {salesPaymentStatisticResult && 
            <div style={{ display: "none" }}>
                <StatisticsToPrint 
                    salesPaymentStatisticResult={salesPaymentStatisticResult} 
                    intervalMonthYears={intervalMonthYears} 
                    statisticsType={statisticsType}
                    title={salesGrouping()}
                    ref={componentRef}
                />
            </div>
        }
        </>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box pt={3}>{children}</Box>
            )}
        </div>
    );
}
  
function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        positionSalesStatistics: state.app.positionSalesStatistics,
        cityStatistics: state.event.cityStatistics,
        currentMonthYear: state.event.currentMonthYear,
        monthYears: state.event.monthYear,
        statisticsType: state.event.statisticsType,
        fullMonthYear: state.event.fullMonthYear,
        rollUpCards: state.event.rollUpCards,
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    positionSalesStatisticsAction: (positionSalesStatistics: DefaultPosition) => 
        dispatch(actions.app.positionSalesStatisticsAction(positionSalesStatistics)),
    cityStatisticsAction: (cityStatistics: boolean) => 
        dispatch(actions.event.cityStatisticsAction(cityStatistics)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),  
});

export default connect(mapStateToProps, mapDispatchToProps)(CityStatistics);