import { PropertyValueSet } from "../WebServerProxy";
import { DefaultProperties } from "../store/billboards/types";
import { ISideProperties } from "../RemoteCommands/type";


export class PropertyValuesArray {
    [index: string]: string;
}

export interface IBillboardProperties {
    side1Count: number;
    side2Count: number;
    oddNumber: boolean;
    sides1: string[];
    sides2: string[];
}
export class PropertiesHelper {

    static getSide1Count(properties: ISideProperties[]) {
        const property = properties.find(item => item.propertyName === "NumberOfSeatsOnSide1");
        return property ? parseInt(property.value) : 0;
    }

    static getSide2Count(properties: ISideProperties[]) {
        const property = properties.find(item => item.propertyName === "NumberOfSeatsOnSide2");
        return property ? parseInt(property.value) : 0;
    }

    static getOddNumbers(properties: ISideProperties[]) {
        const property = properties.find(item => item.propertyName === "WayA_OddNumbers");
        var oddNumbers = property ? property.value === 'true' : false;
        return oddNumbers;
    }

    static getSide(letter:string, count:number, isOdd:boolean): string[] {
        let sides = new Array<string>(count);
        let counter: number = 1;
        for (var i = 0; i < count; i++) {
            sides[i] = `${letter}${counter}`;
            counter = counter + (isOdd ? 2 : 1);
        }
        return sides;
    }

    static getBillboardProperties(side1Properties: ISideProperties[], side2Properties: ISideProperties[]) {
        const oddNumber = PropertiesHelper.getOddNumbers(side1Properties);
        const side1Count = PropertiesHelper.getSide1Count(side1Properties);
        const side2Count = PropertiesHelper.getSide2Count(side2Properties);
        let sides1: string[] = [];
        let sides2: string[] = [];
        if(side2Count === 0 && oddNumber === false) {
            for(let i = 1; i <= side1Count; i+=2)  {
                sides1.push("A" + i)
            }
            for(let i = 2; i <= side1Count; i+=2)  {
                sides2.push("A" + i)
            }
        } else {
            sides1 = PropertiesHelper.getSide("A", side1Count, oddNumber);
            sides2 = PropertiesHelper.getSide("B", side2Count, false);
        }

        const properties: IBillboardProperties = {
            oddNumber,
            side1Count,
            side2Count,
            sides1,
            sides2
        }
        return properties;
    }

    static arePropertiesValid(set: PropertyValueSet): boolean {
        if (!set)
            return false;
        const importantProperties = Object.keys(DefaultProperties);
        for (let i = 0; i < importantProperties.length; i++) {
            if (!set.propertyValues.find(pv => pv.property.propertyName === importantProperties[i]) && importantProperties[i] !== 'WayB' 
                && importantProperties[i] !== 'WayB_OddNumbers' && importantProperties[i] !== 'Type' && importantProperties[i] !== 'Light') {
                console.log(`${importantProperties[i]} not found`);
                return false;
            }
        }
        return true;
    }

    static getPropertyValue(set: PropertyValueSet, property: string, defaultValue: string) {
        const propertyValue = set.propertyValues.find(pv => pv.property.propertyName === property);
        if (propertyValue)
            return propertyValue.value;
        return defaultValue;
    }

    static getValuesArray(set: PropertyValueSet): PropertyValuesArray {
        const r = new PropertyValuesArray();
        set.propertyValues.forEach(v => r[v.property.propertyName] = v.value);
        return r;
    }
}
