import React, { Dispatch, useEffect, useState, useCallback } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions, selectors } from '../../store';
import { connect } from 'react-redux';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import Draggable from 'react-draggable';
import { Card, CardHeader, CardContent, IconButton, Tooltip, Grid, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { RollUpCard } from '../../store/event/types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { a11yProps, TabPanel } from '../../utils/tabs';
import BillboardInfoProperties from "../BillboardInfo/BillboardInfoProperties";
import { BillboardsShortInfo, ISideProperties } from '../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import Slider from "react-slick";
import { IBillboardProperties, PropertiesHelper } from '../../utils/PropertiesHelper';
import moment from 'moment';
import { DatesRange } from '../bricks/InputDatesRange';
import { BillboardCarousel } from './BillboardCarousel';
import { BillboardMap } from './BillboardMap';
import useClipboard from "react-use-clipboard";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './BillboardCard.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
        billboardShort: BillboardsShortInfo;
        dates?: DatesRange;
        geoMarkId?: string;
        imageType?: string;
        initialSide?: string;
        notMoved?: boolean;
    }

export type SliderState = {
    mainA: Slider | null;
    mainB: Slider | null;
    thumbA: Slider | null;
    thumbB: Slider | null;
}
export type SliderRef = {
    mainRefA: Slider | null;
    mainRefB: Slider | null;
    thumbRefA: Slider | null;
    thumbRefB: Slider | null;
}

const BillboardCard:React.FC<Props> = (props) => {
    const {
        draggable,
        draggablePositions,
        rollUpCards,
        draggablePositionAction,
        rollUpCardAction,
        billboardShort,
        billboardPropertiesAction,
        dates,
        allStatuses,
        billboardShortAction,
        photoVersion,
        imageType,
        geoMarkId,
        initialSide,
        notMoved
    } = props;

    const sideCount = (initialSide && initialSide.includes('side2')) ? 1 : 0;

    const [name, setName] = useState("");
    const [side1Properties, setSide1Properties] = useState<ISideProperties[]>([]);
    const [side2Properties, setSide2Properties] = useState<ISideProperties[]>([]);
    const [uploadedPhotos, setUploadedPhotos] = useState<string[]>([]);
    const [side, setSide] = React.useState(sideCount);
    const [loading, setLoading] = useState(false);

    const copyUrl  = window.location.href;
    const [isCopied, setCopied] = useClipboard(copyUrl);

    const TTImage = uploadedPhotos.find(item => item === 'TT');
    const TT2Image = uploadedPhotos.find(item => item === 'TT2');
    let sideName = imageType;

    const handleSideChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        sideName = undefined;
        setSide(newValue);
    };

    const sortImages = useCallback(() => {
        const result: string[] = [];
        const name = `Side${side + 1}`;
        for(let image of uploadedPhotos) {
            if(image.includes(name)) {
                result.push(image);
            }
        }
        return result;
    }, [side, uploadedPhotos]);

    const getBillboardInfo = useCallback(() => {
        SystemEvent.SubscribeEventGetBillboardInfo(
            "BillboardCard", 
            (answer) => {
                setName(answer.name);
                setSide1Properties(answer.side1Properties);
                setSide2Properties(answer.side2Properties);
                billboardPropertiesAction(
                    PropertiesHelper.getBillboardProperties(
                        answer.side1Properties, 
                        answer.side2Properties
                    )
                );
                setUploadedPhotos(answer.uploadedPhotos);
                setLoading(false);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );

        setLoading(true);
        const dateStart = dates ? moment.utc(dates.DateFrom, "YYYY-MM-DD").format('DD.MM.YYYY') : "";
        const dateEnd = dates ? moment.utc(dates.DateTo, "YYYY-MM-DD").format('DD.MM.YYYY') : "";
        SystemEvent.EventGetBillboardInfo(billboardShort.geoMarkId, dateStart, dateEnd);
    }, [billboardShort]);

    useEffect(() => {
        getBillboardInfo();
    }, []);

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'BillboardCard',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Конструкция",
            key: "BillboardCard"
        });
    }

    const handleClose = () => {
        billboardShortAction(undefined);
    }

    const draggablePosition = draggablePositions.find(item => item.key === "BillboardCard");
    const rollUpCardHas = rollUpCards.some(item => item.key === "BillboardCard");
    const sideA = side1Properties.find(item => item.propertyName === "NumberOfSeatsOnSide1" && +item.value > 0);
    const sideB = side2Properties.find(item => item.propertyName === "NumberOfSeatsOnSide2" && +item.value > 0);

    return (
        <Draggable
            handle=".billboardCard"
            defaultPosition={notMoved ? {x: 0, y: 42} : draggablePosition ? draggablePosition.position : {x: 388, y: 100}}
            disabled={notMoved ? notMoved : !draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card
                className={`
                    selectedCard 
                    billboardCard
                    ${billboardShort ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <Tabs
                    value={side}
                    onChange={handleSideChange}
                    indicatorColor="primary"
                    variant="standard"
                    textColor="primary"
                    className="billboardCard__tabs"
                    aria-label="icon label tabs">
                    {sideA && 
                        <Tab 
                            label={<span>Сторона <strong>A</strong></span>} 
                            className="billboardCard__tab" 
                            {...a11yProps(sideCount)} />
                    }
                    {sideB && 
                        <Tab 
                            label={<span>Сторона <strong>B</strong></span>} 
                            className="billboardCard__tab" 
                            {...a11yProps(sideCount)} />
                    }
                </Tabs>
                <CardHeader
                    action={
                        !notMoved && <div className="draggableCard__wrap">
                            <Tooltip
                                title={""}
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="center" justify="space-between">
                            <Grid item>
                                <span className="billboardCard__title">{name}</span>
                            </Grid>
                        </Grid>
                    }
                />
                {loading ?
                    <div className="loading">
                        <CircularProgress />
                    </div>
                    :
                    <CardContent>
                        <TabPanel value={side} index={0}>
                            <Grid container spacing={2} className="cancelDraggable">
                                <Grid item xs={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <strong>руб./мес.</strong>
                                        </Grid>
                                        {/* <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                startIcon={<ShoppingCartIcon />}>
                                                В корзину
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                    <BillboardInfoProperties 
                                        billboardShort={billboardShort} 
                                        sideProperties={side1Properties} 
                                        name={name} 
                                        size="small" 
                                        TTImage={TTImage} 
                                        geoMarkId={geoMarkId}
                                        imageType={sideName} />
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="billboardCard__getLink">
                                        <span 
                                            className="link"
                                            onClick={setCopied}>
                                            {isCopied ? "Ссылка скорпирована в буфер" : "Получить ссылку"}
                                        </span>
                                    </div>
                                    <BillboardCarousel 
                                        uploadedPhotos={sortImages()}
                                        geoMarkId={billboardShort.geoMarkId}
                                        photoVersion={photoVersion} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={side} index={1}>
                            <Grid container spacing={2} className="cancelDraggable">
                                <Grid item xs={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <strong>руб./мес.</strong>
                                        </Grid>
                                        {/* <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                startIcon={<ShoppingCartIcon />}>
                                                В корзину
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                    <BillboardInfoProperties 
                                        billboardShort={billboardShort} 
                                        sideProperties={side2Properties} 
                                        name={name} 
                                        size="small" 
                                        TTImage={TTImage} 
                                        TTImage2={TT2Image}
                                        geoMarkId={geoMarkId}
                                        imageType={sideName} />
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="billboardCard__getLink">
                                        <span className="link">Получить ссылку</span>
                                    </div>
                                    <BillboardCarousel
                                        uploadedPhotos={sortImages()}
                                        geoMarkId={billboardShort.geoMarkId}
                                        photoVersion={photoVersion} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <BillboardMap 
                            billboardShort={billboardShort} 
                            dates={dates!} 
                            statuses={allStatuses} />
                    </CardContent>
                }
            </Card>
        </Draggable>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
        allStatuses: selectors.billboards.getAllStatuses(state.billboards),
        photoVersion: state.billboards.photoVersion
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    billboardPropertiesAction: (billboardProperties: IBillboardProperties | undefined) => 
        dispatch(actions.billboards.billboardPropertiesAction(billboardProperties)),
    billboardShortAction: (billboardShort: BillboardsShortInfo | undefined) => 
        dispatch(actions.billboards.billboardShortAction(billboardShort)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillboardCard);