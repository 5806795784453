import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { ClientItem, ClientType } from '../../store/customers/types';
import { sortAlphabeticallyClientItem } from '../../utils/sortAlphabetically';
import { clientsArray } from '../../utils/clientsArray';
import './bricks.scss';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    currentClients: ClientItem[];
    onChange: (clietn: ClientItem[]) => void;
    billboardCustomers: ClientItem[];
}

const FilterByCustomers: React.FC<Props> = (props) => {
    const { 
        companies,
        customers,
        agencyInfo,
        onChange,
        currentClients,
        billboardCustomers
    } = props;

    const clientArray = () => {
        const client = clientsArray(companies, customers, agencyInfo);
        if(billboardCustomers)
            return sortAlphabeticallyClientItem(billboardCustomers);
        
        return sortAlphabeticallyClientItem(client);
    };

    const handleChange = (event: React.ChangeEvent<{}>, changeValue: ClientItem[]) => {
        onChange(changeValue);
    }

    const renderOptionSelect = (option: ClientItem) => {
        let optionType: string;

        if(option.customerType.toLowerCase() === ClientType.company) {
            optionType = 'Компания';
        } else if(option.customerType.toLowerCase() === ClientType.agency) {
            optionType = 'Агентства';
        } else {
            optionType = '';
        }

        return (
            <span className="projectItemSelect">
                <span>{option.name}</span>
                <span className="projectItemSelect__type">
                    {optionType}
                </span>
            </span>
        );
    };

    return (
        <Autocomplete
            multiple
            options={clientArray()}
            disableCloseOnSelect
            getOptionLabel={(option: ClientItem) => option.name}
            defaultValue={currentClients}
            onChange={(event: React.ChangeEvent<{}>, changeValue: ClientItem[]) => 
                handleChange(event, changeValue)}
            className="cancelDraggable"
            renderOption={(option: ClientItem) => renderOptionSelect(option)}
            filterOptions={(options: ClientItem[], state: FilterOptionsState) => 
                fliterOptions(options, state)}
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    variant="outlined" 
                    label="Фильтр по клиентам"  
                    fullWidth 
                    size="small" 
                    className="multiInput" />
            )}
        />
    )
}

const fliterOptions = (options: ClientItem[], state: FilterOptionsState) => {
    const optionsArray: ClientItem[] = options.filter(option => option.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 
        || option.name.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);
    return optionsArray;
}

const mapStateToProps = (state: RootState) => ({
    companies: state.customers.companies,
    customers: state.customers.customers,
    agencyInfo: state.event.agencyInfo
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(FilterByCustomers);