import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import { ISales, SalesPaymentStatisticResult } from '../../RemoteCommands/type';
import { StatisticsType } from '../../store/event/types';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { DatesRange } from '../bricks/InputDatesRange';
import { Dropdown } from 'react-bootstrap';
import { GetProjectParmas } from '../../RemoteCommands/type';
import { localStorageGetItem } from '../../utils/storage';
import { groupSales } from '../../utils/groupSales';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    salesPaymentStatisticResult: SalesPaymentStatisticResult;
    dates?: DatesRange,
    setRegionName?: React.Dispatch<React.SetStateAction<string | null| undefined>>;
}

const SalesStatisticsTable:React.FC<Props> = (props) => {
    const { 
        salesPaymentStatisticResult,
        curretnSaleAction,
        statisticsType,
        setRegionName,
        statisticsTypeAction,
        projectStatisticsAction,
        openSalesInformationAction,
        openCurrentSalesAction
    } = props;
    const { header, statistic, totalItem } = salesPaymentStatisticResult; 

    const employeesStatisticClick = (sale: ISales) => {
        curretnSaleAction(sale);
        openCurrentSalesAction(true);
    }

    const soldTotalCountClick = (sale: ISales) => {
        curretnSaleAction(sale);
        openSalesInformationAction(true);
    }

    const projectStatisticsClick = (sale: ISales) => {
        const payload: GetProjectParmas = {
            projectType: "CommonProject",
            projectIds: sale.projectIds,
            language: localStorageGetItem("lang")
        }
        SystemEvent.EventGetProjects(payload);
        curretnSaleAction(sale);
        projectStatisticsAction(true);
    }

    const cityStatisticClick = (sale: ISales) => {
        if(setRegionName) {
            setRegionName(sale.name);
        }
        statisticsTypeAction(StatisticsType.city);
        // if(dates && dates.DateFrom && dates.DateTo) {
        //     let salesPaymentStatisticFilter: SalesPaymentStatisticFilter = {
        //         Year: Number(moment(dates.DateFrom).format('YYYY')),
        //         Month: Number(moment(dates.DateFrom).format('M')),
        //         YearTo: Number(moment(dates.DateTo).format('YYYY')),
        //         MonthTo: Number(moment(dates.DateTo).format('M')),
        //         Filter: sale.name
        //     };
        //     SystemEvent.EventGetSalesPaymentStatistic(salesPaymentStatisticFilter, "City");
        // }
        // cityStatisticsAction(true)
    }

    const saleNameView = (sale: ISales) => {
        if(
            statisticsType === StatisticsType.client 
                || statisticsType === StatisticsType.employee 
                || statisticsType === StatisticsType.region 
        ) {
            return (
                <Dropdown
                    className="regionNameLink">
                    <Dropdown.Toggle 
                        variant="link" 
                        id="regionNameLinkBtn">
                        {sale.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {statisticsType !== StatisticsType.region &&
                            <Dropdown.Item onClick={() => projectStatisticsClick(sale)}>по проектам</Dropdown.Item>
                        }
                        <Dropdown.Item onClick={() => employeesStatisticClick(sale)}>
                            {statisticsType === "employee" ? "по клиентам" : "по сотрудникам"}</Dropdown.Item>
                        <Dropdown.Item onClick={() => cityStatisticClick(sale)}>по городам (районам)</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        } else {
            return (
                <span>{sale.name}</span>
            )
        }
    }

    const allSoldTotalSum = () => {
        const allSum = totalItem.soldTotalSum + totalItem.designSum + totalItem.installationSum + totalItem.printSum + totalItem.additionalInstallationSum;
        return allSum;
    }

    return (
        <>
            <TableContainer component={Paper} className="calculateDistance">
                <Table className="calculateDistance__table print" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{header}</TableCell>
                            {
                                ((
                                    statisticsType === StatisticsType.sale) 
                                        || (statisticsType === StatisticsType.region) 
                                        || (statisticsType === StatisticsType.city) 
                                        || (statisticsType === StatisticsType.constructionType
                                )) &&
                                <TableCell align="center">% Загрузки</TableCell>
                            }
                            <TableCell align="center">Кол-во продано</TableCell>
                            <TableCell align="center">Сумма продано (руб.)</TableCell>
                            <TableCell align="center">Сумма оплачено (руб.)</TableCell>
                            <TableCell align="center">Задолженность (руб.)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupSales(statistic).map(sale => (
                            <TableRow key={sale.accountId + Math.random()}>
                                <TableCell>
                                    {saleNameView(sale)}
                                </TableCell>
                                {
                                    ((
                                        statisticsType === StatisticsType.sale) 
                                            || (statisticsType === StatisticsType.region) 
                                            || (statisticsType === StatisticsType.city) 
                                            || (statisticsType === StatisticsType.constructionType
                                    )) &&
                                        <TableCell align="center">{sale.usagePercent.toFixed(2)}%</TableCell>
                                }
                                <TableCell align="center">
                                    {(statisticsType === StatisticsType.client || statisticsType === StatisticsType.employee) 
                                        ?
                                            <span className="link" onClick={() => soldTotalCountClick(sale)}>
                                                {sale.soldTotalCount}
                                            </span>
                                        : sale.soldTotalCount
                                    }
                                </TableCell>
                                <TableCell align="center">{sale.soldTotalSum + sale.designSum + sale.installationSum + sale.printSum + sale.additionalInstallationSum}</TableCell>
                                <TableCell align="center">{sale.paidTotalSum}</TableCell>
                                <TableCell align="center">{sale.debtSum}</TableCell>
                            </TableRow>
                        ))}
                        {statistic.length > 0 &&
                            <TableRow>
                                <TableCell>
                                    <strong>{totalItem.name}</strong>
                                </TableCell>
                                {
                                    ((statisticsType === StatisticsType.sale) 
                                        || (statisticsType === StatisticsType.region) 
                                        || (statisticsType === StatisticsType.city) 
                                        || (statisticsType === StatisticsType.constructionType
                                    )) &&
                                    <TableCell align="center">
                                        <strong>{totalItem.usagePercent}%</strong>
                                    </TableCell>
                                }
                                <TableCell align="center">
                                    <strong>{totalItem.soldTotalCount}</strong>
                                </TableCell>
                                <TableCell align="center">
                                    <strong>{allSoldTotalSum()}</strong>
                                </TableCell>
                                <TableCell align="center">
                                    <strong>{totalItem.paidTotalSum}</strong>
                                </TableCell>
                                <TableCell align="center">
                                    <strong>{totalItem.debtSum}</strong>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        statisticsType: state.event.statisticsType
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    curretnSaleAction: (curretnSale: ISales | null) => 
        dispatch(actions.event.curretnSaleAction(curretnSale)),
    projectStatisticsAction: (projectStatistics: boolean) => 
        dispatch(actions.event.projectStatisticsAction(projectStatistics)),
    statisticsTypeAction: (statisticsType: StatisticsType) => 
        dispatch(actions.event.statisticsTypeAction(statisticsType)),
    openSalesInformationAction: (openSalesInformation: boolean) => 
        dispatch(actions.event.openSalesInformationAction(openSalesInformation)),
    openCurrentSalesAction: (openCurrentSales: boolean) => 
        dispatch(actions.event.openCurrentSalesAction(openCurrentSales))
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesStatisticsTable);