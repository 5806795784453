import React, { useState, useEffect, Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { Box, Fab, TextField, Tooltip } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { getMonthYear, MonthYear } from '../../utils/getMonthYear';
import ContextMenu from './ContextMenu';
import { BillboardsShortInfo } from '../../RemoteCommands/type';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    selectedBillboard: BillboardsShortInfo | undefined;
}

const DateInterval:React.FC<Props> = (props) => {
    const { 
        selectedBillboard, 
        statusStatisticsFull, 
        statusStatistics, 
        successSnackbar, 
        selectedSide, 
        billboadStatuses,
        monthYearAction,
        currentGeoMarkStatus,
        fullMonthYearAction
    } =  props;

    const [hidden, setHidden] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentMonth, setCurrentMonth] = React.useState<MonthYear | null>(null);

    const contextMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, item: MonthYear) => {
        setAnchorEl(event.currentTarget);
        setCurrentMonth(item);
        fullMonthYearAction(false);
    };

    const contextMenuFullYear = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
        const firstMonth = getMonthYear(statusStatisticsFull, statusStatistics, selectedBillboard)[0];
        setCurrentMonth(firstMonth);
        fullMonthYearAction(true);
    }
    
    const contextMenuClose = () => {
        setAnchorEl(null);
    };

    const month = [
        {key: '1', label: 'Янв.'},
        {key: '2', label: 'Февр.'},
        {key: '3', label: 'Март'},
        {key: '4', label: 'Апр.'},
        {key: '5', label: 'Май'},
        {key: '6', label: 'Июнь'},
        {key: '7', label: 'Июль'},
        {key: '8', label: 'Авг.'},
        {key: '9', label: 'Сент.'},
        {key: '10', label: 'Окт.'},
        {key: '11', label: 'Нояб.'},
        {key: '12', label: 'Дек.'},
    ];

    const toggleClick = () => {
        setHidden(!hidden);
    }

    useEffect(() => {
        if(selectedBillboard) {
            const builder = new EventBuilder(`EventUserGetYearStatistics`);
            builder.AddParameter('GeoMarkGid', selectedBillboard.gid + selectedSide);
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }
        getMonthYear(statusStatisticsFull, statusStatistics, selectedBillboard);
    }, [successSnackbar, selectedBillboard, billboadStatuses]);

    const getMonthYearInfo = (item: MonthYear) => {
        const currentStatusStatistics = statusStatisticsFull.filter(statistic => 
            statistic.Month.toString() === item.month && statistic.Year.toString() === item.year);
        const tooltipInfo = currentStatusStatistics.map(currentStatus => {
            if(currentStatus.Count === 0) {
                return;
            }
            return (
                <div 
                    key={currentStatus.Month + Math.random()}
                    className="statisticTooltip">
                    {currentStatus.Description}
                </div>
            );
        });
        return tooltipInfo;
    }

    useEffect(() => {
        const newMonthYear = getMonthYear(statusStatisticsFull, statusStatistics, selectedBillboard);
        monthYearAction(newMonthYear);
    }, []);

    useEffect(() => {
        if(!hidden) {
            const builder = new EventBuilder(`EventUserGetFullYearStatistics`);
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }
        getMonthYear(statusStatisticsFull, statusStatistics, selectedBillboard);
    }, [currentGeoMarkStatus, hidden]);

    return (
        <div className="dateInterval" style={{transform: `translateY(${hidden ? '-100%' : 0})`}}>
            <div className="dateInterval__GID">
                <TextField id="outlined-basic" label="GID" value={selectedBillboard ? selectedBillboard.gid + selectedSide : 'Все'} variant="outlined" size="small" disabled />
            </div>
            <div className="dateInterval__content">
                <div 
                    className="dateInterval__item"
                    onContextMenu={(event) => contextMenuFullYear(event)}>
                    <span 
                        className="dateInterval__title"
                        onContextMenu={(e)=> e.preventDefault()}>Мес</span>
                    <span 
                        className="dateInterval__title"
                        onContextMenu={(e)=> e.preventDefault()}>Год</span>
                </div>
                {getMonthYear(statusStatisticsFull, statusStatistics, selectedBillboard).map((item) => {
                    const filterMonth = month.filter(m => m.key === item.month);
                    const intervalItem = (
                        <Box 
                            className="dateInterval__item" 
                            style={{backgroundColor: item.color}}
                            onContextMenu={(event) => contextMenuOpen(event, item)} 
                        >
                            <span className="dateInterval__span" 
                                onContextMenu={(e)=> e.preventDefault()}>{filterMonth[0] ? filterMonth[0].label : item.month}</span>
                            <span className="dateInterval__span" 
                                onContextMenu={(e)=> e.preventDefault()}>{item.year}</span>
                        </Box>
                    );
                    if(!selectedBillboard) {
                        return <Tooltip key={item.month} title={getMonthYearInfo(item)}>{intervalItem}</Tooltip>
                    } else {
                        return <span key={item.month}>{intervalItem}</span>
                    }
                })}
                {currentMonth && 
                    <ContextMenu 
                        contextMenuClose={contextMenuClose}
                        anchorEl={anchorEl} 
                        currentMonthYear={currentMonth} />
                }
            </div>
            <Fab color="primary" size="medium" 
                className="dateInterval__btn"
                onClick={toggleClick}>
                <KeyboardArrowUpIcon fontSize="large" style={{transform: `rotate(${hidden ? 180 : 0}deg)`}} />
            </Fab>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    statusStatisticsFull: state.event.statusStatisticsFull,
    statusStatistics: state.event.statusStatistics,
    successSnackbar: state.billboards.successSnackbar,
    selectedSide: state.billboards.selectedSide,
    billboadStatuses: state.billboards.billboadStatuses,
    currentGeoMarkStatus: state.todo.currentGeoMarkStatus
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    monthYearAction: (monthYear: MonthYear[]) => 
        dispatch(actions.event.monthYearAction(monthYear)),  
    fullMonthYearAction: (fullMonthYear: boolean) => 
        dispatch(actions.event.fullMonthYearAction(fullMonthYear)), 
});

export default connect(mapStateToProps, mapDispatchToProps)(DateInterval);