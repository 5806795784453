import { FormControl, Input } from '@material-ui/core';
import React from 'react';

type Props = {
    value: string | number;
    name: string;
    changeInputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const AdditionalCostFormControl:React.FC<Props> = (props) => {
    const {
        value,
        name,
        changeInputHandler,
        disabled
    } = props;

    return (
        <FormControl 
            className="additionalCostFormControl cancelDraggable">
            <Input
                type="number"
                value={value}
                name={name}
                onChange={changeInputHandler}
                disabled={disabled}
            />
        </FormControl>
    );
}

export default AdditionalCostFormControl;