import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { ActionFromBackendButton } from '../../../../../store/todo/types';
import ActionButton from './ActionButton';
import { Button } from '@material-ui/core';
import SupervisedUserCircleSharpIcon from '@material-ui/icons/SupervisedUserCircleSharp';


type Props = ReturnType<typeof mapDispatchToProps> & {
    actionButtons: ActionFromBackendButton[]
}


const ButtonsGroup: React.FunctionComponent<Props> = ({ actionButtons, agenciesOpenAction }) => {

    const buttonsItems = actionButtons.map(button =>
        <ActionButton key={button.FrontendAction + Math.random()} actionButton={button} />
    )

    return (
        <div className="buttonGroup">
            <Button 
                variant="contained" 
                color="primary"
                size="small"
                startIcon={<SupervisedUserCircleSharpIcon />}
                onClick={() => agenciesOpenAction(true)}
                className="agencyButton">
                <span className="buttonText">Агентства</span>
            </Button>
            {buttonsItems}
        </div>
        );
}

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    agenciesOpenAction: (agenciesOpen: boolean) => dispatch(actions.event.agenciesOpenAction(agenciesOpen)),
});

export default connect(null, mapDispatchToProps)(ButtonsGroup);
