import React, { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';       
import { IconButton, CardHeader, Grid, AppBar, Tabs, Tab, Box, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DefaultPosition } from '../../store/app/types';
import SalesStatisticsTable from './SalesStatisticsTable';
import moment from 'moment';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { SalesPaymentStatisticFilter, SalesPaymentStatisticResult, StatisticFilter, StatisticRegionFilter, BillboardUsageFilter, IBillboardsUsageInfoItems, BillboardsAverageMonthUsage } from '../../RemoteCommands/type';
import GraphsCharts from '../bricks/GraphsCharts';
import { DatesRange } from '../bricks/InputDatesRange';
import { MonthYear } from '../../utils/getMonthYear';
import { GeoMarkStatus } from 'sedi-webserverproxy';
import { RollUpCard, StatisticsType } from '../../store/event/types';
import CurrentSalesStatistic from './CurrentSalesStatistic';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import StatisticsToPrint from './StatisticsToPrint';
import DatePicker from "react-datepicker";
import BillBoardsUsageInfo from '../BillBoardsUsageInfo/BillBoardsUsageInfo';
import GraphsChartsUsageInfo from '../bricks/GraphsChartsUsageInfo';
import RegionAutocomplete from '../bricks/RegionAutocomplete';
import CityAutocomplete from '../bricks/CityAutocomplete';
import './Statistics.scss';
import ProjectStatistics from './ProjectStatistics';
import RemoveIcon from '@material-ui/icons/Remove';
import SalesInformation from './SalesInformation';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

const SalesStatistics:React.FC<Props> = (props) => {
    const {
        draggable,
        positionSalesStatistics,
        positionSalesStatisticsAction,
        salesStatisticsToggleAction,
        salesStatisticsToggle,
        currentMonthYear,
        monthYears,
        statisticsType,
        fullMonthYear,
        curretnSale,
        projectStatistics,
        rollUpCardAction,
        rollUpCards,
        openSalesInformation,
        openCurrentSales,
        zIndex
    } = props;

    const lastMonthYear = monthYears[monthYears.length - 1];
    const dateFrom = new Date(`${monthYears[0].year}-${monthYears[0].monthNumber}-01`);
    const dateTo = new Date(`${lastMonthYear.year}-${lastMonthYear.monthNumber}-01`);

    const [salesPaymentStatisticResult, setSalesPaymentStatisticResult] = useState<SalesPaymentStatisticResult | null>(null);
    const [billboardsUsageInfoResult, setBillboardsUsageInfoResult] = useState<IBillboardsUsageInfoItems[]>([]);
    const [regionName, setRegionName] = useState<string | null| undefined>(undefined);
    const [cityName, setCityName] = useState<string | null| undefined>(undefined);
    const [value, setValue] = React.useState(0);
    const [dates, setDates] = useState<DatesRange>({
        DateFrom: undefined,
        DateTo: undefined
    });
    const [billboardsAverageMonthUsage, setBillboardsAverageMonthUsage] = useState<BillboardsAverageMonthUsage[]>([]);
    const [totalData, setTotalData] = useState<BillboardsAverageMonthUsage[]>([]);
    const [isFixedSum, setIsFixedSum] = useState(false);

    const componentRef: any = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    useEffect(() => {
        if(dates.DateFrom && dates.DateTo) {
            const monthCount = monthDiff(dates.DateFrom, dates.DateTo);
            if(monthCount >= 12 || monthCount === 0) {
                const date = new Date(dates.DateFrom);
                const dateTo = new Date(date.setMonth(date.getMonth() + 11));
                setDates(prevState => ({
                    ...prevState,
                    DateTo: dateTo
                }))
            }
        }
    }, []);

    useEffect(() => {
        if(dates.DateFrom && dates.DateTo) {
            const monthCount = monthDiff(dates.DateFrom, dates.DateTo);
            if(monthCount >= 12 || monthCount === 0) {
                const date = new Date(dates.DateTo);
                const dateFrom = new Date(date.setMonth(date.getMonth() - 11));
                setDates(prevState => ({
                    ...prevState,
                    DateFrom: dateFrom
                }))
            }
        }
    }, []);

    const dateFromChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateFrom: date ? date : undefined
        }));
    }

    const dateToChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateTo: date ? date : undefined
        }));
    }

    useEffect(() => {
        if(salesStatisticsToggle) {
            setDates({
                DateFrom: dateFrom,
                DateTo: dateTo
            });
        }
    }, [salesStatisticsToggle]);

    useEffect(() => {
        if(fullMonthYear) {
            return (
                setDates({
                    DateFrom: dateFrom,
                    DateTo: dateTo
                })
            )
        };
        if((currentMonthYear && statisticsType !== StatisticsType.sale) || (currentMonthYear && statisticsType !== StatisticsType.loadingConstructions)) {
            const dateFromClient = new Date(`${currentMonthYear.year}-${currentMonthYear.monthNumber}-01`);
            setDates({
                DateFrom: dateFromClient,
                DateTo: dateFromClient
            });
        }
        if(statisticsType === StatisticsType.sale || statisticsType === StatisticsType.loadingConstructions) {
            setDates({
                DateFrom: dateFrom,
                DateTo: dateTo
            });
        }
    }, [currentMonthYear, fullMonthYear]);

    const monthDiff = (dateFrom: Date, dateTo: Date) => {
        let months;
            months = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
            months -= dateFrom.getMonth();
            months += dateTo.getMonth();
        return months <= 0 ? 0 : months;
    }

    const intervalMonthYears = () => {
        if(!dates.DateFrom || !dates.DateTo) {
            return monthYears;
        }

        const fromDate = dates.DateFrom;
        const result: MonthYear[] = [];
        const intervalCount = monthDiff(dates.DateFrom, dates.DateTo);
        for(let i = 0; i < intervalCount; i++) {
            const dateStart = moment(fromDate, "YYYY-MM-DD").add(i, 'month');
            result.push(new MonthYear(dateStart.format("MMM"), parseInt(dateStart.format('M')), dateStart.format("YYYY"), "", GeoMarkStatus._FreeOnlyForFrontend));
        }
        return result;
    }

    const handleChangeDate = (isFirstDate: boolean, date: Date | null) => {
        const newDateValue = date ? date : undefined;

        let newDates = dates;
        if (isFirstDate) {
            newDates.DateFrom = newDateValue
        } else {
            newDates.DateTo = newDateValue
        }
        setDates(newDates);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        positionSalesStatisticsAction(position);
    };

    const salesStatisticsClose = () => {
        salesStatisticsToggleAction(false)
    }

    const changeStatisticsHandler = useCallback(() => {
        if(dates.DateFrom && dates.DateTo) {
            let salesPaymentStatisticFilter: SalesPaymentStatisticFilter = {
                Year: Number(moment(dates.DateFrom).format('YYYY')),
                Month: Number(moment(dates.DateFrom).format('M')),
                YearTo: Number(moment(dates.DateTo).format('YYYY')),
                MonthTo: Number(moment(dates.DateTo).format('M'))
            };

            let salesPaymentGrouping: string | undefined = undefined;
            if(statisticsType === StatisticsType.client) {
                salesPaymentGrouping = 'BuyerAccountId';
            } else if(statisticsType === StatisticsType.employee) {
                salesPaymentGrouping = 'SellerAccountId';
            } else if(statisticsType === StatisticsType.region) {
                salesPaymentGrouping = 'Region';
            } else if (statisticsType === StatisticsType.city) {
                salesPaymentGrouping = 'City';
            } else if(statisticsType === StatisticsType.constructionType) {
                salesPaymentGrouping = 'ConstructionType';
            } else {
                salesPaymentGrouping = undefined;
            }

            if(statisticsType === StatisticsType.loadingConstructions) {
                const billboardUsageFilter: BillboardUsageFilter = {
                    year: salesPaymentStatisticFilter.Year,
                    month: salesPaymentStatisticFilter.Month,
                    yearTo: salesPaymentStatisticFilter.YearTo,
                    monthTo: salesPaymentStatisticFilter.MonthTo,
                    region: regionName,
                    city: cityName === "Все" ? null : cityName
                }
                const monthCount = monthDiff(dates.DateFrom, dates.DateTo);
                //if(monthCount > 0 && monthCount < 12) {
                    SystemEvent.EventGetBillBoardsUsageInfo(billboardUsageFilter, isFixedSum);
                //}
            } else {
                SystemEvent.EventGetSalesPaymentStatistic(salesPaymentStatisticFilter, salesPaymentGrouping);
            }
        }
    }, [dates, regionName, cityName, isFixedSum]);

    useEffect(() => {
        changeStatisticsHandler();
    }, [dates, regionName, cityName, isFixedSum]);

    useEffect(() => {
        if(curretnSale) {
            if(statisticsType === StatisticsType.region) {
                const statisticFilter: StatisticRegionFilter = {
                    Year: parseInt(moment(dates.DateFrom).format('YYYY')),
                    Month: parseInt(moment(dates.DateFrom).format('M')),
                    YearTo: parseInt(moment(dates.DateTo).format('YYYY')),
                    MonthTo: parseInt(moment(dates.DateTo).format('M')),
                    RegionName: curretnSale.name
                };
                SystemEvent.EventGetSalesPaymentRegionStatistic(statisticFilter);
            } else {
                const statisticFilter: StatisticFilter = {
                    Year: parseInt(moment(dates.DateFrom).format('YYYY')),
                    Month: parseInt(moment(dates.DateFrom).format('M')),
                    YearTo: parseInt(moment(dates.DateTo).format('YYYY')),
                    MonthTo: parseInt(moment(dates.DateTo).format('M')),
                    BuyerAccountId: statisticsType === StatisticsType.client ? curretnSale.accountId : undefined,
                    SellerAccountId: statisticsType === StatisticsType.employee ? curretnSale.accountId : undefined
                };
                SystemEvent.EventGetSalesPaymentAccountStatistic(statisticFilter);
            }
        }
    }, [dates]);

    useEffect(() => {
        SystemEvent.SubscribeEventGetSalesPaymentStatistic(
            "EventGetSalesPaymentStatistic", 
            (answer) => {
                const answerSales = answer.salesPaymentStatisticResult;
                setSalesPaymentStatisticResult(answerSales);
            }, 
            (error) => errorCallback(error)
        );

        SystemEvent.SubscribeEventGetSalesPaymentRegionStatistic(
            "EventGetSalesPaymentRegionStatistic", 
            (answer) => {
                console.log('answer:', answer)
            }, 
            (error) => errorCallback(error)
        );
        SystemEvent.SubscribeEventGetBillBoardsUsageInfo(
            "EventGetBillBoardsUsageInfo", 
            (answer) => {
                setBillboardsUsageInfoResult(answer.billboardsUsageInfoResult);
                setBillboardsAverageMonthUsage(answer.billboardsAverageMonthUsage);
                setTotalData(answer.totalData);
            }, 
            (error) => errorCallback(error)
        );
    }, []);

    const salesGrouping = () => {
        switch (statisticsType as StatisticsType) {
            case StatisticsType.client:
                return 'по клиентам'
            case StatisticsType.employee:
                return 'по сотрудникам';
            case StatisticsType.region:
                return 'по регионам';
            case StatisticsType.city:
                return 'по городам (районам)';
            case StatisticsType.constructionType:
                return 'по типам конструкций';
            default: 
                return;
        }
    }

    const total = isFixedSum ? totalData  : billboardsAverageMonthUsage;

    const tabPanelView = () => {
        if(statisticsType === StatisticsType.loadingConstructions) {
            return (
                <>
                    <TabPanel value={value} index={0}>
                        <BillBoardsUsageInfo 
                            billboardsUsageInfoResult={billboardsUsageInfoResult} 
                            dates={dates} 
                            isFixedSum={isFixedSum} 
                            totalData={totalData} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <GraphsChartsUsageInfo 
                            billboardsUsageInfoResult={billboardsUsageInfoResult}
                            billboardsAverageMonthUsage={total}
                            dates={dates} 
                            isFixedSum={isFixedSum} />
                    </TabPanel>
                </>
            )
        } else {
            if(!currentMonthYear || !salesPaymentStatisticResult) {
                return null
            }
            return (
                <>
                    <TabPanel value={value} index={0}>
                        <SalesStatisticsTable 
                            salesPaymentStatisticResult={salesPaymentStatisticResult} 
                            dates={dates} 
                            setRegionName={setRegionName} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <GraphsCharts 
                            salesPaymentStatisticResult={salesPaymentStatisticResult} 
                            intervalMonthYears={intervalMonthYears} 
                            statisticsType={statisticsType} />
                    </TabPanel>
                    <div className="totalStatistics">
                        {`
                            Всего продано/Оплачено: 
                            ${salesPaymentStatisticResult.totalItem.soldTotalCount} 
                            поверхности на Сумму 
                            ${
                                salesPaymentStatisticResult.totalItem.soldTotalSum 
                                    + salesPaymentStatisticResult.totalItem.designSum 
                                    + salesPaymentStatisticResult.totalItem.installationSum 
                                    + salesPaymentStatisticResult.totalItem.printSum 
                                    + salesPaymentStatisticResult.totalItem.additionalInstallationSum
                            } руб.
                        `}
                    </div>
                </>
            )
        }
    }

    const rollUpCardHas = rollUpCards.some(item => item.key === "SalesStatistics");
    const rolleUpClick = () => {
        rollUpCardAction({
            title: `Статистика`,
            key: "SalesStatistics"
        });
    }

    return (
        <>
        <Draggable
            handle=".selectedCard"
            defaultPosition={positionSalesStatistics}
            disabled={!draggable}
            onStop={onStop}>
            <Card 
                className={`
                    selectedCard salesStatistics constructionStatistics 
                    ${salesStatisticsToggle ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto', zIndex: zIndex}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Распечатать"
                                placement="top">
                                <IconButton 
                                    onClick={handlePrint}>
                                    <PrintIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                className="ml-1"
                                onClick={salesStatisticsClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid 
                            container 
                            spacing={2} 
                            alignItems="center"
                            justify="space-between">
                            <Grid item xs={12}>
                                <Grid 
                                    container 
                                    spacing={1} 
                                    alignItems="center">
                                    {statisticsType === StatisticsType.loadingConstructions ?
                                        <Grid item xs={12}>
                                            <div className="box-title">
                                                Статистика загрузки конструкций
                                            </div>
                                        </Grid>
                                    : 
                                        <Grid item>
                                            <div className="box-title">
                                                {/* Продажи {statisticsType !== StatisticsType.sale && (moment(currentMonthYear.month, 'MM').format('MMMM') + ' ' + currentMonthYear.year)} */}
                                                Продажи {salesGrouping()} {regionName ? ", " + regionName : ""}
                                            </div>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <div className="chartDate">
                                            {/* <TextField
                                                label="Дата от"
                                                type="month"
                                                value={dates.DateFrom ? moment(dates.DateFrom).format('YYYY-MM') : ""}
                                                onChange={(event) => handleChangeDate(true, event)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            /> */}
                                            <DatePicker
                                                selected={dates.DateFrom}
                                                onChange={(date: Date | null) => dateFromChange(date)}
                                                selectsStart
                                                startDate={dates.DateFrom}
                                                endDate={dates.DateTo}
                                                dateFormat="MMMM yyyy"
                                                showMonthYearPicker
                                                isClearable
                                                locale="ru"
                                                placeholderText="Дата от"
                                            />
                                            <DatePicker
                                                selected={dates.DateTo}
                                                onChange={(date: Date | null) => dateToChange(date)}
                                                selectsStart
                                                startDate={dates.DateFrom}
                                                endDate={dates.DateTo}
                                                dateFormat="MMMM yyyy"
                                                isClearable
                                                showMonthYearPicker
                                                locale="ru"
                                                placeholderText="Дата до"
                                            />
                                            {/* <TextField
                                                label="Дата до"
                                                type="month"
                                                value={dates.DateTo ? moment(dates.DateTo).format('YYYY-MM') : ""}
                                                onChange={(event) => handleChangeDate(false, event)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            /> */}
                                        </div>
                                    </Grid>
                                    {statisticsType === StatisticsType.loadingConstructions &&
                                        <>
                                            <Grid item xs={3}>
                                                <RegionAutocomplete 
                                                    regionName={regionName}
                                                    setRegionName={setRegionName} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CityAutocomplete 
                                                    cityName={cityName} 
                                                    setCityName={setCityName} 
                                                    regionName={regionName} />
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="pt-0">
                    <AppBar 
                        position="static" 
                        color="default"
                        className="appBarStatistics">
                        <Tabs 
                            value={value} 
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary">
                            <Tab label="Таблица" {...a11yProps(0)} />
                            <Tab label="Диаграмма" {...a11yProps(1)} />
                        </Tabs>
                        {statisticsType === "loadingConstructions" &&
                            <FormControlLabel
                                className="isFixedSum"
                                control={
                                    <Checkbox
                                        checked={isFixedSum}
                                        onChange={(event) => setIsFixedSum(event.target.checked)}
                                        name="isFixedSum"
                                        color="primary"
                                    />
                                }
                                label="по деньгам"
                            />
                        }
                    </AppBar>
                    {tabPanelView()}
                </CardContent>
            </Card>  
        </Draggable>
        {openCurrentSales &&
            <CurrentSalesStatistic 
                dates={dates} />
        }
        {projectStatistics &&
            <ProjectStatistics 
                dates={dates} />
        }
        {openSalesInformation &&
            <SalesInformation 
                dates={dates} />
        }
        {salesPaymentStatisticResult && 
            <div style={{ display: "none" }}>
                <StatisticsToPrint 
                    salesPaymentStatisticResult={salesPaymentStatisticResult} 
                    intervalMonthYears={intervalMonthYears} 
                    statisticsType={statisticsType}
                    title={salesGrouping()}
                    ref={componentRef}
                />
            </div>
        }
        </>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box pt={3}>{children}</Box>
            )}
        </div>
    );
}
  
function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        positionSalesStatistics: state.app.positionSalesStatistics,
        salesStatisticsToggle: state.event.salesStatisticsToggle,
        currentMonthYear: state.event.currentMonthYear,
        monthYears: state.event.monthYear,
        statisticsType: state.event.statisticsType,
        fullMonthYear: state.event.fullMonthYear,
        curretnSale: state.event.curretnSale,
        projectStatistics: state.event.projectStatistics,
        rollUpCards: state.event.rollUpCards,
        openSalesInformation: state.event.openSalesInformation,
        openCurrentSales: state.event.openCurrentSales,
        zIndex: state.event.zIndex
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    positionSalesStatisticsAction: (positionSalesStatistics: DefaultPosition) => 
        dispatch(actions.app.positionSalesStatisticsAction(positionSalesStatistics)),
    salesStatisticsToggleAction: (salesStatisticsToggle: boolean) => 
        dispatch(actions.event.salesStatisticsToggleAction(salesStatisticsToggle)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard))
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesStatistics);