import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import { SystemEvent } from '../../../RemoteCommands/SystemEvent';
import { IAdditionalCostParameters } from '../../../RemoteCommands/type';
import AdditionalCostTableRow from './AdditionalCostTableRow';
import { DatesRange } from '../../bricks/InputDatesRange';
import moment from 'moment';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    additionalCostItem: IAdditionalCostParameters;
    additionalCostParameters: IAdditionalCostParameters[];
    disabledSaveScore?: React.MutableRefObject<boolean>;
    scoreSaveHandler?: (disabled: boolean) => void;
    duplicateClick: (item: IAdditionalCostParameters) => void;
    duplicateAddCost?: IAdditionalCostParameters;
    resetDuplicate: () => void;
    deleteHandle: (item: IAdditionalCostParameters) => void;
};

const AdditionalCostTableRowContainer:React.FC<Props> = (props) => {
    const { 
        additionalCostItem,
        projectID,
        additionalCostParameters,
        currentGeoMarkStatus,
        scoreSaveHandler,
        disabledEditAction,
        disabledEdit,
        disabledScoreSaveAction,
        duplicateClick,
        duplicateAddCost,
        resetDuplicate,
        deleteHandle
    } = props;
    const [editChecked, setEditChecked] = useState(Boolean(duplicateAddCost));
    const [aditionalCostState, setAditionalCostState] = useState<IAdditionalCostParameters>(additionalCostItem);
    const [_additionalCostParameters, setAdditionalCostParameters] = useState<IAdditionalCostParameters[]>(additionalCostParameters);
    const [dates, setDates] = useState<DatesRange>({
        DateFrom: initialDate(additionalCostItem.month, additionalCostItem.year),
        DateTo: undefined
    });

    useMemo(() => {
        if(editChecked && duplicateAddCost) {
            setAditionalCostState(duplicateAddCost);
        } else {
            setAditionalCostState(additionalCostItem);
        }
    }, [additionalCostItem]);

    useMemo(() => {
        setAdditionalCostParameters(additionalCostParameters);
    }, [additionalCostParameters]);

    const dateFromChange = (date: Date | null) => {
        setDates(prevState => ({
            ...prevState,
            DateFrom: date ? date : undefined
        }));
        const month = date ? +moment(date).format('M') : 0;
        const year = date ? +moment(date).format('YYYY') : 0;
        setAditionalCostState(prevState => ({
            ...prevState,
            month,
            year
        }));
    }

    const getSum = (value: number, name: string) => {
        let sum: number;
        if(name === "itemsCount") {
            sum = Math.floor(aditionalCostState.itemCost * value)
        } 
        if(name === "itemCost") {
            sum = Math.floor(value * aditionalCostState.itemsCount)
        }

        setAditionalCostState(prevState => ({
            ...prevState,
            totalCost: sum
        }));
    }

    const getCost = (sum: number) => {
        const { itemsCount } = aditionalCostState;
        const cost = Math.floor(sum / itemsCount);
        setAditionalCostState(prevState => ({
            ...prevState,
            itemCost: cost
        }));
    }

    const editHandleClick = () => {
        setEditChecked(true);
        disabledEditAction(true);
        if(scoreSaveHandler) {
            scoreSaveHandler(true);
        }
    }

    const editHandleClose = () => {
        setEditChecked(false);
        resetDuplicate();
        disabledEditAction(false);
        setAditionalCostState(additionalCostItem);
        disabledScoreSaveAction(false);
    }

    useEffect(() => {
        setAdditionalCostParameters(
            getItemsWithUpdateItem(_additionalCostParameters, aditionalCostState)
        );
    }, [aditionalCostState]);

    const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        if(event.target.name === "totalCost" && aditionalCostState.itemsCount === 0) {
            return alert("Нельзя задавать итоговую сумму при нулевом количестве");
        }

        getSum(parseInt(event.target.value), event.target.name);

        if(event.target.name === "totalCost")  {
            getCost(parseInt(event.target.value));
            setAditionalCostState(prevState => ({...prevState, ...{
                totalCost: parseInt(event.target.value)
            }}));
        } else {
            setAditionalCostState(prevState => ({...prevState, ...{
                [event.target.name]: parseInt(event.target.value)
            }}));
        }
    };

    const saveProjectAdditionalCosts = () => {
        SystemEvent.EventSaveProjectAdditionalCosts(projectID, _additionalCostParameters);
        disabledEditAction(false);
        setEditChecked(false);
        resetDuplicate();
        if(scoreSaveHandler) {
            scoreSaveHandler(false);
        }
    }

    return (
        <>
            <AdditionalCostTableRow 
                aditionalCostState={aditionalCostState} 
                editChecked={editChecked} 
                editHandleClick={editHandleClick} 
                editHandleClose={editHandleClose} 
                saveProjectAdditionalCosts={saveProjectAdditionalCosts} 
                changeInputHandler={changeInputHandler}
                currentGeoMarkStatus={currentGeoMarkStatus} 
                disabledEdit={disabledEdit} 
                dates={dates} 
                dateFromChange={dateFromChange} 
                duplicateClick={duplicateClick} 
                deleteHandle={deleteHandle} />
        </>
    );
}

function getItemsWithUpdateItem(items: IAdditionalCostParameters[], item: IAdditionalCostParameters) {
    return items.map((_item) => {
        if (_item.id === item.id && _item.costName === item.costName && _item.condition === item.condition && _item.billId === item.billId) {
            return item;
        }
        return _item;
    });
}

const mapStateToProps = (state: RootState) => ({
    projectID: state.todo.projectID,
    currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
    disabledEdit: state.event.disabledEdit
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    disabledEditAction: (disabledEdit: boolean) => 
        dispatch(actions.event.disabledEditAction(disabledEdit)),
    disabledScoreSaveAction: (disabledScoreSave: boolean) => 
        dispatch(actions.event.disabledScoreSaveAction(disabledScoreSave))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCostTableRowContainer);

function initialDate(month: number, year: number) {
    if(month === 0 && year === 0) {
        return undefined;
    }
    return new Date(year, month - 1, 1)
}