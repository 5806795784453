import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ClientInfo } from 'sedi-webserverproxy';

type Props = {
    companyEmail: string
    individualEmail: ClientInfo[],
    emailChange: (value: string) => void
}

const SendEmailSelect:React.FC<Props> = ({companyEmail, individualEmail, emailChange}) => {
    const classes = useStyles();
    const [email, setEmail] = React.useState('');

    const inputLabelEmail = React.useRef<HTMLLabelElement>(null);
    const [labelWidthEmail, setLabelWidthEmail] = React.useState(0);
    React.useEffect(() => {
        setLabelWidthEmail(inputLabelEmail.current!.offsetWidth);
    }, []);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEmail(event.target.value as string);
        emailChange(event.target.value as string);
    };

    return (
        <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel ref={inputLabelEmail} id="select-outlined-labels">Email адрес</InputLabel>
            <Select
                autoFocus
                labelId="select-outlined-labels"
                id="select-outlineds"
                value={email}
                onChange={handleChange}
                labelWidth={labelWidthEmail}>
                {companyEmail !== '' && <MenuItem value={companyEmail}>{companyEmail}</MenuItem>}
                {individualEmail.map(item => {
                    if(item.emails[0]) {
                        return (
                            <MenuItem 
                                value={item.emails[0].email} 
                                key={`send-email-${item.id}`}>
                                {item.emails[0].email}
                                <span className={classes.name}>{item.name}</span>
                            </MenuItem>
                        )
                    }
                })}
            </Select>
        </FormControl>
    );
}

export default SendEmailSelect

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      opacity: 0.7,
      fontSize: 12,
      marginLeft: 10
    }
  }),
)