import isEmail from 'validator/lib/isEmail'

export type EmailValue = {
    value: string
    isValid: boolean
}

function validateEmail(value: string):EmailValue {
    const isValid = isEmail(value)

    const validateEmail = {
        value: value,
        isValid
    }

    return validateEmail
}

export {validateEmail}