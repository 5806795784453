import { TodoActionsState, ActionFromBackend } from './types';


export function getLastTodoAction(state: TodoActionsState) : ActionFromBackend | undefined {
  return state.TodoActions[state.TodoActions.length - 1];
}


export function getAllTodoActions(state: TodoActionsState) : ActionFromBackend[] {
  return state.TodoActions;
}


export function hasTodoActions(state: TodoActionsState) : boolean {
  return state.TodoActions.length > 0;
}


export function getProjectID(state: TodoActionsState) {
  return state.projectID;
}

export function getOpenProjectModal(state: TodoActionsState) {
  return state.openProjectModal;
}

export function getCurrentCompanyAccountId(state: TodoActionsState) {
  return state.currentCompanyAccountId;
}