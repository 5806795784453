import React from 'react';
import { connect } from 'react-redux';
import { GeoMarkTimeStatus } from 'sedi-webserverproxy';
import { BillboardsShortInfo } from '../../RemoteCommands/type';
import { RootState, selectors } from '../../store';

type Props = 
    ReturnType<typeof mapStateToProps> & {
        billboardID: number
}

const NextBillboardSideTooltip: React.FunctionComponent<Props> = (props) => {
    const { 
        billboardID, 
        billboadStatuses, 
        billboardsShortInfo 
    } = props;

    const currentStatuses: GeoMarkTimeStatus[] = billboadStatuses.filter(status => status.geoMarkId === billboardID);
    const currentBillboard = billboardsShortInfo.find(billboard => billboard.geoMarkId === billboardID) as BillboardsShortInfo;

    // const sideACount = currentBillboard.sideACount;
    // const sideBCount = currentBillboard.sideBCount;

    const sideACount = 6;
    const sideBCount = 6;

    const geoMarkStatuses = (geoMarkStatus: string, side: string) => {
        const filterStatuses = currentStatuses.filter(status => 
            status.geoMarkId === billboardID && status.geoMarkStatus === geoMarkStatus);

        return filterStatuses.filter(item => item.geoMarkPart.includes(side)).length; 

    }

    const freeAllCountA = sideACount - geoMarkStatuses('Reserved', 'A');
    const freeAllCountB = sideBCount - geoMarkStatuses('Reserved', 'B');

    const free   =  `Свободно сторона А - 
        ${freeAllCountA > 0 ? freeAllCountA : 0}, 
        B - ${freeAllCountB > 0 ? freeAllCountB : 0}`;
    const reserved =  `Резерв сторона А - 
        ${geoMarkStatuses('Reserved', 'A')}, B - ${geoMarkStatuses('Reserved', 'B')}`;
    const booked =  `Бронь сторона А - 
        ${geoMarkStatuses('Booked', 'A')}, B - ${geoMarkStatuses('Booked', 'B')}`;
    const sold   =  `Продано сторона А - 
        ${geoMarkStatuses('Sold', 'A')}, B - ${geoMarkStatuses('Sold', 'B')}`;
    const paid   =  `Оплачено сторона А - 
        ${geoMarkStatuses('Paid', 'A')}, B - ${geoMarkStatuses('Paid', 'B')}`;

    return (
        <ul className="tooltip-list-group">
            <li>{free}</li>
            {
                (
                    geoMarkStatuses('Reserved', 'A') > 0 
                    || geoMarkStatuses('Reserved', 'B') > 0
                )
                    && <li>{reserved}</li>
            }
            {
                (
                    geoMarkStatuses('Booked', 'A') > 0 
                    || geoMarkStatuses('Booked', 'B') > 0
                )
                    && <li>{booked}</li>
            }
            {
                (
                    geoMarkStatuses('Sold', 'A') > 0 
                    || geoMarkStatuses('Sold', 'B') > 0
                )
                    && <li>{sold}</li>
            }
            {
                (
                    geoMarkStatuses('Paid', 'A') > 0 
                    || geoMarkStatuses('Paid', 'B') > 0
                )
                    && <li>{paid}</li>
            }
        </ul>
    )
}

const mapStateToProps = (state: RootState) => ({
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    billboadStatuses: state.billboards.billboadStatuses,
    statuses: selectors.billboards.getAllStatuses(state.billboards)
});

export default connect(mapStateToProps)(NextBillboardSideTooltip);