import React, { Component } from 'react'
import TextFieldMaterial, { TextFieldProps } from '@material-ui/core/TextField'
import { EmailValue, validateEmail } from '../../utils/validateEmail';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Translate } from 'react-localize-redux';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';

type Props = TextFieldProps & {
    onEmailChange: (value: EmailValue) => void;
    onClickDelete: ((event: any) => void) | undefined;
    IsLegalEntity: boolean | undefined;
    onAddNewEmail?: (() => void) | undefined;
}

type State = EmailValue

export default class InputEmailValidate extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = validateEmail(props.value as string)
    }
    componentDidUpdate(prevProps: Props) {
        if(prevProps.value !== this.props.value) {
            this.setState(validateEmail(this.props.value as string))
        }
    }
    handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        const emailValue = event.target.value
        const newState = validateEmail(emailValue)

        this.setState(newState);
        this.props.onEmailChange(newState)
    }

    render() {
        const { onEmailChange, IsLegalEntity, onClickDelete, onAddNewEmail,  ...inputProps } = this.props
        const { value, isValid } = this.state

        return (
            <TextFieldMaterial 
                {...inputProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onChange={(event) => this.handleChange(event)}
                value={value}
                error={value.length > 0 && (!isValid && true)}
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        {IsLegalEntity !== undefined && IsLegalEntity === false ? null : 
                            <>
                                <IconButton edge="end" onClick={onClickDelete}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                                <Tooltip title={<Translate id='ClientDetailAddEmail' />} placement="top">
                                    <IconButton edge="end" onClick={onAddNewEmail}>
                                        <AddIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </InputAdornment>
                }}
            />
        )
    }
}