import { DatesRange } from '../components/bricks/InputDatesRange';
import { CSSProperties } from '@material-ui/styles';
import { getHighlightColor } from './statusesColor';
import { IBillboardStatuses } from '../RemoteCommands/type';

function getHighlightStyle(gradient: string, dates: DatesRange, status?: IBillboardStatuses): CSSProperties | undefined {
    if (status === undefined 
        || dates.DateFrom === undefined
        || dates.DateTo === undefined
        ) {
        return;
    }

    // копируем даты
    const userDate1 = new Date(dates.DateFrom);
    const userDate2 = new Date(dates.DateTo);
    const statusDate1 = new Date(status.dateTimeStart);
    const statusDate2 = new Date(status.dateTimeEnd);

    // выставляем время 00:00:00.000 для начальных и 23:59:59.999 для конечных
    userDate1.setHours(0, 0, 0, 0);
    userDate2.setHours(23, 59, 59, 999);    
    statusDate1.setHours(0, 0, 0, 0);
    statusDate2.setHours(23, 59, 59, 999);

    const userDateAsNumber1 = userDate1.valueOf();
    const userDateAsNumber2 = userDate2.valueOf();
    const statusDateAsNumber1 = statusDate1.valueOf();
    const statusDateAsNumber2 = statusDate2.valueOf();

    let color1 = '#fff';
    let color2 = '#fff';
    let color3 = '#fff';
    const highlightColor = getHighlightColor(status.status);
    

    // отмечаем отрезки
    let point1 = userDateAsNumber1; // 0%
    let point2 = statusDateAsNumber1; // промежуточная1
    let point3 = statusDateAsNumber2; // промежуточная2 (возможно, промежуточная1 === промежуточная2)
    let point4 = userDateAsNumber2; // 100%

    if (statusDateAsNumber1 < userDateAsNumber1 && statusDateAsNumber2 > userDateAsNumber2) {
        // 100%
        return {
            'backgroundColor': highlightColor
        };
    }
    else if (statusDateAsNumber1 < userDateAsNumber1) {
        // вначале от 0 до X
        point2 = statusDateAsNumber2;
        point3 = statusDateAsNumber2;
        color1 = highlightColor;
    }
    else if (statusDateAsNumber2 > userDateAsNumber2) {
        // вконце от Y до 100
        point2 = statusDateAsNumber1;
        point3 = statusDateAsNumber1;
        color2 = highlightColor;
    }
    else {
        // в середине от X до Y
        point2 = statusDateAsNumber1;
        point3 = statusDateAsNumber2;
        color2 = highlightColor;
    }


    // смещаем систему координат к 0    
    const minPoint = point1;
    //const pointAtZero1 = 0; // point1 - minPoint
    const pointAtZero2 = point2 - minPoint;
    const pointAtZero3 = point3 - minPoint;
    const pointAtZero4 = point4 - minPoint;

    // переводим в проценты
    const maxPointAtZero = pointAtZero4;
    //const pointPercent1 = 0;
    const pointPercent2 = Math.round(pointAtZero2 / maxPointAtZero * 100);
    const pointPercent3 = Math.round(pointAtZero3 / maxPointAtZero * 100);
    //const pointPercent4 = 100;

    let cssBgGradient;
    if (point2 === point3) {
        cssBgGradient = `${color1} 0%, ${color1} ${pointPercent2}%, 
                ${color2} ${pointPercent2}%, ${color2} 100%`;
    }
    else {
        cssBgGradient = `${color1} 0%, ${color1} ${pointPercent2}%, 
                ${color2} ${pointPercent2}%, ${color2} ${pointPercent3}%,
                ${color3} ${pointPercent3}%, ${color3} 100%`;
    }

    if(gradient === 'linear') {
        cssBgGradient = `linear-gradient(to bottom, ${cssBgGradient})`;
    } else if (gradient === 'conic') {
        cssBgGradient = `conic-gradient(${cssBgGradient})`;
    }

    return {
        'backgroundImage': cssBgGradient
    };
}

export function linerGradient(dates: DatesRange, status?: IBillboardStatuses): CSSProperties | undefined {
    return getHighlightStyle('linear', dates, status);
}
export function conicGradient(dates: DatesRange, status?: IBillboardStatuses): CSSProperties | undefined {
    return getHighlightStyle('conic', dates, status);
}

