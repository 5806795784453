/// <reference types="signalr" />
import EventDispatcher from "./Events";
import RemoteCommandHandler from "./RemoteCommandHandler";
import RemoteCommandSynchronizerCollection from "./RemoteCommandSynchronizerCollection";
import BUILD_PARAMS from '../utils/build';
import { localStorageGetItem } from '../utils/storage';
import { ServerProxy } from "./ServerProxy";


export enum ConnectionState {
    Connecting = 0,
    Connected = 1,
    Reconnecting = 2,
    Disconnected = 3
}

export class SignalR {

    private static server = `${BUILD_PARAMS.WEBSERVER_URL}:9011/signalr`;
    public static proxy: any = null;
    private static initialized: boolean = false;
    private static initializing: boolean = false;

    static ConnectionStateChanged = new EventDispatcher();
    private static connectionState = ConnectionState.Disconnected;

    static get ConnectionState(): ConnectionState {
        return SignalR.connectionState;
    }

    static set ConnectionState(value: ConnectionState) {
        SignalR.connectionState = value;
        SignalR.ConnectionStateChanged.dispatch();
    }

    static Initialize() {
        if (SignalR.initializing)
            return;
        SignalR.initializing = true;        
        RemoteCommandSynchronizerCollection.Initialize();
        //@ts-ignore
        var jq = (document as any).jq;
        if (typeof (jq.signalR) !== "function") {
            throw new Error(
                "SignalR: SignalR is not loaded. Please ensure jquery.signalR-x.js is referenced before ~/signalr/js.");
        }

        const signalR = jq.signalR;

        function makeProxyCallback(hub: any, callback: any) {
            return function() {
                // Call the client hub method
                callback.apply(hub, jq.makeArray(arguments));
            };
        }

        function registerHubProxies(instance: any, shouldSubscribe: any) {
            let key: any;
            let hub: any;
            let memberKey: any;
            let memberValue: any;
            let subscriptionMethod: any;

            for (key in instance) {
                if (instance.hasOwnProperty(key)) {
                    hub = instance[key];

                    if (!(hub.hubName)) {
                        // Not a client hub
                        continue;
                    }

                    if (shouldSubscribe) {
                        // We want to subscribe to the hub events
                        subscriptionMethod = hub.on;
                    } else {
                        // We want to unsubscribe from the hub events
                        subscriptionMethod = hub.off;
                    }

                    // Loop through all members on the hub and find client hub functions to subscribe/unsubscribe
                    for (memberKey in hub.client) {
                        if (hub.client.hasOwnProperty(memberKey)) {
                            memberValue = hub.client[memberKey];

                            if (!jq.isFunction(memberValue)) {
                                // Not a client hub function
                                continue;
                            }

                            // Use the actual user-provided callback as the "identity" value for the registration.
                            subscriptionMethod.call(hub, memberKey, makeProxyCallback(hub, memberValue), memberValue);
                        }
                    }
                }
            }
        }

        jq.hubConnection.prototype.createHubProxies = function() {
            var proxies: any = {};
            this.starting(function() {
                // Register the hub proxies as subscribed
                // (instance, shouldSubscribe)

                registerHubProxies(proxies, true);
                //@ts-ignore
                this._registerSubscribedHubs();
            }).disconnected(() => {
                // Unsubscribe all hub proxies when we "disconnect".  This is to ensure that we do not re-add functional call backs.
                // (instance, shouldSubscribe)
                console.log("disconnected");
                registerHubProxies(proxies, false);
            });

            proxies["webManagersHub"] = this.createHubProxy("webManagersHub");
            proxies["webManagersHub"].client = {};
            proxies["webManagersHub"].server = {
                sendCommand(commandData: any) {
                    return proxies["webManagersHub"].invoke.apply(proxies["webManagersHub"],
                        jq.merge(["SendCommand"], jq.makeArray(arguments)));
                }
            };

            return proxies;
        };

        signalR.hub = jq.hubConnection(SignalR.server, { useDefaultPath: false });
        jq.extend(signalR, signalR.hub.createHubProxies());

        const proxy = jq.connection.webManagersHub;
        SignalR.proxy = proxy;

        proxy.client.sendCommand = (command: any) => {
            RemoteCommandHandler.Handle(command);
        };

        jq.connection.hub.stateChanged((e: any) => {
            console.log(e);
            SignalR.ConnectionState = e.newState;
        });

        jq.connection.hub.qs = `apikey=${BUILD_PARAMS.WEBSERVER_APIKEY}`;
        jq.connection.hub.start()
            .done(() => {
                console.log(`Now connected, connection ID=${jq.connection.hub.id}`);
                console.log(`Connected, transport = ${jq.connection.hub.transport.name}`);
                SignalR.initialized = true;
                SignalR.initializing = false;
            })
            .fail(() => {
                console.log("Could not Connect!");
                SignalR.initializing = false;
            });
        jq.connection.hub.disconnected(function() {
            setTimeout(function() {
                jq.connection.hub.start()
                .done(() => {
                    console.log(`Now connected, connection ID=${jq.connection.hub.id}`);
                    console.log(`Connected, transport = ${jq.connection.hub.transport.name}`);
                    SignalR.initialized = true;
                    SignalR.initializing = false;
                    const key = localStorageGetItem('LicenceKey');
                    if(key) {
                        ServerProxy.LoginWithLicenceKey(key);
                    }
                })
                .fail(() => {
                    console.log("Could not Connect!");
                    SignalR.initializing = false;
                });
            }, 5000); // Restart connection after 5 seconds.
        });
    }

    static SendCommand(command: string) {
        if (SignalR.initialized === false) {
            this.Initialize();
            setTimeout(() => SignalR.SendCommand(command), 1000);
        } else {
            SignalR.proxy.server.sendCommand(command);
        }
    }
}