import React from 'react';
import { ActionFromBackendButton, FrontendAction } from '../../../../../store/todo/types';
import Button from './CustomButtons/Button';
import ButtonCompleteCurrentAction from './CustomButtons/CompleteCurrentAction';
import ButtonOpenCallerCompanyProfile from './CustomButtons/OpenCallerCompanyProfile';
import ButtonOpenCallerCustomerProfile from './CustomButtons/OpenCallerCustomerProfile';
import ButtonOpenProjectModal from './CustomButtons/OpenProjectModal';
import SendSystemEventButton from './CustomButtons/SendSystemEventButton';
import ButtonOpenProjectDiscount from './CustomButtons/ButtonOpenProjectDiscount';


type Props = {
    actionButton: ActionFromBackendButton
}


const ActionButton: React.FunctionComponent<Props> = ({ actionButton }) => {
    const customButton = getCustomButtonComponent(actionButton);
    return <>{customButton}</>;
}


const getCustomButtonComponent = (actionButton: ActionFromBackendButton): JSX.Element | undefined => {
    const customButtons = {
        [FrontendAction.CompleteCurrentAction]: ButtonCompleteCurrentAction,
        [FrontendAction.OpenCallerCustomerProfile]: ButtonOpenCallerCustomerProfile,
        [FrontendAction.OpenCallerCompanyProfile]: ButtonOpenCallerCompanyProfile,
        [FrontendAction.OpenProjects]: ButtonOpenProjectModal,
        [FrontendAction.OpenMessageDialog]: ButtonOpenProjectModal,
        [FrontendAction.SendSystemEvent]: SendSystemEventButton,
        [FrontendAction.OpenProjectDiscount]: ButtonOpenProjectDiscount,
    }

    const CustomButtonComponent = customButtons[actionButton.FrontendAction];
    if (CustomButtonComponent === undefined) {
        return <Button text={actionButton.Text}
            onClick={() => alert(`Unknown action '${actionButton.FrontendAction}'`)} />;
    }

    return <CustomButtonComponent action={actionButton} />;   
}


export default ActionButton;
