import React from 'react';
import '../css/toast.css'
import { connect } from 'react-redux';
import PortalToast from './components/PortalToast';
import { toast } from 'react-toastify';
import { RootState, actions } from '../../store';
import { hasTodoActions } from '../../store/todo/selectors';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';


const WATCH_ACTIONS_DELAY = 3000;


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


class Portal extends React.Component<Props>{

    toastID: string | undefined;
    timeoutID: number = -1;
    

    openToast = () => {
        const incomingToastOptions = {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            className: 'incoming-call-toast'
        };

        // Аргумент типа "{ position: string; autoClose: boolean; hideProgressBar: boolean; closeOnClick: boolean; pauseOnHover: boolean; draggable: boolean; closeButton: boolean; className: string; }" нельзя назначить параметру типа "ToastOptions".
        // Типы свойства "autoClose" несовместимы.
        // Тип "boolean" не может быть назначен для типа "number | false | undefined".
        // @ts-ignore
        this.toastID = toast(<PortalToast />, incomingToastOptions);
    }


    closeToast = () => {
        if (this.toastID !== undefined) {
            toast.dismiss(this.toastID);
        }
    }


    componentDidMount() {
        if (this.props.showToast) {
            this.closeToast();
            this.openToast();
        }        

        this.watchGetActions();
    }
    

    componentDidUpdate(prevProps: Props) {
        const { showToast, hasTodoActions } = this.props;

        if (prevProps.showToast !== showToast) {
            if (showToast) {
                this.openToast();
            }
            else {
                this.closeToast();
            }
        }

        if (hasTodoActions === false && prevProps.hasTodoActions === true){
            this.watchGetActions();
        }
    }


    componentWillUnmount(){
        this.closeToast();      
        this.resetTimeoutID();  
    }

    
    resetTimeoutID() {
        if (this.timeoutID > 0){
            clearTimeout(this.timeoutID);
            this.timeoutID = -1;
        }
    }


    watchGetActions(){
        this.resetTimeoutID();

        this.timeoutID = window.setTimeout(() => {
            this.resetTimeoutID();
            
            const { hasTodoActions } = this.props;                        
            if (hasTodoActions){
                return;
            }
            console.warn('NO ACTIONS');
            this.props.getFirstActionCreator();
        }, WATCH_ACTIONS_DELAY);
    }


    render() {
        return (<></>);
    }
}


const mapStateToProps = (state: RootState) => ({
    showToast: state.sip.hasActiveCall || hasTodoActions(state.todo),
    hasTodoActions: hasTodoActions(state.todo)
});


const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    getFirstActionCreator: () => dispatch(actions.todo.getFirstActionCreator()),
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(Portal);