import { ActionCreator, Action } from 'redux';
import { EventActionsTypes, AgencyInfo, IMessageToUser, StatusStatistics, StatusStatisticsFull, BrandGeoObjects, SelectedFocus, RollUpCard, StatisticsType, MessageInfo } from './types';
import { EventParameters } from '../../utils/eventHelpers';
import { IGetAddressItem } from 'sedi-webserverproxy';
import { EventError } from '../../RemoteCommands/SystemEvent';
import { MonthYear } from '../../utils/getMonthYear';
import { AdditionalGeoMarkTariffs, IAdditionalCostParameters, IBillboardsUsageInfoItems, ISales, TariffParameterConditions } from '../../RemoteCommands/type';

export interface IEventPatterns extends Action<EventActionsTypes.EVENT_PATTERNS> {
    eventPatterns: { [id: string]: EventParameters };
}

export interface IAgencyInfo extends Action<EventActionsTypes.GET_AGENCY_INFO> {
    agencyInfo: AgencyInfo[]
}

export interface IMessageToUserAction extends Action<EventActionsTypes.MESSAGE_TO_USER> {
    messageToUser: IMessageToUser
}

export interface IMessageToUserArrAction extends Action<EventActionsTypes.MESSAGE_TO_USERS> {
    messageToUser: IMessageToUser[]
}

export interface ICurrentAgency extends Action<EventActionsTypes.CURRENT_AGENCY> {
    currentAgency: AgencyInfo
}

export interface IEventNameAction extends Action<EventActionsTypes.EVENT_NAME> {
    eventName: string;
}
export interface IAgenciesOpen extends Action<EventActionsTypes.AGENCIES_OPEN> {
    agenciesOpen: boolean;
}

export interface IStatusStatistics extends Action<EventActionsTypes.STATUS_STATISTICS> {
    statusStatistics: StatusStatistics[];
}

export interface IStatusStatisticsFull extends Action<EventActionsTypes.STATUS_STATISTICS_FULL> {
    statusStatisticsFull: StatusStatisticsFull[];
}

export interface IAddNewAgency extends Action<EventActionsTypes.ADD_NEW_AGENCY> {
    addNewAgency: boolean;
}

export interface IErrorMessage extends Action<EventActionsTypes.ERROR_MESSAGE> {
    errorMessage: string;
}

export interface ISuccessMessage extends Action<EventActionsTypes.SUCCESS_MESSAGE> {
    successMessage: string | null;
}

export interface IMaxDistance extends Action<EventActionsTypes.MAX_DISTANCE> {
    maxDistance: string;
}

export interface IMaxDistanceSearch extends Action<EventActionsTypes.MAX_DISTANCE_SEARCH> {
    maxDistanceSearch: boolean;
}

export interface IShowMarkers extends Action<EventActionsTypes.SHOW_MARKERS> {
    showMarkers: boolean;
}

export interface IGetAddresses extends Action<EventActionsTypes.GET_ADDRESSES> {
    addresses: IGetAddressItem[];
}

export interface ISelectedAddress extends Action<EventActionsTypes.SELECTED_ADDRESS> {
    selectedAddress: IGetAddressItem | null;
}

export interface IBrandGeoObjectsAction extends Action<EventActionsTypes.GET_BRAND_GEO_OBJECTS> {
    brandGeoObjects: BrandGeoObjects[];
}
export interface INewBrandGeoObjectsAction extends Action<EventActionsTypes.GET_NEW_BRAND_GEO_OBJECTS> {
    newBrandGeoObjects: BrandGeoObjects[];
}
export interface IBrandGeoObjectAction extends Action<EventActionsTypes.GET_BRAND_GEO_OBJECT> {
    brandGeoObject: BrandGeoObjects;
}

export interface ILoadingAction extends Action<EventActionsTypes.LOADING> {
    loading: boolean;
}

export interface IOpenNextBillboardAction extends Action<EventActionsTypes.OPEN_NEXT_BILLBOARD> {
    openNextBillboard: boolean;
}

export interface IAddressValue extends Action<EventActionsTypes.ADDRESS_VALUE> {
    addressValue: string;
}

export interface ISearchGeoObjectsToggle extends Action<EventActionsTypes.SEARCH_GEO_OBJECTS_TOGGLE> {
    searchGeoObjectsToggle: boolean;
}
export interface ISetBrandBoolean extends Action<EventActionsTypes.SET_BRAND_BOOLEAN> {
    setBrandBoolean: boolean;
}

export interface ISelectedFocus extends Action<EventActionsTypes.SELECTED_FOCUS> {
    selectedFocus: SelectedFocus;
}
export interface ISalesStatisticsToggle extends Action<EventActionsTypes.SALES_STATISTICS_TOGGLE> {
    salesStatisticsToggle: boolean;
}

export interface IEventError extends Action<EventActionsTypes.EVENT_ERROR> {
    eventError: EventError | null;
}
export interface ICurrentMonthYear extends Action<EventActionsTypes.CURRENT_MONTH_YEAR> {
    currentMonthYear: MonthYear | null;
}

export interface IGeoPointNull extends Action<EventActionsTypes.GEO_POINT_NULL> {
    geoPointNull: boolean;
}

export interface IRollUpCard extends Action<EventActionsTypes.ROLL_UP_CARD> {
    rollUpCard: RollUpCard;
}
export interface IRollUpCards extends Action<EventActionsTypes.ROLL_UP_CARDS> {
    rollUpCards: RollUpCard[];
}

export interface IMonthYear extends Action<EventActionsTypes.MONTH_YEAR> {
    monthYear: MonthYear[];
}
export interface IStatisticsType extends Action<EventActionsTypes.STATISTICS_TYPE> {
    statisticsType: StatisticsType;
}

export interface IFullMonthYear extends Action<EventActionsTypes.FULL_MONTH_YEAR> {
    fullMonthYear: boolean;
}

export interface IAdditionalCost extends Action<EventActionsTypes.ADDITIONAL_COST> {
    additionalCost: number;
}

export interface ICurretnSale extends Action<EventActionsTypes.CURRENT_SALE> {
    curretnSale: ISales | null;
}

export interface IChangeProjectStatus extends Action<EventActionsTypes.CHANGE_PROJECT_STATUS> {
    changeProjectStatus: boolean;
}

export interface IDisabledScoreSave extends Action<EventActionsTypes.DISABLED_SCORE_SAVE> {
    disabledScoreSave: boolean;
}

export interface IUseAllFreeGeoMarkParts extends Action<EventActionsTypes.USER_ALL_FREE_GOE_MARK_PARTS> {
    useAllFreeGeoMarkParts: boolean;
}

export interface IUsageInfo extends Action<EventActionsTypes.USAGE_INFO> {
    usageInfo: IBillboardsUsageInfoItems | null;
}

export interface IOpenEmployeesCard extends Action<EventActionsTypes.OPEN_EMPLOYEES_CARD> {
    openEmployeesCard: boolean;
}

export interface IEmployeesCount extends Action<EventActionsTypes.EMPLOYEES_COUNT> {
    employeesCount: number;
}

export interface IAdditionalCostAction extends Action<EventActionsTypes.ADDITIONAL_COST_PARAMETETS> {
    additionalCostParameters: IAdditionalCostParameters[];
}

export interface IDisabledEdit extends Action<EventActionsTypes.DISABLED_EDIT> {
    disabledEdit: boolean;
}

export interface IOpenAdditionalTariff extends Action<EventActionsTypes.OPEN_ADITIONAL_TARIFF> {
    openAdditionalTariff: boolean;
}

export interface IAdditionalTariffs extends Action<EventActionsTypes.ADITIONAL_TARIFFS> {
    additionalTariffs: TariffParameterConditions[];
}

export interface IOpenBillsCard extends Action<EventActionsTypes.OPEN_BILLS_CARD> {
    openBillsCard: boolean;
}

export interface IAdditionalGeoMarkTariffs extends Action<EventActionsTypes.ADDITIONAL_GEO_MARK_TARIFFS> {
    additionalGeoMarkTariffs: AdditionalGeoMarkTariffs[];
}

export interface IAdditionalGeoMarkTariff extends Action<EventActionsTypes.ADDITIONAL_GEO_MARK_TARIFF> {
    additionalGeoMarkTariff: AdditionalGeoMarkTariffs | null;
}

export interface IMessageInfo extends Action<EventActionsTypes.MESSAGE_INFO> {
    messageInfo: MessageInfo | null;
}

export interface IRegions extends Action<EventActionsTypes.REGIONS> {regions: string[]}
export interface ICities extends Action<EventActionsTypes.CITIES> {cities: string[]}
export interface IProjectStatistics extends Action<EventActionsTypes.PROJECT_STATISTICS> {projectStatistics: boolean}
export interface ICityStatistics extends Action<EventActionsTypes.CITY_STATISTICS> {cityStatistics: boolean}
export interface IOpenSalesInformation extends Action<EventActionsTypes.OPEN_SALES_INFORMATION> {openSalesInformation: boolean}
export interface IOpenCurrentSales extends Action<EventActionsTypes.OPEN_CURRENT_SALES> {openCurrentSales: boolean}
export interface IZIndex extends Action<EventActionsTypes.Z_INDEX> {zIndex: number}
export interface IGID extends Action<EventActionsTypes.GID> {gid: string | undefined}
export interface IDontSale extends Action<EventActionsTypes.DONT_SALE> {dontSale: boolean}
export interface IOpenPhotocontrolCard extends Action<EventActionsTypes.OPEN_PHOTOCONTROL_CARD> {openPhotocontrolCard: boolean}

export type EventActions =
    | IEventPatterns
    | IAgencyInfo
    | IMessageToUserAction
    | ICurrentAgency
    | IEventNameAction
    | IAgenciesOpen
    | IMessageToUserArrAction
    | IStatusStatistics
    | IStatusStatisticsFull
    | IAddNewAgency
    | IErrorMessage
    | ISuccessMessage
    | IMaxDistance
    | IMaxDistanceSearch
    | IShowMarkers
    | IGetAddresses
    | ISelectedAddress
    | IBrandGeoObjectsAction
    | INewBrandGeoObjectsAction
    | IBrandGeoObjectAction
    | ILoadingAction
    | IOpenNextBillboardAction
    | IAddressValue
    | ISearchGeoObjectsToggle
    | ISetBrandBoolean
    | ISelectedFocus
    | ISalesStatisticsToggle
    | IEventError
    | IGeoPointNull
    | ICurrentMonthYear
    | IRollUpCard
    | IRollUpCards
    | IMonthYear
    | IStatisticsType
    | IFullMonthYear
    | IAdditionalCost
    | ICurretnSale
    | IChangeProjectStatus
    | IDisabledScoreSave
    | IUseAllFreeGeoMarkParts
    | IUsageInfo
    | IOpenEmployeesCard
    | IEmployeesCount
    | IAdditionalCostAction
    | IDisabledEdit
    | IOpenAdditionalTariff
    | IAdditionalTariffs
    | IAdditionalGeoMarkTariffs
    | IAdditionalGeoMarkTariff
    | IMessageInfo
    | IRegions
    | ICities
    | IProjectStatistics
    | ICityStatistics
    | IOpenSalesInformation
    | IOpenCurrentSales
    | IZIndex
    | IGID
    | IDontSale
    | IOpenBillsCard
    | IOpenPhotocontrolCard;
    
export const dontSaleAction: ActionCreator<IDontSale> = (dontSale: boolean) => {
    return {
        type: EventActionsTypes.DONT_SALE,
        dontSale
    }
}

export const openPhotocontrolCardAction: ActionCreator<IOpenPhotocontrolCard> = (openPhotocontrolCard: boolean) => {
    return {
        type: EventActionsTypes.OPEN_PHOTOCONTROL_CARD,
        openPhotocontrolCard
    }
}

export const gidAction: ActionCreator<IGID> = (gid: string | undefined) => {
    return {
        type: EventActionsTypes.GID,
        gid
    }
}
export const zIndexAction: ActionCreator<IZIndex> = (zIndex: number) => {
    return {
        type: EventActionsTypes.Z_INDEX,
        zIndex
    }
}
export const openCurrentSalesAction: ActionCreator<IOpenCurrentSales> = (openCurrentSales: boolean) => {
    return {
        type: EventActionsTypes.OPEN_CURRENT_SALES,
        openCurrentSales
    }
}
export const openSalesInformationAction: ActionCreator<IOpenSalesInformation> = (openSalesInformation: boolean) => {
    return {
        type: EventActionsTypes.OPEN_SALES_INFORMATION,
        openSalesInformation
    }
}
export const cityStatisticsAction: ActionCreator<ICityStatistics> = (cityStatistics: boolean) => {
    return {
        type: EventActionsTypes.CITY_STATISTICS,
        cityStatistics
    }
}
export const projectStatisticsAction: ActionCreator<IProjectStatistics> = (projectStatistics: boolean) => {
    return {
        type: EventActionsTypes.PROJECT_STATISTICS,
        projectStatistics
    }
}
export const regionsAction: ActionCreator<IRegions> = (regions: string[]) => {
    return {
        type: EventActionsTypes.REGIONS,
        regions
    }
}

export const citiesAction: ActionCreator<ICities> = (cities: string[]) => {
    return {
        type: EventActionsTypes.CITIES,
        cities
    }
}

export const additionalGeoMarkTariffAction: ActionCreator<IAdditionalGeoMarkTariff> = (
    additionalGeoMarkTariff: AdditionalGeoMarkTariffs | null
) => {
    return {
        type: EventActionsTypes.ADDITIONAL_GEO_MARK_TARIFF,
        additionalGeoMarkTariff
    }
}

export const messageInfoAction: ActionCreator<IMessageInfo> = (
    messageInfo: MessageInfo | null
) => {
    return {
        type: EventActionsTypes.MESSAGE_INFO,
        messageInfo
    }
}

export const additionalGeoMarkTariffsAction: ActionCreator<IAdditionalGeoMarkTariffs> = (additionalGeoMarkTariffs: AdditionalGeoMarkTariffs[]) => {
    return {
        type: EventActionsTypes.ADDITIONAL_GEO_MARK_TARIFFS,
        additionalGeoMarkTariffs
    }
}

export const additionalTariffsAction: ActionCreator<IAdditionalTariffs> = (additionalTariffs: TariffParameterConditions[]) => {
    return {
        type: EventActionsTypes.ADITIONAL_TARIFFS,
        additionalTariffs
    }
}

export const openAdditionalTariffAction: ActionCreator<IOpenAdditionalTariff> = (openAdditionalTariff: boolean) => {
    return {
        type: EventActionsTypes.OPEN_ADITIONAL_TARIFF,
        openAdditionalTariff
    }
}

export const openBillsCardAction: ActionCreator<IOpenBillsCard> = (openBillsCard: boolean) => {
    return {
        type: EventActionsTypes.OPEN_BILLS_CARD,
        openBillsCard
    }
}

export const disabledEditAction: ActionCreator<IDisabledEdit> = (disabledEdit: boolean) => {
    return {
        type: EventActionsTypes.DISABLED_EDIT,
        disabledEdit
    }
}

export const additionalCostParametersAction: ActionCreator<IAdditionalCostAction> = (additionalCostParameters: IAdditionalCostParameters[]) => {
    return {
        type: EventActionsTypes.ADDITIONAL_COST_PARAMETETS,
        additionalCostParameters
    }
}

export const employeesCountAction: ActionCreator<IEmployeesCount> = (employeesCount: number) => {
    return {
        type: EventActionsTypes.EMPLOYEES_COUNT,
        employeesCount
    }
}

export const openEmployeesCardAction: ActionCreator<IOpenEmployeesCard> = (openEmployeesCard: boolean) => {
    return {
        type: EventActionsTypes.OPEN_EMPLOYEES_CARD,
        openEmployeesCard
    }
}

export const usageInfoAction: ActionCreator<IUsageInfo> = (usageInfo: IBillboardsUsageInfoItems | null) => {
    return {
        type: EventActionsTypes.USAGE_INFO,
        usageInfo
    }
}
export function useAllFreeGeoMarkPartsAction(useAllFreeGeoMarkParts: boolean) {
    const action: IUseAllFreeGeoMarkParts = {
        type: EventActionsTypes.USER_ALL_FREE_GOE_MARK_PARTS,
        useAllFreeGeoMarkParts
    }
    return action;
}
export const disabledScoreSaveAction: ActionCreator<IDisabledScoreSave> = (disabledScoreSave: boolean) => {
    return {
        type: EventActionsTypes.DISABLED_SCORE_SAVE,
        disabledScoreSave
    }
}
export const changeProjectStatusAction: ActionCreator<IChangeProjectStatus> = (changeProjectStatus: boolean) => {
    return {
        type: EventActionsTypes.CHANGE_PROJECT_STATUS,
        changeProjectStatus
    }
}
export const curretnSaleAction: ActionCreator<ICurretnSale> = (curretnSale: ISales | null) => {
    return {
        type: EventActionsTypes.CURRENT_SALE,
        curretnSale
    }
}
export const additionalCostAction: ActionCreator<IAdditionalCost> = (additionalCost: number) => {
    return {
        type: EventActionsTypes.ADDITIONAL_COST,
        additionalCost
    }
}
export const fullMonthYearAction: ActionCreator<IFullMonthYear> = (fullMonthYear: boolean) => {
    return {
        type: EventActionsTypes.FULL_MONTH_YEAR,
        fullMonthYear
    }
}

export const statisticsTypeAction: ActionCreator<IStatisticsType> = (statisticsType: StatisticsType) => {
    return {
        type: EventActionsTypes.STATISTICS_TYPE,
        statisticsType
    }
}

export const monthYearAction: ActionCreator<IMonthYear> = (monthYear: MonthYear[]) => {
    return {
        type: EventActionsTypes.MONTH_YEAR,
        monthYear
    }
}

export const rollUpCardsAction: ActionCreator<IRollUpCards> = (rollUpCards: RollUpCard[]) => {
    return {
        type: EventActionsTypes.ROLL_UP_CARDS,
        rollUpCards
    }
}

export const rollUpCardAction: ActionCreator<IRollUpCard> = (rollUpCard: RollUpCard) => {
    return {
        type: EventActionsTypes.ROLL_UP_CARD,
        rollUpCard
    }
}

export const currentMonthYearAction: ActionCreator<ICurrentMonthYear> = (currentMonthYear: MonthYear | null) => {
    return {
        type: EventActionsTypes.CURRENT_MONTH_YEAR,
        currentMonthYear
    }
}

export const geoPointNullAction: ActionCreator<IGeoPointNull> = (geoPointNull: boolean) => {
    return {
        type: EventActionsTypes.GEO_POINT_NULL,
        geoPointNull
    }
}

export const eventErrorAction: ActionCreator<IEventError> = (eventError: EventError | null) => {
    return {
        type: EventActionsTypes.EVENT_ERROR,
        eventError
    }
}

export const salesStatisticsToggleAction: ActionCreator<ISalesStatisticsToggle> = (salesStatisticsToggle: boolean) => {
    return {
        type: EventActionsTypes.SALES_STATISTICS_TOGGLE,
        salesStatisticsToggle
    }
}

export const selectedFocusAction: ActionCreator<ISelectedFocus> = (selectedFocus: SelectedFocus) => {
    return {
        type: EventActionsTypes.SELECTED_FOCUS,
        selectedFocus
    }
}

export const setBrandBooleanAction: ActionCreator<ISetBrandBoolean> = (setBrandBoolean: boolean) => {
    return {
        type: EventActionsTypes.SET_BRAND_BOOLEAN,
        setBrandBoolean
    }
}

export const searchGeoObjectsToggleAction: ActionCreator<ISearchGeoObjectsToggle> = (searchGeoObjectsToggle: boolean) => {
    return {
        type: EventActionsTypes.SEARCH_GEO_OBJECTS_TOGGLE,
        searchGeoObjectsToggle
    }
}

export const addressValueAction: ActionCreator<IAddressValue> = (addressValue: string) => {
    return {
        type: EventActionsTypes.ADDRESS_VALUE,
        addressValue
    }
}

export const openNextBillboardAction: ActionCreator<IOpenNextBillboardAction> = (openNextBillboard: boolean) => {
    return {
        type: EventActionsTypes.OPEN_NEXT_BILLBOARD,
        openNextBillboard
    }
}

export const loadingAction: ActionCreator<ILoadingAction> = (loading: boolean) => {
    return {
        type: EventActionsTypes.LOADING,
        loading
    }
}

export const brandGeoObjectsAction: ActionCreator<IBrandGeoObjectsAction> = (brandGeoObjects: BrandGeoObjects[]) => {
    return {
        type: EventActionsTypes.GET_BRAND_GEO_OBJECTS,
        brandGeoObjects
    }
}

export const newBrandGeoObjectsAction: ActionCreator<INewBrandGeoObjectsAction> = (newBrandGeoObjects: BrandGeoObjects[]) => {
    return {
        type: EventActionsTypes.GET_NEW_BRAND_GEO_OBJECTS,
        newBrandGeoObjects
    }
}

export const brandGeoObjectAction: ActionCreator<IBrandGeoObjectAction> = (brandGeoObject: BrandGeoObjects) => {
    return {
        type: EventActionsTypes.GET_BRAND_GEO_OBJECT,
        brandGeoObject
    }
}

export const selectedAddressAction: ActionCreator<ISelectedAddress> = (selectedAddress: IGetAddressItem | null) => {
    return {
        type: EventActionsTypes.SELECTED_ADDRESS,
        selectedAddress
    }
}

export const addressesAction: ActionCreator<IGetAddresses> = (addresses: IGetAddressItem[]) => {
    return {
        type: EventActionsTypes.GET_ADDRESSES,
        addresses
    }
}

export const showMarkersAction: ActionCreator<IShowMarkers> = (showMarkers: boolean) => {
    return {
        type: EventActionsTypes.SHOW_MARKERS,
        showMarkers
    }
}

export const maxDistanceAction: ActionCreator<IMaxDistance> = (maxDistance: string) => {
    return {
        type: EventActionsTypes.MAX_DISTANCE,
        maxDistance
    }
}

export const maxDistanceSearchAction: ActionCreator<IMaxDistanceSearch> = (maxDistanceSearch: boolean) => {
    return {
        type: EventActionsTypes.MAX_DISTANCE_SEARCH,
        maxDistanceSearch
    }
}

export const errorMessageAction: ActionCreator<IErrorMessage> = (errorMessage: string) => {
    return {
        type: EventActionsTypes.ERROR_MESSAGE,
        errorMessage
    }
}

export const successMessageAction: ActionCreator<ISuccessMessage> = (successMessage: string | null) => {
    return {
        type: EventActionsTypes.SUCCESS_MESSAGE,
        successMessage
    }
}

export const statusStatisticsAction: ActionCreator<IStatusStatistics> = (statusStatistics: StatusStatistics[]) => {
    return {
        type: EventActionsTypes.STATUS_STATISTICS,
        statusStatistics
    }
}

export const statusStatisticsFullAction: ActionCreator<IStatusStatisticsFull> = (statusStatisticsFull: StatusStatisticsFull[]) => {
    return {
        type: EventActionsTypes.STATUS_STATISTICS_FULL,
        statusStatisticsFull
    }
}

export const agenciesOpenAction: ActionCreator<IAgenciesOpen> = (agenciesOpen: boolean) => {
    return {
        type: EventActionsTypes.AGENCIES_OPEN,
        agenciesOpen
    }
}

export function eventNameAction(eventName: string) {
    const action: IEventNameAction = {
        type: EventActionsTypes.EVENT_NAME,
        eventName
    }
    return action;
}

export const eventPatternsAction: ActionCreator<IEventPatterns> = (eventPatterns: { [id: string]: EventParameters}) => {
    return {
        type: EventActionsTypes.EVENT_PATTERNS,
        eventPatterns
    }
}

export const agencyInfoAction: ActionCreator<IAgencyInfo> = (agencyInfo: AgencyInfo[]) => {
    return {
        type: EventActionsTypes.GET_AGENCY_INFO,
        agencyInfo
    }
}

export const messageToUserAction: ActionCreator<IMessageToUserAction> = (messageToUser: IMessageToUser) => {
    return {
        type: EventActionsTypes.MESSAGE_TO_USER,
        messageToUser
    }
}

export const messageToUserArrayAction: ActionCreator<IMessageToUserArrAction> = (messageToUser: IMessageToUser[]) => {
    return {
        type: EventActionsTypes.MESSAGE_TO_USERS,
        messageToUser
    }
}

export const currentAgencyAction: ActionCreator<ICurrentAgency> = (currentAgency: AgencyInfo) => {
    return {
        type: EventActionsTypes.CURRENT_AGENCY,
        currentAgency
    }
}

export const addNewAgencyAction: ActionCreator<IAddNewAgency> = (addNewAgency: boolean) => {
    return {
        type: EventActionsTypes.ADD_NEW_AGENCY,
        addNewAgency
    }
}