import React, { Dispatch, useCallback, useEffect, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { Coworkers } from '../../store/todo/types';
import CreateIcon from '@material-ui/icons/Create';
import { GetProjectResult, Project } from 'sedi-webserverproxy';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './bricks.scss';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { ChangeProjectParams } from '../../RemoteCommands/type';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    project: Project;
    setTenderProjects?: React.Dispatch<React.SetStateAction<GetProjectResult>>;
};

const EditProjectCreatorName:React.FC<Props> = (props) => {
    const { 
        coworkers,
        project
    } = props;
    
    const [editProjectCreator, setEditProjectCreator] = useState(false);
    const [coworker, setCoworker] = useState<Coworkers | null>(null);

    const handleSelectChange = (event: React.ChangeEvent<{}>, changeValue: Coworkers) => {
        if(changeValue) {
            const findCoworker = coworkers.find(item => item.id === changeValue.id);
            if(findCoworker) {
                setCoworker(findCoworker)
            }
        }
    };

    const onSaveProjectCreatorName  = useCallback( async () => {
        if(coworker) {
            const payload: ChangeProjectParams = {
                projectId: project.projectId,
                creatorAccountId: coworker.id
            }
    
            SystemEvent.EventChangeProject(payload);
            // const builder = new EventBuilder(`EventChangeProject`);
            //     builder.AddParameter('ProjectId', project.projectId);
            //     builder.AddParameter('CreatorAccountId', coworker.Id);
            // ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }
        // const response = await getWebServerProxy().ToDo.GetProjects({
        //     ownerAccountId: clientItem ? clientItem.accountId : undefined, 
        //     brandId:  currentBrandInfo ? currentBrandInfo.brandId : undefined,
        //     status: projectFilter.projectStatus,
        //     creatorAccountId: projectFilter.coworker
        // });
        // const fetchedProjects = response.Result || [];
        // if(setTenderProjects !== undefined) {
        //     setTenderProjects(fetchedProjects);
        // } else {
        //     setAllProjects(fetchedProjects);
        // }
        setEditProjectCreator(false);
    }, [coworker]);

    useEffect(() => {
        if(editProjectCreator) {
            const findCoworker = coworkers.find(item => item.id === project.creatorAccountId);
            if(findCoworker) {
                setCoworker(findCoworker)
            }
        }
    }, [editProjectCreator]);

    return (
        <>
            {!editProjectCreator ? 
                <span className="editProjectCreatorName">
                    <span>
                        {project.creatorName}
                    </span>
                    <CreateIcon onClick={() => setEditProjectCreator(true)} />
                </span>
                : 
                <Autocomplete
                    options={coworkers}
                    getOptionLabel={(option: Coworkers) => option.name}
                    value={coworker}
                    onChange={(event: React.ChangeEvent<{}>, changeValue: Coworkers) => 
                        handleSelectChange(event, changeValue)}
                    freeSolo
                    renderInput={(params) => 
                        <TextField 
                            {...params} 
                            variant="outlined" 
                            fullWidth 
                            size="small" 
                            InputLabelProps={{ shrink: true }} 
                            onBlur={onSaveProjectCreatorName}/>
                    }
                />
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    coworkers: state.todo.coworkers,
    clientItem: state.customers.clientItem,
    currentBrandInfo: state.billboards.currentBrandInfo
});


const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setAllProjects: (projects: Project[]) => 
        dispatch(actions.todo.setAllProjects(projects)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectCreatorName);