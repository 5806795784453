import { convertDateToParamAsIsoDate } from "../WebServerProxy";


export function convertDatesInObjectToIso(source: any) : any{
    if (source === undefined || source === null){
        throw new Error('Invalid patameters');
    }

    let result: any = {};

    Object.entries(source)
        .forEach(([objectKey, objectValue]) => {
            let convertedValue;

            if (objectValue instanceof Date){
                convertedValue = convertDateToParamAsIsoDate(objectValue as Date);
            }
            else {
                convertedValue = objectValue;
            }

            result[objectKey] = convertedValue;
        });

    return result;
}