import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { 
    Badge,
    Button, 
    Card, 
    CardContent, 
    CardHeader,  
    FormControl, 
    Grid, 
    IconButton, 
    InputLabel,  
    MenuItem, 
    Select, 
    Tooltip, 
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';
import { DefaultPosition } from '../../store/app/types';
import GetAddressAutocomplete from './GetAddressAutocomplete';
import SearchOblectsTable from './SearchOblectsTable';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import { AddressInfo, IGetAddressItem } from 'sedi-webserverproxy';
import { IMultipleMarker } from '../map/types';
import './bricks.scss';
import { BrandAddress, BrandGeoObjects, GeoPoint } from '../../store/event/types';
import ImportExcelDialog from './ImportExcelDialog';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { MessageHelper } from '../MessageHub/MessageHelper';
import CircularProgressWithLabel from './CircularProgressWithLabel';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
        selectedBrandMarker: (brandMarkers: IMultipleMarker[]) => void;
    }

const SearchOblectsBrand: React.FC<Props> = (props) => {
    const { 
        draggable,
        positionSearchObjects,
        positionSearchObjectsAction,
        currentBrandInfo,
        addresses,
        selectedAddress,
        newBrandGeoObjects,
        newBrandGeoObjectsAction,
        selectedBrandMarker,
        addressesAction,
        selectedAddressAction,
        addressValueAction,
        searchGeoObjectsToggle,
        searchGeoObjectsToggleAction,
        regions
    } = props;

    const [selectedRegion, setSelectedRegion] = useState('all');
    const [regionName, setRegionName] = useState("");
    const [importExcelOpen, setImportExcelOpen] = useState(false);
    const [progress, setProgress] = React.useState<number | null>(null);
    const [geoMarks, setGeoMarks] = useState<BrandGeoObjects[] | null>([]);

    useEffect(() => {
        SystemEvent.SubscribeEventImportBrands(
            "SearchOblectsBrand", 
            (answer) => {
                let newGeoMarks: BrandGeoObjects[] | null = null
                if(answer.geoMarks) {
                    const parseGeoMarks = JSON.parse(answer.geoMarks);
                    newGeoMarks = parseGeoMarks.map((item: any) => (
                        Object.assign({ Billbaords: null }, item)
                    ))
                }
                setProgress(Number(answer.executionPercent));
                setGeoMarks(newGeoMarks);
            }, 
            (error) => errorCallback(error)
        );
        MessageHelper.subscribeProgress("SearchOblectsBrand", (data) => {
            setProgress(data.progress);
        });
    }, []);

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);

    const addressesInfoSort: { [id: string] : AddressInfo; } = {};

    // billboards.filter(item => item.addressInfo)
    //     .forEach(billboard => addressesInfoSort[billboard.addressInfo.areaName] = billboard.addressInfo);
    const addressesInfo = Object.keys(addressesInfoSort).map(key => addressesInfoSort[key]);
    const geoObjectId = -Math.random() * (5000 - 1) + 1;

    const importExcelClickClose = () => {
        setImportExcelOpen(false);
    }

    const selectedAddressInfo = (regionName: string) => {
        setRegionName(regionName)
    }

    const searchClick = () => {
        if(selectedAddress !== null) {
            const city = selectedAddress.c ? selectedAddress.c + ', ' : ''; 
            const street = selectedAddress.s ? selectedAddress.s + ', ' : '';
            const address: BrandAddress = {
                AddressString: city + street + selectedAddress.v,
                CityName: city,
                CountryName: selectedAddress.co,
                GeoPoint: {
                    Latitude: selectedAddress.g.lat,
                    Longitude: selectedAddress.g.lon
                },
                HouseNumber: selectedAddress.s ? selectedAddress.v : undefined,
                StreetName: selectedAddress.s
            }
            const geoObjectName = street + selectedAddress.v.replace(/ *\([^)]*\) */g, "");
            const brandItem: BrandGeoObjects = {
                GeoMarkId: geoObjectId,
                GeoMarkName: geoObjectName,
                Address: address,
                GeoPoint: {
                    Latitude: selectedAddress.g.lat,
                    Longitude: selectedAddress.g.lon
                },
                Billboards: null
            }
            newBrandGeoObjectsAction([...newBrandGeoObjects, brandItem]);
        } else {
            const items = addresses.map((item) => {
                const city = item.c ? item.c + ', ' : ''; 
                const street = item.s ? item.s + ', ' : '';
                const address: BrandAddress = {
                    AddressString: city + street + item.v,
                    CityName: city,
                    CountryName: item.co,
                    GeoPoint: {
                        Latitude: item.g.lat,
                        Longitude: item.g.lon
                    },
                    HouseNumber: item.s ? item.v : undefined,
                    StreetName: item.s
                }
                const geoObjectName = item.v.replace(/ *\([^)]*\) */g, "");
                const geoObjectID = -Math.random() * (5000 - 1) + 1;
                const center: GeoPoint = {
                    Latitude: item.g.lat,
                    Longitude: item.g.lon
                }
                return new BrandGeoObjects(geoObjectID, geoObjectName, address, center);
            });
            const itemsConcat = newBrandGeoObjects.concat(items);
            newBrandGeoObjectsAction(itemsConcat);
        }
        selectedAddressAction(null);
        addressesAction([]);
        addressValueAction('');
    }

    const saveBrandGeoObjects = () => {
        for(let geoObject of newBrandGeoObjects) {
            if(geoObject.GeoMarkId < 1) {
                geoObject.GeoMarkId = -1;
            }
        }
        // const filterBrandGeoObjects = newBrandGeoObjects.filter(item => item.GeoMarkId < 1);
        if(currentBrandInfo) {
            const builder = new EventBuilder(`EventSetBrandGeoObjects`);
                builder.AddParameter('BrandId', currentBrandInfo.brandId);
                builder.AddParameter('BrandGeoObjects', JSON.stringify(newBrandGeoObjects));
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
            newBrandGeoObjectsAction([]);
            if(geoMarks) {
                const builderGeoMarks = new EventBuilder(`EventSetBrandGeoObjects`);
                    builderGeoMarks.AddParameter('BrandId', currentBrandInfo.brandId);
                    builderGeoMarks.AddParameter('BrandGeoObjects', JSON.stringify(geoMarks));
                    ServerProxy.SendSystemEvent(builderGeoMarks.GetSystemEvent());
                setGeoMarks(null);
            }
        }
    }

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        positionSearchObjectsAction(position);
    };

    useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    });

    useEffect(() => {
        if(currentBrandInfo) {
            const builder = new EventBuilder(`EventGetBrandGeoObjects`);
                builder.AddParameter('BrandId', currentBrandInfo.brandId);
                ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }
    }, [currentBrandInfo]);

    return (
        <>
            <Draggable
                handle=".searchOblectsBrand"
                defaultPosition={positionSearchObjects}
                disabled={!draggable}
                onStop={onStop}>
                <Card 
                    className={`searchOblectsBrand ${searchGeoObjectsToggle ? 'openSelectedCard' : 'closeSelectedCard'}`}
                    style={{cursor: draggable ? 'move' : 'auto'}}>
                    <CardHeader
                        action={
                            <IconButton 
                                aria-label="settings" 
                                className="mt-1 ml-1"
                                onClick={() => searchGeoObjectsToggleAction(false)}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title={
                            <Grid container spacing={1} alignItems="center" justify="space-between">
                                <Grid item>
                                    <div className="box-title">
                                        Объекты бренда {currentBrandInfo && currentBrandInfo.brandName}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <FormControl 
                                                variant="outlined" 
                                                size="small" 
                                                style={{ width: 150 }}>
                                                <InputLabel ref={inputLabel}>Выберите регион</InputLabel>
                                                <Select
                                                    value={selectedRegion}
                                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => 
                                                        setSelectedRegion(event.target.value as string)}
                                                    labelWidth={labelWidth}>
                                                    <MenuItem value="all">Все</MenuItem>
                                                    {regions.map(item => {
                                                        return (
                                                            <MenuItem 
                                                                value={item} 
                                                                key={'menu-item-' + item}>
                                                                    <span   
                                                                        onClick={() => selectedAddressInfo(item)}>
                                                                        {item}
                                                                    </span>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <GetAddressAutocomplete 
                                                addressInfo={null} 
                                                regionName={regionName} />
                                        </Grid>
                                        <Grid item>
                                            <Tooltip 
                                                title={`Добавление объектов бренда ${currentBrandInfo && currentBrandInfo.brandName}`}>
                                                    <Badge 
                                                        badgeContent={selectedAddress ? 1 : addresses.length} 
                                                        color="secondary">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={searchClick}
                                                            disabled={selectedAddress || addresses.length > 0 ? false : true}>
                                                            Добавить
                                                        </Button>
                                                    </Badge>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    />
                    <CardContent className="billboardList__table">
                        <Grid 
                            container 
                            spacing={1} 
                            alignItems="center" 
                            justify="flex-end"
                            className="mb-2">
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    endIcon={
                                        progress && 
                                            <>
                                                {progress !== 100 && 
                                                    <CircularProgressWithLabel value={progress} />
                                                }
                                            </>
                                    }
                                    onClick={() => setImportExcelOpen(true)}>
                                    Импортировать объекты из Excel-таблицы
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    onClick={saveBrandGeoObjects}>
                                    Сохранить как объекты бренда
                                </Button>
                            </Grid>
                        </Grid>
                        <SearchOblectsTable 
                            selectedBrandMarker={selectedBrandMarker} 
                            geoMarks={geoMarks} 
                            setGeoMarks={setGeoMarks} />
                    </CardContent>
                </Card>
            </Draggable>
            <ImportExcelDialog 
                importExcelOpen={importExcelOpen} 
                importExcelClickClose={importExcelClickClose} />
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    draggable: state.app.draggable,
    positionSearchObjects: state.app.positionSearchObjects,
    openSearchObjects: state.billboards.openBrandsCard,
    currentBrandInfo: state.billboards.currentBrandInfo,
    addresses: state.event.addresses,
    selectedAddress: state.event.selectedAddress,
    newBrandGeoObjects: state.event.newBrandGeoObjects,
    searchGeoObjectsToggle: state.event.searchGeoObjectsToggle,
    regions: state.event.regions
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    maxDistanceSearchAction: (maxDistanceSearch: boolean) => 
        dispatch(actions.event.maxDistanceSearchAction(maxDistanceSearch)),
    positionSearchObjectsAction: (positionSearchObjects: DefaultPosition) => 
        dispatch(actions.app.positionSearchObjectsAction(positionSearchObjects)),
    newBrandGeoObjectsAction: (newBrandGeoObjects: BrandGeoObjects[]) => 
        dispatch(actions.event.newBrandGeoObjectsAction(newBrandGeoObjects)),
    addressesAction: (addresses: IGetAddressItem[]) => 
        dispatch(actions.event.addressesAction(addresses)),
    selectedAddressAction: (selectedAddress: IGetAddressItem | null) => 
        dispatch(actions.event.selectedAddressAction(selectedAddress)),
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),
    searchGeoObjectsToggleAction: (searchGeoObjectsToggle: boolean) => 
        dispatch(actions.event.searchGeoObjectsToggleAction(searchGeoObjectsToggle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchOblectsBrand);