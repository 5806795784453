import { QiwiBillStatus } from "../../RemoteCommands/type"

export enum ReduxBillsActionsTypes {
    BILL_STATUSES = "BILL_STATUSES"
}

export type BillStatuses = {
    localization: string,
    value: QiwiBillStatus
}

export interface BillsActionsState {
    billStatuses: BillStatuses[]
}