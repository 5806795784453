import React, { useState } from 'react';
import { 
    Dialog, 
    DialogTitle, 
    IconButton, 
    DialogContent, 
    TextField,
    DialogActions,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { convertBase64 } from '../../utils/convertBase64';

type Props = {
    importExcelOpen: boolean;
    importExcelClickClose: () => void;
}

const ImportExcelDialog:React.FC<Props> = (props) => {
    const { 
        importExcelOpen, 
        importExcelClickClose,  
    } = props;
    const [excelBase64, setExcelBase64] = useState<string | null>(null);

    const importExcelUpload = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const excelFile: File = (target.files as FileList)[0];
        try {
            const base64: string = await convertBase64(excelFile);
            const base64Replace = base64.replace(/^data:(.*,)?/, '');
            setExcelBase64(base64Replace);
        } catch(error) {
            console.log("error:", error);
        }
    }

    const importExcelClick = () => {
        if(excelBase64) {
            SystemEvent.EventImportBrands(excelBase64);
        }
        importExcelClickClose();
    }

    return (
        <Dialog
            open={importExcelOpen}
            onClose={importExcelClickClose}
            fullWidth
            maxWidth="sm">
            <DialogTitle>
                <IconButton 
                    style={{ position: 'absolute', top: '5px', right: '5px' }} 
                    onClick={importExcelClickClose}>
                    <CloseIcon />
                </IconButton>
                Указать файл
            </DialogTitle>
            <DialogContent>
                <div className="importExcel">
                    <TextField
                        fullWidth  
                        variant="outlined" 
                        size="small"
                        type="file"
                        onChange={importExcelUpload}
                        autoFocus={true}
                    />
                </div>
            </DialogContent>
            <DialogActions className="pt-3 pb-4 pr-4">
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={importExcelClick}>
                    Импортировать
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ImportExcelDialog;