import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions } from '../../../store';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ExceededTimeProject } from '../../../utils/onlyUnique';

type Props = ReturnType<typeof mapDispatchToProps> & {
    exceededTimeProject: ExceededTimeProject;
}

function ExceededProjectConfirm(props: Props) {
    const { exceededTimeProject, exceededTimeProjectAction, setActiveProject, openSelectedBillboardsAction } = props;

    const handleClickOpen = () => {
        setActiveProject(parseInt(exceededTimeProject.projectId));
        openSelectedBillboardsAction(true);
        exceededTimeProjectAction(null);
    };

    const handleClose = () => {
        exceededTimeProjectAction(null);
    };

  return (
        <Dialog
            open={Boolean(exceededTimeProject)}
            onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">Превышено максимально допустимое время</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {exceededTimeProject.eventText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Отмена
                </Button>
                <Button onClick={handleClickOpen} color="primary" autoFocus>
                    Открыть проект
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    exceededTimeProjectAction: (exceededTimeProject: ExceededTimeProject | null) => 
        dispatch(actions.todo.exceededTimeProjectAction(exceededTimeProject)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => 
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
});

export default connect(null, mapDispatchToProps)(ExceededProjectConfirm)