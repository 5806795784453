import React, { Dispatch, useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, TableSortLabel } from '@material-ui/core';
import CurrentGeoMarkTariff from './CurrentGeoMarkTariff';
import { AdditionalGeoMarkTariff, AdditionalGeoMarkTariffs } from '../../RemoteCommands/type';
import _ from 'lodash';
import { OrderType, SortOrder } from '../../store/event/types';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

function AdditionalTariffsTable(props: Props) {
    const { 
        additionalTariffs, 
        additionalGeoMarkTariffs,
        loading,
        additionalGeoMarkTariff,
        additionalGeoMarkTariffsAction
    } = props;

    const orderStore: SortOrder = JSON.parse(localStorage.getItem('orderStore') || '{ "order":"asc","orderBy":"cost" }');
    const [order, setOrder] = React.useState<OrderType>(orderStore.order);
    const [orderBy, setOrderBy] = React.useState(orderStore.orderBy);

    useEffect(() => {
        let chars = additionalGeoMarkTariffs;
        chars = _.orderBy(chars, [orderBy], [order]);
        additionalGeoMarkTariffsAction(chars);
    }, [order]);

    const handleSortClick = (type: string) => {
        setOrderBy(type);
        let payload: SortOrder = {
            order: OrderType.asc,
            orderBy: type
        }
        if(order === OrderType.asc) {
            payload = {
                order: OrderType.desc,
                orderBy: type
            }
            localStorage.setItem('orderStore', JSON.stringify(payload));
            return setOrder(OrderType.desc);
        }
        localStorage.setItem('orderStore', JSON.stringify(payload));
        setOrder(OrderType.asc);
    }

    if(loading) {
        return (
            <TableContainer 
                component={Paper} 
                className="additionalTariffsTable">
                <div className="loading">
                    <CircularProgress />
                </div>
            </TableContainer>
        )
    }

    return (
        <TableContainer 
            component={Paper} 
            className="additionalTariffsTable">
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === "pricingType"}
                                direction={order}
                                onClick={() => handleSortClick("pricingType")}>
                                Тип стоимости
                            </TableSortLabel>
                        </TableCell>
                        <TableCell 
                            style={{ width: 150 }} 
                            align="center">
                            <TableSortLabel
                                active={orderBy === "cost"}
                                direction={order}
                                onClick={() => handleSortClick("cost")}>
                                Cтоимость
                            </TableSortLabel>
                        </TableCell>
                        {additionalTariffs.map(item => (
                            <TableCell 
                                key={'tableHead-' + item.conditionName} 
                                align="center">
                                {/* <TableSortLabel
                                    active={orderBy === item.conditionName}
                                    direction={order}
                                    onClick={() => handleSortClick(item.conditionName)}>
                                    {item.conditionLocalization}
                                </TableSortLabel> */}
                                {item.conditionLocalization}
                            </TableCell>
                        ))}
                        <TableCell 
                            style={{ width: 100 }} 
                            align="center">
                            Действия
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="cancelDraggable">
                    {additionalGeoMarkTariff && 
                        <CurrentGeoMarkTariff  
                            geoMarkTariff={new AdditionalGeoMarkTariff(additionalGeoMarkTariff)} />
                    }
                    {additionalGeoMarkTariffs.map(tariff => (
                        <CurrentGeoMarkTariff 
                            key={'tariffParameterId-' + tariff.tariffParameterId} 
                            geoMarkTariff={tariff} />
                    )).reverse()}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    additionalTariffs: state.event.additionalTariffs,
    additionalGeoMarkTariffs: state.event.additionalGeoMarkTariffs,
    loading: state.event.loading,
    additionalGeoMarkTariff: state.event.additionalGeoMarkTariff
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    additionalGeoMarkTariffsAction: (additionalGeoMarkTariffs: AdditionalGeoMarkTariffs[]) => 
        dispatch(actions.event.additionalGeoMarkTariffsAction(additionalGeoMarkTariffs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalTariffsTable);