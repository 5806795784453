import React, { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Tooltip } from '@material-ui/core';
import { Project } from 'sedi-webserverproxy';
import { EventBuilder } from '../../utils/eventHelpers';
import { ServerProxy } from '../../RemoteCommands/ServerProxy';
import AddIcon from '@material-ui/icons/Add';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    disabled: boolean;
    label?: string;
    inputRef?: (name: any) => void
}

const ProjectOnlyAutocomplete:React.FC<Props> = (props) => {
    const { 
        disabled, 
        projects,
        projectID,
        label,
        commercialOfferNullAction,
        discountOpenAction,
        setActiveProject,
        clientItem,
        currentBrandInfo,
        dates,
        inputRef
    } = props;

    const [addProjectIcon, setAddProjectIcon] = useState(false);

    const currentProject = projects.find(project => project.projectId === projectID);


    const projectSelectChange = (changeValue: Project) => {
        commercialOfferNullAction();
        if(changeValue) {
            discountOpenAction(false);
            return setActiveProject(changeValue.projectId);
        } else {
            return '';
        }
    }

    const fliterOptions = (options: Project[], state: FilterOptionsState) => {
        const optionsArray: Project[] = 
            options.filter(option => option.projectName.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0 
                || option.projectName.toLowerCase().indexOf(' ' + state.inputValue.toLowerCase()) > 0);

        if(optionsArray.length === 0 && state.inputValue.length === 0) {
            setAddProjectIcon(true);
        } else {
            setAddProjectIcon(false);
        }
        return optionsArray
    }

    async function addProject() {
        if(clientItem) {
            const projectName = clientItem.name + ' ' + moment(new Date()).format('DD.MM.YYYY');
            const builder = new EventBuilder(`EventCreateProject`);
                builder.AddParameter('ProjectOwnerAccountId', clientItem.accountId);
                builder.AddParameter('ProjectName', projectName);
            if(currentBrandInfo) {
                builder.AddParameter('BrandId', currentBrandInfo.brandId);
            } 
            if(dates.DateFrom) {
                const dateStart = moment(dates.DateFrom).format('yyyy-MM-DD HH:mm:ss');
                builder.AddParameterDate('DateStart', dateStart);
            }
            if(dates.DateTo) {
                const dateEnd = moment(dates.DateTo).format('yyyy-MM-DD HH:mm:ss');
                builder.AddParameterDate('DateEnd', dateEnd);
            }
            ServerProxy.SendSystemEvent(builder.GetSystemEvent());
        }
    }

    return (
        <div className="brandAutocomplete">
            <Autocomplete
                options={projects}
                getOptionLabel={(option: Project) => option ? option.projectName : ''}
                value={currentProject ? currentProject : ''}
                defaultValue={currentProject ? currentProject : ''}
                onChange={(event: React.ChangeEvent<{}>, changeValue: Project) => projectSelectChange(changeValue)}
                filterOptions={(options: Project[], state: FilterOptionsState) => fliterOptions(options, state)}
                disabled={disabled}
                renderInput={params => (
                    <TextField 
                        {...params}
                        label={label ? label : "Выберите проект"}
                        variant="outlined" 
                        size="small"
                        fullWidth 
                        inputRef={input => {
                            inputRef && inputRef(input);
                        }}
                    />
                )}
                className="autoComplateOff cancelDraggable"
                closeIcon={
                    <CloseIcon 
                        fontSize="small" 
                        onClick={() => setActiveProject(-1)} 
                    />
                }
            />
            {addProjectIcon && 
                <Tooltip 
                    title="Добавить новый проект" 
                    placement="top">
                    <AddIcon 
                        className="addNewBrand" 
                        onClick={addProject} />
                </Tooltip>
            }
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    brandsInfo: state.billboards.brandsInfo,
    currentAgency: state.event.currentAgency,
    currentBrandInfo: state.billboards.currentBrandInfo,
    projects: state.todo.projects,
    projectID: state.todo.projectID,
    clientItem: state.customers.clientItem,
    dates: state.todo.dates
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    commercialOfferNullAction: () => dispatch(actions.todo.commercialOfferNullAction()),
    discountOpenAction: (discountOpen: boolean) => 
        dispatch(actions.todo.discountOpenAction(discountOpen)),
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOnlyAutocomplete);