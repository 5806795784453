import React, { Dispatch, useMemo } from "react";
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { RootState, selectors, actions } from '../../store';
import { IconButton, Paper, Size, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AddCircle from '@material-ui/icons/AddCircle';
import { getTranslate } from 'react-localize-redux';
import { BillboardsShortInfo, GeoMarkTariffsParams, ISideProperties, IBillboardsUsageInfoItems, IBillboardStatuses, IGeoMarkTimeStatuses } from "../../RemoteCommands/type";
import { SystemEvent } from "../../RemoteCommands/SystemEvent";
import { RollUpCard } from "../../store/event/types";
import { Settings } from '../../utils/settings';
//@ts-ignore
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm.js";

type Props = 
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
    billboardShort: BillboardsShortInfo;
    sideProperties: ISideProperties[];
    name: string;
    size?: Size;
    TTImage?: string;
    TTImage2?: string;
    geoMarkId?: string;
    imageType?: string;
    billboardOverSaleStatuses?: IBillboardStatuses[];
    overSaleHandleClick?: (isOverSale: boolean) => void;
    canAddOverSale?: boolean;
};

const BillboardInfoProperties = (props: Props) => {
    const {
        selectedSide,
        translate,
        billboardShort,
        sideProperties,
        openGeoMarkTariffAction,
        rollUpCardsAction,
        rollUpCards,
        name,
        usageInfoAction,
        size,
        TTImage,
        TTImage2,
        imageType,
        geoMarkId,
        billboardOverSaleStatuses,
        overSaleHandleClick,
        currentGeoMarkStatus,
        canAddOverSale
    } = props;

    const properties = sideProperties.filter(item => 
        item.propertyName !== "NumberOfSeatsOnSide1" 
        && item.propertyName !== "NumberOfSeatsOnSide2"
        && item.propertyName !== "WayA_OddNumbers"
    );

    const openTariffClick = () => {
        openGeoMarkTariffAction(true);
        const payload: GeoMarkTariffsParams = {
            geoMarkIds: [billboardShort.geoMarkId]
        }
        SystemEvent.EventGetGeoMarkTariffs(payload);
    }

    const openOverSaleClick = () => {
        if(overSaleHandleClick)
            overSaleHandleClick(true);
    }

    const openBillboardUsageInfoClick = () => {
        const usageInfo: IBillboardsUsageInfoItems = {
            geoMarkId: billboardShort.geoMarkId,
            geoMarkName: name,
            gid: billboardShort.gid,
            items: []
        }
        usageInfoAction(usageInfo);
        const idx = rollUpCards.findIndex((item) => item.key === "BillBoardDetailedUsageInfo");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    const imageTypeClick = () => {
        const fancybox = new Fancybox([
            {
              src: Settings.useNewUrl ? 
              `${Settings.imgUrlNew + billboardShort.geoMarkId}&imagetype=${TTImage}&bigPhoto=true` : 
              `${Settings.imgUrl + billboardShort.geoMarkId}&sideName=${TTImage}&bigPhoto=true`,
              type: "image",
            },
        ]);
        fancybox.next();
    }

    const TT2ImageClick = () => {
        const fancybox = new Fancybox([
            {
              src: Settings.useNewUrl ? 
              `${Settings.imgUrlNew + billboardShort.geoMarkId}&imagetype=TT2&bigPhoto=true` : 
              `${Settings.imgUrl + billboardShort.geoMarkId}&sideName=TT2&bigPhoto=true`,
              type: "image",
            },
        ]);
        fancybox.next();
    }

    const TTImageClick = () => {
        const fancybox = new Fancybox([
            {
              src: Settings.useNewUrl ? 
              `${Settings.imgUrlNew + billboardShort.geoMarkId}&imagetype=TT&bigPhoto=true` : 
              `${Settings.imgUrl + billboardShort.geoMarkId}&sideName=TT&bigPhoto=true`,
              type: "image",
            },
        ]);
        fancybox.next();
    }

    useMemo(() => {
        if(imageType == "TT2"){
            TT2ImageClick();
        } else {
            if(imageType == "TT") {
                TTImageClick();
            } else {
                if(imageType){
                    imageTypeClick();
                }
            }
        }
    }, [imageType]);

    const disabledStatus = currentGeoMarkStatus.value === "Paid" || currentGeoMarkStatus.value === "Sold";

    return (
        <Paper className={`billboard-popup-list`}>
            <TableContainer className="billboard-container">
                <Table className="billboard-edit-tabel" size={size}>
                    <TableHead className="billboard-edit-tabel__head">
                        <TableRow>
                            <TableCell>Данные конструкции</TableCell>
                            <TableCell align="right">
                                {!geoMarkId && 
                                    <>
                                        <Tooltip 
                                            title="Тарифы" 
                                            placement="top">
                                            <IconButton 
                                                onClick={openTariffClick}
                                                className="mt-1 ml-1">
                                                <MonetizationOnIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip 
                                            title="Детальная информация по загрузке билборда" 
                                            placement="top">
                                            <IconButton 
                                                onClick={openBillboardUsageInfoClick}
                                                className="mt-1 ml-1">
                                                <EqualizerIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {properties.map(property => {
                            if(property.propertyName === "Light") {
                                return (
                                    <TableRow key={`property-${property.propertyValueId}`}>
                                        <TableCell><strong>{property.propertyLocalization}</strong></TableCell>
                                        <TableCell>{property.value === "true" ? "да" : "нет"}</TableCell>
                                    </TableRow>
                                )
                            }
                            if(property.propertyName === "OverSalesCount") {
                                return (
                                    <TableRow key={`property-${property.propertyValueId}`}>
                                        <TableCell><strong>{property.propertyLocalization}</strong></TableCell>
                                        <TableCell>
                                            {billboardOverSaleStatuses ? 
                                                "Продано " + 
                                                 billboardOverSaleStatuses.length + 
                                                " из " : ""}
                                            
                                            {property.value}
                                            
                                            {canAddOverSale ?
                                                <Tooltip 
                                                    title="Добавить в проект" 
                                                    placement="top">
                                                    <IconButton 
                                                        onClick={openOverSaleClick}
                                                        className="mt-1 ml-1"
                                                        disabled={disabledStatus}>
                                                        <AddCircle />
                                                    </IconButton>
                                                </Tooltip>
                                             : ""
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            return (
                                <TableRow key={`property-${property.propertyValueId}`}>
                                    <TableCell><strong>{property.propertyLocalization}</strong></TableCell>
                                    <TableCell>{property.value}</TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell><strong>GID:</strong></TableCell>
                            <TableCell>{billboardShort.gid + selectedSide}</TableCell>
                        </TableRow>
                        {TTImage && !TTImage2 &&
                            <TableRow>
                                <TableCell><strong>Технические требования:</strong></TableCell>
                                <TableCell>
                                    <span 
                                        className="link"
                                        onClick={imageTypeClick}>
                                        Посмотреть
                                    </span>
                                </TableCell>
                            </TableRow>
                        }
                        {TTImage2 &&
                            <TableRow>
                                <TableCell><strong>Технические требования:</strong></TableCell>
                                <TableCell>
                                    <span 
                                        className="link"
                                        onClick={TT2ImageClick}>
                                        Посмотреть
                                    </span>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

const mapStateToProps = (state: RootState) => {
    let projectID = selectors.todo.getProjectID(state.todo);

    return {
        projectID,
        selectedSide: state.billboards.selectedSide,
        translate: getTranslate(state.localize),
        rollUpCards: state.event.rollUpCards,
        currentGeoMarkStatus: state.todo.currentGeoMarkStatus
    }
};


const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    openGeoMarkTariffAction: (openGeoMarkTariff: boolean) => 
        dispatch(actions.billboards.openGeoMarkTariffAction(openGeoMarkTariff)),
    rollUpCardsAction: (rollUpCards: RollUpCard[]) => 
        dispatch(actions.event.rollUpCardsAction(rollUpCards)),
    usageInfoAction: (usageInfo: IBillboardsUsageInfoItems | null) => 
        dispatch(actions.event.usageInfoAction(usageInfo)),
});


export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(BillboardInfoProperties);