import React, { FunctionComponent } from 'react';
import Fab from '@material-ui/core/Fab';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import '../map.css'

type ResetBounds = {
    resetBounds?: () => void,
}

const ResetButton: FunctionComponent<ResetBounds> = (props) => {
    const { resetBounds } = props;
    return (
        <div className="reset-button">
            <Fab color="primary" aria-label="add" size="small" onClick={(resetBounds)}>
                <FullscreenIcon />
            </Fab>
        </div>
    )
}

export default ResetButton;