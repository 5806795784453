import React from 'react';
import { ActionFromBackendItem } from '../../../../../store/todo/types';
import ButtonsGroup from './ButtonsGroup';


type Props = {
    actionItem: ActionFromBackendItem
}


const ActionItem: React.FunctionComponent<Props> = ({ actionItem }) => {

    return (
        <>
            <div className="notification__infoClient">{actionItem.Text}</div>
            <ButtonsGroup actionButtons={actionItem.Buttons} />
        </>
    );
}


export default ActionItem;