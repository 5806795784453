import React, { Dispatch } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { removeItemAtIndex } from '../../../utils/replaceItem';
import { SystemEvent } from '../../../RemoteCommands/SystemEvent';
import { IAdditionalCostParameters } from '../../../RemoteCommands/type';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    deleteOpen: boolean;
    deleteCloseHandle: () => void;
    additionalCost: IAdditionalCostParameters;
}

const DeleteCostModal:React.FC<Props> = (props) => {
    const { 
        deleteOpen,  
        deleteCloseHandle,
        additionalCostParameters,
        additionalCost,
        additionalCostParametersAction,
        projectID
    } = props;

    const deleteHandleClick = (item: IAdditionalCostParameters) => {
        const index = additionalCostParameters.findIndex((listItem) => listItem === item);
        const newList = removeItemAtIndex(additionalCostParameters, index);
        additionalCostParametersAction(newList);
        SystemEvent.EventSaveProjectAdditionalCosts(projectID, newList);
        deleteCloseHandle();
    }


    return (
        <Dialog
            open={deleteOpen}
            onClose={deleteCloseHandle}>
            <DialogTitle>Удалить</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы действительно хотите удалить?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={deleteCloseHandle} color="primary">
                    Отмена
                </Button>
                <Button 
                    color="secondary" 
                    autoFocus 
                    onClick={() => deleteHandleClick(additionalCost)}>
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state: RootState) => ({
    projectID: state.todo.projectID,
    additionalCostParameters: state.event.additionalCostParameters
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    additionalCostParametersAction: (additionalCostParameters: IAdditionalCostParameters[]) => 
        dispatch(actions.event.additionalCostParametersAction(additionalCostParameters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCostModal);