import React, { FunctionComponent, useState } from 'react';
import SideGroup from './components/SideGroup';
import { DatesRange } from '../../bricks/InputDatesRange';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { linerGradient } from '../../../utils/getHighlightStyle';
import { IBillboardStatuses } from '../../../RemoteCommands/type';
import { IBillboardProperties } from '../../../utils/PropertiesHelper';


type Props = {
    onClick: (sideID: string) => void,
    statuses: IBillboardStatuses[],
    dates: DatesRange,
    selectedSide: string;
    billboardProperties: IBillboardProperties;
    isSideHandle: (side: boolean) => void;
};


const BillboardSides: FunctionComponent<Props> = (props) => {
    const {  
        statuses, 
        dates, 
        onClick,
        billboardProperties,
        selectedSide,
        isSideHandle
    } = props;

    const [selectedSideA, setSelectedSideA] = useState(selectedSide);
    const [selectedSideB, setSelectedSideB] = useState("");

    const onChangeSide = (sideID: string) => {
        if(sideID) {
            const sideLetter = sideID.charAt(0);
            if(sideLetter === "A") {
                setSelectedSideA(sideID);
                setSelectedSideB("");
                isSideHandle(true)
            } else {
                setSelectedSideB(sideID);
                setSelectedSideA("");
                isSideHandle(false);
            }
            // if(billboardProperties.oddNumber === true) {
            //     const sideLetter = sideID.charAt(0);
            //     if(sideLetter === "A") {
            //         setSelectedSideA(sideID);
            //         setSelectedSideB("");
            //         isSideHandle(true)
            //     } else {
            //         setSelectedSideB(sideID);
            //         setSelectedSideA("");
            //         isSideHandle(false);
            //     }
            // } else {
            //     const sideNumber = parseInt(sideID.replace(/[^\d]/g, ''));
            //     if(sideNumber % 2 === 1) {
            //         setSelectedSideA(sideID);
            //         setSelectedSideB("");
            //         isSideHandle(true);
            //     } else {
            //         setSelectedSideB(sideID);
            //         setSelectedSideA("");
            //         isSideHandle(false);
            //     }
            // }
            onClick(sideID);
        } else {
            return '';
        }
    }

    const renderOptionSide = (sideID: string) => {
        const sideStatus = statuses.find(s => s.geoMarkPart === sideID);
        const style = linerGradient(dates, sideStatus);
        return (
            <div className="renderOptionSide">
                <span style={style} className="renderOptionSide__square"></span>
                {sideID}
                <span className="renderOptionSide__status">{sideStatus ? sideStatus.status : ''}</span>
            </div>
        )
    }

    // const sidesA = optionsSide(true, billBoard);
    // const sidesB = optionsSide(false, billBoard);


    const sideGroupView = () => {
        return (
            <>
                {billboardProperties.sides1.length > 6 ? 
                    <Autocomplete
                        className="billboardSideCount"
                        options={billboardProperties.sides1}
                        value={selectedSideA}
                        autoHighlight
                        defaultValue={billboardProperties.sides1[0]}
                        onChange={(event: React.ChangeEvent<{}>, sideID: string) => onChangeSide(sideID)}
                        renderOption={option => renderOptionSide(option)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                fullWidth
                                label="Выберите поверхность"
                                variant="outlined"
                                size="small"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password" // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                :
                    <SideGroup
                        sides={billboardProperties.sides1}
                        statuses={statuses}
                        dates={dates}
                        onClick={(sideID) => onChangeSide(sideID)}
                        selectedSide={selectedSideA}
                    />            
                }

                {billboardProperties.sides2.length > 6 ? 
                    <Autocomplete
                        className="billboardSideCount"
                        options={billboardProperties.sides2}
                        value={selectedSideB}
                        autoHighlight
                        onChange={(event: React.ChangeEvent<{}>, sideID: string) => onChangeSide(sideID)}
                        renderOption={option => renderOptionSide(option)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                fullWidth
                                label="Выберите поверхность"
                                variant="outlined"
                                size="small"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password" // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    :
                    <SideGroup
                        sides={billboardProperties.sides2}
                        statuses={statuses}
                        dates={dates}
                        onClick={(sideID) => onChangeSide(sideID)}
                        selectedSide={selectedSideB}
                    />
                }
            </>
        )
    }

    return (
        <div className="letter-button-group">
            {sideGroupView()}
        </div>
    );
};


export default BillboardSides;