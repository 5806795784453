import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';       
import { IconButton, CardHeader, Grid, Button, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TariffAgenciesAutocomplete from './TariffAgencies';
import AddressAutocomplete from '../bricks/AddressAutocomplete';
import TariffTable from './TariffTable';
import { GeoMarkTariffs, IGeoMarkTariffs } from '../../store/billboards/types';
import { BillboardTariffs, GetGeoMarkTariffs } from 'sedi-webserverproxy';
import BrandAutocomplete from './BrandAutocomplete';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import Draggable from 'react-draggable';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import { RollUpCard } from '../../store/event/types';
import RemoveIcon from '@material-ui/icons/Remove';
import { BillboardsShortInfo } from '../../RemoteCommands/type';
import './TariffCard.scss';

type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;

const TariffCard:React.FC<Props> = (props) => {
    const {
        openTariffCardAction, 
        billboardTariffsOwner, 
        billboardsShortInfo, 
        currentAgency,
        getGeoMarkTariffsOwnerAction,
        getGeoMarkTariffsAction,
        draggablePositions,
        draggablePositionAction,
        draggable,
        rollUpCards,
        rollUpCardAction,
        billboardShort,
        billboardShortAction
    } = props;

    const [addChecked, setAddChekced] = useState(false);
    const [geoMarkTariffs, setGeoMarkTariffs] = useState<GeoMarkTariffs[]>([]);
    const [geoMarkId, setGeoMarkId] = useState<number>(-1);

    const addCheckedCose = () => {
        setAddChekced(false);
    }

    const onBillboardChange = (billboard: BillboardsShortInfo | undefined) => {
        billboardShortAction(billboard);
    }

    // let geoMarkTariffs: GeoMarkTariffs[] = [];

    const newGeoMarkTariffs = (tariffs: GetGeoMarkTariffs[]) => {
        let _geoMarkTariffs: GeoMarkTariffs[] = [];
        for(let tariff of tariffs) {  
            const currentBillboard = billboardsShortInfo.find(billboard => billboard.geoMarkId === tariff.geoMarkId);
            const { billboardTariffs } = tariff;
            if(currentBillboard && billboardTariffs) {
                let payloadA: IGeoMarkTariffs = {
                    geoMarkId: currentBillboard.geoMarkId,
                    name: currentBillboard.name,
                    constructionType: "",
                    format: "",
                    side: "A",
                    january: 0,
                    february: 0,
                    march: 0,
                    april: 0,
                    may: 0,
                    june: 0,
                    july: 0,
                    august: 0,
                    september: 0,
                    october: 0,
                    november: 0,
                    december: 0,
                }
                let payloadB: IGeoMarkTariffs = {
                    geoMarkId: currentBillboard.geoMarkId,
                    name: currentBillboard.name,
                    constructionType: "",
                    format: "",
                    side: "B",
                    january: 0,
                    february: 0,
                    march: 0,
                    april: 0,
                    may: 0,
                    june: 0,
                    july: 0,
                    august: 0,
                    september: 0,
                    october: 0,
                    november: 0,
                    december: 0,
                }
                if(billboardTariffs.find(item => item.side === "A")) {
                    monthPrice(billboardTariffs, payloadA);
                    _geoMarkTariffs.push(payloadA);
                }
                if(billboardTariffs.find(item => item.side === "B")) {
                    monthPrice(billboardTariffs, payloadB);
                    _geoMarkTariffs.push(payloadB);
                }
            }
        }
        setGeoMarkTariffs(_geoMarkTariffs);
    }

    const addTariffClick = () => {
        if(billboardShort) {
            let payload: IGeoMarkTariffs = {
                geoMarkId: billboardShort.geoMarkId,
                name: billboardShort.name,
                constructionType: "constructionType",
                format: "format",
                side: "A",
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
            };
            setGeoMarkId(billboardShort.geoMarkId);
            setGeoMarkTariffs(prevState => [...prevState, payload]);
            onBillboardChange(undefined);
            setAddChekced(true);
        }
    }

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'TariffCard',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Тарифы",
            key: "TariffCard"
        });
    }

    const draggablePosition = draggablePositions.find(item => item.key === "TariffCard");
    const rollUpCardHas = rollUpCards.some(item => item.key === "TariffCard");

    useEffect(() => {
        SystemEvent.SubscribeEventGetGeoMarkTariffs(
            "TariffCard", 
            (answer) => {
                if(answer.geoMarkTariffs.length > 0 && answer.geoMarkTariffs[0].ownerAccountId > 0) {
                    getGeoMarkTariffsOwnerAction(answer.geoMarkTariffs);
                } else {
                    getGeoMarkTariffsAction(answer.geoMarkTariffs);
                }
            }, 
            (error) => errorCallback(error)
        );
    }, []);

    useEffect(() => {
        newGeoMarkTariffs(billboardTariffsOwner);
    }, [billboardTariffsOwner, currentAgency]);

    return (
        <Draggable
            handle=".selectedTariffCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card
                className={`
                    selectedTariffCard ${true ? 'openSelectedTariffCard' : 'closeSelectedTariffCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                aria-label="settings" 
                                className="mt-1 ml-1"
                                onClick={() => openTariffCardAction(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <div className="box-title">Тарифы</div>
                            </Grid>
                            <Grid item>
                                <TariffAgenciesAutocomplete />
                            </Grid>
                            <Grid item>
                                <BrandAutocomplete disabled={currentAgency ? false : true} />
                            </Grid>
                            <Grid item xs={3}>
                                <AddressAutocomplete 
                                    billboard={billboardShort}
                                    onBillboardChange={(billboard: BillboardsShortInfo | undefined) => onBillboardChange(billboard)} />
                            </Grid>
                            <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={addTariffClick}
                                disabled={billboardShort ? false : true} >
                                Добавить
                            </Button>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent className="pt-0">
                    <TableContainer component={Paper} className="projectTableContainer">
                        <TariffTable 
                            geoMarkTariffs={geoMarkTariffs} 
                            geoMarkId={geoMarkId} 
                            addChecked={addChecked}
                            addCheckedCose={addCheckedCose}
                            currentAgency={currentAgency}
                        />
                    </TableContainer>
                </CardContent>
            </Card>
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => ({
    billboardTariffsOwner: state.billboards.billboardTariffsOwner,
    billboardsShortInfo: state.billboards.billboardsShortInfo,
    billboardShort: state.billboards.billboardShort,
    openTariffCard: state.billboards.openTariffCard,
    currentAgency: state.event.currentAgency,
    currentBrand: state.billboards.currentBrandInfo,
    draggable: state.app.draggable,
    draggablePositions: state.app.draggablePositions,
    rollUpCards: state.event.rollUpCards
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    openTariffCardAction: (openTariffCard: boolean) => 
        dispatch(actions.billboards.openTariffCardAction(openTariffCard)),
    getGeoMarkTariffsOwnerAction: (billboardTariffsOwner: GetGeoMarkTariffs[]) => 
        dispatch(actions.billboards.getGeoMarkTariffsOwnerAction(billboardTariffsOwner)),
    getGeoMarkTariffsAction: (billboardTariffsOwner: GetGeoMarkTariffs[]) => 
        dispatch(actions.billboards.getGeoMarkTariffsAction(billboardTariffsOwner)),
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    billboardShortAction: (billboardShort: BillboardsShortInfo | undefined) => 
        dispatch(actions.billboards.billboardShortAction(billboardShort)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffCard);

function monthPrice(billboardTariffs: BillboardTariffs[], geoMarkTariff: IGeoMarkTariffs) {
    billboardTariffs.forEach((price) => {
        switch(price.month) { 
            case 1: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.january = price.totalCost
                }
               break;
            } 
            case 2: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.february = price.totalCost
                }
                break;
            }
            case 3: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.march = price.totalCost
                }
                break;
            }
            case 4: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.april = price.totalCost
                }
                break;
            }
            case 5: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.may = price.totalCost
                }
                break;
            }
            case 6: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.june = price.totalCost
                }
                break;
            }
            case 7: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.july = price.totalCost
                }
                break;
            }
            case 8: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.august = price.totalCost
                }
                break;
            }
            case 9: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.september = price.totalCost
                }
                break;
            }
            case 10: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.october = price.totalCost
                }
                break;
            }
            case 11: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.november = price.totalCost
                }
                break;
            }
            case 12: { 
                if(geoMarkTariff.side === price.side) {
                    geoMarkTariff.december = price.totalCost
                }
                break;
            }
            default: { 
                break;
            } 
        }
    });
};