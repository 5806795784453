import * as React from 'react';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RootState } from '../../store';
import { sortAlphabeticallyString } from '../../utils/sortAlphabetically';

type Props = ReturnType<typeof mapStateToProps> & {
    regionName: string | null | undefined;
    setRegionName: React.Dispatch<React.SetStateAction<string | undefined | null>>;
};

function RegionAutocomplete(props: Props) {
    const { regionsProps, regionName,  setRegionName} = props;
    const regionsSort = sortAlphabeticallyString(regionsProps);
    const regions = ["Все", ...regionsSort];

    const handleChange = (value: string | null) => {
        if(value === "Все") {
            setRegionName(null);
        } else {
            setRegionName(value);
        }
    }

    return (
        <Autocomplete
            id="region"
            options={regions}
            getOptionLabel={(option) => option}
            value={regionName}
            onChange={(event: React.ChangeEvent<{}>, changeValue: string) => 
                handleChange(changeValue)}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label="Регионы" 
                    variant="outlined"
                    size="small" 
                    fullWidth
                />
            }
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    regionsProps: state.event.regions,
});

export default connect(mapStateToProps)(RegionAutocomplete);