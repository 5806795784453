import React, {FunctionComponent} from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { conicGradient } from '../../../utils/getHighlightStyle';
import { DatesRange } from '../../bricks/InputDatesRange';
import { GeoMarkTimeStatus } from 'sedi-webserverproxy';
import { BillboardsShortInfo, IBillboardStatuses } from '../../../RemoteCommands/type';
import '../components/MarkerBillboard.scss';


interface MarkerProps {
    readonly statuses: GeoMarkTimeStatus[] | undefined;
    readonly onClick: any;
    readonly text: string;
    readonly lat: number;
    readonly lng: any;
    readonly dates: DatesRange;
    readonly billboardShort: BillboardsShortInfo | undefined;
};

const MarkerBillboard: FunctionComponent<MarkerProps> = (props) => {
    const {dates, statuses, billboardShort} = props;
    const status = getStatus(statuses);
    let newStatus: IBillboardStatuses | undefined = undefined;
    if(status) {
        newStatus = {
            dateTimeEnd: status.dateTimeEnd,
            dateTimeStart: status.dateTimeStart,
            geoMarkPart: status.geoMarkPart,
            projectId: status.projectId,
            projectName: "",
            status: status.geoMarkStatus,
            geoMarkTimeStatusId: status.geoMarkTimeStatusId,
            isOverSale: status.isOverSale || false
        }
    }
    const style = conicGradient(dates, newStatus);
    const active = billboardShort ? 'active' : '';

    return (
        <div 
            className={`circle ${active}`}
            onClick={props.onClick} 
            style={style}
            >
            <span className="circle__title">{props.text}</span>
        </div>

    );
}

function getStatus(statuses: GeoMarkTimeStatus[] | undefined) {
    if(statuses === undefined) {
        return;
    }
    return statuses[0];
}

const mapStateToProps = (state: RootState) => ({
    billboardShort: state.billboards.billboardShort,
})

export default connect(mapStateToProps)(MarkerBillboard);