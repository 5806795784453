import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { 
    Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Paper, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Tooltip, Table, TableSortLabel, Badge, Link, Button
} from '@material-ui/core';
import { DefaultPosition, DraggablePosition } from '../../store/app/types';
import CloseIcon from '@material-ui/icons/Close';
import { DatesRange } from '../bricks/InputDatesRange';
import moment from 'moment';
import { RollUpCard, StatisticsType } from '../../store/event/types';
import RemoveIcon from '@material-ui/icons/Remove';
import { ISales, SalesPaymentGidDetailedStatistic, StatisticFilter } from '../../RemoteCommands/type';
import { EnhancedTableProps, getComparator, HeadCell, Order, stableSort } from '../../utils/sorting';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import CircularProgressWithLabel from '../bricks/CircularProgressWithLabel';
import { MessageHelper } from '../MessageHub/MessageHelper';
import { downloadExcel } from '../../utils/downloadFiles';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
        dates: DatesRange,
    }

type DetailInformation = {
    projectId: number;
    projectName: string;
    region: string;
    constructionName: string;
    gid: string;
    amountSold: number;
}

const rows: DetailInformation[] = [
    { projectId: 2429, projectName: "Тест", region: "Московский регион", constructionName: "Мира проспект 15А", gid: "GID1", amountSold: 1500 },
    { projectId: 2361, projectName: "Тест2", region: "Краснадарский регион", constructionName: "Мира проспект 12А", gid: "GID2", amountSold: 2500 },
    { projectId: 2442, projectName: "Тест3", region: "Московский регион", constructionName: "Мира проспект 10А", gid: "GID3", amountSold: 3500 }
]

const SalesInformation:React.FC<Props> = (props) => {
    const {
        draggable,
        draggablePositionAction,
        draggablePositions,
        dates,
        openSalesInformationAction,
        projectStatistics,
        rollUpCardAction,
        rollUpCards,
        curretnSale,
        statisticsType,
        curretnSaleAction,
        openSelectedBillboardsAction,
        setActiveProject,
        zIndexAction,
        zIndex,
        gidAction
    } = props;

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof any>('projectName');
    const [detailedStatistic, setDetailedStatistic] = useState<SalesPaymentGidDetailedStatistic[]>([]);
    const [disabled, setDisabled] = useState(false);
    const [executionPercent, setExecutionPercent] = useState<number | null>(null);
    const [excelFileBytes, setExcelFileBytes] = useState<string | null>(null);

    useEffect(() => {
        SystemEvent.SubscribeEventGetSalesPaymentGidDetailedStatistic(
            "SalesInformation", 
            (answer) => {
                setLoading(false);
                setDetailedStatistic(answer.salesPaymentGidDetailedStatistic);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error)
            }
        );
        const statisticFilter: StatisticFilter = {
            Year: parseInt(moment(dates.DateFrom).format('YYYY')),
            Month: parseInt(moment(dates.DateFrom).format('M')),
            YearTo: parseInt(moment(dates.DateTo).format('YYYY')),
            MonthTo: parseInt(moment(dates.DateTo).format('M')),
            BuyerAccountId: statisticsType === StatisticsType.client && curretnSale ? curretnSale.accountId : undefined,
            SellerAccountId: statisticsType === StatisticsType.employee && curretnSale ? curretnSale.accountId : undefined
        };
        SystemEvent.EventGetSalesPaymentGidDetailedStatistic(statisticFilter);
        
        SystemEvent.SubscribeEventGenerateSalesPaymentGidDetailedStatisticFile(
            "SalesInformation", 
            (answer) => {
                setExecutionPercent(Number(answer.executionPercent));
                setExcelFileBytes(answer.excelFileBytes);
            }, 
            (error) => {
                setDisabled(false);
                errorCallback(error)
            }
        );
        MessageHelper.subscribeProgress("SalesInformation", (data) => {
            setExecutionPercent(data.progress);
        });
    }, []);

    useEffect(() => {
        if(excelFileBytes) {
            const fileName = getTitleText().replace(',', '');
            
            downloadExcel(excelFileBytes, fileName);
            setExecutionPercent(null);
            setDisabled(false);
        }
    }, [excelFileBytes]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'SalesInformation',
            position
        });
    };

    const openProjectInfo = async (row: SalesPaymentGidDetailedStatistic) => {
        setActiveProject(row.projectId);
        gidAction(row.gid);
        openSelectedBillboardsAction(true);
        zIndexAction(1);
    }

    const titleView = () => {
        return (
            <div className="box-title">
                { getTitleText() }
            </div>
        )
    }

    const getTitleText = () => {
        const dateFrom = moment(dates.DateFrom).format('MMMM yyyy'),
            dateTo = moment(dates.DateTo).format('MMMM yyyy');
        let type: string = "";
        if(statisticsType === StatisticsType.client) {
            type = "клиент";
        } else if(statisticsType === StatisticsType.employee) {
            type = "сотрудник";
        } else {
            type = ""
        }
        
        return `Продажи ${dateFrom} - ${dateTo}, ${type} ${curretnSale && curretnSale.name}`
    }

    const draggablePosition = draggablePositions.find(item => item.key === "SalesInformation");
    const rollUpCardHas = rollUpCards.some(item => item.key === "SalesInformation");
    const rolleUpClick = () => {
        rollUpCardAction({
            title: `Информация по продажам`,
            key: "SalesInformation"
        });
    }

    const onClose = () => {
        curretnSaleAction(null);
        openSalesInformationAction(false);
    }

    const getExelFile = async () => {
        setDisabled(true);
        
        const statisticFilter: StatisticFilter = {
            Year: parseInt(moment(dates.DateFrom).format('YYYY')),
            Month: parseInt(moment(dates.DateFrom).format('M')),
            YearTo: parseInt(moment(dates.DateTo).format('YYYY')),
            MonthTo: parseInt(moment(dates.DateTo).format('M')),
            BuyerAccountId: statisticsType === StatisticsType.client && curretnSale ? curretnSale.accountId : undefined,
            SellerAccountId: statisticsType === StatisticsType.employee && curretnSale ? curretnSale.accountId : undefined
        };

        SystemEvent.EventGenerateSalesPaymentGidDetailedStatisticFile(statisticFilter);
    }

    return (
        <Draggable
            handle=".selectedCard"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}>
            <Card 
                className={`
                    selectedCard projectStatistics 
                    ${projectStatistics ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto', zIndex: zIndex}}>
                <CardHeader
                    action={
                        <div className="button-group">
                            <Tooltip
                                title="Свернуть"
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton 
                                className="mt-1 ml-1"
                                onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                {titleView()}
                            </Grid>
                            <Grid item xs={5} className="text-right">
                                    <Tooltip title="Сохранить в Exel" placement="top">
                                        <Link 
                                            id="download_link"
                                            onClick={() => getExelFile()}>
                                                <Badge 
                                                    badgeContent={detailedStatistic && detailedStatistic.length} 
                                                    color="error"
                                                    max={9999}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={disabled}
                                                        startIcon={
                                                            executionPercent 
                                                            ?   
                                                                <>
                                                                    {executionPercent !== 100 && 
                                                                        <CircularProgressWithLabel value={executionPercent} />
                                                                    }
                                                                </> 
                                                            : <img src="https://img.icons8.com/color/24/000000/ms-excel.png" />
                                                        }>
                                                        Сохранить
                                                    </Button>
                                                </Badge>
                                        </Link>
                                    
                                    </Tooltip>
                                </Grid>
                        </Grid>
                    }
                />
                <CardContent className="pt-0">
                <TableContainer 
                    component={Paper}
                    className="tableContainer">
                    {loading ? 
                        <div className="loading">
                            <CircularProgress />
                        </div>
                    :
                        <Table stickyHeader>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                            {stableSort(detailedStatistic, getComparator(order, orderBy)).map(row => (
                                <TableRow key={row.projectId}>
                                    <TableCell component="th" scope="row">
                                        <span className="link" onClick={() => openProjectInfo(row)}>
                                            {row.projectName}
                                        </span>
                                    </TableCell>
                                    <TableCell align="center">{row.region}</TableCell>
                                    <TableCell align="center">{row.boardName}</TableCell>
                                    <TableCell align="center">{row.gid}</TableCell>
                                    <TableCell align="center">{row.sum}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    }
                </TableContainer>
                </CardContent>
                {curretnSale &&
                    <CardContent className='pt-0'>
                        <div className='totalStatistics'>
                            {`Всего продано/Оплачено: ${curretnSale.soldTotalCount} поверхности на cумму 
                             ${ 
                                curretnSale.soldTotalSum 
                                + curretnSale.designSum 
                                + curretnSale.installationSum 
                                + curretnSale.printSum 
                                + curretnSale.additionalInstallationSum
                            } руб.`}
                        </div>
                    </CardContent>
                }
            </Card>    
        </Draggable>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        projectStatistics: state.event.projectStatistics,
        rollUpCards: state.event.rollUpCards,
        curretnSale: state.event.curretnSale,
        statisticsType: state.event.statisticsType,
        zIndex: state.event.zIndex
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    openSalesInformationAction: (openSalesInformation: boolean) => 
        dispatch(actions.event.openSalesInformationAction(openSalesInformation)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),  
    curretnSaleAction: (curretnSale: ISales | null) => 
        dispatch(actions.event.curretnSaleAction(curretnSale)),
    openSelectedBillboardsAction: (openSelectedBillboards: boolean) => 
        dispatch(actions.todo.openSelectedBillboardsAction(openSelectedBillboards)),
    setActiveProject: (projectID: number) => 
        dispatch(actions.todo.setActiveProjectActionCreator(projectID)),
    zIndexAction: (zIndex: number) => 
        dispatch(actions.event.zIndexAction(zIndex)),
    gidAction: (gid: string | undefined) => 
        dispatch(actions.event.gidAction(gid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesInformation);
  
const headCells: readonly HeadCell[] = [
    {
      id: "projectName",
      numeric: false,
      disablePadding: true,
      label: 'Имя проекта',
    },
    {
      id: 'region',
      numeric: true,
      disablePadding: false,
      label: 'Регион',
    },
    {
      id: 'boardName',
      numeric: true,
      disablePadding: false,
      label: 'Название конструкции',
    },
    {
        id: 'gid',
        numeric: true,
        disablePadding: false,
        label: 'GID',
    },
    {
      id: 'sum',
      numeric: true,
      disablePadding: false,
      label: 'Сумма продано',
    },
];
  
function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = 
        (property: keyof any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
  
    return (
        <TableHead>
            <TableRow>
            {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id as string}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
            ))}
            </TableRow>
        </TableHead>
    );
}