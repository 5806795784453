import React from 'react';
import CallerAccountInfo from './components/CallerAccountInfo';
import CallButtons from './components/CallButtons';


type Props = {
    isIncomingCall: boolean
}


export class IncomingCall extends React.Component<Props>{

    render() {
        const { isIncomingCall } = this.props;

        return (
            <div className="notification notification-call">
                <div className="notification__title">
                    {isIncomingCall ? 'Входящий звонок' : 'Исходящий звонок'}
                </div>
                <div className="notification__content">
                    <span>
                        <CallerAccountInfo />
                    </span>
                    <span>
                        <CallButtons />
                    </span>
                </div>
                {/* <div className="small text-muted">any msg from server</div> */}
            </div>
        );
    }
}
