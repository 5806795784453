import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { RolesInfo } from 'sedi-webserverproxy';
import './bricks.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
    label: string;
    roles: RolesInfo[];
    onChange: (newChangeValue: any[]) => void;
    currentValue: RolesInfo[];
    shrink?: boolean;
}
const RolesAutocomplate: FunctionComponent<Props> = ({ label, onChange, roles, currentValue, shrink }) => {

    const selectedRoles = roles.map(role => role.key);
 
    return (
        <Autocomplete
            multiple
            options={selectedRoles}
            disableCloseOnSelect
            className="multiInputAutocomplate"
            getOptionLabel={(option: string) => option}
            onChange={(event: React.ChangeEvent<{}>, changeValue: RolesInfo[]) => onChange(changeValue)}
            defaultValue={currentValue ? currentValue : []}
            value={currentValue ? currentValue : []}
            renderOption={(option: string, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option}
                </React.Fragment>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    fullWidth
                    size="small"
                    className="multiInput"
                    InputLabelProps={{ shrink: shrink }}
                />
            )}
        />
    );
}

const mapStateToProps = (state: RootState) => ({
    roles: state.billboards.roles
});

export default connect(mapStateToProps)(RolesAutocomplate);