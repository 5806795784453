import React, { useEffect, useState } from 'react'
import { RootState } from '../../../store';
import { connect } from 'react-redux';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent,  
    DialogTitle, 
    Grid,
    IconButton
} from '@material-ui/core';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { IGeoMarkTimeStatuses } from '../../../RemoteCommands/type';
import { GeoMarkTimeStatus } from 'sedi-webserverproxy';
import { SystemEvent } from '../../../RemoteCommands/SystemEvent';
import CloseIcon from '@material-ui/icons/Close';
import { DatesRange } from '../../bricks/InputDatesRange';

type Props = ReturnType<typeof mapStateToProps> &  {
    duplicateOpen: boolean;
    duplicateClickClose: () => void;
    geoMarkTimeStatus: IGeoMarkTimeStatuses;
    geoMarkId: number;
}

const DuplicateConfirm:React.FC<Props> = (props) => {
    const {
        duplicateOpen,
        duplicateClickClose,
        currentGeoMarkStatus,
        projectID,
        geoMarkTimeStatus,
        geoMarkId
    } = props;

    const [dates, setDates] = useState<DatesRange>({
        DateFrom: moment(geoMarkTimeStatus.dateTimeStart, "YYYY-MM-DD").toDate(),
        DateTo: moment(geoMarkTimeStatus.dateTimeEnd, "YYYY-MM-DD").toDate()
    });

    useEffect(() => {
        setDates({
            DateFrom: moment(geoMarkTimeStatus.dateTimeStart, "YYYY-MM-DD").toDate(),
            DateTo: moment(geoMarkTimeStatus.dateTimeEnd, "YYYY-MM-DD").toDate()
        })
    }, [geoMarkTimeStatus]);

    const duplicateClick = (geoMarkTimeStatus: IGeoMarkTimeStatuses, geoMarkId: number) => {
        const newStatus: GeoMarkTimeStatus = {
            dateTimeEnd: moment(dates.DateTo).format('YYYY-MM-DDTHH:mm:ss'),
            dateTimeStart: moment(dates.DateFrom).format('YYYY-MM-DDTHH:mm:ss'),
            geoMarkId: geoMarkId,
            geoMarkPart: geoMarkTimeStatus.geoMarkPart,
            geoMarkStatus: currentGeoMarkStatus.value,
            geoMarkTimeStatusId: -1,
            projectId: projectID
        }
        SystemEvent.EventSetGeoMarkTimeStatus([newStatus], false);
        duplicateClickClose();
    }

    const handleChangeDate = (isFirstDate: boolean, date: Date | null) => {
        const newDateValue = date ? date : undefined;
        if(isFirstDate) {
            setDates(prevState => ({
                ...prevState,
                DateFrom: newDateValue
            }))
        } else {
            setDates(prevState => ({
                ...prevState,
                DateTo: newDateValue
            }))
        }
    }

    return (
        <Dialog
            open={duplicateOpen}
            className="duplicateConfirm">
            <DialogTitle>
                Дублировать поверхность {geoMarkTimeStatus.gid}
                <IconButton
                    className="duplicateConfirm__close"
                    onClick={duplicateClickClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item>
                        <DatePicker
                            selected={dates.DateFrom}
                            onChange={(date: Date | null) => handleChangeDate(true, date)}
                            selectsStart
                            startDate={dates.DateFrom}
                            endDate={dates.DateTo}
                            dateFormat="dd.MM.yyyy"
                            locale="ru"
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            selected={dates.DateTo}
                            onChange={(date: Date | null) => handleChangeDate(false, date)}
                            selectsStart
                            startDate={dates.DateFrom}
                            endDate={dates.DateTo}
                            dateFormat="dd.MM.yyyy"
                            locale="ru"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={duplicateClickClose} 
                    color="secondary">
                    Нет
                </Button>
                <Button 
                    color="primary" 
                    autoFocus
                    onClick={() => duplicateClick(geoMarkTimeStatus, geoMarkId)}>
                    Да
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state: RootState) => ({
    currentGeoMarkStatus: state.todo.currentGeoMarkStatus,
    projectID: state.todo.projectID
});

export default connect(mapStateToProps)(DuplicateConfirm);