import React from 'react';
import GoogleMapReact from 'google-map-react';
import BUILD_PARAMS from '../../utils/build';
import MarkerBillboard from '../map/components/MarkerBillboard';
import { BillboardsShortInfo } from '../../RemoteCommands/type';
import { DatesRange } from '../bricks/InputDatesRange';
import { GeoMarkTimeStatus } from 'sedi-webserverproxy';

type Props = {
    billboardShort: BillboardsShortInfo;
    dates: DatesRange;
    statuses: GeoMarkTimeStatus[];
};

export const BillboardMap = (props: Props) => {
    const { billboardShort, dates, statuses } = props;

    const getBillboardStatuses = (billboard: BillboardsShortInfo) => {
        return statuses.filter(s => s.geoMarkId === billboard.geoMarkId);
    }

    const defaultProps = {
        center: {
            lat: billboardShort.geoPoint.latitude,
            lng: billboardShort.geoPoint.longitude
        },
        zoom: 16
    };

    return (
        <div className="billboardMap cancelDraggable">
            <GoogleMapReact
                bootstrapURLKeys={{ key: BUILD_PARAMS.GOOGLE_MAPS_API_KEY || ''}}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}>
                <MarkerBillboard 
                    lat={billboardShort.geoPoint.latitude} 
                    lng={billboardShort.geoPoint.longitude}
                    statuses={getBillboardStatuses(billboardShort)}
                    dates={dates}
                    text={billboardShort.gid} 
                    key={'billboard-' + billboardShort.geoMarkId}
                    onClick={() => console.log("click")}>
                </MarkerBillboard>
            </GoogleMapReact>
        </div>
    );
};